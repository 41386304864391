package org.tlsys.admin.cross

import org.tlsys.admin.serialization.JsInvoker
import org.tlsys.admin.serialization.RList
import org.tlsys.asIterator
import org.tlsys.lk.*
import org.tlsys.makeFlow
import pw.binom.date.DateTime
import pw.binom.rpc.AbstractRemoteService

object RemoteOsmiService : CrossOsmiService, AbstractRemoteService(JsInvoker) {

    override val _updateConfig by remote<Unit>()
    override val _getConfig by remote<OsmiConfigDTO>()
    override val _getEnabled by remote<Boolean>()
    override val _setEnabled by remote<Unit>()
    override val _getTemplates by remote<RList<OsmicardTemplateDto>>()
    override val _editTemplate by remote<OsmicardTemplateDto?>()
    override val _refreshTemplates by remote<RList<OsmicardTemplateDto>>()
    override val _getMasterTemplateValue by remote<String?>()
    override val _getEvents by remote<RList<OsmicardEventDto>>()
    override val _emmitEvent by remote<Unit>()
}

fun CrossOsmiService.getAllEvents(pageSize: Int, serial: String?) =
    makeFlow<DateTime, OsmicardEventDto>(cursor = { it.createdAt }) { cursor ->
        getEvents(
            date = cursor,
            limit = pageSize,
            serial = serial,
        )
    }.asIterator()
