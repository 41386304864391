package org.tlsys.gui

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.ui.*
import pw.binom.web.layout.*
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.px
import kotlinx.dom.addClass

class MessageDialog private constructor(
        title: String,
        message: String,
        val width: Int?,
        val height: Int?,
        val type: MessageType = MessageType.ERROR,
        canClose: Boolean = true
) : TitleDialog(title) {

    enum class MessageType {
        ERROR,
        ATTENTION,
        INFO
    }

    init {
        if (width != null)
            dom.style.width = width.px

        if (height != null)
            dom.style.height = height.px
    }

    private val componentsLayout = FlexLayout.div {
        direction = FlexLayout.Direction.Column
        parent.appendTo(layout, grow = 1, shrink = 1)
    }

    private val textBodyLayout = FlexLayout.div {
        direction = FlexLayout.Direction.Row
        alignItems = FlexLayout.AlignItems.Center
        parent.appendTo(layout, grow = 1, shrink = 1)
    }


    val img = Span()
            .addClass("material-icons")
            .appendTo(textBodyLayout, grow = 0, shrink = 0)
            .also {
                it.dom.style.fontSize = 50.px
                it.text=when (type) {
                    MessageType.ERROR -> "error"
                    MessageType.ATTENTION -> "attention"
                    MessageType.INFO -> "info"
                }
            }

//    private val image = (document.createElement("img") as HTMLImageElement).apply {
//        textBodyLayout.add(this) {
//            grow = 0
//            shrink = 0
//        }
//        when (type) {
//            MessageType.ERROR -> this.src = js("error_icon_png")
//            MessageType.ATTENTION -> this.src = js("attention_icon_png")
//            MessageType.INFO -> this.src = js("info_icon_png")
//        }
//
//        style.width = "32px"
//        style.height = "32px"
//    }

    private val text = Span(message).apply {
        dom.classList.add(Styles.SIMPLE_TEXT)
        appendTo(textBodyLayout, grow = 1, shrink = 1)
        dom.style.paddingLeft = "20px"
    }


    private val buttonsLayout = FlexLayout.div {
        direction = FlexLayout.Direction.Row
        justifyContent = FlexLayout.JustifyContent.End
        layout.add(parent)
        parent.addClass("buttonLayout")
    }

    private val okBtn = ConfirmButton("OK").apply {
        buttonsLayout.add(dom)
        onClick {
            close()
        }
    }

    init {
        if (!canClose) {
            okBtn.dom.remove()
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        close()
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        fun show(title: String, message: String, width: Int? = null, height: Int? = null, type: MessageType = MessageType.ERROR, canClose: Boolean = true) = async2<Unit> {
            layoutNavigator.show(MessageDialog(title = title, message = message, width = width, height = height, type = type, canClose = canClose)).await()
        }

        fun showError(message: String, title: String = "Ошибка", canClose: Boolean = true,width:Int?=null) =
                show(title = title, message = message, type = MessageType.ERROR, canClose = canClose,width = width)

        fun showInfo(message: String, title: String = "Информация", canClose: Boolean = true,width:Int?=null) =
                show(title = title, message = message, type = MessageType.INFO, canClose = canClose,width = width)
    }
}