package org.tlsys

object STORAGE_CONSTS {
    const val LAST_TESTED_PHONE = "last_phone"
    const val LAST_TESTED_EMAIL = "last_email"
    const val LAST_TESTED_TELEGRAM = "last_telegram"
    const val LAST_TESTED_TEXT_SMS = "last_tested_text_SMS"
    const val LAST_TESTED_TEXT_EMAIL = "last_tested_text_EMAIL"

    const val LAST_TESTED_SURNAME = "last_tested_surname"
    const val LAST_TESTED_FIRSTNAME = "last_tested_firstname"
    const val LAST_TESTED_PATRONYMIC = "LAST_TESTED_PATRONYMIC"
}
