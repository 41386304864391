package org.tlsys.dto

import org.tlsys.json.*

class FCursor(val offset: ULong = 0uL) : JDTO {

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object FACTORY : JDTOFactory<FCursor> {
        override val type: String
            get() = "fcursor"

        override fun read(node: JsonNode) = FCursor(node["offset"]!!.string().toULong())

        override fun write(obj: FCursor) =
                JsonNode.obj {
                    "offset" to obj.offset.toString()
                }

    }
}