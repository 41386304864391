package org.tlsys.members

import kotlinx.browser.localStorage
import libs.Date
import libs.timeUTC
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.Validated
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.cms.bool
import org.tlsys.cms.long
import org.tlsys.cms.string
import org.tlsys.cms.ui.TimeCountSelector
import org.tlsys.cms.ui.time
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class ChangeBalansDialog private constructor(val f: suspend (amount: Float, start: Long, end: Long?, description: String) -> Boolean) :
    TitleDialog(MemberInfoPage.CHANGE_BALANS) {

    init {
        dom.style.width = 320.px
    }

    private var _canceled = false
    val canceled: Boolean
        get() = _canceled

    private val body = SaveCancelBody().appendTo(super.layout, grow = 1, shrink = 1)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private var oldStartDate by localStorage.long("add_balans_dialog:startDate")
    private var oldEndDate by localStorage.long("add_balans_dialog:endDate")
    private var oldwithDrop by localStorage.bool("add_balans_dialog:withDrop")
    private var oldsum by localStorage.long("add_balans_dialog:sum")
    private var oldDiscription by localStorage.string("add_balans_dialog:description")

    private val deltaSum = EditText(placeHolder = "Сумма")
        .textValidator(DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2))
        .text(oldsum?.let { it / 100f }?.toString() ?: "0")
        .appendTo(layout, grow = 0, shrink = 0)

    val activeLabel = DatetimeEditor(pw.binom.date.DateTime(oldStartDate ?: Date().timeUTC))
        .withLabel("Дата активации")
        .appendTo(layout, grow = 0, shrink = 0)

    val withDrop = Checkbox("Сгорание").appendTo(layout, grow = 0, shrink = 0)

    val disableDate =
        TimeCountSelector(placeHolder = "Период сгорания").time(oldEndDate)
            .appendTo(layout, grow = 0, shrink = 0)

    val description =
        EditText(placeHolder = "Описание транакции").appendTo(layout, grow = 0, shrink = 0)
            .text(oldDiscription ?: "")
    private val l = Validated {
        if (withDrop.checked) {
            disableDate.valid
        } else {
            true
        }
    }
    private val m = MultiValidator(l, deltaSum)

    init {
        disableDate.autoTimeFormat()
        m.onValidChange {
            body.okBtn.enabled = it
        }
        withDrop.EVENT_CHANGED.on {
            disableDate.enabled = withDrop.checked
            disableDate.canBeEmpty = !withDrop.checked
            if (!withDrop.checked) {
                disableDate.time = null
            }
            l.refresh()
        }
        disableDate.onValidChange {
            l.refresh()
        }
        withDrop.checked = oldwithDrop ?: false
        body.cancel {
            cancelClick()
        }
        body.ok {
            f(
                deltaSum.text.toFloat(),
                activeLabel.content.date!!.time,
                if (withDrop.checked) disableDate.time!! else null,
                description.text.takeIf { it.isNotBlank() } ?: "",
            )
            oldStartDate = activeLabel.content.date!!.time
            oldEndDate = disableDate.time
            oldsum = (deltaSum.text.toFloat() * 100f).toLong()
            _canceled = false
            close()
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    companion object {
        suspend fun show(f: suspend (amount: Float, start: Long, end: Long?, description: String) -> Boolean): Boolean {
            val d = ChangeBalansDialog(f)
            d.show()
            return d.canceled
        }
    }
}
