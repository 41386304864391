package org.tlsys.utils

import kotlinx.browser.localStorage
import org.tlsys.admin.ui.EditText
import org.tlsys.admin.ui.text
import org.w3c.dom.*
import org.w3c.dom.Storage
import kotlin.properties.ReadWriteProperty
import kotlin.reflect.KProperty

class StorageVar(val name: String, val storage: Storage = localStorage) :
    ReadWriteProperty<Any?, String?>,
    DataHolder<String?> {
    override fun getValue(thisRef: Any?, property: KProperty<*>): String? = get()

    override fun setValue(thisRef: Any?, property: KProperty<*>, value: String?) {
        set(value)
    }

    override fun get(): String? = storage[name]

    override fun set(value: String?) {
        if (value == null) {
            storage.removeItem(name)
        } else {
            storage[name] = value
        }
    }
}

interface DataHolder<T> {
    fun get(): T
    fun set(value: T)
}

fun <T : EditText> T.bind(property: DataHolder<String?>): T {
    this.text(property.get() ?: "")
    this.eventChange.on {
        property.set(this.text.takeIf { it.isNotEmpty() })
    }
    return this
}
