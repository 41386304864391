package org.tlsys.dto

import org.tlsys.json.*

inline class ProcessId(val uuid: String)

class ProcessDTO(
        val label: String,
        val start: Long,
        val update: Long,
        val max: Long?,
        val progress: Long,
        val success: Boolean?,
        val result: String?,
        val id: ProcessId
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<ProcessDTO> {
        override val type: String
            get() = "process"

        override fun read(node: JsonNode) = ProcessDTO(
                label = node["label"]!!.string(),
                result = node["result"]?.string(),
                success = node["success"]?.boolean(),
                max = node["max"]?.long(),
                start = node["start"]!!.long(),
                update = node["update"]!!.long(),
                progress = node["progress"]!!.long(),
                id = ProcessId(node["id"]!!.string())
        )

        override fun write(obj: ProcessDTO) =
                JsonNode.obj()
                        .set("label", obj.label.json)
                        .set("result", obj.result?.json)
                        .set("success", obj.success?.json)
                        .set("max", obj.max?.json)
                        .set("start", obj.start.json)
                        .set("update", obj.update.json)
                        .set("progress", obj.progress.json)
                        .set("id", obj.id.uuid.json)

    }
}