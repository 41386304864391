package org.tlsys.members.search

import org.tlsys.admin.MemberListProvider
import org.tlsys.admin.core.Services
import org.tlsys.api.MembersService
import org.tlsys.cms.Extensions
import org.tlsys.cms.search.SearchSource
import org.tlsys.dto.MemberDTO
import org.tlsys.json.jdto
import org.tlsys.lex.SimpleParse
import kotlin.js.Promise

object NameSearchSource : SearchSource {

    override val title: String = "ФИО"
    override val searchBy: String = "по ФИО"
    override val uid: String = "MemberName"

    override fun valid(text: String): String? {
        try {
            if (SimpleParse.parse(text) != null) {
                return null
            }
            return "Другая ошибка"
        } catch (e: dynamic) {
            return "Ошибка"
        }
    }

    override fun serarch(text: String, filter: MemberListProvider.Filter): SearchSource.SearchResult = Result(text)

    init {
        Extensions.membersSearch.extend(this)
    }

    class Result(val exp: String) : SearchSource.SearchResult {
        private val membersService by Services.byClass(MembersService::class)
        override fun close() {
        }

        override fun getResult(max: Int, offset: Int): Promise<List<MemberDTO>> =
            membersService.findMembers(exp = exp.jdto, max = max.jdto, offset = offset.jdto).promise
    }
}
