package org.tlsys.node.properties

import org.tlsys.ParamEditor
import org.tlsys.ParamEditorFactory
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.Validator
import org.tlsys.admin.ui.Combobox
import org.tlsys.admin.ui.listModel
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.node.api.GoodsService
import org.tlsys.px
import org.tlsys.wc.ParamDTO
import org.w3c.dom.HTMLButtonElement

object GoodDictionaryPropertyFactory : ParamEditorFactory {
    override fun create(type: String, canBeEmpty: Boolean) =
        if (type == ParamDTO.Type.GOODS_DICTIONARY.name) {
            GoodDictionaryProperty(canBeEmpty)
        } else {
            null
        }
}

class GoodDictionaryProperty(canBeEmpty: Boolean) : ParamEditor<HTMLButtonElement>, Combobox() {
    override var paramValue: String = ""
        set(value) {
            field = value
            if (init) {
                update()
            }
        }
    private var init = false
    private val goodsService by Services.byClass(GoodsService::class)
    private val list by lazy { goodsService.getDictionaries().promise }

    private var _valid = true
    override val valid: Boolean
        get() = _valid && super.valid

    private fun update() = async2<Unit> {
        _valid = false
        enabled = false
        val list = list.await()

        model = listModel(listOf("Нет") + list.map { it.name })

        val ss = list.indexOfFirst { it.id == paramValue }.takeIf { it >= 0 }?.let { it + 1 } ?: 0
        _valid = true
        selected = ss
        enabled = true
    }

    init {
        listModel(listOf("Нет"))
        selected = 0

        dom.style.minHeight = 34.px
        addValidator(Validator { if (canBeEmpty || it > 0) valid() else invalid("Список товаров не выбран") })

        dom.addEventListener("change", {
            async2 {
                val list = list.await()
                paramValue = list.getOrNull(selected - 1)?.id ?: ""
            }
        })
    }

    override suspend fun onStart() {
        console.info("Start Good DIctionary Component")
        if (!this.init) {
            this.init = true
        }

        update()
    }
}
