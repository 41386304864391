package org.tlsys.promotion.dto

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.admin.BonusAmountConfig

@Serializable
data class HolidaysBonusPromotionConfig(
    @SerialName("tag_filter")
    val tagFilter: TagFilterDto,
    val month: Int = 1,
    @SerialName("day_of_month")
    val dayOfMonth: Int = 1,
    val amount: BonusAmountConfig,
    val description: String = "",
    @SerialName("send_notify")
    val sendNotify: Boolean = false,
    @SerialName("notify_text")
    val notifyText: String = "",
)
