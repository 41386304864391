package org.tlsys.api

import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import org.tlsys.ApiUrls
import org.tlsys.ExternalScriptManifest
import org.tlsys.dto.ExternalManifest
import org.tlsys.dto.ExternalScriptDto
import pw.binom.uuid.UUID
import pw.binom.uuid.serialization.UUIDStringSerializer

object ExternalScriptsService {
    suspend fun getList() =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.EXTERNALSCRIPTS_LIST_POST,
            bodyClass = Unit.serializer(),
            body = null,
            response = ListSerializer(ExternalScriptDto.serializer()),
            method = "GET",
        )

    suspend fun getManifestList() =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.EXTERNALSCRIPTS_MANIFESTLIST_POST,
            bodyClass = Unit.serializer(),
            body = null,
            response = ListSerializer(ExternalScriptManifest.serializer()),
            method = "GET",
        )

    suspend fun create(record: ExternalScriptDto) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.EXTERNALSCRIPTS_CREATE_POST,
            bodyClass = ExternalScriptDto.serializer(),
            body = record,
            response = ExternalScriptDto.serializer(),
            method = "POST",
        )

    suspend fun update(record: ExternalScriptDto) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.EXTERNALSCRIPTS_UPDATE_POST,
            bodyClass = ExternalScriptDto.serializer(),
            body = record,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun delete(id: UUID) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.EXTERNALSCRIPTS_DELETE_POST,
            bodyClass = UUIDStringSerializer,
            body = id,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun getManifest(id: UUID) =
        try {
            Core2ApiAccess.call(
                serviceUrl = ApiUrls.EXTERNALSCRIPTS_GET_MANIFEST_POST,
                bodyClass = UUIDStringSerializer,
                body = id,
                response = ExternalManifest.serializer(),
                method = "POST",
            )
        } catch (e: NotFoundException) {
            null
        }

//    suspend fun getById(id: UUID) =
//        Core2ApiAccess.call(
//            serviceUrl = ApiUrls.TEMPLATE_BY_ID_GET + UTF8.encode(id.toString()),
//            bodyClass = Unit.serializer(),
//            body = Unit,
//            response = Unit.serializer(),
//            method = "GET"
//        )
}
