package org.tlsys.admin

import kotlinx.serialization.Serializable

@Serializable
enum class PermanentPromotionType(val title: String) {
    BIRTHDAY_PROMOTION(
        title = "Бонусы на день рождения",
    ),

    TAGS_BY_PAYMENT(
        title = "Метки по оборотам",
    ),

    REMOVE_UNUSED(
        title = "Удаление бонусов не активных клиентов",
    ),

    HOLIDAYS(title = "Бонусы на праздники"),
}
