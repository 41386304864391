package org.tlsys.core.report

import org.tlsys.admin.addClass
import org.tlsys.admin.ui.AbstractPage
import org.tlsys.admin.ui.Span
import org.tlsys.admin.ui.Styles
import org.tlsys.async2
import org.tlsys.ui.*
import pw.binom.web.layout.*

class ReportPage : AbstractPage() {

    interface ReportConfigDialog {
        suspend fun make()

        val title: String
    }

    companion object {
        val URI = "report"
        val TITLE = "Отчёты"
    }

    private val item1 = Item(DocumentReport).appendTo(contentLayout, grow = 0, shrink = 0)
    private val item2 = Item(ClientReport).appendTo(contentLayout, grow = 0, shrink = 0)
    private val item3 = Item(TransactionReport).appendTo(contentLayout, grow = 0, shrink = 0)
    // private val item3 = Item(InterrogationReport).appendTo(contentLayout, grow = 0, shrink = 0)

    override suspend fun getTitle(): String = TITLE
    class Item(val item: ReportConfigDialog) : DivComponentWithLayout(alignItems = FlexLayout.AlignItems.Center) {
        val span = Span(item.title)
            .addClass(Styles.SIMPLE_TEXT)
            .appendTo(layout)
            .also {
                it.dom.style.cursor = "default"
            }

        init {
            dom.onclick = {
                async2 {
                    item.make()
                }
            }
            addClass(Styles.LIST_ITEM_STYLE)
        }
    }
}
