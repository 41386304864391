package org.tlsys.core.search

import kotlinx.browser.document
import libs.asDateString
import libs.asDateUtc
import org.tlsys.AutoLoadAsyncListIterator
import org.tlsys.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.await
import org.tlsys.core.AsyncIterator
import org.tlsys.core.clientImport.unfix
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.DivComponentWithLayout
import org.tlsys.ui.createLink
import pw.binom.web.Listener
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class SearchByMemberTab : DivComponentWithLayout(
    direction = FlexLayout.Direction.Row,
    alignItems = FlexLayout.AlignItems.Start,
) {

    private val searchService by Services.byClass(SearchService::class)

    private var it: AsyncIterator<SearchElement.MemberSearchElement>? = null
    private val table = MaterialTable().appendTo(layout)
    private val scroll = ScrollController(dom)

    init {
        layout.wrap = FlexLayout.Wrap.Enable
        layout.alignContent = FlexLayout.AlignContent.Start
        val head = MaterialTable.Header().appendTo(table)

        class Head(text: String) : MaterialTable.Column() {
            init {
                dom.innerText = text
                dom.style.apply {
                    fontFamily = Styles.DEFAULT_TEXT_FONT
                    fontSize = Styles.DEFAULT_FONT_SIZE.px
                }
            }
        }
        Head("ФИО").appendTo(head)
        Head("Карты").appendTo(head)
        Head("Списание бонусов").appendTo(head)
        Head("Бонусы").appendTo(head)
        Head("День рождения").appendTo(head)
        Head("CardPr").appendTo(head)
        Head("Телефоны").appendTo(head)
        Head("E-mail").appendTo(head)
    }

    suspend fun search(text: String?, tags: List<Long>, gender: Boolean?, cardPr: Boolean?) {
        it = searchService.search(
            text = text,
            tags = tags,
            gender = gender,
            cardPr = cardPr,
        )
        needReload = true
        doLoad()
    }

    private var listener: Listener? = null
    private var scrollY = 0
    private var needReload = true

    fun addTop(member: SearchElement.MemberSearchElement) {
        val item = MemberItem(member).appendFirstTo(table)
        Effects.blockRow(item.dom)
        Effects.createItem(item.dom)
    }

    private suspend fun doLoad() {
        println("Loading....")
        needReload = false
        table.clearBody()
        listener?.stopListen()
        listener = null
        val it = it ?: return

        if (isStarted) {
            listener = scroll.addScrollListener {
                scrollY = scroll.y
                if (scroll.endScrollY) {
                    ProcessBlocker.block("Загрузка клиентов") {
                        var i = 0
                        while (i < 30) {
                            if (!it.hasNext()) {
                                break
                            }
                            val e = it.next()
                            MemberItem(e).appendTo(table)
                            i++
                        }
                    }
                }
            }
            ProcessBlocker.block("Загрузка клиентов") {
                while (!scroll.visibleScrollY) {
                    if (!it.hasNext()) {
                        break
                    }
                    val e = it.next()
                    MemberItem(e).appendTo(table)
                }
            }.await()
        } else {
            console.info("Not started!")
        }
    }

    override suspend fun onStart() {
        super.onStart()
        console.info("SearchByMemberTab->onStart")
        val scrollY = scrollY
        if (listener == null && needReload) {
            doLoad()
        }
        scroll.y = scrollY
    }
}

private class MemberItem(val item: SearchElement.MemberSearchElement) : MaterialTable.Row() {
    inner class MemberName : MaterialTable.Column() {
        private val title = document.createLink()

        init {
            this.dom.appendChild(title)
            var txt = "${item.lastName} ${item.firstName} ${item.middleName ?: ""}"
            if (txt.isBlank()) {
                txt = "нет"
            }
            title.innerText = txt
            title.href = "#/members/m${item.memberId}"
            title.addClass(Styles.LINK)
        }
    }

    class Field(text: String) : MaterialTable.Column() {
        init {
            dom.innerText = text
            dom.style.apply {
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.DEFAULT_FONT_SIZE.px
            }
        }
    }

    class FieldList(text: List<String>) : MaterialTable.Column() {
        init {
            dom.innerText = text.joinToString("\n")
            dom.style.apply {
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.DEFAULT_FONT_SIZE.px
            }
        }
    }

    //    private val sex = Field(item.sex?.let { if (it) "Мужской" else "Женский" } ?: "Не указанно")
//            .appendTo(this)
    private val member = MemberName().appendTo(this)
    private val cards = FieldList(item.cards)
        .appendTo(this)
    private val allowDropBonus = Field(if (item.allowBonusDrop) "Да" else "Нет")
        .appendTo(this)
    private val bonus = Field((item.bonusAllowDrop + item.bonusNotAllowDrop).unfix(2).toString())
        .appendTo(this)
    private val birthday = Field(item.birthday?.let { it.asDateUtc.asDateString } ?: "")
        .appendTo(this)
    private val cardPr = Field(item.cardPrInstallDate?.let { it.asDateUtc.asDateString } ?: "")
        .appendTo(this)
    private val phones = FieldList(item.phones)
        .appendTo(this)
    private val emails = FieldList(item.emails)
        .appendTo(this)

    init {
        dom.addClass(Styles.LIST_ITEM_STYLE)
    }
}

fun SearchService.search(
    text: String?,
    tags: List<Long>,
    gender: Boolean?,
    cardPr: Boolean?,
) = AutoLoadAsyncListIterator(30, 60) { max, offset ->
    this.searchByMember(
        search = text?.jdto,
        tags = tags.map { it.jdto }.jdto(),
        offset = offset.jdto,
        max = max.jdto,
        gender = gender?.jdto,
        cardPr = cardPr?.jdto,
    ).promise
}
