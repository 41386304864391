package org.tlsys.admin.ui

import org.tlsys.css.animate
import org.tlsys.px
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import kotlinx.browser.window
import kotlin.js.Promise

/**
 * Эффекты
 */
object Effects {

    /**
     * Элемент [element] моргнет. Нужен для привлечения внимания
     */
    fun blockRow(element: HTMLElement): Promise<Unit> {
        val rowColor = window.getComputedStyle(element).background
        return element.animate {
            duration = 500
            start {
                background = "#FFDE00"
            }
            end {
                background = rowColor
            }
        }.start()
    }

    /**
     * Создает анимацию появления строки. Функция ничего не создает! Только анимирует
     */
    fun createAndBlankItem(dom: HTMLElement): Promise<Unit> {
        val d = window.getComputedStyle(dom)
        return dom.animate {
            init {
                overflow = "hidden"
            }
            0{
                height = 0.px
                opacity = 0.0
                transform = "scale(1, 0)"
                background = "#FFDE00"
            }

            30 {
                height = d.height
                opacity = d.opacity.toDoubleOrNull() ?: 1.0
                transform = "scale(1, 1)"
            }

            100 {
                background = d.background
            }
            duration = 500
        }.start()
    }

    /**
     * Создает анимацию удаления строки. Функция ничего не удаляет! Только анимирует
     */
    fun removeItem(dom: HTMLElement): Promise<Unit> {
        val d = window.getComputedStyle(dom)
        return dom.animate {
            init {
                overflow = "hidden"
            }
            0{
                height = d.height
                opacity = d.opacity.toDoubleOrNull() ?: 1.0
//                padding = d.padding
                transform = "scale(1, 1)"
            }

            100 {
                height = 0.px
                opacity = 0.0
//                padding = 0.px
                transform = "scale(1, 0)"
            }
            duration = 100
        }.start()
    }

    fun removeInline(dom: HTMLDivElement, duration: Int = 100): Promise<Unit> {
        val d = window.getComputedStyle(dom)
        return dom.animate {
            function = "ease-in-out"
            init {
                overflow = "hidden"
            }
            0 {
                width = d.width
                opacity = d.opacity.toDoubleOrNull() ?: 1.0
                padding = d.padding
                margin = d.margin
                transform = "scale(1, 1)"
            }
            100{
                width = 0.px
                opacity = 0.0
                padding = 0.px
                margin = 0.px
                transform = "scale(0, 1)"
            }
            this.duration = duration
        }.start()
    }

    fun createInline(dom: HTMLDivElement, duration: Int = 100): Promise<Unit> {
        val d = window.getComputedStyle(dom)
        return dom.animate {
            function = "ease-in-out"
            init {
                overflow = "hidden"
            }
            0{
                width = 0.px
                opacity = 0.0
//                padding = 0.px
                transform = "scale(0, 1)"
            }

            100 {
                width = d.width
                opacity = d.opacity.toDoubleOrNull() ?: 1.0
//                padding = d.padding
                transform = "scale(1, 1)"
            }
            this.duration = duration
        }.start()
    }

    /**
     * Создает анимацию появления строки. Функция ничего не создает! Только анимирует
     */
    fun createItem(dom: HTMLElement): Promise<Unit> {
        val d = window.getComputedStyle(dom)
        return dom.animate {
            init {
                overflow = "hidden"
            }
            0{
                height = 0.px
                opacity = 0.0
                transform = "scale(1, 0)"
            }

            100 {
                height = d.height
                opacity = d.opacity.toDoubleOrNull() ?: 1.0
//                padding = d.padding
                transform = "scale(1, 1)"
            }
            duration = 100
        }.start()
    }
}