package org.tlsys.cms.ui

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.AnimationSpeed
import org.tlsys.navigation.LayoutLevelManager
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pw.binom.web.AbstractComponent
import kotlin.js.Promise

class LayoutLevelManagerImpl(val dom: HTMLElement) : LayoutLevelManager<HTMLDivElement> {

    init {
        dom.style.apply {
            transition = "${AnimationSpeed.LAYOUT_CHANGE}ms background-color ease"
        }
    }

    private fun refreshLevels() {
        for (i in 0 until levels.size)
            levels[i].level = levels.size - 1 - i

        val colorCof = (levels.size - 1) * 20
        dom.style.backgroundColor = "rgb(${66 - colorCof}, ${74 - colorCof}, ${84 - colorCof})"
    }

    inner class Level : AbstractComponent<HTMLDivElement>(), LayoutLevelManager.Level<HTMLDivElement> {
        override val dom: HTMLDivElement = document.createDiv()

        init {
            dom.style.apply {
                left = "0px"
                right = "0px"
                top = "0px"
                bottom = "0px"
                position = "absolute"
                background = "transparent"

                transition =
                    "${AnimationSpeed.LAYOUT_CHANGE}ms filter ease, ${AnimationSpeed.LAYOUT_CHANGE}ms transform ease"
            }

            this@LayoutLevelManagerImpl.dom.appendChild(dom)
        }

        var level: Int = 0
            set(value) {
                field = value
                dom.style.apply {
                    if (value > 0) {
                        filter =
                            "blur(${(level * 2).px}) brightness(${1 - level / 10f * 3}) grayscale(${level / 10f * 3})"
                        transform = "scale(${1 - level / 100f})"
                    } else {
                        filter = ""
                        transform = ""
                    }
                }
            }

        override fun close() = Promise<Unit> { d, c ->
            window.setTimeout({
                dom.remove()
                d(Unit)
            }, AnimationSpeed.LAYOUT_CHANGE)
            levels -= this
            refreshLevels()
        }

        override val content: LayoutLevelManager.LevelContent<out HTMLElement>
            get() = TODO("Not yet implemented")
    }

    private val levels = ArrayList<Level>()

    fun last() = levels.lastOrNull()

    override val current: LayoutLevelManager.Level<HTMLDivElement>?
        get() = last()

    fun giveLayout(): Level {
        val l = Level()
        levels += l
        refreshLevels()
        return l
    }

    override fun createLayout(content: LayoutLevelManager.LevelContent<out HTMLElement>): LayoutLevelManager.Level<HTMLDivElement> =
        giveLayout()
}
