package org.tlsys.core.promotion.setMaxDiscount

import org.tlsys.JsonObject
import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.OrderPromotionType
import org.tlsys.admin.SetMaxBonusPayConfig
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.api.ShopsService
import org.tlsys.asPercent
import org.tlsys.core.promotion.BasePromotionDialog
import org.tlsys.core.promotion.OrderPromotionEditor
import org.tlsys.goods.DTODictionary
import org.tlsys.goods.api.GoodDictionaryService
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import pw.binom.uuid.toUUID
import pw.binom.web.layout.*

class SetMaxDiscountOrderPromotion(record: OrderPromotionRecord?) :
    BasePromotionDialog(record, type.title) {
    companion object : OrderPromotionEditor {
        override val type: OrderPromotionType
            get() = OrderPromotionType.SET_MAX_DISCOUNT

        override suspend fun show(record: OrderPromotionRecord?): OrderPromotionRecord? {
            val d = SetMaxDiscountOrderPromotion(record)
            d.show()
            return d.result
        }
    }

    init {
        dom.style.height = 450.px
    }

    private val shopsService by Services.byClass(ShopsService::class)
    private val memberTagService by Services.byClass(MemberTagService::class)
    private val goodDictionaryService by Services.byClass(GoodDictionaryService::class)
    private lateinit var dictionariesList: List<DTODictionary>

    override suspend fun onInit() {
        val dictionaries = goodDictionaryService.getDictionaries((-1).jdto, 0L.jdto).await()
        this.dictionaries.content.items = listOf("Все товары") + dictionaries.map { it.name }
        dictionariesList = dictionaries
        if (params != null) {
            val selected = dictionariesList.indexOfFirst { it.id.toUUID() == params.goodDictionaryId }
            this.dictionaries.content.select = if (selected >= 0) selected + 1 else 0

//            params.records.forEach { level ->
//                list.add(RecordItem(level))
//            }
            val tags =
                memberTagService.getTagList((params.includeTags + params.excludeTags).map { it.jdto }.jdto())
                    .await()
                    .associateBy { it.id }
            conditions.includeTags.content.addTag(
                params.includeTags.map { tags[it]!! },
            )
            conditions.excludeTags.content.addTag(
                params.excludeTags.map { tags[it]!! },
            )
            conditions.includeShops.content.addTag(
                params.shops.mapNotNull { shopsService.getShop(it.jdto).await() },
            )
            maxPercentPay.text = params.maxPercentPay.toString().removeSuffix("%")
        } else {
            this.dictionaries.content.select = 0
        }
    }

    private val params = record?.params?.getObject<SetMaxBonusPayConfig>()
    private val dictionaries = Combobox2()
        .addToValidator(validator)
        .validator(Validator { if (it >= 0) valid() else invalid() })
        .withLabel("Список товаров")
        .appendTo(config.layout, grow = 0, shrink = 0)
    private val maxPercentPay = EditText(placeHolder = "Максимальный процент оплаты бонусами").textValidator(
        TextValidators.NOT_BLANK
            and DoubleValidator.FORMAT
            and DoubleValidator.minOrEquals2(100.0) and DoubleValidator.moreOrEquals2(0.0),
    )
        .text(params?.maxPercentPay?.toString() ?: "0")
        .appendTo(config.layout, grow = 0, shrink = 0)
        .addToValidator(validator)

    override val type: OrderPromotionType
        get() = SetMaxDiscountOrderPromotion.type

    override suspend fun buildConfig(): JsonObject =
        JsonObject.create(
            SetMaxBonusPayConfig(
                includeTags = conditions.includeTags.content.list.map { it.tag.id },
                excludeTags = conditions.excludeTags.content.list.map { it.tag.id },
                shops = conditions.includeShops.content.list.map { it.id },
                maxPercentPay = maxPercentPay.text.asPercent,
                goodDictionaryId = dictionaries.content.select.let { if (it > 0) dictionariesList[it - 1].id.toUUID() else null },
            ),
        )
}
