package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.admin.core.Services
import org.tlsys.api.MemberTagService
import org.tlsys.dto.MemberTagDTO
import org.tlsys.json.jdto
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.*

class TagSelectorFragment(private val selected: List<Long>, private val hide: List<Long> = emptyList()) :
    AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    init {
        afterConstruct()
    }

    private class Item(val tag: MemberTagDTO, selected: Boolean, val selectedItems: ArrayList<Long>) :
        Checkbox(label = tag.value, checked = selected) {
        init {
            this.EVENT_CHANGED.on {
                if (checked) {
                    if (tag.id !in selectedItems) {
                        selectedItems.add(tag.id)
                    }
                } else {
                    if (tag.id in selectedItems) {
                        selectedItems.remove(tag.id)
                    }
                }
            }
        }
    }

    private val layout = FlexLayout(dom).apply {
        direction = FlexLayout.Direction.Column
    }.asParent()

    private val memberTagService by Services.byClass(MemberTagService::class)
    private val list = ListView<Item>().appendTo(layout)

    private val items = ArrayList<Item>()
    private val _selectedItems = ArrayList<Long>()
    val selectedItems: List<Long>
        get() = _selectedItems

    init {
        _selectedItems += selected
    }

    var iii = 0
    override suspend fun onStart() {
        iii++
        super.onStart()
        console.info("TagSelectorFragment->OnStart -> $iii")
        memberTagService.getTags(null, 0.jdto, (-1).jdto).await()
            .filter { it.id !in hide }
            .map {
                list.addLast(Item(it, selected = it.id in selected, selectedItems = _selectedItems))
            }
    }

    override suspend fun onStop() {
        iii--
        console.info("TagSelectorFragment->OnStop -> $iii")
        super.onStop()
    }
}
