package org.tlsys.help

import kotlinx.browser.document
import org.tlsys.cms.events.EventElement
import org.tlsys.cms.onClick
import org.tlsys.px
import org.tlsys.ui.DivComponent
import org.tlsys.ui.createDiv
import org.tlsys.wc.Closable
import org.w3c.dom.HTMLElement
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

class HelpScreen : DivComponent(), Closable {
    init {
        dom.style.apply {
            left = "0px"
            right = "0px"
            top = "0px"
            bottom = "0px"
            position = "absolute"
            background = "transparent"

            transition = "${animationTime}ms filter ease, ${animationTime}ms transform ease"
        }
    }

    private var focusElement: HTMLElement? = null

    private val hole = document.createDiv().also {
        it.style.apply {
            boxShadow = "rgba(0, 172, 193, 0.8) 0px 0px 0px 3px, rgba(0, 0, 0, 0.6) 0px 0px 0px 99999px"
            position = "absolute"

            borderRadius = "50%"
            left = "50px"
            top = "50px"
            cursor = "pointer"

            width = "150px"
            height = "150px"
        }
        this@HelpScreen.dom.appendChild(it)

        it.onClick {
            clickEvent.dispatch()
            focusElement?.click()
            focusElement?.focus()
        }
    }

    fun clearFocuse() {
        hole.style.apply {
            width = 0.px
            height = 0.px
        }
    }

    val clickEvent = EventElement()

    suspend fun waitTargetClick() {
        suspendCoroutine<Unit> { con ->
            clickEvent.one { con.resume(Unit) }
        }
    }

    enum class Align {
        BEGIN, CENTER, END
    }

    fun holdFocus(dom: HTMLElement, holeRadius: Float = 50f, x: Align = Align.CENTER, xOffset: Float = 0f) {
        val rect = dom.getBoundingClientRect()
        val xCor = when (x) {
            Align.BEGIN -> rect.left
            Align.CENTER -> rect.left + rect.width / 2.0
            Align.END -> rect.left + rect.width
        }
        val yCor = rect.top + rect.height / 2.0

        hole.style.left = (xCor - holeRadius / 2f + xOffset).px
        hole.style.top = (yCor - holeRadius / 2f).px
        hole.style.width = holeRadius.px
        hole.style.height = holeRadius.px
        focusElement = dom
        dom.blur()
    }

    fun start() {
        document.body!!.appendChild(dom)
    }

    override fun close() {
        dom.parentNode?.removeChild(dom)
    }
}

private const val animationTime = 500
