package org.tlsys.node.properties

import org.tlsys.ParamEditor
import org.tlsys.ParamEditorFactory
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.InputTextString
import org.tlsys.wc.ParamDTO
import org.w3c.dom.HTMLInputElement

object StringPropertyFactory : ParamEditorFactory {
    override fun create(type: String, canBeEmpty: Boolean) =
        if (type == ParamDTO.Type.TEXT.name) {
            StringProperty(canBeEmpty)
        } else {
            null
        }
}

class StringProperty(canBeEmpty: Boolean) : InputTextString(), ParamEditor<HTMLInputElement> {
    override var paramValue: String
        get() = text
        set(value) {
            text = value
        }

    init {
        if (!canBeEmpty) {
            addTextValidator(TextValidators.NOT_BLANK)
        }
    }
}
