package org.tlsys.members

import kotlinx.browser.document
import libs.*
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.DEFAULT_SESSION
import org.tlsys.api.MembersService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.dto.ReferenceDto
import org.tlsys.dto.auth.CorePermission
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.permission
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class ReferalsFragment(val memberId: Long) : AbstractFragment<HTMLDivElement>(document.createDiv()) {
    val allowEdit by permission(CorePermission.EDIT_MEMBERS_REFERENCES)
    private val layout = FlexLayout(this, direction = FlexLayout.Direction.Column)

    private val membersService by Services.byClass(MembersService::class)

    private val table = MaterialTable().appendTo(layout, grow = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Статус").appendTo(header).widthPx(1)
            MaterialTable.TextColumn("Клиент").appendTo(header)
            MaterialTable.TextColumn("Дата").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }
    private val scroll = ScrollController(layout.dom)
    override suspend fun onInit() {
        super.onInit()
        val parent = membersService.getParentsRef(memberId.jdto, (-1).jdto, 0L.jdto).await().firstOrNull()
        membersService.getChildsRef(memberId.jdto, (-1).jdto, 0L.jdto).await()
            .forEach {
                Item(
                    to = it,
                ).appendTo(table)
            }
    }

    init {
        if (allowEdit) {
            val addBtn = AddButton().appendTo(layout, grow = 0, shrink = 0)
            addBtn.onClick {
                val member = MemberSelectorDialog.show().await() ?: return@onClick
                if (member == memberId) {
                    MessageDialog.showError(message = "Клиент не может ссылаться сам на себя")
                    return@onClick
                }
                val mem = ProcessBlocker.block("Создание связи") {
                    membersService.addRefer(from = memberId.jdto, to = member.jdto)
                    membersService.getMemberById(member.jdto).await()!!
                }.await()
                val ref = ReferenceDto(
                    member = mem,
                    createDate = Date().timeUTC,
                )
                val item = Item(ref).appendTo(table)
                Effects.blockRow(item.dom)
            }
        }
    }

    private inner class Item(val to: ReferenceDto) : MaterialTable.Row() {

        private inner class StatusColumn : MaterialTable.Column() {
            val layout = DivLayout().appendTo(dom)
            val activeExternalSync = MaterialIcon("cloud").appendTo(layout.layout)
            val slaveExternalSync = MaterialIcon("swap_horiz").appendTo(layout.layout)

            init {
                layout.addClass(Styles.ICONS_H)
                layout.addClass(Styles.LEFT_PADDING)
                refresh()
            }

            fun refresh() {
                val osmiCardEnabled = DEFAULT_SESSION!!.company.osmiCardEnabled
                val cardPrEnabled = DEFAULT_SESSION!!.company.cardPrEnabled
                activeExternalSync.visible =
                    (osmiCardEnabled && to.member.osmiCardInstalled != null) || (cardPrEnabled && to.member.cardPrInstallDate != null)
                slaveExternalSync.visible =
                    !activeExternalSync.visible && (osmiCardEnabled && to.member.osmiCardCreated != null)
            }
        }

        private val status = StatusColumn().appendTo(this)
        private val memberName =
            MaterialTable.LinkColumn(text = to.member.fullName, href = "#/${MembersPage.URI}/m${to.member.id}")
                .appendTo(this)
        private val date =
            MaterialTable.TextColumn(to.createDate?.asDateUtc?.let { "${it.asDateString} ${it.asTimeString}" } ?: "")
                .appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace()).appendTo(this)

        init {
            actions.component.visibleOnHover(this)
            if (allowEdit) {
                actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                    async2 {
                        if (!YesNoDialog.show("Вы действительно хотите удалить связь?", width = 340).await()) {
                            return@async2
                        }
                        ProcessBlocker.block("Удаление связи") {
                            membersService.removeRefer(from = memberId.jdto, to = to.member.id.jdto).await()
                        }.await()
                        removeSelf()
                    }
                }
            }
        }
    }

    override suspend fun onStart() {
        super.onStart()
        layout.onStart()
    }

    override suspend fun onStop() {
        layout.onStop()
        super.onStop()
    }
}
