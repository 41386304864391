package org.tlsys.admin.core

import kotlin.reflect.KClass

external class Proxy(obj: Any, handler: ProxyHandler)
external interface ProxyHandler

fun <T : Any> KClass<T>.newInstance(): T {
    return js("Object").create(this.js.asDynamic().prototype).unsafeCast<T>()
}

fun createProxyHandler(
    apply: ((target: Any, thisArg: Any, argumentsList: Array<Any?>) -> Any?)? = null,
    get: ((target: Any, property: String, receiver: Any) -> Any?)? = null,
): ProxyHandler {
    val obj = js("({})")

    if (apply != null) {
        obj.apply = apply
    }

    if (get != null) {
        obj.get = get
    }

    return obj
}
