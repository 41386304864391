package org.tlsys

import kotlinx.browser.document
import kotlinx.dom.removeClass
import org.tlsys.admin.GlobalMenuItem
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.Span
import org.tlsys.admin.ui.Styles
import org.tlsys.cms.DEFAULT_FONT_SIZE
import org.tlsys.cms.Extensions.globalMenu
import org.tlsys.css.CSS
import org.tlsys.ui.*
import org.tlsys.user.UserIcon
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.*

private object STYLES {
    val MIN = "min"
    val ICON = "icon"
    val TITLE = "title"
    val STYLE = CSS.style {
        ".$MIN" then {
            ".$TITLE" {
                color = "transparent"
                overflow = "hidden"
            }
            ".$ICON" {
            }
        }

        ".$ICON" {
            color = "rgba(0,0,0,0.6)"
        }

        ".$TITLE" {
            transition = "width 300ms ease-in-out, color 300ms ease-in-out"
            whiteSpace = "nowrap"
            margin = "0px 0px 0px 20px"
        }
        overflow = "hidden"
    }.name

    val COMPONY_INFO = CSS.genName()
    val STYLE2 = CSS.style {
        ".$MIN .$COMPONY_INFO" then {
            height = 0.px
            opacity = 0.0
        }

        ".$COMPONY_INFO" {
            transition = "height 300ms ease-in-out, opacity 300ms ease-in-out"
            opacity = 1.0
            height = 50.px
        }
    }.name
}

class NavBtn(imageUrl: String, uri: String, text: String) :
    AbstractComponent<HTMLAnchorElement>() {
    override val dom: HTMLAnchorElement = document.createLink()
    val layout = FlexLayout(this, alignItems = FlexLayout.AlignItems.Center)
    val icon = document.createElement("i").unsafeCast<HTMLElement>()
    val txt = document.createSpan()

    var full: Boolean = true
        set(value) {
            field = value
            if (value) {
                dom.removeClass(STYLES.MIN)
            } else {
                dom.addClass(STYLES.MIN)
            }
        }

    init {
        dom.addClass(STYLES.STYLE)
        dom.classList.add(NavigationPlane.link.name)
        dom.href = "#/$uri"

        icon.innerHTML = imageUrl
        icon.addClass("material-icons")
        icon.style.fontSize = 24.px
        icon.appendTo(layout, grow = 0, shrink = 0)
        icon.addClass(STYLES.ICON)

        txt.addClass(STYLES.TITLE)
        txt.style.apply {
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontSize = DEFAULT_FONT_SIZE.px
        }
        txt.innerText = text
        txt.appendTo(layout, grow = 0, shrink = 0)
    }
}

class NavigationPlane : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    //    private val bg = document.getElementById("navigation") as HTMLDivElement
    private val layout = FlexLayout(dom, direction = FlexLayout.Direction.Column)
    val userMenu = UserIcon().appendTo(layout, grow = 0, shrink = 0)

    var full: Boolean = true
        set(value) {
            field = value
            list.forEach {
                it.btn.full = value
            }
            if (value) {
                dom.removeClass(STYLES.MIN)
            } else {
                dom.addClass(STYLES.MIN)
            }

            userMenu.dom.style.height = (if (value) 50 else 0).px
            userMenu.dom.style.opacity = if (field) "1" else "0"
        }

    init {
        dom.addClass(STYLES.STYLE2)
        userMenu.dom.style.opacity = "1"
        userMenu.dom.style.height = 50.px
        userMenu.dom.style.transition = "height 300ms ease-in-out, opacity 300ms ease-in-out"
//        bg.parentNode!!.appendChild(dom)
        dom.style.apply {
            //            position = "a"
//            top = 0.px
//            left = 0.px
//            bottom = 0.px
//            width = BIG_WIDTH.px
            backgroundColor = Styles.NAVIGATION_COLOR
            boxShadow = "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)"
        }
    }

    companion object {
        val ACTIVE_LINK = "enabled"
        val link = CSS.style {
            backgroundColor = "transparent"
            border = "none"
            outline = "none"
            color = "#000"
            textDecoration = "none"
            textAlign = "left"
            padding = "10px 0px 13px 20px"

            transition = "100ms background-color linear, 100ms color linear"

            hover {
                backgroundColor = "#ececec"
            }

            cursor = "pointer"
            ".$ACTIVE_LINK" then {
                backgroundColor = "#f3f3f3"
                cursor = "default"
                color = "rgba(0,0,0,.7)"
            }
        }
        const val BIG_WIDTH = 228
        const val MIN_WIDTH = 64
    }

    private val globalMenuItems by Services.listByClass(GlobalMenuItem::class)
    fun buildMenu() {
        val items = globalMenu.elements.map { it.value } + globalMenuItems

        items.forEach {
            val after: Array<String> = try {
                it.after
            } catch (e: dynamic) {
                arrayOf()
            }
            add(uri = it.uri, text = it.name, after = after, imageUrl = it.img)
        }

        console.info("External Menu:", items)
    }

    private fun remove(r: Record) {
        if (r in list) {
            r.btn.dom.parentElement!!.removeChild(r.btn.dom)
            list.remove(r)
        }
    }

    class Record(val pageName: String, text: String, val btn: NavBtn, val after: Array<String>)

    private val list = ArrayList<Record>()
    val recrods: List<Record>
        get() = list

    private val buttonBody = DivLayout(direction = FlexLayout.Direction.Column)
        .appendTo(layout, grow = 1, shrink = 1)

    private val companyInfo = DivLayout(direction = FlexLayout.Direction.Column)
        .appendTo(layout, grow = 0, shrink = 0).also {
            it.dom.addClass(STYLES.COMPONY_INFO)
        }

    init {
        companyInfo.dom.style.apply {
            borderTop = "solid 1px #E0E0E0"
            padding = "0px 0px 0px 15px"
        }
        Span("Все права защищены.").appendTo(companyInfo.layout).addClass(Styles.SMALL_TEXT)
        Span("ООО ТрейдЛайн").appendTo(companyInfo.layout).addClass(Styles.SMALL_TEXT)
        Span("Версия " + Env.version).appendTo(companyInfo.layout).addClass(Styles.SMALL_TEXT)
    }

    fun add(imageUrl: String, uri: String, text: String, after: Array<String>?): Record {
        val after = after ?: arrayOf()
        val btn = NavBtn(uri = uri, imageUrl = imageUrl, text = text)

//        btn.style.apply {
//            display = "block"
//        }

        var beforeRecord: Record? = null

        beforeRecord = after.findLast { v ->
            list.find { it.pageName == v } != null
        }?.run {
            list.find { it.pageName == this }
        }

        if (beforeRecord != null) {
            buttonBody.layout.addAfter(element = btn.dom, after = beforeRecord.btn.dom)
        } else {
            buttonBody.layout.add(btn.dom)
        }
        val r = Record(uri, text, btn, after)
        list += r
        return r
    }

    fun setActive(pageName: String?) {
        if (pageName === null) {
            for (r in list) {
                r.btn.dom.removeClass(ACTIVE_LINK)
            }
        } else {
            for (r in list) {
                if (r.pageName != pageName) {
                    r.btn.dom.removeClass(ACTIVE_LINK)
                } else {
                    r.btn.dom.addClass(ACTIVE_LINK)
                }
            }
        }
    }
}
