package org.tlsys.core

import org.tlsys.*
import org.tlsys.admin.GlobalMenuItem
import org.tlsys.admin.SettingItem
import org.tlsys.admin.TranscationColumn
import org.tlsys.admin.core.PageNavigation
import org.tlsys.admin.core.Remote
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.AbstractPage
import org.tlsys.api.ShopsService
import org.tlsys.core.api.ApiPage
import org.tlsys.core.api.ApiService
import org.tlsys.core.api.ImportService
import org.tlsys.core.api.ReportService
import org.tlsys.core.clientImport.ImportMembersAction
import org.tlsys.core.interrogation.InterrogationListPage
import org.tlsys.core.promotion.AdminPromotionService
import org.tlsys.core.promotion.PromotionsPage
import org.tlsys.core.report.ReportPage
import org.tlsys.core.search.SearchGlobalPageService
import org.tlsys.documents.RemoteDocumentServiceImpl
import org.tlsys.documents.dialogs.DocumentView
import org.tlsys.dto.DTOTerminal
import org.tlsys.dto.TransactionDTO
import org.tlsys.dto.auth.CorePermission
import org.tlsys.interrogation.InterrogationWebService
import org.tlsys.json.jdto
import org.tlsys.ui.Page

fun coreMain(remote: Remote) {
    core_dto_set.reg()

    Services.reg(ImportMembersAction)
    Services.reg(
        ReadyDoneListener {
            Services.reg(TransactionTerminalExternal)
            Services.reg(TransactionDocumentExternal)
            Services.reg(PaymentServiceImpl())
            Services.reg(remote.getService(ApiService::class, invokator = CORE_INVOKATOR, setvice = API_SERVICE))
            Services.reg(
                remote.getService(
                    AdminPromotionService::class,
                    invokator = CORE_INVOKATOR,
                    setvice = PROMOTION_SERVICE,
                ),
            )
            Services.reg(
                remote.getService(
                    PayDebtService::class,
                    invokator = CORE_INVOKATOR,
                    setvice = PAYDEBTSERVICE_SERVICE,
                ),
            )
            Services.reg(remote.getService(ReportService::class, invokator = CORE_INVOKATOR, setvice = REPORT_SERVICE))
            Services.reg(remote.getService(ImportService::class, invokator = CORE_INVOKATOR, setvice = IMPORT_SERVICE))
            Services.reg(
                remote.getService(
                    InterrogationWebService::class,
                    invokator = CORE_INVOKATOR,
                    setvice = INTERROGATION_SERVICE,
                ),
            )
            Services.reg(SearchGlobalPageService)

            val showPromotions by permission(CorePermission.SHOW_PROMOTIONS)
            val showReport by permission(CorePermission.SHOW_REPORTS)

            Services.reg(object : SettingItem {
                override val uri: String
                    get() = ApiPage.URI
                override val title: String
                    get() = ApiPage.TITLE

                override suspend fun getPage(): Page = ApiPage()
            })

//        Services.reg(object : SettingItem {
//            override val uri: String
//                get() = PayDebtPage.URI
//            override val title: String
//                get() = PayDebtPage.TITLE
//
//            override suspend fun getPage(): Page = PayDebtPage()
//        })

            Services.reg(object : SettingItem {
                override val uri: String
                    get() = InterrogationListPage.URI
                override val title: String
                    get() = InterrogationListPage.TITLE

                override suspend fun getPage(): Page = InterrogationListPage()
            })

            if (showPromotions) {
                Services.reg(
                    object : GlobalMenuItem {
                        override val name: String
                            get() = PromotionsPage.TITLE
                        override val uri: String
                            get() = PromotionsPage.URI
                        override val after: Array<String>
                            get() = emptyArray()
                        override val img: String
                            get() = "settings"
                        override val page: (parent: AbstractPage) -> AbstractPage = { PromotionsPage() }
                    },
                )
            }

            if (showReport) {
                Services.reg(
                    object : GlobalMenuItem {
                        override val name: String
                            get() = ReportPage.TITLE
                        override val uri: String
                            get() = ReportPage.URI
                        override val after: Array<String>
                            get() = emptyArray()
                        override val img: String
                            get() = "assessment"
                        override val page: (parent: AbstractPage) -> AbstractPage = { ReportPage() }
                    },
                )
            }
        },
    )
}

private val shopsService by Services.byClass(ShopsService::class)

val terminalLoader = Cache<Long, DTOTerminal?> {
    shopsService.getTerminalById(it.jdto).await()
}

object TransactionDocumentExternal : TranscationColumn {
    override val columnName: String
        get() = "Номер документ"

    override fun valueOf(transactionDTO: TransactionDTO) = async2 {
        val docUUID = transactionDTO.document ?: return@async2 "Документ отсутствует"
        val doc = RemoteDocumentServiceImpl.getDocument(docUUID.jdto).await() ?: return@async2 "Документ не найден"
        doc.number
    }

    override fun isClicable(transactionDTO: TransactionDTO) = async2 {
        val docUUID = transactionDTO.document ?: return@async2 false
        RemoteDocumentServiceImpl.getDocument(docUUID.jdto).await() ?: return@async2 false
        true
    }

    override fun click(transactionDTO: TransactionDTO) = async2<Unit> {
        val docUUID = transactionDTO.document ?: return@async2
        val document = RemoteDocumentServiceImpl.getDocument(docUUID.jdto).await() ?: return@async2
        DocumentView.show(document).await()
    }
}

object TransactionTerminalExternal : TranscationColumn {
    override val columnName: String
        get() = "Терминал"

    private val navigator by Services.byClass(PageNavigation::class)

    override fun valueOf(transactionDTO: TransactionDTO) = async2<String> {
        val docUUID = transactionDTO.document ?: return@async2 "нет"
        val doc = RemoteDocumentServiceImpl.getDocument(docUUID.jdto).await() ?: return@async2 "нет"
        val terminal = terminalLoader.get(doc.terminalId) ?: return@async2 "Терминал не найден"
        val shop = shopsService.getShopOfTerminal(doc.terminalId.jdto).await()

        "${shop.title}, ${terminal.name}"
    }

    override fun isClicable(transactionDTO: TransactionDTO) = async2 {
        transactionDTO.document != null
    }

    override fun click(transactionDTO: TransactionDTO) = async2 {
        val docUUID = transactionDTO.document ?: return@async2
        val doc = RemoteDocumentServiceImpl.getDocument(docUUID.jdto).await() ?: return@async2
        val terminal = shopsService.getTerminalById(doc.terminalId.jdto).await() ?: return@async2
        val shop = shopsService.getShopOfTerminal(doc.terminalId.jdto).await()
        navigator.go("/shops/s${shop.id}/t${terminal.id}")
    }
}
