package org.tlsys.node.properties

import org.tlsys.ParamEditor
import org.tlsys.ParamEditorFactory
import org.tlsys.admin.form.IntegerValidator
import org.tlsys.admin.form.Validator
import org.tlsys.admin.form.or
import org.tlsys.admin.ui.InputTextString
import org.tlsys.wc.ParamDTO
import org.w3c.dom.HTMLInputElement

object IntPropertyFactory : ParamEditorFactory {
    override fun create(type: String, canBeEmpty: Boolean) =
        if (type == ParamDTO.Type.INT.name) {
            IntProperty(canBeEmpty)
        } else {
            null
        }
}

class IntProperty(canBeEmpty: Boolean) : ParamEditor<HTMLInputElement>, InputTextString() {

    override var paramValue: String
        get() = text
        set(value) {
            text = value
        }

    init {
        if (canBeEmpty) {
            addTextValidator(IntegerValidator.FORMAT or (Validator { if (it.isEmpty()) valid() else invalid() }))
        } else {
            addTextValidator(IntegerValidator.FORMAT)
        }
    }
}
