package org.tlsys.core

import kotlin.js.Promise

interface Closeable {
    fun close()
}

interface CloseableAsync {
    suspend fun close()
}

fun CloseableAsync(f: suspend () -> Unit) = object : CloseableAsync {
    override suspend fun close() {
        f()
    }
}

fun Closeable(f: () -> Unit) = object : Closeable {
    override fun close() {
        f()
    }
}