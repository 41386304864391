package libs

/**
 * @author Subochev Anton
 *
 * caffeine.mgn@gmail.com
 *
 * JavsSript Date Class
 */
external class Date {
    constructor()
    internal constructor(ms: Double)
    constructor(date: String)
    internal constructor(
        year: Int,
        month: Int,
        day: Int = definedExternally,
        hour: Int = definedExternally,
        minute: Int = definedExternally,
        second: Int = definedExternally,
        millisecond: Int = definedExternally
    )

    internal fun getTime(): Double = definedExternally
    internal fun setTime(milliseconds: Double): Unit = definedExternally

    internal fun getFullYear(): Int = definedExternally
    internal fun setFullYear(year: Int, month: Int = definedExternally, date: Int = definedExternally): Unit =
        definedExternally

    internal fun getMonth(): Int = definedExternally
    internal fun setMonth(month: Int, date: Int = definedExternally): Unit = definedExternally

    internal fun getDate(): Int = definedExternally
    internal fun setDate(date: Int): Unit = definedExternally

    internal fun getHours(): Int = definedExternally
    internal fun setHours(
        hour: Int,
        min: Int = definedExternally,
        sec: Int = definedExternally,
        ms: Int = definedExternally
    ): Unit = definedExternally

    internal fun getMinutes(): Int = definedExternally
    internal fun setMinutes(min: Int, sec: Int = definedExternally, ms: Int = definedExternally): Unit =
        definedExternally

    internal fun getSeconds(): Int = definedExternally
    internal fun setSeconds(sec: Int, ms: Int = definedExternally): Unit = definedExternally

    internal fun getMilliseconds(): Int = definedExternally
    internal fun setMilliseconds(ms: Int): Unit = definedExternally

    internal fun getDay(): Int = definedExternally

    internal fun getTimezoneOffset(): Int = definedExternally

    fun getUTCDate(): Int = definedExternally
    fun setUTCDate(value: Int): Unit = definedExternally

    fun getUTCDay(): Int = definedExternally

    fun getUTCFullYear(): Int = definedExternally
    fun setUTCFullYear(value: Int): Unit = definedExternally

    fun getUTCHours(): Int = definedExternally
    fun setUTCHours(value: Int): Unit = definedExternally

    fun getUTCMilliseconds(): Int = definedExternally
    fun setUTCMilliseconds(value: Int): Unit = definedExternally

    fun getUTCMinutes(): Int = definedExternally
    fun setUTCMinutes(value: Int): Unit = definedExternally

    fun getUTCMonth(): Int = definedExternally
    fun setUTCMonth(value: Int): Unit = definedExternally

    fun getUTCSeconds(): Int = definedExternally
    fun setUTCSeconds(secondsValue: Int, msValue: Int = definedExternally): Unit = definedExternally

    companion object

}

var Date.time: Long
    get() = getTime().toLong()
    set(it) = setTime(it.toDouble())

var Date.month: Int
    get() = getMonth()
    set(it) = setMonth(it)

var Date.year: Int
    get() = getFullYear()
    set(it) = setFullYear(it)

var Date.date: Int
    get() = getDate()
    set(it) = setDate(it)

var Date.hours: Int
    get() = getHours()
    set(it) = setHours(it)

var Date.minutes: Int
    get() = getMinutes()
    set(it) = setMinutes(it)

var Date.seconds: Int
    get() = getSeconds()
    set(it) = setSeconds(it)

var Date.milliseconds: Int
    get() = getMilliseconds()
    set(it) = setMilliseconds(it)

val Date.lastDate: Int
    get() = Date(year, month + 1, 0).getDate()

val Date.day: Int
    get() = getDay()

val Date.timezoneOffset: Int
    get() = getTimezoneOffset()

fun Date.Companion.fromUTC(time: Long) = Date(time)
fun Date.Companion.now() = Date()

inline val Date.Companion.MINUTE: Long
    get() = 60 * 1_000

inline val Date.Companion.HOUR: Long
    get() = 60 * MINUTE

inline val Date.Companion.DAY: Long
    get() = 24 * HOUR

inline val Date.Companion.WEEK: Long
    get() = 7 * DAY

fun Date.Companion.time(value: Long) = Date(value.toDouble())
fun Date.Companion.date(
    year: Int = 1970,
    month: Int = 0,
    day: Int = 1,
    hour: Int = 0,
    minute: Int = 0,
    second: Int = 0,
    millisecond: Int = 0
) = Date(
    year = year,
    month = month,
    day = day,
    hour = hour,
    minute = minute,
    second = second,
    millisecond = millisecond
).apply {
    this@apply.year = year
}

var Date.timeUTC: Long
    get() = getTime().toLong() + getTimezoneOffset() * 60 * 1000
    set(it) = setTime((it - getTimezoneOffset() * 60 * 1000).toDouble())

fun Date(time: Long) = Date(time.toDouble())

/**
 * Начало дня в часовом поясе браузера
 */
val Date.startOfDay: Date
    get() {
        val date = Date.fromUTC(timeUTC)
        date.setHours(0, 0, 0, 0)
        return date
    }

/**
 * Конец дня в часовом поясе браузера
 */
val Date.endOfDay: Date
    get() {
        val date = Date.fromUTC(timeUTC)
        date.setHours(23, 59, 59, 999)
        return date
    }

/**
 * Возвращает имя месяца в предложном падеже. Например "Январе", "Феврале"... "Августе"
 */
fun monthName3(m: Int) = monthNames3[m]

val monthNames3 = arrayOf(
    "Январе",
    "Феврале",
    "Марте",
    "Апреле",
    "Мае",
    "Июне",
    "Июле",
    "Августе",
    "Сентябре",
    "Октябре",
    "Ноябре",
    "Декабре",
)

/**
 * Возвращает имя месяца в родительном падеже. Например "Января", "Февраля"... "Августа"
 */
fun monthName2(m: Int) = monthNames2[m]

val monthNames2 = arrayOf(
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
)

/**
 * Возвращает имя месяца в именительном падеже. Например "Январь", "Февраль"... "Август"
 */
val monthNames = arrayOf(
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
)

val daysInMonth = arrayOf(
    31,//январь
    29,//февраль
    31,//март
    30,//апрель
    31,//май
    30,//июнь
    31,//июль
    31,//август
    30,//сентября
    31,//октябрь
    30,//ноябрь
    31,//декабрь
)

fun monthName(m: Int) = monthNames[m]

val Date.asDateString: String
    get() {
        var s = if (this.date > 9) "${this.date}." else "0${this.date}."
        s += if (this.month >= 9) "${this.month + 1}." else "0${this.month + 1}."
        s += "${this.year}"
        return s
    }

/**
 * Выводит время в формате MM:HH:SS
 */
val Date.asTimeStringWithSecond: String
    get() {
        var s = asTimeString
        s += if (this.seconds + 1 > 9) ":${this.seconds + 1}" else ":0${this.seconds + 1}"
        return s
    }

/**
 * Выводит время в формате MM:HH
 */
val Date.asTimeString: String
    get() {
        var s = if (this.hours > 9) "${this.hours}:" else "0${this.hours}:"
        s += if (this.minutes + 1 > 9) "${this.minutes}" else "0${this.minutes}"
        return s
    }

/**
 * Сравнивает на равенство только дату
 */
fun Date.dateEquels(other: Date) = year == other.year && month == other.month && date == other.date

val Long.asDateUtc
    get() = Date.fromUTC(this)