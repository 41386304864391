package org.tlsys.api

import org.tlsys.AutoLoadAsyncListIterator
import org.tlsys.async2
import org.tlsys.dto.auth.ExPermissionDTO
import org.tlsys.json.jdto

fun ExPermissionManagerService.getAllPermissionIterator(minForLoad: Int, maxPart: Int) = AutoLoadAsyncListIterator<ExPermissionDTO>(minFull = minForLoad, max = maxPart, loader = { max, offset ->
    async2 { getAllPermission(offset = offset.toInt().jdto, max = max.jdto).await() }
})

fun ExPermissionManagerService.getOrCreate(uid: String, owner: String, title: String) = async2 {
    getPermissionByUID(uid = uid.jdto).await() ?: createPermission(uid = uid.jdto, title = title.jdto, owner = owner.jdto).await()
}