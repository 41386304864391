package org.tlsys.security

import kotlinx.browser.document
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.GroupService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.cms.*
import org.tlsys.dto.DTOOperationNotAllow
import org.tlsys.dto.auth.GroupDTO
import org.tlsys.dto.auth.PermissionDTO
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

/**
 * Created by Ермаков Игорь Александрович (email: ) on 20.08.2017.
 *
 * @author Igor Ermakov (igor.yermakov94@yandex.ru)
 * @author Subochev Anton (caffeine.mgn@gmail.com)
 */
class GroupPermissionsFragment(var group: GroupDTO) : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    companion object {
        const val NAME = "Системные"
    }

    private val groupService by Services.byClass(GroupService::class)

    private inner class PermissionLabelControlContainer(permission: PermissionDTO) :
        AbstractComponent<HTMLDivElement>() {
        override val dom: HTMLDivElement = document.createDiv()
        val layout = FlexLayout(dom, direction = FlexLayout.Direction.Row, alignItems = FlexLayout.AlignItems.Center)
        val span = Span(permission.title).addClass(SIMPLE_TEXT).appendTo(layout, grow = 1, shrink = 1)
            .apply { dom.style.textAlign = "left" }
        val content = BigCheckboxNoText().appendTo(layout, grow = 0, shrink = 0)

        init {
            dom.style.padding = 10.px
            addClass(LIST_ITEM_STYLE)

            async2 {
                content.enabled = false
                content.checked = groupDTO.await()!!.permissions.any { it.name === permission.name }
                content.enabled = true

                content.EVENT_CHANGED.on {
                    async2 {
                        val old = !content.checked
                        try {
                            if (content.checked) {
                                groupService.addPermission(group.id.jdto, permission.name.jdto).await()
                            } else {
                                groupService.removePermission(group.id.jdto, permission.name.jdto).await()
                            }
                        } catch (e: DTOOperationNotAllow) {
                            content.setCheckedForce(old)

                            MessageDialog.showError(
                                title = "Ошибка безопасности",
                                message = "Недостаточно прав для изменения доступа.",
                            )
                        }
                    }
                }
            }
        }
    }

    private val scroll = ScrollController(dom)

    private fun addPermissionToView(permission: PermissionDTO) {
        PermissionLabelControlContainer(permission).appendTo(layout, grow = 0, shrink = 0)
    }

    private val groupDTO = groupService.getGroupById(group.id.jdto).promise

    override suspend fun onInit() {
        super.onInit()
        addPermissionToView(PermissionDTO.CREATE_USER)
        addPermissionToView(PermissionDTO.CREATE_GROUP)
        addPermissionToView(PermissionDTO.ADD_USER_TO_GROUP)
        addPermissionToView(PermissionDTO.REMOVE_USER_FROM_GROUP)
        addPermissionToView(PermissionDTO.DELETE_GROUP)
        addPermissionToView(PermissionDTO.DELETE_USER)
        addPermissionToView(PermissionDTO.CHANGE_GROUP_NAME)
        addPermissionToView(PermissionDTO.CHANGE_GROUP_PERMISSION)

        addPermissionToView(PermissionDTO.CREATE_SHOP)
        addPermissionToView(PermissionDTO.CREATE_TERMINAL)

        addPermissionToView(PermissionDTO.EDIT_SHOP_INFO)
        addPermissionToView(PermissionDTO.EDIT_TERMINAL_INFO)

        addPermissionToView(PermissionDTO.REMOVE_SHOP)
        addPermissionToView(PermissionDTO.REMOVE_TERMINAL)

        addPermissionToView(PermissionDTO.CHANGE_PLUGIN_ENABLE_STATE)
        addPermissionToView(PermissionDTO.EDIT_SETTINGS)
    }
}
