package org.tlsys.navigation

import kotlinx.browser.window
import org.tlsys.admin.events.EventElement
import org.tlsys.async
import org.tlsys.ui.Page
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HashChangeEvent
import org.w3c.dom.events.KeyboardEvent

open class HashPageControl(root: Page, val view: PageView) : PageControl("/") {
    override suspend fun updateParams(params: Map<String, String?>) {
        console.info("HashPageControl::updateParams($params)")
        view.current!!.updateParams(params)
    }

    class PageNotFoundException(val pageName: String) : RuntimeException("Can't find page $pageName")

    interface PageView : LayoutLevelManager.LevelContent<HTMLDivElement> {
        suspend fun set(page: Page)
        suspend fun backTo(page: Page) = set(page)
        suspend fun nextTo(page: Page) = set(page)
        val current: Page?

        override fun keyDown(event: KeyboardEvent): Boolean =
            current?.keyDown(event) ?: super.keyDown(event)

        override fun keyUp(event: KeyboardEvent): Boolean =
            current?.keyUp(event) ?: super.keyUp(event)
    }

    private class PagePath(val page: Page, val uri: String)

    private var next: Boolean? = null

    override suspend fun prepare() {
        next = null
    }

    override suspend fun done() {
        if (view.current != currentPage) {
            if (next == false) {
                view.backTo(currentPage)
            } else {
                view.nextTo(currentPage)
            }
        }
    }

    fun before(page: Page): Page? = pages.getOrNull(pages.indexOfFirst { it.page === page } - 1)?.page
    fun after(page: Page): Page? = pages.getOrNull(pages.indexOfFirst { it.page === page } + 1)?.page

    private val pages = ArrayList<PagePath>()
    fun getUrl(page: Page): String? {
        val index = pages.indexOfFirst { it.page === page }

        if (index < 0) {
            return null
        }

        var str = ""
        if (pages.size > 1) {
            for (i in 1..index)
                str += "/" + pages[i].uri
        }
        return str
    }

    override val currentPage: Page
        get() = pages.last().page

    override suspend fun up() {
        pages.removeAt(pages.size - 1)
        if (next == null) {
            next = false
        }
    }

    override suspend fun goto(pageName: String, params: Map<String, String?>): Page {
        if (next == null) {
            next = true
        }
        val page = currentPage.next(pageName) ?: throw PageNotFoundException(pageName)
        page.updateParams(params)
        pages.add(PagePath(page, pageName))
        return page
    }

    private fun excludeHash(url: String) =
        url.indexOf('#').let { if (it < 0) "" else url.substring(it + 1) }.removeSuffix("/")

    private var started = false

    init {
        pages.add(PagePath(root, ""))
    }

    fun resetParams(params: Map<String, String?>, uri: String? = null) {
        val p = HashMap(getParams())
        params.forEach {
            p[it.key] = it.value
        }
        setParams(p, uri)
    }

    fun getParams(): Map<String, String?> {
        val url = fixRelative("${window.location.hash.removePrefix("#")}")
        return getParams(url)
    }

    fun setParams(params: Map<String, String?>, uri: String? = null) {
        go(
            "${uri ?: ""}?" + params.entries.map {
                if (it.value == null) {
                    encodeURIComponent(it.key)
                } else {
                    "${encodeURIComponent(it.key)}=${encodeURIComponent(it.value!!)}"
                }
            }.joinToString("&")
        )
    }

    fun go(path: String) {
        if (path.startsWith("/")) {
            window.location.hash = path
            return
        }

        val url = fixRelative("${window.location.hash.removePrefix("#")}")
        if (path.startsWith("?")) {
            console.info("only update params!")
            console.info("url: ${getURI(url)}")
            console.info("params: $path")
            window.location.hash = getURI(url) + path
            return
        }
        window.location.hash = "$url/$path"
    }

    private val pageChangeListeners = ArrayList<suspend (String, String) -> Unit>()
    val changePageListener = EventElement()

    fun addPageChangeListener(func: suspend (oldUrl: String, newUrl: String) -> Unit) {
        pageChangeListeners += func
    }

    fun start() {
        console.info("HashPageControl::start")
        if (started) {
            throw IllegalStateException("HashPageControl already was started")
        }
        started = true

        window.addEventListener("hashchange", { event ->
            event as HashChangeEvent
            val new = excludeHash(event.newURL)
            var t = fixRelative(new)
            console.info("new path: $t")
            if (new != t) {
                window.location.hash = t
                return@addEventListener
            }
            async {
                console.info("reset path: $new")
                pageChangeListeners.forEach {
                    it(currentPath, t)
                }
                setPath(new)
                changePageListener.dispatch()
            }
        }, false)

        async {
            console.info("HashPageControl::start   #0")
            view.nextTo(currentPage)
            setPath(window.location.hash.removePrefix("#"))
        }
    }
}

fun getURI(path: String): String {
    var p = path.lastIndexOf('/')
    p = path.indexOf('?', maxOf(0, p))
    if (p == -1) {
        return path
    }
    return path.substring(0, p)
}

fun getParams(path: String): Map<String, String?> {
    var p = path.lastIndexOf('/')
    p = path.indexOf('?', maxOf(0, p))
    if (p == -1) {
        return emptyMap()
    }
    return path.substring(p + 1).split('&').map {
        val items = it.split('=', limit = 2)
        items[0] to items.getOrNull(1)?.let { decodeURIComponent(it) }
    }.toMap()
}

fun urlWithParams(path: String, params: Map<String, String?>): String {
    if (params.isEmpty()) {
        return path
    }

    val sb = StringBuilder(path)
    sb.append("?")
    var first = true
    params.forEach {
        if (!first) {
            sb.append("&")
        }
        sb.append(it.key)
        if (it.value != null) {
            sb.append("=").append(encodeURIComponent(it.value!!))
        }
        first = false
    }
    return sb.toString()
}

private external fun encodeURIComponent(str: String): String
private external fun decodeURIComponent(str: String): String
