package org.tlsys.wc

import org.tlsys.goods.DTODictionary
import org.tlsys.json.*

const val WC_SERVICE_INVOCATOR = "WCExtensions"
const val NODE_GOODS_SERVICE = "node_goods_service"
const val NODES_SERVICE = "NODES_SERVICE"
const val MEMBER_REPORT_SERVICE = "MEMBER_REPORT_SERVICE"
const val CLOSE_DOCUMENT_SERVICE = "CLOSE_DOCUMENT_SERVICE"
const val CALC_DOCUMENT_SERVICE = "CALC_DOCUMENT_SERVICE"
const val SCRIPT_ADMIN_SERVICE = "SCRIPT_ADMIN_SERVICE"

enum class DiagramType {
    MEMBER_REPORT,
    CLOSE_DOCUMENT,
    CALC_DOCUMENT
}

//@DefaultConstructor
//class JPair<out FIRST : DTO?, out SECOND : DTO?>(val first: FIRST, val second: SECOND) : DTO {
//    override val DTO_ID: Int
//        get() = Companion.DTO_ID
//
//    companion object : DTOFactory {
//        override val DTO_ID: Int = Objects.calcId("org.tlsys.wc.JPair")
//
//        override fun read(reader: Reader): DTO {
//            return JPair(
//                    first = reader.obj(),
//                    second = reader.obj()
//            )
//        }
//
//        override fun write(obj: DTO, writer: Writer) {
//            obj as JPair<DTO?, DTO?>
//            writer.obj(obj.first)
//            writer.obj(obj.second)
//        }
//    }
//
//    val value: Pair<FIRST, SECOND>
//        get() = first to second
//}
//
//val <A : DTO?, B : DTO?>Pair<A, B>.dto: JPair<A, B>
//    get() = JPair(first, second)

@DefaultConstructor
class Report(val title: List<String>, val data: List<String>) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<Report> {
        override val type: String
            get() = "Report"

        override fun read(node: JsonNode) =
                Report(
                        title = JsonFactory.readArray<JString>(node["title"]!!).map { it.value },
                        data = JsonFactory.readArray<JString>(node["data"]!!).map { it.value }
                )

        override fun write(obj: Report) =
                JsonNode.obj(
                        "title" to JsonFactory.writeArray(obj.title.map { it.jdto }),
                        "data" to JsonFactory.writeArray(obj.title.map { it.jdto })
                )
    }
}

@DefaultConstructor
class DiagramItem(val name: String, val type: DiagramType, val id: String) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DiagramItem> {
        override val type: String
            get() = "DiagramItem"

        override fun read(node: JsonNode) =
                DiagramItem(
                        name = node["name"]!!.string(),
                        type = DiagramType.valueOf(node["type"]!!.string()),
                        id = node["id"]!!.string()
                )

        override fun write(obj: DiagramItem) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "type" to obj.type.name.json,
                        "name" to obj.name.json
                )
    }
}

@DefaultConstructor
class DiagramInfo(val id: String, val name: String, val json: String, val type: DiagramType) : JDTO {

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DiagramInfo> {
        override val type: String
            get() = "DiagramInfo"

        override fun read(node: JsonNode) =
                DiagramInfo(
                        id = node["id"]!!.string(),
                        name = node["name"]!!.string(),
                        type = DiagramType.valueOf(node["type"]!!.string()),
                        json = node["json"]!!.string()
                )

        override fun write(obj: DiagramInfo) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "name" to obj.name.json,
                        "type" to obj.type.name.json,
                        "json" to obj.json.json
                )
    }
}

@DefaultConstructor
class DiagramExecutionDTO(val id: String, val version: String, val start: Long, val end: Long?) : JDTO {

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DiagramExecutionDTO> {
        override val type: String
            get() = "DiagramExecutionDTO"

        override fun read(node: JsonNode) =
                DiagramExecutionDTO(
                        id = node["id"]!!.string(),
                        version = node["version"]!!.string(),
                        start = node["start"]!!.long(),
                        end = node["end"]!!.long()
                )

        override fun write(obj: DiagramExecutionDTO) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "version" to obj.version.json,
                        "start" to obj.start.json,
                        "end" to obj.end?.json
                )


    }
}

@DefaultConstructor
class DiagramStateShortDTO(val id: String, val success: Boolean, val date: Long) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DiagramStateShortDTO> {
        override val type: String
            get() = "DiagramStateShortDTO"

        override fun read(node: JsonNode) =
                DiagramStateShortDTO(
                        id = node["id"]!!.string(),
                        date = node["date"]!!.long(),
                        success = node["success"]!!.boolean()
                )

        override fun write(obj: DiagramStateShortDTO) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "date" to obj.date.json,
                        "success" to obj.success.json
                )
    }
}

@DefaultConstructor
class BlockStateDTO(val blockId: Int, val messages: String?, val exception: List<ScriptMessage>) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = Companion.asDefault

    companion object : JDTOFactory<BlockStateDTO> {
        override val type: String
            get() = "block_state"

        override fun read(node: JsonNode): BlockStateDTO =
                BlockStateDTO(
                        blockId = node["block"]!!.int(),
                        exception = node["exception"]?.let { JsonFactory.readArray<ScriptMessage>(it) }
                                ?: listOf(),
                        messages = node["messages"]?.string()
                )

        override fun write(obj: BlockStateDTO): JsonNode =
                JsonNode.obj()
                        .set("block", obj.blockId.json)
                        .set("exception", JsonFactory.writeArray(obj.exception))
                        .set("messages", obj.messages?.json)

    }
}

@DefaultConstructor
class DiagramStateDTO(val id: String, val success: Boolean, val date: Long, val state: List<BlockStateDTO>, val diagram: String) : JDTO {

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DiagramStateDTO> {
        override val type: String
            get() = "DiagramStateDTO"

        override fun read(node: JsonNode) =
                DiagramStateDTO(
                        id = node["id"]!!.string(),
                        success = node["success"]!!.boolean(),
                        date = node["date"]!!.long(),
                        state = JsonFactory.readArray(node["state"]!!),
                        diagram = node["diagram"]!!.string()
                )

        override fun write(obj: DiagramStateDTO) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "success" to obj.success.json,
                        "date" to obj.date.json,
                        "state" to JsonFactory.writeArray(obj.state),
                        "diagram" to obj.diagram.json
                )
    }
}

@DefaultConstructor
class ParamDTO(val type: Type, val id: Int, val title: String, val canBeEmpty: Boolean) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<ParamDTO> {
        override val type: String
            get() = "node_param"

        override fun read(node: JsonNode): ParamDTO =
                ParamDTO(
                        type = Type.valueOf(node["type"]!!.string()),
                        id = node["id"]!!.int(),
                        title = node["title"]!!.string(),
                        canBeEmpty = node["allowEmpty"]?.boolean() ?: false
                )


        override fun write(obj: ParamDTO): JsonNode =
                JsonNode.obj()
                        .set("type", obj.type.name.json)
                        .set("id", obj.id.json)
                        .set("title", obj.title.json)
                        .set("allowEmpty", obj.canBeEmpty)

    }

    enum class Type {
        INT, DOUBLE, TEXT, SHOP, DURATION, GOODS_DICTIONARY, BOOLEAN,DATE
    }
}