package org.tlsys.node.properties

import org.tlsys.ParamEditor
import org.tlsys.ParamEditorFactory
import org.tlsys.admin.ui.ShopShortSelector
import org.tlsys.wc.ParamDTO
import org.w3c.dom.HTMLDivElement

object ShopPropertyFactory : ParamEditorFactory {
    override fun create(type: String, canBeEmpty: Boolean): ParamEditor<*>? =
        if (type == ParamDTO.Type.SHOP.name) {
            ShopProperty(canBeEmpty)
        } else {
            null
        }
}

class ShopProperty(canBeEmpty: Boolean) : ParamEditor<HTMLDivElement>,
    ShopShortSelector(shopId = null, canBeEmpty = canBeEmpty) {
    override var paramValue: String
        get() = shopId?.toString() ?: ""
        set(value) {
            shopId = value.toLongOrNull()
        }
}
