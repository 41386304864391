package org.tlsys.dto

import org.tlsys.api.RemoteCartService
import org.tlsys.api.SMSManagerService
import org.tlsys.dto.auth.*
import org.tlsys.dto.contacts.ContactDTO
import org.tlsys.dto.contacts.ContactExistException
import org.tlsys.file.RFile
import org.tlsys.json.reg

abstract class DtoSet {
    private var reg = false
    protected open val subSet: List<DtoSet>
        get() = emptyList()

    fun reg() {
        if (reg)
            return
        reg = true
        subSet.forEach {
            it.reg()
        }
        regDto()
    }

    protected abstract fun regDto()
}

object reg_admin_extensionLib : DtoSet() {
    override fun regDto() {
        CompanyDTO.reg()
        GroupDTO.reg()
        ExPermissionDTO.reg()
        ExPermissionDuplicateExceptionDTO.reg()
        ExPermissionNotFoundExceptionDTO.reg()
//        SessionDTO.reg()
        UserDTO.reg()
        UserNotFoundExceptionDTO.reg()
        ContactDTO.reg()
        ContactExistException.reg()
        CardDTO.reg()
        ReferenceDto.reg()
        CardExistDTO.reg()
        RemoteCartService.CartGood.reg()
        FCursor.reg()
        MemberTagDTO.reg()
        MemberDTO.reg()
        AccountDTO.reg()
        HoldRecordDto.reg()
        BalansInfoDTO.reg()
        ProcessDTO.reg()
        SMSManagerService.AccountDTO.reg()
        DTOShop.reg()
        DTOTerminal.reg()
        ServiceNotFoundException.reg()
        TransactionDTO.reg()
        MemberBalans.reg()
        RFile.reg()
    }
}