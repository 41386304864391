package org.tlsys.jobs.api

import org.tlsys.jobs.dto.ExecutionDTO
import org.tlsys.jobs.dto.JobDTO
import org.tlsys.jobs.dto.TaskDTO
import org.tlsys.json.*
import kotlin.js.JsName

interface JobAdminService {
    companion object {
        const val SERVICE = "JobAdminService"
    }

    @JsName("getJobs")
    fun getJobs(): AsyncResult<JList<JobDTO>>

    @JsName("getJob")
    fun getJob(jobId: JString): AsyncResult<JobDTO?>

    @JsName("getTask")
    fun getTask(id: JString, taskName: JString): AsyncResult<TaskDTO?>

    @JsName("getTasks")
    fun getTasks(): AsyncResult<JList<TaskDTO>>

    @JsName("deleteJob")
    fun deleteJob(id: JString): AsyncResult<JUnit>

    @JsName("createJob")
    fun createJob(scriptId: JString, title: JString, taskName: JString, params: JMap<JString, JString>): AsyncResult<JobDTO>

    @JsName("editJob")
    fun editJob(jobId: JString, title: JString,taskName: JString, params: JMap<JString, JString>): AsyncResult<JobDTO?>

    @JsName("getExecutions")
    fun getExecutions(max: JInt, offset: JLong): AsyncResult<JList<ExecutionDTO>>

    @JsName("runJob")
    fun runJob(jobId: JString): AsyncResult<ExecutionDTO?>
}