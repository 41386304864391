package org.tlsys.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import org.tlsys.Phone
import org.tlsys.toPhone

object PhoneSerializer : KSerializer<Phone> {

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("Phone", PrimitiveKind.STRING)

    override fun deserialize(decoder: Decoder): Phone =
        decoder.decodeString().toPhone()

    override fun serialize(encoder: Encoder, value: Phone) {
        encoder.encodeString(value.raw)
    }
}
