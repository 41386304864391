package org.tlsys.login

import kotlinx.browser.document
import org.tlsys.*
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.ui.createSpan
import org.tlsys.ui.on
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.KeyboardEvent
import pw.binom.web.layout.*

class LoginWindow private constructor(val loginFunc: suspend (login: String, password: String) -> String?) :
    TitleDialog("Вход в систему") {

    private val btns = SaveCancelBody(okText = "ВХОД", cancelText = "РЕГИСТРАЦИЯ")
        .appendTo(super.layout, grow = 1, shrink = 1)

    override val layout: FlexLayout<HTMLDivElement>
        get() = btns.componentsLayout

    private val login = EditText(placeHolder = "Телефон")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    private val password = EditText(password = true, placeHolder = "Пароль")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    private val badLoginNameOrPassword = Span("Неверные данные для входа").apply {
        dom.style.apply {
            paddingTop = "10px"
            textAlign = "center"
            fontSize = 12.px
            color = "#d0021b"
            fontFamily = Styles.DEFAULT_TEXT_FONT
        }
    }.appendTo(layout, grow = 0, shrink = 0)

    init {
        document.createSpan("Забыли пароль?")
            .appendTo(super.layout, grow = 0, shrink = 0)
            .addClass(Styles.LINK)
            .addClass(Styles.TEXT_CENTER)
            .also {
                it.style.paddingTop = 30.px
            }
            .on("click") {
                close()
                ReparePasswordWindow.show()
            }

        login.dom.style.marginBottom = 10.px
        dom.style.width = "273px"
        dom.style.height = "260px"
        badLoginNameOrPassword.dom.style.display = "none"
        btns.ok {
            tryLogin()
        }
        btns.cancel {
            close()
            async2 { showReg() }
//            window.location.href = "https://tlsys.ru/#register"
        }
    }

    private fun tryLogin() {
        async2 {
            val errorText = loginFunc(login.text, password.text)
            badLoginNameOrPassword.dom.style.display = if (errorText == null) {
                "none"
            } else {
                ""
            }
            if (errorText != null) {
                badLoginNameOrPassword.text = errorText
            } else {
                close()
            }
        }
    }

    override fun onKeyDown(event: KeyboardEvent) {
        if (event.keyCode == 27 || event.keyCode == 13) {
            tryLogin()
        }
        super.onKeyDown(event)
    }

    companion object {

        private val layoutNavigator by Services.byClass(LayoutNavigator::class)

        suspend fun show(loginFunc: suspend (login: String, password: String) -> String?) {
            val p = layoutNavigator.show(LoginWindow(loginFunc))
            p.await()
        }
    }
}
