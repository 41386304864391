package org.tlsys.admin.cross

import org.tlsys.WGoodElement
import org.tlsys.admin.serialization.*
import pw.binom.rpc.AbstractRemoteService

private object RemoteGoodsService1 : CrossGoodsService, AbstractRemoteService(JsInvoker) {
    override val getByCodes by remote<RList<WGoodElement>>()
}

object RemoteGoodsService {
    suspend fun getByCodes(codes: List<String>, goods: Boolean, groups: Boolean) =
        RemoteGoodsService1.getByCodes(mapOf("codes" to codes.dto(), "goods" to true, "groups" to false))
}
