package org.tlsys.navigation

import org.w3c.dom.HTMLElement
import org.w3c.dom.events.KeyboardEvent
import pw.binom.web.Component

interface Layout2<T : HTMLElement> : Component<T> {
    fun onFocusGot() {
    }

    fun onFocusLost() {
    }

    fun onKeyDown(event: KeyboardEvent) {
    }

    fun onKeyUp(event: KeyboardEvent) {
    }
}
