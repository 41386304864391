package org.tlsys

import kotlinx.serialization.Serializable
import org.tlsys.serialization.DateDurationSerializer
import pw.binom.date.DateTime
import kotlin.jvm.JvmInline
import kotlin.math.ceil
import kotlin.math.floor
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.ExperimentalTime

@OptIn(ExperimentalTime::class)
@Serializable(DateDurationSerializer::class)
@JvmInline
value class DateDuration(private val value: Long) {
    companion object {
        val ZERO = DateDuration(0L)
    }

    init {
        require(value >= 0L)
    }

    operator fun unaryMinus() = DateDuration(-value)
    operator fun compareTo(value: Long): Int =
        when {
            this.value > value -> 1
            this.value < value -> -1
            else -> 0
        }

    operator fun DateTime.minus(milliseconds: Long) =
        DateDuration(value - milliseconds)

    operator fun DateTime.plus(milliseconds: Long) =
        DateDuration(value + milliseconds)

    operator fun minus(second: DateDuration) =
        DateDuration(value - second.value)

    operator fun plus(second: DateDuration) =
        DateDuration(value + second.value)

    val now
        get() = DateTime(DateTime.nowTime + value)

    val inPast
        get() = DateTime(DateTime.nowTime - value)

    val milliseconds: Long
        get() = value

    val asLong: Long
        get() = milliseconds

    val asDuration
        get() = value.milliseconds

    override fun toString(): String =
        asDuration.toString()

    fun toReadableString(): String {
        var total = value
        val day = value / (1000L * 60L * 60L * 24L)

        total -= day * (1000L * 60L * 60L * 24L)
        val h = total / (1000L * 60L * 60L)
        total -= h * (1000L * 60L * 60L)
        val m = total / (1000L * 60L)
        return "${if (day < 99) day.asTwo() else day}:${h.asTwo()}:${m.asTwo()}"
    }

    fun calcOptimalType(allowTypes: Array<TimeType>): TimeType? {
        val list = allowTypes.sortedBy { it.msValue }
        val tt = value.toDouble()
        for (i in list.size - 1 downTo 0) {
            val z = (1.0 * tt) / (1.0 * list[i].msValue.toDouble())

            if (!z.isHasDecimalPart()) {
                // timeType = types[i]
                return list[i]
            }
        }
        return null
    }
}

fun Double.isHasDecimalPart() = floor(this) != ceil(this)

private fun Long.asTwo(): String =
    if (this > 9) {
        toString()
    } else {
        "0$this"
    }

val Long.toDateDuration
    get() = DateDuration(this)

operator fun DateTime.plus(other: DateDuration) =
    DateTime(this.time + other.asLong)

operator fun DateTime.minus(duration: DateDuration) =
    this - duration.milliseconds

operator fun DateTime.minus(milliseconds: Long) =
    DateTime(time - milliseconds)
