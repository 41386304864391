package org.tlsys.templates

import org.tlsys.GatewayUtils
import org.tlsys.Template
import org.tlsys.TemplateType
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.connectWith
import org.tlsys.admin.ui.*
import org.tlsys.ui.*
import org.tlsys.ui.before
import org.w3c.dom.HTMLDivElement
import pw.binom.uuid.nextUuid
import pw.binom.web.layout.*
import kotlin.random.Random

class EmailTemplateDialog(val dto: Template?, val new: Boolean) : TitleDialog("Шаблон электронного письма") {
    private val body = SaveCancelBody(okText = if (new) "Создать" else "Сохранить").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private val name = EditText(placeHolder = "Название шаблона")
        .text(dto?.name ?: "Шаблон письма")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    private val subject = EditText(placeHolder = "Заголовок письма")
        .text(dto?.subject ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    private val text = TextareaInput()
        .text(dto?.body ?: "")
        .withLabel("HTML текст шаблона")
        .appendTo(layout)

    private fun build() = Template(
        id = if (new || dto == null) Random.nextUuid() else dto.id,
        name = name.text,
        type = TemplateType.EMAIL,
        subject = subject.text,
        body = text.content.text,
    )

    private val preview = BaseButton("Предосмотр")
        .appendTo(body.buttonsLayout, grow = 0, shrink = 1)
        .before(body.cancelBtn)

    private var result: Template? = null

    override fun onEscapeClick() {
        super.onEscapeClick()
        result = null
        close()
    }

    init {
        dom.style.apply {
            width = "70%"
            height = "70%"
        }
        MultiValidator(name, subject, text.content)
            .connectWith(preview)
            .connectWith(body.okBtn)

        preview.onClick {
            val firstName = "Сергей"
            val secondName = "Павлович"
            val lastName = "Королёв"

            val html = GatewayUtils.replaceText(
                body = text.content.text,
                message = "Тело сообщения",
                firstName = firstName,
                middleName = secondName,
                lastName = lastName,
            )

            val subjectText = GatewayUtils.replaceText(
                body = subject.text,
                message = "С днем рождения!",
                firstName = firstName,
                middleName = secondName,
                lastName = lastName,
            )

            EmailPreviewDialog.show(
                subject = subjectText,
                body = html,
            )
        }
        body.ok {
            result = build()
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    companion object {
        suspend fun show(dto: Template?, new: Boolean): Template? {
            val d = EmailTemplateDialog(dto = dto, new = new)
            d.show()
            return d.result
        }
    }
}
