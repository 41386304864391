package org.tlsys.dto

import org.tlsys.json.*

class DTOShop(
        val id: Long = 0,
        val title: String = "",
        val address: String = ""
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTOShop> {
        override val type: String
            get() = "DTOShop"

        override fun read(node: JsonNode) = DTOShop(
                id = node["id"]!!.long(),
                title = node["title"]!!.string(),
                address = node["address"]!!.string()
        )

        override fun write(obj: DTOShop) = JsonNode.obj()
                .set("id", obj.id.json)
                .set("title", obj.title.json)
                .set("address", obj.address.json)
    }
}

class DTOTerminal(val id: Long = 0, val name: String = "", val code: String = "") : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTOTerminal> {
        override val type: String
            get() = "DTOTerminal"

        override fun read(node: JsonNode) = DTOTerminal(
                id = node["id"]!!.long(),
                code = node["code"]!!.string(),
                name = node["name"]!!.string()
        )

        override fun write(obj: DTOTerminal) = JsonNode.obj()
                .set("id", obj.id.json)
                .set("code", obj.code.json)
                .set("name", obj.name.json)
    }
}