package org.tlsys

import kotlinx.browser.window
import org.tlsys.admin.core.PageNavigation
import org.tlsys.gui.BreadCrumbs
import org.tlsys.navigation.HashPageControl
import org.tlsys.navigation.urlWithParams
import org.tlsys.ui.Page

class HashPageControlImp(val adminPage: AdminPage, root: Page, view: PageView, val breadCrumbs: BreadCrumbs?) :
    HashPageControl(root, view), PageNavigation {
    override fun go(url: String, params: Map<String, String?>) {
        window.location.hash = "#${urlWithParams(url, params)}"
    }

    override suspend fun goto(pageName: String): Page = goto(pageName, mapOf())

    var bb: BreadCrumbs.PathBuilder? = null
    override suspend fun prepare() {
        super.prepare()
        bb = breadCrumbs?.newPath()
    }

    override suspend fun updateParams(params: Map<String, String?>) {
        super.updateParams(params)
        console.info("HashPageControlImp::updateParams($params)")
        bb = breadCrumbs?.newPath()
        bb?.up()
        bb?.goIn(view.current!!, params)
        bb?.build()
        bb = null
    }

    override suspend fun done() {
        super.done()
        bb?.build()

        var page = currentPage
        while (true) {
            val b = before(page)
            if (b == null || b is DefaultPage) {
                adminPage.navigation.setActive(getUrl(page)?.removePrefix("/"))
                break
            }
            page = b
        }
    }

    override suspend fun up() {
        super.up()
        bb?.up()
    }

    override suspend fun goto(pageName: String, params: Map<String, String?>): Page {
        val r = super.goto(pageName, params)
        bb?.goIn(r, params)
        return r
    }
}
