package org.tlsys.node

import kotlinx.browser.document
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.cms.ui.FloatButton
import org.tlsys.core.map
import org.tlsys.json.jdto
import org.tlsys.node.api.ScriptAdminService
import org.tlsys.node.api.scriptsIterator
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.*

class ScriptsPage : AbstractPage() {
    override suspend fun getTitle(): String = TITLE

    companion object {
        val URI = "scripts"
        val TITLE = "Сценарии"
    }

    val scriptAdminService by Services.byClass(ScriptAdminService::class)
    val tools = ToolsPlane(usingSearch = false).appendTo(contentLayout, grow = 0, shrink = 0)
    private val addBtn = tools.button("Добавить") {
        async2 {
            val script = scriptAdminService.createScript(
                ScriptAddDialog.show(null).await()?.jdto
                    ?: return@async2,
            ).await()
            val item = Item(script)
            list.addFirst(item)
            Effects.createItem(item.dom)
            Effects.blockRow(item.dom)
        }
    }
    private val list = LoadableListView(
        iterator = scriptAdminService.scriptsIterator().map { Item(it) },
        partCount = 30,
    ).appendTo(contentLayout)

    private inner class Item(var script: ScriptDTO) : AbstractComponent<HTMLDivElement>() {
        override val dom: HTMLDivElement = document.createDiv()
        private val layout = FlexLayout(dom, alignItems = FlexLayout.AlignItems.Center)
        private val title = Span().appendTo(layout).addClass(Styles.SIMPLE_TEXT)
        private val delete =
            FloatButton(image = "delete.svg", height = Styles.ICON_SIZE, width = Styles.ICON_SIZE).appendTo(
                layout,
                grow = 0,
                shrink = 0,
            )
        private val refresh = FloatButton(text = "Обновить").appendTo(layout, grow = 0, shrink = 0)
        fun refresh() {
            title.text = script.name
        }

        init {
            title.dom.style.apply {
                textAlign = "left"
                cursor = "default"
            }
            dom.style.minHeight = 50.px
            addClass(Styles.LIST_ITEM_STYLE)
            refresh()
            refresh.onClick {
                ProcessBlocker.block("Обновление сценария") {
                    val ss = scriptAdminService.refreshScript(script.id.jdto).await()
                    if (ss == null) {
                        Effects.removeItem(dom).await()
                        list.remove(this)
                    } else {
                        script = ss
                        refresh()
                        Effects.blockRow(dom)
                    }
                }
            }
            delete.onClick {
                ProcessBlocker.block("Удаление сценария") {
                    scriptAdminService.removeScript(script.id.jdto).await()
                    Effects.removeItem(dom).await()
                    list.remove(this)
                }
            }
        }

        override suspend fun onStart() {
            super.onStart()
            layout.onStart()
        }

        override suspend fun onStop() {
            layout.onStop()
            super.onStop()
        }
    }
}
