package org.tlsys.goods.pages

import org.tlsys.admin.core.Services
import org.tlsys.admin.form.ProtocolValidators
import org.tlsys.admin.ui.*
import org.tlsys.await
import org.tlsys.goods.Charsets
import org.tlsys.goods.DTOImportSettings
import org.tlsys.goods.api.ImportService
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.Page
import org.tlsys.ui.appendTo
import pw.binom.web.layout.*

class GoodsSettings : AbstractPage() {
    override suspend fun getTitle(): String = TITLE

    companion object {
        const val URI = "goods2"
        const val TITLE = "Товары"
    }

    val settingPanel = SettingsPlane().appendTo(contentLayout, grow = 1, shrink = 1)

    /*
        val gotoHistory = settingPanel.add("История изменений", ConfirmButton("Перейти").apply {
            onClick {
                async {
                    pageNavigation.go("/settings/$URI/${GoodsHistoryPage.URI}")
                }
            }
        })
    */
    init {
        settingPanel.add("Авто-импорт")
    }

    val autoImport = settingPanel.add("Загружать товары периодический", BigCheckboxNoText())
    val autoImportPath = settingPanel.add("Источник данных", EditText().textValidator(ProtocolValidators.ALL))
    val charsetSelector = settingPanel.add("Кодировка", Combobox2(Charsets.values().map { it.title }))
    val codeNormalization = settingPanel.add("Нормализация кодов", BigCheckboxNoText())
    val importNow = settingPanel.add("Импортировать сейчас", ConfirmButton("Выполнить"))

    private val importService by Services.byClass(ImportService::class)

    override suspend fun onInit() {
        super.onInit()
        autoImportPath.component.dom.style.apply {
            width = 300.px
        }

        autoImportPath.component.enabled = false
        autoImport.component.enabled = false
        importNow.component.enabled = false

        autoImportPath.component.onValidChange {
            importNow.component.enabled = it
        }

        ProcessBlocker.block("Получение данных") {
            val data = importService.getSettings().await()

            autoImport.component.checked = data.autoImport
            autoImportPath.component.text = data.url ?: ""
            charsetSelector.component.select = Charsets.findByCode(data.charset).ordinal
            codeNormalization.component.checked = data.codeNormalization

            autoImport.component.EVENT_CHANGED.on {
                save()
            }

            autoImportPath.component.eventChange.on {
                if (autoImportPath.component.valid) {
                    save()
                }
            }

            charsetSelector.component.eventChange.on {
                save()
            }
            codeNormalization.component.EVENT_CHANGED.on {
                save()
            }

            autoImportPath.component.enabled = true
            autoImport.component.enabled = true

            importNow.component.onClick {
                val result = ProcessBlocker.block("Импорт товаров из источника ${autoImportPath.component.text}") {
                    importService.importNow(
                        url = autoImportPath.component.text.jdto,
                        charset = Charsets.values()[charsetSelector.component.select].code.jdto,
                        codeNormalization = codeNormalization.component.checked.jdto,
                    ).await()
                }.await()?.value
                if (result == null) {
                    MessageDialog.showInfo("Импорт товаров завершён")
                } else {
                    MessageDialog.showError(result)
                }
            }
        }
    }

    private fun save() = ProcessBlocker.block("Сохранение данных") {
        importService.setSettings(
            DTOImportSettings(
                autoImport = autoImport.component.checked,
                url = autoImportPath.component.text,
                charset = Charsets.values()[charsetSelector.component.select].code,
                codeNormalization = codeNormalization.component.checked,
            ),
        ).await()
    }

    override suspend fun next(path: String): Page? =
        when (path) {
//                GoodsHistoryPage.URI -> GoodsHistoryPage()
            else -> super.next(path)
        }
}
