package org.tlsys.api

import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.serializer
import org.tlsys.ApiUrls
import org.tlsys.Env
import org.tlsys.api.core2.*
import org.tlsys.dto.GenerateReportConfigDto
import org.tlsys.dto.SecurityJournal
import org.tlsys.serialization.MemberExportConfig
import org.tlsys.serialization.internalJson
import org.w3c.xhr.XMLHttpRequest
import pw.binom.date.DateTime
import pw.binom.url.UrlEncoder
import pw.binom.uuid.UUID
import pw.binom.uuid.serialization.UUIDStringSerializer
import pw.binom.uuid.toUUID
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.coroutines.suspendCoroutine

object Core2ApiAccess {

    suspend fun resume(session: String?) {
        suspendCoroutine<Unit> { con ->
            val rr = XMLHttpRequest()
            rr.withCredentials = true
            rr.onreadystatechange = {
                if (rr.readyState == XMLHttpRequest.DONE) {
                    if (rr.status == 200.toShort()) {
                        con.resume(Unit)
                    } else {
                        con.resumeWithException(RuntimeException("Unknown CODE=${rr.status}"))
                    }
                }
            }

            val uri = if (session == null) "" else "?SESSION=${UrlEncoder.pathEncode(session)}"
            rr.open(method = "POST", url = "${Env.core2Url}${Auth.RESUME}$uri", async = true)
            rr.send()
        }
    }

//    val servicePath = when {
//        Env.ns.isEmpty() -> "${window.location.origin}${window.location.pathname}core2"
//        else -> if (Env.ns == "stage") "https://tl2-core2-${Env.ns}-backend.c.binom.pw" else "https://tl2-core2-${Env.ns}-backend.c.tlsys.org"
//    }

    suspend fun generateTransactionReport(
        amountType: GenerateReportConfigDto.AmountType,
        dateStart: DateTime?,
        dateEnd: DateTime?,
    ) = call(
        serviceUrl = Reports.TRANSACTIONS,
        body = GenerateReportConfigDto(
            amountType = amountType,
            dateStart = dateStart,
            dateEnd = dateEnd,
        ),
        bodyClass = GenerateReportConfigDto.serializer(),
        method = "POST",
        response = UUIDStringSerializer,
    )

    suspend fun exportMembers(config: MemberExportConfig) =
        call(
            serviceUrl = Members.EXPORT,
            body = config,
            bodyClass = MemberExportConfig.serializer(),
            response = String.serializer(),
            method = "POST",
        ).toUUID()

    suspend fun getDownloadPath(fileId: UUID) =
        "${Env.core2Url}${Files.DOWNLOAD}$fileId"

    suspend fun getJournal(offset: Long = 0, max: Long, buyer: Long?): SecurityJournal {
        var uri = "${Journal.GET_LIST}?offset=$offset&max=$max"
        if (buyer != null) {
            uri = "$uri&buyer=$buyer"
        }
        return call(
            serviceUrl = uri,
            body = null,
            bodyClass = Unit.serializer(),
            response = SecurityJournal.serializer(),
            method = "GET",
        )
    }

    suspend fun <BODY : Any, RESPONSE : Any> call(
        method: String,
        serviceUrl: String,
        bodyClass: KSerializer<BODY>,
        body: BODY?,
        response: KSerializer<RESPONSE>,
    ) =
        suspendCoroutine<RESPONSE> { con ->
            val rr = XMLHttpRequest()
            rr.withCredentials = true

            rr.onreadystatechange = {
                if (rr.readyState == XMLHttpRequest.DONE) {
                    when (rr.status) {
                        204.toShort(), 202.toShort(), 201.toShort() -> if (response === Unit.serializer()) {
                            con.resume(Unit as RESPONSE)
                        } else {
                            con.resumeWithException(
                                IllegalArgumentException("Can't read value of type ${response.descriptor.serialName}"),
                            )
                        }

                        200.toShort() -> if (response === Unit.serializer()) {
                            con.resume(Unit as RESPONSE)
                        } else {
                            con.resume(
                                internalJson.decodeFromString(response, rr.responseText),
                            )
                        }

                        404.toShort() -> throw NotFoundException()
                        else -> con.resumeWithException(RuntimeException("Unknown CODE=${rr.status}"))
                    }
                }
            }

            rr.open(method = method, url = "${Env.core2Url.removeSuffix("/")}$serviceUrl", async = true)
//            rr.setRequestHeader("Content-Type", "application/json; charset=utf-8")
            if (body != null) {
                rr.send(internalJson.encodeToString(bodyClass, body))
            } else {
                rr.send()
            }
        }
}
