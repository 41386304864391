package org.tlsys.admin.ui

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.px
import org.tlsys.ui.addClass
import pw.binom.web.layout.*
import kotlin.js.Promise

class YesNoDialog private constructor(width: Int?, text: String, val f: (Boolean) -> Unit) : AbstractDialog() {
    private val mainLayout = FlexLayout(dom)
    private val body = SaveCancelBody(okText = "Да", cancelText = "Нет").apply {
        mainLayout.add(dom) {
            shrink = 1
            grow = 1
        }
    }

    val textBodyLayout = FlexLayout.div {
        direction = FlexLayout.Direction.Row
        alignItems = FlexLayout.AlignItems.Center
        appendTo(body.componentsLayout, grow = 1, shrink = 1)
    }

    //    val image = (document.createElement("img") as HTMLImageElement).apply {
//        textBodyLayout.add(this) {
//            grow = 0
//            shrink = 0
//        }
//        this.src = js("icon_question_png")
//
//        style.width = "32px"
//        style.height = "32px"
//    }
    val img = Span("help_outline")
        .addClass("material-icons")
        .appendTo(textBodyLayout, grow = 0, shrink = 0)
        .also {
            it.dom.style.fontSize = 50.px
        }

    val text = Span(text).apply {
        dom.style.paddingLeft = "20px"
        dom.classList.add(Styles.SIMPLE_TEXT)
        appendTo(textBodyLayout, grow = 1, shrink = 1)
    }

    override suspend fun onStart() {
        super.onStart()
        body.okBtn.dom.focus()
    }

    init {

        if (width != null) {
            dom.style.width = width.px
        }

        body.okBtn.onClick {
            close()
            f(true)
        }

        body.cancelBtn.onClick {
            cancelClick()
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        close()
        f(false)
    }

    companion object {

        private val layoutNavigator by Services.byClass(LayoutNavigator::class)

        fun show(text: String, width: Int? = null) = Promise<Boolean> { d, c ->
            layoutNavigator.show(
                YesNoDialog(width = width, text = text, f = {
                    d(it)
                })
            )
        }
    }
}
