package org.tlsys.admin

import androidx.compose.runtime.DisposableEffectResult
import androidx.compose.runtime.DisposableEffectScope

val DisposableEffectScope.DoNothing: DisposableEffectResult
    get() = NoDispose

private object NoDispose : DisposableEffectResult {
    override fun dispose() {
    }
}
