package org.tlsys.core.promotion

import org.tlsys.admin.PermanentPromotion
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.Promotion2Service
import org.tlsys.await
import org.tlsys.cms.click
import org.tlsys.ui.*
import org.tlsys.ui.DivComponentWithLayout
import org.w3c.dom.DOMRect
import pw.binom.date.DateTime
import pw.binom.uuid.nextUuid
import pw.binom.web.layout.*
import kotlin.random.Random

class PermanentPromotionFragment : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {

    private val promo2List by Services.listByClass(PermanentPromotionEditor::class)
    private val table = MaterialTable().appendTo(layout, grow = 0, shrink = 1)

    init {
        MaterialTable.Header().also {
            MaterialTable.TextColumn("Статус").appendTo(it).widthPx(100)
            MaterialTable.TextColumn("Название").appendTo(it)
            MaterialTable.TextColumn("Дата начала акции").appendTo(it).widthPx(170)
            MaterialTable.TextColumn("Дата окончания акции").appendTo(it).widthPx(170)
            MaterialTable.TextColumn("Действия").appendTo(it).widthPx(1)
        }.appendTo(table)
    }

    override suspend fun onInit() {
        super.onInit()
        Promotion2Service.getPermanentPromotions(max = 50, offset = 0).map {
            PromoItem2(it).appendTo(table)
        }
    }

    suspend fun addClick(rect: DOMRect) {
        val index = Selector2.select(
            items = promo2List.map { it.type.title },
            x = rect.left,
            y = rect.top,
        ) ?: return
        val rr = promo2List[index].show(record = null, new = true) ?: return
        Promotion2Service.createPermanentPromotion(rr)
        val pi = PromoItem2(rr)
        pi.appendTo(table)
        Effects.createItem(pi.dom).await()
    }

    private inner class PromoItem2(var item: PermanentPromotion) :
        MaterialTable.Row() {
        private val status = MaterialTable.TextColumn().appendTo(this).addClass(Styles.SIMPLE_TEXT)
        private val title = MaterialTable.TextColumn().appendTo(this).addClass(Styles.SIMPLE_TEXT)
        private val dateStart = MaterialTable.TextColumn().appendTo(this).addClass(Styles.SIMPLE_TEXT)
        private val dateDuration = MaterialTable.TextColumn().appendTo(this).addClass(Styles.SIMPLE_TEXT)
        private val actions = MaterialTable.ComponentColumn(ActionPlace()).appendTo(this)
        private fun update() {
            title.text = item.title
            status.text = item.status.title
            dateStart.text = promotionFormat.toString(item.startDate)
            dateDuration.text = item.endDate?.let { promotionFormat.toString(it) } ?: "Бессрочно"
        }

        init {
            addClass(Styles.LIST_ITEM_STYLE)
            actions.component.visibleOnHover(this)
            actions.component.iconBtn(MaterialIcons.EDIT).click {
                val editor = promo2List.find { it.type == item.type } ?: return@click
                val newItem = editor.show(record = item, new = false) ?: return@click
                Promotion2Service.updatePermanentPromotion(newItem)
                item = newItem
                update()
                Effects.blockRow(dom)
            }
            actions.component.iconBtn(MaterialIcons.CONTENT_COPY).click {
                val editor = promo2List.find { it.type == item.type } ?: return@click
                val newItem =
                    editor.show(
                        record = item.copy(id = Random.nextUuid(), createDate = DateTime.now, owner = 0),
                        new = true,
                    )
                        ?: return@click
                Promotion2Service.createPermanentPromotion(newItem)
                val pi = PromoItem2(newItem)
                pi.appendTo(layout = layout, grow = 0, shrink = 0)
                Effects.createItem(pi.dom).await()
            }
            update()
        }
    }
}
