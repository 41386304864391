package org.tlsys.wc

import org.tlsys.json.*

class CloseDocumentResultDTO(
        val member: Long?,
        val bonus: List<MemberAmountAdd>,
        val success: Boolean,
        val positions: List<Position>,
        val screenMessage: String?,
        val documentMessages: List<String>?,
        val state: List<BlockStateDTO>?) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<CloseDocumentResultDTO> {
        override val type: String
            get() = "close_document_result"

        override fun read(node: JsonNode) =
                CloseDocumentResultDTO(
                        bonus = JsonFactory.readArray<MemberAmountAdd>(node["bonus"]!!),
                        positions = JsonFactory.readArray<Position>(node["positions"]!!),
                        member = node["member"]!!.long(),
                        state = node["state"]?.let { JsonFactory.readArray<BlockStateDTO>(it) },
                        success = node["success"]!!.boolean(),
                        screenMessage = node["screen_message"]?.string(),
                        documentMessages = node["document_messages"]?.mapNotNull { it?.string() }
                )

        override fun write(obj: CloseDocumentResultDTO) =
                JsonNode.obj()
                        .set("bonus", JsonFactory.writeArray(obj.bonus))
                        .set("positions", JsonFactory.writeArray(obj.positions))
                        .set("member", obj.member?.json)
                        .set("state", obj.state?.let { JsonFactory.writeArray(it) })
                        .set("success", obj.success.json)
                        .set("screen_message", obj.screenMessage?.json)
                        .set("document_messages", obj.documentMessages?.let { JsonNode.array().putAll(it.map { it.json }) })

    }

    class MemberAmountAdd(val member: Long, val amount: Long, val start: Long, val end: Long?) : JDTO {
        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : JDTOFactory<MemberAmountAdd> {
            override val type: String
                get() = "${CloseDocumentResultDTO.type}_add_amount"

            override fun read(node: JsonNode) =
                    MemberAmountAdd(
                            member = node["member"]!!.long(),
                            amount = node["amount"]!!.long(),
                            start = node["start"]!!.long(),
                            end = node["end"]?.long()
                    )

            override fun write(obj: MemberAmountAdd) =
                    JsonNode.obj()
                            .set("member", obj.member.json)
                            .set("amount", obj.amount.json)
                            .set("start", obj.start.json)
                            .set("end", obj.end?.json)

        }
    }

    class Position(val code: String, val amount: Long, val count: Long, val id: String, val bonus: List<MemberAmountAdd>) : JDTO {
        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : JDTOFactory<Position> {
            override val type: String
                get() = "${CloseDocumentResultDTO.type}_position"

            override fun read(node: JsonNode) =
                    Position(
                            id = node["id"]!!.string(),
                            code = node["code"]!!.string(),
                            amount = node["amount"]!!.long(),
                            count = node["count"]!!.long(),
                            bonus = JsonFactory.readArray(node["bonus"]!!)
                    )

            override fun write(obj: Position): JsonNode =
                    JsonNode.obj()
                            .set("id", obj.id.json)
                            .set("code", obj.code.json)
                            .set("amount", obj.amount.toString().json)
                            .set("count", obj.count.toString().json)
                            .set("bonus", JsonFactory.writeArray(obj.bonus))

        }
    }
}