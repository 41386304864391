package org.tlsys.serialization

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TelegramTestData(
    @SerialName("chat_id")
    val chatId: String,
    val text: String,
)
