package org.tlsys

import org.w3c.dom.Node

fun <E> MutableIterable<E>.removeIf(t: (E) -> Boolean) {
    val it = iterator()
    while (it.hasNext()) {
        val h = it.next()
        if (t(h)) {
            it.remove()
        }
    }
}

fun Node.appendChildFirst(node: Node) {
    val next = firstChild
    if (next == null)
        appendChild(node)
    else
        insertBefore(node, next)
}

fun <T> Iterable<T>.sumByLong(f: (T) -> Long): Long {
    var out = 0L
    forEach { out += f(it) }
    return out
}

inline fun <T> Sequence<T>.sumByLong(selector: (T) -> Long): Long {
    var result = 0L
    iterator().forEach { result += selector(it) }
    return result
}