package org.tlsys.goods

import org.tlsys.json.*

class DTOClassifier(val id: String, val code: String, val name: String) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTOClassifier> {
        override val type: String
            get() = "classifier"

        override fun read(node: JsonNode) =
                DTOClassifier(
                        id = node["id"]!!.string(),
                        name = node["name"]!!.string(),
                        code = node["code"]!!.string()
                )

        override fun write(obj: DTOClassifier): JsonNode =
                JsonNode.obj()
                        .set("id", obj.id.json)
                        .set("name", obj.name.json)
                        .set("code", obj.code.json)
    }
}