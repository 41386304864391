package org.tlsys

import kotlinx.serialization.Serializable

@Serializable
data class GatewaySendResult(val status: Status, val description: String?) {
    enum class Status(val isError: Boolean) {
        OK(isError = false),
        FAIL(isError = true),
        BALANCE(isError = true),
        INVALID_CONFIG(isError = true),
        INVALID_AUTH_DATA(isError = true),
        NOT_FOUND(isError = true),
        GONE(isError = true),
        INVALID_ADDRESS(isError = true),
    }
}
