package org.tlsys.json

class JResponce(val error: Boolean, val obj: JsonNode?) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JResponce> {
        override val type: String
            get() = "JResponce"

        override fun read(node: JsonNode) = JResponce(
                error = node["error"]!!.boolean(),
                obj = node["obj"]
        )

        override fun write(obj: JResponce) =
                JsonNode.obj()
                        .set("error", obj.error.json)
                        .set("obj", obj.obj)

        fun ok(obj: JDTO?) = JResponce(error = false, obj = obj?.let { it.write() })
        fun error(obj: JDTO) = JResponce(error = true, obj = obj.write())
    }
}