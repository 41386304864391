package org.tlsys.admin

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.JsonObject
import pw.binom.date.DateTime
import pw.binom.uuid.UUID

@Serializable
data class PermanentPromotion(
    @Contextual
    val id: UUID,
    val title: String,
    val owner: Long,
    val status: PromotionStatus,
    @Contextual
    @SerialName("create_date")
    val createDate: DateTime,
    @Contextual
    @SerialName("start_date")
    val startDate: DateTime,
    @Contextual
    @SerialName("end_date")
    val endDate: DateTime? = null,

    @SerialName("time_to_start")
    val timeToStart: TimeIntervalEnum,
    val type: PermanentPromotionType,

    @SerialName("config")
    val config: JsonObject,
)
