package org.tlsys.core.promotion.externalScriptPromotion

import org.tlsys.ConfigArgs
import org.tlsys.Env
import org.tlsys.ExternalUiMethods
import org.tlsys.admin.form.SimpleValidator
import org.tlsys.api.Core2ApiAccess
import org.tlsys.api.DEFAULT_SESSION
import org.tlsys.cms.events.EventElement
import org.tlsys.timeout
import pw.binom.url.URI
import pw.binom.url.toURI
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime

@OptIn(ExperimentalTime::class)
class BaseExternalUI(override val baseTimeout: Duration = 5.seconds) : ExternalUIImpl() {

    val validator = SimpleValidator<Boolean>()

    val config: ConfigArgs = js("({})").unsafeCast<ConfigArgs>().apply {
        sessionKey = DEFAULT_SESSION!!.key
        companyId = DEFAULT_SESSION!!.company.id.toInt()
        userId = DEFAULT_SESSION!!.user.id.toInt()
        apiPath = Env.core2Url.removeSuffix("/")
    }

    override suspend fun calling(method: ExternalUiMethods, args: Any?): Any? =
        when (method) {
            ExternalUiMethods.YES_NO_DIALOG -> {
                val param = args.unsafeCast<DialogArgs?>() ?: throw IllegalArgumentException("args is null")
                ExternalYesNoDialog.show(
                    title = param.title ?: throw IllegalArgumentException("title is null"),
                    width = param.width ?: throw IllegalArgumentException("width is null"),
                    height = param.height ?: throw IllegalArgumentException("height is null"),
                    url = param.url?.toURI() ?: throw IllegalArgumentException("url is null"),
                    data = param.data,
                )
            }

            ExternalUiMethods.ON_CANCEL_DIALOG -> {
                val param = args.unsafeCast<DialogArgs?>() ?: throw IllegalArgumentException("args is null")
                ExternalOkCancelDialog.show(
                    title = param.title ?: throw IllegalArgumentException("title is null"),
                    width = param.width ?: throw IllegalArgumentException("width is null"),
                    height = param.height ?: throw IllegalArgumentException("height is null"),
                    url = param.url?.toURI() ?: throw IllegalArgumentException("url is null"),
                    data = param.data,
                )
            }

            ExternalUiMethods.OK_DIALOG -> {
                val param = args.unsafeCast<DialogArgs?>() ?: throw IllegalArgumentException("args is null")
                ExternalOkDialog.show(
                    title = param.title ?: throw IllegalArgumentException("title is null"),
                    width = param.width ?: throw IllegalArgumentException("width is null"),
                    height = param.height ?: throw IllegalArgumentException("height is null"),
                    url = param.url?.toURI() ?: throw IllegalArgumentException("url is null"),
                    data = param.data,
                )
            }

            ExternalUiMethods.VALID_UPDATE,
            ExternalUiMethods.START,
            ExternalUiMethods.STOP,
            ExternalUiMethods.OK_CLICK,
            ExternalUiMethods.YES_CLICK,
            ExternalUiMethods.NO_CLICK,
            ExternalUiMethods.CANCEL_CLICK,
            ExternalUiMethods.CONFIG,
            -> throw IllegalStateException()

            ExternalUiMethods.GET_STATE -> {
                console.info("TL: returning state", state)
                state
            }

            ExternalUiMethods.SET_STATE -> {
                state = args?.asDynamic()
                console.info("TL: set state", args)
                true
            }
        }

    private var state: dynamic = null

    override suspend fun event(method: ExternalUiMethods, args: Any?) {
        when (method) {
            ExternalUiMethods.VALID_UPDATE -> validator.updateValue(
                args as? Boolean ?: throw IllegalArgumentException(),
            )

            ExternalUiMethods.START,
            ExternalUiMethods.STOP,
            ExternalUiMethods.YES_NO_DIALOG,
            ExternalUiMethods.ON_CANCEL_DIALOG,
            ExternalUiMethods.OK_DIALOG,
            ExternalUiMethods.OK_CLICK,
            ExternalUiMethods.YES_CLICK,
            ExternalUiMethods.NO_CLICK,
            ExternalUiMethods.CANCEL_CLICK,
            ExternalUiMethods.GET_STATE,
            ExternalUiMethods.SET_STATE,
            ExternalUiMethods.CONFIG,
            -> throw IllegalStateException()
        }
    }

    @OptIn(ExperimentalTime::class)
    override suspend fun afterInit(): Boolean {
        super.afterInit()
        return try {
            console.info("try config iframe")
            timeout(baseTimeout) {
                call(ExternalUiMethods.CONFIG, config)
            }
            console.info("config complite")
            true
        } catch (e: dynamic) {
            console.info("config not work")
            false
        }
    }

    val event_Error = EventElement()

    override suspend fun onStart() {
        try {
            super.onStart()
//            timeout(baseTimeout) {
//                call(ExternalUiMethods.START, null)
//            }
        } catch (e: dynamic) {
            event_Error.dispatch()
        }
    }

    override suspend fun onStop() {
        try {
            super.onStop()
        } catch (e: dynamic) {
            // Do nothing
        }
    }

    override suspend fun loadPage(url: URI): Boolean {
        validator.forceSetValid(false)
        return super.loadPage(url)
    }

    override suspend fun stop() {
        validator.forceSetValid(false)
        super.stop()
    }
}
