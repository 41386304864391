package org.tlsys

import libs.Date
import libs.now
import libs.time
import kotlin.js.Promise

class LocalCache<K, V>(val ttl: Long, val getter: suspend (K) -> Promise<V?>) {
    inner class Item(val eof: Long, val user: Promise<V?>)

    private val data = HashMap<K, Item>()

    suspend fun get(key: K): V? {
        var item = data[key]?.takeIf { it.eof > Date.now().time }
        if (item == null) {
            item = Item(eof = Date.now().time + ttl, user = getter(key))
            data[key] = item
        }
        return item.user.await()
    }
}