package org.tlsys

import kotlinx.browser.document
import org.tlsys.admin.ui.AbstractPage
import org.tlsys.css.animate
import org.tlsys.navigation.HashPageControl
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.*

class PageView : AbstractComponent<HTMLDivElement>(), HashPageControl.PageView {
    override val dom: HTMLDivElement = document.createDiv()
    override val current: Page?
        get() = page

    override suspend fun set(page: Page) {
        backTo(page)
    }

    override suspend fun backTo(page: Page) {
        val oldPage = this.page
        oldPage?.onStop()
        this.page!!.dom.animate {
            duration = 200
            function = "ease-in"
            init {
                position = "absolute"
                top = 0.px

                height = "${this@PageView.dom.offsetHeight}px"
                width = "${this@PageView.dom.offsetWidth}px"
            }
            0 {
                left = 0.px
                opacity = 1.0
            }
            100 {
                left = 50.px
                opacity = 0.0
            }
            onEnd {
                pageViewLayout.remove(parent)
            }
        }.start()

        this.page = page
        pageViewLayout.add(page.dom) {
            grow = 1
            shrink = 1
        }

        this.page?.onStart()
        this.page?.dom?.animate {
            duration = 200
            function = "ease-out"
            init {
                position = "absolute"
                top = 0.px
                height = "${this@PageView.dom.offsetHeight}px"
                width = "${this@PageView.dom.offsetWidth}px"
            }
            start {
                left = "-50px"
                opacity = 0.0
            }
            end {
                left = 0.px
                opacity = 1.0
            }
        }?.start()
    }

    override suspend fun nextTo(page: Page) {
        val oldPage = this.page
        oldPage?.onStop()
        this.page?.dom?.animate {
            function = "ease-in"
            duration = PAGE_CHANGE_ANIMATION_CHANGE / 2
            init {
                position = "absolute"
                top = 0.px

                height = "${this@PageView.dom.offsetHeight}px"
                width = "${this@PageView.dom.offsetWidth}px"
            }
            start {
                left = 0.px
                opacity = 1.0
            }
            end {
                left = "-50px"
                opacity = 0.0
            }
            onEnd {
                pageViewLayout.remove(oldPage!!.dom)
            }
        }?.start()

        this.page = page
        page.dom.style.left
        pageViewLayout.add(page.dom) {
            grow = 1
            shrink = 1
        }
        page.onStart()

        this.page?.dom?.animate {
            function = "ease-out"
            duration = PAGE_CHANGE_ANIMATION_CHANGE / 2
            init {
                position = "absolute"
                top = 0.px
                height = "${this@PageView.dom.offsetHeight}px"
                width = "${this@PageView.dom.offsetWidth}px"
            }
            start {
                left = 50.px
                opacity = 0.0
            }
            end {
                left = 0.px
                opacity = 1.0
            }
        }?.start()
    }

    var page: Page? = null

    val pageViewLayout = FlexLayout(dom).apply {
        direction = FlexLayout.Direction.Column
    }

    fun set(page: AbstractPage) = async2 {
        (this.page)?.onStop()
        pageViewLayout.remove(this.page!!.dom)
        this.page = page
        pageViewLayout.add(page.dom) {
            grow = 1
            shrink = 1
        }
        page.onStart()
    }
}
