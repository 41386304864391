package org.tlsys

const val ADMIN_INVOKATOR = "ADMIN_INVOKATOR"
const val SHOP_SERVICE = "SHOP_SERVICE"
const val CART_SERVICE = "CART_SERVICE"
const val USER_SERVICE = "USER_SERVICE"
const val CARD_SERVICE = "CARD_SERVICE"
const val REMOTE_COMPANY_SERVICE = "REMOTE_COMPANY_SERVICE"
const val MEMBERS_SERVICE = "MEMBERS_SERVICE"
const val GROUPS_SERVICE = "GROUPS_SERVICE"
const val TRANSACTIONS_SERVICE = "TRANSACTIONS_SERVICE"
const val CONTRACTS_SERVICE = "CONTRACTS_SERVICE"
const val ACCOUNTS_SERVICE = "ACCOUNTS_SERVICE"
const val PROCESS_SERVICE = "PROCESS_SERVICE"
const val MEMBERTAGS_SERVICE = "MEMBERTAGS_SERVICE"
const val EXPERMISSIONMANAGERSERVICE_SERVICE = "EXPERMISSIONMANAGERSERVICE_SERVICE"