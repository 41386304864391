package org.tlsys.frontol

import org.tlsys.dto.DtoSet
import org.tlsys.dto.reg_admin_extensionLib
import org.tlsys.frontol.dto.ProcessingConfig
import org.tlsys.json.reg

const val PROCESSING_SERVICE_INVOCATOR = "Processing Admin Extension"
const val CONFIG_SERVICE = "ConfigService"

object processing_dto_set: DtoSet(){
    override fun regDto() {
        ProcessingConfig.reg()
    }

    override val subSet: List<DtoSet> = listOf(reg_admin_extensionLib)
}