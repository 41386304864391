package org.tlsys.json

import kotlin.js.Json as KJson

@Suppress("UNUSED_PARAMETER")
private inline fun deleteProperty(obj: Any, property: Any) {
    js("delete obj[property]")
}

actual interface JsonNode : Iterable<JsonNode?> {
    actual operator fun get(name: String): JsonNode?
    actual operator fun get(index: Int): JsonNode?
    actual fun string(): String
    actual fun int(): Int
    actual fun boolean(): Boolean
    actual fun json(): String
    actual val isArray: Boolean
    actual val isObject: Boolean

    actual companion object {
        actual fun parse(text: String): JsonNode =
                JsonNodeImp(JSON.parse<Any?>(text))

        actual fun string(value: String): JsonNode = JsonNodeImp(value)

        actual fun int(value: Int): JsonNode = JsonNodeImp(value)
        actual fun boolean(value: Boolean): JsonNode = JsonNodeImp(value)
        actual fun nullValue(): JsonNode = JsonNodeImp(null)
        actual fun obj(): JsonNode =
                JsonNodeImp(js("({})"))

        actual fun array(): JsonNode =
                JsonNodeImp(js("([])"))
    }

    actual fun put(node: JsonNode?): JsonNode
    actual operator fun set(name: String, node: JsonNode?): JsonNode
    actual fun putAll(nodes: List<JsonNode>): JsonNode
    actual val size: Int
    actual fun fields(): Array<String>
}

private class JsonNodeImp(val obj: dynamic) : JsonNode {
    override fun fields(): Array<String> = objectKeys(obj)

    init {
        if (obj == null)
            throw NullPointerException("Json Node is NULL")
    }

    override val size: Int
        get() = if (isArray) obj.length else throw IllegalStateException("Json object is not array")

    override fun putAll(nodes: List<JsonNode>): JsonNode {
        if (!isArray)
            TODO()
        nodes.forEach {
            this.obj.push((it as JsonNodeImp).obj)
        }
        return this
    }

    override fun put(node: JsonNode?): JsonNode {
        if (!isArray)
            TODO()
        this.obj.push((node as JsonNodeImp?)?.obj)
        return this
    }

    override fun set(name: String, node: JsonNode?): JsonNode {
        if (node == null) {
            deleteProperty(obj, name)
        } else {
            obj[name] = (node as JsonNodeImp?)?.obj
        }
        return this
    }

    override fun get(name: String): JsonNode? {
        val o = obj[name] ?: return null
        return JsonNodeImp(o)
    }

    override fun get(index: Int): JsonNode? {
        if (!isArray)
            TODO()
        val o = obj[index] ?: return null
        return JsonNodeImp(o)
    }

    override fun string() = obj.toString()

    override fun int() = obj as Int

    override fun boolean() = obj as Boolean

    override fun json(): String = JSON.stringify(obj)

    override val isArray: Boolean
        get() = obj is Array<*>

    override val isObject: Boolean
        get() = jsTypeOf(obj) == "object"

    private var iterator: Iterator<JsonNode?>? = null

    override fun iterator(): Iterator<JsonNode?> {
        if (!isArray)
            TODO()
        iterator = (obj.unsafeCast<Array<*>>()).map<Any?, JsonNode?> {
            if (it == null)
                null
            else
                JsonNodeImp(it)
        }.iterator()
        return iterator!!
    }

}