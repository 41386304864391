package org.tlsys.members

import libs.Date
import libs.now
import libs.year
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.await
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class AddMemberDialog : TitleDialog("Новый клиент") {
    init {
        dom.style.width = "460px"
    }

    private var _canceled: Boolean = true

    val canceled: Boolean
        get() = _canceled

    private val body = SaveCancelBody().appendTo(super.layout).also {
        it.componentsLayout.direction = FlexLayout.Direction.Row
        it.componentsLayout.wrap = FlexLayout.Wrap.Enable
    }
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    val lastName = EditText(placeHolder = "Фамилия")
        .appendTo(layout, grow = 0, shrink = 0)

    val firstName = EditText(placeHolder = "Имя")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)

    val middleName = EditText(placeHolder = "Отчество")
        .appendTo(layout, grow = 0, shrink = 0)

    /*
        val sex_birthday_group = FlexLayout.div {
            direction = FlexLayout.Direction.Row
            alignItems = FlexLayout.AlignItems.Center
            body.componentsLayout.add(parent)
        }
    */
    private val sexGroup = RadioBox.Group().validator(
        Validator {
            if (it.selected == null) {
                invalid("Пол не выбран")
            } else {
                valid()
            }
        },
    )
    private val sexMGroup = ElementGroup("Пол").appendTo(layout, grow = 0, shrink = 0)
    private val sexMale = RadioBox("Мужской").appendTo(sexMGroup.layout).group(sexGroup)
    private val sexFamale = RadioBox("Женский").appendTo(sexMGroup.layout).group(sexGroup).checked()
    val isMale
        get() = sexMale.checked

    /*
    val sex = LabelControlContener("Пол", BigCheckbox("Мужской", "Женский")).apply {
        sex_birthday_group.add(dom) {
            basis = 152
        }
    }
    */
    val birthDayInput = DateEditText(date = null, allowEmpty = true, placeHolder = "Дата рождения")
        .dateValidator(DateValidator.BEFORE_TODAY and DateValidator.before(Date.now().apply { year -= 120 }))
        .appendTo(layout, grow = 1, shrink = 1)

    val phone = EditText(placeHolder = "Телефон")
        .textValidator(TextValidators.IS_PHONE or TextValidators.BLANK)
        .appendTo(layout)

    private val validator = MultiValidator(firstName, middleName, lastName, birthDayInput, phone, sexGroup)

    /*
        val birthday = LabelControlContener("Дата рождения", FlexLayout.layout {


            this.add(birthDayInput.dom) {
                grow = 1
            }
            add(FloatButton(image = "delete.svg", width = ICON_SIZE, height = ICON_SIZE).also {
                it.dom.style.margin = "5px 0px 0px 5px"
                this@AddMemberDialog.dom.appendChild(it.dom)
                it.onClick {
                    birthDayInput.date = null
                }
            }.dom) {
                grow = 0
                shrink = 0
            }
        }).apply {
            sex_birthday_group.add(dom) {
                grow = 1
            }
            dom.style.marginLeft = "26px"
        }
    */
//    private fun refreshStatus() {
//        body.okBtn.enabled =
//                firstName.isValid &&
//                        middleName.isValid &&
//                        lastName.isValid &&
//                        birthDayInput.valid
//    }

    init {

//        val doRefresh: (Boolean) -> Unit = {
//            refreshStatus()
//        }

//        firstName.eventValidChange.on(doRefresh)
//
//        middleName.eventValidChange.on(doRefresh)
//
//        lastName.eventValidChange.on(doRefresh)
//
//        birthDayInput.onValidChange(doRefresh)

        /*
        firstName.content.refreshValidStatus()
        middleName.content.refreshValidStatus()
        lastName.content.refreshValidStatus()
        birthday.content.refreshValidStatus()
        */
//        refreshStatus()

        validator.onValidChange {
            body.okBtn.enabled = it
        }

        body.ok {
            _canceled = false
            close()
        }

        body.cancel {
            cancelClick()
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    companion object {
        val ll by Services.byClass(LayoutNavigator::class)
        suspend fun show(): AddMemberDialog? {
            val d = AddMemberDialog()
            ll.show(d).await()
            if (d.canceled) {
                return null
            }
            return d
        }
    }
}
