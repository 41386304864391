package org.tlsys.node.properties

import org.tlsys.ParamEditor
import org.tlsys.ParamEditorFactory
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.ui.BigCheckboxNoText
import org.tlsys.core.Closeable
import org.tlsys.wc.ParamDTO
import org.w3c.dom.HTMLSpanElement

object BooleanPropertyFactory : ParamEditorFactory {
    override fun create(type: String, canBeEmpty: Boolean) =
        if (type == ParamDTO.Type.BOOLEAN.name) {
            BooleanProperty()
        } else {
            null
        }
}

class BooleanProperty : ParamEditor<HTMLSpanElement>, BigCheckboxNoText() {
    override var paramValue: String
        get() = if (checked) "true" else "false"
        set(value) {
            checked = value == "true"
        }
    override val valid: Boolean
        get() = true

    override fun onValidChange(listener: ValidListener): Closeable {
        listener(true)
        return Closeable { }
    }
}
