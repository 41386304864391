package org.tlsys

import kotlinx.serialization.Polymorphic
import kotlinx.serialization.Serializable

@Polymorphic
@Serializable
class OSession(
    val user: OUser,
    val company: OCompany,
    val group: List<OGroup>,
    val key: String,
    val exPermissions: List<OExPermission>,
    val permissions: List<OPermission>,
) : O() {
    fun isAllow(permission: OPermission) =
        if (user.name.startsWith("admin")) true else group.find { permission in it.permissions } != null

    fun isAllow(uuid: String) = if (user.name.startsWith("admin")) true else exPermissions.any { it.uid == uuid }
}

@Polymorphic
@Serializable
class OVerify(
    val error: String?,
    val requestId: String?,
    val phone: String?,
) : O()

@Polymorphic
@Serializable
class OVerifyResponse(
    val error: String?,
    val session: OSession?,
) : O()

@Serializable
class OUser(val id: Long, val name: String, val companyId: Long, val login: String)

@Serializable
data class OCompany(
    val id: Long,
    val name: String,
    val cartEnabled: Boolean,
    val osmiCardEnabled: Boolean,
    val cardPrEnabled: Boolean,
)

@Serializable
class OGroup(val id: Long, val name: String, val companyId: Long, val permissions: List<OPermission>)

@Serializable
class OExPermission(val id: Long, val uid: String, val title: String, val owner: String)

object AuthNetworkConsts {
    const val CALL = "/aapi/call/"
    const val CALL2 = "/aapi/call2/"

    /**
     * Вход
     */
    const val LOGIN = "/aapi/auth/login"

    /**
     * Выход
     */
    const val LOGOUT = "/aapi/auth/logout"

    /**
     * Восстановление сессии
     */
    const val RESUME = "/aapi/auth/resume"

    /**
     * Запрос создания новой компании
     */
    const val REGISTRATION = "/aapi/auth/registration"

    /**
     * Переотправка пароля для регистрации
     */
    const val REGISTRATION_RESEND = "/aapi/auth/registration_resend"
    const val REGISTRATION_END = "/aapi/auth/registration_end"

    /**
     * Сбрасывает пароль
     */
    const val RESET_PASSWORD_END = "/aapi/auth/reset_password_end"
    const val RESET_PASSWORD_RESEND = "/aapi/auth/reset_password_resend"

    /**
     * Создаёт запрос на восстановление пароля
     */
    const val RESET_PASSWORD_REQUEST = "/aapi/auth/reset_password"
//    const val VERIFYREPAREPASSWORD="auth/verifyreparepassword"
}

@Serializable
enum class OPermission {
    CREATE_USER,
    DELETE_USER,

    ADD_USER_TO_GROUP,
    REMOVE_USER_FROM_GROUP,

    CREATE_GROUP,
    DELETE_GROUP,
    CHANGE_GROUP_PERMISSION,
    CHANGE_GROUP_NAME,

    @Deprecated(message = "Don't use it")
    CHANGE_COMPANY_NAME,

//    @Deprecated(level = DeprecationLevel.ERROR, message = "Устарело. Перенесено в дополнительные права доступа")
//    CREATE_MEMBER,

    CREATE_SHOP,
    EDIT_SHOP_INFO,

    /**
     * Удаление маганизна. Если в магазине есть терминалы, то необходимо разрешение REMOVE_TERMINAL
     */
    REMOVE_SHOP,

    CREATE_TERMINAL,
    EDIT_TERMINAL_INFO,
    REMOVE_TERMINAL,

    /**
     * Работа с плагинами
     */
    CHANGE_PLUGIN_ENABLE_STATE,
    EDIT_SETTINGS,
}
