package org.tlsys.connect

import org.tlsys.json.JRequest
import org.tlsys.json.JResponce
import org.tlsys.json.JsonFactory
import org.tlsys.json.JsonNode
import org.w3c.xhr.XMLHttpRequest
import kotlin.js.Promise

class HTTPConnect(val url: String) : Connection {
    override fun <T> send2(request: JRequest) = Promise<JResponce> { d, c ->
        val rr = XMLHttpRequest()
        rr.withCredentials = true

        rr.onreadystatechange = {
            if (rr.readyState == XMLHttpRequest.DONE) {
                if (rr.status == 200.toShort()) {
                    d(JsonFactory.read<JResponce>(JsonNode.parse(rr.responseText)))
                } else {
                    c(RuntimeException("Unknown CODE=${rr.status}"))
                }
            }
        }

        rr.open(method = "POST", url = "$url/${request.service}/${request.method}", async = true)
        rr.setRequestHeader("Content-Type", "application/json; charset=utf-8")
        rr.send(JsonFactory.write(request).json())
    }
}
