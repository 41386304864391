package org.tlsys

import kotlinx.serialization.decodeFromString
import org.tlsys.admin.core.Services
import org.tlsys.api.Core2ApiAccess
import org.tlsys.api.MUTABLE_DEFAULT_SESSION
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.coroutines.suspendCoroutine

object SessionService {
    suspend fun login(login: String, password: String): OSession {
        val session = suspendCoroutine<OSession> { con ->
            val xhr = XMLHttpRequest()
            xhr.withCredentials = true

            val data = FormData()
            data.append("login", encrypt(login))
            data.append("password", encrypt(password))

            xhr.onreadystatechange = {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (xhr.status == 200.toShort()) {
                        val session = jsonO.decodeFromString<OSession>(xhr.responseText)
                        MUTABLE_DEFAULT_SESSION = session
                        Services.reg(session)
                        con.resume(session)
                    } else {
                        con.resumeWithException(BadLoginOrPassword())
                    }
                }
            }

            xhr.open(method = "POST", url = Env.adminOldBEUri + AuthNetworkConsts.LOGIN, async = true)
            xhr.send(data)
        }
        Core2ApiAccess.resume(session.key)
        return session
    }

    suspend fun registration(phone: String, userName: String, companyName: String, recapchaToken: String): OVerify =
        suspendCoroutine { con ->
            val xhr = XMLHttpRequest()
            xhr.withCredentials = true

            val data = FormData()
            data.append("phone", encrypt(phone))
            data.append("userName", encrypt(userName))
            data.append("companyName", encrypt(companyName))
            data.append("recapchaToken", encrypt(recapchaToken))

            xhr.onreadystatechange = {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (xhr.status == 200.toShort()) {
                        val verify = jsonO.decodeFromString<OVerify>(xhr.responseText)
                        con.resume(verify)
                    } else {
                        con.resumeWithException(RuntimeException())
                    }
                }
            }

            xhr.open(
                method = "POST",
                url = "${Env.adminOldBEUri}${AuthNetworkConsts.REGISTRATION}",
                async = true,
            )
            xhr.send(data)
        }

    suspend fun reparePassword(login: String, recapchaToken: String): OVerify =
        suspendCoroutine { con ->
            val xhr = XMLHttpRequest()
            xhr.withCredentials = true

            val data = FormData()
            data.append("login", encrypt(login))
            data.append("recapchaToken", encrypt(recapchaToken))

            xhr.onreadystatechange = {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (xhr.status == 200.toShort()) {
                        val verify = jsonO.decodeFromString<OVerify>(xhr.responseText)
                        con.resume(verify)
                    } else {
                        con.resumeWithException(RuntimeException())
                    }
                }
            }

            xhr.open(
                method = "POST",
                url = "${Env.adminOldBEUri}${AuthNetworkConsts.RESET_PASSWORD_REQUEST}",
                async = true,
            )
            xhr.send(data)
        }

    /*    suspend fun checkValidRepareKey(requestId: String, code: String, recapchaToken: String): OVerify =
                suspendCoroutine { con ->
                    val xhr = XMLHttpRequest()
                    xhr.withCredentials = true


                    val data = FormData()
                    data.append("requestId", encrypt(requestId))
                    data.append("code", encrypt(code))
                    data.append("recapchaToken", encrypt(recapchaToken))

                    xhr.onreadystatechange = {
                        if (xhr.readyState == XMLHttpRequest.DONE) {
                            if (xhr.status == 200.toShort()) {
                                val verify = jsonO.decodeFromString<OVerify>(xhr.responseText)
                                con.resume(verify)
                            } else {
                                con.resumeWithException(RuntimeException())
                            }
                        }
                    }

                    xhr.open(method = "POST", url = "${Env.serverUrl}auth/checkreparepassword", async = true)
                    xhr.send(data)
                }*/

    suspend fun reparePassword(
        requestId: String,
        code: String,
        password: String,
        recapchaToken: String,
    ): OVerifyResponse =
        suspendCoroutine { con ->
            val xhr = XMLHttpRequest()
            xhr.withCredentials = true

            val data = FormData()
            data.append("requestId", encrypt(requestId))
            data.append("code", encrypt(code))
            data.append("password", encrypt(password))
            data.append("recapchaToken", encrypt(recapchaToken))

            xhr.onreadystatechange = {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (xhr.status == 200.toShort()) {
                        val verify = jsonO.decodeFromString<OVerifyResponse>(xhr.responseText)
                        con.resume(verify)
                    } else {
                        con.resumeWithException(RuntimeException())
                    }
                }
            }

            xhr.open(
                method = "POST",
                url = "${Env.adminOldBEUri}${AuthNetworkConsts.RESET_PASSWORD_END}",
                async = true,
            )
            xhr.send(data)
        }

    /*    suspend fun reparePasswordVerify(requestId: String, code: String, recapchaToken: String): OVerify =
                suspendCoroutine { con ->
                    val xhr = XMLHttpRequest()
                    xhr.withCredentials = true


                    val data = FormData()
                    data.append("requestId", encrypt(requestId))
                    data.append("code", encrypt(code))
                    data.append("recapchaToken", encrypt(recapchaToken))

                    xhr.onreadystatechange = {
                        if (xhr.readyState == XMLHttpRequest.DONE) {
                            if (xhr.status == 200.toShort()) {
                                val verify = jsonO.decodeFromString<OVerify>(xhr.responseText)
                                con.resume(verify)
                            } else {
                                con.resumeWithException(RuntimeException())
                            }
                        }
                    }

                    xhr.open(method = "POST", url = "${Env.serverUrl}${AuthURI.VERIFYREPAREPASSWORD}", async = true)
                    xhr.send(data)
                }*/

    suspend fun registrationResend(requestId: String, recapchaToken: String): OVerify =
        suspendCoroutine { con ->
            val xhr = XMLHttpRequest()
            xhr.withCredentials = true

            val data = FormData()
            data.append("requestId", encrypt(requestId))
            data.append("recapchaToken", encrypt(recapchaToken))

            xhr.onreadystatechange = {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (xhr.status == 200.toShort()) {
                        val verify = jsonO.decodeFromString<OVerify>(xhr.responseText)
                        con.resume(verify)
                    } else {
                        con.resumeWithException(RuntimeException())
                    }
                }
            }

            xhr.open(
                method = "POST",
                url = "${Env.adminOldBEUri}${AuthNetworkConsts.REGISTRATION_RESEND}",
                async = true,
            )
            xhr.send(data)
        }

    suspend fun reparePasswordResend(requestId: String, recapchaToken: String): OVerify =
        suspendCoroutine { con ->
            val xhr = XMLHttpRequest()
            xhr.withCredentials = true

            val data = FormData()
            data.append("requestId", encrypt(requestId))
            data.append("recapchaToken", encrypt(recapchaToken))

            xhr.onreadystatechange = {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (xhr.status == 200.toShort()) {
                        val verify = jsonO.decodeFromString<OVerify>(xhr.responseText)
                        con.resume(verify)
                    } else {
                        con.resumeWithException(RuntimeException())
                    }
                }
            }

            xhr.open(
                method = "POST",
                url = "${Env.adminOldBEUri}${AuthNetworkConsts.RESET_PASSWORD_RESEND}",
                async = true,
            )
            xhr.send(data)
        }

    suspend fun registrationVerify(code: String, requestId: String, recapchaToken: String): OVerifyResponse =
        suspendCoroutine { con ->
            val xhr = XMLHttpRequest()
            xhr.withCredentials = true

            val data = FormData()
            data.append("code", encrypt(code))
            data.append("requestId", encrypt(requestId))
            data.append("recapchaToken", encrypt(recapchaToken))

            xhr.onreadystatechange = {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (xhr.status == 200.toShort()) {
                        val verify = jsonO.decodeFromString<OVerifyResponse>(xhr.responseText)
                        con.resume(verify)
                    } else {
                        con.resumeWithException(RuntimeException())
                    }
                }
            }

            xhr.open(
                method = "POST",
                url = "${Env.adminOldBEUri}${AuthNetworkConsts.REGISTRATION_END}",
                async = true,
            )
            xhr.send(data)
        }

    suspend fun logout() {
        suspendCoroutine<Unit> { con ->
            val xhr = XMLHttpRequest()
            xhr.withCredentials = true

            xhr.onreadystatechange = {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    if (xhr.status == 200.toShort() || xhr.status == 204.toShort()) {
                        con.resume(Unit)
                    } else {
                        con.resumeWithException(BadLoginOrPassword())
                    }
                }
            }

            xhr.open(method = "POST", url = "${Env.adminOldBEUri}${AuthNetworkConsts.LOGOUT}", async = true)
            xhr.send()
        }
    }

    suspend fun resume(): OSession =
        suspendCoroutine { con ->
            val rr = XMLHttpRequest()
            rr.withCredentials = true

            rr.onreadystatechange = {
                if (rr.readyState == XMLHttpRequest.DONE) {
                    if (rr.status == 200.toShort()) {
                        val session = jsonO.decodeFromString<OSession>(rr.responseText)
                        MUTABLE_DEFAULT_SESSION = session
                        Services.reg(session)
                        con.resume(session)
                    } else {
                        con.resumeWithException(SessionBreak())
                    }
                }
            }

            rr.open(method = "POST", url = Env.adminOldBEUri + AuthNetworkConsts.RESUME, async = true)
            rr.send()
        }
}
