package org.tlsys

object WebUtils {
    fun getPhone(value: String): String {
        val value = value.trim()
        val rexexp = "(\\+?7[\\s,-,\\(]*\\d|8[\\s,-,\\(]*9|\\(?9[\\s,-,\\(]*)([\\s,\\-,\\),\\(]*\\d){9}".toRegex()
        if (!value.matches(rexexp)) {
            throw IllegalArgumentException("Illegal phone format: [$value]")
        }
        val txt = "[\\s\\-()]".toRegex().replace(value, "")
        return when {
            txt.startsWith("8") -> "+7${txt.removePrefix("8")}"
            txt.startsWith("7") -> "+$txt"
            else -> txt
        }
    }

    fun phoneFormat(value: String): String {
        val p = getPhone(value)

        val l1 = p.substring(p.length - 2)
        val l2 = p.substring(p.length - 4, p.length - 2)
        val l3 = p.substring(p.length - 7, p.length - 4)
        val l4 = p.substring(p.length - 10, p.length - 7)
        val l5 = p.substring(0, p.length - 10)

        return "$l5-$l4-$l3-$l2-$l1"
    }

    fun phoneMask(value: String): String {
        val p = getPhone(value)

        val l1 = p.substring(p.length - 2)
        val l2 = "**"
        val l3 = "**"
        val l4 = p.substring(p.length - 10, p.length - 9) + "**"
        val l5 = p.substring(0, p.length - 10)

        return "$l5-$l4-$l3-$l2-$l1"
    }
}