package org.tlsys.dto

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import pw.binom.openapi.StdFormat
import pw.binom.openapi.annotation.Format
import pw.binom.uuid.UUID

@Serializable
class ExternalPromotionConfig(
    @Contextual
    @SerialName("external_script_id")
    @Format(StdFormat.UUID)
    val externalScriptId: UUID,
    @Contextual
    @SerialName("promotion_id")
    @Format(StdFormat.UUID)
    val promotionType: UUID,

    @SerialName("promotion_config")
    val promotionConfig: String,
)
