package org.tlsys

import org.w3c.dom.HTMLElement
import pw.binom.web.Component

/**
 * Добавляет класс элементу и возвращает его
 *
 * @param className имя класса, который требуется добавить
 * @return текущий элемент [this]
 */
fun <T : HTMLElement> T.addClass(className: String): T {
    classList.add(className)
    return this
}

inline fun <T : Component<HTMLElement>> T.displayHide(): T {
    dom.displayHide()
    return this
}

inline fun <T : Component<HTMLElement>> T.displayShow(): T {
    dom.displayShow()
    return this
}

fun <T : HTMLElement> T.displayHide(): T {
    if (style.display == "none") {
        return this
    }
    this.asDynamic()["old_display_value"] = style.display
    style.display = "none"
    return this
}

fun <T : HTMLElement> T.displayShow(): T {
    style.display = this.asDynamic()["old_display_value"] ?: ""
    return this
}