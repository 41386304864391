package org.tlsys

import kotlinx.serialization.Serializable
import org.tlsys.serialization.PercentSerializer
import kotlin.jvm.JvmInline
import kotlin.math.roundToLong

/**
 * Хранит проценты. Хранит так же два знака после запятой. Например 100% будет 100_00L
 */
@Serializable(PercentSerializer::class)
@JvmInline
value class Percent(private val value: Long) {
    companion object {
        val ZERO = Percent(0L)
        val FULL = Percent(100_00L)
    }

    operator fun unaryMinus() = Percent(-value)
    operator fun compareTo(value: Long): Int =
        when {
            this.value > value -> 1
            this.value < value -> -1
            else -> 0
        }

    operator fun compareTo(value: Percent): Int =
        when {
            this.value > value.value -> 1
            this.value < value.value -> -1
            else -> 0
        }

    operator fun plus(second: Percent) =
        Percent(value + second.value)

    fun invert(): Percent {
        if (this > FULL) {
            throw IllegalArgumentException("Current value grate than 100%")
        }

        if (this < ZERO) {
            throw IllegalArgumentException("Current value less than 0%")
        }
        return FULL - this
    }

    operator fun minus(percent: Percent): Percent = Percent(value - percent.value)

    /**
     * Возвращает процент умноженный на 100. т.е. 73% будут выглядить как `73_00L`
     */
    val asLong: Long
        get() = value

    val asFloatDivide
        get() = value.toFloat() * 0.0001f

    val asDoubleDivide
        get() = value.toDouble() * 0.0001

    val asFloat
        get() = value.toFloat() * 0.01f

    val asDouble
        get() = value.toFloat() * 0.01

    override fun toString(): String =
        "$asFloat%"
}

val Float.asPercent
    get() = Percent((this * 100f).roundToLong())

val Double.toPercent
    get() = Percent((this * 100f).roundToLong())

val String.asPercent
    get() = Percent((this.removeSuffix("%").toDouble() * 100.0).roundToLong())

val Long.asPercent
    get() = Percent(this)

fun MoneyValue.percentOf(totalSum: MoneyValue): Percent {
    if (asLong == 0L || totalSum.asLong == 0L) return Percent.ZERO
    return (asDouble / totalSum.asDouble * 100.0).toPercent
}

fun Quantity.percentOf(totalSum: Quantity): Percent {
    if (asLong == 0L || totalSum.asLong == 0L) return org.tlsys.Percent.ZERO
    return (asDouble / totalSum.asDouble * 100.0).toPercent
}

@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("sumOfPercent2")
inline fun <T> Iterable<T>.sumOf(selector: (T) -> Percent): Percent = this.sumOfPercent(selector)

@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("sumOfPercent")
inline fun <T> Iterable<T>.sumOfPercent(selector: (T) -> Percent): Percent {
    var sum = 0L
    for (element in this) {
        sum += selector(element).asLong
    }
    return sum.asPercent
}
