package org.tlsys.goods.services

import kotlinx.coroutines.flow.channelFlow
import org.tlsys.AutoLoadAsyncListIterator
import org.tlsys.goods.api.GoodDictionaryService
import org.tlsys.json.jdto
import org.tlsys.makeFlow3

fun GoodDictionaryService.dictionaryFlow(partSize: Int) =
    channelFlow {
        var offset = 0L
        while (true) {
            val list = getDictionaries(offset = offset.jdto, max = partSize.jdto).await()
            offset += list.size
            list.forEach {
                send(it)
            }
        }
    }

fun GoodDictionaryService.dictionaryIterator(minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        getDictionaries(offset = offset.jdto, max = max.jdto).promise
    })

fun GoodDictionaryService.goodsIterator2(dictionaryId: String, filter: String?, minForLoad: Int, maxPart: Int) =
    makeFlow3 { offset ->
        getGoods(
            filter = filter?.jdto,
            dictionaryId = dictionaryId.jdto,
            offset = offset.jdto,
            max = maxPart.jdto,
        ).await()
    }

fun GoodDictionaryService.goodsIterator(dictionaryId: String, filter: String?, minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        getGoods(filter = filter?.jdto, dictionaryId = dictionaryId.jdto, offset = offset.jdto, max = max.jdto).promise
    })

fun GoodDictionaryService.groupIterator(dictionaryId: String, minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        getGroups(dictionaryId = dictionaryId.jdto, offset = offset.jdto, max = max.jdto).promise
    })

fun GoodDictionaryService.classifiersIterator(dictionaryId: String, minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        getClassifiers(dictionaryId = dictionaryId.jdto, offset = offset.jdto, max = max.jdto).promise
    })
