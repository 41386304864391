package org.tlsys.gateway

import org.tlsys.InternalTelegramConfig
import org.tlsys.admin.GatewayProvider
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.EditText
import org.tlsys.admin.ui.text
import org.tlsys.admin.ui.textValidator
import org.tlsys.px
import org.tlsys.serialization.Gateway
import org.tlsys.serialization.internalJson
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import pw.binom.web.layout.*

class TelegramGatewayDialog(new: Boolean, gateway: Gateway?) : GatewayDialog(new = new, gateway = gateway) {
    private val configs =
        gateway?.config?.let { internalJson.decodeFromString(InternalTelegramConfig.serializer(), it) }

    private val token = EditText(placeHolder = "Токен бота")
        .text(configs?.token ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)

    override fun getConfig(): String {
        val config = InternalTelegramConfig(token = token.text)
        return internalJson.encodeToString(InternalTelegramConfig.serializer(), config)
    }

    override fun getGatewayProvider(): GatewayProvider = GatewayProvider.INTERNAL_TELEGRAM
    override suspend fun testClick(gateway: Gateway) {
        TelegramGatewayTestDialog.show(gateway)
    }

    init {
        multiValidator.addValidated(token)
        dom.style.width = 500.px
    }

    companion object {
        suspend fun show(new: Boolean, gateway: Gateway?): Gateway? =
            TelegramGatewayDialog(new = new, gateway = gateway).showAndGetResult()
    }
}
