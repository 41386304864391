package org.tlsys.members

import org.tlsys.admin.MemberListProvider
import org.tlsys.admin.ui.SaveCancelBody
import org.tlsys.admin.ui.TitleDialog
import org.tlsys.admin.ui.withLabel
import org.tlsys.heightPX
import org.tlsys.tags.TagSelector
import org.tlsys.ui.*
import org.tlsys.widthPX
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class FilterDialog(filters: MemberListProvider.Filter) : TitleDialog(title = "Фильтры") {

    private val body = SaveCancelBody(okText = "ПРИМЕНИТЬ").appendTo(super.layout, grow = 1, shrink = 1)

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private var canceled = false
    private val withTags = TagSelector(filters.withTags)
        .withLabel("Включить")
        .appendTo(layout, grow = 1, shrink = 1, basis = 50)
    private val withoutTags = TagSelector(filters.withoutTags)
        .withLabel("Исключить")
        .appendTo(layout, grow = 1, shrink = 1, basis = 50)

    init {
        withTags.element.event_Change.on {
            withoutTags.element.disabled = withTags.element.selectedItems
        }
        withoutTags.element.event_Change.on {
            withTags.element.disabled = withoutTags.element.selectedItems
        }
        withTags.element.event_Change.dispatch()
        withoutTags.element.event_Change.dispatch()
    }

    override suspend fun onStart() {
        super.onStart()
        layout.onStart()
    }

    override suspend fun onStop() {
        super.onStop()
        layout.onStop()
    }

    init {
        dom.style.apply {
            widthPX = 600f
            heightPX = 600f
        }
        body.ok {
            canceled = false
            close()
        }

        body.cancel {
            canceled = true
            close()
        }
    }

    companion object {
        suspend fun show(filters: MemberListProvider.Filter): MemberListProvider.Filter? {
            val dd = FilterDialog(filters)
            dd.show()
            return if (dd.canceled) {
                null
            } else {
                MemberListProvider.Filter(
                    sex = filters.sex,
                    withTags = dd.withTags.element.selectedItems,
                    withoutTags = dd.withoutTags.element.selectedItems,
                )
            }
        }
    }
}
