package org.tlsys.shops

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.admin.ui.*
import org.tlsys.ui.*
import pw.binom.web.layout.*

/**
 * Created by Igor on 05.03.2017.
 */

class AboutDialog private constructor(val code: String) : TitleDialog("Идентификатор терминала") {
    init {
        dom.style.width = "350px"
        dom.style.height = "170px"
    }

    val body = SaveCancelBody(okText = "Скопировать", cancelText = "Закрыть")
        .appendTo(layout, grow = 1, shrink = 1)

    val labelcode = EditText(placeHolder = "Код", readOnly = true).text(code)
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)

    override fun onEscapeClick() {
        super.onEscapeClick()
        close()
    }

    init {

        body.ok {
            labelcode.focus()
            labelcode.select()
            val successful = document.execCommand("copy")
            if (successful) {
                this.close()
            } else {
                window.alert("Копирование не поддерживается браузером, пробуйте вручную")
            }
        }

        body.cancel { this.close() }
    }

    companion object {
        suspend fun show(code: String) {
            AboutDialog(code).show()
        }
    }
}
