package org.tlsys.serialization

import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import pw.binom.date.DateTime

@Serializable
data class MemberExportConfig(
    val includeTags: Collection<Long>,
    val excludeTags: Collection<Long>,
    val cardPrInstalled: Boolean?,
    val osmicardInstalled: Boolean?,
    @Contextual
    val activeAfter: DateTime?,
    @Contextual
    val activeBefore: DateTime?,
    val gender: Boolean?,
    val clientTZ: Int,
)
