package org.tlsys.gateway

import org.tlsys.SMSConfig
import org.tlsys.admin.GatewayProvider
import org.tlsys.admin.form.IntegerValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.or
import org.tlsys.admin.ui.EditText
import org.tlsys.admin.ui.text
import org.tlsys.admin.ui.textValidator
import org.tlsys.serialization.Gateway
import pw.binom.web.layout.appendTo

class MtsConnectGatewayDialog(new: Boolean, gateway: Gateway?) : AbstractSMSDialog(new = new, gateway = gateway) {
    override fun getGatewayProvider(): GatewayProvider = GatewayProvider.MTC_CONNECT

    private val smsAddress = EditText(placeHolder = "Псевдоним отправителя", password = false)
        .text(configs?.mtc?.smsAddress ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)

    private val clazz = EditText(placeHolder = "Класс канала", password = false)
        .text(configs?.mtc?.smsAddress ?: "")
        .textValidator(IntegerValidator.FORMAT or TextValidators.EMPTY)
        .appendTo(layout, grow = 0, shrink = 0)

    override suspend fun testClick(gateway: Gateway) {
        SMSGatewayTestDialog.show(gateway)
    }

    init {
        multiValidator.addValidated(smsAddress)
        multiValidator.addValidated(clazz)
    }

    override fun configOverload(config: SMSConfig): SMSConfig {
        return super.configOverload(config).copy(
            mtc = SMSConfig.MTC(
                clazz = clazz.text.takeIf { it.isNotBlank() }?.toInt(),
                smsAddress = smsAddress.text,
            ),
        )
    }

    companion object {
        suspend fun show(new: Boolean, gateway: Gateway?): Gateway? =
            MtsConnectGatewayDialog(new = new, gateway = gateway).showAndGetResult()
    }
}
