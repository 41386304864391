package org.tlsys.serialization

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import org.tlsys.GatewayEventType
import org.tlsys.admin.GatewayProvider
import org.tlsys.admin.GatewayStatus
import pw.binom.date.DateTime
import pw.binom.uuid.UUID

@Serializable
data class Gateway(
    @Contextual
    val id: UUID,

    val title: String,
    val status: GatewayStatus,
    val provider: GatewayProvider,
    val config: String,

    @SerialName("event_types")
    val eventTypes: Int,

    @Contextual
    @SerialName("create_date")
    val createDate: DateTime,

    @Contextual
    @SerialName("change_date")
    val changeDate: DateTime,

    @SerialName("template_id")
    @Contextual
    val templateId: UUID?,
) {
    @Transient
    val eventTypeSet
        get() = GatewayEventType.uncompress(eventTypes)
}
