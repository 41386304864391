package org.tlsys

import pw.binom.url.toPathMask

object ApiUrls {
    const val JOURNAL_POST = "/capi/internal_api/v1/journal"
    const val JOURNAL_GET = "/capi/v1/journal"
    const val ORDER_PROMOTION_CREATE_POST = "/capi/v1/promotion/order/create"
    const val ORDER_PROMOTION_UPDATE_POST = "/capi/v1/promotion/order/update"
    const val ORDER_PROMOTION_LIST_GET = "/capi/v1/promotion/order/list"

    const val TEMPLATE_LIST_GET = "/capi/v1/templates"
    const val TEMPLATE_BY_ID_GET = "/capi/v1/templates/"
    const val TEMPLATE_UPDATE_POST = "/capi/v1/templates/update"
    const val TEMPLATE_CREATE_POST = "/capi/v1/templates/create"
    const val TEMPLATE_DELETE_POST = "/capi/v1/templates/delete"

    const val PERMANENT_PROMOTION_CREATE_POST = "/capi/v1/promotion/permanent/create"
    const val PERMANENT_PROMOTION_UPDATE_POST = "/capi/v1/promotion/permanent/update"
    const val PERMANENT_PROMOTION_LIST_GET = "/capi/v1/promotion/permanent/list"
    const val PERMANENT_PROMOTION_MANUAL_EXECUTE = "/capi/v1/promotion/permanent/manual_execute"

    const val GATEWAY_CREATE_POST = "/capi/v1/gateway/create"
    const val GATEWAY_UPDATE_POST = "/capi/v1/gateway/update"
    const val GATEWAY_DELETE_POST = "/capi/v1/gateway/delete"
    const val GATEWAY_LIST_GET = "/capi/v1/gateway/list"
    const val GATEWAY_TEST = "/capi/v1/gateway/test"

    val MEMBERS_LIST = "/capi/v1/members/".toPathMask()
    val MEMBER_BY_ID = "/capi/v1/members/{memberId}".toPathMask()
    const val DICTIONARIES_CREATE_POST = "/capi/v1/dictionaries"
    val DICTIONARIES_DELETE_POST = "/capi/v1/dictionaries/{id}".toPathMask()

    const val EXTERNALSCRIPTS_CREATE_POST = "/capi/v1/externalscripts/create"
    const val EXTERNALSCRIPTS_LIST_POST = "/capi/v1/externalscripts/list"
    const val EXTERNALSCRIPTS_MANIFESTLIST_POST = "/capi/v1/externalscripts/manifest_list"
    const val EXTERNALSCRIPTS_UPDATE_POST = "/capi/v1/externalscripts/update"
    const val EXTERNALSCRIPTS_DELETE_POST = "/capi/v1/externalscripts/delete"
    const val EXTERNALSCRIPTS_GET_MANIFEST_POST = "/capi/v1/externalscripts/manifest"

    const val FIND_SNAPSHOT_BY_DOCUMENT_UID = "/capi/v1/snapshots/getByDocumentUid"
    const val FIND_SNAPSHOT_BY_DOCUMENT_ID = "/capi/v1/snapshots/getByDocumentId"
    const val FIND_SNAPSHOT_BY_ID = "/capi/v1/snapshots/getById"
}
