package org.tlsys.cms

import org.tlsys.admin.GlobalMenuItem
import org.tlsys.admin.form.Validated
import org.tlsys.admin.ui.Fragment
import org.tlsys.cms.events.EventElement
import org.w3c.dom.HTMLDivElement
import kotlin.js.Promise
import org.tlsys.cms.search.SearchSource as MembersSearchSource

object Extensions {

    interface AccountExtensionFragment {
        val name: String
        val fragment: (Long) -> Fragment<HTMLDivElement>
    }

    val accountExtensionFragments = Extension<AccountExtensionFragment>()

    /**
     * Расширение глобального меню. Позволяет добовлять новые пункты в глобальное меню
     */
    val globalMenu = Extension<GlobalMenuItem>()

    val membersSearch = Extension<MembersSearchSource>()

    interface AccountProprty {
        /**
         * Название поля
         */
        val title: String

        /**
         * Указывает на возможность редактировать данное поле
         */
        val canEdit: Boolean

        /**
         * Возвращает значение параметра
         */
        fun getValue(accountId: Long): Promise<String>

        /**
         * Инициировать изменение
         */
        fun edit(accountId: Long): Promise<AccountPropertyEdit>
    }

    interface MemberProprty {
        /**
         * Название поля
         */
        val title: String

        /**
         * Указывает на возможность редактировать данное поле
         */
        val canEdit: Boolean

        /**
         * Возвращает значение параметра
         */
        fun getValue(member: Long): Promise<String>

        /**
         * Инициировать изменение
         */
        fun edit(member: Long): Promise<AccountPropertyEdit>
    }

    interface MemberAction {
        val title: String
        fun action(member: Long): Promise<Unit>
    }

    interface AccountPropertyEdit : Validated, Fragment<HTMLDivElement> {

        /**
         * Заголовок фрагмента
         */
        val title: String

        /**
         * Ширена фрагмента
         */
        val width: Int

        /**
         * Высота фрагмента
         */
        val height: Int

        /**
         * Сохраняет измененное значение
         */
        fun save(): Promise<Unit>

        /**
         * Изменение поля было отменино
         */
        fun cancelled()
    }

    /**
     * Расширяет свойства счета: позволяет добавить дополнительные поля
     */
    val accountFieldProperty = Extension<AccountProprty>()

    val memberFieldProperty = Extension<MemberProprty>()
    val memberActionProperty = Extension<MemberAction>()

    /**
     * Вызывается когда админка готова к загрузке плагинов, дополнений и т.п.
     */
    val readyListeners = EventElement()

    /**
     * Добавляет в таблицу дистрибутив
     */
    interface TerminalDistributionItem {
        var title: String
        var iconurl: String
        var onClick: (Long) -> Unit
    }

    val terminalDistributionItem = Extension<TerminalDistributionItem>()
}
