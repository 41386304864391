package org.tlsys.dto.contacts

import org.tlsys.json.*

class ContactDTO(
        val id: String,
        val value: String,
        val type: Type
) : JDTO {
    enum class Type {
        PHONE,
        EMAIL,
        ADDRESS,
        TELEGRAM
    }

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<ContactDTO> {
        override val type: String
            get() = "contact"

        override fun read(node: JsonNode) =
                ContactDTO(
                        id = node["id"]!!.string(),
                        value = node["value"]!!.string(),
                        type = Type.valueOf(node["type"]!!.string())
                )

        override fun write(obj: ContactDTO) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "value" to obj.value.json,
                        "type" to obj.type.name.json
                )

    }
}