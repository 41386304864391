package org.tlsys.admin.ui

import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.tlsys.ui.*
import org.tlsys.core.Closeable
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.tlsys.ui.on
import org.w3c.dom.DataTransfer
import org.w3c.dom.HTMLElement
import org.w3c.files.File
import org.w3c.files.FileList
import org.w3c.files.FileReader
import org.w3c.files.get
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlin.js.*

private val DRAGING_STYLE = CSS.genName()
private val WAIT_FILE = CSS.style {
    border = "1px solid #ddd"
    boxSizing = "border-box"
    transition = "border-width 200ms, background-color 200ms"
    backgroundColor = "#fbfbfb"

    ".$DRAGING_STYLE" then {
        borderWidth = 2.px
        backgroundColor = "#f0f0f0"
    }
}.name

private val UPLOAD_ICON = CSS.style {
    opacity = 0.3
    cursor = "default"
    userSelect = "none"
    display = "inline-block"
    fontSize = 70.px
    transition = "opacity 200ms"
    hover {
        opacity = 0.5
    }
}.name

/**
 * @param fileFilter Фильтр файлов для загрузки. Если все в порядке должен вернуть null. Однако если результат - строка,
 * то это воспринимается как отказ загрузки файла, с последующим выводом сообщения
 */
open class FileUploadPlace(val description: String?, val fileFilter: (DataTransfer) -> String? = { null }) :
    DivComponent() {
    companion object {
        val dragSupported: Boolean
            get() = jsTypeOf(window.asDynamic()["FileReader"]) != "undefined"

        fun forElement(description: String?, dom: HTMLElement, files: (List<File>) -> Unit) {
            val place = FileUploadPlace(description)
            var b = false
            place.dom.style.apply {
                position = "absolute"
                width = "100%"
                height = "100%"
            }

            place.addFileSelectEventListener {
                window.setTimeout({
                    place.dom.remove()
                    files(place.files)
                    b = false
                }, 200)
            }

            place.dom.on("dragleave") {
                console.info("Set timeout")
                window.setTimeout({
                    place.dom.remove()
                    b = false
                }, 200)
            }

            dom.on("dragover") {
                if (!b) {
                    dom.appendChild(place.dom)
                    console.info("Place created!111")
                }
                b = true
            }
        }
    }

    private var fileList = ArrayList<File>()
    val files: List<File>
        get() = fileList

    private val fileListeners = ArrayList<() -> Unit>()

    fun addFileSelectEventListener(f: () -> Unit): Closeable {
        fileListeners += f
        f()
        return Closeable {
            fileListeners -= f
        }
    }

    init {
        if (dragSupported) {

            val div = document.createDiv().apply {
                addClass(Styles.SIMPLE_TEXT)
                dom.appendChild(this)
                style.position = "absolute"
                style.left = "50%"
                style.top = "50%"
                style.transform = "translate(-50%, -50%)"
                style.whiteSpace = "nowrap"
                style.textAlign = "center"
            }

            val cloud = document.createDiv().apply {
                addClass("material-icons")
                addClass(UPLOAD_ICON)
                innerHTML = "cloud_upload"
                div.appendChild(this)
            }
//            if (description != null) {
//                val divDiscription = document.createDiv().apply {
//                    addClass(Styles.SIMPLE_TEXT)
//                    div.appendChild(this)
//                    innerText = description
//                }
//            }
            val divText = document.createDiv().apply {
                addClass(Styles.SIMPLE_TEXT)
                div.appendChild(this)
            }

            fun showGood(files: FileList) {
                divText.innerText = "Загружено файлов: ${files.length}"
            }

            fun showWarning(text: String) {
                divText.innerText = text
            }

            divText.innerText = description?:""
            dom.classList.add(WAIT_FILE)
            dom.ondragover = {
                dom.classList.add(DRAGING_STYLE)
                false
            }
            dom.ondragleave = {
                dom.classList.remove(DRAGING_STYLE)
                false
            }
            dom.ondrop = {
                dom.classList.remove(DRAGING_STYLE)
                val data = it.asDynamic().dataTransfer.unsafeCast<DataTransfer>()
                it.preventDefault()
                it.stopPropagation()


                val message = fileFilter(data)

                if (message != null) {
                    showWarning(message)
                    data.clearData()
                } else {
                    showGood(data.files)
                    fileList.clear()
                    for (i in 0 until data.files.length) {
                        fileList.add(data.files.get(i)!!)
                    }
                    fileListeners.toTypedArray().forEach {
                        it()
                    }
                }
            }
        } else {
            TODO()
        }
    }
}

fun FileList.asArray(): Array<File> = Array<File>(length) { get(it)!! }
fun File.readText() = Promise<String> { d, c ->

    val reader = FileReader()
    reader.onload = {
        d(reader.result)
    }

    reader.onerror = {
        c(RuntimeException("Can't load file"))
    }

    reader.onabort = {
        c(RuntimeException("Loading was aborted"))
    }
    reader.readAsText(this)
}

fun File.readBytes() = Promise<Int8Array> { d, c ->

    val reader = FileReader()
    reader.onload = {
        d(Int8Array(reader.result.unsafeCast<ArrayBuffer>()))
    }

    reader.onerror = {
        c(RuntimeException("Can't load file"))
    }

    reader.onabort = {
        c(RuntimeException("Loading was aborted"))
    }
    reader.readAsArrayBuffer(this)
}

fun File.readBlob() = Promise<Int8Array> { d, c ->

    val reader = FileReader()
    reader.onload = {
        d(Int8Array(reader.result.unsafeCast<ArrayBuffer>()))
    }

    reader.onerror = {
        c(RuntimeException("Can't load file"))
    }

    reader.onabort = {
        c(RuntimeException("Loading was aborted"))
    }
    reader.readAsArrayBuffer(this)
}