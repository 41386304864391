package org.tlsys.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import org.tlsys.Quantity
import org.tlsys.toQuantity

/**
 * @see [QuantitySerializerFloat] for serialize as float
 */
object QuantitySerializerLong : KSerializer<Quantity> {
    override fun deserialize(decoder: Decoder): Quantity =
        Quantity(decoder.decodeLong())

    override val descriptor: SerialDescriptor = Long.serializer().descriptor

    override fun serialize(encoder: Encoder, value: Quantity) {
        encoder.encodeLong(value.asLong)
    }
}

object QuantitySerializerFloat : KSerializer<Quantity> {
    override fun deserialize(decoder: Decoder): Quantity =
        decoder.decodeFloat().toQuantity

    override val descriptor: SerialDescriptor = Float.serializer().descriptor

    override fun serialize(encoder: Encoder, value: Quantity) {
        encoder.encodeFloat(value.asFloat)
    }
}
