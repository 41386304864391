package org.tlsys.animation

import kotlinx.dom.removeClass
import org.tlsys.addClass
import org.tlsys.admin.ui.AbstractDialog
import org.tlsys.async2
import org.tlsys.styles.DialogStyle
import org.tlsys.ui.once
import org.w3c.dom.HTMLElement
import kotlin.js.Promise

interface AnimationDialogTheme {
    fun dialogShow(time: Int, dialog: AbstractDialog): Promise<Unit>
    fun dialogHide(time: Int, dialog: AbstractDialog): Promise<Unit>
}

suspend fun HTMLElement.applyAnimationStyle(animationClass: String) {
    once("animationend") {
        removeClass(animationClass)
    }
    addClass(animationClass)
}

object DefaultAnimationDialogTheme : AnimationDialogTheme {
    override fun dialogHide(time: Int, dialog: AbstractDialog): Promise<Unit> =
        async2 {
            dialog.dom.applyAnimationStyle(DialogStyle.ANIMATION_HIDE)
        }

    override fun dialogShow(time: Int, dialog: AbstractDialog): Promise<Unit> =
        async2 {
            dialog.dom.applyAnimationStyle(DialogStyle.ANIMATION_SHOW)
        }
}
