package org.tlsys.node

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*
import kotlin.js.Promise

class ScriptAddDialog(scriptDTO: ScriptDTO?) : TitleDialog("Новый сценарий") {

    private val body = SaveCancelBody().appendTo(super.layout)

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val path = EditText(placeHolder = "Адрес")
        .text(scriptDTO?.url ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    private var canceled = false

    override suspend fun onStart() {
        super.onStart()
        path.focus()
    }

    init {
        path.eventValidChange.on {
            body.okBtn.enabled = it
        }
        body.ok {
            canceled = false
            close()
        }
        body.cancel {
            canceled = true
            close()
        }
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)

        fun show(scriptDTO: ScriptDTO?) = Promise<String?> { d, c ->
            val dd = ScriptAddDialog(scriptDTO)
            layoutNavigator.show(dd) {
                if (dd.canceled) {
                    d(null)
                } else {
                    d(dd.path.text)
                }
            }
        }
    }
}
