package org.tlsys.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import org.tlsys.JsonObject

object JsonObjectSerializer : KSerializer<JsonObject> {
    override fun deserialize(decoder: Decoder): JsonObject =
        JsonObject.fromString(decoder.decodeString())

    override val descriptor: SerialDescriptor
        get() = String.serializer().descriptor

    override fun serialize(encoder: Encoder, value: JsonObject) {
        encoder.encodeString(value.toString())
    }
}
