package org.tlsys

import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.MaterialTable
import org.tlsys.admin.ui.appendTo
import org.tlsys.admin.ui.widthPx
import org.tlsys.api.*
import org.tlsys.dto.JournalDto
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.date.format.toDatePattern
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

private val dp = "yyyy-MM-dd HH:mm:ss".toDatePattern()

class JournalView(val buyerId: Long?, val userId: Long?) :
    DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    private val table = MaterialTable().appendTo(layout, grow = 0, shrink = 1)
    private val usersService by Services.byClass(UsersService::class)
    private val membersService by Services.byClass(MembersService::class)

    inner class JournalItem(val dto: JournalDto) : MaterialTable.Row() {
        private val msg = MaterialTable.TextColumn(dto.message ?: "").appendTo(this)
        private val buyer =
            if (buyerId == null) {
                MaterialTable.TextColumn(dto.member?.let { "#$it" } ?: "нет").appendTo(this)
            } else {
                null
            }
        private val user =
            if (userId == null) {
                MaterialTable.TextColumn(dto.user?.let { "#$it" } ?: "нет").appendTo(this)
            } else {
                null
            }
        private val date = MaterialTable.TextColumn(dp.toString(dto.date)).appendTo(this)

        init {
            if (user != null) {
                async2 {
                    user.text =
                        dto.user?.let { usersService.getUserById2(it)?.name ?: "Удаленный пользователь #$it" }
                            ?: "нет"
                }
            }
            if (buyer != null) {
                async2 {
                    buyer.text =
                        dto.member?.let { membersService.getMemberById2(it)?.fullName ?: "Удаленный клиент #$it" }
                            ?: "нет"
                }
            }
        }
    }

    private val scroll = ScrollController(dom)

    init {
        MaterialTable.Header().also {
            MaterialTable.TextColumn("Сообщение").appendTo(it)
            if (buyerId == null) {
                MaterialTable.TextColumn("Клиент").appendTo(it)
            }
            if (userId == null) {
                MaterialTable.TextColumn("Пользователь").appendTo(it)
            }
            MaterialTable.TextColumn("Дата").widthPx(150).appendTo(it)
        }.appendTo(table)
    }

    override suspend fun onStart() {
        super.onStart()
        table.clearBody()
        Core2ApiAccess.getJournal(offset = 0, max = 100, buyer = buyerId).records.forEach {
            JournalItem(it).appendTo(table)
        }
    }
}
