package org.tlsys.cms.ui

import kotlinx.browser.document
import org.tlsys.DateDuration
import org.tlsys.TimeType
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.core.Closeable
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.*

infix operator fun Array<TimeType>.minus(ms: TimeType): Array<TimeType> {
    if (ms !in this) {
        return this
    }
    return this.dropWhile { it === ms }.toTypedArray()
}

/**
 * Компонент состоящий из поля ввода и выполающего списка.
 * В поле ввода вводится цифра, в выподающем меню - значение.
 * Компонент заточин на использование для ввода времени длитильности.
 * Например что бы ввести через какой периуд времени бонусы должны сгореть.
 *
 * Значение [time] ВСЕГДА содержит время в милисекундах, вне зависимости от того с каким типом отображения времени сейчас
 * компонент.
 *
 * После создания компонента и установки ему значения требуется вызвать функцию [autoTimeFormat], что бы отображение было
 * более человеко-читаемым.
 *
 * @author Субочев Антон (caffeine.mgn@gmail.com)
 */
open class TimeCountSelector(
    placeHolder: String = "",
    val maxAfterPoint: Int = 2,
    allowTypes: Array<TimeType> = defaultTimeTypes,
    canBeEmpty: Boolean = false,
) : AbstractComponent<HTMLDivElement>(), Validated {
    override val dom: HTMLDivElement = document.createDiv()
    override fun onValidChange(listener: ValidListener): Closeable = input.onValidChange(listener)

    init {
        if (allowTypes.isEmpty()) {
            throw IllegalArgumentException("argument \"allowTypes\" must be not empty")
        }
    }

    companion object {
        /**
         * Массив всех типов времени
         */
        val allTimeTypes =
            arrayOf(TimeType.MS, TimeType.SECONDS, TimeType.MINUTE, TimeType.HOUR, TimeType.DAY, TimeType.WEEK)
        val defaultTimeTypes = arrayOf(TimeType.MINUTE, TimeType.HOUR, TimeType.DAY, TimeType.WEEK)

        private fun sortTimeTypes(types: Array<TimeType>): Array<TimeType> {
            val out = ArrayList<TimeType>(5)
            fun testAdd(t: TimeType) {
                if (t in types) out.add(t)
            }
            testAdd(TimeType.MS)
            testAdd(TimeType.SECONDS)
            testAdd(TimeType.MINUTE)
            testAdd(TimeType.HOUR)
            testAdd(TimeType.DAY)
            testAdd(TimeType.WEEK)
            return out.toTypedArray()
        }

        fun calcOptimalType(time: Long, maxAfterPoint: Int, allowTypes: Array<TimeType>): TimeType? {
            val list = sortTimeTypes(allowTypes)
            val tt = time.toDouble()
            for (i in list.size - 1 downTo 0) {
                val z = (1.0 * tt) / (1.0 * list[i].msValue.toDouble())
                console.info(
                    "->tt: [$tt], item: [${list[i].msValue.toDouble()}], z: [$z] count after zero: [${
                        DoubleValidator.getAfterPoint(
                            z.toString(),
                        )
                    }]",
                )
                if (DoubleValidator.getAfterPoint(z.toString()) <= maxAfterPoint) {
                    // timeType = types[i]
                    return list[i]
                }
            }
            return null
        }
    }

    private val types = sortTimeTypes(allowTypes)

    private var current = 0

    private val layout = FlexLayout(dom, direction = FlexLayout.Direction.Row)

    var canBeEmpty: Boolean = canBeEmpty
        set(value) {
            field = value
            forceRefreshValid()
        }

    fun forceRefreshValid() {
        input.forceRefreshValid()
    }

    private var validator = Validator<String> {
        if (canBeEmpty) {
            if (maxAfterPoint >= 0) {
                (DoubleValidator.FORMAT or DoubleValidator.forMaxAfterPoint(maxAfterPoint) or (Validator { if (it.isEmpty()) valid() else invalid() })).valid(
                    it,
                )
            } else {
                valid()
            }
        } else {
            if (maxAfterPoint >= 0) {
                (DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(maxAfterPoint)).valid(it)
            } else {
                valid()
            }
        }
    }

    private val input = EditText(placeHolder = placeHolder).apply {
//        if (canBeEmpty) {
//            if (maxAfterPoint >= 0)
//                textValidator =
//                    (DoubleValidator.FORMAT or DoubleValidator.forMaxAfterPoint(maxAfterPoint) or (Validator { if (it.isEmpty()) null else "" }))
//        } else {
//            if (maxAfterPoint >= 0)
//                textValidator = (DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(maxAfterPoint))
//        }
        textValidator = validator
        dom.style.marginRight = 8.px
        layout.add(dom) {
            shrink = 1
            grow = 1
        }
    }

    override val valid: Boolean
        get() = input.valid

    /**
     * Тип отображения времени. Не влеяет на значение переменной [value]. То есть например [value] хранит значение
     * 60000, а визуально в компоненте написано "1 минута"
     */
    var timeType: TimeType
        get() = types[current]
        set(it) {
            val p = types.indexOf(it)
            if (p <= -1) {
                throw IllegalArgumentException("Type ${it.name} is not allowd")
            }
            val tt = time
            if (tt !== null) {
                input.text = ((tt.toDouble()) / (types[p].msValue.toDouble())).toString()
            }

            current = p
        }

    /**
     * Автоматический подберает необходимый тип визуализации. Например, если [time] имеет значение 60000,
     * а [timeType] = милисекунды то после вызова [autoTimeFormat] значен [timeType] - примин "минуты" и визуально поле
     * ввода заполнится значением "1".
     */
    fun autoTimeFormat() {
        val timeLocal = time ?: return

        if (timeLocal == 0L) {
            timeTypeSelector.select = types.indexOf(types[0])
            return
        }

        val ty = calcOptimalType(time = timeLocal, maxAfterPoint = maxAfterPoint, allowTypes = types)

        if (ty === null) {
            return
        }
        timeTypeSelector.select = types.indexOf(ty)
    }

    /**
     * Текущее введенное время. Всегда в милисекундах. То есть устанавливать значение нужно в милисекундах и получать
     * значение нужно тоже в милисекундах.
     */
    var time: Long?
        set(it) {
            if (it === null) {
                input.text = ""
            } else {
                input.text = (it.toDouble() / (types[current].msValue.toDouble())).toString()
            }
        }
        get() {
            val v = input.text.toFloatOrNull()
            if (!input.valid || v == null) {
                return null
            }
            return (v.toLong() * types[current].msValue)
        }

    val timeDuration
        get() = time?.let { DateDuration(it) }

    private val timeTypeSelector = Combobox2().apply {
        dom.style.width = 110.px
        items = types.map {
            when (it) {
                TimeType.MS -> "Миллисекунд"
                TimeType.SECONDS -> "Секунд"
                TimeType.MINUTE -> "Минут"
                TimeType.HOUR -> "Часов"
                TimeType.DAY -> "Дней"
                TimeType.WEEK -> "Недель"
            }
        }
        layout.add(dom) {
            shrink = 0
            grow = 0
            align = FlexLayout.FlexItem.AlignSelf.Center
        }

        this.select = this@TimeCountSelector.current
    }

    /**
     * Статус компонента. Если имеет значение false, то компонент будет серым, а так же  у пользователя
     * не будет возможности менять значение.
     *
     * Если true, то пользователь сможет сам вводить значение
     */
    var enabled: Boolean
        get() = input.enabled
        set(it) {
            input.enabled = it
            timeTypeSelector.enabled = it
        }

    val EVENT_CHANGED = EventElement()

    init {
        input.dom.addEventListener("change", {
            dom.dispatchEvent(Event("change"))
            EVENT_CHANGED.dispatch()
        })

        input.dom.addEventListener("valid", {
            dom.dispatchEvent(Event("valid"))
        })

        timeTypeSelector.eventChange.on {
            timeType = types[it]
            console.info("timeType->$timeType")
        }
    }
}

fun <T : TimeCountSelector> T.time(time: Long?): T {
    this.time = time
    if (time != null) {
        this.autoTimeFormat()
    }
    return this
}

fun <T : TimeCountSelector> T.time(time: DateDuration?): T =
    time(time?.asLong)
