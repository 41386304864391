package org.tlsys.dto

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.serialization.URISerializer
import pw.binom.openapi.StdFormat
import pw.binom.openapi.annotation.Description
import pw.binom.openapi.annotation.Example
import pw.binom.openapi.annotation.Format
import pw.binom.url.URI
import pw.binom.uuid.UUID

@SerialName("ExternalScriptDto")
@Serializable
class ExternalScriptDto(
    @Contextual
    @Description("Идентификатор скрипта")
    @Example("5ce186da-40f4-4d50-b0e1-5d125a0283a6")
    @Format(StdFormat.UUID)
    val id: UUID,
    @Description("Наименование скрипта")
    val name: String,
    @Description("URL манифест-файла")
    @Serializable(URISerializer::class)
    @Example("https://cocopops.ru/v1/promotion/10")
    @Format(StdFormat.URI)
    val url: URI,
    @Description("Авторизационные данные")
    val auth: String?,
    @Description("Флаг отладки скрипта")
    val debug: Boolean,
)
