package org.tlsys.security

import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.dto.auth.GroupDTO
import org.tlsys.ui.*
import pw.binom.web.layout.*

/**
 * Created by Ермаков Игорь Александрович (email: igor.yermakov94@yandex.ru) on 06.09.2017.
 */
class AddGroupDialog(val group: GroupDTO?) :
    TitleDialog(if (group === null) "Создание группы" else "Редактирование группы") {
    init {
        dom.style.width = "300px"
        dom.style.height = "150px"
    }

    private var _canceled: Boolean = true

    val canceled: Boolean
        get() = _canceled

    val body = SaveCancelBody()
        .appendTo(layout, grow = 1, shrink = 1)

    val name = EditText(placeHolder = "Наименование").textValidator(TextValidators.NOT_BLANK)
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)

    init {
        name.text = group?.name ?: ""

        body.ok {
            _canceled = false
            close()
        }

        body.cancel {
            cancelClick()
        }

        name.onValidChange {
            body.okBtn.enabled = it
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    companion object {
        suspend fun show(group: GroupDTO?): AddGroupDialog? {
            val d = AddGroupDialog(group)
            d.show()
            return if (d.canceled) {
                null
            } else {
                d
            }
        }
    }
}
