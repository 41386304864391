package org.tlsys.goods.pages

import org.tlsys.admin.ui.*
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.before
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class GoodFilterDialog(private val initValue: String) : TitleDialog("Фильтр товаров") {
    val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val edit = EditText(placeHolder = "Код или имя товара товара")
        .text(initValue)
        .appendTo(layout, grow = 0, shrink = 0)

    private var value: String = ""

    private val clearBtn = BaseButton("ОТЧИСТИТЬ")
        .appendTo(body.buttonsLayout, grow = 0, shrink = 0)
        .before(body.cancelBtn)

    override suspend fun onStart() {
        super.onStart()
        edit.focus()
        edit.select()
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        value = initValue
        close()
    }

    init {
        dom.style.width = 400.px
        clearBtn.onClick {
            value = ""
            close()
        }
        body.ok {
            value = edit.text
            close()
        }
        body.cancel {
            value = initValue
            close()
        }
    }

    companion object {
        suspend fun show(value: String?): String? {
            val d = GoodFilterDialog(value ?: "")
            d.show()
            return d.value.takeIf { it.isNotBlank() }
        }
    }
}
