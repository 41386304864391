package org.tlsys.admin

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.Percent
import pw.binom.uuid.UUID

@Serializable
data class SetMaxBonusPayConfig(
    @SerialName("include_tags")
    val includeTags: List<Long>,
    @SerialName("exclude_tags")
    val excludeTags: List<Long>,
    val shops: List<Long>,
    @SerialName("max_percent_pay")
    val maxPercentPay: Percent,
    @SerialName("good_dictionary_id")
    @Contextual
    val goodDictionaryId: UUID?,
)
