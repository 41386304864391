package org.tlsys

import pw.binom.generator

fun <VALUE> makeFlow3(
    func: suspend (Long) -> Collection<VALUE>,
) = generator<VALUE> {
    var afterDate: Long = 0
    while (!isClosed) {
        val values = func(afterDate)
        if (values.isEmpty()) {
            break
        }
        afterDate += values.size
        values.forEach {
            if (isClosed) {
                return@generator
            }
            yield(it)
        }
    }
}

fun <CURSOR : Any, VALUE> makeFlow(
    cursor: (VALUE) -> CURSOR,
    func: suspend (CURSOR?) -> Collection<VALUE>,
) = generator<VALUE> {
    var afterDate: CURSOR? = null
    while (!isClosed) {
        val values = func(afterDate)
        if (values.isEmpty()) {
            break
        }
        afterDate = cursor(values.last())
        values.forEach {
            if (isClosed) {
                return@generator
            }
            yield(it)
        }
    }
}
