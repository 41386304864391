package org.tlsys.api

import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import org.tlsys.ApiUrls
import org.tlsys.GatewaySendResult
import org.tlsys.serialization.Gateway
import org.tlsys.serialization.GatewayTest
import pw.binom.uuid.UUID
import pw.binom.uuid.serialization.UUIDStringSerializer

object GatewayService {
    suspend fun getList() =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.GATEWAY_LIST_GET,
            bodyClass = Unit.serializer(),
            body = null,
            response = ListSerializer(Gateway.serializer()),
            method = "POST",
        )

    suspend fun create(record: Gateway) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.GATEWAY_CREATE_POST,
            bodyClass = Gateway.serializer(),
            body = record,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun test(record: GatewayTest) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.GATEWAY_TEST,
            bodyClass = GatewayTest.serializer(),
            body = record,
            response = GatewaySendResult.serializer(),
            method = "POST",
        )

    suspend fun update(record: Gateway) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.GATEWAY_UPDATE_POST,
            bodyClass = Gateway.serializer(),
            body = record,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun delete(id: UUID) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.GATEWAY_DELETE_POST,
            bodyClass = UUIDStringSerializer,
            body = id,
            response = Unit.serializer(),
            method = "POST",
        )
}
