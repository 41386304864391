package org.tlsys.api

import org.tlsys.Dto
import org.tlsys.json.*
import kotlin.js.JsName

interface RemoteCartService {
    @JsName("addToCart")
    fun addToCart(memberId: JLong, goodCode: JString, count: JLong): AsyncResult<CartGood>

    @JsName("updateToCart")
    fun updateToCart(memberId: JLong, id: JString, count: JLong): AsyncResult<CartGood?>

    @JsName("removeFromCart")
    fun removeFromCart(memberId: JLong, id: JString): AsyncResult<JUnit>

    @JsName("getCart")
    fun getCart(memberId: JLong): AsyncResult<JList<CartGood>>

    @Dto
    class CartGood(val id: JString, val memberId: JLong, val goodCode: JString, val count: JLong) : JDTO {
        companion object : AutoJDTOFactory<CartGood>(CartGood::class)

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }
}