package org.tlsys.shops

import org.tlsys.*
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.ShopsService
import org.tlsys.core.Closeable
import org.tlsys.dto.DTOShop
import org.tlsys.json.jdto
import org.tlsys.ui.Page
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class ShopsPage : AbstractPage() {
    override suspend fun getTitle(): String = TITLE

    companion object {

        val TITLE = "Торговые объекты"
        val URI = "shops"

        private val costumColumns = HashSet<CustomColumn>()

        class CustomColumn(val name: String, val render: (TablePoint<DTOShop>) -> Unit) : Closeable {
            override fun close() {
                costumColumns.remove(this)
            }
        }

        fun addColumn(name: String, render: (TablePoint<DTOShop>) -> Unit): CustomColumn {
            val c = CustomColumn(name, render)
            costumColumns += c
            return c
        }
    }

    private val shopsService by Services.byClass(ShopsService::class)

    private val allowChangeBalance by permission(OPermission.CREATE_SHOP)
    private val removeShop by permission(OPermission.REMOVE_SHOP)

    override suspend fun onInit() {
        super.onInit()
        shopsService.getShops(0.jdto, (-1).jdto).await().forEach {
            ShopItem(it).appendTo(table)
        }
    }

    private val scroll = ScrollController(dom)
    private val table = MaterialTable().appendTo(contentLayout, grow = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Название").appendTo(header)
            MaterialTable.TextColumn("Адрес").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    fun getFirstShop() =
        table.rows.getOrNull(0)?.let { it as ShopItem }

    val addBtn = AddButton().appendTo(contentLayout, grow = 0, shrink = 0)

    init {
        if (allowChangeBalance) {

            addBtn.onClick {
                val d = AddShopDialog.show(null) ?: return@onClick
                val shop = shopsService.createShop(d.first.jdto, d.second.jdto).promise.await()
                val itt = ShopItem(shop).appendTo(table)
                Effects.createItem(itt.dom).await()
                Effects.blockRow(itt.dom)
            }
        }
    }

    override suspend fun next(path: String): Page? {
        if (!path.startsWith("s")) {
            return super.next(path)
        }
        val t = path.substring(1).toLongOrNull() ?: return super.next(path)
        return ShopInfo(t)
    }

    inner class ShopItem(var shop: DTOShop) : MaterialTable.Row() {
        //        private val layout = FlexLayout(dom, FlexLayout.Direction.Row, alignItems = FlexLayout.AlignItems.Center)
        val title = MaterialTable.LinkColumn(
            text = "",
            href = "",
        ).appendTo(this)
        private val address = MaterialTable.TextColumn("").appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)
//        private val actionPanel = ActionPlace().appendTo(layout, grow = 0, shrink = 0)

        private fun reset() {
            title.text = shop.title
            title.href = "#${pageNavigation.getUrl(this@ShopsPage)}/s${shop.id}"
            address.text = shop.address
        }

        init {
            actions.component.visibleOnHover(this)
            dom.style.paddingLeft = 16.px
            addClass(Styles.LIST_ITEM_STYLE)
            dom.style.minHeight = 56.px

            actions.component.iconBtn(MaterialIcons.SETTINGS).onClick {
                async2 {
                    val data = AddShopDialog.show(this@ShopItem.shop) ?: return@async2
                    shopsService.setShopTitleAndAddress(
                        this@ShopItem.shop.id.jdto,
                        data.first.jdto,
                        data.second.jdto,
                    ).promise.then { newShop ->
                        this@ShopItem.shop = newShop
                        reset()
                        Effects.blockRow(this@ShopItem.dom)
                    }
                }
            }

            if (removeShop) {
                actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                    async2 {
                        if (YesNoDialog.show(
                                text = "Удалить магазин ${
                                    shop.title.takeIf { it.isNotBlank() }
                                        ?: shop.address
                                }?",
                                width = 500,
                            ).await()
                        ) {
                            shopsService.setShopDeletedFlag(shop.id.jdto, true.jdto).await()
                            Effects.removeItem(this@ShopItem.dom).await()
                            removeSelf()
                        }
                    }
                }
            }

            reset()
        }
    }
}
