package org.tlsys

import kotlinx.browser.document
import kotlinx.dom.removeClass
import org.tlsys.admin.SearchFieldService
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.Span
import org.tlsys.cms.events.EventElement
import org.tlsys.cms.onClick
import org.tlsys.css.CSS
import org.tlsys.search.SearchField
import org.tlsys.ui.DivLayout
import org.tlsys.ui.createDiv
import org.tlsys.ui.createSpan
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLSpanElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.FlexLayout
import pw.binom.web.layout.appendTo

val OPEN = CSS.genName()
val CLOSE = CSS.genName()

private val STYLE = CSS.style {
    val SPEED = 300
    ".$OPEN" then {
    }
    transition = "transform ${SPEED}ms ease-in-out"
    ".$CLOSE" then {
        transform = "rotate(180deg)"
        ":before" then {
            transform = "rotate(315deg)"
            width = "12px"
            bottom = "180%"
            left = "-2.5px"
        }

        ":after" then {
            transform = "rotate(225deg)"
            width = "12px"
            top = "180%"
            left = "-2.5px"
        }
    }

    background = "rgba(255,255,255,.9)"
    width = "13px"
    height = "2px"
    marginLeft = "calc( 50% - 6.5px )"

    ":before" then {
        content = "''"
        position = "absolute"
        left = 0.px
        width = "inherit"
        height = 2.px
        bottom = "300%"
        background = "rgba(255,255,255,.9)"
        transition =
            "width ${SPEED}ms ease-in-out, transform ${SPEED}ms ease-in-out, left ${SPEED}ms ease-in-out, top ${SPEED}ms ease-in-out"
    }

    ":after" then {
        content = "''"
        position = "absolute"
        left = 0.px
        width = "inherit"
        height = 2.px
        top = "300%"
        background = "rgba(255,255,255,.9)"
        transition =
            "width ${SPEED}ms ease-in-out, transform ${SPEED}ms ease-in-out, left ${SPEED}ms ease-in-out, top ${SPEED}ms ease-in-out"
    }

    position = "relative"
    display = "block"
}.name

class NavigationChanger(val page: AdminPage) : AbstractComponent<HTMLSpanElement>() {
    override val dom: HTMLSpanElement = document.createSpan()
    private val gg = Span()
    var open: Boolean = true
        set(value) {
            field = value
            if (value) {
                gg.dom.addClass(OPEN)
                gg.dom.removeClass(CLOSE)
            } else {
                gg.dom.addClass(CLOSE)
                gg.dom.removeClass(OPEN)
            }
        }

    val eventClick = EventElement()

    init {
        dom.appendChild(gg.dom)
        gg.addClass(STYLE)
        gg.addClass(OPEN)

        dom.onClick {
            eventClick.dispatch()
            page.navigation.full = open
        }
        dom.style.apply {
            width = 41.px
            paddingTop = 30.px
            textAlign = "center"
            cursor = "pointer"
        }
    }
}

// private class Balance : DivComponentWithLayout() {
//    private val span = Span().appendTo(layout)
//    private val _value = Span().appendTo(layout)
//    private val paymentService by Services.byClassOrNull(PaymentService::class)
//    var visible: Boolean
//        get() = dom.style.display != "none"
//        set(value) {
//            dom.style.display = if (value) "" else "none"
//        }
//    var value: Long = 0
//        set(value) {
//            field = value
//            if (value < 0L) span.text = "К оплате: " else span.text = "Баланс: "
//
//            val v = if (value < 0) -value else value
//            _value.text = "${v / 100f}"
//        }
//
//    init {
//        dom.on("click") {
//            async {
//                paymentService?.click()
//            }
//        }
//        dom.style.apply {
//            opacity = "0.8"
//            cursor = "pointer"
//        }
//        span.dom.style.apply {
//            fontFamily = Styles.DEFAULT_TEXT_FONT
//            fontWeight = "400"
//            color = "#fff"
//        }
//        _value.dom.style.apply {
//            fontFamily = Styles.DEFAULT_TEXT_FONT
//            fontWeight = "600"
//            color = "#fff"
//        }
//        window.setInterval({
//            async {
//                val balance = paymentService?.getBalance()
//                visible = balance != null
//                if (balance != null) {
//                    value = balance
//                }
//            }
//        }, 60_000)
//    }
// }

class AdminPage : AbstractComponent<HTMLElement>() {
    override val dom: HTMLElement = document.createDiv()
    private val layout = FlexLayout(this, direction = FlexLayout.Direction.ColumnReverse)
    private val mainContent =
        DivLayout(direction = FlexLayout.Direction.RowReverse).appendTo(layout, grow = 1, shrink = 1).also {
            it.dom.style.apply {
                backgroundColor = "#f4f4f4"
            }
        }

    private val headerBG = DivLayout().appendTo(layout, grow = 0, shrink = 0).also {
        it.dom.style.apply {
            height = 60.px
            position = "relative"
        }
    }

    // ------------------
    private val header = DivLayout().appendTo(headerBG.layout).also {
        it.dom.style.apply {
            backgroundColor = "#00acc1"
            position = "absolute"
            width = "100%"
            height = "100%"
            boxShadow = "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)"
        }
    }

//    private val balance = Balance().apply {
//        header.dom.appendChild(dom)
//        dom.style.apply {
//            position = "absolute"
//            right = 20.px
//            top = 17.px
//        }
//    }

    val cc = NavigationChanger(this)
        .appendTo(header.layout, grow = 0, shrink = 0)
        .also {
            it.dom.style.apply {
                marginLeft = 12.px
            }
        }

    val searchField = SearchField().appendTo(header.layout, grow = 1, shrink = 0)

    val body = DivLayout().appendTo(mainContent.layout).also {
        it.dom.style.apply {
            //            padding = "5px 15px 0px 15px"
        }
    }

    private val navigationBg = DivLayout().appendTo(mainContent.layout, grow = 0, shrink = 0).also {
        it.dom.style.width = NavigationPlane.BIG_WIDTH.px
        it.dom.style.position = "relative"
    }

    val navigation = NavigationPlane().appendTo(navigationBg.layout).also {
        it.dom.style.apply {
            position = "absolute"
            width = "100%"
            height = "100%"
        }
    }

    init {
        Services.reg(this)
        Services.reg(object : SearchFieldService {
            private var enableEvents = true
            override var value: String
                get() = searchField.value
                set(value) {
                    enableEvents = false
                    searchField.value = value
                    enableEvents = true
                }
            override val changeEvent = org.tlsys.admin.events.EventElement()

            init {
                searchField.changeEvent.on {
                    if (enableEvents) {
                        changeEvent.dispatch()
                    }
                }
            }
        })
        navigationBg.dom.style.transition = "width 300ms ease-in-out"
        cc.eventClick.on {
            cc.open = !cc.open
            navigation.full = cc.open
            navigationBg.dom.style.width =
                (if (cc.open) NavigationPlane.BIG_WIDTH else NavigationPlane.MIN_WIDTH).px
        }
    }
}
