package org.tlsys.api

import org.tlsys.async2
import org.tlsys.file.RFile
import org.tlsys.json.JsonFactory
import org.tlsys.json.JsonNode
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest
import kotlin.js.Promise

class UploadProgress(val loaded: Int, val total: Int?)

fun FileService.upload(data: FormData, process: ((UploadProgress) -> Unit)? = null) = Promise<List<RFile>> { d, c ->
    val xhr = XMLHttpRequest()

    xhr.onload = {
        if (xhr.readyState == XMLHttpRequest.DONE) {
            if (xhr.status == 200.toShort()) {
                d(JsonNode.parse(xhr.responseText).filterNotNull().map {
                    JsonFactory.read<RFile>(it)
                })
            } else {
                c(RuntimeException("Unknown Code [${xhr.status}]"))
            }
        }
    }
    if (process != null)
        xhr.upload.onprogress = {
            process(UploadProgress(
                    loaded = it.loaded.toInt(),
                    total = if (it.lengthComputable) it.total.toInt() else null
            ))
        }
    xhr.upload.ontimeout = {
        c(Exception("Timeout"))
    }
    xhr.upload.onabort = {
        c(Exception("Abort"))
    }

    xhr.upload.onerror = {
        c(Exception("Error"))
    }

    xhr.ontimeout = {
        c(Exception("Timeout"))
    }
    xhr.onabort = {
        c(Exception("Abort"))
    }

    xhr.onerror = {
        c(Exception("Error"))
    }
    async2 {
        xhr.open(method = "POST", url = getUploadUrl().await().value + "-new", async = true)
        xhr.send(data)
    }
}