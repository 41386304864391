package org.tlsys.core

import org.tlsys.PaymentService
import org.tlsys.admin.core.PageNavigation
import org.tlsys.admin.core.Services
import org.tlsys.core.page.PayDebtPage

class PaymentServiceImpl : PaymentService {
    private val pageNavigation by Services.byClass(PageNavigation::class)
    private val payDebtService by Services.byClass(PayDebtService::class)
    override suspend fun getBalance(): Long = payDebtService.getBalance().await().value
    override suspend fun click() {
        pageNavigation.go("/settings/${PayDebtPage.URI}")
    }
}
