package org.tlsys.dto

import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import pw.binom.date.DateTime
import pw.binom.openapi.StdFormat
import pw.binom.openapi.annotation.Description
import pw.binom.openapi.annotation.Format

@Description("Настройки генерации отчёта по транзакциям")
@Serializable
class GenerateReportConfigDto(
    @Description("Тип списания бонусов")
    val amountType: AmountType = AmountType.BOTH,
    @Format(StdFormat.DATE_TIME)
    @Description("Дата начала периода")
    @Contextual
    val dateStart: DateTime? = null,
    @Format(StdFormat.DATE_TIME)
    @Description("Дата окончания периода")
    @Contextual
    val dateEnd: DateTime? = null,
) {

    @Serializable
    enum class AmountType {
        BOTH,
        ONLY_DROP,
        ONLY_ADD,
    }
}
