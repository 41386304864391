package org.tlsys.script

import org.tlsys.dto.DateDay
import org.tlsys.json.*
import kotlin.jvm.JvmName
import kotlin.jvm.JvmOverloads

interface UI : JDTO
class StageBuilder {
    internal val elements = ArrayList<Layout.Item>()
    fun add(ui: UI, grow: Int, shrink: Int) {
        elements += Layout.Item(ui, grow = grow, shrink = shrink)
    }

    fun inputString(id: String, holder: String = "", defaultValue: String = "", canBeEmpty: Boolean = false, grow: Int = 0, shrink: Int = 0) {
        elements += Layout.Item(
                InputString(
                        id = id,
                        canBeEmpty = canBeEmpty,
                        holder = holder,
                        default = defaultValue
                ),
                grow = grow,
                shrink = shrink
        )
    }

    fun inputInt(id: String, holder: String = "", defaultValue: Int? = null, canBeEmpty: Boolean = false, grow: Int = 0, shrink: Int = 0): Layout.Item {
        val l = Layout.Item(
            InputInteger(
                id = id,
                canBeEmpty = canBeEmpty,
                holder = holder,
                default = defaultValue
            ),
            grow = grow,
            shrink = shrink
        )
        elements += l
        return l
    }

    fun inputFloat(id: String, holder: String = "", defaultValue: Float? = null, canBeEmpty: Boolean = false, grow: Int = 0, shrink: Int = 0): Layout.Item {
        val l = Layout.Item(
                FloatString(
                        id = id,
                        canBeEmpty = canBeEmpty,
                        holder = holder,
                        default = defaultValue
                ),
                grow = grow,
                shrink = shrink
        )
        elements += l
        return l
    }

    fun date(id: String, holder: String = "", defaultValue: DateDay? = null, canBeEmpty: Boolean = false, grow: Int = 0, shrink: Int = 0): Layout.Item {
        val l = Layout.Item(
            InputDate(
                id = id,
                canBeEmpty = canBeEmpty,
                holder = holder,
                default = defaultValue
            ),
            grow = grow,
            shrink = shrink
        )
        elements += l
        return l
    }

    fun label(text: String, ui: UI): Layout.Item {
        val l = Layout.Item(
                Label(text, ui),
                grow = 0,
                shrink = 0
        )
        elements += l
        return l
    }

    fun tabs(func: TabBuilder.() -> Unit): Layout.Item {
        val builder = TabBuilder()
        builder.func()
        val l = Layout.Item(
                builder.build(),
                grow = 0,
                shrink = 0
        )
        elements += l
        return l
    }

    fun text(text: String, grow: Int = 0, shrink: Int = 0) {
        elements += Layout.Item(
                Text(text),
                grow = grow,
                shrink = shrink
        )
    }

    fun duration(id: String, holder: String, defaultValue: Long? = null, canBeEmpty: Boolean = false, grow: Int = 0, shrink: Int = 0): Layout.Item {
        val l = Layout.Item(
                InputDuration(holder, defaultValue, canBeEmpty, id),
                grow = grow,
                shrink = shrink
        )
        elements += l
        return l
    }

    fun goodList(id: String, canBeEmpty: Boolean = true, grow: Int = 0, shrink: Int = 0): Layout.Item {
        val l = Layout.Item(
            element = InputGoodList(id = id, canBeEmpty = canBeEmpty),
            grow = grow, shrink = shrink
        )
        elements += l
        return l
    }

    fun layout(vertical: Boolean = true, grow: Int = 0, shrink: Int = 0, func: StageBuilder.() -> Unit): Layout {
        val builder = StageBuilder()
        builder.func()
        val l = Layout(builder.elements, vertical)
        elements += Layout.Item(
                l,
                grow = grow,
                shrink = shrink
        )
        return l
    }

    fun label(text: String, func: StageBuilder.() -> UI): Layout.Item {
        val builder = StageBuilder()
        builder.func()
        val e = builder.elements[0]!!.element
        return label(text, e)
    }
}

class TabBuilder {
    private val tabs = ArrayList<Tabs.Tab>()
    fun tab(title: String, vertical: Boolean = true, func: StageBuilder.() -> Unit) {
        tabs += Tabs.Tab(title, rootLayout(vertical = vertical, func = func))
    }

    fun build() = Tabs(tabs)
}

@JvmOverloads
fun rootLayout(vertical: Boolean = true, func: StageBuilder.() -> Unit): Layout {
    val builder = StageBuilder()
    builder.func()
    val l = Layout(builder.elements, vertical)
    return l
}

class Layout(val childs: List<Item>, var vertical: Boolean) : UI {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<Layout> {
        override val type: String
            get() = "Layout"

        override fun read(node: JsonNode) = Layout(
                JsonFactory.readArray(node["childs"]!!),
                node["vertical"]!!.boolean()
        )

        override fun write(obj: Layout) = JsonNode.obj(
                "childs" to JsonFactory.writeArray(obj.childs),
                "vertical" to obj.vertical.json
        )

    }

    class Item(val element: UI, var grow: Int, var shrink: Int) : JDTO {
        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        fun grow(grow: Int): Item {
            this.grow = grow
            return this
        }

        fun shrink(shrink: Int): Item {
            this.shrink = shrink
            return this
        }

        fun place(grow: Int, shrink: Int): Item {
            this.grow = grow
            this.shrink = shrink
            return this
        }

        companion object : JDTOFactory<Item> {
            override val type: String
                get() = "Layout_Item"

            override fun read(node: JsonNode) = Item(
                    element = JsonFactory.read(node["element"]!!),
                    shrink = node["shrink"]!!.int(),
                    grow = node["grow"]!!.int()
            )

            override fun write(obj: Item) = JsonNode.obj(
                    "element" to JsonFactory.write(obj.element),
                    "shrink" to obj.shrink.json,
                    "grow" to obj.grow.json
            )

        }
    }
}

class Text(val text: String) : UI {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<Text> {
        override val type: String
            get() = "Text"

        override fun read(node: JsonNode) =
                Text(node["text"]!!.string())

        override fun write(obj: Text) = JsonNode.obj("text" to obj.text.json)

    }
}

interface Input : UI {
    val id: String
}

class InputGoodList(override val id: String, val canBeEmpty: Boolean = false) : Input, JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<InputGoodList> {
        override val type: String
            get() = "InputGoodList"

        override fun read(node: JsonNode) =
                InputGoodList(
                        canBeEmpty = node["canBeEmpty"]!!.boolean(),
                        id = node["id"]!!.string()
                )

        override fun write(obj: InputGoodList) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "canBeEmpty" to obj.canBeEmpty.json
                )

    }
}

class InputShopList(override val id: String, val canBeEmpty: Boolean = false) : Input, JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<InputGoodList> {
        override val type: String
            get() = "InputShopList"

        override fun read(node: JsonNode) =
                InputGoodList(
                        canBeEmpty = node["canBeEmpty"]!!.boolean(),
                        id = node["id"]!!.string()
                )

        override fun write(obj: InputGoodList) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "canBeEmpty" to obj.canBeEmpty.json
                )

    }
}

class ITagPanel(override val id: String) : Input, JDTO {
    companion object : JDTOFactory<ITagPanel> {
        override val type: String
            get() = "ITagPanel"

        override fun read(node: JsonNode) =
                ITagPanel(node["id"]!!.string())

        override fun write(obj: ITagPanel) =
                JsonNode.obj("id" to obj.id.json)

    }

    override val factory: JDTOFactory<JDTO>
        get() = asDefault
}

class ICheckbox(override val id: String, val title: String, val default: Boolean) : Input, JDTO {

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<ICheckbox> {
        override val type: String
            get() = "ICheckbox"

        override fun read(node: JsonNode) = ICheckbox(
                id = node["id"]!!.string(),
                title = node["title"]!!.string(),
                default = node["default"]!!.boolean()
        )

        override fun write(obj: ICheckbox) = JsonNode.obj(
                "id" to obj.id.json,
                "title" to obj.title.json,
                "default" to obj.default.json
        )

    }
}

class InputString(override val id: String, val holder: String? = null, val default: String = "", val canBeEmpty: Boolean = false) : Input, JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<InputString> {
        override val type: String
            get() = "InputString"

        override fun read(node: JsonNode) =
                InputString(
                        holder = node["holder"]?.string(),
                        canBeEmpty = node["canBeEmpty"]!!.boolean(),
                        id = node["id"]!!.string(),
                        default = node["default"]!!.string()
                )

        override fun write(obj: InputString) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "holder" to obj.holder?.json,
                        "canBeEmpty" to obj.canBeEmpty.json,
                        "default" to obj.default.json
                )

    }
}

class FloatString(val holder: String? = null, val default: Float?, val canBeEmpty: Boolean = false, override val id: String) : Input {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<FloatString> {
        override val type: String
            get() = "FloatString"

        override fun read(node: JsonNode) = FloatString(
                id = node["id"]!!.string(),
                holder = node["holder"]?.string(),
                default = node["default"]?.float(),
                canBeEmpty = node["canBeEmpty"]!!.boolean()
        )

        override fun write(obj: FloatString) = JsonNode.obj(
                "id" to obj.id.json,
                "holder" to obj.holder?.json,
                "default" to obj.default?.json,
                "canBeEmpty" to obj.canBeEmpty.json
        )

    }
}

class InputDatetime(val holder: String? = null, val default: String?, val canBeEmpty: Boolean = false, override val id: String) : Input {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<InputDatetime> {
        override val type: String
            get() = "InputDatetime"

        override fun read(node: JsonNode) = InputDatetime(
            holder = node["holder"]?.string(),
            id = node["id"]!!.string(),
            canBeEmpty = node["canBeEmpty"]!!.boolean(),
            default = node["default"]?.string()
        )

        override fun write(obj: InputDatetime) = JsonNode.obj(
            "holder" to obj.holder?.json,
            "id" to obj.id.json,
            "canBeEmpty" to obj.canBeEmpty.json,
            "default" to obj.default?.json
        )
    }
}

class InputDate(val holder: String? = null, val default: DateDay?, val canBeEmpty: Boolean = false, override val id: String) : Input {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<InputDate> {
        override val type: String
            get() = "InputDate"

        override fun read(node: JsonNode) = InputDate(
                holder = node["holder"]?.string(),
                id = node["id"]!!.string(),
                canBeEmpty = node["canBeEmpty"]!!.boolean(),
                default = node["default"]?.string()?.let { DateDay.parse(it) }
        )

        override fun write(obj: InputDate) = JsonNode.obj(
                "holder" to obj.holder?.json,
                "id" to obj.id.json,
                "canBeEmpty" to obj.canBeEmpty.json,
                "default" to obj.default?.toString()?.json
        )
    }
}

class InputInteger(val holder: String? = null, val default: Int?, val canBeEmpty: Boolean = false, override val id: String) : Input {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<InputInteger> {
        override val type: String
            get() = "InputInteger"

        override fun read(node: JsonNode) = InputInteger(
                holder = node["holder"]?.string(),
                id = node["id"]!!.string(),
                canBeEmpty = node["canBeEmpty"]!!.boolean(),
                default = node["default"]?.int()
        )

        override fun write(obj: InputInteger) = JsonNode.obj(
                "holder" to obj.holder?.json,
                "id" to obj.id.json,
                "canBeEmpty" to obj.canBeEmpty.json,
                "default" to obj.default?.json
        )
    }
}

class InputDuration(val holder: String? = null, val defaultValue: Long?, val canBeEmpty: Boolean = false, override val id: String) : Input {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<InputDuration> {
        override val type: String
            get() = "InputDuration"

        override fun read(node: JsonNode) = InputDuration(
                holder = node["holder"]?.string(),
                id = node["id"]!!.string(),
                canBeEmpty = node["canBeEmpty"]!!.boolean(),
                defaultValue = node["default"]?.long()
        )

        override fun write(obj: InputDuration) = JsonNode.obj(
                "holder" to obj.holder?.json,
                "id" to obj.id.json,
                "canBeEmpty" to obj.canBeEmpty.json,
                "default" to obj.defaultValue?.json
        )
    }
}

class InputBoolean(override val id: String, val default: Boolean) : Input {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<InputBoolean> {
        override val type: String
            get() = "InputBoolean"

        override fun read(node: JsonNode) = InputBoolean(
                id = node["id"]!!.string(),
                default = node["default"]!!.boolean()
        )

        override fun write(obj: InputBoolean) = JsonNode.obj(
                "id" to obj.id.json,
                "default" to obj.default.json
        )

    }
}

class Label(val title: String, val ui: UI) : UI {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<Label> {
        override val type: String
            get() = "Label"

        override fun read(node: JsonNode) =
                Label(
                        title = node["title"]!!.string(),
                        ui = JsonFactory.read(node["ui"]!!)
                )

        override fun write(obj: Label) = JsonNode.obj(
                "title" to obj.title.json,
                "ui" to JsonFactory.write(obj.ui)
        )

    }
}

class Tabs(val tabs: List<Tab>) : UI {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    class Tab(val title: String, val ui: UI) : JDTO {
        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : JDTOFactory<Tab> {
            override val type: String
                get() = "Tabs_Tab"

            override fun read(node: JsonNode) = Tab(
                    title = node["title"]!!.string(),
                    ui = JsonFactory.read(node["ui"]!!)
            )

            override fun write(obj: Tab) =
                    JsonNode.obj(
                            "title" to obj.title.json,
                            "ui" to JsonFactory.write(obj.ui)
                    )

        }
    }

    companion object : JDTOFactory<Tabs> {
        override val type: String
            get() = "Tabs"

        override fun read(node: JsonNode) = Tabs(
                JsonFactory.readArray(node["tabs"]!!)
        )

        override fun write(obj: Tabs) =
                JsonNode.obj(
                        "tabs" to JsonFactory.writeArray(obj.tabs)
                )

    }
}