package org.tlsys.json

expect interface JsonNode : Iterable<JsonNode?> {
    operator fun get(name: String): JsonNode?
    operator fun get(index: Int): JsonNode?
    fun string(): String
    fun int(): Int
    fun boolean(): Boolean
    fun json(): String
    fun put(node: JsonNode?): JsonNode
    fun putAll(nodes: List<JsonNode>): JsonNode
    fun fields(): Array<String>
    operator fun set(name: String, node: JsonNode?): JsonNode
    val size: Int

    val isArray: Boolean
    val isObject: Boolean

    companion object {
        fun parse(text: String): JsonNode
        fun string(value: String): JsonNode
        fun int(value: Int): JsonNode
        fun boolean(value: Boolean): JsonNode
        fun nullValue(): JsonNode
        fun obj(): JsonNode
        fun array(): JsonNode
    }
}

operator fun JsonNode.Companion.invoke(f: NodeBuilder.() -> Unit): JsonNode {
    val obj = JsonNode.obj()
    obj.invoke(f)
    return obj
}

fun JsonNode.Companion.node(vararg values: Pair<String, JsonNode?>): JsonNode {
    val o = JsonNode.obj()
    values.forEach {
        o.set(it.first, it.second)
    }
    return o
}

class NodeBuilder(val node: JsonNode) {
    infix fun String.to(value: String?) {
        node.set(this, value?.json)
    }

    infix fun String.to(value: Int?) {
        node.set(this, value?.json)
    }

    infix fun String.to(value: Long?) {
        node.set(this, value?.json)
    }

    infix fun String.to(value: Float?) {
        node.set(this, value?.json)
    }

    infix fun String.to(value: Double?) {
        node.set(this, value?.json)
    }

    infix fun String.to(value: Boolean?) {
        node.set(this, value?.json)
    }

    infix fun String.to(value: JsonNode?) {
        set(this, value)
    }

    fun set(key: String, value: JsonNode?) {
        node.set(key, value)
    }
}

inline fun JsonNode.Companion.obj(noinline f: NodeBuilder.() -> Unit): JsonNode =
        obj().invoke(f)

fun JsonNode.Companion.obj(vararg field: Pair<String, JsonNode?>): JsonNode {
    val v = obj()
    field.forEach {
        v.set(it.first, it.second)
    }
    return v
}

operator fun JsonNode.invoke(f: NodeBuilder.() -> Unit): JsonNode {
    val nd = NodeBuilder(this)
    nd.f()
    return this
}

fun JsonNode.float() = string().toFloat()
fun JsonNode.double() = string().toDouble()

fun JsonNode.long() = string().toLong()
fun JsonNode.byte() = int().toByte()
fun JsonNode.short() = int().toShort()

fun JsonNode.Companion.float(value: Float) = JsonNode.string(value.toString())
fun JsonNode.Companion.double(value: Double) = JsonNode.string(value.toString())

fun JsonNode.Companion.long(value: Long) = JsonNode.string(value.toString())
fun JsonNode.Companion.byte(value: Byte) = JsonNode.int(value.toInt())
fun JsonNode.Companion.short(value: Short) = JsonNode.int(value.toInt())
val JsonNode.self: JsonNode?
    get() = this
inline val Int.json: JsonNode
    get() = JsonNode.int(this)

inline val Float.json: JsonNode
    get() = JsonNode.float(this)

inline val Long.json: JsonNode
    get() = JsonNode.long(this)

inline val Double.json: JsonNode
    get() = JsonNode.double(this)

inline val Byte.json: JsonNode
    get() = JsonNode.byte(this)

inline val Boolean.json: JsonNode
    get() = JsonNode.boolean(this)

inline val String.json: JsonNode
    get() = JsonNode.string(this)

fun <T : JsonNode> Collection<T>.json(): JsonNode {
    val out = JsonNode.array()
    forEach {
        out.put(it)
    }
    return out
}

fun <T : JsonNode> Array<T>.json(): JsonNode {
    val out = JsonNode.array()
    forEach {
        out.put(it)
    }
    return out
}

inline operator fun JsonNode.set(field: String, value: String?) = set(field, value?.json)
inline operator fun JsonNode.set(field: String, value: Int) = set(field, value.json)
inline operator fun JsonNode.set(field: String, value: Long) = set(field, value.json)

inline operator fun JsonNode.set(field: String, value: Float) = set(field, value.json)
inline operator fun JsonNode.set(field: String, value: Double) = set(field, value.json)

inline operator fun JsonNode.set(field: String, value: Boolean) = set(field, value.json)