package org.tlsys

import org.tlsys.connect.HTTPConnect
import org.tlsys.connect.MUTABLE_DEFAULT_CONNECTION
import org.w3c.dom.get
import org.w3c.dom.set

class BadLoginOrPassword : Exception()
class SessionBreak : Exception()
// class CannotConnect : Exception()
// class ConnectionClosed : Exception()

// class ResurceGetter<T>(val getResurce: () -> Promise<T>) {
//
//    private var getting = false
//    private var res: T? = null
//
//    private inner class WaitRec(val done: (T) -> Unit, val error: (Throwable) -> Unit)
//
//    private val waits = ArrayList<WaitRec>()
//
//    fun reset() {
//        res = null
//        getting = false
//    }
//
//    val value: Promise<T>
//        get() {
//            if (res === null) {
//                if (getting) {
//                    return Promise<T> { d, c ->
//                        waits.add(WaitRec(done = d, error = c))
//                    }
//                } else {
//                    getting = true
//                    return Promise<T> { d, c ->
//                        getResurce().then {
//                            res = it
//                            getting = false
//                            d(it)
//                            val size = waits.size
//                            for (w in waits) {
//                                w.done(it)
//                            }
//                            waits.clear()
//
//                        }.catch {
//                            getting = false
//                            c(it)
//                            val size = waits.size
//                            for (w in waits) {
//                                w.error(it)
//                            }
//                            waits.clear()
//                        }
//                    }
//                }
//            } else {
//                return Promise.resolve(res!!)
//            }
//        }
// }

private var closed = false

private fun shutdown() {
    closed = true
}

object TotalMessageConnector {

    init {
        MUTABLE_DEFAULT_CONNECTION = HTTPConnect("rpc")
    }

    val SESSION_KEY = "LOGIN_SESSION_KEY"

//    private var _session: SessionDTO? = null
//    val session: SessionDTO
//        get() = _session!!

    private var goodLogin = false

//    private val authService by Services.byClass(AuthService::class)

//    fun login(login: String, password: String) = async {
//        try {
//            MUTABLE_DEFAULT_SESSION = null
//            val session = authService.login(
//                    login = encrypt(login).jdto,
//                    password = encrypt(password).jdto
//            ).await() ?: throw BadLoginOrPassword()
//
//            _session = session
// //            CompanyInfo.name = session.company.name
// //            CompanyInfo.companyId = session.company.id
//
//            localStorage[SESSION_KEY] = session.key
//            MUTABLE_DEFAULT_SESSION = session
//            Services.reg(session)
//            goodLogin = true
//
//        } catch (e: UserNotFoundExceptionDTO) {
//            throw BadLoginOrPassword()
//        }
//
//        //val out = sendSys(LoginRequest(login = login, password = password)).wait()
//
//    }

    suspend fun logout() {
        SessionService.logout()
    }

//    fun resume() = async {
//        val key = localStorage[SESSION_KEY] ?: throw SessionBreak()
//
//        try {
//            val out = authService.resume().await()
//            if (out == null) {
//                if (goodLogin) {
//                    MainViewLayout.show(SessionLostDialog())
//                    shutdown()
//                }
//                throw SessionBreak()
//            }
// //            CompanyInfo.name = out.session!!.company.name
// //            CompanyInfo.companyId = out.session!!.company.id
//
//            _session = out
//            localStorage[SESSION_KEY] = out!!.key
//            MUTABLE_DEFAULT_SESSION = out
//            Services.reg(out)
//            goodLogin = true
//        } catch (e: dynamic) {
//            console.dir(e)
//            throw e
//        }
//    }

    // private class Request(val obj: DTO?, f: (DTO?) -> Unit, e: (Throwable) -> Unit)
//    private var counter: Long = 0
//
//    private class Request(val done: (DTO?) -> Unit, val error: (Throwable) -> Unit)
//
//    private val wait = HashMap<Long, Request>()
//
//
//    private var sysConnectGetter: () -> Promise<WebSocket> = {
//        async {
//            if (closed)
//                throw ConnectionClosed()
//            var protokol = "ws"
//
//            if (window.location.protocol == "https:")
//                protokol = "wss"
//
//            val ws = connectWS("$protokol://${window.location.host}${window.location.pathname}api").wait()
//
//            ws.onclose = {
//                sysConnect.reset()
//                serviceConnect.reset()
//                val connectionClosed = ConnectionClosed()
//                for (w in wait)
//                    w.value.error(connectionClosed)
//                wait.clear()
//            }
//
//            ws.onmessage = {
//                val data = it.asDynamic().data
//
//                val reader = JSReader.fromBinaryString(data)
//                val res = reader.obj()
//                if (res is Response) {
//                    val f = wait[res.id]
//                    if (f !== null) {
//                        wait.remove(res.id)
//                        f.done(res.message)
//                    }
//                }
//                undefined
//            }
//
//            ws.onerror = {
//                console.debugger()
//                window.alert("WS Error!")
//            }
//
//            val ff: () -> Unit = {
//                localStorage[SESSION_KEY] = "OLOLO!!!"
//                console.info("Closing connection")
//                ws.close()
//            }
//            window.asDynamic()["doClose"] = ff
//
// /*
//            //эмуляция потери соединения
//            window.setTimeout({
//                ws.close()
//            }, 5_000)
// */
//            ws
//        }
//    }

//    private var serviceConnectGetter: () -> Promise<WebSocket> = {
//        Promise<WebSocket> { d, c ->
//            sysConnect.value.then { ws ->
//                resume().then {
//                    d(ws)
//                }.catch { c(it) }
//            }.catch {
//                c(it)
//            }
//        }
//    }
//
//    private var sysConnect = ResurceGetter(sysConnectGetter)
//    private var serviceConnect = ResurceGetter(serviceConnectGetter)
//
//
//    private fun sendSys(obj: DTO?) = Promise<DTO?> { d, c ->
//        sysConnect.value.then { writeMessage(obj, it, d, c) }.catch { c(it) }
//    }
//
//    fun writeMessage(obj: DTO?, ws: WebSocket, d: (DTO?) -> Unit, c: (Throwable) -> Unit) {
//        val id = counter++
//        val w = JSWriter()
//        w.obj(Request(id, obj))
//        wait[id] = Request(d, c)
//        val textForSend = w.toBinaryString()
//        console.info("Message done. size=${textForSend.length}")
//        ws.send(textForSend)
//    }
//
//    fun send(obj: DTO?) = Promise<DTO?> { d, c ->
//        serviceConnect.value.then { ws ->
//            writeMessage(obj, ws, d, c)
//        }.catch {
//            c(it)
//        }
//    }
//
//    fun <T : DTO> exe(request: ExeRequest) = Promise<ExeResponce<T>> { d, c ->
//        send(request).then { d(it as ExeResponce<T>) }
//    }
}

// private fun connectWS(url: String) = Promise<WebSocket> { d, c ->
//    val ws = WebSocket(url)
//
//    val onError: (Event) -> dynamic = {
//        c(CannotConnect())
//        shutdown()
//        MainPageControl.show(ConnectionLostDialog())
//    }
//
//    val onConnected: (Event) -> dynamic = {
//        ws.removeEventListener("error", onError)
//        d(ws)
//    }
//    ws.addEventListener("open", onConnected)
//    ws.addEventListener("error", onError)
// }
