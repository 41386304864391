package org.tlsys

import kotlinx.browser.localStorage
import org.tlsys.admin.addClass
import org.tlsys.admin.ui.Checkbox
import org.tlsys.admin.ui.SaveCancelBody
import org.tlsys.admin.ui.Span
import org.tlsys.admin.ui.TitleDialog
import org.tlsys.cms.LINK
import org.tlsys.cms.SIMPLE_TEXT
import org.tlsys.cms.click
import org.tlsys.ui.*
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.get
import org.w3c.dom.set
import pw.binom.web.layout.*

class HelpDialog : TitleDialog(title = "Добро пожаловать в личный кабинет TradeLine.") {
    private val body = SaveCancelBody(okText = "Закрыть").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    init {
        body.cancelBtn.dom.style.display = "none"
        fun txt(text: String) =
            Span("").also { it.dom.innerHTML = text }.appendTo(layout, grow = 0, shrink = 0).addClass(SIMPLE_TEXT)
        txt("Прочтите пожалуйста краткую инструкцию для того чтобы запустить бонусы в своем магазине. Это 4 простых шага!").apply {
            dom.style.paddingBottomPX = 10f
        }
        txt("1. Нужно определить какое кассовое программное обеспечение вы используете:")
        txt("1.2 Frontol - настройте по <a href=\"https://tlsys.ru/frontol/\" target=\"_blank\" class=\"$LINK\">инструкции</a>.")
        txt("1.3 Штрих-М Кассир - напишите запрос на <a href=\"mailto:support@tlsys.ru\" class=\"$LINK\">support@tlsys.ru</a>.")
//        txt("1.4 Эвотор - установите приложение.")
        txt("2. Настройте способы регистрации клиентов и смс-уведомления <a href=\"#/settings/bonusSettings\" class=\"$LINK\">здесь</a>.")
        txt("3. Настройте бонусную программу <a href=\"#/promotions\" class=\"$LINK\">здесь</a>.")
        txt("4. Вносите данные о своих клиенах <a href=\"#/members\" class=\"$LINK\">здесь</a>.")
        txt("")
        txt("Начинайте работать, если останутся вопросы - пишите на <a href=\"mailto:support@tlsys.ru\" class=\"$LINK\">support@tlsys.ru</a>")

        val cb = Checkbox("Показать окно справки при следующем входе").appendTo(layout, grow = 0, shrink = 0)
        dom.getElementsByTagName("a").let {
            (0 until it.length).forEach { i ->
                (it.get(i) as HTMLAnchorElement).onclick = {
                    close()
                }
            }
        }
        cb.checked = showOnNext()
        body.okBtn.click {
            try {
                localStorage["VIWE_HELP_ON_NEXT"] = if (cb.checked) "true" else "false"
                close()
            } catch (e: dynamic) {
                console.error("ERROR", e)
            } finally {
            }
        }
    }

    companion object {
        fun showOnNext() = localStorage["VIWE_HELP_ON_NEXT"] != "false"
    }
}
