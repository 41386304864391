package org.tlsys.admin

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.MoneyValue
import pw.binom.uuid.UUID

@Serializable
data class BonusByPayedWithLevelsConfig(
    val levels: List<Level>,
    @SerialName("include_tags")
    val includeTags: List<Long>,
    @SerialName("exclude_tags")
    val excludeTags: List<Long>,
    val shops: List<Long>,
    @SerialName("apply_on_bonus_pay_gone")
    val applyOnBonusPayGone: Boolean = false,
    @Contextual
    val includeGoodsDictionaryId: UUID? = null,
    @Contextual
    val excludeGoodsDictionaryId: UUID? = null,
) {
    @Serializable
    data class Level(
        @SerialName("min_sum")
        val minSum: MoneyValue,
        val self: BonusPercentConfig,
        @SerialName("use_partner_bonus")
        val usePartnerBonus: Boolean,
        val partner: BonusPercentConfig,
    )
}
