package org.tlsys.node

import org.tlsys.dto.DtoSet
import org.tlsys.dto.reg_admin_extensionLib
import org.tlsys.json.JsonFactory
import org.tlsys.json.reg
import org.tlsys.script.*
import org.tlsys.wc.*

object nodes_dto_set : DtoSet() {
    override fun regDto() {
        JsonFactory.reg(DiagramInfo)
        DiagramItem.reg()
        Report.reg()
        JsonFactory.reg(BlockStateDTO)

        DiagramStateShortDTO.reg()
        DiagramStateDTO.reg()

        ParamDTO.reg()
        ScriptMessage.reg()
        DocumentPositionDTO.reg()
        MemberActionDTO.reg()
        MemberActionDTO.ResultDTO.InfoDTO.reg()
        MemberActionDTO.ResultDTO.WarningDTO.reg()
        MemberActionDTO.ResultDTO.ErrorDTO.reg()

        CloseDocumentResultDTO.reg()
        JsonFactory.reg(CalcDocumentResultDTO)
        JsonFactory.reg(CalcDocumentResultDTO.MaxPayRecrod)
        CalcDocumentResultDTO.DiscontRecord.reg()
        CalcDocumentResultDTO.NewPositionRecord.reg()
        CloseDocumentResultDTO.Position.reg()
        CloseDocumentResultDTO.MemberAmountAdd.reg()

        DiagramExecutionDTO.reg()
        MemberReportDTO.reg()
        ScriptDTO.reg()
        Layout.reg()
        InputDuration.reg()
        Layout.Item.reg()
        InputString.reg()
        ICheckbox.reg()
        InputGoodList.reg()
        FloatString.reg()
        InputDate.reg()
        InputDatetime.reg()
        InputInteger.reg()
        InputBoolean.reg()
        Label.reg()
        Tabs.reg()
        ITagPanel.reg()
        Text.reg()
        Tabs.Tab.reg()
    }

    override val subSet: List<DtoSet> = listOf(reg_admin_extensionLib)
}