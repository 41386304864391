package org.tlsys.interrogation

import org.tlsys.Dto
import org.tlsys.json.*
import kotlin.js.JsName

interface InterrogationWebService {

    @Dto
    class Interrogation(
            val id: JString,
            val question: JString,
            val answers: JList<JString>,
            val allowOwenAnswer: JBoolean,
            val multiAnswer: JBoolean,
            val onlyOnce: JBoolean
    ) : JDTO {
        companion object : AutoJDTOFactory<Interrogation>(Interrogation::class, "org.tlsys.interrogation.Interrogation")

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }

    @JsName("getList")
    fun getList(): AsyncResult<JList<Interrogation>>

    @JsName("getInterrogationTerminals")
    fun getInterrogationTerminals(id: JString): AsyncResult<JList<JLong>>

    @JsName("setInterrogationTerminals")
    fun setInterrogationTerminals(id: JString, terminals: JList<JLong>): AsyncResult<JUnit>

    @JsName("create")
    fun create(interrogation: Interrogation): AsyncResult<Interrogation>

    @JsName("update")
    fun update(interrogation: Interrogation): AsyncResult<Interrogation?>

    @JsName("delete")
    fun delete(id: JString): AsyncResult<JBoolean>

    @JsName("get")
    fun get(id: JString): AsyncResult<Interrogation?>
}