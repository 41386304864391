package org.tlsys.core.clientImport

import libs.asDateString
import libs.asDateUtc
import libs.asTimeString
import org.tlsys.admin.core.Services
import org.tlsys.admin.core.join
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.ui.MaterialTable
import org.tlsys.admin.ui.ProcessBlocker
import org.tlsys.admin.ui.appendTo
import org.tlsys.api.ProcessService
import org.tlsys.await
import org.tlsys.core.Closeable
import org.tlsys.core.api.ImportService
import org.tlsys.file.RFile
import org.tlsys.gui.MessageDialog
import org.tlsys.json.JList
import org.tlsys.json.JsonNode
import org.tlsys.json.jdto
import org.tlsys.json.read
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.math.pow
import kotlin.math.roundToLong

class ImportPreviewStage(
    val columns: JList<ImportService.ExcelColumn>,
    val rowStart: Int,
    val rowEnd: Int,
    val allowDrop: Boolean,
    val makePlan: Boolean,
    preview: List<ImportService.MemberInfo>,
    val file: RFile,
    val importMode: ImportService.ProcessingMode,
) : DivComponentWithLayout(direction = FlexLayout.Direction.Column), Master.Stage<HTMLDivElement> {
    override val isFinallyStage: Boolean
        get() = false

    private val div = DivLayout(direction = FlexLayout.Direction.Column).appendTo(layout)
    private val table = MaterialTable().appendTo(div.layout)
    private val scroll = ScrollController(div.dom)

    init {
        val h = MaterialTable.Header()
        h.appendTo(table)
        val f =
            columns.any { it.type == ImportService.ExcelColumnType.F || it.type == ImportService.ExcelColumnType.FIO || it.type == ImportService.ExcelColumnType.FIO_CARD }
        val i =
            columns.any { it.type == ImportService.ExcelColumnType.I || it.type == ImportService.ExcelColumnType.FIO || it.type == ImportService.ExcelColumnType.FIO_CARD }
        val o =
            columns.any { it.type == ImportService.ExcelColumnType.O || it.type == ImportService.ExcelColumnType.FIO || it.type == ImportService.ExcelColumnType.FIO_CARD }
        val sex = columns.any { it.type == ImportService.ExcelColumnType.SEX }
        val phones = columns.any { it.type == ImportService.ExcelColumnType.PHONE }
        val emails = columns.any { it.type == ImportService.ExcelColumnType.EMAIL }
        val cards = columns.any { it.type == ImportService.ExcelColumnType.CARD_CODE }
        val bonusSum = columns.any { it.type == ImportService.ExcelColumnType.BONUS_SUM }
        val bonusFinishDate = columns.any { it.type == ImportService.ExcelColumnType.BONUS_FINISH_DATE }
        val tags = columns.any { it.type == ImportService.ExcelColumnType.TAGS }
        val payments = columns.any { it.type == ImportService.ExcelColumnType.PAYMENT }

        if (f) {
            MaterialTable.TextColumn("Фамилия").appendTo(h)
        }
        if (i) {
            MaterialTable.TextColumn("Имя").appendTo(h)
        }
        if (o) {
            MaterialTable.TextColumn("Отчество").appendTo(h)
        }
        if (sex) {
            MaterialTable.TextColumn("Пол").appendTo(h)
        }
        if (bonusSum) {
            MaterialTable.TextColumn("Сумма бонусов").appendTo(h)
        }
        if (bonusFinishDate) {
            MaterialTable.TextColumn("Дата сгорания бонусов").appendTo(h)
        }
        if (payments) {
            MaterialTable.TextColumn("Накопления").appendTo(h)
        }
        if (cards) {
            MaterialTable.TextColumn("Карты").appendTo(h)
        }
        if (tags) {
            MaterialTable.TextColumn("Метки").appendTo(h)
        }
        if (phones) {
            MaterialTable.TextColumn("Телефон").appendTo(h)
        }
        if (emails) {
            MaterialTable.TextColumn("E-mail").appendTo(h)
        }

        preview.forEach {
            val row = MaterialTable.Row().appendTo(table)
            if (f) {
                MaterialTable.TextColumn(it.lastName?.value ?: "").appendTo(row)
            }
            if (i) {
                MaterialTable.TextColumn(it.firstName?.value ?: "").appendTo(row)
            }
            if (o) {
                MaterialTable.TextColumn(it.middleName?.value ?: "").appendTo(row)
            }
            if (sex) {
                MaterialTable.TextColumn(if (it.gender?.value == true) "Мужской" else "Женский").appendTo(row)
            }
            if (bonusSum) {
                MaterialTable.TextColumn((it.bonusSum?.value)?.let { it.unfix(2) }?.toString() ?: "").appendTo(row)
            }
            if (bonusFinishDate) {
                val date = it.bonusFinishDate!!.value.asDateUtc
                MaterialTable.TextColumn("${date.asTimeString} ${date.asDateString}").appendTo(row)
            }
            if (payments) {
                MaterialTable.TextColumn((it.payments?.value)?.let { it.unfix(2) }?.toString() ?: "").appendTo(row)
            }
            if (cards) {
                MaterialTable.TextColumn(it.cards?.map { it.value }?.joinToString("<br/>") ?: "").appendTo(row)
            }
            if (tags) {
                MaterialTable.TextColumn(it.tags?.map { it.value }?.joinToString(", ") ?: "").appendTo(row)
            }
            if (phones) {
                MaterialTable.TextColumn(it.phones?.map { it.value }?.joinToString("<br/>") ?: "").appendTo(row)
            }
            if (emails) {
                MaterialTable.TextColumn(it.emails?.map { it.value }?.joinToString("<br/>") ?: "").appendTo(row)
            }
        }
    }

    override suspend fun next(): Master.Stage<out HTMLElement> {
        val result = ProcessBlocker.block("Импорт клиентов") {
            val processId = importService.importExcel(
                file = file,
                columns = columns,
                rowEnd = rowEnd.jdto,
                rowStart = rowStart.jdto,
                allowDrop = allowDrop.jdto,
                importMode = importMode.name.jdto,
                makePlan = makePlan.jdto,
            ).await()

            val process = processService.getProcess(processId).await()!!
            process.join(1000).await()
        }.await()
        return if (result != null) {
            val importResult = JsonNode.parse(result).read<ImportService.ImportResult>()
            ResultViewStage(importResult)
        } else {
            MessageDialog.showError("Не удается выполнить импорт клиентов").await()
            throw Master.NextStepAbortException()
        }
    }

    private val importService by Services.byClass(ImportService::class)
    private val processService by Services.byClass(ProcessService::class)

    override val title: String
        get() = "Предпросмотр"
    override val height: Int
        get() = 400
    override val valid: Boolean
        get() = true

    override fun onValidChange(listener: ValidListener): Closeable {
        return Closeable { }
    }

    class MemberInfoItem(val memberInfo: ImportService.MemberInfo) : DivComponentWithLayout()
}

fun Double.fix(count: Int = 2): Long = (this * 10.0f.pow(count)).roundToLong()
fun Float.fix(count: Int = 2): Long = (this * 10.0f.pow(count)).roundToLong()
fun Long.unfix(count: Int = 2): Float {
    if (this == 0L) {
        return 0f
    }
    return this / 10.0f.pow(count)
}
