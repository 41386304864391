package org.tlsys.members

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.cross.RemoteCartService2
import org.tlsys.admin.cross.RemoteGoodsService
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.api.RemoteCartService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.goods.DTOGood
import org.tlsys.goods.dialogs.GoodsSelectDialog
import org.tlsys.json.jdto
import org.tlsys.ui.DivComponentWithLayout
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.js.Promise

class CountDialog(val count: Long) : TitleDialog("Новая позиция") {
    val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val dd = EditText(placeHolder = "Количество товара")
        .textValidator(DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2))
        .text((count / 1000f).toString())
        .appendTo(layout, grow = 0, shrink = 0)
    private var cancelled = false

    init {
        dd.eventValidChange.on {
            body.okBtn.enabled = it
        }

        body.ok {
            cancelled = false
            close()
        }
        body.cancel {
            cancelled = true
            close()
        }
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        fun show(count: Long) = Promise<Long?> { d, c ->
            val dd = CountDialog(count = count)
            layoutNavigator.show(dd) {
                if (it.cancelled) {
                    d(null)
                } else {
                    d((it.dd.text.toFloat() * 1000f).toLong())
                }
            }
        }
    }
}

class CartFragment(val memberId: Long) : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    private val cartService by Services.byClass(RemoteCartService::class)
    private val scroll = ScrollController(dom)

//    val goodList = object : EditableList() {
//        override fun isValid(text: String): Boolean =
//                TextValidators.IS_PHONE.valid(text) == null && super.isValid(text)
//
//        override suspend fun createItemForAdd(): Item? {
//            return super.createItemForAdd()
//        }
//    }.appendTo(layout)

    override suspend fun onInit() {
        super.onInit()

        val items = cartService.getCart(memberId.jdto).await().map {
            console.log(it)
            Item(it).appendTo(table)
        }

        val codes = items.asSequence().map { it.card.goodCode.value }.distinct().toList()
        RemoteGoodsService.getByCodes(codes = codes, goods = true, groups = false).forEach { good ->
            items.forEach {
                if (it.card.goodCode.value == good.code) {
                    it.code.text = good.name
                }
            }
        }
    }

    private val table = MaterialTable()
        .appendTo(layout, grow = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Название").appendTo(header)
            MaterialTable.TextColumn("Кол-во").appendTo(header).widthPx(70)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    init {
        val addBtn = AddButton().appendTo(layout, grow = 0, shrink = 0).onClick {
            val good = (GoodsSelectDialog.show(goods = true, classifiers = false, groups = false).await() as DTOGood?)
                ?: return@onClick
            val count = CountDialog.show(1000L).await() ?: return@onClick
            val e = cartService.addToCart(
                memberId = memberId.jdto,
                goodCode = good.code.jdto,
                count = count.jdto,
            ).await()

            val item = Item(e).appendTo(table)
            Effects.createItem(item.dom)
            Effects.blockRow(item.dom)
            RemoteGoodsService.getByCodes(codes = listOf(good.code), goods = true, groups = false).forEach {
                if (it.code == item.card.goodCode.value) {
                    item.code.text = it.name
                }
            }
        }
    }

    private inner class Item(var card: RemoteCartService.CartGood) : MaterialTable.Row() {
        val code = MaterialTable.TextColumn(card.goodCode.value).appendTo(this)
        val count = MaterialTable.TextColumn((card.count.value / 1000f).toString()).appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        init {
            actions.component.visibleOnHover(this)
            actions.component.iconBtn(MaterialIcons.EDIT).onClick {
                async2 {
                    val newCount = CountDialog.show(card.count.value).await() ?: return@async2
                    RemoteCartService2.updateCount(
                        id = card.id.value,
                        member = memberId,
                        count = newCount,
                    )
                    card = RemoteCartService.CartGood(
                        id = card.id,
                        memberId = card.memberId,
                        goodCode = card.goodCode,
                        count = newCount.jdto,
                    )
                    count.text = (card.count.value / 1000f).toString()
                    Effects.blockRow(dom).await()
                }
            }
            actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                async2 {
                    cartService.removeFromCart(memberId = memberId.jdto, id = card.id).await()
                    Effects.removeItem(dom).await()
                    removeSelf()
                }
            }
        }
    }
}
