package org.tlsys.tags

import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.dto.MemberTagDTO
import org.tlsys.json.jdto
import org.tlsys.settings.Settings
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class TagsPage : AbstractPage() {
    override suspend fun getTitle(): String = TITLE

    companion object {
        val URI = "tags"
        val TITLE = "Метки"

        fun regPluginPage() {
            Settings.reg(uri = URI, name = TITLE, creator = {
                TagsPage()
            })
        }
    }

    private val scroll = ScrollController(dom)
    private val memberTagService by Services.byClass(MemberTagService::class)

//    val toolPlane = ToolsPlane(usingSearch = true).also { toolPlane ->
//        toolPlane.appendTo(contentLayout, grow = 0, shrink = 0)
//
//        toolPlane.search!!.addTextValidator(Validator {
//            try {
//                if (it.isBlank() || SimpleParse.parse(it) != null)//парсим лексемы
//                    null//если какие-то лексемы найдены, то все ок, начит все верно
//                else
//                    "Другая ошибка"//результат null. Не порядок
//            } catch (e: dynamic) {
//                "Ошибка"//случилась какая-то ошибка. Не порядок
//            }
//        })
//        val vv = toolPlane.search!!
//        toolPlane.search!!.onEnter {
//            if (!vv.valid)
//                return@onEnter
//            val search = if (vv.text.isBlank()) null else SimpleParse.parse(vv.text)
//            fragmentView.set2(TagFragment(search = vv.value))
//        }
//
//        toolPlane.image("add.svg", Styles.ICON_SIZE) {
//            async {
//                val newValue = TagEditDialog.show(null).await()?.trim() ?: return@async
//                val tag = memberTagService.createTag(newValue.jdto).await()
//                (fragmentView.view as TagFragment).addedElement(tag)
//            }
//        }
//    }

    private val table = MaterialTable().appendTo(contentLayout, grow = 0, shrink = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Название").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    init {
        AddButton()
            .appendTo(contentLayout, grow = 0, shrink = 0)
            .onClick {
                val newValue = TagEditDialog.show(null)?.trim() ?: return@onClick
                val tag = memberTagService.createTag(newValue.jdto).await()
                val item = Item(tag).appendTo(table)
                Effects.createItem(item.dom)
                Effects.blockRow(item.dom)
            }
    }

    override suspend fun onInit() {
        super.onInit()
        memberTagService.getTags(null, 0.jdto, (-1).jdto).await().forEach {
            Item(it).appendTo(table)
        }
    }

//    val fragmentView = ComponentView().appendTo(contentLayout, grow = 1, shrink = 1)
//
//    init {
//        fragmentView.set2(TagFragment(search = null))
//    }

    private inner class Item(var tag: MemberTagDTO) : MaterialTable.Row() {

        private val code = MaterialTable.TextColumn("")
            .appendTo(this)

        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        fun update() {
            code.text = tag.value
        }

        init {
            actions.component.visibleOnHover(this)
            update()

            actions.component.iconBtn(MaterialIcons.EDIT).onClick {
                async2 {
                    TagEditDialog.show(tag.value)?.let {
                        tag = memberTagService.changeTag(id = tag.id.jdto, value = it.jdto).await()
                        update()
                        Effects.blockRow(dom)
                    }
                }
            }

            actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                async2 {
                    if (!YesNoDialog.show("Удалить метку \"${tag.value}\"?", 400).await()) {
                        return@async2
                    }
                    memberTagService.removeTag(tag.id.jdto)
                    Effects.removeItem(dom).await()
                    removeSelf()
                }
            }
        }
    }
}
