package org.tlsys.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.builders.InputAttrsScope
import org.jetbrains.compose.web.dom.ElementScope
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLSpanElement

@Composable
fun Checkbox(
    defaultValue: Boolean,
    attrs: (InputAttrsScope<Boolean>.() -> Unit)? = null,
    func: @Composable ElementScope<HTMLSpanElement>.() -> Unit,
) {
    Label {
        Input(type = InputType.Checkbox, attrs = {
            if (defaultValue) {
                defaultChecked()
            }
            attrs?.invoke(this)
        })
        Span { func() }
    }
}
