package org.tlsys.shops

import org.tlsys.OPermission
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.ShopsService
import org.tlsys.await
import org.tlsys.dto.DTOShop
import org.tlsys.json.jdto
import org.tlsys.permission
import org.tlsys.ui.*
import org.tlsys.ui.Page
import org.tlsys.utils.promise
import pw.binom.web.layout.*

/**
 * Created by Igor on 15.02.2017.
 */
class ShopInfo(val id: Long) : AbstractPage() {
    private val shopsService by Services.byClass(ShopsService::class)
    val _currentShop by promise {
        shopsService.getShop(id.jdto).await()
    }

    override suspend fun getTitle(): String = _currentShop.await()?.title ?: "Объект #$id"
    private val allowCreateTerminal by permission(OPermission.CREATE_TERMINAL)
    var currentShop: DTOShop? = null

    /*val headLayout = FlexLayout.div {
        this.parent.classList.add("infoHead")
        contentLayout.add(this.parent) {
            direction = FlexLayout.Direction.Row
            shrink = 0
        }
    }*/

    val actions = FlexLayout.div {
        this.parent.classList.add("actions")
    }

//    val tools = ToolsPlane(usingSearch = false).appendTo(contentLayout, grow = 0, shrink = 0)

    val terminalsFragment = TerminalsListFragmentForShop(
        shopId = id,
        allowGotoTerm = true,
        allowDelete = true,
        allowEdit = true,
        allowAdd = allowCreateTerminal,
    )
        .appendTo(contentLayout, grow = 1, shrink = 1)

    override suspend fun onStart() {
        super.onStart()
    }

    override suspend fun next(path: String): Page? {
        if (path.startsWith("t")) {
            val terminalId = path.substring(1).toLongOrNull() ?: return super.next(path)
            return TerminalInfo(terminalId)
        }
        return super.next(path)
    }
}
