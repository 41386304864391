package org.tlsys

import kotlinx.browser.document
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.cms.ui.TimeCountSelector
import org.tlsys.core.Closeable
import org.tlsys.dto.DateDay
import org.tlsys.dto.toDate
import org.tlsys.dto.toDateDay
import org.tlsys.json.JsonFactory
import org.tlsys.json.JsonNode
import org.tlsys.json.jdto
import org.tlsys.node.api.GoodsService
import org.tlsys.script.*
import org.tlsys.script.Layout
import org.tlsys.script.Tabs
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLSpanElement
import pw.binom.date.DateTime
import pw.binom.date.iso8601
import pw.binom.date.parseIso8601Date
import pw.binom.web.AbstractComponent
import pw.binom.web.Component
import pw.binom.web.layout.*
import kotlin.collections.set

class ParamUIServiceImpl : ParamUIService {
    override fun build(settings: String, values: Map<String, String>?): ParamUIService.Params {
        if (settings.isEmpty()) {
            return EmptyParam()
        }
        val param = ParamImpl(settings)
        if (values != null) {
            param.root.setValue(values)
        }
        return param
    }
}

private class EmptyParam : ParamUIService.Params, AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    override fun values(): Map<String, String> = emptyMap()

    override val valid: Boolean
        get() = true

    override fun onValidChange(listener: ValidListener): Closeable =
        Closeable { }
}

private class ParamImpl(val body: String) : ParamUIService.Params, AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    val root = ui2sui(JsonFactory.read(JsonNode.parse(body)))

    override fun values(): Map<String, String> {
        val map = HashMap<String, String>()
        root.buildParams(map)
        return map
    }

    override val valid: Boolean
        get() = root.valid

    init {
        dom.appendChild(root.dom)
    }

    override suspend fun onStart() {
        super.onStart()
        root.onStart()
    }

    override suspend fun onStop() {
        root.onStop()
        super.onStop()
    }

    override fun onValidChange(listener: ValidListener): Closeable = root.onValidChange(listener)
}

private interface SUI<T : HTMLElement> : Component<T>, Validated {
    fun buildParams(map: MutableMap<String, String>)
    fun setValue(map: Map<String, String>)
}

private class STagPanel(dto: ITagPanel) : TagPanel(readOnly = false), SUI<HTMLDivElement> {
    private val id = dto.id
    private val memberTagService by Services.byClass(MemberTagService::class)
    override fun buildParams(map: MutableMap<String, String>) {
        map[id] = this.list.map { it.tag.id.toString() }.joinToString("|")
    }

    private var loading = false

    override fun setValue(map: Map<String, String>) {
        clear()
        val ids = map[id]
            ?.splitToSequence('|')
            ?.filter { it.isNotBlank() }
            ?.map {
                it.toLongOrNull()
            }
            ?.filterNotNull()
            ?.map { it.jdto }?.toList()?.jdto()
        if (ids != null) {
            async2 {
                loading = true
                listeners.forEach {
                    it.invoke(false)
                }
                addTag(memberTagService.getTagList(ids).await())
                loading = false
                listeners.forEach {
                    it.invoke(true)
                }
            }
        }
    }

    private val listeners = ArrayList<ValidListener>()

    override val valid: Boolean
        get() = !loading

    override fun onValidChange(listener: ValidListener): Closeable {
        listeners += listener
        return Closeable {
            listeners -= listener
        }
    }
}

private class SText(text: Text) : Span(text.text), SUI<HTMLSpanElement> {
    override fun buildParams(map: MutableMap<String, String>) {
    }

    override fun setValue(map: Map<String, String>) {
    }

    override val valid: Boolean
        get() = true

    init {
        addClass(Styles.SIMPLE_TEXT)
    }

    override fun onValidChange(listener: ValidListener): Closeable = Closeable { }
}

private class SLayout(val layout: Layout) : AbstractComponent<HTMLDivElement>(), SUI<HTMLDivElement> {
    override val dom: HTMLDivElement = document.createDiv()
    override fun setValue(map: Map<String, String>) {
        childs.forEach {
            it.setValue(map)
        }
    }

    override fun buildParams(map: MutableMap<String, String>) {
        childs.forEach {
            it.buildParams(map)
        }
    }

    override val valid: Boolean
        get() = validator.valid

    override fun onValidChange(listener: ValidListener): Closeable = validator.onValidChange(listener)

    private val flex = FlexLayout(this)
    private val validator = MultiValidator()
    private val childs = layout.childs.map {
        val el = ui2sui(it.element)
        validator.addValidated(el)
        el.appendTo(flex, grow = it.grow, shrink = it.shrink)
    }

    init {
        flex.direction = if (layout.vertical) {
            FlexLayout.Direction.Column
        } else {
            FlexLayout.Direction.Row
        }
    }

    override suspend fun onStart() {
        super.onStart()
        flex.onStart()
    }

    override suspend fun onStop() {
        flex.onStop()
        super.onStop()
    }
}

private class SInputString(private val id: String, placeHolder: String?, value: String) :
    EditText(
        placeHolder = placeHolder ?: "",
    ),
    SUI<HTMLDivElement> {

    override fun buildParams(map: MutableMap<String, String>) {
        map[id] = text
    }

    override fun setValue(map: Map<String, String>) {
        map[id]?.let { this.text = it }
    }

    init {
        this.text = value
    }
}

private class SLabel(value: Label) :
    LabelControlContener<SUI<out HTMLElement>>(
        value.title,
        ui2sui(value.ui),
    ),
    SUI<HTMLDivElement> {
    override fun buildParams(map: MutableMap<String, String>) {
        element.buildParams(map)
    }

    override fun setValue(map: Map<String, String>) {
        element.setValue(map)
    }

    override val valid: Boolean
        get() = element.valid

    override fun onValidChange(listener: ValidListener): Closeable = element.onValidChange(listener)
}

private class SInputGoodList(val data: InputGoodList) : Combobox2(), SUI<HTMLDivElement> {
    private val id = data.id
    private var value: String? = null
    override fun buildParams(map: MutableMap<String, String>) {
        map[id] = value ?: ""
    }

    override fun setValue(map: Map<String, String>) {
        value = map[id]?.takeIf { it.isNotBlank() }
    }

    private val goodsService by Services.byClass(GoodsService::class)

    private var inited = false
    override suspend fun onStart() {
        super.onStart()
        if (!inited) {
            console.info("Init GoodListSelector")
            enabled = false
            inited = true
            val list = goodsService.getDictionaries().await()
            items = listOf("Нет") + list.map { it.name }
            select = list.indexOfFirst { it.id == value } + 1
            eventChange.on {
                if (it == 0) {
                    value = null
                } else {
                    value = list[it - 1].id
                }
            }
            validator(Validator { if (data.canBeEmpty || it > 0) valid() else invalid("Список товаров не выбран") })
            enabled = true
        }
    }
}

private class SCheckbox(date: ICheckbox) : Checkbox(label = date.title, checked = date.default), SUI<HTMLDivElement> {
    private val id = date.id
    override fun buildParams(map: MutableMap<String, String>) {
        map[id] = if (checked) "true" else "false"
    }

    override fun setValue(map: Map<String, String>) {
        checked = map[id] == "true"
    }

    override val valid: Boolean
        get() = true

    override fun onValidChange(listener: ValidListener): Closeable = Closeable { }
}

private class SDataInput(date: InputDate) :
    DateEditText(
        placeHolder = date.holder,
        allowEmpty = date.canBeEmpty,
    ),
    SUI<HTMLDivElement> {
    private val id = date.id
    override fun buildParams(map: MutableMap<String, String>) {
        map[id] = date?.toDateDay()?.toString() ?: ""
    }

    override fun setValue(map: Map<String, String>) {
        date = map[id]?.takeIf { it.isNotBlank() }?.let { DateDay.parse(it).toDate() }
    }
}

private class SDatatimeInput(date: InputDatetime) :
    DatetimeEditor(date.default?.parseIso8601Date() ?: DateTime.now),
    SUI<HTMLDivElement> {
    private val id = date.id

    init {
        if (!date.canBeEmpty) {
            addValidator(Validator { if (it == null) invalid() else valid() })
        }
    }

    override fun buildParams(map: MutableMap<String, String>) {
        map[id] = date?.iso8601() ?: ""
    }

    override fun setValue(map: Map<String, String>) {
        date = map[id]?.takeIf { it.isNotBlank() }?.parseIso8601Date()
    }
}

private class SDuration(private val value: InputDuration) :
    TimeCountSelector(
        placeHolder = value.holder
            ?: "",
        canBeEmpty = value.canBeEmpty,
        maxAfterPoint = 2,
    ),
    SUI<HTMLDivElement> {
    private val id = value.id
    override fun buildParams(map: MutableMap<String, String>) {
        map[id] = time?.toString() ?: ""
    }

    override fun setValue(map: Map<String, String>) {
        time = map[id]?.toLongOrNull() ?: value.defaultValue
        console.info("Set time: $time")
    }

    override suspend fun onStart() {
        super.onStart()
        console.info("Make Autoformat")
        autoTimeFormat()
    }
}

private class STabs(val dto: Tabs) :
    DivComponentWithLayout(direction = FlexLayout.Direction.Column),
    SUI<HTMLDivElement> {
    private val tabs = org.tlsys.admin.ui.Tabs().appendTo(layout, 0, 0)
    private val comView = ComponentView().appendTo(layout, 1, 1)

    private val components = ArrayList<SUI<out HTMLElement>>()

    init {
        comView.dom.style.paddingTop = 10.px
        dto.tabs.forEachIndexed { index, tab ->
            val com = ui2sui(tab.ui)
            components.add(com)
            tabs.add(tab.title) {
                comView.set2(com)
            }
        }
    }

    override suspend fun onStart() {
        super.onStart()
        val com = components.firstOrNull() ?: return
        tabs.active = 0
        console.info("Start Tab")
        comView.onStart()
    }

    override suspend fun onStop() {
        comView.onStop()
        super.onStop()
    }

    override fun buildParams(map: MutableMap<String, String>) {
        components.forEach {
            it.buildParams(map)
        }
    }

    override fun setValue(map: Map<String, String>) {
        components.forEach {
            it.setValue(map)
        }
    }

    override val valid: Boolean
        get() = !components.any { !it.valid }

    override fun onValidChange(listener: ValidListener): Closeable {
        val list = components.map {
            it.onValidChange(listener)
        }
        return Closeable {
            list.forEach {
                it.close()
            }
        }
    }
}

private fun ui2sui(ui: UI): SUI<out HTMLElement> =
    when (ui) {
        is Layout -> SLayout(ui)
        is InputString -> SInputString(id = ui.id, placeHolder = ui.holder, value = ui.default).also {
            if (!ui.canBeEmpty) {
                it.textValidator(TextValidators.NOT_BLANK)
            }
        }

        is Label -> SLabel(ui)
        is Text -> SText(ui)
        is InputDuration -> SDuration(ui)
        is InputDate -> SDataInput(ui)
        is InputDatetime -> SDatatimeInput(ui)
        is InputGoodList -> SInputGoodList(ui)
        is ICheckbox -> SCheckbox(ui)
        is ITagPanel -> STagPanel(ui)
        is Tabs -> STabs(ui)
        is InputInteger -> SInputString(
            id = ui.id,
            placeHolder = ui.holder,
            value = ui.default?.toString() ?: "",
        )
            .also {
                if (!ui.canBeEmpty) {
                    it.textValidator(TextValidators.NOT_BLANK and IntegerValidator.FORMAT)
                } else {
                    it.textValidator(TextValidators.BLANK or IntegerValidator.FORMAT)
                }
            }

        is FloatString -> SInputString(
            id = ui.id,
            placeHolder = ui.holder,
            value = ui.default?.toString() ?: "",
        )
            .also {
                if (!ui.canBeEmpty) {
                    it.textValidator(TextValidators.NOT_BLANK and IntegerValidator.FORMAT)
                } else {
                    it.textValidator(TextValidators.BLANK or DoubleValidator.FORMAT)
                }
            }

        else -> TODO("Not Implement for ${ui::class.simpleName}")
    }
