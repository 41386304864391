package org.tlsys

import kotlinx.serialization.Serializable

@Serializable
enum class TemplateType(val title: String) {
    EMAIL(title = "Электронная почта"),
    SMS(title = "SMS"),
    TELEGRAM(title = "Telegram"),
}
