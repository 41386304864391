package org.tlsys.dto

import kotlin.jvm.JvmStatic

class DateDay(val year: Int, val month: Int, val day: Int) {
    companion object {
        @JvmStatic
        fun parse(str: String): DateDay {
            val list = str.split('-')
            return DateDay(
                year = list[0].toInt(),
                month = list[1].toInt(),
                day = list[2].toInt()
            )
        }
    }

//    fun toDate(hh: Int, mm: Int, sec: Int, ms: Int) =
//        Date.of(
//            year = year, month = month,
//            dayOfMonth = day,
//            hours = hh,
//            minutes = mm,
//            seconds = sec,
//            millis = ms,
//            timeZoneOffset = 0
//        )

    override fun toString() = "$year-$month-$day"
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as DateDay

        if (year != other.year) return false
        if (month != other.month) return false
        if (day != other.day) return false

        return true
    }

    override fun hashCode(): Int {
        var result = year
        result = 31 * result + month
        result = 31 * result + day
        return result
    }

    operator fun compareTo(other: DateDay): Int {
        (year - other.year).let { if (it != 0) return it }
        (month - other.month).let { if (it != 0) return it }
        (day - other.day).let { if (it != 0) return it }
        return 0
    }
}
