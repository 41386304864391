package org.tlsys.lk

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.admin.serialization.DateWrapper
import org.tlsys.admin.serialization.RList
import org.tlsys.serialization.DateTimeSerializer
import pw.binom.date.DateTime
import pw.binom.rpc.CrossService

interface CrossOsmiService : CrossService {
    companion object {
        const val CONFIG = "config"
        const val VALUE = "value"
        const val TEMPLATE = "template"
        const val DATE = "DATE"
        const val LIMIT = "LIMIT"
        const val SERIAL = "SERIAL"
    }

    override val name: String
        get() = "osmi_service"

    val _updateConfig: CrossService.CrossMethod<Unit>
    val _getConfig: CrossService.CrossMethod<OsmiConfigDTO>
    val _getEnabled: CrossService.CrossMethod<Boolean>
    val _setEnabled: CrossService.CrossMethod<Unit>
    val _getTemplates: CrossService.CrossMethod<RList<OsmicardTemplateDto>>
    val _editTemplate: CrossService.CrossMethod<OsmicardTemplateDto?>
    val _refreshTemplates: CrossService.CrossMethod<RList<OsmicardTemplateDto>>
    val _getMasterTemplateValue: CrossService.CrossMethod<String?>
    val _getEvents: CrossService.CrossMethod<RList<OsmicardEventDto>>
    val _emmitEvent: CrossService.CrossMethod<Unit>
}

@Serializable
data class OsmicardEventDto(
    val simulated: Boolean,
    val serial: String,
    val event: OsmicardEvent,
    @Serializable(DateTimeSerializer::class)
    val createdAt: DateTime,
)

@Serializable
data class OsmiConfigDTO(
    val prefix: String,
    val apiId: String,
    val apiKey: String,
    val allowDropCreatedCard: Boolean,
    val greetingBonusAmount: Long,
    val greetingBonusStart: Long,
    val greetingBonusEnd: Long? = null,
    val referentBonusAmount: Long,
    val referentBonusStart: Long,
    val referentBonusEnd: Long? = null,
    val referalLabel: String,
    val nameLabel: String,
    val bonusLabel: String,
    val referalText: String,
    val enableReferalSystem: Boolean,
    val enableGreeting: Boolean,
)

suspend inline fun CrossOsmiService.updateConfig(config: OsmiConfigDTO) =
    _updateConfig(mapOf(CrossOsmiService.CONFIG to config))

suspend inline fun CrossOsmiService.getConfig() = _getConfig(mapOf())
suspend inline fun CrossOsmiService.getEnabled() = _getEnabled(mapOf())
suspend inline fun CrossOsmiService.getTemplates() = _getTemplates(mapOf())
suspend inline fun CrossOsmiService.refreshTemplates() = _refreshTemplates(mapOf())
suspend inline fun CrossOsmiService.emmitEvent(event: OsmicardEventDto) =
    _emmitEvent(mapOf(CrossOsmiService.VALUE to event))

suspend inline fun CrossOsmiService.getEvents(date: DateTime?, limit: Int, serial: String?) = _getEvents(
    mapOf(
        CrossOsmiService.DATE to date?.let { DateWrapper(it) },
        CrossOsmiService.LIMIT to limit,
        CrossOsmiService.SERIAL to serial,
    )
)

suspend inline fun CrossOsmiService.editTemplate(template: OsmicardTemplateDto) =
    _editTemplate(mapOf(CrossOsmiService.VALUE to template))

suspend inline fun CrossOsmiService.setEnabled(value: Boolean) = _setEnabled(mapOf(CrossOsmiService.VALUE to value))
suspend inline fun CrossOsmiService.getMasterTemplateValue(templateName: String, valueName: String) =
    _getMasterTemplateValue(
        mapOf(
            CrossOsmiService.TEMPLATE to templateName,
            CrossOsmiService.VALUE to valueName
        )
    )

@Serializable
class OsmicardTemplateDto(
    val id: String,
    val name: String,
    val tags: RList<Long>,
    val values: RList<TagValue>,
) {
    @Serializable
    class TagValue(val name: String, val value: String)
}

@Serializable
enum class OsmicardEvent(val code: String, val description: String) {
    /**
     * Содержимое карты изменилось
     */
    @SerialName("cardupdate")
    CARDUPDATE(
        code = "cardupdate",
        description = "Содержимое карты изменилось",
    ),

    /**
     * Автоматически создана новая карта из маркетинговой кампании
     */
    @SerialName("cardautocreate")
    CARDAUTOCREATE(
        code = "cardautocreate",
        description = "Автоматически создана новая карта из маркетинговой кампании",
    ),

    /**
     * Создана новая карта, включая автоматические карты из маркетинговой кампании
     */
    @SerialName("cardcreate")
    cardcreate(
        code = "cardcreate",
        description = "Создана новая карта, включая автоматические карты из маркетинговой кампании",
    ),

    /**
     * Пользователь успешно заполнил и отправил он-лайн анкету в процессе саморегистрации карты
     */
    @SerialName("userregister")
    userregister(
        code = "userregister",
        description = "Пользователь успешно заполнил и отправил он-лайн анкету в процессе саморегистрации карты",
    ),

    /**
     * Пользователь установил карту на телефон или разрешил ранее запрещенное автообновления для
     * установленной карты. Срабатывает каждый раз на регистрацию телефона с сервером на получение обновлений.
     * Если карта устанавливается на нескольких устройствах, то каждое устройство генерит свое событие по факту
     * регистрации с сервером.
     */
    @SerialName("cardactive")
    cardactive(
        code = "cardactive",
        description = "Пользователь установил карту. Вызывается один раз",
    ),

    /**
     * Пользователь установил карту на телефон или разрешил ранее запрещенное автообновления для установленной
     * карты. Срабатывает один раз, когда общее количество устройств с картой становится равным 1 (одно устройство).
     * Срабатывает только при переходе количества устройств с 0 в 1 и не наоборот.
     */
    @SerialName("cardactive1")
    CARDACTIVE1(
        code = "cardactive1",
        description = "Пользователь установил карту. Вызывается каждый раз",
    ),

    /**
     * Пользователь удалил карту с телефона или выключил ранее разрешенное автообновления для установленной карты.
     * Если карта стоит на нескольких устройствах, то каждое устройство генерит свое событие по факту удаления регистрации с сервером.
     */
    @SerialName("cardinactive")
    cardinactive(
        code = "cardinactive",
        description = "Пользователь удалил карту с телефона",
    ),

    /**
     * Карта была удалена со всех телефонов или автообновление выключено на всех телефонах с картой. Событие
     * срабатывает один раз когда не осталось активных устройств с картой.
     */
    @SerialName("cardnodevices")
    CARD_NO_DEVICES(
        code = "cardnodevices",
        description = "Карта была удалена со всех телефонов",
    ),

    /**
     * Телефон загрузил с сервера обновленную карту
     */
    @SerialName("carddownload")
    carddownload(
        code = "carddownload",
        description = "Телефон загрузил с сервера обновленную карту",
    ),

    /**
     * Вызывалась функция API по обновлению чоп карты с новым уровнем лояльности
     */
    @SerialName("chopscan")
    chopscan(
        code = "chopscan",
        description = "Вызывалась функция API по обновлению чоп карты с новым уровнем лояльности",
    ),

    /**
     * Получен входящий СМС запрос на выдачу карты
     */
    @SerialName("smsrequest")
    smsrequest(
        code = "smsrequest",
        description = "Получен входящий СМС запрос на выдачу карты",
    );

    companion object {
        fun find(code: String): OsmicardEvent? {
            val ser = serializer()
            for (i in 0 until ser.descriptor.elementsCount) {
                if (ser.descriptor.getElementName(i) == code) {
                    return values()[i]
                }
            }
            return null
        }
    }
}
