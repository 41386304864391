package org.tlsys.core.api

import org.tlsys.Dto
import org.tlsys.file.RFile
import org.tlsys.json.*
import kotlin.js.JsName

interface ImportService {

    enum class ProcessingMode {
        ONLY_CREATE,
        ONLY_UPDATE,
        CREATE_OR_UPDATE,
    }

    @JsName("checkImportExcel")
    fun checkImportExcel(file: RFile, rowStart: JInt, rowEnd: JInt, columns: JList<ExcelColumn>): AsyncResult<JList<JString>>

    @JsName("getPreviewImportExcel")
    fun getPreviewImportExcel(file: RFile, rowStart: JInt, rowEnd: JInt, columns: JList<ExcelColumn>): AsyncResult<JList<MemberInfo>>

    @JsName("importExcel")
    fun importExcel(
        file: RFile,
        rowStart: JInt,
        rowEnd: JInt,
        allowDrop: JBoolean,
        columns: JList<ExcelColumn>,
        importMode: JString,
        makePlan: JBoolean,
    ): AsyncResult<JString>

    @Dto
    class ExcelColumn(val typeStr: JString, val column: JString) : JDTO {
        companion object : AutoJDTOFactory<ExcelColumn>(ExcelColumn::class)

        val type: ExcelColumnType
            get() = ExcelColumnType.valueOf(typeStr.value)

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }

    @Dto
    class MemberInfo(
        val lastName: JString?,
        val firstName: JString?,
        val middleName: JString?,
        val gender: JBoolean?,
        val bonusSum: JLong?,
        val payments: JLong?,
        val bonusFinishDate: JLong?,
        val cards: JList<JString>?,
        val phones: JList<JString>?,
        val emails: JList<JString>?,
        val tags: JList<JString>?,
    ) : JDTO {
        companion object : AutoJDTOFactory<MemberInfo>(MemberInfo::class)

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }

    @Dto
    class ImportResult(val reportFile: JString, val notImportedClientsCount: JInt, val warningCount: JInt) : JDTO {
        companion object : AutoJDTOFactory<ImportResult>(ImportResult::class)

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }

    enum class ExcelColumnType(val rus: String) {
        FIO("ФИО"),
        FIO_CARD("ФИО и Карта"),
        F("Фамилия"),
        I("Имя"),
        O("Отчество"),
        BONUS_SUM("Сумма бонусов"),
        BONUS_FINISH_DATE("Дата сгорания бонусов"),
        CARD_CODE("Карта"),
        PHONE("Телефон"),
        EMAIL("Электронная почта"),
        SEX("Пол"),
        TAGS("Метки"),
        PAYMENT("Накопления");

        companion object {
            fun valueOfOrNull(name: String) = values().find { it.name == name }
        }
    }
}
