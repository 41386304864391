package org.tlsys.members

import kotlinx.browser.localStorage
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.cms.long
import org.tlsys.cms.string
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import pw.binom.web.layout.*

class HoldBalansDialog private constructor(val actionFunc: suspend (amount: Float, description: String?) -> Boolean) :
    TitleDialog(MemberInfoPage.HOLD_BALANS) {

    init {
        dom.style.width = 320.px
        dom.style.height = 220.px
    }

    private var _canceled = false
    val canceled: Boolean
        get() = _canceled

    private val body = SaveCancelBody().apply {
        layout.add(dom) {
            grow = 1
            shrink = 1
        }
    }

    private var oldsum by localStorage.long("hold_balans_dialog:sum")
    private var oldDiscription by localStorage.string("hold_balans_dialog:description")

    val deltaSum = EditText(placeHolder = "Сумма")
        .textValidator(DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2))
        .text(oldsum?.let { it / 100f }?.toString() ?: "0")
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)
    val description =
        EditText(placeHolder = "Описание").appendTo(body.componentsLayout, grow = 0, shrink = 0)
            .text(oldDiscription ?: "")

    fun refreshState() {
        body.okBtn.enabled = deltaSum.valid
    }

    init {
        deltaSum.eventChange.on {
            refreshState()
        }

        body.cancel {
            cancelClick()
        }

        body.ok {
            actionFunc(
                deltaSum.text.toFloat(),
                description.text.takeIf { it.isNotBlank() },
            )
            oldsum = (deltaSum.text.toFloat() * 100f).toLong()
            _canceled = false
            close()
        }
        refreshState()
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    companion object {
        suspend fun show(f: suspend (amount: Float, description: String?) -> Boolean): Boolean {
            val d = HoldBalansDialog(f)
            d.show()
            return d.canceled
        }
    }
}
