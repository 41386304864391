package org.tlsys

enum class TimeType(val msValue: Long) {
    MS(1),
    SECONDS(1_000),
    MINUTE(1_000 * 60),
    HOUR(1_000 * 60 * 60),
    DAY(1_000 * 60 * 60 * 24),
    WEEK(1_000 * 60 * 60 * 24 * 7),
    ;

    fun convert(time: Long): Double = time.toDouble() / msValue.toDouble()

    fun getSuffex(value: Double): String {
        val unit = when (this) {
            TimeType.MS -> "ms"
            TimeType.SECONDS -> "s"
            TimeType.MINUTE -> "m"
            TimeType.HOUR -> "h"
            TimeType.DAY -> "d"
            TimeType.WEEK -> "w"
        }
        return unit
    }

    fun getSuffixRus(value: Double): String =
        when (this) {
            TimeType.MS -> "мс"
            TimeType.SECONDS -> "сек"
            TimeType.MINUTE -> "мин"
            TimeType.HOUR -> "час"
            TimeType.DAY -> "ден"
            TimeType.WEEK -> "нед"
        }
}
