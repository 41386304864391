package org.tlsys.promotion.dto

import kotlinx.serialization.Serializable

@Serializable
class TagSetterConfig(
    val addTags: List<Long> = emptyList(),
    val removeTags: List<Long> = emptyList(),
    override val filter: TagFilterDtoWithShop = TagFilterDtoWithShop(),
) : WithFilterByTagAndShops
