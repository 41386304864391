package org.tlsys

import kotlinx.serialization.Serializable
import org.tlsys.serialization.EmailSerializer
import kotlin.jvm.JvmInline

@Serializable(EmailSerializer::class)
@JvmInline
value class Email constructor(val raw: String) {
    companion object {
        fun of(email: String): Email? {
            val trimedEmail = email.trim()
            if (trimedEmail.length < 3) {
                return null
            }
            if (" " in trimedEmail) {
                return null
            }
            if (trimedEmail.count { it == '@' } != 1) {
                return null
            }
            if (trimedEmail[0] == '@' || trimedEmail[trimedEmail.lastIndex] == '@') {
                return null
            }
            return Email(trimedEmail)
        }
    }

    override fun toString(): String = raw
}

fun String.toEmail() = this.toEmailOrNull() ?: throw IllegalArgumentException("Email \"$this\" is invalid")
fun String.toEmailOrNull() = Email.of(this)
