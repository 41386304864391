package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.admin.childElements
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.con
import org.tlsys.ui.createDiv
import org.tlsys.ui.on
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.FlexLayout

class ToolsPlane(val usingSearch: Boolean) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    companion object Style {
        val PLANE = CSS.genName()
        val BUTTON = CSS.genName()
        val IMAGE = CSS.genName()

        init {
            CSS {
                ".$PLANE" {
                    height = 34.px
                    val baseBtn = template {
                        cursor = "pointer"
                        margin = "0px 12px 0px 12px"
                    }
                    padding = "16px 0px 16px 0px"
                    ".$IMAGE" {
                        border = "0"
                        backgroundColor = "transparent"
                        backgroundRepeat = "no-repeat"
                        backgroundPosition = "center"
                        extend(baseBtn)
                        ":disabled" then {
                            cursor = "default"
                            filter = "grayscale(1)"
                        }
                    }
                    ".$BUTTON" {
                        backgroundColor = "#fafafa"
                        border = "1px solid #3bafda"
                        borderRadius = 4.px
                        padding = "0px 32px 0px 32px"
                        fontFamily = Styles.DEFAULT_TEXT_FONT
                        fontSize = 12.px
                        marginLeft = 5.px
                        color = "#3bafda"
                        extend(baseBtn)
                        ":disabled" then {
                            cursor = "default"
                            color = "#CCC"
                            borderColor = "#CCC"
                        }
                    }
                }
            }
        }
    }

    init {
        dom.classList.add(PLANE)
    }

    val layout = FlexLayout(dom, direction = FlexLayout.Direction.Row)

    val search = if (!usingSearch) null else SearchInput(placeHolder = "Поиск").apply {
        dom.style.width = "233px"
        layout.add(dom)
        // this@ToolsPlane.dom.appendChild(dom)
    }

    val buttonsLayout = FlexLayout.div {
        justifyContent = FlexLayout.JustifyContent.End
        layout.add(parent) {
            grow = 1
            shrink = 1
        }
    }

    fun button(text: String, clickListener: (Button) -> Unit) = Button(text).apply {
        dom.classList.add(BUTTON)
        buttonsLayout.add(dom)
        dom.on("click") {
            clickListener(this@apply)
        }
    }

    fun image(data: String, width: Int, clickListener: (Button) -> Unit) = Button("").apply {
        dom.classList.add(IMAGE)
        dom.style.backgroundImage = "url($data)"
        dom.style.width = width.px
        dom.style.margin = "0px 10px"
        buttonsLayout.add(dom)
        dom.on("click") {
            clickListener(this@apply)
        }
    }

    var enabled: Boolean = true
        set(value) {
            search?.enabled = value

            buttonsLayout.dom.childElements.forEach {
                val con = it.con()
                if (con != null && con is Button) {
                    con.enabled = value
                }
            }

            field = value
        }

    /*
    val simpleBtn = Button("ADD MEMBER").apply {
        buttonsLayout.add(dom)
    }
    */

    /*
    val simpleFloatBtn = Button("FILTER").apply {
        dom.addClass("floatButton")
        buttonsLayout.add(dom)
    }
    */
}
