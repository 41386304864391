package org.tlsys.dto

import org.tlsys.json.*

enum class SearchSource {
    NAME, PHONE, EMAIL, CARD
}

class MemberTagDTO(val id: Long, val value: String) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<MemberTagDTO> {
        override val type: String
            get() = "MemberTagDTO"

        override fun read(node: JsonNode) = MemberTagDTO(
            id = node["id"]!!.long(),
            value = node["value"]!!.string()
        )

        override fun write(obj: MemberTagDTO) = JsonNode.obj()
            .set("id", obj.id.json)
            .set("value", obj.value.json)

    }
}

class MemberDTO(
    val id: Long,
    val created: Long,
    val firstName: String,
    val middleName: String?,
    val lastName: String,
    val sex: Boolean,
    val active: Boolean,
    val birthday: Long?,
    val allowBalans: Float,
    val notAllowBalans: Float,
    val testPromotion: Boolean,
    val cardPrInstallDate: Long?,
    val allowSub: Boolean,
    val osmiCardCreated: Long?,
    val osmiCardInstalled: Long?,
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<MemberDTO> {
        override val type: String
            get() = "MemberDTO"

        override fun read(node: JsonNode) = MemberDTO(
            id = node["id"]!!.long(),
            created = node["created"]!!.long(),
            firstName = node["firstName"]!!.string(),
            middleName = node["middleName"]?.string(),
            lastName = node["lastName"]!!.string(),
            sex = node["sex"]!!.boolean(),
            active = node["active"]!!.boolean(),
            birthday = node["birthday"]?.long(),
            allowBalans = node["allowBalans"]!!.float(),
            notAllowBalans = node["notAllowBalans"]!!.float(),
            cardPrInstallDate = node["cardPrInstallDate"]?.long(),
            testPromotion = node["testPromotion"]?.boolean() ?: false,
            allowSub = node["allowSub"]?.boolean() ?: false,
            osmiCardCreated = node["osmiCardCreated"]?.long(),
            osmiCardInstalled = node["osmiCardInstalled"]?.long(),
        )

        override fun write(obj: MemberDTO) = JsonNode.obj()
            .set("id", obj.id.json)
            .set("created", obj.created.json)
            .set("firstName", obj.firstName.json)
            .set("middleName", obj.middleName?.json)
            .set("lastName", obj.lastName.json)
            .set("sex", obj.sex.json)
            .set("active", obj.active.json)
            .set("birthday", obj.birthday?.json)
            .set("id", obj.id.json)
            .set("allowBalans", obj.allowBalans.json)
            .set("notAllowBalans", obj.notAllowBalans.json)
            .set("cardPrInstallDate", obj.cardPrInstallDate?.json)
            .set("testPromotion", obj.testPromotion.json)
            .set("allowSub", obj.allowSub.json)
            .set("osmiCardCreated", obj.osmiCardCreated?.json)
            .set("osmiCardInstalled", obj.osmiCardInstalled?.json)
    }

    val fullName: String
        get() {
            val sb = StringBuilder()
            if (lastName.isNotBlank())
                sb.append(lastName)

            if (firstName.isNotEmpty()) {
                if (sb.isNotEmpty())
                    sb.append(" ")
                sb.append(firstName)
            }

            if (!middleName.isNullOrBlank()) {
                if (sb.isNotEmpty())
                    sb.append(" ")
                sb.append(middleName)
            }
            return sb.toString()
        }
}

class HoldRecordDto(
    val id: JString,
    val amount: JLong,
    val userId: JLong?,
    val createDate: JLong,
    val description: JString?
) : JDTO {
    constructor() : this(
        id = JString.EMPTY,
        amount = 0L.jdto,
        userId = null,
        createDate = 0L.jdto,
        description = null,
    )

    companion object : AutoJDTOFactory<HoldRecordDto>(HoldRecordDto::class)

    override val factory: JDTOFactory<JDTO>
        get() = asDefault
}

class AccountDTO(
    val allowAdd: Boolean,
    val allowSub: Boolean,
    val enabled: Boolean,
    val id: Long,
    val memberId: Long,
    val info: BalansInfoDTO? = null
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<AccountDTO> {
        override val type: String
            get() = "AccountDTO"

        override fun read(node: JsonNode) = AccountDTO(
            allowAdd = node["allowAdd"]!!.boolean(),
            allowSub = node["allowSub"]!!.boolean(),
            enabled = node["enabled"]!!.boolean(),
            id = node["id"]!!.long(),
            memberId = node["memberId"]!!.long(),
            info = node["info"]?.let { JsonFactory.read<BalansInfoDTO>(it) }
        )

        override fun write(obj: AccountDTO) = JsonNode.obj()
            .set("allowAdd", obj.allowAdd.json)
            .set("allowSub", obj.allowSub.json)
            .set("enabled", obj.enabled.json)
            .set("id", obj.id.json)
            .set("memberId", obj.memberId.json)
            .set("info", obj.info?.write())
    }
}

class MemberBalans(val member: JLong, val allow: JLong, val notAllow: JLong) : JDTO {
    protected constructor() : this(0L.jdto, 0L.jdto, 0L.jdto)

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : AutoJDTOFactory<MemberBalans>(MemberBalans::class)
}

class BalansInfoDTO(val accountId: Long, val allow: Float, val notAllow: Float) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<BalansInfoDTO> {
        override val type: String
            get() = "BalansInfoDTO"

        override fun read(node: JsonNode) = BalansInfoDTO(
            accountId = node["accountId"]!!.long(),
            notAllow = node["notAllow"]!!.float(),
            allow = node["allow"]!!.float()
        )

        override fun write(obj: BalansInfoDTO) = JsonNode.obj()
            .set("accountId", obj.accountId.json)
            .set("notAllow", obj.notAllow.json)
            .set("allow", obj.allow.json)


    }
}