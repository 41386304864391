package org.tlsys.shops

import kotlinx.browser.document
import kotlinx.dom.clear
import org.tlsys.admin.ui.AbstractFragment
import org.tlsys.admin.ui.StaticTableDataProvider
import org.tlsys.admin.ui.Table
import org.tlsys.cms.Extensions
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

/**
 * Created by Igor on 08.03.2017.
 */

class DistributionFragment(val termId: Long) : AbstractFragment<HTMLDivElement>(document.createDiv()) {
    val layout = FlexLayout(dom).apply {
        direction = FlexLayout.Direction.Column
    }

    private companion object {
        val activeFragments = ArrayList<DistributionFragment>()
    }

    val data = ArrayList<Extensions.TerminalDistributionItem>()

    var model = StaticTableDataProvider<Extensions.TerminalDistributionItem> {
        column("") {
            it.body.clear()
            it.body.style.backgroundImage = it.data.iconurl
            it.body.style.backgroundColor = "transparent"
            it.body.style.backgroundRepeat = "no-repeat"
            it.body.style.backgroundPosition = "center"
            it.body.style.border = "0"
            it.body.style.width = 24.px
            it.body.style.height = 24.px
        }
        column("Название") {
            val v = it.data
            it.body.innerText = it.data.title
            it.body.style.backgroundPosition = "center"
            it.body.style.width = 424.px
            it.body.onclick = {
                v.onClick(termId)
            }
            it.body.classList.add("link")
        }
    }

    var table: Table? = null

    fun refresh() {
        model.clear()
        model.addAll(data)
        if (table != null) {
            table!!.refresh()
        }
    }

    init {
        Extensions.terminalDistributionItem.use { el ->
            data.add(el.value)
            el.close {
                data.remove(el.value)
                refresh()
            }
            refresh()
        }

        // model.addAll(data)
        table = Table(model = model, render = model.render)
        layout.add(table!!.dom)
        table!!.refresh()
    }
}
