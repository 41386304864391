package org.tlsys.jobs.components

import libs.asDateString
import libs.asDateUtc
import libs.asTimeString
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.core.AsyncListIterator
import org.tlsys.jobs.api.JobAdminService
import org.tlsys.jobs.dialogs.ExecutionDialog
import org.tlsys.jobs.dto.ExecutionDTO
import org.tlsys.ui.*
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class ExecutionList(val iterator: AsyncListIterator<ExecutionDTO>) :
    DivComponentWithLayout(direction = FlexLayout.Direction.Column) {

    //    private val list = LoadableListView(iterator.map { Item(it) }).appendTo(layout)
    private val jobAdminService by Services.byClass(JobAdminService::class)

    private val table = MaterialTable().appendTo(layout, grow = 0, shrink = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Запуск").appendTo(header).widthPx(120)
            MaterialTable.TextColumn("Окончание").appendTo(header).widthPx(120)
            MaterialTable.TextColumn("Название").appendTo(header)
            MaterialTable.TextColumn("Вложение").appendTo(header).widthPx(1)
        }
    private val scroll = ScrollController(dom)
    override suspend fun onInit() {
        super.onInit()
        while (!scroll.visibleScrollY && iterator.hasNext()) {
            Item(iterator.next()).appendTo(table)
        }

        var loading = false
        scroll.addScrollListener {
            if (loading) {
                return@addScrollListener
            }
            async2 {
                if (scroll.endScrollY && iterator.hasNext()) {
                    loading = true
                    for (i in 0..9) {
                        if (!iterator.hasNext()) {
                            break
                        }
                        Item(iterator.next()).appendTo(table)
                    }
                    loading = false
                }
            }
        }
    }

    private inner class Item(val exe: ExecutionDTO) : MaterialTable.Row() {
        private val start = exe.start.value.asDateUtc
        private val end = exe.end?.value?.asDateUtc
//        private val layout = FlexLayout(this, alignItems = FlexLayout.AlignItems.Center)
//        private val startLabel = Span("${start.asDateString} ${start.asTimeString}").appendTo(layout).addClass(Styles.SIMPLE_TEXT)
//        private val endLabel = Span().appendTo(layout).addClass(Styles.SIMPLE_TEXT)
//        private val jobName = Span().appendTo(layout).addClass(Styles.SIMPLE_TEXT)

        private val startLabel = MaterialTable.TextColumn("${start.asDateString} ${start.asTimeString}").appendTo(this)
        private val endLabel = MaterialTable.TextColumn("").appendTo(this)
        private val jobName = MaterialTable.TextColumn("").appendTo(this)
        private val attachment = MaterialTable.ComponentColumn(Span()).appendTo(this)
//        private val executionCount = Span(exe.files.size.toString()).appendTo(layout, grow = 0, shrink = 0).addClass(Styles.SIMPLE_TEXT)

        private val job by lazy {
            jobAdminService.getJob(exe.jobId).promise
        }

        init {
            dom.style.cursor = "pointer"
            endLabel.text = if (end == null) "Выполняется" else "${end.asDateString} ${end.asTimeString}"
            addClass(Styles.LIST_ITEM_STYLE)
            if (exe.files.isNotEmpty()) {
                attachment.component.text = "attachment"
                attachment.component.dom.addClass("material-icons")
//                val div = document.createDiv()
//                div.style.apply {
//                    background = "url('attachment.svg')"
//                    width = (Styles.ICON_SIZE * 0.9).px
//                    height = (Styles.ICON_SIZE * 0.9).px
//                }
//                div.appendTo(layout, grow = 0, shrink = 0)
            }

            dom.onclick = {
                async2 {
                    ExecutionDialog.show(exe)
                }
            }
        }

        override suspend fun onStart() {
            super.onStart()
            async2 {
                jobName.text = job.await()?.title?.value ?: "Задача удалена"
            }
        }
    }
}
