package org.tlsys.frontol

import org.tlsys.admin.SettingItem
import org.tlsys.admin.core.Remote
import org.tlsys.admin.core.Services
import org.tlsys.frontol.api.ConfigService
import org.tlsys.ui.Page

fun frontolMain(remote: Remote) {
    processing_dto_set.reg()
    Services.reg(
        remote.getService(
            ConfigService::class,
            invokator = PROCESSING_SERVICE_INVOCATOR,
            setvice = CONFIG_SERVICE,
        ),
    )

    Services.reg(
        object : SettingItem {
            override val uri: String
                get() = ConfigPage.URI
            override val title: String
                get() = ConfigPage.TITLE

            override suspend fun getPage(): Page = ConfigPage()
        },
    )
    Services.reg(
        object : SettingItem {
            override val uri: String
                get() = "config2"
            override val title: String
                get() = "Бонусная система (beta)"

            override suspend fun getPage(): Page = ConfigPage2()
        },
    )
}
