package org.tlsys.login

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.*
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.ui.*
import pw.binom.web.layout.*

class ReparePasswordWindow private constructor() : TitleDialog("Восстановление пароля") {

    private val enterPhone = object : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        init {
            Span("Введите имя пользователя пользователя или телефон")
                .appendTo(layout, grow = 0, shrink = 0)
                .addClass(Styles.SIMPLE_TEXT)
                .addClass(Styles.TEXT_CENTER)
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        val phone = EditText(placeHolder = "Идентификатор")
            .textValidator(TextValidators.NOT_BLANK)
            .appendTo(layout, grow = 0, shrink = 0)

        init {
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        val capcha = RecapchaButton().appendTo(layout, grow = 0, shrink = 0)

        init {
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        private val errorSpan = Span()
            .addClass(Styles.ERROR)
            .addClass(Styles.TEXT_CENTER)
            .also {
                it.dom.style.paddingBottom = 20.px
            }
            .appendTo(layout, grow = 0, shrink = 0)
            .displayHide()

        private val next = ConfirmButton("Отправить").appendTo(layout, grow = 0, shrink = 0)

        private val codeValidator = Validated {
            capcha.checked
        }.attach(capcha.EVENT_CHANGED)

        private val validator = MultiValidator(phone, codeValidator)

        override suspend fun onStart() {
            super.onStart()
            this@ReparePasswordWindow.dom.style.height = 270.px
        }

        init {

            validator.onValidChange {
                next.enabled = it
            }

            next.onClick {
                val s = try {
                    SessionService.reparePassword(phone.text, capcha.token!!)
                } catch (e: dynamic) {
                    OVerify(null, null, null)
                }
                if (s.requestId == null) {
                    errorSpan.text = s.error
                        ?: "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                    errorSpan.displayShow()
                } else {
                    view.set2(
                        CheckValidCode(
                            phone = s.phone!!,
                            requestId = s.requestId!!,
                        ),
                    )
                }
            }
        }
    }

    private inner class CheckValidCode(val phone: String, var requestId: String) :
        DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        init {
            Span("На телефон $phone отправлено сообщение с проверочным кодом.")
                .appendTo(layout, grow = 0, shrink = 0)
                .addClass(Styles.SIMPLE_TEXT)
                .addClass(Styles.TEXT_CENTER)
            Span("Введите его")
                .appendTo(layout, grow = 0, shrink = 0)
                .addClass(Styles.SIMPLE_TEXT)
                .addClass(Styles.TEXT_CENTER)
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        val code = EditText(placeHolder = "Код")
            .appendTo(layout, grow = 0, shrink = 0)
            .textValidator(TextValidators.NOT_BLANK and TextValidators.maxLength(4))

        override suspend fun onStart() {
            super.onStart()
            this@ReparePasswordWindow.dom.style.height = "510px"
        }

        init {
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
            Span("Пароль должен содержать латинские большие и маленькие буквы, а так же цифры. Длина пароля должна быть не менее 6 символов")
                .appendTo(layout, grow = 0, shrink = 0)
                .addClass(Styles.SIMPLE_TEXT)
                .addClass(Styles.TEXT_CENTER)
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        val password = EditText(placeHolder = "Пароль", password = true)
            .appendTo(layout, grow = 0, shrink = 0)
            .textValidator(
                TextValidators.NOT_BLANK
                    and TextValidators.minLength(6)
                    and TextValidators.PASSWORD,
            )

        val replayPassword = EditText(placeHolder = "Повтор пароля", password = true)
            .appendTo(layout, grow = 0, shrink = 0)
            .textValidator(
                TextValidators.NOT_BLANK
                    and TextValidators.minLength(6)
                    and TextValidators.PASSWORD,
            )

        init {
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        private val errorSpan = Span()
            .addClass(Styles.ERROR)
            .addClass(Styles.TEXT_CENTER)
            .also {
                it.dom.style.paddingBottom = 20.px
            }
            .appendTo(layout, grow = 0, shrink = 0)
            .displayHide()

        private var c = 0
        private var timer = 0
        private val resend = BaseButton("Послать SMS снова").appendTo(layout, grow = 0, shrink = 0)

        init {
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        private val next = ConfirmButton("Сменить пароль").appendTo(layout, grow = 0, shrink = 0)

        private val passValidator = Validated {
            password.text == replayPassword.text
        }.attach(password.eventChange).attach(replayPassword.eventChange)

        private val multiValidator = MultiValidator(code, password, passValidator)

        init {
            resend.onClick {
                try {
                    resend.enabled = false
                    val r = SessionService.reparePasswordResend(
                        requestId = requestId,
                        recapchaToken = enterPhone.capcha.token!!,
                    )
                    if (r.requestId == null) {
                        resend.enabled = true
                        errorSpan.text = r.error
                            ?: "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                        errorSpan.displayShow()
                    } else {
                        resend.enabled = false
                        c = 60
                        resend.text = "Послать SMS снова ($c)"
                        requestId = r.requestId!!
                        timer = window.setInterval({
                            c--
                            if (c > 0) {
                                resend.text = "Послать SMS снова ($c)"
                            } else {
                                resend.text = "Послать SMS снова"
                                resend.enabled = true
                                window.clearInterval(timer)
                            }
                        }, 1000)
                    }
                } catch (e: dynamic) {
                    errorSpan.text = "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                    errorSpan.displayShow()
                }
            }
            next.onClick {
                val s =
                    try {
                        SessionService.reparePassword(
                            requestId = requestId,
                            code = code.text,
                            password = password.text,
                            recapchaToken = enterPhone.capcha.token!!,
                        )
                    } catch (e: dynamic) {
                        OVerifyResponse(null, null)
                    }
                if (s.session == null) {
                    errorSpan.text = s.error
                        ?: "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                    errorSpan.displayShow()
                } else {
                    close()
                    SuccessWindow.show(
                        title = "Восстановление пароля",
                        message = "Пароль успешно восстановлен",
                    )
                }
            }
            multiValidator.onValidChange {
                next.enabled = it
            }
        }
    }

    val view = ComponentView().appendTo(layout)

    init {
        dom.style.apply {
            width = 250.px
            transitionProperty = "background-color, color"
            transitionDuration = "200ms"
        }
        view.set2(enterPhone)
        DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        Span()
            .appendTo(layout, grow = 0, shrink = 0)
            .addClass(Styles.TEXT_CENTER)
            .also {
                document.createSpan("Войти")
                    .addClass(Styles.LINK)
                    .appendTo(it.dom)
                    .on("click") {
                        async2 {
                            close()
                            showLogin()
                        }
                    }
                document.createSpan(" или ")
                    .addClass(Styles.SIMPLE_TEXT)
                    .appendTo(it.dom)
                document.createSpan("зарегистрироваться")
                    .addClass(Styles.LINK)
                    .appendTo(it.dom)
                    .on("click") {
                        async2 {
                            close()
                            showReg()
                        }
                    }
            }
    }

    companion object {
        fun show() {
            async2 {
                ReparePasswordWindow().show()
            }
        }
    }
}
