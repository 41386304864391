package org.tlsys.admin.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.descriptors.element
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object FloatSerializer : KSerializer<Float> {
    override val descriptor: SerialDescriptor =
        buildClassSerialDescriptor("f") {
            element<String>("v")
        }

    override fun serialize(encoder: Encoder, value: Float) {
        encoder.beginStructure(this.descriptor).also {
            it.encodeFloatElement(this.descriptor, 0, value)
            it.endStructure(this.descriptor)
        }
    }

    override fun deserialize(decoder: Decoder): Float {
        return decoder.beginStructure(descriptor).let {
            val index = it.decodeElementIndex(BooleanSerializer.descriptor)
            if (index != 0) {
                throw SerializationException("Invalid index $index")
            }
            val s = it.decodeFloatElement(descriptor, 0)
            it.endStructure(descriptor)
            s
        }
    }
}

// object ArraySerializer2 : KSerializer<Array<Any?>> {
//    private val sel = ArraySerializer(PolymorphicSerializer(Any::class).nullable)
//    override val descriptor: SerialDescriptor =
//            buildClassSerialDescriptor("a") {
//                element<Array<Any?>>("v")
//            }
//
//    override fun serialize(encoder: Encoder, value: Array<Any?>) {
//
//        encoder.beginStructure(this.descriptor).also {
//            it.encodeSerializableElement(descriptor, 0, sel, value)
//            it.endStructure(this.descriptor)
//        }
//    }
//
//    override fun deserialize(decoder: Decoder): Array<Any?> {
//        return decoder.beginStructure(descriptor).let {
//            val s = it.decodeSerializableElement(descriptor, 0, sel, null)
//            it.endStructure(descriptor)
//            s as Array<Any?>
//        }
//    }
// }
