package org.tlsys

import org.tlsys.admin.form.Validated
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pw.binom.web.Component
import kotlin.js.Json

interface ParamEditor<T : HTMLElement> : Component<T>, Validated {
    var paramValue: String
}

interface ParamEditorFactory {
    fun create(type: String, canBeEmpty: Boolean): ParamEditor<*>?
}

fun List<ParamEditorFactory>.create(type: String, canBeEmpty: Boolean): ParamEditor<*>? =
        asSequence().map { it.create(type = type, canBeEmpty = canBeEmpty) }.filterNotNull().firstOrNull()

interface ParamUIService {
    fun build(settings: String, values: Map<String, String>?): Params
    interface Params : Component<HTMLDivElement>, Validated {
        fun values(): Map<String, String>
    }
}

fun ParamUIService.build(settings: String, values: String?): ParamUIService.Params {
//    val settings = if (settings.startsWith("[")) settings else "[$settings]"
    val values = values?.let{if (it.startsWith("[")) it else "[$it]"}
    val bb = values?.let { JSON.parse<Array<Json>>(it) }?.let { json ->
        json.flatMap { v ->
            v.keys.map {
                it to (v[it] as String)
            }
        }.toMap()
    }
    return build(settings, bb)
}

val Json.keys: Array<String>
    get() {
        val self = this
        return js("Object.keys(self)").unsafeCast<Array<String>>()
    }