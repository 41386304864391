package org.tlsys.json

class JVMException(message: String?) : JDTO, Exception(message) {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JVMException> {
        override val type: String
            get() = "JVMException"

        override fun read(node: JsonNode) =
                JVMException(
                        node["message"]?.string()
                )

        override fun write(obj: JVMException) = JsonNode.obj(
                "message" to obj.message?.json
        )
    }
}