package org.tlsys.admin.ui

import org.tlsys.addClass
import org.tlsys.admin.events.EventElementValue
import org.tlsys.async2
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.on

class Dropdown(text: String, var items: List<String> = emptyList()) : Button(text) {
    private companion object {
        val STYLE = CSS.style {
            border = "none"
            padding = "7px 32px 7px 25px"
            backgroundColor = "transparent"
            outline = "none"
            transition = "background-color 300ms ease, transform 300ms ease"
            cursor = "pointer"
            borderRadius = 2.px
            position = "relative"
            boxSizing = "border-box"

            ":after" then {
                content = "'▼'"
                position = "absolute"
                fontSize = 10.px
                right = 5.px
                top = "calc(50% - 4px)"
                color = "rgba(0,0,0,0.4)"
            }
            ":active" then {
                transform = "scale(0.96)"
            }
            ":hover" then {
                backgroundColor = "rgba(0,0,0,0.1)"
            }
        }.name
    }

    val eventClick = EventElementValue<Int>()

    init {
        dom.addClass(STYLE)
        dom.on("click") {
            if (this.items.isNotEmpty()) {
                async2 {
                    val rect = dom.getBoundingClientRect()
                    val s = Selector2.select(
                        items = items,
                        x = rect.left,
                        y = rect.y
                    )

                    if (s != null)
                        eventClick.dispatch(s)
                }
                it.preventDefault()
            }
        }
    }
}