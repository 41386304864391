package org.tlsys.core.search

import kotlinx.browser.window
import libs.Date
import libs.now
import libs.timeUTC
import org.tlsys.admin.GlobalPage
import org.tlsys.admin.SearchFieldService
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.Core2ApiAccess
import org.tlsys.api.MembersService
import org.tlsys.core.clientImport.ImportDialog
import org.tlsys.core.clientImport.fix
import org.tlsys.core.customers.dialog.CreateCustomerDialog
import org.tlsys.dto.CardExistDTO
import org.tlsys.dto.contacts.ContactExistException
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.navigation.HashPageControl
import org.tlsys.px
import org.tlsys.serialization.MemberExportConfig
import org.tlsys.ui.*
import org.tlsys.ui.on
import pw.binom.web.layout.*

private external fun encodeURIComponent(text: String): String

object SearchGlobalPageService : GlobalPage {
    override val uri: String
        get() = SearchPage.URI
    override val page = SearchPage()

    private val searchFieldService by Services.byClass(SearchFieldService::class)
    private val pageControl by Services.byClass(HashPageControl::class)

    init {
        searchFieldService.changeEvent.on {
            pageControl.resetParams(mapOf("q" to searchFieldService.value), "/${SearchPage.URI}")
        }
    }
}

class SearchPage : AbstractPage() {

    companion object {
        const val URI = "search"
    }

    var currentTab = 0
    private val searchFieldService by Services.byClass(SearchFieldService::class)

    override suspend fun getTitle(): String = "Поиск"

    private val membersService by Services.byClass(MembersService::class)

    private fun makeQ(text: String?, tags: List<Long>, gender: Boolean?, cardPr: Boolean?) =
        "${text ?: ""},$tags,$gender,$cardPr"

    override suspend fun updateParams(params: Map<String, String?>) {
        val query = params["q"]
        val tags = params["tags"]?.split('_')?.mapNotNull { it.toLongOrNull() } ?: emptyList()
        val gender = params["g"]?.let {
            when (it) {
                "1" -> true
                "0" -> false
                else -> null
            }
        }
        val cardPr = params["pr"]?.let {
            when (it) {
                "1" -> true
                "0" -> false
                else -> null
            }
        }
        searchFieldService.value = query ?: ""
        val q = makeQ(text = query, tags = tags, gender = gender, cardPr = cardPr)
        if (oldSearch != q) {
            if (isStarted) {
                oldSearch = q
                search(text = query, tags = tags, gender = gender, cardPr = cardPr)
            }
        }
        super.updateParams(params)
    }

    val actionPanel = ActionPanel().appendTo(contentLayout, grow = 0, shrink = 0)
    private val tabs = Tabs().apply {
        dom.on("click") {
            currentTab = active
        }
    }.appendTo(actionPanel, FlexLayout.FlexItem.AlignSelf.Normal)

    init {
        actionPanel.addSpace()
    }

    val createClient = actionPanel.iconBtn("add")
    val clientFilter = actionPanel.iconBtn("filter_alt")
    val importExport = actionPanel.iconBtn("import_export")

    private val view = ComponentView().apply {
        dom.style.paddingTop = 8.px
    }.appendTo(contentLayout, grow = 1, shrink = 1)

    private val searchByMemberTab = SearchByMemberTab()
    private val pageControl by Services.byClass(HashPageControl::class)

    private fun exportXlsx() {
        ProcessBlocker.block("Экспорт") {
            val fileId = Core2ApiAccess.exportMembers(
                MemberExportConfig(
                    includeTags = emptyList(),
                    excludeTags = emptyList(),
                    cardPrInstalled = null,
                    osmicardInstalled = null,
                    activeAfter = null,
                    activeBefore = null,
                    gender = null,
                    clientTZ = pw.binom.date.DateTime.systemZoneOffset,
                ),
            )
            window.open(Core2ApiAccess.getDownloadPath(fileId = fileId), "_blank")
        }
    }

    init {
        importExport.onClick {
            val rect = importExport.dom.getBoundingClientRect()
            val selected = Selector2.select(
                items = listOf("Импорт", "Экспорт xlsx"),
                x = rect.left,
                y = rect.top,
            ) ?: return@onClick
            when (selected) {
                0 -> ImportDialog.show()
                1 -> exportXlsx()
            }
        }
        clientFilter.onClick {
            console.info("params:", params.map { "${it.key}=${it.value}" }.joinToString(", "))
            val filter = FilterDialog.Filter(
                selectedTags = params["tags"]?.split('_')?.mapNotNull { it.toLongOrNull() } ?: emptyList(),
                gender = when (params["g"]?.trim()) {
                    "1" -> true
                    "0" -> false
                    else -> null
                },
                cardPr = when (params["pr"]?.trim()) {
                    "1" -> true
                    "0" -> false
                    else -> null
                },
            )
            val tt = FilterDialog.show(filter) ?: return@onClick
            val gender = when (tt.gender) {
                true -> "1"
                false -> "0"
                null -> "null"
            }
            val cardPr = when (tt.cardPr) {
                true -> "1"
                false -> "0"
                null -> "null"
            }
            pageControl.resetParams(
                mapOf(
                    "tags" to tt.selectedTags.joinToString("_"),
                    "g" to gender,
                    "pr" to cardPr,
                ),
            )
        }
        createClient.onClick {
            val params = CreateCustomerDialog.show() ?: return@onClick
            try {
                val member = membersService.createMember(
                    firstName = params.firstName.text.jdto,
                    lastName = params.lastName.text.jdto,
                    sex = params.sexMale.checked.jdto,
                    middleName = params.middleName.text.jdto,
                    phones = params.phonesList.values.map { it.jdto }.jdto(),
                    emails = params.emailList.values.map { it.jdto }.jdto(),
                    allowDropBonus = params.allowDrop.checked.jdto,
                    birthDay = params.birthDayInput.date?.timeUTC?.jdto,
                    tags = params.tagSelector.list.map { it.tag.id.jdto }.jdto(),
                    cards = params.cardsList.values.map { it.jdto }.jdto(),
                ).await()
                searchByMemberTab.addTop(
                    SearchElement.MemberSearchElement(
                        memberId = member.id,
                        cards = params.cardsList.values,
                        allowBonusDrop = params.allowDrop.checked,
                        birthday = member.birthday,
                        firstName = member.firstName,
                        lastName = member.lastName,
                        middleName = member.middleName,
                        sex = member.sex,
                        phones = params.phonesList.values,
                        emails = params.emailList.values,
                        cardPrInstallDate = null,
                        created = Date.now().timeUTC,
                        tags = params.tagSelector.list.map { it.tag.id },
                        bonusAllowDrop = member.allowBalans.fix(2),
                        bonusNotAllowDrop = member.notAllowBalans.fix(2),
                        osmicardInstallDate = null,
                    ),
                )
            } catch (e: CardExistDTO) {
                MessageDialog.showError("Карта ${e.code} уже существует")
            } catch (e: ContactExistException) {
                MessageDialog.showError("Контакт ${e.contact} уже существует")
            }
        }
        tabs.event_ACTIVE_CHANGED.on {
            clientFilter.dom.style.display = if (tabs.active == 0) "" else "none"
            createClient.dom.style.display = if (tabs.active == 0) "" else "none"
            importExport.dom.style.display = if (tabs.active == 0) "" else "none"
        }

        tabs.add("Клиенты") {
            view.set2(searchByMemberTab)
        }

        tabs.active = 0
    }

    private var oldSearch: String? = ""

    private suspend fun search(text: String?, tags: List<Long>, gender: Boolean?, cardPr: Boolean?) {
        searchByMemberTab.search(text = text, tags = tags, gender = gender, cardPr = cardPr)
    }

    override suspend fun onStart() {
        super.onStart()
        console.info("SearchPage->onStart")
        val query = params["q"]
        val gender = params["g"]?.let {
            when (it) {
                "1" -> true
                "0" -> false
                else -> null
            }
        }
        val cardPr = params["pr"]?.let {
            when (it) {
                "1" -> true
                "0" -> false
                else -> null
            }
        }
        val tags = params["tags"]?.split('_')?.mapNotNull { it.toLongOrNull() } ?: emptyList()
        searchFieldService.value = query ?: ""
        val q = makeQ(text = query, tags = tags, gender = gender, cardPr = cardPr)
        if (oldSearch != q) {
            oldSearch = q
            search(text = query, tags = tags, gender = gender, cardPr = cardPr)
        }
    }
}
