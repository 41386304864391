package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.addClass
import org.tlsys.css.CSS
import org.tlsys.css.animate
import org.tlsys.px
import org.tlsys.ui.*
import pw.binom.web.layout.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.Component

enum class MaterialIcons(val code: String) {
    CONTENT_COPY(code = "content_copy"),
    EDIT(code = "edit"),
    DELETE(code = "delete"),
    SETTINGS(code = "settings"),
    INFO(code = "info"),
    SYNC(code = "sync"),
    PLAY_ARROW(code = "play_arrow"),
    FOLDER(code = "folder"),
    INSERT_DRIVE_FILE(code = "insert_drive_file"),
    ERROR(code = "error"),
}

class ActionPlace : DivComponentWithLayout(alignItems = FlexLayout.AlignItems.Center) {
    companion object {
        val BUTTON = CSS.style {
            opacity = 0.5
            border = "none"
            backgroundColor = "transparent"
            outline = "none"
            cursor = "pointer"
            userSelect = "none"
            transition = "opacity 100ms ease-in-out"
            ":hover" then {
                opacity = 1.0
            }
        }.name
    }

    var visible: Boolean
        get() = (dom.style.opacity?.toFloatOrNull() ?: 1f) == 1f
        set(value) {
            dom.style.opacity = (if (value) 1f else 0f).toString()
        }

    fun iconBtn(icon: MaterialIcons) = iconBtn(icon.code)

    fun iconBtn(icon: String): Button {
        val b = Button(style = BUTTON)
        b.dom.addClass("material-icons")
        b.text = icon
        b.dom.style.backgroundColor = "transparent"
        b.appendTo(layout, grow = 0, shrink = 0)
        return b
    }

    fun visibleOnHover(component: Component<*>) {
        component.dom.on("mouseover") {
            visible = true
        }
        component.dom.on("mouseleave") {
            visible = false
        }
    }

    init {
        visible = false
        dom.style.transition = "opacity 100ms ease-in-out"
    }
}

class MaterialIcon(iconId: String, size: Int = 24) :
    AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createElement("div").unsafeCast<HTMLDivElement>()
    init {
        dom.addClass("material-icons")
        dom.innerText = iconId
        dom.style.backgroundColor = "transparent"
        dom.style.cursor = "default"
        dom.style.fontSize = size.px
    }

    var visible: Boolean = true
        set(value) {
            field = value
            if (isStarted) {
                if (value) {
                    dom.animate {
                        0 {
                            opacity = 1.0
                        }
                        100 {
                            opacity = 0.0
                        }
                        onEnd {
                            dom.style.display = "none"
                        }
                    }.start()
                } else {
                    dom.style.display = ""
                    dom.animate {
                        0 {
                            opacity = 0.0
                        }
                        100 {
                            opacity = 1.0
                        }
                    }.start()
                }
            } else {
                dom.style.display = if (value) "" else "none"
            }
        }
}
