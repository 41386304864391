package org.tlsys.admin.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.descriptors.element
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object UnitSerializer : KSerializer<Unit> {
    override val descriptor: SerialDescriptor =
            buildClassSerialDescriptor("u") {

            }

    override fun serialize(encoder: Encoder, value: Unit) {
        encoder.beginStructure(this.descriptor).also {
            it.endStructure(this.descriptor)
        }
    }

    override fun deserialize(decoder: Decoder): Unit {
        return decoder.beginStructure(descriptor).let {
            it.endStructure(descriptor)
        }
    }
}