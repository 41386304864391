package org.tlsys.dto.auth

import org.tlsys.json.*

data class CompanyDTO(val id: Long, val name: String, val cartEnabled: Boolean) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<CompanyDTO> {
        override fun read(node: JsonNode) = CompanyDTO(
                id = node["id"]!!.long(),
                name = node["name"]!!.string(),
                cartEnabled = node["cartEnabled"]!!.boolean()
        )

        override fun write(obj: CompanyDTO) =
                JsonNode.obj()
                        .set("id", obj.id.json)
                        .set("name", obj.name.json)
                        .set("cartEnabled", obj.cartEnabled.json)

        override val type: String
            get() = "CompanyDTO"
    }
}