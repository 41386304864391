package org.tlsys.api

import org.tlsys.Dto
import org.tlsys.json.*
import kotlin.js.JsName

interface SMSManagerService {
    companion object {
        const val NAME = "SMSManagerService"
    }

    enum class Provider {
        SMSC_RU,
        SMS_RU,
    }

    enum class Type {
        /**
         * Отсылка кодов в СМС
         */
        SECURITY_CODE,

        /**
         * Уведомление
         */
        NOTIFICATION,
    }

    @Dto
    class AccountDTO(
        val id: JString,
        val provider: JString,
        val types: JList<JString>,
        val login: JString?,
        val name: JString,
    ) : JDTO {

        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        val providerEnum
            get() = SMSManagerService.Provider.valueOf(provider.value)

        val typesEnum
            get() = types.map { SMSManagerService.Type.valueOf(it.value) }

        companion object : JDTOFactory<AccountDTO> {
            override val type: String
                get() = "SMSAccountDTO"

            override fun read(node: JsonNode): AccountDTO =
                AccountDTO(
                    id = node["id"]!!.string().jdto,
                    provider = node["provider"]!!.string().jdto,
                    types = node["types"]!!.map { it!!.string().jdto }.jdto(),
                    login = node["login"]?.string()?.jdto,
                    name = node["name"]!!.string().jdto,
                )

            override fun write(obj: AccountDTO): JsonNode = JsonNode.obj()
                .set("id", obj.id.value)
                .set("provider", obj.provider.value)
                .set("types", obj.types.value.map { JsonNode.string(it.value) }.json())
                .set("login", obj.login?.value)
                .set("name", obj.name.value)
        }
    }

    @JsName("getAccounts")
    fun getAccounts(): AsyncResult<JList<AccountDTO>>

    @JsName("createAccount")
    fun createAccount(provider: JString, name: JString, login: JString, password: JString): AsyncResult<AccountDTO>

    @JsName("getBalance")
    fun getBalance(account: JString): AsyncResult<JLong?>

    @JsName("deleteAccount")
    fun deleteAccount(account: JString): AsyncResult<JUnit>

    @JsName("updateAccount")
    fun updateAccount(
        account: JString,
        name: JString,
        login: JString,
        password: JString,
        provider: JString,
    ): AsyncResult<AccountDTO?>

    @JsName("checkAccount")
    fun checkAccount(provider: JString, account: JString?, login: JString, password: JString): AsyncResult<JString?>

    @JsName("updateTypes")
    fun updateTypes(account: JString, types: JList<JString>): AsyncResult<AccountDTO?>
}

val SMSManagerService.Type.text
    get() = when (this) {
        SMSManagerService.Type.SECURITY_CODE -> "Код безопасности"
        SMSManagerService.Type.NOTIFICATION -> "Уведомления"
    }
