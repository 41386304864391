package org.tlsys.core.promotion.clearBonusOnUnusedPromotion

import org.tlsys.*
import org.tlsys.admin.*
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.admin.ui.Tabs
import org.tlsys.api.MemberTagService
import org.tlsys.cms.ui.TimeCountSelector
import org.tlsys.cms.ui.time
import org.tlsys.core.promotion.ConditionsTab
import org.tlsys.core.promotion.PermanentPromotionEditor
import org.tlsys.json.jdto
import org.tlsys.promotion.dto.ClearBonusOnUnusedConfig
import org.tlsys.promotion.dto.TagFilterDto
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.date.format.toDatePattern
import pw.binom.uuid.nextUuid
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.random.Random

private val ddd = "HH:mm".toDatePattern()

class ClearBonusOnUnusedPromotionDialog(var record: PermanentPromotion?, new: Boolean) :
    TitleDialog(PermanentPromotionType.REMOVE_UNUSED.title) {

    init {
        this.title = when {
            record != null && new -> "Копия ${PermanentPromotionType.REMOVE_UNUSED.title}"
            record != null && !new -> "Редактирование ${PermanentPromotionType.REMOVE_UNUSED.title}"
            else -> "Новая ${PermanentPromotionType.REMOVE_UNUSED.title}"
        }
    }

    private val configs = record?.config?.getObject<ClearBonusOnUnusedConfig>()
    private var result: PermanentPromotion? = null
    private val memberTagService by Services.byClass(MemberTagService::class)

    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val dd = DivLayout().appendTo(layout, grow = 0, shrink = 0)
    private val name = EditText(placeHolder = "Название")
        .text(
            when {
                record != null && new -> "Копия ${record?.title}"
                record != null && !new -> record!!.title
                else -> PermanentPromotionType.REMOVE_UNUSED.title
            },
        )
        .appendTo(dd.layout, grow = 1, shrink = 1)
        .andTextValidator(TextValidators.NOT_BLANK)
    private val status = Combobox2(
        items = PromotionStatus.values().map { it.title },
        select = record?.status?.let { PromotionStatus.values().indexOf(it) } ?: 0,
    ).appendTo(dd.layout, grow = 1, shrink = 1)
    private val timeToStart = Combobox2(
        items = TimeIntervalEnum.values().map {
            val d = pw.binom.date.DateTime(it.delay)
            ddd.toString(d)
        },
        select = record?.timeToStart?.let { TimeIntervalEnum.values().indexOf(it) } ?: 0,
    )
        .withLabel("Время запуска")
        .appendTo(layout, grow = 0, shrink = 0)
    private val dates = DivLayout().appendTo(layout, grow = 0, shrink = 0)
    private val dateStart =
        DatetimeEditor(record?.startDate ?: pw.binom.date.DateTime.now)
            .withLabel("Дата начала")
            .appendTo(dates.layout, grow = 1, shrink = 1)
    private val dateEnd =
        DatetimeEditor(
            record?.endDate ?: pw.binom.date.DateTime(pw.binom.date.DateTime.nowTime + 1000 * 60 * 60 * 24 * 7),
        )
            .withLabel("Дата окончания")
            .appendTo(dates.layout, grow = 1, shrink = 1)
    private val tabs = Tabs(bottomPudding = true).appendTo(layout, grow = 0, shrink = 0)
    private val viewport = ComponentView().appendTo(layout = layout)
    private val viewScroll = ScrollController(layout.dom)
    private val config = Config()
    private val conditions = ConditionsTab()

    private val notifies = object : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        val sendNotify = Checkbox(label = "Рассылка уведомлений")
            .checked(configs?.sendNotify ?: false)
            .appendTo(layout, grow = 0, shrink = 0)
        val sendNotifyBefore = TimeCountSelector(
            placeHolder = "Время перед отправкой удалением бонусов",
            allowTypes = arrayOf(TimeType.DAY, TimeType.WEEK),
        )
            .time(configs?.notifyBeforeCleaner)
            .appendTo(layout, grow = 0, shrink = 0)
        val notifyText = TextareaInput()
            .text(configs?.text ?: "")
            .also { it.dom.style.height = 140.px }
            .withLabel("Текст сообщения")
            .appendTo(layout, grow = 1, shrink = 1)
    }

    private val validator =
        MultiValidator(name, config.dropBonusPercent, notifies.sendNotifyBefore, config.dropAfterNotUse)

    init {
        dom.style.width = 500.px
        dom.style.height = 650.px
        tabs.add("Настройки") {
            viewport.set2(config)
        }
        tabs.add("Уведомления") {
            viewport.set2(notifies)
        }
        tabs.add("Условия") {
            viewport.set2(conditions)
        }

        tabs.active = 0
        validator.onValidChange {
            body.okBtn.enabled = it
        }
        body.ok {
            val config = ClearBonusOnUnusedConfig(
                sendNotify = notifies.sendNotify.checked,
                notifyBeforeCleaner = notifies.sendNotifyBefore.time!!.toDateDuration,
                text = notifies.notifyText.content.text,
                tagFilter = TagFilterDto(
                    includeTags = conditions.includeTags.content.list.map { it.tag.id },
                    excludeTags = conditions.excludeTags.content.list.map { it.tag.id },
                ),
                cleanAfter = config.dropAfterNotUse.time!!.toDateDuration,
                percent = config.dropBonusPercent.text.toDouble().toPercent,
            )

            result = PermanentPromotion(
                id = record?.id ?: Random.nextUuid(),
                title = name.text,
                owner = record?.owner ?: 0L,
                status = PromotionStatus.values()[status.select],
                createDate = record?.createDate?.let { pw.binom.date.DateTime(it.time) } ?: pw.binom.date.DateTime.now,
                startDate = dateStart.content.date!!,
                endDate = dateEnd.content.date,
                timeToStart = TimeIntervalEnum.values()[timeToStart.content.select],
                type = type,
                config = JsonObject.create(config),
            )
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    override suspend fun onInit() {
        super.onInit()
        if (record != null && configs != null) {
            val tags =
                memberTagService.getTagList(
                    (configs.tagFilter.includeTags + configs.tagFilter.excludeTags).map { it.jdto }
                        .jdto(),
                ).await()
                    .associateBy { it.id }

            conditions.includeTags.content.addTag(
                configs.tagFilter.includeTags.map { tags[it]!! },
            )
            conditions.excludeTags.content.addTag(
                configs.tagFilter.excludeTags.map { tags[it]!! },
            )
        }
    }

    inner class Config : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        val dropBonusPercent = EditText(placeHolder = "Процент бонусов к сгоранию")
            .textValidator(
                DoubleValidator.FORMAT and DoubleValidator.moreOrEquals2(0.0) and DoubleValidator.minOrEquals2(
                    100.0,
                ),
            )
            .text(configs?.percent?.asDouble?.toString() ?: "100")
            .appendTo(layout, grow = 0, shrink = 0)
        val dropAfterNotUse = TimeCountSelector(
            placeHolder = "Время перед удалением бонусов",
            allowTypes = arrayOf(TimeType.DAY, TimeType.WEEK),
        )
            .time(configs?.cleanAfter)
            .appendTo(layout, grow = 0, shrink = 0)
    }

    override fun onEscapeClick() {
        result = null
        close()
    }

    companion object : PermanentPromotionEditor {
        override val type: PermanentPromotionType
            get() = PermanentPromotionType.REMOVE_UNUSED

        override suspend fun show(record: PermanentPromotion?, new: Boolean): PermanentPromotion? {
            val d = ClearBonusOnUnusedPromotionDialog(record = record, new = new)
            d.show()
            return d.result
        }
    }
}
