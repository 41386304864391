package org.tlsys

import org.tlsys.core.AsyncIterator
import org.tlsys.core.AsyncListIterator
import org.tlsys.core.Stack
import pw.binom.Generator
import pw.binom.GeneratorResult
import kotlin.js.Promise

fun <T, V : List<T>> Promise<List<T>>.asIterator() = object : AsyncListIterator<T> {
    override fun back() {
        cursor--
    }

    var cursor = 0

    override suspend fun hasNext(): Boolean =
        cursor <= await().size - 1

    override suspend fun next(): T {
        if (cursor >= await().size) {
            throw NoSuchElementException()
        }
        return await()[cursor++]
    }
}

private object EmptyAsyncIterator : AsyncIterator<Any> {
    override suspend fun hasNext() = false
    override suspend fun next() = throw NoSuchElementException()
}

fun <T> emptyAsyncIterator(): AsyncIterator<T> = EmptyAsyncIterator.unsafeCast<AsyncIterator<T>>()

abstract class AbstractAsyncListIterator<T>(
    val max: Int,
    val loader: (max: Int, offset: Long) -> Promise<Collection<T>>
) : AsyncListIterator<T> {

    private val list = Stack<T>()
    var offset = 0L
    var endded = false
    private var value: T? = null
    protected abstract fun needLoad(loaded: Int): Boolean

    private suspend fun giveNext(): T? {
        if (value != null) {
            return value
        }

        if (endded && list.isEmpty) {
            return null
        }
        if (needLoad(list.length) && !endded) {
            val result = loader(max, offset)
            offset += max
            val loaded = result.await()
            endded = loaded.size < max
            if (loaded.isEmpty() && list.isEmpty) {
                return null
            }
            loaded.forEach { list.push(it) }
        }

        value = list.popFirst()
        return value
    }

    override suspend fun next(): T {
        val v = giveNext() ?: throw NoSuchElementException()
        value = null
        return v
    }

    override suspend fun hasNext() = giveNext() != null

    override fun back() {
        offset--
    }
}

class SimpleAsyncListIterator<T>(max: Int, loader: (max: Int, offset: Long) -> Promise<Collection<T>>) :
    AbstractAsyncListIterator<T>(max, loader) {
    override fun needLoad(loaded: Int): Boolean = loaded == 0
}

class AutoLoadAsyncListIterator<T>(
    val minFull: Int,
    max: Int,
    loader: (max: Int, offset: Long) -> Promise<Collection<T>>
) : AbstractAsyncListIterator<T>(max, loader) {
    override fun needLoad(loaded: Int): Boolean = loaded <= minFull
}

fun <VALUE> Generator<VALUE>.asIterator() = object : AsyncIterator<VALUE> {
    private var hasValue = false
    private var result = GeneratorResult.end<VALUE>()
    private suspend fun preload() {
        if (hasValue) {
            return
        }
        hasValue = true
        result = this@asIterator.next()
    }

    override suspend fun hasNext(): Boolean {
        if (hasValue) {
            return true
        }
        preload()
        return !result.isEmpty
    }

    override suspend fun next(): VALUE {
        preload()
        if (result.isEmpty) {
            throw NoSuchElementException()
        }
        val r = result
        hasValue = false
        result = GeneratorResult.end()
        return r.value as VALUE
    }
}
