package org.tlsys.documents

import org.tlsys.admin.core.Remote
import org.tlsys.admin.core.Services
import org.tlsys.core.CORE_INVOKATOR
import org.tlsys.core.DOCUMENTS_SERVICE
import org.tlsys.core.documents.RemoteDocumentService

val RemoteDocumentServiceImpl by lazy {
    val remote by Services.byClass(Remote::class)
    remote.getService(RemoteDocumentService::class, invokator = CORE_INVOKATOR, setvice = DOCUMENTS_SERVICE)
}
