package org.tlsys.admin.ui

import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.styles.Text

object Styles {
    val MAIN_CONTENT_COLOR = "f4f4f4"
    val NAVIGATION_COLOR = "#fff"
    inline val DEFAULT_TEXT_FONT: String
        get() = "\"Roboto\", sans-serif"

    inline val FONT_SIZE_H1
        get() = 58.5

    inline val FONT_SIZE_H2
        get() = 49.84

    inline val FONT_SIZE_H3
        get() = 40.88

    inline val FONT_SIZE_H4
        get() = 31.92

    inline val FONT_SIZE_H5
        get() = 22.96

    inline val FONT_SIZE_H6
        get() = 14.0

    fun fontSizeH(index: Int) =
        when (index) {
            1 -> FONT_SIZE_H1
            2 -> FONT_SIZE_H2
            3 -> FONT_SIZE_H3
            4 -> FONT_SIZE_H4
            5 -> FONT_SIZE_H5
            6 -> FONT_SIZE_H6
            else -> throw IllegalArgumentException()
        }

    inline val DEFAULT_FONT_SIZE: Int
        get() = 14
    inline val SMALL_FONT_SIZE: Int
        get() = 10
    inline val LINK_COLOR
        get() = "#3aafda"
    inline val ERROR_COLOR
        get() = "#d0021b"

    val ERROR = CSS.style {
        fontSize = DEFAULT_FONT_SIZE.px
        color = ERROR_COLOR
        fontFamily = DEFAULT_TEXT_FONT
        textDecoration = "none"
    }.name

    val LINK = Text.DEFAULT_LINK
    const val SIMPLE_TEXT = Text.DEFAULT_TEXT

    val SMALL_TEXT = CSS.style {
        fontFamily = DEFAULT_TEXT_FONT
        fontSize = SMALL_FONT_SIZE.px
    }.name

    val ICON_SIZE = 23

    val DEFAULT_CURSOR = CSS.style {
        cursor = "default"
    }.name

    val TEXT_CENTER = CSS.style {
        textAlign = "center"
    }.name

    val ICONS_H = CSS.style {
        ":not(:first-child)" {
            paddingLeft = 7.px
        }
    }.name

    val LEFT_PADDING = CSS.style {
        paddingLeft = 7.px
    }.name

    val DEFAILT_ITEM_HEIGHT = 50

    /**
     * Стиль чередования линий. Нужен для листов элементов
     */
    val LIST_ITEM_STYLE = CSS.style {
        //        textAlign = "center"
        paddingRight = 10.px
        paddingLeft = 10.px
        minHeight = DEFAILT_ITEM_HEIGHT.px
        ":nth-child(2n)" then {
            backgroundColor = "rgba(0, 0, 0, 0.02)"
        }

        ":hover" then {
            backgroundColor = "rgba(0, 0, 0, 0.06)"
        }
        transition = "background-color 50ms"
    }.name

    val LIST_ITEM_HEAD_STYLE = CSS.style {
        paddingRight = 10.px
        paddingLeft = 10.px
        minHeight = DEFAILT_ITEM_HEIGHT.px
        backgroundColor = "rgba(0, 0, 0, 0.1)"

        ":hover" then {
            backgroundColor = "rgba(0, 0, 0, 0.15)"
        }
        transition = "background-color 50ms"
    }.name
}