package org.tlsys.gateway

import org.tlsys.admin.GatewayProvider
import org.tlsys.admin.SettingItem
import org.tlsys.admin.ui.*
import org.tlsys.api.GatewayService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.cms.click
import org.tlsys.serialization.Gateway
import org.tlsys.ui.*
import pw.binom.uuid.nextUuid
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.random.Random

private suspend fun showGatewayDialog(new: Boolean, gatewayProvider: GatewayProvider, gateway: Gateway?) =
    when (gatewayProvider) {
        GatewayProvider.SMS_RU -> SMSRuGatewayDialog.show(new = new, gateway = gateway)
        GatewayProvider.SMSC -> SMSCGatewayDialog.show(new = new, gateway = gateway)
        GatewayProvider.MTC_CONNECT -> MtsConnectGatewayDialog.show(new = new, gateway = gateway)
        GatewayProvider.INTERNAL_SMTP -> InternalSMTPDialog.show(new = new, gateway = gateway)
        GatewayProvider.INTERNAL_TELEGRAM -> TelegramGatewayDialog.show(new = new, gateway = gateway)
    }

class GatewayPage : AbstractPage() {
    override suspend fun getTitle(): String = GatewaySettingItem.title

    object GatewaySettingItem : SettingItem {
        override val uri: String
            get() = "gateways"
        override val title: String
            get() = "Шлюзы"

        override suspend fun getPage(): Page = GatewayPage()
    }

    private val tooBar = ActionPanel()
        .appendTo(contentLayout, grow = 0, shrink = 0).apply {
            addSpace()
        }
    private val div = DivLayout(direction = FlexLayout.Direction.Column).appendTo(contentLayout, grow = 1, shrink = 1)
    private val table = MaterialTable().appendTo(div.layout, grow = 0, shrink = 0)

    //    private val addBtn = AddButton().appendTo(contentLayout, grow = 0, shrink = 0)
    private val scrollController = ScrollController(div.dom)
    private val addDropdown = Dropdown("Добавить", GatewayProvider.values().map { it.title }).appendTo(tooBar)

    init {
        val head = MaterialTable.Header().appendTo(table)
        MaterialTable.TextColumn("Статус").appendTo(head).widthPx(100)
        MaterialTable.TextColumn("Тип").appendTo(head).widthPx(130)
        MaterialTable.TextColumn("Название").appendTo(head)
        MaterialTable.TextColumn("События").appendTo(head)
        MaterialTable.TextColumn("Действия").appendTo(head).widthPx(1)
        addDropdown.eventClick.on { selected ->
            async2 {
                val gateway = showGatewayDialog(
                    new = true,
                    gatewayProvider = GatewayProvider.values()[selected],
                    gateway = null,
                ) ?: return@async2

                ProcessBlocker2.block("Создание шлюза") {
                    GatewayService.create(gateway)
                }.await()

                val row = GatewayTableItem(gateway)
                row.appendTo(table)
                Effects.createAndBlankItem(row.dom)
            }
        }
//        addBtn.onClick {
//            val r = addBtn.dom.getBoundingClientRect()
//            val selected = Selector2.select(
//                items = GatewayProvider.values().map { it.title },
//                x = r.left + r.width * 0.5 - 10.0,
//                y = r.top,
//                width = null,
//            ) ?: return@onClick
//
//            val gateway = showGatewayDialog(
//                new = true,
//                gatewayProvider = GatewayProvider.values()[selected],
//                gateway = null
//            ) ?: return@onClick
//
//            ProcessBlocker2.block("Создание шлюза") {
//                GatewayService.create(gateway)
//            }.await()
//
//            val row = GatewayTableItem(gateway)
//            row.appendTo(table)
//            Effects.createAndBlankItem(row.dom)
//        }
    }

    private inner class GatewayTableItem(var r: Gateway) : MaterialTable.Row() {
        val state = MaterialTable.TextColumn("").appendTo(this)
        val type = MaterialTable.TextColumn("").appendTo(this)
        val name = MaterialTable.TextColumn("").appendTo(this)
        val events = MaterialTable.TextColumn("").appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        init {
            actions.component.visibleOnHover(this)
            refresh()

            actions.component.iconBtn(MaterialIcons.CONTENT_COPY).click {
                val r = showGatewayDialog(
                    new = true,
                    gatewayProvider = r.provider,
                    gateway = r.copy(id = Random.nextUuid()),
                ) ?: return@click

                ProcessBlocker2.block("Обновление шлюза") {
                    GatewayService.create(r)
                }.await()
                val row = GatewayTableItem(r)
                row.appendTo(table)
                Effects.createAndBlankItem(row.dom)
            }

            actions.component.iconBtn(MaterialIcons.EDIT).click {
                val r = showGatewayDialog(
                    new = false,
                    gatewayProvider = r.provider,
                    gateway = r,
                ) ?: return@click

                ProcessBlocker2.block("Обновление шлюза") {
                    GatewayService.update(r)
                }.await()
                this.r = r
                refresh()
                Effects.blockRow(dom)
            }

            actions.component.iconBtn(MaterialIcons.DELETE).click {
                if (!YesNoDialog.show("Удалить шлюз ${r.title}", width = 500).await()) {
                    return@click
                }

                GatewayService.delete(r.id)
                Effects.removeItem(dom)
                removeSelf()
            }
        }

        fun refresh() {
            state.text = r.status.title
            type.text = r.provider.title
            name.text = r.title
            events.text = r.eventTypeSet.joinToString { it.title }
        }
    }

    override suspend fun onInit() {
        super.onInit()
        GatewayService.getList().forEach {
            GatewayTableItem(it).appendTo(table)
        }
        super.onInit()
    }
}
