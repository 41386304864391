package org.tlsys.admin.core

import kotlin.reflect.KClass
import kotlin.reflect.KProperty

object Services {

    class Service<T : Any>(val obj: T)

    private val services = HashMap<String, Service<*>>()
    private var nameIt = 0
    fun <T : Any> reg(obj: T) = reg(name = "bean#${++nameIt}", obj = obj)

    fun <T : Any> reg(name: String, obj: T) {
        if (services.containsKey(name)) {
            throw IllegalArgumentException("Service with name $name already exist")
        }
        if (obj.asDynamic()["CLASS"] == null) {
            obj.asDynamic()["CLASS"] = obj::class
        }
        services[name] = Service(obj = obj)
    }

    fun <T : Any> byClass(clazz: KClass<T>) = ByClass(clazz)
    fun <T : Any> byClassOrNull(clazz: KClass<T>) = ByClassOrNull(clazz)

    fun <T : Any> listByClass(clazz: KClass<T>) = ListByClass(clazz)

    class ByClass<T : Any>(private val clazz: KClass<T>) {
        fun get(): T {
            val beans = services.values.filter { clazz.isInstance(it.obj) || it.obj.asDynamic()["CLASS"] == clazz }
            if (beans.isEmpty()) {
                services.values.forEach {
                    val clazz = it.obj.asDynamic()["CLASS"]
                    console.info("clazz=$clazz <> ${this.clazz}")
                }
                console.info("services: ", services.map { it.value.obj }.toTypedArray())
                throw IllegalStateException("No such Service with class ${clazz.simpleName}")
            }
            if (beans.size > 1) {
                throw IllegalStateException("Added ${beans.size} with type ${clazz.simpleName}")
            }
            return beans[0].obj.unsafeCast<T>()
        }

        operator fun getValue(thisRef: Any?, property: KProperty<*>): T = get()
    }

    class ByClassOrNull<T : Any>(private val clazz: KClass<T>) {
        operator fun getValue(thisRef: Any?, property: KProperty<*>): T? {
            val beans = services.values.filter { clazz.isInstance(it.obj) || it.obj.asDynamic()["CLASS"] == clazz }
            if (beans.isEmpty()) {
                return null
            }
            if (beans.size > 1) {
                throw IllegalStateException("Added ${beans.size} with type ${clazz.simpleName}")
            }
            return beans.first().obj.unsafeCast<T>()
        }
    }

    class ListByClass<T : Any>(private val clazz: KClass<T>) {
        operator fun getValue(thisRef: Any?, property: KProperty<*>): List<T> {
            return services.values.filter { clazz.isInstance(it.obj) }.map { it.obj }.unsafeCast<List<T>>()
        }
    }
}
