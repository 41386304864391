package org.tlsys.core.promotion.birthdayPromotion

import org.tlsys.*
import org.tlsys.admin.BirthdayPromotionConfig
import org.tlsys.admin.PermanentPromotion
import org.tlsys.admin.PermanentPromotionType
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.cms.ui.TimeCountSelector
import org.tlsys.cms.ui.time
import org.tlsys.core.promotion.BasePermanentPromotion
import org.tlsys.core.promotion.BonusConfigEditor
import org.tlsys.core.promotion.ConditionsTab
import org.tlsys.core.promotion.PermanentPromotionEditor
import org.tlsys.promotion.dto.TagFilterDto
import org.tlsys.ui.*
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.web.layout.*

class BirthdayPromotionDialog private constructor(record: PermanentPromotion?) :
    BasePermanentPromotion(type = PermanentPromotionType.BIRTHDAY_PROMOTION, record = record) {
    init {
        console.info("record?.config: ${record?.config}")
        console.info("config: ${record?.config?.toString()}")
    }

    private val configs = record?.config?.getObject<BirthdayPromotionConfig>()
    private val memberTagService by Services.byClass(MemberTagService::class)

    private val conditions = ConditionsTab()
    private val config = object : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        val searchOffset = TimeCountSelector(
            placeHolder = "Кол. дней до дня рождения",
            allowTypes = arrayOf(TimeType.DAY, TimeType.WEEK),
        )
            .time(configs?.searchOffset ?: DateDuration.ZERO)
            .appendTo(layout, grow = 0, shrink = 0)
        val tagPrefix = EditText(placeHolder = "Префикс маркерных меток")
            .text(configs?.tagPrefix ?: "")
            .textValidator(TextValidators.NOT_BLANK)
            .appendTo(layout, grow = 0, shrink = 0)
        val bonus = BonusConfigEditor.AmountEditor(
            value = configs?.bonus?.bonusAmount ?: MoneyValue.ZERO,
            start = configs?.bonus?.start ?: DateDuration.ZERO,
            end = configs?.bonus?.end,
        ).appendTo(layout, grow = 0, shrink = 0)
    }
    private val notifies = object : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        val sendNotify = Checkbox(label = "Рассылка уведомлений")
            .checked(configs?.sendNotify ?: true)
            .appendTo(layout, grow = 0, shrink = 0)
        val notifyText = TextareaInput()
            .text(configs?.notifyText ?: "")
            .also { it.dom.style.height = 140.px }
            .withLabel("Текст сообщения")
            .appendTo(layout, grow = 1, shrink = 1)
    }

    init {
        validator.addValidated(config.tagPrefix)
        validator.addValidated(config.searchOffset)
        dom.style.width = 500.px
        dom.style.height = 650.px
        tabs.add("Настройки") {
            viewport.set2(config)
        }
        tabs.add("Уведомление") {
            viewport.set2(notifies)
        }
        tabs.add("Условия") {
            viewport.set2(conditions)
        }
        tabs.active = 0
    }

    override fun build(): JsonObject =
        JsonObject.create(
            BirthdayPromotionConfig(
                includeTags = conditions.tagFilter.includeTags,
                excludeTags = conditions.tagFilter.excludeTags,
                bonus = config.bonus.value2!!,
                sendNotify = notifies.sendNotify.checked,
                notifyText = notifies.notifyText.content.text,
                tagPrefix = this.config.tagPrefix.text,
                searchOffset = config.searchOffset.timeDuration!!,
            ),
        )

    override suspend fun onInit() {
        super.onInit()

        if (record != null && configs != null) {
            conditions.setTags(
                TagFilterDto(
                    includeTags = configs.includeTags,
                    excludeTags = configs.excludeTags,
                ),
            )
//            val tags =
//                memberTagService.getTagList((configs.includeTags + configs.excludeTags).map { it.jdto }.jdto()).await()
//                    .associateBy { it.id }
//
//            conditions.includeTags.content.addTag(
//                configs.includeTags.map { tags[it]!! }
//            )
//            conditions.excludeTags.content.addTag(
//                configs.excludeTags.map { tags[it]!! }
//            )
        }
    }

    companion object : PermanentPromotionEditor {
        override val type: PermanentPromotionType
            get() = PermanentPromotionType.BIRTHDAY_PROMOTION

        override suspend fun show(record: PermanentPromotion?, new: Boolean): PermanentPromotion? {
            val d = BirthdayPromotionDialog(record)
            d.show()
            return d.result
        }
    }
}
