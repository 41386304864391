package org.tlsys.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import org.tlsys.MoneyValue

object MoneyValueSerializer : KSerializer<MoneyValue> {
    override fun deserialize(decoder: Decoder): MoneyValue =
        MoneyValue(decoder.decodeLong())

    override val descriptor: SerialDescriptor =
        Long.serializer().descriptor

    override fun serialize(encoder: Encoder, value: MoneyValue) {
        encoder.encodeLong(value.asLong)
    }
}
