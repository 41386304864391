package org.tlsys.tags

import kotlinx.browser.document
import org.tlsys.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.ui.Checkbox
import org.tlsys.admin.ui.Styles
import org.tlsys.api.MemberTagService
import org.tlsys.api.getTagsIterator
import org.tlsys.async2
import org.tlsys.dto.MemberTagDTO
import org.tlsys.ui.DivComponentWithLayout
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import pw.binom.web.layout.FlexLayout

class TagSelector(selected: List<Long>) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    private inner class Item(val tag: MemberTagDTO) :
        DivComponentWithLayout(alignItems = FlexLayout.AlignItems.Center) {
        val checkbox = Checkbox(label = tag.value, checked = (tag.id in _selectedItems)).appendTo(layout)

        init {
            items += this
            dom.addClass(Styles.LIST_ITEM_STYLE)
            checkbox.enabled = tag.id !in _disabled
            checkbox.EVENT_CHANGED.on {
                if (checkbox.checked) {
                    if (tag.id !in _selectedItems) {
                        _selectedItems.add(tag.id)
                    }
                } else {
                    if (tag.id in _selectedItems) {
                        _selectedItems.remove(tag.id)
                    }
                }
                event_Change.dispatch()
            }
        }
    }

    /**
     * Вызывается при изменении списка выбранных тегов. Изменение поля [disabled] не влечет вызов события
     */
    val event_Change = EventElement()

    /*
        fun disabled(set: Collection<Long>) {
            _disabled.clear()
            _disabled.addAll(set)
            refreshDisabled()
        }
    */
    private fun refreshDisabled() {
        items.forEach {
            it.checkbox.enabled = it.tag.id !in _disabled
        }
    }

    private val items = ArrayList<Item>()
    private val _disabled = HashSet<Long>()

    /**
     * Список тегов, которые запрещено выберать
     */
    var disabled: Collection<Long>
        get() = _disabled
        set(value) {
            _disabled.clear()
            _disabled.addAll(value)
            refreshDisabled()
        }

    private val layout = FlexLayout(dom, direction = FlexLayout.Direction.Column).asParent()

    private val memberTagService by Services.byClass(MemberTagService::class)
    private var tagIterator = memberTagService.getTagsIterator(search = null, minForLoad = 7, maxPart = 10)
    private val scroll = ScrollController(dom)

    private val _selectedItems = ArrayList<Long>()
    val selectedItems: List<Long>
        get() {
            if (_disabled.isEmpty()) {
                return _selectedItems
            }
            return _selectedItems.filter { it !in _disabled }
        }

    init {
        afterConstruct()
        _selectedItems += selected
    }

    override suspend fun onStop() {
        super.onStop()
    }

    private var first = true

    override suspend fun onStart() {
        super.onStart()
        if (first) {
            first = false
            while (!scroll.visibleScrollY) {
                if (!tagIterator.hasNext()) {
                    break
                }
                val e = tagIterator.next()
                Item(tag = e).appendTo(layout, grow = 0, shrink = 0)
            }
            scroll.addScrollListener {
                async2 {
                    if (scroll.endScrollY) {
                        repeat(5) {
                            if (tagIterator.hasNext()) {
                                val e = tagIterator.next()
                                Item(tag = e).appendTo(layout, grow = 0, shrink = 0)
                            }
                        }
                    }
                }
            }
        }
    }
}
