package org.tlsys.admin.ui

import org.tlsys.async2
import org.tlsys.core.CloseableAsync
import org.w3c.dom.HTMLElement
import pw.binom.web.Component

interface ComponentStorage {
    suspend fun element(com: Component<*>): CloseableAsync
    fun <V : HTMLElement, S : Component<V>> S.use(): S
}

class ComponentStorageHelper : ComponentStorage {
    override fun <V : HTMLElement, S : Component<V>> S.use(): S {
        async2 {
            element(this)
        }
        return this
    }

    private var _started = false
    val started: Boolean
        get() = _started
    protected val components = ArrayList<Component<*>>()
    override suspend fun element(com: Component<*>): CloseableAsync {
        components += com
        if (_started)
            com.onStart()
        return CloseableAsync {
            if (_started)
                com.onStop()
            components -= com
        }
    }

    suspend fun start() {
        _started = true
        ArrayList(components).forEach {
            it.onStart()
        }
    }

    suspend fun stop() {
        components.toTypedArray().forEach {
            it.onStop()
        }
        _started = false
    }
}