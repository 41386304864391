package org.tlsys.dto.contacts

import org.tlsys.json.*


class ContactExistException(val contact: String, val type: ContactDTO.Type) : RuntimeException(), JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<ContactExistException> {
        override val type: String
            get() = "ContactExistException"

        override fun read(node: JsonNode) =
                ContactExistException(
                        type = ContactDTO.Type.valueOf(node["type"]!!.string()),
                        contact = node["contact"]!!.string()
                )

        override fun write(obj: ContactExistException) =
                JsonNode.obj(
                        "type" to obj.type.name.json,
                        "contact" to obj.contact.json
                )

    }
}
