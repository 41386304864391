package org.tlsys.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.ElementScope
import org.w3c.dom.HTMLDivElement

@Composable
fun DivFlex(
    direction: FlexDirection = FlexDirection.Column,
    wrap: FlexWrap = FlexWrap.Nowrap,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    func: @Composable ElementScope<HTMLDivElement>.() -> Unit,
) {
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            flexDirection(direction)
            flexWrap(wrap)
        }
        attrs?.invoke(this)
    }) {
        func()
    }
}
