package org.tlsys.admin.ui

import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.ui.*
import org.tlsys.api.ShopsService
import org.tlsys.api.shopTerminalIterator
import org.tlsys.core.filter
import org.tlsys.core.filterNotNull
import org.tlsys.core.map
import org.tlsys.dto.DTOShop
import org.tlsys.dto.DTOTerminal
import pw.binom.web.layout.*
import org.w3c.dom.HTMLDivElement

class TerminalSelectorDialog(hide: List<Long>) : TitleDialog("Терминалы") {
    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    init {
        dom.style.maxHeight = "80%"
        dom.style.maxWidth = "80%"
    }

    private val shopsService by Services.byClass(ShopsService::class)
    private val terminals = ArrayList<TerminalItem>()

    private inner class ShopItem(dto: DTOShop) : DivComponentWithLayout(alignItems = FlexLayout.AlignItems.Center) {
        val terminals = ArrayList<TerminalItem>()
        val checked = Checkbox((dto.title.takeIf { it.isNotBlank() } ?: dto.address)).appendTo(layout)
        var enableEvent = true

        init {
            addClass(Styles.LIST_ITEM_HEAD_STYLE)

            checked.EVENT_CHANGED.on {
                if (enableEvent) {
                    terminals.forEach {
                        it.enableEvent = false
                        it.checked.checked = checked.checked
                        it.enableEvent = true
                    }
                }
            }
        }
    }

    private inner class TerminalItem(val dto: DTOTerminal, val shop: ShopItem) : DivComponentWithLayout(alignItems = FlexLayout.AlignItems.Center) {
        val checked = Checkbox(dto.name).appendTo(layout)
        var enableEvent = true

        init {
            dom.style.padding = "0px 0px 0px 20px"
            addClass(Styles.LIST_ITEM_STYLE)
            terminals += this
            shop.terminals += this

            checked.EVENT_CHANGED.on {
                if (enableEvent) {
                    shop.enableEvent = false
                    shop.checked.checked = shop.terminals.all { it.checked.checked }
                    shop.enableEvent = true
                }

                body.okBtn.enabled = terminals.any { it.checked.checked }
            }
        }
    }

    private var currentShop: ShopItem? = null

    private val iterator = shopsService.shopTerminalIterator(minForLoad = 20, maxPart = 30)
            .filter {
                if (it is DTOTerminal && it.id in hide)
                    return@filter false
                true
            }
            .map {
                when (it) {
                    is DTOShop -> {
                        val item = ShopItem(it)
                        currentShop = item
                        item
                    }

                    is DTOTerminal -> {
                        TerminalItem(it, currentShop!!)
                    }
                    else -> null
                }
            }.filterNotNull()

    private val list = LoadableListView(iterator).appendTo(layout)

    private var canceled = false

    init {
        body.cancel {
            canceled = true
            close()
        }

        body.ok {
            canceled = false
            close()
        }

        body.okBtn.enabled = false
    }

    companion object {
        suspend fun show(hide: List<Long>): List<DTOTerminal>? {
            val d = TerminalSelectorDialog(hide)
            d.show()
            if (d.canceled)
                return null
            return d.terminals.asSequence().filter { it.checked.checked }.map { it.dto }.toList()
        }
    }
}