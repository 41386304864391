package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.core.Closeable
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.w3c.dom.*
import pw.binom.web.AbstractComponent
import kotlin.collections.ArrayList
import kotlin.collections.HashMap
import kotlin.collections.forEach
import kotlin.collections.plusAssign
import kotlin.collections.set

class YearPicker(itemOnRow: Int = 3, val start: Int, val count: Int, selected: Int?) :
    AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    private companion object Styles1 {
        val SELECTED = CSS.genName()
        val MONTH = CSS.genName()
        val MONTH_PICKER = CSS.genName()

        init {
            CSS {
                ".$MONTH_PICKER" {
                    borderCollapse = "collapse"
                    width = "100%"
                    ".$MONTH" {
                        transitionProperty = "background-color, color"
                        transitionDuration = "200ms"
                        padding = "10px"
                        fontFamily = Styles.DEFAULT_TEXT_FONT
                        fontSize = Styles.DEFAULT_FONT_SIZE.px
                        textAlign = "center"
                    }
                    ".$SELECTED" {
                        backgroundColor = "#3aafda"
                        borderRadius = 4.px
                        color = "#fff"
                        cursor = "default"
                    }

                    ".$MONTH:not(.$SELECTED)" {
                        cursor = "pointer"
                    }
                }
            }
        }
    }

    private val changeListeners = ArrayList<() -> Unit>()

    fun onChange(f: () -> Unit): Closeable {
        changeListeners += f
        return Closeable {
            changeListeners.remove(f)
        }
    }

    private var _selected = selected
    private val list = HashMap<Int, HTMLTableCellElement>()
    fun forceSetSelected(selected: Int?) {
        if (selected != null) {
            list[selected!!]?.classList?.remove(Styles1.SELECTED)
        }
        _selected = selected
        if (_selected != null) {
            list[_selected!!]?.classList?.add(Styles1.SELECTED)
        }
    }

    var selected: Int?
        get() = _selected
        set(it) {
            if (selected == it) {
                changeListeners.forEach { it() }
                return
            }

            forceSetSelected(it)
            changeListeners.forEach { it() }
        }

    init {
        if (itemOnRow <= 0) TODO()
    }

    val table = (document.createElement("table") as HTMLTableElement).apply {
        dom.appendChild(this)
        classList.add(Styles1.MONTH_PICKER)
    }
    private val body = (document.createElement("tbody") as HTMLTableSectionElement).apply {
        table.appendChild(this)
    }

    init {
        var allowOnLine = itemOnRow
        var lastRow = body.insertRow() as HTMLTableRowElement
        for (i in start..(start + count - 1)) {
            if (allowOnLine == 0) {
                allowOnLine = itemOnRow
                lastRow = body.insertRow() as HTMLTableRowElement
            }
            val col = lastRow.insertCell() as HTMLTableCellElement
            col.classList.add(Styles1.MONTH)
            list[i] = col
            col.innerText = i.toString()
            allowOnLine--

            col.addEventListener("click", {
                this.selected = i
            })
        }
        forceSetSelected(selected)
    }
}
