package org.tlsys.core.interrogation

import org.tlsys.*
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.api.ShopsService
import org.tlsys.interrogation.InterrogationWebService
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class InterrogationList : EditableList<EditableList.Item>()

class InterrogationDialog(private val interrogation: InterrogationWebService.Interrogation?) : TitleDialog("Опрос") {
    private val shopsService by Services.byClass(ShopsService::class)
    private val interrogationService by Services.byClass(InterrogationWebService::class)
    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    init {
        dom.style.apply {
            width = 400.px
            height = 600.px
        }
    }

    private val question = EditText("Вопрос")
        .textValidator(TextValidators.NOT_BLANK)
        .text(interrogation?.question?.value ?: "")
        .appendTo(layout, grow = 0, shrink = 0)

    private val multiAnswer = Checkbox(
        label = "Множество вариантов выбора",
        checked = interrogation?.multiAnswer?.value ?: false,
    ).appendTo(layout, grow = 0, shrink = 0)
    private val onlyOnce = Checkbox(
        label = "Единичное прохождение",
        checked = interrogation?.onlyOnce?.value ?: true,
    ).appendTo(layout, grow = 0, shrink = 0)
    private val allowOwenAnswer = Checkbox(
        label = "Вариант \"Другое\"",
        checked = interrogation?.allowOwenAnswer?.value ?: false,
    ).appendTo(layout, grow = 0, shrink = 0)

    private val scroll2 = ScrollController(layout.dom)

    private val terminalFilter = TerminalPanel(readOnly = false)
        .withLabel("Терминалы")
        .appendTo(layout, grow = 0, shrink = 0)

    private var cancel = false

    private val editableList = InterrogationList().appendTo(layout)

    init {
        editableList.dom.style.minHeight = 100.px
    }

    init {
        interrogation?.answers?.forEach {
            editableList.add(it.value)
        }
    }

    private val answers
        get() = editableList.values // list.dom.childElements.filter { it.isControl }.mapNotNull { it.con() as? Item }.map { it.text }

    override suspend fun onInit() {
        super.onInit()
        if (interrogation != null) {
            val terms = interrogationService.getInterrogationTerminals(interrogation.id).await()
            terms.mapNotNull {
                shopsService.getTerminalById(it).await()
            }.forEach {
                terminalFilter.element.addTag(it)
            }
        }
    }

    init {
        val mm = MultiValidator(question)
        mm.onValidChange {
            body.okBtn.enabled = it
        }
        body.ok {
            cancel = false
            close()
        }
        body.cancel {
            cancel = true
            close()
        }
    }

    companion object {
        suspend fun show(interrogation: InterrogationWebService.Interrogation?): Pair<InterrogationWebService.Interrogation, List<Long>>? {
            val d = InterrogationDialog(interrogation)
            d.show()
            if (d.cancel) {
                return null
            }
            return InterrogationWebService.Interrogation(
                id = interrogation?.id ?: "".jdto,
                allowOwenAnswer = d.allowOwenAnswer.checked.jdto,
                multiAnswer = d.multiAnswer.checked.jdto,
                question = d.question.text.jdto,
                answers = d.answers.map { it.jdto }.jdto(),
                onlyOnce = d.onlyOnce.checked.jdto,
            ) to d.terminalFilter.element.list.map { it.tag.id }
        }
    }
}
