package org.tlsys.jobs.pages

import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.await
import org.tlsys.jobs.api.JobAdminService
import org.tlsys.jobs.api.executionsIterator
import org.tlsys.jobs.components.ExecutionList
import org.tlsys.jobs.components.JobList
import org.tlsys.ui.*
import pw.binom.web.layout.*

class JobsPage : AbstractPage() {

    companion object {
        val URI = "jobs"
        val TITLE = "Задачи"
    }

    override suspend fun getTitle(): String = TITLE
    private val jobAdminService by Services.byClass(JobAdminService::class)
    private val actions = ActionPanel().appendTo(contentLayout, grow = 0, shrink = 0)
    private val tabs =
        Tabs().appendTo(actions, FlexLayout.FlexItem.AlignSelf.Normal) // .appendTo(contentLayout, grow = 0, shrink = 0)
    private val view = ComponentView().appendTo(contentLayout)
    private val jobList = JobList()
    private val executionList = ExecutionList(jobAdminService.executionsIterator(30, 50))

    init {
        tabs.add("Задачи") {
            view.set2(jobList)
        }

        tabs.add("Выполненые") {
            view.set2(executionList)
        }

        tabs.active = 0

        actions.addSpace()

        val button = actions.iconBtn("add")
        tabs.event_ACTIVE_CHANGED.on {
            button.dom.style.display = if (tabs.active == 0) {
                ""
            } else {
                "none"
            }
        }

        button.onClick {
            val coord = button.dom.getBoundingClientRect()
            PopupMenu.build {
                val list = jobAdminService.getTasks().await()
                if (list.isEmpty()) {
                    it.text("Нет доступных сценариев")
                }
                list.forEach { script ->
                    it.text(script.taskName.value) {
                        jobList.createJob(script)
                    }
                }
            }.await().showRight(x = coord.right.toInt(), y = coord.top.toInt())
        }
    }
}
