package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.dom.removeClass
import org.tlsys.*
import org.tlsys.admin.addClass
import org.tlsys.css.CSS
import org.tlsys.ui.*
import org.w3c.dom.*
import pw.binom.web.layout.*
import pw.binom.web.AbstractComponent
import pw.binom.web.Component

open class MaterialTable :
    AbstractComponent<HTMLTableElement>() {
    override val dom: HTMLTableElement = document.createElement("table").unsafeCast<HTMLTableElement>()

    companion object {
        val HEADER_STYLE = CSS.style {
            height = 47.px
            "td" {
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.DEFAULT_FONT_SIZE.px
                fontWeight = "bold"
                padding = "15px 5px"
                color = "rgba(0,0,0,.6)"
                fontWeight = "bold"
                top = "0"
                position = "sticky"
                backgroundColor = "rgb(244, 244, 244)"
                boxShadow = "0px 1px 0px 0px #d0d0d0"
            }
        }.name

        val ROW_STYLE = CSS.style {
            "td" {
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.DEFAULT_FONT_SIZE.px
                padding = "15px 5px"
                color = "rgba(0,0,0,.6)"
                fontWeight = "500"
            }
            borderBottom = "1px solid #d0d0d0"
        }.name
        val ROW_BG_STYLE = CSS.style {
            ":nth-child(odd)" then {
                backgroundColor = "#f2f2f2"
            }
        }.name
        val SEPARATOR = CSS.style {
            "td" {
                top = "47px"
                position = "sticky"
                backgroundColor = "rgb(244, 244, 244)"
                boxShadow = "0px 1px 0px 0px #d0d0d0"
                color = "rgba(0,0,0,.6)"
                fontWeight = "bold"
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.DEFAULT_FONT_SIZE.px
                padding = "15px 5px"
            }
        }.name
    }

    internal val thead = dom.createTHead()
    internal val tbody = dom.createTBody()
    val rows
        get() = tbody.childNodes.asList().listMap { it as HTMLTableRowElement; it.con().unsafeCast<Row>() }

    override suspend fun onStart() {
        super.onStart()
        Component.callOnStart(thead)
        Component.callOnStart(tbody)
    }

    override suspend fun onStop() {
        Component.callOnStop(tbody)
        Component.callOnStop(thead)
        super.onStop()
    }

    abstract class Column :
        AbstractComponent<HTMLTableCellElement>() {
        override val dom: HTMLTableCellElement = document.createElement("td").unsafeCast<HTMLTableCellElement>()
    }

    class ComponentColumn<H : HTMLElement, T : Component<H>>(val component: T) : Column() {
        init {
            dom.appendChild(component.dom)
        }

        override suspend fun onStart() {
            super.onStart()
            component.onStart()
        }

        override suspend fun onStop() {
            component.onStop()
            super.onStop()
        }
    }

    open class LayoutColumn(rowSpan: Int = 1) : Column() {
        private var _layout = DivLayout().appendTo(dom)
        val layout
            get() = _layout.layout

        var rowSpan: Int
            get() = dom.rowSpan
            set(value) {
                dom.rowSpan = value
            }
        var colSpan: Int
            get() = dom.colSpan
            set(value) {
                dom.colSpan = value
            }

        init {
            dom.rowSpan = rowSpan
            dom.colSpan = colSpan
        }
    }

    open class TextColumn(text: String = "", rowSpan: Int = 1) : Column() {
        var text: String
            get() = dom.innerText
            set(value) {
                dom.innerText = value
            }

        var rowSpan: Int
            get() = dom.rowSpan
            set(value) {
                dom.rowSpan = value
            }
        var colSpan: Int
            get() = dom.colSpan
            set(value) {
                dom.colSpan = value
            }

        init {
            dom.innerText = text
            dom.rowSpan = rowSpan
            dom.colSpan = colSpan
        }
    }

    open class LinkColumn(text: String, href: String, rowSpan: Int = 1) : Column() {
        val element = document.createLink()!!.appendTo(dom)
        var text: String
            get() = element.innerText
            set(value) {
                element.innerText = value
            }

        var href: String
            get() = element.href
            set(value) {
                element.href = value
            }

        init {
            this.text = text
            this.href = href
            dom.rowSpan = rowSpan
            element.addClass(Styles.LINK)
//            dom.addClass(Styles.SIMPLE_TEXT)
        }
    }

    open class Row :
        AbstractComponent<HTMLTableRowElement>() {
        override val dom: HTMLTableRowElement = document.createElement("tr").unsafeCast<HTMLTableRowElement>()
        fun <T : Column> append(element: T) {
            dom.appendChild(element.dom)
            if (isStarted) {
                async2 {
                    element.onStart()
                }
            }
        }

        fun removeSelf() {
            if (isStarted) {
                async2 {
                    onStop()
                    dom.remove()
                }
            } else {
                dom.remove()
            }
        }

        init {
            addClass(ROW_STYLE)
            addClass(ROW_BG_STYLE)
        }
    }

    open class BindedRow : Row() {
        init {
            dom.addClass(SEPARATOR)
            dom.removeClass(ROW_STYLE)
            dom.removeClass(ROW_BG_STYLE)
        }
    }

    open class BindedTextRow(text: String, rowSpan: Int = 1, colSpan: Int = 1) : BindedRow() {
        val column = TextColumn(text, rowSpan).appendTo(this)

        var rowSpan: Int
            get() = column.rowSpan
            set(value) {
                column.rowSpan = value
            }

        var colSpan: Int
            get() = column.colSpan
            set(value) {
                column.colSpan = value
            }

        var text: String
            get() = column.text
            set(value) {
                column.text = value
            }

        init {
            this.rowSpan = rowSpan
            this.colSpan = colSpan
        }
    }

    open class Header : Row() {
        init {
            dom.addClass(HEADER_STYLE)
            dom.removeClass(ROW_STYLE)
            dom.removeClass(ROW_BG_STYLE)
        }
    }

    fun appendBody(row: Row) {
        tbody.appendChild(row.dom)
        if (isStarted) {
            async2 {
                row.onStart()
            }
        }
    }

    fun appendBodyAfter(row: Row, after: Row) {
        tbody.insertAfter(row.dom, after.dom)
        if (isStarted) {
            async2 {
                row.onStart()
            }
        }
    }

    fun appendFirstBody(row: Row) {
        tbody.appendChild(row.dom)
        tbody.appendChildFirst(row.dom)
        if (isStarted) {
            async2 {
                row.onStart()
            }
        }
    }

    fun appendHeader(header: Header) {
        thead.appendChild(header.dom)
        if (isStarted) {
            async2 {
                header.onStart()
            }
        }
    }

    suspend fun clearBody() {
        do {
            val el = tbody.firstElementChild as? HTMLElement ?: break
            Component.getComponent(el)?.onStop()
//            val row = el.asDynamic().CONTROL as Row
//            row.onStop()
            el.remove()
        } while (true)
    }

    init {
//        dom.style.position = "relative"
        dom.style.maxHeight = "100%"
    }
}

fun <T : MaterialTable.Row> T.appendFirstTo(table: MaterialTable): T {
    if (this is MaterialTable.Header) {
        throw IllegalArgumentException("$this can't be header row")
    } else {
        table.appendFirstBody(this)
    }
    return this
}

fun <T : MaterialTable.Row> T.appendTo(table: MaterialTable): T {
    if (this is MaterialTable.Header) {
        table.appendHeader(this)
    } else {
        table.appendBody(this)
    }
    return this
}

fun <R : MaterialTable.Row, T : MaterialTable.Column> T.appendTo(row: R): T {
    row.append(this)
    return this
}

fun <T, R> List<T>.listMap(func: (T) -> R) = object : AbstractList<R>() {
    override val size: Int
        get() = this@listMap.size

    override fun get(index: Int): R = func(this@listMap[index])
}

fun <T : MaterialTable.Column> T.widthPx(width: Int): T {
    dom.style.width = width.px
    return this
}
