package org.tlsys.core.report

import kotlinx.browser.window
import org.tlsys.admin.core.Services
import org.tlsys.admin.core.join
import org.tlsys.admin.ui.*
import org.tlsys.api.ProcessService
import org.tlsys.await
import org.tlsys.core.api.ReportService
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

object ClientReport : ReportPage.ReportConfigDialog {
    override val title = "По Клиентам"
    override suspend fun make() {
        ClientReportConfigDialog.show()
    }
}

private class ClientReportConfigDialog : TitleDialog("Отчет по Клиентам") {
    private val body = SaveCancelBody("Создать", "Закрыть")
        .appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val cardPrAndSexFilter =
        DivLayout(direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)
    private val sexGroup = RadioBox.Group()
    private val sexMGroup = ElementGroup("Пол").appendTo(cardPrAndSexFilter.layout, grow = 1, shrink = 1)
    private val sexMale = RadioBox("Мужской").appendTo(sexMGroup.layout).group(sexGroup)
    private val sexFamale = RadioBox("Женский").appendTo(sexMGroup.layout).group(sexGroup)
    private val sexNotFiltered = RadioBox("Не фильтровать").appendTo(sexMGroup.layout).group(sexGroup).checked()

    private val cardPrGroup = RadioBox.Group()
    private val cardPrMGroup = ElementGroup("CardPr").appendTo(cardPrAndSexFilter.layout, grow = 1, shrink = 1)
    private val cardPrInstalled = RadioBox("Установлено").appendTo(cardPrMGroup.layout).group(cardPrGroup)
    private val cardPrNotInstalled = RadioBox("Не установлено").appendTo(cardPrMGroup.layout).group(cardPrGroup)
    private val cardPrNotFiltered =
        RadioBox("Не фильтровать").appendTo(cardPrMGroup.layout).group(cardPrGroup).checked()

    private val includeTags = TagPanel(readOnly = false)
        .withLabel("Фильтр по метокам")
        .appendTo(layout)

    private val excludeTags = TagPanel(readOnly = false)
        .withLabel("Исключить по метке")
        .appendTo(layout)

    private val terminalFilter = TerminalPanel(readOnly = false)
        .withLabel("Фильтр по терминалам")
        .appendTo(layout)

    private val reportService by Services.byClass(ReportService::class)
    private val processService by Services.byClass(ProcessService::class)

    init {
        body.ok {
            ProcessBlocker.block("") {
                val process = reportService.genReportByClient(
                    memberFilter = null,
                    filterByTags = includeTags.element.list.map { it.tag.id.jdto }.jdto(),
                    excludeTags = excludeTags.element.list.map { it.tag.id.jdto }.jdto(),
                    terminalFilter = terminalFilter.element.list.map { it.tag.id.jdto }.jdto(),
                    birthdayAfter = null,
                    birthdayBefore = null,
                    cardPrInstalled = when {
                        cardPrInstalled.checked -> true
                        cardPrNotInstalled.checked -> false
                        else -> null
                    }?.jdto,
                    sex = when {
                        sexMale.checked -> true
                        sexFamale.checked -> false
                        else -> null
                    }?.jdto,
                ).await()

                val process1 = processService.getProcess(process).await()!!
                val file = process1.join(1_000).await()!!
                window.open(file, "_blank")
                MessageDialog.showInfo("Отчет сгенерирован!")
            }
        }

        body.cancel {
            close()
        }
    }

    companion object {
        suspend fun show() {
            val d = ClientReportConfigDialog()
            d.show()
        }
    }
}
