package org.tlsys.promotion.dto

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.DateDuration
import org.tlsys.Percent

@Serializable
data class ClearBonusOnUnusedConfig(
    @SerialName("tag_filter")
    val tagFilter: TagFilterDto = TagFilterDto(),
    @SerialName("clean_after")
    val cleanAfter: DateDuration = DateDuration.ZERO,
    @SerialName("percent")
    val percent: Percent = Percent.ZERO,
    @SerialName("send_notify")
    val sendNotify: Boolean = false,
    @SerialName("notify_before_clean")
    val notifyBeforeCleaner: DateDuration = DateDuration.ZERO,
    val text: String = "",
)
