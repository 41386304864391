package org.tlsys.json

interface Primitive<T> : JDTO {
    val value: T
}

class JByte(override val value: Byte) : Primitive<Byte> {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JByte> {
        override val type: String
            get() = "b"

        override fun read(node: JsonNode): JByte =
                JByte(node["value"]!!.byte())

        override fun write(obj: JByte): JsonNode =
                JsonNode.obj().set("value", obj.value.json)
    }
}

class JBoolean(override val value: Boolean) : Primitive<Boolean> {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault



    companion object : JDTOFactory<JBoolean> {
        override val type: String
            get() = "a"

        override fun read(node: JsonNode): JBoolean =
                JBoolean(node["value"]!!.boolean())

        override fun write(obj: JBoolean): JsonNode =
                JsonNode.obj().set("value", obj.value.json)
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as JBoolean

        if (value != other.value) return false

        return true
    }

    override fun hashCode(): Int {
        return value.hashCode()
    }
}

class JLong(override val value: Long) : Primitive<Long> {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JLong> {
        override val type: String
            get() = "z"

        override fun read(node: JsonNode): JLong =
                JLong(node["value"]!!.string().toLong())

        override fun write(obj: JLong): JsonNode =
                JsonNode.obj().set("value", obj.value.toString().json)
    }
}

class JInt(override val value: Int) : Primitive<Int> {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JInt> {
        override val type: String
            get() = "i"

        override fun read(node: JsonNode): JInt =
                JInt(node["value"]!!.int())

        override fun write(obj: JInt): JsonNode =
                JsonNode.obj().set("value", obj.value.json)
    }
}

class JFloat(override val value: Float) : Primitive<Float> {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JFloat> {
        override val type: String
            get() = "f"

        override fun read(node: JsonNode): JFloat =
                JFloat(node["value"]!!.float())

        override fun write(obj: JFloat): JsonNode =
                JsonNode.obj().set("value", obj.value.json)
    }
}

class JString(override val value: String) : Primitive<String> {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault



    companion object : JDTOFactory<JString> {

        val EMPTY: JString = "".jdto

        override val type: String
            get() = "s"

        override fun read(node: JsonNode): JString =
                JString(node["value"]!!.string())

        override fun write(obj: JString): JsonNode =
                JsonNode.obj().set("value", obj.value.json)
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as JString

        if (value != other.value) return false

        return true
    }

    override fun hashCode(): Int {
        return value.hashCode()
    }
}

class JSet<T : JDTO?>(val value: Set<T>) : Set<T> by value, JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JSet<*>> {
        override val type: String
            get() = "jset"

        override fun read(node: JsonNode): JSet<*> =
                JSet(
                        node["values"]!!.map {
                            it?.let { JsonFactory.read<JDTO>(it) }
                        }.toSet()
                )

        override fun write(obj: JSet<*>): JsonNode =
                JsonNode.obj()
                        .set("values", JsonNode.array().putAll(obj.map { JsonFactory.write(it) }))
    }
}

class JList<T : JDTO?>(val value: List<T>) : List<T> by value, JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    constructor() : this(emptyList())

    companion object : JDTOFactory<JList<*>> {
        override val type: String
            get() = "jlist"

        override fun read(node: JsonNode): JList<*> =
                JList(
                        node["values"]!!.map {
                            it?.let { JsonFactory.read<JDTO>(it) }
                        }
                )

        override fun write(obj: JList<*>): JsonNode =
                JsonNode.obj()
                        .set("values", JsonNode.array().putAll(obj.map { JsonFactory.write(it) }))
    }
}
fun <T:JDTO?> JList.Companion.empty()=JList<T>(emptyList())

class JMap<K : JDTO?, V : JDTO?>(val value: Map<K, V> = emptyMap()) : Map<K, V>, JDTO {

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    override val entries: Set<Map.Entry<K, V>>
        get() = value.entries
    override val keys: Set<K>
        get() = value.keys
    override val size: Int
        get() = value.size
    override val values: Collection<V>
        get() = value.values

    override fun containsKey(key: K): Boolean = value.containsKey(key)
    override fun containsValue(value: V): Boolean = this.value.containsValue(value)
    override fun get(key: K): V? = value.get(key)
    override fun isEmpty(): Boolean = value.isEmpty()

    companion object : JDTOFactory<JMap<*, *>> {
        override val type: String
            get() = "jmap"

        override fun read(node: JsonNode): JMap<*, *> =
                JMap(
                        node["values"]!!.associate {
                            it!!["key"]?.let { JsonFactory.read<JDTO>(it) } to it!!["value"]?.let { JsonFactory.read<JDTO>(it) }
                        }
                )

        override fun write(obj: JMap<*, *>): JsonNode {
            val out = JsonNode.array()

            out.putAll(
                    obj.value.map {
                        JsonNode.obj()
                                .set("key", JsonFactory.write(it.key))
                                .set("value", JsonFactory.write(it.value))
                    }
            )
            val o = JsonNode.obj()
            o.set("values", out)
            return o
        }
    }
}

class JPair<FIRST : JDTO?, SECOD : JDTO?>(val first: FIRST, val secod: SECOD) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JPair<*, *>> {
        override val type: String
            get() = "pair"

        override fun read(node: JsonNode) =
                JPair(
                        first = node["first"]?.let { JsonFactory.read<JDTO>(it) },
                        secod = node["second"]?.let { JsonFactory.read<JDTO>(it) }
                )

        override fun write(obj: JPair<*, *>) =
                JsonNode.obj()
                        .set("first", JsonFactory.write(obj.first))
                        .set("second", JsonFactory.write(obj.secod))

    }
}

val <A : JDTO?, B : JDTO> Pair<A, B>.jdto: JPair<A, B>
    get() = JPair(first, second)

fun <FIRST : JDTO?, SECOD : JDTO?> jpair(first: FIRST, secod: SECOD) = JPair(first, secod)

fun <K : JDTO?, V : JDTO?> Map<K, V>.jdto() = JMap(this)
fun <T : JDTO?> List<T>.jdto() = JList(this)
fun <T : JDTO?> Set<T>.jdto() = JSet(this)

val Long.jdto: JLong
    get() = JLong(this)

val Int.jdto: JInt
    get() = JInt(this)

val String.jdto: JString
    get() = JString(this)

val Float.jdto: JFloat
    get() = JFloat(this)

val Boolean.jdto: JBoolean
    get() = JBoolean(this)

val Byte.jdto: JByte
    get() = JByte(this)