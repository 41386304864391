package org.tlsys.admin.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.builders.InputAttrsScope
import org.jetbrains.compose.web.dom.*
import org.tlsys.admin.DoNothing
import org.tlsys.admin.Materialize
import org.tlsys.admin.form.ValidationResult
import org.tlsys.admin.form.Validator
import org.w3c.dom.HTMLDivElement

private var counter = 0

@Composable
fun InputText(
    placeHolder: String,
    valid: MutableState<Boolean>? = null,
    value: String = "",
    withErrorMessage: Boolean = true,
    validator: Validator<String>? = null,
    inputAttrs: (InputAttrsScope<String>.(Validator<String>) -> Unit)? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
    val id = remember { "inputText${counter++}" }
    Div(attrs = {
        classes("input-field", "inline")
        attrs?.invoke(this)
    }) {
        val errorMessage = remember {
            mutableStateOf(validator?.valid(value) ?: ValidationResult.valid())
        }
        valid?.value = errorMessage.value.isValid
        Input(type = InputType.Text, attrs = {
            ref {
                Materialize.updateTextFields()
                DoNothing
            }
            inputAttrs?.invoke(this, validator ?: Validator.valid())
            id(id)
            defaultValue(value)
            onInput {
                val currentValue = it.value
                errorMessage.value = validator?.valid(currentValue) ?: ValidationResult.valid()
                valid?.value = errorMessage.value.isValid
            }
            if (errorMessage.value.isValid) {
                classes("valid")
            } else {
                classes("invalid")
            }
        })
        Label(forId = id) {
            Text(placeHolder)
        }
        if (withErrorMessage) {
            Span(attrs = {
                classes("helper-text")
                attr("data-error", errorMessage.value.message ?: "")
            })
        }
    }
}
