package org.tlsys.admin.serialization

import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass
import org.tlsys.WGoodElement
import org.tlsys.lk.OsmiConfigDTO
import org.tlsys.lk.OsmicardEventDto
import org.tlsys.lk.OsmicardTemplateDto
import org.tlsys.serialization.DateTimeSerializer
import pw.binom.date.DateTime
import kotlin.js.JsName

private val vvvv = PolymorphicSerializer(Any::class)

interface R {
    companion object {
        fun fromJson(json: String) = json4.decodeFromString(vvvv, json)
//        fun fromJsonList(json: String) = json4.decodeFromString(ListSerializer(Response.serializer()), json)
    }

    @OptIn(InternalSerializationApi::class)
    fun toJson(): String = json4.encodeToString(vvvv, this)
}

@OptIn(InternalSerializationApi::class)
fun List<R>.toJson(): String = json4.encodeToString(this)

private val module5 = SerializersModule {
    contextual(DateTime::class, DateTimeSerializer)
    polymorphic(Any::class) {
        subclass(RemoteException.ServerException::class)
        subclass(Response::class)
        subclass(String::class, StringSerializer)
        subclass(DateWrapper::class)
        subclass(Int::class, IntSerializer)
        subclass(Unit::class, UnitSerializer)
        subclass(Float::class, FloatSerializer)
        subclass(Double::class, DoubleSerializer)
        subclass(Long::class, LongSerializer)
        subclass(OsmicardEventDto::class)
        subclass(Boolean::class, BooleanSerializer)
        subclass(RList::class, RList.serializer(vvvv.nullable) as KSerializer<RList<*>>)
        subclass(WGoodElement::class)
        subclass(OsmiConfigDTO::class)
        subclass(OsmicardTemplateDto::class)
    }
}

@Serializable
@SerialName("datetime")
class DateWrapper(
    @Serializable(DateTimeSerializer::class)
    val dateTime: DateTime
)

@SerialName("t")
@Serializable
class RList<E> private constructor(val value: ArrayList<E>) : R, MutableList<E> by value {
    companion object {
        fun <E> make(list: List<E>): RList<E> =
            when (list) {
                is RList -> list
                is ArrayList -> RList(list)
                else -> RList(ArrayList(list))
            }
    }
}

fun <E> List<E>.dto() = RList.make(this)

@JsName("json4")
private val json4 = Json {
    serializersModule = module5
    classDiscriminator = "#type"
    ignoreUnknownKeys = true
}

private val vv = MapSerializer(String.serializer(), PolymorphicSerializer(Any::class).nullable)
fun Map<String, Any?>.toJson() = json4.encodeToString(vv, this)

fun String.jsonToMap() = json4.decodeFromString(vv, this)
fun parseResponse(json: String) = json4.decodeFromString(Response.serializer(), json)

fun makeResponse(response: Response) = json4.encodeToString(Response.serializer(), response)
