package org.tlsys

import kotlinx.serialization.Serializable
import org.tlsys.dto.ExternalManifest
import org.tlsys.dto.ExternalScriptDto
import org.tlsys.serialization.URISerializer
import pw.binom.url.URI

@Serializable
class ExternalScriptManifest(
    val manifest: ExternalManifest?,
    val script: ExternalScriptDto,
    val valid: Boolean,
    @Serializable(URISerializer::class)
    val rootUri: URI,
)
