package org.tlsys

import kotlin.coroutines.Continuation
import kotlin.coroutines.EmptyCoroutineContext
import kotlin.coroutines.startCoroutine
import kotlin.js.Promise

fun <T> (suspend () -> T).start() = Promise<T> { resolve, reject ->
    this.startCoroutine(object : Continuation<T> {
        override fun resumeWith(result: Result<T>) {
            if (result.isSuccess) {
                result.onSuccess(resolve)
            }
            if (result.isFailure) {
                reject(result.exceptionOrNull()!!)
            }
        }

        override val context = EmptyCoroutineContext
    })
}

fun <T> async(c: suspend () -> T): Promise<T> = c.start()