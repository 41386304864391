package org.tlsys.shops

import org.tlsys.DIALOG_AUTO_FOCUS_ON_INPUT
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.dto.DTOShop
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import pw.binom.web.layout.*

class AddShopDialog private constructor(val shop: DTOShop?) :
    TitleDialog(if (shop === null) "Создание торгового объекта" else "Редактирование торгового объекта") {
    init {
        dom.style.width = "320px"
        dom.style.height = "240px"
    }

    private var _canceled: Boolean = true

    val canceled: Boolean
        get() = _canceled

    val body = SaveCancelBody().appendTo(layout, shrink = 1, grow = 1)

    val name = EditText(placeHolder = "Наименование").textValidator(TextValidators.NOT_BLANK)
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)
    val adress = EditText("Адрес").appendTo(body.componentsLayout, grow = 0, shrink = 0)

    private fun refreshStatus() {
        body.okBtn.enabled =
            name.valid &&
            adress.valid
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    override suspend fun onStart() {
        super.onStart()
        if (DIALOG_AUTO_FOCUS_ON_INPUT) {
            name.focus()
            name.select()
        }
    }

    init {
        name.eventValidChange.on {
            refreshStatus()
        }
        adress.eventValidChange.on {
            refreshStatus()
        }

        if (shop !== null) {
            name.text = shop.title
            adress.text = shop.address
        }

        refreshStatus()

        body.ok {
            _canceled = false
            close()
        }

        body.cancel {
            cancelClick()
        }
    }

    companion object {
        suspend fun show(shop: DTOShop?): Pair<String, String>? {
            val d = AddShopDialog(shop)
            d.show()
            if (d.canceled) {
                return null
            }
            return d.name.text to d.adress.text
        }
    }
}
