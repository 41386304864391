package org.tlsys.serialization

import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import org.tlsys.Email

@Serializable
data class EmailTestData(
    @Contextual
    val destination: Email,
    val text: String,
)
