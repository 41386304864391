package org.tlsys

import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLScriptElement

object Jivo {
    private const val LOGIN = "LOGIN"
    private const val NAME = "NAME"
    private const val STATUS = "NAME"
    private const val COMPANY_ID = "COMPANY_ID"
    private const val COMPANY = "COMPANY"

    fun setSession(session: OSession?) {
        if (session == null) {
            fields.clear()
            fields[STATUS] = Field(title = "Статус", content = "Не вошел в систему")
        } else {
            fields[STATUS] = Field(title = "Статус", content = "Вошел в систему")
            fields[COMPANY_ID] = Field(title = "ID компании", content = "#${session.company.id}")
            fields[COMPANY] = Field(title = "Компания", content = "${session.company.name}")
            fields[LOGIN] = Field(title = "Логин", content = "${session.user.login}")
            fields[NAME] = Field(title = "Имя", content = "${session.user.name}")
        }
        update()
    }

    fun loginField(login: String?): Field? {
        login ?: return null
        return Field(title = "Login", content = login)
    }

    fun nameField(name: String?): Field? {
        name ?: return null
        return Field(title = "Имя пользователя", content = name)
    }

    class Field(val title: String?, val content: String? = null, val link: String? = null)

    private val callback: () -> Unit = {
        inited = true
        if (fields.isNotEmpty()) {
            privateSetCustomData(fields.values)
        }
    }

    private var inited = false
    private var fields = HashMap<String, Field>()

    fun init() {
        inited = jsTypeOf(window.asDynamic()["jivo_api"]) != "undefined"

        val script = document.createElement("script").unsafeCast<HTMLScriptElement>()
        script.setAttribute("data-jv-id", "dwMwv4VCFt")
        script.async = true
        script.src = "//code.jivosite.com/widget.js"
        document.head!!.appendChild(script)
//        <script src="//code.jivosite.com/widget.js" data-jv-id="dwMwv4VCFt" async></script>

        if (!inited) {
            window.asDynamic()["jivo_onLoadCallback"] = callback
        }
    }

    private fun update() {
        if (inited) {
            privateSetCustomData(fields.values)
        }
    }

    operator fun set(key: String, value: Field?) {
        if (value == null) {
            fields.remove(key) ?: return
        } else {
            fields[key] = value
        }
        update()
    }

    private fun privateSetCustomData(fields: Collection<Field>) {
        try {
            val data = fields.map {
                val obj = js("({})")
                if (it.title != null) {
                    obj.title = it.title
                }
                if (it.content != null) {
                    obj.content = it.content
                }
                if (it.link != null) {
                    obj.link = it.link
                }
                obj
            }.toTypedArray()
            window.asDynamic().jivo_api.setCustomData(data)
        } catch (e: dynamic) {
            console.error("Error: ", e)
        }
    }
}
