package org.tlsys.cms

import org.tlsys.admin.ui.Styles.DEFAULT_TEXT_FONT
import org.tlsys.css.CSS
import org.tlsys.px

// inline val DEFAULT_TEXT_FONT: String
//    get() = "Open Sans"
inline val DEFAULT_FONT_SIZE: Int
    get() = 14
val LINK = "link"
val SIMPLE_TEXT = "simpleText"
val ICON_SIZE = 23

private var styles = CSS {
    ".$SIMPLE_TEXT" {
        fontFamily = DEFAULT_TEXT_FONT
        fontSize = DEFAULT_FONT_SIZE.px
    }
    ".$LINK" {
        fontSize = DEFAULT_FONT_SIZE.px
        color = "#3aafda"
        fontFamily = DEFAULT_TEXT_FONT
        cursor = "pointer"
        textDecoration = "none"
    }
}

/**
 * Стиль чередования линий. Нужен для листов элементов
 */
val LIST_ITEM_STYLE = CSS.style {
    textAlign = "center"
    paddingRight = 10.px
    ":nth-child(2n)" then {
        backgroundColor = "rgba(0, 0, 0, 0.02)"
    }

    ":hover" then {
        backgroundColor = "rgba(0, 0, 0, 0.06)"
    }
    transition = "background-color 50ms"
}.name
