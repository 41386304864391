package org.tlsys.promotion.dto

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TagFilterDto(
    @SerialName("include_tags")
    val includeTags: List<Long> = emptyList(),
    @SerialName("exclude_tags")
    val excludeTags: List<Long> = emptyList(),
) {
    companion object {
        val EMPTY = TagFilterDto(
            includeTags = emptyList(),
            excludeTags = emptyList(),
        )
    }
}
