package org.tlsys.core.promotion.externalScriptPromotion

import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.tlsys.ConfigArgs
import org.tlsys.ExternalUiMethods
import org.tlsys.admin.form.Validated
import org.tlsys.styles.ExternalScript
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.url.URI
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.*
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds
import kotlin.time.ExperimentalTime

@OptIn(ExperimentalTime::class)
class UiContainerExternalUI(baseTimeout: Duration = 5.seconds) : ExternalUI<HTMLDivElement>,
    AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    private val ui = BaseExternalUI(baseTimeout = baseTimeout).appendTo(dom)
    private val bgDiv = document.createDiv().appendTo(dom)
    private val messageDiv = document.createDiv().appendTo(bgDiv)
    val config: ConfigArgs
        get() = ui.config

    init {
        dom.addClass(ExternalScript.CONTAINER)
        bgDiv.addClass(ExternalScript.SCREEN_BG)
        bgDiv.addClass(ExternalScript.EMPTY_SCREEN)
        messageDiv.addClass(ExternalScript.SCREEN_MESSAGE)
        messageDiv.innerText = "Выберите скрипт"
    }

    val validator: Validated
        get() = ui.validator

    override val uri: URI?
        get() = ui.uri

    override suspend fun dispatch(method: ExternalUiMethods, args: Any?) {
        ui.dispatch(method, args)
    }

    override suspend fun call(method: ExternalUiMethods, args: Any?): Any? =
        ui.call(method, args)

    override suspend fun loadPage(url: URI): Boolean {
        removeAllClasses()
        messageDiv.innerText = "Загрузка"
        bgDiv.addClass(ExternalScript.LOADING_SCREEN)
        val r = try {
            ui.loadPage(url)
        } catch (e: dynamic) {
            false
        }
        if (!r) {
            messageDiv.innerText = "Ошибка загрузки скрипта"
            removeAllClasses()
            bgDiv.addClass(ExternalScript.ERROR_SCREEN)
        } else {
            bgDiv.addClass(ExternalScript.LOADED_SCREEN)
        }
        return r
    }

    private fun removeAllClasses() {
        bgDiv.removeClass(ExternalScript.EMPTY_SCREEN)
        bgDiv.removeClass(ExternalScript.ERROR_SCREEN)
        bgDiv.removeClass(ExternalScript.LOADING_SCREEN)
        bgDiv.removeClass(ExternalScript.LOADED_SCREEN)
    }

    override suspend fun stop() {
        removeAllClasses()
        bgDiv.addClass(ExternalScript.EMPTY_SCREEN)
        messageDiv.innerText = "Выберите скрипт"
        ui.stop()
    }

    override suspend fun onStart() {
        super.onStart()
        console.error("UiContainerExternalUI: onStart")
        ui.onStart()
    }

    override suspend fun onStop() {
        ui.onStop()
        super.onStop()
    }
}
