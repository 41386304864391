package org.tlsys.goods.services

import org.tlsys.AutoLoadAsyncListIterator
import org.tlsys.async2
import org.tlsys.goods.DTOGood
import org.tlsys.goods.DTOGoodGroup
import org.tlsys.goods.api.GoodsService
import org.tlsys.json.jdto

fun GoodsService.groupIterator(parent: String?, minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        getGroups(offset = offset.jdto, max = max.jdto, parent = parent?.jdto).promise
    })

fun GoodsService.goodsIterator(parent: String?, minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        getGoods(offset = offset.jdto, max = max.jdto, parent = parent?.jdto).promise
    })

fun GoodsService.classifiersIterator(minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        getClassifiers(offset = offset.jdto, max = max.jdto).promise
    })

fun GoodsService.searchGroupsIterator(search: String, minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator<DTOGoodGroup>(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        async2 {
            searchGroups(offset = offset.jdto, max = max.jdto, search = search.jdto).await() ?: listOf<DTOGoodGroup>()
        }
    })

fun GoodsService.searchGoodsIterator(search: String, minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator<DTOGood>(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        async2 { searchGoods(offset = offset.jdto, max = max.jdto, search = search.jdto).await() ?: listOf<DTOGood>() }
    })

fun GoodsService.searchClassifierIterator(search: String, minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        async2 { searchClassifiers(offset = offset.jdto, max = max.jdto, search = search.jdto).await() ?: listOf() }
    })
