package org.tlsys

import kotlinx.serialization.InternalSerializationApi
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.serializer
import org.tlsys.serialization.JsonObjectSerializer
import org.tlsys.serialization.internalJson
import kotlin.jvm.JvmInline

@Serializable(JsonObjectSerializer::class)
@JvmInline
@OptIn(InternalSerializationApi::class)
value class JsonObject private constructor(private val value: String) {
    companion object {

        inline fun <reified T : Any> create(value: T) =
            fromString(internalJson.encodeToString(T::class.serializer(), value))

        fun fromString(value: String) = JsonObject(value)
    }

    inline fun <reified T : Any> getObject() =
        try {
            internalJson.decodeFromString(T::class.serializer(), toString())
        } catch (e: Throwable) {
            throw SerializationException("Can't decode JSON \"$this\" to ${T::class}", e)
        }

    override fun toString(): String = value
}
