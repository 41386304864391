package org.tlsys.members

import kotlinx.browser.document
import org.tlsys.WebUtils
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.ContractsService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.cms.SIMPLE_TEXT
import org.tlsys.dto.auth.CorePermission
import org.tlsys.dto.contacts.ContactDTO
import org.tlsys.dto.contacts.ContactExistException
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.permission
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class ContactHistoryFragment(val memberId: Long) : AbstractFragment<HTMLDivElement>(document.createDiv()) {
    private val allowEdit by permission(CorePermission.EDIT_MEMBERS_CONTACTS)
    private val layout = FlexLayout(this, direction = FlexLayout.Direction.Column)
    private val contractsService by Services.byClass(ContractsService::class)

    override suspend fun onStart() {
        super.onStart()
        layout.onStart()
    }

    override suspend fun onStop() {
        layout.onStop()
        super.onStop()
    }

    override suspend fun onInit() {
        super.onInit()
        contractsService.getAllContact(memberId = memberId.jdto).await().forEach {
            Item(it).appendTo(table)
        }
    }

    private val table = MaterialTable().appendTo(layout, grow = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Тип").appendTo(header).widthPx(1)
            MaterialTable.TextColumn("Значение").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    init {
        if (allowEdit) {
            val addBtn = AddButton().appendTo(layout, grow = 0, shrink = 0)
            addBtn.onClick {
                val d = ContactEditDialog.show(null).await() ?: return@onClick
                val item = ProcessBlocker.block("Создание контакта") {
                    try {
                        val d = contractsService.create(
                            type = d.type.name.jdto,
                            memberId = memberId.jdto,
                            value = d.value.jdto,
                        ).await()
                        Item(d)
                    } catch (e: ContactExistException) {
                        MessageDialog.showError("Контакт ${e.contact} уже существует")
                        null
                    }
                }.await() ?: return@onClick

                item.appendTo(table)
                Effects.createItem(item.dom)
                Effects.blockRow(item.dom)
            }
        }
    }

    private inner class Item(val contact: ContactDTO) : MaterialTable.Row() {
        private val type = MaterialTable.ComponentColumn(
            Span().appendTo(layout, grow = 1, shrink = 1, basis = 0).addClass(SIMPLE_TEXT),
        )
            .appendTo(this)
        private val value = MaterialTable.ComponentColumn(
            Span().appendTo(layout, grow = 1, shrink = 1, basis = 0).addClass(SIMPLE_TEXT),
        )
            .appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        init {
            if (allowEdit) {
                actions.component.iconBtn(MaterialIcons.SETTINGS).onClick {
                    async2 {
                        val d = ContactEditDialog.show(contact).await() ?: return@async2
                        ProcessBlocker.block("Изменение контакта") {
                            try {
                                contractsService.set(
                                    type = contact.type.name.jdto,
                                    value = d.value.jdto,
                                    id = contact.id.jdto,
                                    memberId = memberId.jdto,
                                ).await()
                                value.component.text = WebUtils.getPhone(d.value)
                                Effects.blockRow(dom)
                            } catch (e: ContactExistException) {
                                MessageDialog.showError("Контакт ${e.contact} уже существует")
                            }
                        }
                    }
                }
                actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                    async2 {
                        if (YesNoDialog.show("Удалить контакт ${contact.value}?", width = 310).await()) {
                            ProcessBlocker.block("Удаление контакта") {
                                contractsService.delete(
                                    type = contact.type.name.jdto,
                                    memberId = memberId.jdto,
                                    id = contact.id.jdto,
                                ).await()
                            }.await()
                            Effects.removeItem(this.dom).await()
                            removeSelf()
                        }
                    }
                }
            }
            type.component.text = when (contact.type) {
                ContactDTO.Type.EMAIL -> "Электронный адрес"
                ContactDTO.Type.PHONE -> "Телефон"
                ContactDTO.Type.ADDRESS -> "Адрес"
                ContactDTO.Type.TELEGRAM -> "Telegram"
            }

            value.component.text = contact.value
            actions.component.visibleOnHover(this)
        }
    }
}
