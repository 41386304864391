package org.tlsys.ui

import org.tlsys.core.Closeable
import org.w3c.dom.Element
import org.w3c.dom.events.Event

fun <T : Element> T.on(event: String, useCapture: Boolean = false, f: (Event) -> Unit): Closeable {
    addEventListener(event, f, useCapture)
    return Closeable {
        removeEventListener(event, f)
    }
}

fun <T : Element> T.once(event: String, f: (Event) -> Unit): Closeable {
    var ff: Closeable? = null

    ff = on(event) {
        f(it)
        ff!!.close()
    }
    return ff
}
