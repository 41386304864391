package org.tlsys.admin

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AddBonusPromotionConfig(
    @SerialName("include_tags")
    val includeTags: List<Long>,
    @SerialName("exclude_tags")
    val excludeTags: List<Long>,
    val bonus: BonusAmountConfig,
    val shops: List<Long>,
    @SerialName("apply_on_bonus_pay_gone")
    val applyOnBonusPayGone: Boolean = false,
)
