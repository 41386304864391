package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.dom.addClass
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.*
import pw.binom.web.layout.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pw.binom.web.AbstractComponent
import pw.binom.web.Component

/**
 * Контейнер для компонента с подписью. Данный компонент позволяет
 * сделать подпись [label], а ниже разместить компонент [content]
 *
 * @param label подпись
 * @param content контект, который нужно разместить под подписью
 */
open class LabelControlContener<out T : Component<out HTMLElement>>(label: String, val content: T) :
    AbstractComponent<HTMLDivElement>() {
    final override val dom: HTMLDivElement = document.createDiv()
    private companion object {
        val STYLE = CSS.style {
            ">span" then {
                fontSize = 11.2.px
                fontFamily = Styles.DEFAULT_TEXT_FONT
                color = "#9e9e9e"
                fontWeight = "500"
                userSelect = "none"
//                position = "absolute"
//                top = 0.px
            }
            paddingTop = 10.px
//            position = "relative"
        }.name
    }

//    override suspend fun onStart() {
//        val e = element
//        if (e is Component<*>)
//            e.onStart()
//    }

//    override suspend fun onStop() {
//        val e = element
//        if (e is Component<*>)
//            e.onStop()
//    }

    private val layout = FlexLayout(dom, direction = FlexLayout.Direction.Column).asParent()

    /**
     * Подпись
     */
    private val labelElement = Span(label).also {
        //        it.dom.addClass("label")
    }.appendTo(layout, grow = 0, shrink = 0)

    var label: String
        get() = labelElement.text
        set(value) {
            labelElement.text = value
        }

    private val con2 = DivLayout(direction = FlexLayout.Direction.Column).appendTo(layout)

    init {
        afterConstruct()
        content.appendTo(con2.layout)
        dom.addClass(STYLE)
    }

    val element: T
        get() = content

//    init {
//        dom.classList.add("namedControlContener")
//    }
}

typealias LabelContainer<T> = LabelControlContener<T>

fun <T : HTMLElement, V : Component<T>> V.withLabel(label: String) = LabelContainer(label, this)
class HSplit : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createElement("div").unsafeCast<HTMLDivElement>()
    init {
        dom.style.height = 15.px
    }
}