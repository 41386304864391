package org.tlsys.lex

class LexStream(private val text: String, private val keywords: Array<String>) {
    private var _cursor: Int = 0
    val cursor: Int
        get() = _cursor

    fun next(): String? {
        if (_cursor >= text.length)
            return null

        fun isSpace(c: Char) = c == ' ' || c == '\n' || c == '\t'

        fun isKeyword(c: Char): Boolean {
            for (s in keywords) {
                if (s[0] == c) {
                    return true
                }
            }
            return false
        }

        fun readSpaces(): String {
            val start = _cursor
            while (true) {
                if (_cursor >= text.length) {
                    break
                }
                if (isSpace(text[_cursor])) {
                    _cursor++
                    continue
                }
                break
            }
            return text.substring(start, _cursor)
        }

        fun readKeyWord(): String? {
            var words = keywords.toMutableList()
            words.removeIf {
                val t = text.substring(_cursor, _cursor + it.length)
                it != t
            }
            if (words.isEmpty())
                return null
            if (words.size == 1) {
                _cursor += words[0].length
                return words[0]
            }
            TODO()
        }

        fun readWord(): String {

            fun findEnd(): String {
                var out = ""
                while (_cursor < text.length) {
                    if (text[_cursor] == '\"') {
                        _cursor++
                        return out
                    }
                    if (text[_cursor] == '\\') {
                        _cursor += 2
                        continue
                    }
                    out += text[_cursor]
                    _cursor++
                }
                return out
            }

            var out = ""
            while (true) {
                if (_cursor >= text.length) {
                    break
                }
                if (text[_cursor] == '\"') {
                    _cursor++
                    return findEnd()
                }
                if (text[_cursor] == '\\') {
                    _cursor++
                    _cursor++
                    out += text[_cursor]
                    continue
                }
                if (isKeyword(text[_cursor])) {
                    break
                }
                if (!isSpace(text[_cursor])) {
                    out += text[_cursor]
                    _cursor++
                    continue
                }
                break
            }
            return out
            //return text.substring(start, cursor)
        }

        if (isSpace(text[_cursor])) {
            return readSpaces()
        }
        if (isKeyword(text[_cursor])) {
            val g = readKeyWord()
            if (g != null)
                return g
        }
        return readWord()
        //TODO("Unknown cursor \"${text[cursor]}\"")
    }
}

private fun <E> MutableIterable<E>.removeIf(t: (E) -> Boolean) {
    val it = iterator()
    while (it.hasNext()) {
        val h = it.next()
        if (t(h)) {
            it.remove()
        }
    }
}