package org.tlsys.members

import kotlinx.browser.document
import kotlinx.browser.window
import libs.asDateString
import libs.asDateUtc
import libs.asTimeString
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.AccountsService
import org.tlsys.api.TransactionsService
import org.tlsys.api.getByMemberIterator
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.core.clientImport.unfix
import org.tlsys.documents.RemoteDocumentServiceImpl
import org.tlsys.documents.dialogs.DocumentView
import org.tlsys.dto.HoldRecordDto
import org.tlsys.dto.TransactionDTO
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class TransactionsFragment(val member: Long) : AbstractFragment<HTMLDivElement>(document.createDiv()) {

    private val layout = FlexLayout(this, direction = FlexLayout.Direction.Column)
    private val transactionsService by Services.byClass(TransactionsService::class)
    private val accountsService by Services.byClass(AccountsService::class)
    private val it = transactionsService.getByMemberIterator(member = member, partSize = 50, minFull = 30)

    private val panel = ToolsPlane(usingSearch = false).appendTo(layout, grow = 0, shrink = 0)

    init {

        panel.button("Экспорт") {
            async2 {
                window.location.href = transactionsService.export(member.jdto).await().value
            }
        }
    }

    private val scroll = ScrollController(dom)
    private val table = MaterialTable().appendTo(layout, grow = 0, shrink = 1)

    init {
        val head = MaterialTable.Header().appendTo(table)
        MaterialTable.TextColumn("Дата").appendTo(head).widthPx(120)
        MaterialTable.TextColumn("Изменение").appendTo(head).widthPx(1)
        MaterialTable.TextColumn("Терминал").appendTo(head)
        MaterialTable.TextColumn("Документ").appendTo(head).widthPx(1)
        MaterialTable.TextColumn("").appendTo(head).widthPx(1) // Описание транзакции
    }

    override suspend fun onStart() {
        super.onStart()
        layout.onStart()

        accountsService.getHolds(member.jdto).await().forEach {
            ItemHold(it).appendTo(table)
        }

        while (!scroll.visibleScrollY && it.hasNext()) {
            Item(it.next()).appendTo(table)
        }

        var l = false
        scroll.addScrollListener {
            if (scroll.endScrollY && !l) {
                async2 {
                    l = true
                    for (i in 0..10) {
                        if (!it.hasNext()) {
                            break
                        }
                        Item(it.next()).appendTo(table)
                    }
                    l = false
                }
            }
        }
    }

//    private val columns by Services.listByClass(TranscationColumn::class)
//    private val list = LoadableListView(
//            iterator = it,
//            partCount = 50,
//            header = TableHeader().apply {
//                add("Дата", basis = 130, grow = 0, shrink = 0)
//                add("Изменение", basis = 0)
//                columns.forEach {
//                    add(it.columnName, basis = 0)
//                }
//            }
//    ).appendTo(layout, grow = 1, shrink = 1)

    private class ItemHold(val tx: HoldRecordDto) : MaterialTable.Row() {
        val date =
            MaterialTable.TextColumn("${tx.createDate.value.asDateUtc.asDateString} ${tx.createDate.value.asDateUtc.asTimeString}")
                .appendTo(this)
        val delta = MaterialTable.LayoutColumn().appendTo(this)

        init {
            delta.layout.alignItems = FlexLayout.AlignItems.Center
            MaterialIcon("lock", size = 18).appendTo(delta.layout, grow = 0, shrink = 0)
            val deltaText =
                Span(tx.amount.value.unfix(2).toString()).addClass(Styles.SIMPLE_TEXT).appendTo(delta.layout)

            val term = MaterialTable.TextColumn("нет").appendTo(this)
            val doc = MaterialTable.TextColumn("нет").appendTo(this)

            deltaText.dom.style.color = if (tx.amount.value >= 0) {
                "#258627"
            } else {
                "#d1021b"
            }

            val descriptionColumn = MaterialTable.LayoutColumn().appendTo(this)
            val actionPlace = ActionPlace().appendTo(descriptionColumn.layout)
            if (tx.description?.value?.isNotBlank() == true || tx.userId != null) {
                actionPlace.iconBtn(MaterialIcons.INFO).onClick {
//                    TransactionDescriptionDialog.show(tx)
                }
            }
            actionPlace.visibleOnHover(this)
        }
    }

    private class Item(val tx: TransactionDTO) : MaterialTable.Row() {
        val date = MaterialTable.TextColumn("${tx.date.asDateUtc.asDateString} ${tx.date.asDateUtc.asTimeString}")
            .appendTo(this)
        val delta = MaterialTable.TextColumn(tx.delta.unfix(2).toString()).appendTo(this)
        private val document by lazy {
            async2 {
                val id = tx.document ?: return@async2 null
                RemoteDocumentServiceImpl.getDocument(id.jdto).await()
            }
        }

        init {
            val term = if (tx.terminalId == null) {
                MaterialTable.TextColumn("нет")
            } else {
                MaterialTable.LinkColumn(tx.terminalName!!, "#/shops/s${tx.shopId}/t${tx.terminalId}")
            }
            term.appendTo(this)
            val doc = MaterialTable.TextColumn(tx.documentNumber ?: "нет").appendTo(this)

            if (tx.document != null) {
                doc.dom.onclick = {
                    async2 {
                        DocumentView.show(document.await()!!).await()
                    }
                }
                doc.dom.style.color = Styles.LINK_COLOR
                doc.dom.style.cursor = "pointer"
            }

            delta.dom.style.color = if (tx.delta >= 0) {
                "#258627"
            } else {
                "#d1021b"
            }

            val descriptionColumn = MaterialTable.LayoutColumn().appendTo(this)
            val actionPlace = ActionPlace().appendTo(descriptionColumn.layout)
            if (tx.description?.isNotBlank() == true) {
                actionPlace.iconBtn(MaterialIcons.INFO).onClick {
                    TransactionDescriptionDialog.show(tx)
                }
            }
            actionPlace.visibleOnHover(this)
        }
    }

//    private inner class TransactionItem(val transaction: TransactionDTO) : DivController() {
//        private val layout = FlexLayout(this)
//
//
//        init {
//            addClass(LIST_ITEM_STYLE)
//            val date = Date.fromUTC(transaction.date)
//            Span("${date.asDateString} ${date.asTimeString}").addClass(SIMPLE_TEXT).appendTo(layout, grow = 0, shrink = 0, basis = 130)
//            val balans = Span("${transaction.delta / 100f}").addClass(SIMPLE_TEXT).appendTo(layout, grow = 1, shrink = 1, basis = 0)
//            balans.dom.style.color = if (transaction.delta >= 0)
//                "rgb(37, 134, 39)"
//            else
//                "rgb(208, 2, 27)"
//
//            columns.forEach {
//                val text = Span().addClass(SIMPLE_TEXT).appendTo(layout, grow = 1, shrink = 1, basis = 0)
//                async {
//                    if (it.isClicable(transaction).await()) {
//                        text.addClass(LINK)
//
//                        text.dom.onClick { _ ->
//                            it.click(transaction)
//                        }
//                    }
//                }
//
//                async {
//                    text.text = it.valueOf(transaction).await()
//                }
//            }
//
//            dom.style.padding = 10.px
//        }
//    }

    override suspend fun onStop() {
        layout.onStop()
        super.onStop()
    }
}
