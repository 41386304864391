package org.tlsys

import kotlinx.serialization.Serializable
import org.tlsys.serialization.PhoneSerializer
import kotlin.jvm.JvmInline

@Serializable(PhoneSerializer::class)
@JvmInline
value class Phone internal constructor(val raw: String) {
    val memberPhone: String
        get() = raw.substring(raw.length - 7)
    val maskedMemberPhone: String
        get() = "${raw[raw.length - 7]}${raw[raw.length - 6]}***${raw[raw.length - 2]}${raw[raw.length - 1]}"
    val formatedMemberPhone: String
        get() =
            "${raw[raw.length - 7]}${raw[raw.length - 6]}${raw[raw.length - 5]}" +
                "-${raw[raw.length - 4]}${raw[raw.length - 3]}" +
                "-${raw[raw.length - 2]}${raw[raw.length - 1]}"
    val countryNumber: String
        get() = when (raw.length - 7) {
            1 -> TODO() // +,
            2, // +[7]
            3, // +[7]1
            4, // +[7]12
            5 -> "${raw[1]}" // +[7]123
            6 -> "${raw[1]}${raw[2]}${raw[3]}" // +[712]34
            7, // +[712]345
            8 -> "${raw[1]}${raw[2]}${raw[3]}" // +[712]3456
            9 -> "${raw[1]}${raw[2]}${raw[3]}${raw[4]}" // +[7123]4567
            else -> TODO()
        }

    val operatorNumber: String
        get() = when (raw.length - 7) {
            1 -> TODO() // +
            2 -> "" // +7[]
            3 -> "${raw[2]}" // +7[1]
            4 -> "${raw[2]}${raw[3]}" // +7[12]
            5 -> "${raw[2]}${raw[3]}${raw[4]}" // +7[123]
            6 -> "${raw[4]}${raw[5]}" // +712[34]
            7 -> "${raw[4]}${raw[5]}${raw[6]}" // +712[345]
            8 -> "${raw[4]}${raw[5]}${raw[6]}${raw[7]}" // +712[3456]
            9 -> "${raw[5]}${raw[6]}${raw[7]}${raw[8]}" // +7123[4567]
            else -> TODO()
        }

    /**
     * returns [raw] without `+`
     */
    val withoutPrefix
        get() = raw.removePrefix("+")
    val format: String
        get() = "+$countryNumber-$operatorNumber-$formatedMemberPhone"
//        get() {
//            "+7$countryNumber-$operatorNumber-$memberPhone"
//            val l1 = raw.substring(raw.length - 2)
//            val l2 = raw.substring(raw.length - 4, raw.length - 2)
//            val l3 = raw.substring(raw.length - 7, raw.length - 4)
//            val l4 = raw.substring(raw.length - 10, raw.length - 7)
//            val l5 = raw.substring(0, raw.length - 10)
//            return "$l5-$l4-$l3-$l2-$l1"
//        }

    val masked: String
        get() = "+$countryNumber-$operatorNumber-$maskedMemberPhone"
//        get() {
//            val l1 = raw.substring(raw.length - 2)
//            val l2 = "**"
//            val l3 = "**"
//            val l4 = raw.substring(raw.length - 10, raw.length - 9) + "**"
//            val l5 = raw.substring(0, raw.length - 10)
//            return "$l5-$l4-$l3-$l2-$l1"
//        }

    override fun toString(): String = format
}

val String.toPhoneOrNull
    get() = getPhone(this)

fun String.toPhone() = getPhone(this) ?: throw IllegalArgumentException("Can't parse phone \"$this\"")

private fun getPhone(value: String): Phone? {
    var value = value.trim()
        .replace(" ", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "")
        .replace("[", "")
        .replace("]", "")
    if (value.startsWith("8")) {
        value = "+7" + value.removePrefix("8")
    }
    if (!value.startsWith("+")) {
        value = "+$value"
    }
    while (true) {
        val index = value.indexOf('+', startIndex = 1)
        if (index == -1) {
            break
        }
        value = value.substring(0, index) + value.substring(index + 1)
    }
    for (i in 1 until value.length) {
        if (value[i] !in '0'..'9') {
            return null
        }
    }
    val MIN_PHONE_NUMBER =
        7 + // length of member part
            1 + // min country part
            1 + // min operator part
            1 // +
    val MAX_PHONE_NUMBER =
        7 + // length of member part
            4 + // max country part
            4 + // max operator part
            1 // +
    if (value.length !in MIN_PHONE_NUMBER..MAX_PHONE_NUMBER) {
        return null
    }
    return Phone(value)
//    val rexexp = "(\\+?7[\\s,\\-,\\(]*\\d|8[\\s,\\-,\\(]*9|\\(?9[\\s,\\-,\\(]*)([\\s,\\-,\\),\\(]*\\d){9}".toRegex()
//    if (!value.matches(rexexp)) {
//        return null
//    }
//    val txt = "[\\s\\-()]".toRegex().replace(value, "")
//    val text = when {
//        txt.startsWith("8") -> "+7${txt.removePrefix("8")}"
//        txt.startsWith("7") -> "+$txt"
//        else -> txt
//    }
//    return Phone(text)
}
