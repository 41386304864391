package org.tlsys.navigation

import org.tlsys.ui.Page

abstract class PageControl(currentPath: String) {
    private var _currentPath = currentPath
    val currentPath: String
        get() = _currentPath

    protected abstract suspend fun up()
    protected abstract suspend fun goto(pageName: String, params: Map<String, String?>): Page
    protected abstract suspend fun prepare()
    protected abstract suspend fun done()
    protected abstract suspend fun updateParams(params: Map<String, String?>)

    abstract val currentPage: Page

    suspend fun setPath(path: String) {
        console.info("PageControl::setPath($path)")
        if (getURI(_currentPath) == getURI(path) && _currentPath != path) {
            updateParams(getParams(path))
            _currentPath = path
            return
        }
        val result = navigateRelative(oldPath = _currentPath, newPath = path)
        _currentPath = path

        prepare()
        result.forEach {
            when (it) {
                is PathAction.GoTo -> goto(getURI(it.name), getParams(it.name))
                is PathAction.UpLevel -> up()
            }
        }
        done()
    }
}
