package org.tlsys.dto.auth

import org.tlsys.json.*

class GroupDTO(val id: Long, val name: String, val companyId: Long, val permissions: List<PermissionDTO>) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<GroupDTO> {
        override val type: String
            get() = "GroupDTO"

        override fun read(node: JsonNode) = GroupDTO(
                id = node["id"]!!.long(),
                name = node["name"]!!.string(),
                companyId = node["companyId"]!!.long(),
                permissions = JsonFactory.readArray<JString>(node["permissions"]!!).map { PermissionDTO.valueOf(it.value) }
        )

        override fun write(obj: GroupDTO) =
                JsonNode.obj()
                        .set("id", obj.id.json)
                        .set("name", obj.name.json)
                        .set("companyId", obj.companyId.json)
                        .set("permissions", JsonFactory.writeArray(obj.permissions.map { it.name.jdto }))

    }
}