package org.tlsys.core.promotion.holidaysPromotion

import libs.daysInMonth
import libs.monthName3
import libs.monthNames
import org.tlsys.*
import org.tlsys.admin.PermanentPromotion
import org.tlsys.admin.PermanentPromotionType
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.Validator
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.core.promotion.BasePermanentPromotion
import org.tlsys.core.promotion.BonusConfigEditor
import org.tlsys.core.promotion.ConditionsTab
import org.tlsys.core.promotion.PermanentPromotionEditor
import org.tlsys.promotion.dto.HolidaysBonusPromotionConfig
import org.tlsys.ui.*
import org.tlsys.ui.DivComponentWithLayout
import org.tlsys.ui.DivLayout
import pw.binom.web.layout.*

class HolidaysBonusPromotion private constructor(record: PermanentPromotion?) :
    BasePermanentPromotion(type = PermanentPromotionType.HOLIDAYS, record = record) {
    private val configs = record?.config?.getObject<HolidaysBonusPromotionConfig>()
    private val conditions = ConditionsTab()

    private val config = object : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        private val monthValidator: Validator<String> = Validator {
            val selectedDay = it.toInt()
            val maxDays = daysInMonth[month.select]
            if (selectedDay > maxDays) invalid("В ${monthName3(month.select)} $maxDays дней") else valid()
        }
        val bonus = BonusConfigEditor.AmountEditor(
            value = configs?.amount?.bonusAmount ?: MoneyValue.ZERO,
            start = configs?.amount?.start ?: DateDuration.ZERO,
            end = configs?.amount?.end,
        ).appendTo(layout, grow = 0, shrink = 0)
        val description = EditText(placeHolder = "Описание транзакции")
            .textValidator(TextValidators.NOT_BLANK)
            .text(configs?.description ?: "")
            .appendTo(layout, grow = 0, shrink = 0)
        private val dd = DivLayout()
            .withLabel("Дата")
            .appendTo(layout, grow = 0, shrink = 0)
        val month = Combobox2(monthNames.toList(), select = configs?.month?.let { it - 1 } ?: 0)
            .appendTo(dd.content.layout, grow = 1, shrink = 1)
        val day = EditText(placeHolder = "День")
            .textValidator(
                DoubleValidator.FORMAT and DoubleValidator.moreOrEquals2(0.0) and monthValidator,
            )
            .text(configs?.dayOfMonth?.toString() ?: "1")
            .appendTo(dd.content.layout, grow = 1, shrink = 1)
            .updateOnChange(month)
    }
    private val notifies = object : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        val sendNotify = Checkbox(label = "Рассылка уведомлений")
            .checked(configs?.sendNotify ?: false)
            .appendTo(layout, grow = 0, shrink = 0)
        val notifyText = TextareaInput()
            .text(configs?.notifyText ?: "")
            .also { it.dom.style.height = 140.px }
            .withLabel("Текст сообщения")
            .appendTo(layout, grow = 1, shrink = 1)
    }

    override fun build(): JsonObject =
        JsonObject.create(
            HolidaysBonusPromotionConfig(
                tagFilter = conditions.tagFilter,
                month = config.month.select + 1,
                dayOfMonth = config.day.text.toInt(),
                amount = config.bonus.value2!!,
                description = config.description.text,
                sendNotify = notifies.sendNotify.checked,
                notifyText = notifies.notifyText.content.text,
            ),
        )

    override suspend fun onInit() {
        if (configs != null) {
            conditions.setTags(
                configs.tagFilter,
            )
        }
    }

    init {
        validator.addValidated(config.description)
        validator.addValidated(config.bonus)
        validator.addValidated(config.day)
        dom.style.width = 500.px
        dom.style.height = 650.px
        tabs.add("Настройки") {
            viewport.set2(config)
        }
        tabs.add("Уведомление") {
            viewport.set2(notifies)
        }
        tabs.add("Условия") {
            viewport.set2(conditions)
        }
        tabs.active = 0
    }

    companion object : PermanentPromotionEditor {
        override val type: PermanentPromotionType
            get() = PermanentPromotionType.HOLIDAYS

        override suspend fun show(record: PermanentPromotion?, new: Boolean): PermanentPromotion? {
            val d = HolidaysBonusPromotion(record)
            d.show()
            return d.result
        }
    }
}
