package org.tlsys.admin

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.DateDuration

@Serializable
data class BirthdayPromotionConfig(
    @SerialName("include_tags")
    val includeTags: List<Long>,
    @SerialName("exclude_tags")
    val excludeTags: List<Long>,
    val bonus: BonusAmountConfig,
    @SerialName("send_notify")
    val sendNotify: Boolean,
    @SerialName("notify_text")
    val notifyText: String,
    @SerialName("tag_prefix")
    val tagPrefix: String,
    @SerialName("search_offset")
    val searchOffset: DateDuration,
)
