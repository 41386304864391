package org.tlsys.core.search

import org.tlsys.json.*

sealed class SearchElement : JDTO {

    class MemberSearchElement(
        val memberId: Long,
        val firstName: String?,
        val middleName: String?,
        val lastName: String?,
        val cardPrInstallDate: Long?,
        val osmicardInstallDate: Long?,
        val sex: Boolean?,
        val birthday: Long?,
        val created: Long,
        val tags: List<Long>,
        val phones: List<String>,
        val emails: List<String>,
        val cards: List<String>,
        val allowBonusDrop: Boolean,
        val bonusAllowDrop: Long,
        val bonusNotAllowDrop: Long,
    ) : SearchElement() {
        companion object : JDTOFactory<MemberSearchElement> {
            override val type: String
                get() = "MemberSearchElement"

            override fun read(node: JsonNode) =
                MemberSearchElement(
                    memberId = node["id"]!!.long(),
                    cards = node["cards"]!!.map { it!!.string() },
                    phones = node["phones"]!!.map { it!!.string() },
                    emails = node["emails"]!!.map { it!!.string() },
                    tags = node["tags"]!!.map { it!!.long() },
                    sex = node["sex"]?.boolean(),
                    birthday = node["birthday"]?.long(),
                    middleName = node["middleName"]?.string(),
                    lastName = node["lastName"]?.string(),
                    firstName = node["firstName"]!!.string(),
                    created = node["created"]!!.long(),
                    cardPrInstallDate = node["cardPrInstallDate"]?.long(),
                    allowBonusDrop = node["allowBonusDrop"]!!.boolean(),
                    bonusAllowDrop = node["bonusAllowDrop"]!!.long(),
                    bonusNotAllowDrop = node["bonusNotAllowDrop"]!!.long(),
                    osmicardInstallDate = node["osmicardInstallDate"]?.long(),
                )

            override fun write(obj: MemberSearchElement): JsonNode =
                JsonNode.obj(
                    "id" to obj.memberId.json,
                    "cards" to obj.cards.map { it.json }.json(),
                    "phones" to obj.phones.map { it.json }.json(),
                    "emails" to obj.emails.map { it.json }.json(),
                    "tags" to obj.tags.map { it.json }.json(),
                    "sex" to obj.sex?.json,
                    "birthday" to obj.birthday?.json,
                    "middleName" to obj.middleName?.json,
                    "lastName" to obj.lastName?.json,
                    "firstName" to obj.firstName?.json,
                    "created" to obj.created.json,
                    "cardPrInstallDate" to obj.cardPrInstallDate?.json,
                    "osmicardInstallDate" to obj.osmicardInstallDate?.json,
                    "allowBonusDrop" to obj.allowBonusDrop.json,
                    "bonusAllowDrop" to obj.bonusAllowDrop.json,
                    "bonusNotAllowDrop" to obj.bonusNotAllowDrop.json,
                )
        }

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }
}
