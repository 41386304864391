package org.tlsys.dto

import org.tlsys.dto.auth.*
import org.tlsys.dto.plugins.*
import org.tlsys.json.*

class DTOOperationNotAllow(message: JString?) : RuntimeException(message?.value ?: ""), JDTO {
    constructor():this(null)
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : AutoJDTOFactory<DTOOperationNotAllow>(DTOOperationNotAllow::class)
}

object admin_internal_dto:DtoSet(){
    override fun regDto() {
        DTOOperationNotAllow.reg()
//        LoginResponce.reg()
//        SessionResumeRequest.reg()
        DTOPlugin.reg()
        DTOPluginNeedDependency.reg()
        DTOPluginStartException.reg()
        PluginDescriptionDTO.reg()
    }

    override val subSet: List<DtoSet> = listOf(reg_admin_extensionLib)

}