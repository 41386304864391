package org.tlsys.json

import kotlin.reflect.KClass

interface JDTO {
    val factory: JDTOFactory<JDTO>

    fun write() = JsonFactory.write(this)
}

object JUnit : JDTO, JDTOFactory<JUnit> {
    override val type: String
        get() = "unit"

    override fun read(node: JsonNode) = JUnit

    override fun write(obj: JUnit) = JsonNode.obj()

    override val factory: JDTOFactory<JDTO>
        get() = this.asDefault
}

interface JDTOFactory<T : JDTO> {
    val type: String

    fun read(node: JsonNode): T
    fun write(obj: T): JsonNode
}

val JDTOFactory<*>.asDefault: JDTOFactory<JDTO>
    get() = this as JDTOFactory<JDTO>

fun JDTOFactory<*>.reg() {
    JsonFactory.reg(this)
}

fun <T : JDTO> JsonNode.read() = JsonFactory.read<T>(this)

fun <V : JDTO, T : JDTOFactory<V>> T.read(node: String) = read(JsonNode.parse(node))

object JsonFactory {
    private val factories = HashMap<String, JDTOFactory<JDTO>>()

    fun clearFactories() {
        factories.clear()
    }

    fun reg(factory: JDTOFactory<*>) {
        if (factories.containsKey(factory.type)) {
            TODO("Factory \"${factory.type}\" already exist")
        }

        factories[factory.type] = factory as JDTOFactory<JDTO>
    }

//    fun <T : JDTO?> read(node: String): T = read(JsonNode.parse(node))

    fun <T : JDTO?> read(node: JsonNode): T {
        val type = node["@type"]?.string() ?: TODO("Type not set. node=$node")
        val factory = factories[type] ?: TODO("Can't find factory for $type")
        return factory.read(node) as T
    }

    fun <T : JDTO> write(obj: T?): JsonNode {
        if (obj == null) {
            return JsonNode.nullValue()
        }
        val n = obj.factory.write(obj)
        n["@type"] = obj.factory.type
        return n
    }

    fun <T : JDTO?> readArray(node: JsonNode): List<T> {
        return node.map {
            if (it == null) {
                null as T
            } else {
                read<T>(it)
            }
        }
    }

    fun <T : JDTO> writeArray(array: List<T?>): JsonNode {
        val out = JsonNode.array()
        array.forEach {
            if (it == null) {
                out.put(null)
            } else {
                out.put(write(it))
            }
        }

        return out
    }

    init {
        reg(JInt)
        reg(JFloat)
        reg(JString)
        reg(JMap)
        reg(JList)
        reg(JLong)
        reg(JBoolean)
        reg(JByte)
        reg(JSet)
        reg(JUnit)
        reg(JPair)
        reg(JRequest)
        reg(JResponce)
        reg(JVMException)
    }
}

fun <T : JDTO> JsonNode.dto(): T = JsonFactory.read(this)
fun <T : JDTO> T.json() = JsonFactory.write(this)

internal expect fun objectKeys(obj: JDTO): Array<String>
internal expect fun getFieldValue(obj: JDTO, field: String): Any?
internal expect fun setFieldValue(obj: JDTO, field: String, value: Any?)
internal expect fun <T : JDTO> newInstance(clazz: KClass<T>): T

internal expect fun checkConstructor(any: KClass<*>)

abstract class AutoJDTOFactory<T : JDTO>(val clazz: KClass<T>, override val type: String = clazz.simpleName!!) :
    JDTOFactory<T> {
    init {
        checkConstructor(clazz)
    }

    override fun read(node: JsonNode): T {
        val out = newInstance(clazz)
        node.fields().forEach { it ->
            if (it == "Companion" || it == "@type") {
                return@forEach
            }
            setFieldValue(out, it, readValue(node[it]))
        }

        return out
    }

    override fun write(obj: T): JsonNode {
        val out = JsonNode.obj()
        objectKeys(obj).forEach {
            if (it == "Companion" || it == "@type") {
                return@forEach
            }
            try {
                out[it] = writeValue(getFieldValue(obj, it))
            } catch (e: Throwable) {
                throw RuntimeException("Can't write field $it of class ${clazz.simpleName}")
            }
        }
        return out
    }

    private fun readValue(node: JsonNode?): Any? {
        node ?: return null
        return JsonFactory.read<JDTO>(node)
    }

    private fun writeValue(obj: Any?): JsonNode? {
        obj ?: return null
        return when (obj) {
            is JDTO -> JsonFactory.write(obj)
            else -> TODO("Unknown type of ${obj::class.simpleName}")
        }
    }
}
