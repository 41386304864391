package org.tlsys.admin.ui

open class InputTextString(value: String = "", password: Boolean = false, placeHolder: String = "") : InputText<String>(text = value, password = password, placeHolder = placeHolder) {
    override fun convertText(text: String): String? = text

    override fun convertValue(value: String): String = value

    init {
        onTextChanged()
    }
}