package org.tlsys.admin.ui

import org.tlsys.addClass
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLElement
import pw.binom.web.Component
import pw.binom.web.layout.*

class ActionPanel : DivComponentWithLayout(alignItems = FlexLayout.AlignItems.Center) {
    init {
        dom.style.apply {
            boxShadow = "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)"
            backgroundColor = "#f5f5f5"
            height = 48.px
            padding = "0px 20px"
            marginBottom = 7.px
        }
    }

    fun add(component: Component<HTMLElement>, align: FlexLayout.FlexItem.AlignSelf) {
        component.appendTo(layout, grow = 0, shrink = 0, align = align)
    }

    fun addSpace() {
        DivLayout().appendTo(layout, grow = 1, shrink = 1)
    }

    fun button(text: String): Button {
        val b = BaseButton()
        b.text = text
        b.appendTo(layout, grow = 0, shrink = 0)
        return b
    }

    fun iconBtn(icon: String): Button {
        val b = BaseButton()
        b.dom.addClass("material-icons")
        b.text = icon
        b.appendTo(layout, grow = 0, shrink = 0)
        return b
    }
}

fun <T : Component<E>, E : HTMLElement> T.appendTo(panel: ActionPanel, align: FlexLayout.FlexItem.AlignSelf = FlexLayout.FlexItem.AlignSelf.Auto): T {
    panel.add(this, align)
    return this
}
