package org.tlsys

import org.tlsys.admin.core.Services
import kotlin.properties.ReadOnlyProperty
import kotlin.reflect.KProperty

fun permission(uid: String) = PermissionEx(uid)
fun permission(uid: OPermission) = PermissionEx2(uid)

class PermissionEx(private val uid: String) : ReadOnlyProperty<Any?, Boolean> {
    private val session by Services.byClass(OSession::class)
    override fun getValue(thisRef: Any?, property: KProperty<*>): Boolean =
            session.isAllow(uid)
}

class PermissionEx2(private val uid: OPermission) : ReadOnlyProperty<Any?, Boolean> {
    private val session by Services.byClass(OSession::class)
    override fun getValue(thisRef: Any?, property: KProperty<*>): Boolean =
            session.isAllow(uid)
}