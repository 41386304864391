package org.tlsys

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class InternalSmtpConfig(
    val login: String,
    val password: String,
    @Contextual
    @SerialName("source_email")
    val sourceEmail: Email,
    @SerialName("source_email_alias")
    val sourceEmailAlias: String?,
    @SerialName("server_host")
    val serverHost: String,
    @SerialName("server_port")
    val serverPort: Int,
    @SerialName("use_tls")
    val useTls: Boolean = true,
)
