package org.tlsys.core.promotion

import org.tlsys.JsonObject
import org.tlsys.admin.*
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.admin.ui.Tabs
import org.tlsys.ui.DivLayout
import org.w3c.dom.HTMLDivElement
import pw.binom.date.DateTime
import pw.binom.date.format.toDatePattern
import pw.binom.uuid.nextUuid
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.random.Random

private val ddd = "HH:mm".toDatePattern()

abstract class BasePermanentPromotion(var record: PermanentPromotion?, val type: PermanentPromotionType) :
    TitleDialog(type.title) {
    protected val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    protected var result: PermanentPromotion? = null

    private val dd = DivLayout().appendTo(layout, grow = 0, shrink = 0)
    protected val name = EditText(placeHolder = "Название")
        .text(record?.title ?: type.title)
        .appendTo(dd.layout, grow = 1, shrink = 1)
        .andTextValidator(TextValidators.NOT_BLANK)
    protected val status = Combobox2(
        items = PromotionStatus.values().map { it.title },
        select = record?.status?.let { PromotionStatus.values().indexOf(it) } ?: 0,
    ).appendTo(dd.layout, grow = 1, shrink = 1)
    protected val timeToStart = Combobox2(
        items = TimeIntervalEnum.values().map {
            val d = DateTime(it.delay)
            ddd.toString(d)
        },
        select = record?.timeToStart?.let { TimeIntervalEnum.values().indexOf(it) } ?: 0,
    )
        .withLabel("Время запуска")
        .appendTo(layout, grow = 0, shrink = 0)
    private val dates = DivLayout().appendTo(layout, grow = 0, shrink = 0)
    protected val dateStart =
        DatetimeEditor(record?.startDate ?: DateTime.now)
            .withLabel("Дата начала")
            .appendTo(dates.layout, grow = 1, shrink = 1)
    protected val dateEnd =
        DatetimeEditor(record?.endDate ?: DateTime(DateTime.nowTime + 1000 * 60 * 60 * 24 * 7))
            .withLabel("Дата окончания")
            .appendTo(dates.layout, grow = 1, shrink = 1)
    protected val tabs = Tabs(bottomPudding = true).appendTo(layout, grow = 0, shrink = 0)
    protected val viewport = ComponentView().appendTo(layout = layout)
    protected val validator = MultiValidator(name)
    private val viewScroll = ScrollController(layout.dom)

    protected abstract fun build(): JsonObject

    override fun onEscapeClick() {
        super.onEscapeClick()
        result = null
        close()
    }

    init {
        validator.onValidChange {
            body.okBtn.enabled = it
        }
        body.cancel {
            result = null
            close()
        }

        body.ok {
            val config = build()
            result = PermanentPromotion(
                id = record?.id ?: Random.nextUuid(),
                title = name.text,
                owner = record?.owner ?: 0L,
                status = PromotionStatus.values()[status.select],
                createDate = record?.createDate?.let { DateTime(it.time) } ?: DateTime.now,
                startDate = dateStart.content.date!!,
                endDate = dateEnd.content.date,
                timeToStart = TimeIntervalEnum.values()[timeToStart.content.select],
                type = type,
                config = config,
            )
            close()
        }
    }
}
