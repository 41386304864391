package org.tlsys.admin.ui

import libs.*
import org.tlsys.core.Closeable
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.w3c.dom.*
import kotlinx.browser.document
import pw.binom.web.AbstractComponent

fun buildDate(f: Date.() -> Unit): Date {
    val g = Date.now()
    g.f()
    return g
}

class DayOfMonthPicker(val year: Int, val month: Int, day: Int?) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    private companion object Styles1 {
        val SELECTED = CSS.genName()
        val DAY = CSS.genName()
        val SUNDAY = CSS.genName()
        val DAY_PICKER = CSS.genName()
        val WEEKDAY = CSS.genName()

        init {
            CSS {
                ".$DAY_PICKER" {
                    borderCollapse = "collapse"
                    width = "100%"
                    ".$WEEKDAY" {
                        fontFamily = Styles.DEFAULT_TEXT_FONT
                        fontSize = Styles.DEFAULT_FONT_SIZE.px
                        textAlign = "center"
                    }
                    ".$DAY" {
                        transitionProperty = "background-color, color"
                        transitionDuration = "200ms"
                        padding = "10px"
                        fontFamily = Styles.DEFAULT_TEXT_FONT
                        fontSize = Styles.DEFAULT_FONT_SIZE.px
                        textAlign = "center"
                    }
                    ".$SELECTED" {
                        backgroundColor = "#3aafda"
                        borderRadius = 4.px
                        color = "#fff"
                        cursor = "default"
                    }

                    ".$DAY:not(.$SELECTED)" {
                        cursor = "pointer"
                    }

                    ".$SUNDAY"{
                    }
                }
            }
        }
    }

    private var _day = day

    private val changeListeners = ArrayList<() -> Unit>()

    fun onChange(f: () -> Unit): Closeable {
        changeListeners += f
        return Closeable {
            changeListeners.remove(f)
        }
    }

    var day: Int?
        get() = _day
        set(it) {
            if (it != null && (it <= 0 || it > list.size)) TODO()
            if (day != null) {
                list[day!! - 1].classList.remove(Styles1.SELECTED)
            }
            _day = it
            if (day != null) {
                list[day!! - 1].classList.add(Styles1.SELECTED)
            }

            changeListeners.toTypedArray().forEach {
                it.invoke()
            }
        }

    private val table = (document.createElement("table") as HTMLTableElement).apply {
        classList.add(Styles1.DAY_PICKER)
        dom.appendChild(this)
    }
    private val head = (document.createElement("thead") as HTMLTableSectionElement).apply {
        table.appendChild(this)
    }
    private val body = (document.createElement("tbody") as HTMLTableSectionElement).apply {
        table.appendChild(this)
    }
    private val list = ArrayList<HTMLTableCellElement>()

    init {
        var monthRow = head.insertRow() as HTMLTableRowElement
        var i = 0
        fun insertDay(vararg names: String) {

            for (n in names) {
                val weekDay = monthRow.insertCell(i++)
                weekDay.classList.add(Styles1.WEEKDAY)
                if (i >= 5)
                    weekDay.classList.add(Styles1.SUNDAY)
                weekDay.innerText = n
                monthRow.style.textAlign = "center"
            }
        }
        insertDay("ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС")
    }

    init {

        fun day(source: Int): Int {
            var d = source - 1
            if (d == -1)
                d = 6
            return d
        }

        var row = body.insertRow() as HTMLTableRowElement
        var firstRow = true
        var colNum = 0

        val date = Date().apply {
            this.year = this@DayOfMonthPicker.year
            this.month = this@DayOfMonthPicker.month
        }

        for (i in 1..date.lastDate) {
            date.date = i

            if (!firstRow && day(date.day) == 0) {
                row = body.insertRow() as HTMLTableRowElement
                colNum = 0
            }

            if (firstRow && day(date.day) != 0 && i == 1) {
                for (t in 0..day(date.day) - 1) {
                    val gg = row.insertCell(colNum++) as HTMLTableCellElement
                    gg.innerHTML = "&nbsp;"
                    gg.style.cursor = "default"
                }
            }

            val col = row.insertCell(colNum++) as HTMLTableCellElement
            col.classList.add(Styles1.DAY)
            if (day(date.day) >= 5) {
                col.classList.add(Styles1.SUNDAY)
            }
            col.innerText = i.toString()
            //val dc = DayControl(this, col, d)
            list += col
            col.addEventListener("click", {
                this.day = i
            })

            firstRow = false
        }

        this.day = day
    }
}
