package org.tlsys.ui

import kotlinx.browser.document
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.FlexLayout

open class DivComponent(final override val dom: HTMLDivElement = document.createDiv()) :
    AbstractComponent<HTMLDivElement>() {
    init {
        afterConstruct()
    }
}

abstract class DivComponentWithLayout(
    dom: HTMLDivElement = document.createDiv(),
    alignItems: FlexLayout.AlignItems = FlexLayout.AlignItems.Stretch,
    direction: FlexLayout.Direction = FlexLayout.Direction.Row
) : DivComponent(dom) {
    protected open val layout = FlexLayout(
        parent = dom,
        alignItems = alignItems,
        direction = direction
    )

    override suspend fun onStart() {
        super.onStart()
        layout.onStart()
    }

    override suspend fun onStop() {
        layout.onStop()
        super.onStop()
    }
}

open class DivLayout(
    dom: HTMLDivElement = document.createDiv(),
    alignItems: FlexLayout.AlignItems = FlexLayout.AlignItems.Stretch,
    direction: FlexLayout.Direction = FlexLayout.Direction.Row
) : DivComponentWithLayout(dom = dom, alignItems = alignItems, direction = direction) {
    public override val layout: FlexLayout<HTMLDivElement>
        get() = super.layout
}
