package org.tlsys.cards

import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.px
import org.tlsys.ui.*
import pw.binom.web.layout.*
import kotlin.js.Promise

/**
 * Диалог для создания и изменения карт
 *
 * @param startCode код карты
 * @param f функция, которая вызовится, если была нажата кнопка Сохранить/Изменить. Предполагается,
 * что создание/изменение происходит прямо при нажатии на кнопку, и только потом диалог закрывается,
 * при условии что промис из функции [f] завершился нормально, т.е. не ошибкой
 */
class CardCodeDialog(
    val startCode: String?,
    val enabled: Boolean,
    f: (String, Boolean) -> Promise<Boolean> = { code, enabled -> Promise.resolve(true) },
) : TitleDialog(title = if (startCode == null) "Новая карта" else "Редактирование карты") {

    init {
        dom.style.width = 250.px
    }

    val body = SaveCancelBody().apply {
        this@CardCodeDialog.layout.add(dom) {
            shrink = 1
            grow = 1
        }
    }
    private var _canceled: Boolean = true

    val canceled: Boolean
        get() = _canceled

    val enabledCheckbox = Checkbox(label = "Включена", checked = enabled)
        .withLabel("Статус")
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)

    init {
        HSplit().appendTo(body.componentsLayout, grow = 0, shrink = 0)
    }

    val codeLabel = EditText(placeHolder = "Код карты")
        .textValidator(TextValidators.NOT_BLANK and TextValidators.NO_SPACE)
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)

    init {
        body.okBtn.onClick {
            _canceled = false
            if (startCode != codeLabel.text || enabled != enabledCheckbox.element.checked) {
                f(codeLabel.text, enabledCheckbox.element.checked).then {
                    if (it) {
                        close()
                    }
                }
            } else {
                close()
            }
        }

        body.cancelBtn.onClick {
            cancelClick()
        }

        codeLabel.eventValidChange.on {
            body.okBtn.enabled = it
        }

        codeLabel.text = startCode ?: ""
        // codeLabel.content.refreshValidStatus()
    }

    override suspend fun onStart() {
        super.onStart()
        codeLabel.focus()
        codeLabel.select()
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    companion object {
        suspend fun show(code: String?, enabled: Boolean, func: suspend (String, Boolean) -> Boolean): String? {
            val dd = CardCodeDialog(
                enabled = enabled,
                startCode = code,
                f = { code, enabled -> async2 { func(code, enabled) } },
            )
            dd.show()
            return if (dd.canceled) {
                null
            } else {
                dd.codeLabel.text
            }
        }
    }
}
