package org.tlsys.wc

import org.tlsys.*
import org.tlsys.admin.SettingItem
import org.tlsys.admin.core.Remote
import org.tlsys.admin.core.Services
import org.tlsys.api.ExPermissionManagerService
import org.tlsys.css.CssDeclaration
import org.tlsys.json.JLong
import org.tlsys.json.JMap
import org.tlsys.json.jdto
import org.tlsys.node.ScriptsPage
import org.tlsys.node.api.*
import org.tlsys.node.nodes_dto_set
import org.tlsys.node.properties.*
import org.tlsys.ui.Page
// import org.tlsys.wc.admin.DiagramListPage
import kotlin.js.Promise

const val DEFAULT_TIMEOUT = 5_000
fun wsMain(remote: Remote) {
    nodes_dto_set.reg()

    val memberInfoMap = HashMap<JLong, Promise<Map<String, String?>>>()
    Services.reg(object : ReadyDoneListener {
        override suspend fun onReady() {
            Services.reg(
                remote.getService(
                    GoodsService::class,
                    invokator = WC_SERVICE_INVOCATOR,
                    setvice = NODE_GOODS_SERVICE,
                ),
            )
            Services.reg(
                remote.getService(
                    NodeService::class,
                    invokator = WC_SERVICE_INVOCATOR,
                    setvice = NODES_SERVICE,
                ),
            )
            Services.reg(
                remote.getService(
                    MemberReportRemote::class,
                    invokator = WC_SERVICE_INVOCATOR,
                    setvice = MEMBER_REPORT_SERVICE,
                ),
            )
            Services.reg(
                remote.getService(
                    CloseDocumentRemote::class,
                    invokator = WC_SERVICE_INVOCATOR,
                    setvice = CLOSE_DOCUMENT_SERVICE,
                ),
            )
            Services.reg(
                remote.getService(
                    CalcDocumentRemote::class,
                    invokator = WC_SERVICE_INVOCATOR,
                    setvice = CALC_DOCUMENT_SERVICE,
                ),
            )
            Services.reg(
                remote.getService(
                    ScriptAdminService::class,
                    invokator = WC_SERVICE_INVOCATOR,
                    setvice = SCRIPT_ADMIN_SERVICE,
                ),
            )

            val session by Services.byClass(OSession::class)
            val exPermissionManagerService by Services.byClass(ExPermissionManagerService::class)
//            if (session.isAllow(PERMISSION_VIEW_DIAGRAM_LIST))
//                Services.reg(
//                        object : SettingItem {
//                            override val uri: String
//                                get() = DiagramListPage.URI
//                            override val title: String
//                                get() = DiagramListPage.TITLE
//
//                            override suspend fun getPage(): Page = DiagramListPage()
//                        }
//                )

            if (session.isAllow(PERMISSION_VIEW_SCRIPT_LIST)) {
                Services.reg(
                    object : SettingItem {
                        override val uri: String
                            get() = ScriptsPage.URI
                        override val title: String
                            get() = ScriptsPage.TITLE

                        override suspend fun getPage(): Page = ScriptsPage()
                    },
                )
            }

            Services.reg(DurationPropertyFactory)
            Services.reg(GoodDictionaryPropertyFactory)
            Services.reg(IntPropertyFactory)
            Services.reg(NumberPropertyFactory)
            Services.reg(ShopPropertyFactory)
            Services.reg(StringPropertyFactory)
            Services.reg(BooleanPropertyFactory)
            Services.reg(DatePropertyFactory)
            Services.reg(ParamUIServiceImpl())

            val scriptAdminService by Services.byClass(ScriptAdminService::class)
            scriptAdminService.getMemberInfoTitles().await(DEFAULT_TIMEOUT).forEach {
                Services.reg(object : MemberInfoRecord {
                    override suspend fun isClicable(memberId: Long): Boolean = false

                    override suspend fun click(memberId: Long) {
                    }

                    override val title: String
                        get() = it.value

                    override fun getValue(memberId: Long): Promise<String> = async2 {
                        val value = memberInfoMap.getOrPut(memberId.jdto) {
                            async2 {
                                scriptAdminService.getMemberInfoValues(memberId.jdto)
                                    .await().entries.associate { it.key.value to it.value.value }
                            }
                        }
                        value.await()[it.value] ?: "нет значения"
                    }
                })
            }

            scriptAdminService.getMemberActions().await().forEach {
                if (!it.needPermissions.all { session.isAllow(it.value) }) return@forEach
                Services.reg(object : MemberAction {
                    override suspend fun action(memberId: Long) {
                        if (it.params.value == "") {
                            val result = scriptAdminService.executeMemberAction(
                                memberId = memberId.jdto,
                                actionName = it.actionName,
                                params = JMap(),
                                scriptId = it.scriptId,
                            ).await()
                            if (result != null) {
                                MemberActionDialog.showResult(it.actionName.value, result)
                            }
                        } else {
                            MemberActionDialog.show(memberId, it)
                        }
                    }

                    override val title: String
                        get() = it.actionName.value
                })
            }
        }
    })
}

operator fun CssDeclaration.set(key: String, value: String) {
    this.asDynamic()[key] = value
}

fun interface Closable {
    fun close()
}
