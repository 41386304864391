package org.tlsys.dto

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import pw.binom.date.DateTime
import pw.binom.openapi.StdFormat
import pw.binom.openapi.annotation.Description
import pw.binom.openapi.annotation.Format
import pw.binom.uuid.UUID

@Serializable
data class JournalDto(
    @Format(StdFormat.UUID)
    @Contextual
    @Description("Идентификатор записи")
    val id: UUID? = null,
    @Description("Идентификатор пользователя")
    val user: Long? = null,
    @Description("Идентификатор компании")
    val company: Long,
    @Description("Уровень важности сообщения")
    val level: Level,
    @Description("Текст записи")
    val message: String? = null,
    @Description("Идентификатор клиента")
    val member: Long? = null,
    @Description("Тип записи")
    val type: Type? = null,
    @Description("Дата создания записи")
    @Contextual
    @Format(StdFormat.DATE_TIME)
    val date: DateTime = DateTime.now,
) {
    @Serializable
    enum class Level(val code: Int) {
        @SerialName("fatal")
        FATAL(1),

        @SerialName("error")
        ERROR(100),

        @SerialName("warning")
        WARNING(200),

        @SerialName("info")
        INFO(300),

        @SerialName("debug")
        DEBUG(400),
        ;

        companion object {
            val byCode = values().associateBy { it.code }
            fun findByCode(code: Int) =
                byCode[code] ?: throw IllegalArgumentException("Can't find Journal Level by code. code: [$code]")

            val MIN = FATAL
            val MAX = DEBUG
        }
    }

    @Serializable
    enum class Type {
        CHANGE_MANUAL_MEMBER_AMOUNT,
        CHANGE_CONFIG,
        CHANGE_LOGIN,
        CHANGE_MEMBER_TAGS,
        LOGIN,
        CHANGE_CARD,
        OSMICARD,
    }
}
