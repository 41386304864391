package org.tlsys.frontol

import kotlinx.browser.document
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.form.MutableValidated
import org.tlsys.admin.form.SimpleValidator
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.form.Validator
import org.tlsys.admin.ui.Styles
import org.tlsys.css.CSS
import org.tlsys.px
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import pw.binom.web.AbstractComponent

private object Style {
    val VALID = "valid"
    val ENABLED = "enabled"

    val templateEditor = CSS.style {

        ":not(.$ENABLED)" then {
            backgroundColor = "#EEEEEE"
            cursor = "default"
        }

        ".$ENABLED" then {
            ".$VALID" then {
                backgroundColor = "#e7ffea"
                ":focus" then {
                    boxShadow = "0px 0px 2px green"
                }
            }
            ":not(.$VALID)" then {
                backgroundColor = "#ffe4e4"
                ":focus" then {
                    boxShadow = "0px 0px 2px red"
                }
            }
        }

        border = "1px solid #a9b2bd"
        borderRadius = 4.px
        padding = 8.px
        fontFamily = Styles.DEFAULT_TEXT_FONT
        fontSize = 14.px
        color = "#424a54"
        cursor = "text"
        transition = "100ms linear"
        transitionProperty = "box-shadow, background-color"
    }
}

class TemplateEditor(template: String = "", var knownTemplates: Array<String> = arrayOf()) :
    AbstractComponent<HTMLDivElement>(), MutableValidated<String> {
    override val dom: HTMLDivElement = document.createElement("div").unsafeCast<HTMLDivElement>()

    private val regexSimpleCheck = Regex("([^%]+|%[^%]+%)+")
    private val regexSpan = Regex("<span[^<>]+>([^<>]+)<\\/span>")
    private val regexTemplate = Regex("%[^% ]+%")
    private val regexClean = Regex("&[\\w]+;")

    var content: String
        get() = _content
        set(value) {
            _content = value
            span()
        }

    private var _content: String = ""

    var enabled: Boolean
        get() = dom.isContentEditable
        set(value) {
            dom.contentEditable = value.toString()
            if (value) {
                dom.classList.add(Style.ENABLED)
            } else {
                dom.classList.remove(Style.ENABLED)
            }
        }

    private val validator = SimpleValidator<String>()

    override val valid: Boolean
        get() = validator.valid

    override fun onValidChange(listener: ValidListener) = validator.onValidChange(listener)

    private val validListener: ValidListener = {
        if (it) {
            dom.classList.add(Style.VALID)
        } else {
            dom.classList.remove(Style.VALID)
        }
        dom.dispatchEvent(Event("valid"))
    }

    init {
        dom.classList.add(Style.templateEditor.name)
        dom.addEventListener("blur", { span() })
        dom.addEventListener("keyup", {
            unspan()
            dom.dispatchEvent(Event("change"))
            this.validator.updateValue(content)
            if (valid) {
                EVENT_CHANGED.dispatch()
            }
        })
        validator.forceSetValid(false)
        onValidChange(validListener)
        enabled = true
        content = template
        this.validator.updateValue(content)
    }

    private fun unspan() {
        _content = regexSpan.replace(dom.innerText) { result -> result.groupValues[1] }.clean()
        check()
    }

    private fun String.clean() = replace(regexClean, " ")

    val EVENT_CHANGED = EventElement()

    private fun span() {
        check()
        dom.innerHTML =
            regexTemplate.replace(_content) { result -> "<span style=\"color: ${result.color()}\">${result.value}</span>" }
    }

    private fun MatchResult.color(): String = if (value in knownTemplates) "blue" else "red"

    override fun addValidator(validator: Validator<String>) = run {
        this.validator.addValidator(validator)
        this.validator.forceRefresh()
    }

    init {
        addValidator(
            Validator {
                if (it.isBlank() || (
                        it.matches(regexSimpleCheck) && regexTemplate.findAll(it)
                            .all { it.value in knownTemplates }
                        )
                ) {
                    valid()
                } else {
                    invalid("Что-то не так")
                }
            },
        )
    }

    private fun check() {
    }
}
