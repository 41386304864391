package org.tlsys.core.promotion.tagsByPaymentPromotion

import org.tlsys.*
import org.tlsys.admin.*
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.Validated
import org.tlsys.admin.ui.*
import org.tlsys.admin.ui.Tabs
import org.tlsys.api.MemberTagService
import org.tlsys.api.Promotion2Service
import org.tlsys.cms.click
import org.tlsys.cms.ui.TimeCountSelector
import org.tlsys.cms.ui.time
import org.tlsys.core.promotion.ConditionsTab
import org.tlsys.core.promotion.PermanentPromotionEditor
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.uuid.nextUuid
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.random.Random

class TagByPaymentPromotionDialog(var record: PermanentPromotion?, new: Boolean) :
    TitleDialog(PermanentPromotionType.TAGS_BY_PAYMENT.title) {
    val configs = record?.config?.getObject<TagsByPaymentConfig>()
    private val memberTagService by Services.byClass(MemberTagService::class)

    private val body = SaveCancelBody(okText = if (new) "Создать" else "Сохранить").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    val dd = DivLayout().appendTo(layout, grow = 0, shrink = 0)
    private var result: PermanentPromotion? = null
    private val name = EditText(placeHolder = "Название")
        .text(record?.title ?: type.title)
        .appendTo(dd.layout, grow = 1, shrink = 1)
        .andTextValidator(TextValidators.NOT_BLANK)
    private val status = Combobox2(
        items = PromotionStatus.values().map { it.title },
        select = record?.status?.let { PromotionStatus.values().indexOf(it) } ?: 0,
    ).appendTo(dd.layout, grow = 1, shrink = 1)
    private val dates = DivLayout().appendTo(layout, grow = 0, shrink = 0)
    private val dateStart =
        DatetimeEditor(record?.startDate ?: pw.binom.date.DateTime.now)
            .withLabel("Дата начала")
            .appendTo(dates.layout, grow = 1, shrink = 1)
    private val dateEnd =
        DatetimeEditor(
            record?.endDate ?: pw.binom.date.DateTime(pw.binom.date.DateTime.nowTime + 1000 * 60 * 60 * 24 * 7),
        )
            .withLabel("Дата окончания")
            .appendTo(dates.layout, grow = 1, shrink = 1)
    private val tabs = Tabs(bottomPudding = true).appendTo(layout, grow = 0, shrink = 0)
    private val viewport = ComponentView().appendTo(layout = layout)
    private val viewScroll = ScrollController(layout.dom)
    private val config = DivLayout(direction = FlexLayout.Direction.Column)
    private val periodGroup = RadioBox.Group()
    private val periodLayout = DivLayout(direction = FlexLayout.Direction.Row)
        .withLabel("Расчетный период")
        .appendTo(config.layout, grow = 0, shrink = 0)
    private val allPeriod = RadioBox("Весь период").group(periodGroup)
        .appendTo(periodLayout.content.layout)
        .checked(configs?.allPeriod ?: true)
    private val specificPeriod = RadioBox("Ограниченный период").group(periodGroup)
        .appendTo(periodLayout.content.layout)
        .checked(!(configs?.allPeriod ?: true))
    private val period = TimeCountSelector(placeHolder = "Период расчета накоплений")
        .appendTo(config.layout, shrink = 0, grow = 0)
        .time(configs?.payPeriod)

    private val periodValidator = Validated(period, periodGroup) {
        if (allPeriod.checked) {
            true
        } else {
            period.valid
        }
    }
    private val conditions = ConditionsTab()
    private val validator = MultiValidator(name, periodValidator)
    private val list = LevelEditList().appendTo(config.layout)

    val applyBtn = ConfirmButton("${if (new) "Создать" else "Сохранить"} и приминить")

    override fun onEscapeClick() {
        super.onEscapeClick()
        result = null
        close()
    }

    init {
        applyBtn.appendTo(body.buttonsLayout, grow = 0, shrink = 0)

        applyBtn.onClick {
            if (newStatus.canWork && !YesNoDialog.show("Процесс установки новых меток всем клиентом может занять длительное время.\nЗапустить процесс?")
                    .await()
            ) {
                return@onClick
            }
            save()
            Promotion2Service.executePermanentPromotion(result!!.id)
        }

        periodGroup.eventChange.on(reset = true) {
            periodValidator.refresh()
            period.enabled = specificPeriod.checked
        }
        status.eventChange.on {
            applyBtn.enabled = newStatus.canWork
        }
        dom.style.width = 500.px
        dom.style.height = 650.px
        tabs.add("Настройки") {
            viewport.set2(config)
        }
        tabs.add("Условия") {
            viewport.set2(conditions)
        }

        tabs.active = 0
        validator.onValidChange {
            body.okBtn.enabled = it
        }
        body.ok {
            val newStatus = PromotionStatus.values()[status.select]
            if (newStatus.canWork && !YesNoDialog.show("Изменения будут применены только для клиентов с новыми чеками.\nСохранить изменения?")
                    .await()
            ) {
                return@ok
            }
            save()
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    private val newStatus
        get() = PromotionStatus.values()[status.select]

    private suspend fun save() {
        val config = TagsByPaymentConfig(
            includeTags = conditions.includeTags.content.list.map { it.tag.id },
            excludeTags = conditions.excludeTags.content.list.map { it.tag.id },
            records = list.items.map { it.record }.toList(),
            payPeriod = if (period.valid) period.time?.toDateDuration else configs?.payPeriod,
            allPeriod = allPeriod.checked,
        )
        result = PermanentPromotion(
            id = record?.id ?: Random.nextUuid(),
            title = name.text,
            owner = record?.owner ?: 0L,
            status = newStatus,
            createDate = record?.createDate?.let { pw.binom.date.DateTime(it.time) } ?: pw.binom.date.DateTime.now,
            startDate = dateStart.content.date!!,
            endDate = dateEnd.content.date,
            timeToStart = TimeIntervalEnum.MANUAL,
            type = type,
            config = JsonObject.create(config),
        )
    }

    override suspend fun onInit() {
        super.onInit()

        if (record != null && configs != null) {
            val tags =
                memberTagService.getTagList((configs.includeTags + configs.excludeTags).map { it.jdto }.jdto()).await()
                    .associateBy { it.id }

            conditions.includeTags.content.addTag(
                configs.includeTags.map { tags[it]!! },
            )
            conditions.excludeTags.content.addTag(
                configs.excludeTags.map { tags[it]!! },
            )

            configs.records.forEach { level ->
                list.add(RecordItem(level))
            }
        }
        periodValidator.refresh()
    }

    companion object : PermanentPromotionEditor {
        override val type: PermanentPromotionType
            get() = PermanentPromotionType.TAGS_BY_PAYMENT

        override suspend fun show(record: PermanentPromotion?, new: Boolean): PermanentPromotion? {
            val d = TagByPaymentPromotionDialog(record = record, new = new)
            d.show()
            return d.result
        }
    }

    inner class LevelEditList : EditableList<RecordItem>() {
        override suspend fun createItemForAdd(): Item? {
            val record = RecordDialog.show(null) ?: return null
            return RecordItem(record)
        }

        override fun afterAdd(item: Item) {
            super.afterAdd(item)
            sort()
        }
    }

    inner class RecordItem(
        var record: TagsByPaymentConfig.Record,
    ) : EditableList.Item(), Comparable<RecordItem> {
        override val valid: Boolean
            get() = true

        fun refresh() {
            div.innerText = "Накопления ${record.amount}"
        }

        override suspend fun isCanDeleteItem(): Boolean =
            YesNoDialog.show("Удалить уровень \"${div.innerText}\"", width = 500).await()

        init {
            div.style.cursor = "default"
            div.style.userSelect = "none"
            div.addClass(Styles.SIMPLE_TEXT)
            actionPanel.iconBtn(MaterialIcons.EDIT).click {
                val r = RecordDialog.show(record) ?: return@click
                record = r
                Effects.blockRow(dom)
                refresh()
                list.sort()
            }
            actionPanel.iconBtn(MaterialIcons.CONTENT_COPY).click {
                val r = RecordDialog.show(record) ?: return@click
                val newItem = RecordItem(r)
                list.add(newItem)
                Effects.blockRow(newItem.dom)
                list.sort()
            }
            refresh()
        }

        override fun compareTo(other: RecordItem): Int =
            record.amount.asLong.compareTo(other.record.amount.asLong)
    }
}
