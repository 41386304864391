package org.tlsys

import kotlinx.browser.document
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.AbstractFragment
import org.tlsys.admin.ui.Checkbox
import org.tlsys.api.GroupService
import org.tlsys.api.getGroupsIterator
import org.tlsys.dto.auth.GroupDTO
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class GroupSelectorFragment(private val selected: List<Long>) : AbstractFragment<HTMLDivElement>(document.createDiv()) {
    private class Item(val tag: GroupDTO, selected: Boolean, val selectedItems: ArrayList<Long>) :
        Checkbox(label = tag.name, checked = selected) {
        init {
            this.EVENT_CHANGED.on {
                if (checked) {
                    if (tag.id !in selectedItems) {
                        selectedItems.add(tag.id)
                    }
                } else {
                    if (tag.id in selectedItems) {
                        selectedItems.remove(tag.id)
                    }
                }
            }
        }
    }

    private val layout = FlexLayout(dom, direction = FlexLayout.Direction.Column)
    val scroll = ScrollController(document.createDiv()).also {
        layout.add(it.dom) {
            grow = 1
            shrink = 1
        }
    }

    private val scrollLayout = FlexLayout(scroll.dom).apply {
        direction = FlexLayout.Direction.Column
    }
    private val groupService by Services.byClass(GroupService::class)
    private var tagIterator = groupService.getGroupsIterator(minForLoad = 7, maxPart = 10)
    private val items = ArrayList<Item>()
    private val _selectedItems = ArrayList<Long>()
    val selectedItems: List<Long>
        get() = _selectedItems

    init {
        _selectedItems += selected
    }

    override suspend fun onInit() {
        super.onInit()
        console.info("Init!")

        while (!scroll.visibleScrollY && tagIterator.hasNext()) {
            val element = tagIterator.next()
            val item = Item(element, selected = element.id in selected, selectedItems = _selectedItems)
            items += item
            scrollLayout.add(item.dom) {
                grow = 0
                shrink = 0
            }
        }

        scroll.addScrollListener {
            if (!scroll.endScrollY) {
                return@addScrollListener
            }
            async2 {
                if (tagIterator.hasNext()) {
                    val element = tagIterator.next()
                    scrollLayout.add(
                        Item(
                            element,
                            selected = element.id in selected,
                            selectedItems = _selectedItems,
                        ).dom,
                    ) {
                        grow = 0
                        shrink = 0
                    }
                }
            }
        }
    }
}
