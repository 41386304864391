package org.tlsys.core.report

import kotlinx.browser.window
import libs.Date
import libs.month
import libs.time
import libs.timeUTC
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.Validator
import org.tlsys.admin.ui.*
import org.tlsys.api.Core2ApiAccess
import org.tlsys.dto.GenerateReportConfigDto
import org.tlsys.gui.MessageDialog
import org.tlsys.px
import org.tlsys.ui.DivLayout
import org.w3c.dom.HTMLDivElement
import pw.binom.date.DateTime
import pw.binom.web.layout.FlexLayout
import pw.binom.web.layout.appendTo

object TransactionReport : ReportPage.ReportConfigDialog {
    override suspend fun make() {
        TransactionReportDialog.show()
    }

    override val title: String
        get() = "Отчёт по транзакциям"
}

class TransactionReportDialog : TitleDialog(TransactionReport.title) {

    private val body = SaveCancelBody("Создать", "Закрыть")
        .appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val transactionType = Combobox2(
        items = GenerateReportConfigDto.AmountType.values().map {
            when (it) {
                GenerateReportConfigDto.AmountType.BOTH -> "Списание и начисление"
                GenerateReportConfigDto.AmountType.ONLY_ADD -> "Начисления"
                GenerateReportConfigDto.AmountType.ONLY_DROP -> "Списания"
            }
        },
        select = GenerateReportConfigDto.AmountType.values().indexOf(GenerateReportConfigDto.AmountType.BOTH),
    ).appendTo(layout, grow = 0, shrink = 0)
    private val date = DivLayout(direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)
    private val fromDate = DateEditText(placeHolder = "Начало отчета").appendTo(date.layout, grow = 1)
    private val endDate = DateEditText(placeHolder = "Конец отчета").appendTo(date.layout, grow = 1)

    init {
        dom.style.apply {
            width = 300.px
            height = 210.px
        }
        endDate.date(Date())
        fromDate.date(Date().also { it.month-- })
        fromDate.dateValidator(
            Validator {
                if (endDate.date != null && it.time > endDate.date!!.time) {
                    invalid("Дата начала больше даты конца")
                } else {
                    valid()
                }
            },
        )
        val validator = MultiValidator(fromDate, endDate)
        validator.onValidChange {
            body.okBtn.enabled = it
        }
        fromDate.eventChange.on {
            fromDate.forceRefreshValid()
        }
        endDate.eventChange.on {
            fromDate.forceRefreshValid()
        }

        body.ok {
            ProcessBlocker.block("") {
                val reportFileId = Core2ApiAccess.generateTransactionReport(
                    amountType = GenerateReportConfigDto.AmountType.BOTH,
                    dateStart = fromDate.date?.let { DateTime(it.timeUTC) },
                    dateEnd = endDate.date?.let { DateTime(it.timeUTC) },
                )
                val reportFile = Core2ApiAccess.getDownloadPath(reportFileId)
                console.info("URL:", reportFile)
                window.open(reportFile, "_blank")
                MessageDialog.showInfo("Отчет сгенерирован!")
            }
        }
        body.cancel {
            close()
        }
    }

    companion object {
        suspend fun show() {
            val d = TransactionReportDialog()
            d.show()
        }
    }
}
