package org.tlsys.wc

import org.tlsys.json.*

open class ScriptMessage(open val message: String?, val line: Int? = null) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<ScriptMessage> {
        override val type: String
            get() = "script_message"

        override fun read(node: JsonNode) =
                ScriptMessage(
                        line = node["line"]?.int(),
                        message = node["message"]?.string()
                )

        override fun write(obj: ScriptMessage): JsonNode =
                JsonNode.obj()
                        .set("line", obj.line?.json)
                        .set("message", obj.message?.json)
    }
}
/*
open class ScriptException(message: String? = null) : RuntimeException(message), JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = Companion.asDefault

    companion object : JDTOFactory<ScriptException> {
        override val type: String
            get() = "script_exception"

        override fun read(node: JsonNode): ScriptException =
                ScriptException(node["message"].string())

        override fun write(obj: ScriptException): JsonNode =
                JsonNode.obj().set("message", obj.message?.json)
    }


    class MissingPropertyException(blockId: Int, lineNumber: Int?, val property: String) : ScriptGroovyException(blockId, lineNumber) {

        override val factory: JDTOFactory<JDTO>
            get() = Companion.asDefault

        companion object : JDTOFactory<MissingPropertyException> {
            override val type: String
                get() = "script_missing_property_exception"

            override fun read(node: JsonNode): MissingPropertyException =
                    MissingPropertyException(
                            blockId = node["node"].int()!!,
                            lineNumber = node["line"].int(),
                            property = node["property"].string()!!
                    )

            override fun write(obj: MissingPropertyException): JsonNode {
                TODO("not implemented") //To change body of created functions use File | Settings | File Templates.
            }
        }
    }

    class MultipleErrorsException(blockId: Int, val errors: List<JDTO>) : ScriptGroovyException(blockId = blockId, lineNumber = null) {
        override val factory: JDTOFactory<JDTO>
            get() = Companion.asDefault

        companion object : JDTOFactory<MultipleErrorsException> {
            override fun read(node: JsonNode): MultipleErrorsException =
                    MultipleErrorsException(
                            blockId = node["block"].int()!!,
                            errors = JsonFactory.readArray<JDTO>(node["errors"])?.filterNotNull() ?: listOf()
                    )

            override fun write(obj: MultipleErrorsException): JsonNode =
                    JsonNode.obj().set("block", obj.blockId.json)
                            .also {
                                it.set("errors", JsonFactory.writeArray<JDTO>(obj.errors))
                            }

            override val type: String
                get() = "multiple_errors_exception"
        }

        override val message: String?
            get() = errors.map { it as Throwable; it.message }.joinToString("\n")
    }

    open class ScriptGroovyException(val blockId: Int, val lineNumber: Int?, message: String? = null) : ScriptException(message) {
        override val factory: JDTOFactory<JDTO>
            get() = Companion.asDefault

        companion object : JDTOFactory<ScriptGroovyException> {
            override val type: String
                get() = "script_groovy_exception"

            override fun read(node: JsonNode): ScriptGroovyException =
                    ScriptGroovyException(
                            blockId = node["block"].int()!!,
                            lineNumber = node["line"].int(),
                            message = node["message"].string()
                    )

            override fun write(obj: ScriptGroovyException): JsonNode =
                    JsonNode.obj()
                            .set("block", obj.blockId.json)
                            .set("line", obj.lineNumber?.json)
                            .set("message", obj.message?.json)
        }
    }
}
*/