package org.tlsys.admin.events

import org.tlsys.async
import org.tlsys.core.Closeable

class EventElement {

    private val listeners = ArrayList<() -> Unit>()

    fun on(reset: Boolean = false, f: () -> Unit): Closeable {
        listeners.add(f)
        val f = Closeable {
            listeners.remove(f)
        }

        if (reset) {
            f()
        }

        return f
    }

    fun one(f: suspend () -> Unit): Closeable {
        var e: Closeable? = null
        e = on {
            async {
                f()
            }
            e!!.close()
        }
        return e
    }

    fun dispatch() {
        for (f in listeners)
            f()
    }
}
