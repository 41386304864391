package org.tlsys.core.promotion

import org.tlsys.dto.DtoSet
import org.tlsys.json.*
import kotlin.js.JsName

interface AdminPromotionService {
    class PromotionDTO(
        val id: String,
        val order: Int,
        val scriptId: String,
        val params: String,
        val name: String,
        val enabled: Boolean,
        val title: String,
    ) : JDTO {
//        protected constructor() : this("", 0, "", "", "", false, "")

        companion object : JDTOFactory<PromotionDTO> {
            override val type: String
                get() = "PromotionDTO"

            override fun read(node: JsonNode): PromotionDTO =
                PromotionDTO(
                    id = node["id"]!!.string(),
                    order = node["order"]!!.int(),
                    scriptId = node["scriptId"]!!.string(),
                    params = node["params"]!!.string(),
                    name = node["name"]!!.string(),
                    enabled = node["enabled"]!!.boolean(),
                    title = node["title"]!!.string(),
                )

            override fun write(obj: PromotionDTO): JsonNode =
                JsonNode.obj {
                    set("id", obj.id.json)
                    set("order", obj.order.json)
                    set("scriptId", obj.scriptId.json)
                    set("params", obj.params.json)
                    set("name", obj.name.json)
                    set("enabled", obj.enabled.json)
                    set("title", obj.title.json)
                }

        }

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }

    class ScriptDTO(val id: String, val title: String, val settings: String) : JDTO {
        companion object : JDTOFactory<ScriptDTO> {
            override val type: String
                get() = "ScriptDTO"

            override fun read(node: JsonNode): ScriptDTO = ScriptDTO(
                id = node["id"]!!.string(),
                title = node["title"]!!.string(),
                settings = node["settings"]!!.string()
            )

            override fun write(obj: ScriptDTO): JsonNode =
                JsonNode.obj {
                    set("id", obj.id.json)
                    set("title", obj.title.json)
                    set("settings", obj.settings.json)
                }

        }

        protected constructor() : this("", "", "")

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }


    @JsName("getScripts")
    fun getScripts(): AsyncResult<JList<ScriptDTO>>

    @JsName("createPromotion")
    fun createPromotion(id: JString, name: JString, params: JString, title: JString): AsyncResult<PromotionDTO>

    @JsName("updatePromotion")
    fun updatePromotion(id: JString, params: JString, title: JString): AsyncResult<PromotionDTO>

    @JsName("deletePromotion")
    fun deletePromotion(id: JString): AsyncResult<JUnit>

    @JsName("setEnabled")
    fun setEnabled(id: JString, enabled: JBoolean): AsyncResult<JUnit>

    @JsName("getPromotions")
    fun getPromotions(): AsyncResult<JList<PromotionDTO>>

    object DTO : DtoSet() {
        override fun regDto() {
            PromotionDTO.reg()
            ScriptDTO.reg()
        }

    }
}