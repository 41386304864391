package org.tlsys.cards

import org.tlsys.admin.ui.AbstractPage
import org.tlsys.admin.ui.Styles
import org.tlsys.css.CSS

private const val CARDCODE_CLASS = "cardCode"
private const val MEMBERINFO_CLASS = "memberInfo"
private const val BALANS_CLASS = "balansInfo"

/*
val exAccountHistoryFraqgment = object : Extensions.AccountExtensionFragment {
    override val fragment: (Long) -> Fragment<HTMLDivElement>
        get() = ::CardOfAccountFragment
    override val name: String
        get() = "Карты"
}
*/
class CardsPage : AbstractPage() {
    override suspend fun getTitle(): String = TITLE

    companion object {
        internal val css = CSS.style {
            val column = template {
                overflowX = "hidden"
                textAlign = "center"
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = "16px"
            }
            padding = "8px 0px 8px 0px"
            ":nth-child(2n)" then {
                background = rgba(0.0, 0.0, 0.0, 0.02)
                ":hover" then {
                    backgroundColor = rgba(36.0, 148.0, 188.0, 0.02)
                }
            }

            ":hover" then {
                backgroundColor = rgba(58.0, 175.0, 218.0, 0.02)
            }

            ">.$CARDCODE_CLASS" then {

                color = "#3aafda"
                extend(column)
            }

            ">.$MEMBERINFO_CLASS" then {
                extend(column)
                cursor = "pointer"
                color = "#3aafda"
            }

            ">.$BALANS_CLASS" {
                extend(column)
            }
        }
        const val URI = "cards"
        const val TITLE = "Карты"
        /*
                init {
                    //SearchPage.reg(CardSearchSource())
                    Extensions.accountExtensionFragments.extend {
                        exAccountHistoryFraqgment
                    }
                }
                */
    }
}
