package org.tlsys.ui

import kotlinx.browser.document
import kotlinx.dom.addClass
import org.tlsys.insertAfter
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pw.binom.web.AbstractComponent
import pw.binom.web.Component

// interface Component<out T : HTMLElement> {
//    val dom: T
//    suspend fun onInit(init: Promise<Unit>) {}
//    suspend fun onStart(init: Promise<Unit>) {}
//    suspend fun onStop(init: Promise<Unit>) {}
// }

// abstract class AbstractComponent<T : HTMLElement>(override val dom: T) : Component<T> {
//    init {
//        val g = dom.asDynamic().CONTROL
//        if (g != null && g != undefined)
//            throw IllegalStateException("Dom element already have controller")
//        dom.asDynamic().CONTROL = this
//
//        this::class.simpleName?.also {
//            dom.setAttribute("data-type", it)
//        }
//    }
//    private var _started: Boolean = false
//    private var _firstStart = true
//    protected val started: Boolean
//        get() = _started
//    var readyForUse = false
//        private set
//
//    override suspend fun onStart(init: Promise<Unit>) {
//        if (_firstStart) {
//            onInit(init)
//            _firstStart = false
//        }
//        _started = true
//        super.onStart(init)
//        readyForUse = true
//    }
//
//    override suspend fun onStop(init: Promise<Unit>) {
//        readyForUse = false
//        super.onStop(init)
//        _started = false
//    }
//
//    override suspend fun onInit(init: Promise<Unit>) {
//        super.onInit(init)
//    }
// }

class EmptyComponent : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
}

fun <T : Component<out HTMLElement>> T.before(component: Component<*>): T {
    val parent = component.dom.parentNode ?: TODO()
    dom.remove()
    parent.insertBefore(dom, component.dom)
    return this
}

fun <T : Component<out HTMLElement>> T.after(component: Component<*>): T {
    val parent = component.dom.parentNode ?: TODO()
    dom.remove()
    parent.insertAfter(dom, component.dom)
    return this
}

fun <T : Component<out HTMLElement>> T.addClass(className: String): T {
    dom.addClass(className)
    return this
}
