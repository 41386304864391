package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.addClass
import org.tlsys.async2
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.on
import org.w3c.dom.HTMLButtonElement
import pw.binom.web.AbstractComponent

open class Button(text: String = "", style: String? = null) : AbstractComponent<HTMLButtonElement>() {
    override val dom: HTMLButtonElement = document.createElement("button").unsafeCast<HTMLButtonElement>()

    init {
        if (style != null) {
            dom.classList.add(style)
        }
    }

    var text: String
        get() = dom.innerText
        set(it) {
            dom.innerText = it
        }

    var enabled: Boolean
        get() = !dom.disabled
        set(it) {
            dom.disabled = !it
        }

    init {
        this.text = text
    }
}

fun <T : Button> T.onClick(f: suspend () -> Unit): T {
    dom.on("click") {
        if (!enabled) {
            return@on
        }
        enabled = false
        async2 {
            f()
            enabled = true
        }
    }
    return this
}

private val CANCEL_STYLE = CSS.style {
    border = "none"
    padding = "7px 32px"
    backgroundColor = "transparent"
    outline = "none"
    transition = "background-color 300ms ease, transform 300ms ease"
    borderRadius = 2.px
    ":enabled" then {
        cursor = "pointer"
        ":active" then {
            transform = "scale(0.96)"
        }
        ":hover" then {
            backgroundColor = "rgba(0,0,0,0.1)"
        }
    }
}.name

private val CONFIRM_STYLE = CSS.style {
    backgroundColor = "#009688"
    fontFamily = Styles.DEFAULT_TEXT_FONT
    fontSize = Styles.DEFAULT_FONT_SIZE.px
    color = "#FFF"
    transition = "box-shadow 300ms ease, transform 300ms ease, color 300ms ease, background-color 300ms ease"
    userSelect = "none"
//    letterSpacing = 5.px
    border = "none"
    cursor = "pointer"
    padding = "7px 32px"
    borderRadius = 2.px
    outline = "none"
    boxShadow = "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)"
    ":enabled:active" then {
        boxShadow = "0 0px 0px 0 rgba(0,0,0,0.18), 0 0px 0px 0 rgba(0,0,0,0.15)"
        transform = "scale(0.96)"
    }
    ":enabled:hover" then {
        boxShadow = "0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15)"
    }
    ":disabled" then {
        color = "#9F9F9F"
        backgroundColor = "#DFDFDF"
        cursor = "default"
    }
}.name

fun BaseButton(text: String = "") = Button(text, CANCEL_STYLE)
fun ConfirmButton(text: String = "") = Button(text, CONFIRM_STYLE)
fun MaterialButton(icon: String, style: String? = null) = Button(style = style).also {
    it.dom.addClass("material-icons")
    it.text = icon
}
fun AddButton() = MaterialButton("add", style = ActionPlace.BUTTON)
fun CopyButton() = MaterialButton("content_copy", style = ActionPlace.BUTTON)
fun RefreshButton() = MaterialButton("refresh", style = ActionPlace.BUTTON)
