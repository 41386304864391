package org.tlsys.api

import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import org.tlsys.ApiUrls
import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.PermanentPromotion
import pw.binom.uuid.UUID

object Promotion2Service {
    suspend fun getOrderPromotions(max: Long, offset: Long) =
        Core2ApiAccess.call(
            serviceUrl = "${ApiUrls.ORDER_PROMOTION_LIST_GET}?max=$max&offset=$offset",
            bodyClass = Unit.serializer(),
            body = null,
            response = ListSerializer(OrderPromotionRecord.serializer()),
            method = "POST",
        )

    suspend fun createOrderPromotion(record: OrderPromotionRecord) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.ORDER_PROMOTION_CREATE_POST,
            bodyClass = OrderPromotionRecord.serializer(),
            body = record,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun updateOrderPromotion(record: OrderPromotionRecord) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.ORDER_PROMOTION_UPDATE_POST,
            bodyClass = OrderPromotionRecord.serializer(),
            body = record,
            response = Unit.serializer(),
            method = "POST",
        )

    // --------------

    suspend fun getPermanentPromotions(max: Long, offset: Long) =
        Core2ApiAccess.call(
            serviceUrl = "${ApiUrls.PERMANENT_PROMOTION_LIST_GET}?max=$max&offset=$offset",
            bodyClass = Unit.serializer(),
            body = null,
            response = ListSerializer(PermanentPromotion.serializer()),
            method = "POST",
        )

    suspend fun createPermanentPromotion(record: PermanentPromotion) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.PERMANENT_PROMOTION_CREATE_POST,
            bodyClass = PermanentPromotion.serializer(),
            body = record,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun updatePermanentPromotion(record: PermanentPromotion) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.PERMANENT_PROMOTION_UPDATE_POST,
            bodyClass = PermanentPromotion.serializer(),
            body = record,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun executePermanentPromotion(promotionId: UUID) =
        Core2ApiAccess.call(
            serviceUrl = "${ApiUrls.PERMANENT_PROMOTION_MANUAL_EXECUTE}?promotion=$promotionId",
            bodyClass = Unit.serializer(),
            body = Unit,
            response = Unit.serializer(),
            method = "POST",
        )
}
