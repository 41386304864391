package org.tlsys.cms

import org.tlsys.core.Closeable
import org.tlsys.isOnDocument
import org.w3c.dom.HTMLElement

/**
 * Класс помогающий создавать расширения интерфейса
 *
 * @param T элемент
 */
class Extension<T> {

    private val listeners = ArrayList<(Element<T>) -> Unit>()

    interface Element<out T> {
        val value: T
        fun close(f: () -> Unit)
    }

    private inner class ElementImp(val provider: () -> T) : Element<T> {
        override val value: T by lazy { provider() }

        val destroyListeners = ArrayList<() -> Unit>()
        override fun close(f: () -> Unit) {
            destroyListeners.add(f)
        }
    }

    private val _elements = ArrayList<ElementImp>()
    val elements: List<Element<T>>
        get() = _elements

    fun extend(f: T) = extend { f }

    fun extend(f: () -> T): Closeable {
        val el = ElementImp(f)
        _elements.add(el)

        for (e in listeners)
            e(el)
        return object : Closeable {
            override fun close() {
                el.destroyListeners.forEach { it.invoke() }
                el.destroyListeners.clear()
            }
        }
    }

    fun use(listener: (Element<T>) -> Unit): Closeable {
        listeners.add(listener)

        for (e in _elements)
            listener(e)

        return object : Closeable {
            override fun close() {
                console.info("Close call!")
                listeners.remove(listener)
                _elements.forEach {
                    it.destroyListeners.forEach {
                        it()
                    }
                }
            }
        }
    }
}

fun <T> Extension<T>.use(element: HTMLElement, listener: (Extension.Element<T>) -> Unit): Closeable {
    var l: Closeable? = null
    l = use {
        if (!element.isOnDocument) {
            l!!.close()
        } else {
            listener(it)
        }
    }
    return l
}
