package org.tlsys.node

import org.tlsys.json.*

class ScriptDTO(val id: String, val name: String, val url: String) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<ScriptDTO> {
        override val type: String
            get() = "scriptdto"

        override fun read(node: JsonNode) = ScriptDTO(
                id = node["id"]!!.string(),
                name = node["name"]!!.string(),
                url = node["url"]!!.string()
        )

        override fun write(obj: ScriptDTO) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "name" to obj.name.json,
                        "url" to obj.url.json
                )

    }
}