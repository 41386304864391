package org.tlsys.admin

import kotlinx.serialization.Serializable
import pw.binom.db.serialization.Enumerate

@Serializable
enum class OrderPromotionType(val code: Int, val title: String) {
    @Enumerate.Alias("1")
    BONUS_BY_PAYED_AND_DOC_PAY_WITH_LEVELS(1, "Бонусы по уровням оборота"),

    @Enumerate.Alias("2")
    DISCOUNT_BY_PAYED_AND_DOC_PAY_WITH_LEVELS(2, "Скидка по уровням оборота"),

    @Enumerate.Alias("3")
    DISCOUNT_BY_TAGS(3, "Скидка по меткам"),

    @Enumerate.Alias("4")
    SET_MAX_DISCOUNT(4, "Максимальный процент оплаты бонусами"),

    @Enumerate.Alias("5")
    DISCOUNT_EXTERNAL(5, "Внешний скрипт. Расчет скидки"),

    @Enumerate.Alias("6")
    MAX_PAY_EXTERNAL(6, "Внешний скрипт. Оплата бонусами"),

    @Enumerate.Alias("7")
    BONUS_APPLY_EXTERNAL(7, "Внешний скрипт. Начисление бонусов"),

    @Enumerate.Alias("8")
    TAG_SETTER(code = 8, title = "Установка меток клиента"),

    @Enumerate.Alias("9")
    ADD_BONUS(code = 9, title = "Сумма бонусов на документ"),
    ;

    companion object {
        val byCodes = values().associateBy { it.code }
        fun findByCode(code: Int) =
            byCodes[code] ?: throw IllegalArgumentException("Can't find promotion by code $code")
    }
}
