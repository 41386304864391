package org.tlsys.admin.ui

import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class TagEditDialog(selected: List<Long>, hide: List<Long>) : TitleDialog(title = "Метки") {
    init {
        dom.style.apply {
            width = 300.px
            height = 400.px
        }
    }

    private var _canceled = false
    val canceled: Boolean
        get() = _canceled

    val body = SaveCancelBody(okText = "ОК").appendTo(super.layout, grow = 1, shrink = 1)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val selector = TagSelectorFragment(selected, hide).appendTo(layout)

    override suspend fun onStart() {
        super.onStart()
        console.info("Start TagEditDialog")
    }

    override suspend fun onStop() {
        console.info("Stop TagEditDialog")
        super.onStop()
    }

    init {
        body.cancelBtn.onClick {
            cancelClick()
        }

        body.okBtn.onClick {
            _canceled = false
            close()
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    companion object {
        suspend fun show(selected: List<Long>, hide: List<Long> = emptyList()): List<Long>? {
            val dialog = TagEditDialog(selected, hide)
            dialog.show()
            return if (dialog.canceled) {
                null
            } else {
                dialog.selector.selectedItems
            }
        }
    }
}
