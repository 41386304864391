package org.tlsys.goods

import org.tlsys.json.*

class DTOImportSettings(
    val url: String?,
    val autoImport: Boolean,
    val charset: String,
    val codeNormalization: Boolean,
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTOImportSettings> {
        override val type: String
            get() = "goods_import_settings"

        override fun read(node: JsonNode) =
            DTOImportSettings(
                url = node["url"]?.string(),
                autoImport = node["autoImport"]!!.boolean(),
                charset = node["charset"]!!.string(),
                codeNormalization = node["codeNormalization"]!!.boolean(),
            )

        override fun write(obj: DTOImportSettings): JsonNode =
            JsonNode.obj()
                .set("url", obj.url?.json)
                .set("autoImport", obj.autoImport.json)
                .set("charset", obj.charset)
                .set("codeNormalization", obj.codeNormalization)
    }
}
