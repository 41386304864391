package org.tlsys.admin

fun String.isFinite(): Boolean {
    val self = this
    return js("(self-parseFloat(self) + 1)>=0")
}

fun String.isInteger(): Boolean {
    val self = this
    return js("self==parseInt(self)")
}