package org.tlsys.dto

import org.tlsys.json.*

class PluginDescriptionDTO(
        val name: JString,
        val description: JString,
        val version: JString,
        val lib: JBoolean,
        val dependency: JList<JString>) : JDTO {
    constructor():this(
            name="".jdto,
            description = "".jdto,
            version = "".jdto,
            dependency = emptyList<JString>().jdto(),
            lib = false.jdto
    )
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : AutoJDTOFactory<PluginDescriptionDTO>(PluginDescriptionDTO::class)
}