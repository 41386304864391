package org.tlsys.lk.admin.dto

import org.tlsys.dto.DtoSet
import org.tlsys.dto.reg_admin_extensionLib

const val INVAKATOR = "LK Admin Extension"
const val CARDPR_SERVICE="CARD_PR_SERVICE"

object lk_dto_set:DtoSet(){
    override fun regDto() {
    }

    override val subSet: List<DtoSet> = listOf(reg_admin_extensionLib)
}