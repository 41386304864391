package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.removeClass
import org.tlsys.core.Closeable
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.web.layout.*
import org.tlsys.ui.on
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent

private fun UpArrow() = MaterialButton("expand_less", style = ActionPlace.BUTTON)
private fun DownArrow() = MaterialButton("keyboard_arrow_down", style = ActionPlace.BUTTON)

private class Counter(value: Int, val min: Int, val max: Int) :
    DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    companion object {
        private val BUTTON = CSS.genName()
        private val FOCUS = CSS.genName()
        private val COMPONENT = CSS.style {
            ".$FOCUS" then {
                borderColor = "#d9d9d9"
            }
            border = "1px solid transparent"
            borderRadius = 4.px
            transitionProperty = "border-color"
            transitionDuration = "200ms"
        }.name
        private val STYLE = CSS.style {
            backgroundColor = "transparent"
            border = "none"
            outline = "none"
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontSize = Styles.DEFAULT_FONT_SIZE.px
            textAlign = "center"
        }.name
    }

    private val keyDown: (Event) -> Unit = FUNC@{ event ->
        event as KeyboardEvent
        if (event.keyCode == 38) {
            doUp()
            event.preventDefault()
            return@FUNC
        }
        if (event.keyCode == 40) {
            doDown()
            event.preventDefault()
            return@FUNC
        }
        event.preventDefault()
//        if (event.keyCode !in 48..57 && event.keyCode != 8 && event.keyCode != 37 && event.keyCode != 39) {
//            console.dir(event)
//            event.preventDefault()
//            return@FUNC
//        }
    }

    private val up = UpArrow().appendTo(layout).addClass(BUTTON)
    private val editor = document.createElement("input").unsafeCast<HTMLInputElement>().appendTo(layout).addClass(STYLE)
    private val down = DownArrow().appendTo(layout).addClass(BUTTON)
    var value: Int = value
        set(value) {
            field = value
            refresh()
        }

    private fun refresh() {
        editor.value = if (value < 10) "0$value" else value.toString()
    }

    private fun doUp() {
        val oldStart = editor.selectionStart
        val oldEnd = editor.selectionEnd
        this.value = minOf(max, this.value + 1)
        editor.selectionStart = oldStart
        editor.selectionEnd = oldEnd
    }

    private fun doDown() {
        val oldStart = editor.selectionStart
        val oldEnd = editor.selectionEnd
        this.value = maxOf(min, this.value - 1)
        editor.selectionStart = oldStart
        editor.selectionEnd = oldEnd
    }

    init {
        editor.readOnly = true
        addClass(COMPONENT)
        refresh()
        up.onClick {
            doUp()
        }
        down.onClick {
            doDown()
        }
        this.editor.addEventListener("keydown", keyDown)
        editor.on("focus") {
            addClass(FOCUS)
        }
        editor.on("blur") {
            removeClass(FOCUS)
        }
    }
}

class TimeSelector(h: Int, m: Int) : PopupWindow.Selector, DivComponentWithLayout() {
    data class Value(val h: Int, val m: Int)

    override val valid: Boolean
        get() = true

    override fun onValidChange(listener: ValidListener): Closeable {
        return Closeable { }
    }

    private val hh = Counter(min = 0, max = 23, value = h).appendTo(layout)
    private val mm = Counter(min = 0, max = 59, value = m).appendTo(layout)
    var hours
        get() = hh.value
        set(value) {
            hh.value = value
        }

    var minutes
        get() = mm.value
        set(value) {
            mm.value = value
        }

    companion object {
        suspend fun show(
            x: Float, y: Float,
            h: Int, m: Int,
        ): Value? {
            val res = PopupWindow.show(
                x = x.toInt() - 2, y = y.toInt() - 2, selector = TimeSelector(
                    h = h,
                    m = m,
                )
            ) ?: return null
            return Value(
                h = res.hh.value,
                m = res.mm.value,
            )
        }
    }
}

