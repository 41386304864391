package org.tlsys.dto.auth

import org.tlsys.decrypt
import org.tlsys.encrypt
import org.tlsys.json.*

private val KEY1 = 11
private val KEY2 = 12

class UserDTO(val id: Long, val name: String, val login: String, val phone: String?, val companyId: Long) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<UserDTO> {
        override val type: String
            get() = "UserDTO"

        override fun read(node: JsonNode) =
                UserDTO(
                        id = node["id"]!!.long(),
                        name = decrypt(node["name"]!!.string(), KEY1),
                        companyId = node["company"]!!.long(),
                        login = decrypt(node["login"]!!.string(), KEY2),
                        phone = node["phone"]?.string()?.let { decrypt(it, KEY2) },
                )

        override fun write(obj: UserDTO) =
                JsonNode.obj()
                        .set("id", obj.id.json)
                        .set("name", encrypt(obj.name, KEY1).json)
                        .set("company", obj.companyId.json)
                        .set("login", encrypt(obj.login, KEY2).json)
                        .set("phone", obj.phone?.let { encrypt(it, KEY2) }?.json)
    }
}

class UserNotFoundExceptionDTO : RuntimeException(), JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<UserNotFoundExceptionDTO> {
        override val type: String
            get() = "UserNotFoundExceptionDTO"

        override fun read(node: JsonNode) = UserNotFoundExceptionDTO()

        override fun write(obj: UserNotFoundExceptionDTO) = JsonNode.obj()
    }

}