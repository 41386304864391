package org.tlsys.dto.auth

object CorePermission {
    val OWNER = "KERNEL"
    val NODE_SERVICE = "Node Diagram Service"
    val CHANGE_MEMBER_BALANS = "CHANGE_MEMBER_BALANS"
    val CREATE_MEMBERS = "CREATE_MEMBERS"
    val EDIT_MEMBERS_INFO = "EDIT_MEMBERS_INFO"
    val DELETE_MEMBERS = "DELETE_MEMBERS"
    val EDIT_MEMBERS_REFERENCES = "EDIT_MEMBERS_REFERENCES"
    val EDIT_MEMBERS_TAGS = "EDIT_MEMBERS_TAGS"
    val EDIT_MEMBERS_CARDS = "EDIT_MEMBERS_CARDS"
    val EDIT_MEMBERS_CONTACTS = "EDIT_MEMBERS_CONTACTS"
    val SHOW_TAGS = "SHOW_TAGS"
    val SHOW_SHOPS = "SHOW_SHOPS"
    val SHOW_PLUGINS_CONTROL = "SHOW_PLUGINS_CONTROL"
    val EDIT_MEMBER_STATUSES = "EDIT_MEMBER_STATUSES"
    val EDIT_MEMBER_TEST_PROMOTION = "EDIT_MEMBER_TEST_PROMOTION"
    val SHOW_REPORTS = "SHOW_REPORTS"
    val SHOW_PROMOTIONS = "SHOW_PROMOTIONS"
    val BILLING = "Billing Service"
}