package org.tlsys.promotion.dto

import kotlinx.serialization.Serializable

@Serializable
data class TagFilterDtoWithShop(
    val includeTags: List<Long> = emptyList(),
    val excludeTags: List<Long> = emptyList(),
    val shops: List<Long> = emptyList(),
)
