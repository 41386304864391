package org.tlsys.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import pw.binom.date.DateTime
import pw.binom.date.iso8601
import pw.binom.date.parseIso8601Date

object DateStringIso8601Serializer : KSerializer<DateTime> {
    override fun deserialize(decoder: Decoder): DateTime {
        val text = decoder.decodeString()
        return text.parseIso8601Date(0) ?: throw SerializationException("Can't parse \"$text\" to iso 8601 date")
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("Date", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: DateTime) {
        encoder.encodeString(value.iso8601(0))
    }
}
