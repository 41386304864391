package org.tlsys.shops

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.DIALOG_AUTO_FOCUS_ON_INPUT
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.cms.click
import org.tlsys.dto.DTOTerminal
import org.tlsys.ui.*
import pw.binom.web.layout.*

/**
 * Created by Igor on 28.02.2017.
 */
class AddTerminalDialog private constructor(terminal: DTOTerminal?) :
    TitleDialog(if (terminal === null) "Создание терминала" else "Редактирование терминала") {
    init {
        dom.style.width = "350px"
    }

    private var _canceled: Boolean = true

    val canceled: Boolean
        get() = _canceled

    val body = SaveCancelBody().apply {
        layout.add(dom) {
            shrink = 1
            grow = 1
        }
    }

    val name = EditText(placeHolder = "Наименование").textValidator(TextValidators.NOT_BLANK)
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)

    init {
        if (terminal != null) {
            dom.style.height = "200px"
            val layout = DivLayout(direction = FlexLayout.Direction.Row)
                .appendTo(body.componentsLayout, grow = 0, shrink = 0)
            val code = EditText(placeHolder = "Регистрационный номер", readOnly = true)
                .text(terminal.code)
                .appendTo(layout.layout, grow = 1)
            val copyBtn = CopyButton().appendTo(layout.layout, grow = 0)

            copyBtn.click {
                code.focus()
                code.select()
                val successful = document.execCommand("copy")
                if (!successful) {
                    window.alert("Копирование не поддерживается браузером, пробуйте вручную")
                }
            }
//            val actions = ActionPlace().appendTo(layout.layout, grow = 0)
//            actions.iconBtn("content_copy")
        } else {
            dom.style.height = "150px"
        }
    }

    private fun refreshStatus() {
        body.okBtn.enabled =
            name.valid
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    init {
        name.eventValidChange.on {
            refreshStatus()
        }

        if (terminal !== null) {
            name.text = terminal.name
        }

        refreshStatus()

        body.ok {
            _canceled = false
            close()
        }

        body.cancel {
            cancelClick()
        }
    }

    override suspend fun onStart() {
        super.onStart()
        if (DIALOG_AUTO_FOCUS_ON_INPUT) {
            name.focus()
            name.select()
        }
    }

    companion object {
        suspend fun show(terminal: DTOTerminal?): DTOTerminal? {
            val dd = AddTerminalDialog(terminal)
            dd.show()
            return if (dd.canceled) {
                null
            } else {
                DTOTerminal(name = dd.name.text, code = terminal?.code ?: "", id = terminal?.id ?: 0)
            }
        }
    }
}
