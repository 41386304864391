package org.tlsys

import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import pw.binom.uuid.UUID

@Serializable
data class Template(
    @Contextual
    val id: UUID,
    val name: String,
    val type: TemplateType,
    val subject: String?,
    val body: String,
)
