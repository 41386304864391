package org.tlsys

import kotlinx.serialization.Serializable
import org.tlsys.serialization.QuantitySerializerLong
import pw.binom.openapi.annotation.Description
import kotlin.jvm.JvmInline
import kotlin.math.roundToLong

@DtoDescriptionRU("Количество. В граммах. Например 0.5кг = 500, 0.5л = 500, 2шт = 2000")
@Description("Количество. В граммах. Например 0.5кг = 500, 0.5л = 500, 2шт = 2000")
@Serializable(QuantitySerializerLong::class)
@JvmInline
value class Quantity(private val value: Long) {
    companion object {
        val ZERO = Quantity(0)
    }

    operator fun unaryMinus() = Quantity(-value)
    operator fun compareTo(value: Quantity): Int =
        when {
            this.value > value.value -> 1
            this.value < value.value -> -1
            else -> 0
        }

    operator fun compareTo(value: Long): Int =
        when {
            this.value > value -> 1
            this.value < value -> -1
            else -> 0
        }

    operator fun plus(second: Quantity) =
        Quantity(value + second.value)

    val asLong: Long
        get() = value

    val asFloat: Float
        get() = value * 0.001f

    val asDouble: Double
        get() = value * 0.001

    override fun toString(): String {
        if (value == 0L) {
            return "0"
        }
        val l = value / 1000L
        val f = value / 1000.0f
        return if (l == f.roundToLong()) l.toString() else f.toString().replace('.', ',')
    }

    operator fun minus(second: Quantity) = Quantity(value - second.value)
    operator fun times(invert: Percent) = Quantity((value * invert.asDoubleDivide).roundToLong())
}

val Float.toQuantity: Quantity
    get() = Quantity((this * 1000f).roundToLong())

val Long.toQuantity: Quantity
    get() = Quantity(this)

@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("sumOfMoneyValue")
inline fun <T> Iterable<T>.sumOfQuantity(selector: (T) -> Quantity): Quantity {
    var sum = 0L
    for (element in this) {
        sum += selector(element).asLong
    }
    return sum.toQuantity
}
