package org.tlsys.api

import org.tlsys.AutoLoadAsyncListIterator
import org.tlsys.LocalCache
import org.tlsys.async2
import org.tlsys.dto.FCursor
import org.tlsys.dto.MemberDTO
import org.tlsys.dto.SearchSource
import org.tlsys.json.jdto

fun MembersService.getMembersIterator(sex: Boolean?, withTags: List<Long>, withoutTags: List<Long>, minForLoad: Int, maxPart: Int): AutoLoadAsyncListIterator<MemberDTO> {
    var cursor = FCursor()
    return AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        async2 {
            val ll = getMembers(
                    cursor = cursor,
                    max = max.jdto,
                    sex = sex?.jdto,
                    withTags = withTags.map { it.jdto }.jdto(),
                    withoutTags = withoutTags.map { it.jdto }.jdto()
            ).await()
            cursor = ll.first
            ll.secod
        }
    })
}

fun MembersService.searchMembersIterator(exp: String?,
                          source: SearchSource?,
                          withTags: List<Long>,
                          withoutTags: List<Long>,
                          sex: Boolean?,
                          minForLoad: Int, maxPart: Int): AutoLoadAsyncListIterator<MemberDTO> {
    var cursor = FCursor()
    return AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        async2 {
            val ll = searchMembers(
                    cursor = cursor,
                    max = max.jdto,
                    sex = sex?.jdto,
                    withTags = withTags.map { it.jdto }.jdto(),
                    withoutTags = withoutTags.map { it.jdto }.jdto(),
                    source = source?.name?.jdto,
                    exp = exp?.jdto
            ).await()
            cursor = ll.first
            ll.secod
        }
    })
}

fun MembersService.getReferFromIterator(from: Long, minFull: Int, partSize: Int) =
    AutoLoadAsyncListIterator(minFull = minFull, max = partSize) { max, offset ->
        getChildsRef(from = from.jdto, max = max.jdto, offset = offset.jdto).promise
    }

fun MembersService.getReferToIterator(to: Long, minFull: Int, partSize: Int) =
    AutoLoadAsyncListIterator(minFull = minFull, max = partSize) { max, offset ->
        getParentsRef(to = to.jdto, max = max.jdto, offset = offset.jdto).promise
    }

private var membersLocalCache: LocalCache<Long, MemberDTO>? = null

suspend fun MembersService.getMemberById2(userId: Long): MemberDTO? {
    if (membersLocalCache == null) {
        membersLocalCache = LocalCache(1000L * 60 * 30) {
            getMemberById(userId.jdto).promise
        }
    }
    return membersLocalCache!!.get(userId)
}