package org.tlsys.admin.core

import org.tlsys.api.ProcessService
import org.tlsys.async2
import org.tlsys.dto.ProcessDTO
import org.tlsys.json.jdto
import kotlinx.browser.window
import kotlin.js.Promise

fun ProcessDTO.join(interval: Int) = Promise<String?> { d, c ->
    var updating = false
    var intervalId = 0
    val processService by Services.byClass(ProcessService::class)
    val f: () -> Unit = {
        async2 {
            if (updating)
                return@async2
            updating = true
            try {
                val p = processService.getProcess(this.id.uuid.jdto).await() ?: run {
                    updating = false
                    window.clearInterval(intervalId)
                    c(RuntimeException("Process ${id} not found"))
                    return@async2
                }
                if (p.success != null) {
                    if (p.success == true) {
                        window.clearInterval(intervalId)
                        d(p.result)
                    } else {
                        window.clearInterval(intervalId)
                        c(RuntimeException(p.result))
                    }
                }
            } catch (e: dynamic) {
                window.clearInterval(intervalId)
                c(e)
            }
            updating = false
        }
    }

    intervalId = window.setInterval(f, interval)
}