package org.tlsys.admin.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import org.jetbrains.compose.web.renderComposable
import org.tlsys.admin.ui.AbstractPage
import org.tlsys.ui.Page

fun <T> MutableState<T>.onChange(func: (T) -> Unit) = MutableStateWithOnChange(
    parent = this,
    onChange = func,
)

class MutableStateWithOnChange<T>(val parent: MutableState<T>, val onChange: (T) -> Unit) : MutableState<T> {
    override var value: T
        get() = parent.value
        set(value) {
            onChange(value)
            parent.value = value
        }

    override fun component1(): T = parent.component1()
    override fun toString(): String = parent.toString()

    override fun component2(): (T) -> Unit {
        val func = parent.component2()
        return { newValue: T ->
            onChange(newValue)
            func(newValue)
        }
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as MutableStateWithOnChange<*>

        return parent == other.parent
    }

    override fun hashCode(): Int {
        return parent.hashCode()
    }
}

class PageContent(val onTitleChange: (String) -> Unit) {
    val title = mutableStateOf("").onChange(onTitleChange)
}

class ComposablePage(
    val next: (path: String) -> Page? = { null },
    val func: @Composable PageContent.() -> Unit,
) : AbstractPage() {
    val ctx = PageContent(
        onTitleChange = { newTitle ->
            setTitle(newTitle)
        },
    )

    override suspend fun getTitle(): String = ctx.title.value

    init {
        afterConstruct()
        renderComposable(dom) {
            func(ctx)
        }
    }

    override suspend fun next(path: String): Page? = next(path)
}
