package org.tlsys.core.promotion

import org.tlsys.DateDuration
import org.tlsys.admin.ui.*
import org.tlsys.ui.*
import pw.binom.date.DateTime
import pw.binom.web.layout.*
import kotlin.math.absoluteValue

class PromotionsPage : AbstractPage() {
    companion object {
        val URI = "promotions2"
        val TITLE = "Настройки программы"
    }

    override suspend fun getTitle(): String = TITLE

    private val toolBar = ActionPanel().appendTo(contentLayout, grow = 0, shrink = 0)
    private val tabs = Tabs()
        .appendTo(toolBar, FlexLayout.FlexItem.AlignSelf.Normal)

    init {
        toolBar.addSpace()
    }

    private val addPromotionBtn = Dropdown("Добавить").appendTo(toolBar)

    private val view = ComponentView().appendTo(contentLayout, grow = 1, shrink = 1)
    private val onOrderComponent = OnOrderPromotion()
    private val permonentPromotions = PermanentPromotionFragment()

    init {
        tabs.add("На кассе") {
            view.set2(onOrderComponent)
        }
        tabs.add("Фоновые") {
            view.set2(permonentPromotions)
        }
        addPromotionBtn.onClick {
            when (tabs.active) {
                0 -> onOrderComponent.addClick(addPromotionBtn.dom.getBoundingClientRect())
                1 -> permonentPromotions.addClick(addPromotionBtn.dom.getBoundingClientRect())
            }
        }
        tabs.active = 0
    }

    override suspend fun onInit() {
    }
}

operator fun DateTime.minus(now: DateTime) =
    DateDuration((this.time - now.time).absoluteValue)
