package org.tlsys.dto

import org.tlsys.json.*

class ServiceNotFoundException(val invokator: String, val service: String, val method: String) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<ServiceNotFoundException> {
        override val type: String
            get() = "ServiceNotFoundException"

        override fun read(node: JsonNode) = ServiceNotFoundException(
                invokator = node["invokator"]!!.string(),
                service = node["service"]!!.string(),
                method = node["method"]!!.string()
        )

        override fun write(obj: ServiceNotFoundException) =
                JsonNode.obj(
                        "invokator" to obj.invokator.json,
                        "service" to obj.service.json,
                        "method" to obj.method.json
                )

    }
}