package org.tlsys.tags

import kotlinx.browser.window
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.px
import org.tlsys.ui.*
import pw.binom.web.layout.*

class TagEditDialog(val value: String?) :
    TitleDialog(title = if (value == null) "Новая метка" else "Редактирование метки") {
    private var _canceled: Boolean = true

    init {
        dom.style.width = 300.px
        // dom.style.height = "370px"
    }

    val canceled: Boolean
        get() = _canceled

    val valueLabel =
        EditText(placeHolder = "Название").text(value ?: "").textValidator(TextValidators.NOT_BLANK)
            .appendTo(layout, grow = 0, shrink = 0)

    val body = SaveCancelBody().apply {
        layout.add(dom) {
            shrink = 1
            grow = 1
        }
    }

    override suspend fun onStart() {
        super.onStart()
        window.setTimeout({
            valueLabel.focus()
        }, 100)
    }

    init {

        valueLabel.eventValidChange.on {
            body.okBtn.enabled = it
        }

        body.ok {
            _canceled = false
            close()
        }

        body.cancel {
            cancelClick()
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    companion object {
        suspend fun show(value: String?): String? {
            val dialog = TagEditDialog(value)
            dialog.show()
            return if (dialog.canceled) {
                null
            } else {
                dialog.valueLabel.text
            }
        }
    }
}
