package org.tlsys.core.promotion

import org.tlsys.JsonObject
import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.OrderPromotionType
import org.tlsys.admin.PromotionStatus
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.px
import org.tlsys.ui.DivLayout
import org.w3c.dom.HTMLDivElement
import pw.binom.date.DateTime
import pw.binom.uuid.nextUuid
import pw.binom.web.ScrollController
import pw.binom.web.layout.FlexLayout
import pw.binom.web.layout.appendTo
import kotlin.random.Random

abstract class BasePromotionDialog(record: OrderPromotionRecord?, title: String) : TitleDialog(title) {
    protected val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    protected abstract val type: OrderPromotionType

    private val titleGroup = DivLayout().appendTo(layout, grow = 0, shrink = 0)

    val name = EditText(placeHolder = "Название")
        .text(record?.title ?: type.title)
        .appendTo(titleGroup.layout, grow = 1, shrink = 1)
        .andTextValidator(TextValidators.NOT_BLANK)

    val status = Combobox2(
        items = PromotionStatus.values().map { it.title },
        select = record?.status?.let { PromotionStatus.values().indexOf(it) } ?: 0,
    )
        .appendTo(titleGroup.layout, grow = 1, shrink = 1)
    val dates = DivLayout().appendTo(layout, grow = 0, shrink = 0)

    private val dateStart =
        DatetimeEditor(record?.startDate ?: DateTime.now)
            .withLabel("Дата начала")
            .appendTo(dates.layout, grow = 1, shrink = 1)
    private val dateEnd =
        DatetimeEditor(record?.endDate ?: DateTime(DateTime.nowTime + 1000 * 60 * 60 * 24 * 7))
            .withLabel("Дата окончания")
            .appendTo(dates.layout, grow = 1, shrink = 1)

    val tabs = Tabs().appendTo(layout, grow = 0, shrink = 0)
    private val viewport = ComponentView().appendTo(layout = layout)
    private val viewScroll = ScrollController(layout.dom)
    protected val conditions = ConditionsTabWithShops() // DivLayout(direction = FlexLayout.Direction.Column)
    protected val config = DivLayout(direction = FlexLayout.Direction.Column)
    protected var result: OrderPromotionRecord? = null

    override fun onEscapeClick() {
        result = null
        close()
    }

    protected val validator = MultiValidator(name)
    protected abstract suspend fun buildConfig(): JsonObject

    init {
        validator.onValidChange {
            console.info("Valid changed: $it")
            body.okBtn.enabled = it
        }
        dom.style.width = 500.px
        dom.style.height = 700.px
        tabs.add("Настройки") {
            viewport.set2(config)
        }
        tabs.add("Условия") {
            viewport.set2(conditions)
        }
        tabs.active = 0
        body.ok {
            result = OrderPromotionRecord(
                type = type,
                id = record?.id ?: Random.nextUuid(),
                params = buildConfig(),
                title = name.text,
                ownerUserId = 0,
                status = PromotionStatus.values()[status.select],
                createDate = DateTime.now,
                startDate = dateStart.content.date!!,
                endDate = dateEnd.content.date,
            )

            close()
        }
        body.cancel {
            result = null
            close()
        }
    }
}
