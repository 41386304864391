package org.tlsys.jobs.dto

import org.tlsys.json.*

class TaskDTO(val id: JString, val url: JString, val name: JString, val taskName: JString, val params: JString) : JDTO {
    constructor() : this(JString.EMPTY, JString.EMPTY, JString.EMPTY, JString.EMPTY, JString.EMPTY)

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : AutoJDTOFactory<TaskDTO>(TaskDTO::class, "TaskDTO")
}
/*
enum class ParamType {
    INT, DOUBLE, TEXT, SHOP, DURATION, GOODS_DICTIONARY, BOOLEAN
}

class ScriptParam(val id: JString, val type: JString, val title: JString, val canBeEmpty: JBoolean) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    constructor() : this(JString.EMPTY, JString.EMPTY, JString.EMPTY, JBoolean(false))

    val typeEnum: ParamType
        get() = ParamType.valueOf(type.value)

    companion object : AutoJDTOFactory<ScriptParam>(ScriptParam::class, "JobParam")
}
*/