package org.tlsys.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import org.tlsys.Percent

object PercentSerializer : KSerializer<Percent> {
    override fun deserialize(decoder: Decoder): Percent =
        Percent(decoder.decodeLong())

    override val descriptor: SerialDescriptor =
        Long.serializer().descriptor

    override fun serialize(encoder: Encoder, value: Percent) {
        encoder.encodeLong(value.asLong)
    }
}
