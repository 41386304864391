package org.tlsys.goods.pages

import org.tlsys.admin.core.Services
import org.tlsys.admin.core.join
import org.tlsys.admin.ui.*
import org.tlsys.api.ProcessService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.core.map
import org.tlsys.emptyAsyncIterator
import org.tlsys.goods.*
import org.tlsys.goods.api.GoodDictionaryService
import org.tlsys.goods.api.GoodsService
import org.tlsys.goods.dialogs.GoodsImportDialog
import org.tlsys.goods.dialogs.GoodsSelectDialog
import org.tlsys.goods.services.classifiersIterator
import org.tlsys.goods.services.goodsIterator
import org.tlsys.goods.services.groupIterator
import org.tlsys.goods.views.GroupView
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.w3c.xhr.FormData
import pw.binom.web.layout.appendTo

class DictionaryPage(val dictionaryId: String) : AbstractPage() {
    override suspend fun getTitle(): String = dic.await()?.name ?: ""

    private var _dic: DTODictionary? = null
    private val goodDictionaryService by Services.byClass(GoodDictionaryService::class)
    private val goodsService by Services.byClass(GoodsService::class)

    val dic by lazy {
        async2 {
            val d = goodDictionaryService.getDictionary(dictionaryId.jdto).await()
            _dic = d
            eventTitleChange.dispatch()
            d
        }
    }

    private val processService by Services.byClass(ProcessService::class)
    private val tooBar = ActionPanel()
        .appendTo(contentLayout, grow = 0, shrink = 0).apply {
            addSpace()
        }
    private val filterBtn = tooBar.iconBtn("filter_alt")
    private val clearBtn = tooBar.button("Очистить")
    private val addDropdown = Dropdown("Добавить", listOf("Товар, группа или классификатор", "Импорт")).appendTo(tooBar)

    private var filter: String? = null

    override suspend fun updateParams(params: Map<String, String?>) {
        super.updateParams(params)
        val newFilter = params["filter"]
        filterBtn.dom.style.backgroundColor = if (newFilter != null) {
            "rgba(0,0,0,0.2)"
        } else {
            ""
        }
        if (newFilter != filter) {
            filter = newFilter

            resetView()
//            view.resetSource(
//                groupIt = goodDictionaryService.groupIterator(dictionaryId, minForLoad = 20, maxPart = 30),
//                goodIt = goodDictionaryService.goodsIterator(
//                    dictionaryId,
//                    minForLoad = 20,
//                    maxPart = 30,
//                    filter = filter
//                )
//                    .map { it ->
//                        DTOGood(
//                            id = it.secod?.id ?: "",
//                            code = it.first.value,
//                            name = it.secod?.name ?: "",
//                        )
//                    },
//            )
        }
    }

    init {
        filterBtn.onClick {
            val newFilter = GoodFilterDialog.show(filter)
            if (newFilter == filter) {
                return@onClick
            }
            setParams(mapOf("filter" to newFilter))
        }
        clearBtn.onClick {
            if (!YesNoDialog.show("Очистить список товаров?", 300).await()) {
                return@onClick
            }
            ProcessBlocker.block2("Очистка списка товаров") {
                goodDictionaryService.clear(dictionaryId.jdto).await()
            }
            resetView()
        }
        addDropdown.eventClick.on {
            when (it) {
                0 -> {
                    async2 {
                        val selected = GoodsSelectDialog.show(
                            goods = true,
                            groups = true,
                            classifiers = true,
                        ).await() ?: return@async2
                        when (selected) {
                            is DTOGood -> {
                                ProcessBlocker.block("Добавление товара в список") {
                                    goodDictionaryService.addGoodToDictionary(
                                        dictionaryId = dictionaryId.jdto,
                                        goodId = selected.id.jdto,
                                    ).await()
                                }.await()
                                view.createGood(selected)
                            }

                            is DTOGoodGroup -> {
                                ProcessBlocker.block("Добавление группы в список") {
                                    goodDictionaryService.addGroupToDictionary(
                                        dictionaryId = dictionaryId.jdto,
                                        groupId = selected.id.jdto,
                                    ).await()
                                }.await()
                                view.createGroup(selected)
                            }

                            is DTOClassifier -> {
                                ProcessBlocker.block("Добавление классификатора в список") {
                                    goodDictionaryService.addClassifierToDictionary(
                                        dictionaryId = dictionaryId.jdto,
                                        classifier = selected.code.jdto,
                                    ).await()
                                }.await()
                                view.classifierGroup(selected)
                            }
                        }
                    }
                }

                1 -> {
                    async2 {
                        val files = GoodsImportDialog.show("xml", "txt", "spr").await() ?: return@async2

                        ProcessBlocker.block("Загрузка") {
                            processService.getProcess(
                                goodDictionaryService.prepareImport(dictionaryId = dictionaryId.jdto).await(),
                            ).await()!!
                                .join(1000).await()
                            val uploadUrl = goodsService.getUploadUrl().await().value
                            val formData = FormData()
                            val fileIds = files.files.map {
                                val fileId = goodDictionaryService.startImport(it.name.jdto).await().value
                                formData.append(name = fileId.toString(), value = it, filename = it.name)
                                fileId to it
                            }
                            upload(
                                url = uploadUrl,
                                method = "POST",
                                data = formData,
                                process = {
                                },
                            ).await()
                            fileIds.forEachIndexed { index, it ->
                                val processId = goodDictionaryService.commitImport(
                                    fileId = it.first.jdto,
                                    dictionaryId = dictionaryId.jdto,
                                    charset = files.charset.jdto,
                                    codeNormalization = files.codeNormalization.jdto,
                                ).await()!!
                                processService.getProcess(processId).await()!!.join(1000).await()
                            }
                        }.await()
                        MessageDialog.showInfo("Импорт завершён")
                        resetView()
                    }
                }
            }
        }
    }

    override suspend fun onStart() {
        super.onStart()
        console.info("DictionaryPage OnStart!")
    }

    init {
        onStart {
            resetView()
        }
    }

    private suspend fun resetView() {
        view.resetSource(
            goodIt = goodDictionaryService.goodsIterator(dictionaryId, minForLoad = 20, maxPart = 30, filter = filter)
                .map { it ->
                    DTOGood(
                        id = it.secod?.id ?: "",
                        code = it.first.value,
                        name = it.secod?.name ?: "",
                        vendorCode = it.secod?.vendorCode,
                    )
                },
            groupIt = goodDictionaryService.groupIterator(dictionaryId, minForLoad = 20, maxPart = 30),
            classifierIt = goodDictionaryService.classifiersIterator(dictionaryId, minForLoad = 20, maxPart = 30),
        )
    }

    private val view = GroupView(
        groupIt = emptyAsyncIterator(),
        goodIt = emptyAsyncIterator(),
        classifierIt = emptyAsyncIterator(),
        rootUrl = null,
        deleteGood = {
            async2 {
                goodDictionaryService.deleteGoodFromDictionary(
                    dictionaryId = dictionaryId.jdto,
                    code = it.code.jdto,
                ).await().value
            }
        },
        deleteGroup = {
            async2 {
                goodDictionaryService.deleteGroupFromDictionary(
                    dictionaryId = dictionaryId.jdto,
                    groupId = it.id.jdto,
                ).await().value
            }
        },
        selectOption = null,
        allowRename = false,
        emptyText = "Группы и товары отсутствуют",
    ).appendTo(contentLayout, grow = 1, shrink = 1)
}
