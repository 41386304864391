package org.tlsys.admin.ui

import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.ValidListener
import org.tlsys.core.Closeable
import org.tlsys.px
import org.tlsys.toDateOnly
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.web.layout.*
import pw.binom.date.DateTime
import pw.binom.date.of

class DatetimeSelector(date: DateTime) : PopupWindow.Selector,
    DivComponentWithLayout(direction = FlexLayout.Direction.Row) {
    private val time: TimeSelector
    private val date: DateSelector

    override suspend fun onStart() {
        console.info("DatetimeSelector start")
    }

    override suspend fun onStop() {
        console.info("DatetimeSelector stop")
    }

    init {
        this.time = TimeSelector(h = 0, m = 0).appendTo(layout)
        this.date = DateSelector(year = 1970, month = 0, day = 1).appendTo(layout)
        time.dom.style.width = 150.px
        resetDate(date)
    }

    fun resetDate(date: DateTime) {
        val calc = date.calendar()
        this.time.hours = calc.hours
        this.time.minutes = calc.minutes
        this.date.date = calc.toDateOnly()
    }

    private val multiValidator = MultiValidator(time, this.date)
    override val valid: Boolean
        get() = multiValidator.valid

    override fun onValidChange(listener: ValidListener): Closeable = multiValidator.onValidChange(listener)

    companion object {
        suspend fun show(
            x: Float, y: Float,
            date: DateTime,
        ): DateTime? {
            val selector = DatetimeSelector(
                date = date,
            )
            val now = BaseButton("Текущее")
            now.onClick {
                selector.resetDate(DateTime.now)
            }
            val res = PopupWindow.show(
                x = x.toInt() - 2,
                y = y.toInt() - 2,
                selector = selector,
                buttons = arrayOf(now)
            ) ?: return null
            return DateTime.of(
                year = res.date.year,
                month = res.date.month + 1,
                dayOfMonth = res.date.day,
                hours = res.time.hours,
                minutes = res.time.minutes,
                timeZoneOffset = DateTime.systemZoneOffset,
                seconds = 0,
                millis = 0,
            )
        }
    }
}