package org.tlsys.jobs.components

import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.jobs.api.JobAdminService
import org.tlsys.jobs.dialogs.JobSettings
import org.tlsys.jobs.dto.JobDTO
import org.tlsys.jobs.dto.TaskDTO
import org.tlsys.px
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.web.layout.*
import org.tlsys.admin.ui.appendTo as appendTo2

class JobList : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    private val jobAdminService by Services.byClass(JobAdminService::class)

    //    private val toolsPlane = ToolsPlane(usingSearch = false).appendTo(layout, grow = 0, shrink = 0)
//    private val actions = ActionPanel().appendTo(layout, grow = 0, shrink = 0)

    private val table = MaterialTable().appendTo(layout, grow = 0, shrink = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo2(table)
            MaterialTable.TextColumn("Название").appendTo2(header)
            MaterialTable.TextColumn("Описание").appendTo2(header)
            MaterialTable.TextColumn("Действия").appendTo2(header).widthPx(1)
        }

    override suspend fun onInit() {
        super.onInit()
        jobAdminService.getJobs().await().forEach {
            Item(it).appendTo2(table)
        }
    }

//    init {
//        actions.addSpace()
//        val button = actions.iconBtn("add")
//        button.onClick {
//            val coord = button.dom.getBoundingClientRect()
//            async {
//                PopupMenu.build {
//                    val list = jobAdminService.getTasks().await()
//                    if (list.isEmpty()) {
//                        it.text("Нет доступных сценариев")
//                    }
//                    list.forEach { script ->
//                        it.text(script.taskName.value) {
//                            createJob(script)
//                        }
//                    }
//                }.await().showRight(x = coord.right.toInt(), y = coord.top.toInt())
//            }
//        }
//    }

    fun createJob(script: TaskDTO) {
        async2 {
            val newJob = JobSettings.show(script, null).await() ?: return@async2
            val job = jobAdminService.createJob(
                scriptId = newJob.scriptId,
                title = newJob.title,
                taskName = newJob.taskName,
                params = newJob.params,
            ).await()
            val item = Item(job).appendTo2(table)
            Effects.createAndBlankItem(item.dom)
        }
    }

    private inner class Item(@JsName("job2") var job: JobDTO) : MaterialTable.Row() {
        private val title = MaterialTable.TextColumn("").appendTo2(this)
        private val title2 = MaterialTable.TextColumn("").appendTo2(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo2(this)

//        private val runBtn = FloatButton(image = "play.svg", width = Styles.ICON_SIZE, height = Styles.ICON_SIZE)
//                .appendTo(layout, grow = 0, shrink = 0)
//
//        private val settingsBtn = FloatButton(image = "settings.svg", width = Styles.ICON_SIZE, height = Styles.ICON_SIZE)
//                .appendTo(layout, grow = 0, shrink = 0)
//
//        private val deleteBtn = FloatButton(image = "delete.svg", width = Styles.ICON_SIZE, height = Styles.ICON_SIZE)
//                .appendTo(layout, grow = 0, shrink = 0)

        init {
            actions.component.visibleOnHover(this)
            title2.dom.style.paddingLeft = 10.px
            refresh()

            actions.component.iconBtn(MaterialIcons.PLAY_ARROW).onClick {
                jobAdminService.runJob(job.id)
            }

            actions.component.iconBtn(MaterialIcons.SETTINGS).onClick {
                val script = jobAdminService.getTask(id = job.scriptId, taskName = job.taskName).await()!!
                val j = JobSettings.show(script = script, job = job).await() ?: return@onClick
                job = jobAdminService.editJob(jobId = job.id, taskName = j.taskName, params = j.params, title = j.title)
                    .await()
                    ?: return@onClick
                Effects.blockRow(dom)
                refresh()
            }

            actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                if (!YesNoDialog.show("Удалить задачу \"${job.title.value} (${job.taskName.value})\"").await()) {
                    return@onClick
                }
                jobAdminService.deleteJob(job.id).await()
                Effects.removeItem(dom).await()
                removeSelf()
            }
        }

        fun refresh() {
            title.text = job.title.value
            title2.text = if (job.taskName.value != job.title.value) {
                "${job.scriptName.value} :: ${job.taskName.value}"
            } else {
                job.scriptName.value
            }
        }
    }
}
