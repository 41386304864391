package org.tlsys.osmicard

import org.tlsys.admin.core.Services
import org.tlsys.admin.cross.RemoteOsmiService
import org.tlsys.admin.serialization.RList
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.await
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.lk.OsmicardTemplateDto
import org.tlsys.lk.getMasterTemplateValue
import org.tlsys.ui.*
import org.tlsys.ui.DivLayout
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class TemplateEditDialog(val template: OsmicardTemplateDto) : TitleDialog("Шаблон") {
    val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val memberTagService by Services.byClass(MemberTagService::class)
    private val name =
        EditText(placeHolder = "Название", readOnly = true)
            .text(template.name)
            .appendTo(layout, grow = 0, shrink = 0)
    private val fieldsLayout = DivLayout(direction = FlexLayout.Direction.Column).appendTo(layout, grow = 1, shrink = 1)
    private val tags = TagPanel(false)
        .withLabel("Метки")
        .appendTo(layout, grow = 0, shrink = 0)
    private val scroll = ScrollController(fieldsLayout.dom)

    private val table = MaterialTable().appendTo(fieldsLayout.layout, grow = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Ярлык").appendTo(header).widthPx(1)
//            MaterialTable.TextColumn("Статус").appendTo(header).widthPx(1)
            MaterialTable.TextColumn("Значение").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

//    private val addBtn = AddButton().appendTo(fieldsLayout.layout, grow = 0, shrink = 0)

    private fun buildResult() =
        OsmicardTemplateDto(
            id = template.id,
            name = name.text,
            tags = RList.make(tags.element.list.map { it.tag.id }),
            values = RList.make(table.rows.mapNotNull { it as? TemplateValueRow }.mapNotNull { it.tagValue }),
        )

    override suspend fun onInit() {
        super.onInit()
        tags.element.addTag(memberTagService.getTagList(template.tags.map { it.jdto }.jdto()).await())
        template.values.forEach {
            TemplateValueRow(it).appendTo(table)
        }
    }

    override fun onEscapeClick() {
        canceled = true
        close()
    }

    private inner class TemplateValueRow(var tagValue: OsmicardTemplateDto.TagValue) : MaterialTable.Row() {
        val name = MaterialTable.TextColumn(tagValue.name).appendTo(dom)
        val value = MaterialTable.TextColumn(tagValue.value).appendTo(dom)
        val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        init {
            actions.component.visibleOnHover(this)
            actions.component.iconBtn(MaterialIcons.EDIT).onClick {
                val newValue = ValueEditorDialog.show(name = tagValue.name, value = tagValue.value) ?: return@onClick
                tagValue = OsmicardTemplateDto.TagValue(
                    name = tagValue.name,
                    value = newValue,
                )
                value.text = newValue
                Effects.blockRow(dom)
            }
            actions.component.iconBtn(MaterialIcons.SYNC).onClick {
                if (!YesNoDialog.show(
                        "Текущее значение будет заполнено значением по умолчанию.\nПолдолжить?",
                        width = 600,
                    ).await()
                ) {
                    return@onClick
                }

                val masterValue = RemoteOsmiService.getMasterTemplateValue(
                    templateName = template.name,
                    valueName = tagValue.name,
                )
                if (masterValue == null) {
                    MessageDialog.showError("Не удаётся получить значение поля поумолчанию")
                    return@onClick
                }

                tagValue = OsmicardTemplateDto.TagValue(
                    name = tagValue.name,
                    value = masterValue,
                )
                value.text = masterValue
                Effects.blockRow(dom)
            }
        }
    }

    private var canceled = false

    init {
        body.cancel {
            canceled = true
            close()
        }
        body.ok {
            canceled = false
            close()
        }
    }

    companion object {
        suspend fun show(
            template: OsmicardTemplateDto,
        ): OsmicardTemplateDto? {
            val d = TemplateEditDialog(template)
            d.show()
            return if (d.canceled) {
                null
            } else {
                d.buildResult()
            }
        }
    }
}
