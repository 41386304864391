package org.tlsys.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun Loading(modal: Boolean = false) {
    Div(attrs = {
        classes("valign-wrapper")
        if (modal) {
            classes("loading")
        }
    }) {
        Div(attrs = {
            style {
                textAlign("center")
                width(100.percent)
            }
        }) {
            Div(attrs = { classes("preloader-wrapper", "small", "active") }) {
                Div(attrs = { classes("spinner-layer", "spinner-green-only") }) {
                    Div(attrs = { classes("circle-clipper", "left") }) {
                        Div(attrs = { classes("circle") })
                    }
                    Div(attrs = { classes("gap-patch") }) {
                        Div(attrs = { classes("circle") })
                    }
                    Div(attrs = { classes("circle-clipper", "right") }) {
                        Div(attrs = { classes("circle") })
                    }
                }
            }
        }
    }
}
