package org.tlsys.goods.views

import kotlinx.browser.document
import kotlinx.dom.removeClass
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.await
import org.tlsys.core.AsyncIterator
import org.tlsys.css.CSS
import org.tlsys.goods.DTOClassifier
import org.tlsys.goods.DTOGood
import org.tlsys.goods.DTOGoodGroup
import org.tlsys.goods.api.GoodsService
import org.tlsys.goods.dialogs.GoodEditDialog
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.tlsys.ui.on
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.ScrollController
import pw.binom.web.layout.FlexLayout
import pw.binom.web.layout.appendTo
import kotlin.js.Promise

private val ICON_STYLE = CSS.genName()
private val GOOD_STYLE = CSS.genName()
private val GROUP_STYLE = CSS.genName()
private val BUTTON_STYLE = CSS.genName()
private val SELECTED = CSS.genName()
private val SELECTABLE = CSS.genName()
private val NAME = CSS.genName()
private val CODE = CSS.genName()

private val ROW_STYLE = CSS.style {
//    minHeight = 56.px

    ">.$ICON_STYLE" then {
        width = 24.px
        height = 24.px
        margin = "0px 10px 0px 10px"
        color = "rgba(0,0,0,0.6)"
    }

    ".$SELECTABLE" then {
        cursor = "pointer"
        userSelect = "none"
    }

    ".$SELECTED" then {
        backgroundColor = "rgba(0, 0, 0, 0.2)"
//        "td" {
//            backgroundColor = "rgba(0, 0, 0, 0.2)"
//        }
    }

    ".$GROUP_STYLE>.$ICON_STYLE" then {
//        backgroundImage = "url(${js("folder_png")})"//TODO заменить folder_png
    }

    ".$GOOD_STYLE>.$ICON_STYLE" then {
//        backgroundImage = "url(${js("file_png")})"//TODO заменить file_png
    }

    ">.$NAME" then {
        textAlign = "left"
    }

    ">.$CODE" then {
        textAlign = "center"
    }
}.name

class GroupView(
    var groupIt: AsyncIterator<DTOGoodGroup>,
    var goodIt: AsyncIterator<DTOGood>,
    var classifierIt: AsyncIterator<DTOClassifier>,
    val rootUrl: String?,
    val deleteGroup: ((DTOGoodGroup) -> Promise<Boolean>)?,
    val deleteGood: ((DTOGood) -> Promise<Boolean>)?,
    val selectOption: ((Any) -> Unit)?,
    val allowRename: Boolean,
    val emptyText: String,
) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    init {
        afterConstruct()
    }

    val layout = FlexLayout(this, direction = FlexLayout.Direction.Column).asParent()

    //    val listView = ListView<Controller<HTMLDivElement>>(emptyText).appendTo(layout, grow = 1, shrink = 1)
    val table = MaterialTable().appendTo(layout, grow = 0, shrink = 0)
    private val scroll = ScrollController(dom)

    init {
        MaterialTable.Header().also {
            MaterialTable.TextColumn("").appendTo(it).widthPx(1)
            MaterialTable.TextColumn("Название").appendTo(it)
            MaterialTable.TextColumn("Код").appendTo(it).widthPx(170)
            MaterialTable.TextColumn("Действия").appendTo(it).widthPx(1)
        }.appendTo(table)
    }

    override suspend fun onStart() {
        super.onStart()
        console.info("GroupView->onStart")
    }

    override suspend fun onStop() {
        console.info("GroupView->onStop")
        super.onStop()
    }

    suspend fun resetSource(
        groupIt: AsyncIterator<DTOGoodGroup>,
        goodIt: AsyncIterator<DTOGood>,
        classifierIt: AsyncIterator<DTOClassifier>,
    ) {
        this.groupIt = groupIt
        this.goodIt = goodIt
        this.classifierIt = classifierIt
        table.clearBody()
        loadItems()
    }

    fun createGroup(group: DTOGoodGroup) {
        val g = GroupRow(group)

        val goodItem = table.rows.find { it is GroupRow }
        if (goodItem == null) {
            table.appendFirstBody(g)
        } else {
            table.appendBodyAfter(row = g, after = goodItem)
        }

        Effects.createItem(g.dom)
        Effects.blockRow(g.dom)
    }

    fun classifierGroup(group: DTOClassifier) {
        val g = ClassifierRow(group)

        val goodItem = table.rows.find { it is GroupRow }
        if (goodItem == null) {
            table.appendFirstBody(g)
        } else {
            table.appendBodyAfter(row = g, after = goodItem)
        }

        Effects.createItem(g.dom)
        Effects.blockRow(g.dom)
    }

    fun createGood(good: DTOGood) {
        val g = GoodRow(good)
        table.appendBody(g)
        Effects.createItem(g.dom)
        Effects.blockRow(g.dom)
//        checkEmpty()
    }

    private suspend fun loadGroup(count: Int = 30): Boolean {
        var remaning = count
        var c = 1000
        while (remaning > 0 && c > 0) {
            c--
            if (!groupIt.hasNext()) return false

            val group = groupIt.next()

            val g = GroupRow(group)
            table.appendBody(g)
            remaning--
        }
        return true
    }

    private suspend fun loadGood(count: Int = 30): Boolean {
        var remaning = count
        var c = 1000
        while (remaning > 0 && c > 0) {
            c--
            if (!goodIt.hasNext()) return false

            val group = goodIt.next()

            val g = GoodRow(group)
            table.appendBody(g)
            remaning--
        }
        return true
    }

    private suspend fun loadClassifier(count: Int = 30): Boolean {
        console.info("loadClassifier($count)")
        var remaning = count
        var c = 1000
        while (remaning > 0 && c > 0) {
            c--
            console.info("loadClassifier...")
            if (!classifierIt.hasNext()) return false

            val group = classifierIt.next()

            val g = ClassifierRow(group)
            table.appendBody(g)
            remaning--
        }
        return true
    }

    private var lastScroll = 0
    private var lastSelected: ItemRow? = null

    //    private var selected: Controller<HTMLDivElement>? = null
    private var selected2: ItemRow? = null

    private suspend fun loadItems() {
        ProcessBlocker.block("Загрузка товаров") {
            var c = 1000
            while (!scroll.visibleScrollY && c > 0) {
                c--
                if (!loadGroup()) {
                    if (!loadGood()) {
                        if (!loadClassifier()) {
                            break
                        }
                    }
                }
            }
        }
    }

    init {
        onInit {
            loadItems()
            lastScroll = scroll.y
            scroll.addScrollListener {
                if (scroll.endScrollY) {
                    ProcessBlocker.block("Загрузка товаров") {
                        if (!loadGroup()) {
                            if (!loadGood()) {
                                loadClassifier()
                            }
                        }
                    }
                }
            }
        }

        onStart {
            scroll.y = lastScroll
            lastSelected?.let {
                Effects.blockRow(it.dom)
            }
        }
    }

    private val goodsService by Services.byClass(GoodsService::class)

    private sealed class ItemRow : MaterialTable.Row() {
        protected val status =
            MaterialTable.ComponentColumn(Span().addClass("material-icons")).appendTo(this)
        protected val title =
            createTitle()
                .addClass(Styles.SIMPLE_TEXT)
                .appendTo(this)

        protected abstract fun createTitle(): MaterialTable.Column
        protected val code = MaterialTable.TextColumn().addClass(Styles.SIMPLE_TEXT).appendTo(this)
        protected val actions = MaterialTable.ComponentColumn(ActionPlace()).appendTo(this)

        init {
            afterConstruct()
            dom.addClass(ROW_STYLE)
            actions.component.visibleOnHover(this)
        }
    }

    private inner class GroupRow(var group: DTOGoodGroup) : ItemRow() {
        private val editBtn = if (!allowRename) {
            null
        } else {
            actions.component.iconBtn(MaterialIcons.EDIT)
        }
        private val deleteBtn = if (deleteGood == null) {
            null
        } else {
            actions.component.iconBtn(MaterialIcons.DELETE)
        }

        private fun update() {
            val title = title as MaterialTable.LinkColumn
            title.text = when {
                group.name.isEmpty() -> "Название отсутствует"
                group.id.isEmpty() -> "Группа ${group.code} отсутствует"
                else -> group.name
            }
            code.text = group.code
            title.href = "$rootUrl/${group.id}"
        }

        init {
            afterConstruct()
            status.component.text = MaterialIcons.FOLDER.code
            editBtn?.onClick {
                val data = GoodEditDialog.show(
                    title = "Изменение группы",
                    name = group.name,
                    code = group.code,
                    vendorCode = "",
                ).await()
                    ?: return@onClick
                group = goodsService.editGroup(id = group.id.jdto, name = data.name.jdto, code = data.code.jdto).await()
                    ?: TODO()

                update()
                Effects.blockRow(dom)
            }
            deleteBtn?.onClick {
                if (!YesNoDialog.show("Удалить группу ${group.name}?", 310).await()) {
                    return@onClick
                }
                if (ProcessBlocker.block("Удаление группы") {
                        deleteGroup!!(group).await()
                    }.await()
                ) {
                    Effects.removeItem(dom).await()
                    dom.remove()
                } else {
                    MessageDialog.showError("Группа не найдена")
                }
            }
            if (selectOption != null) {
                dom.on("click") {
                    selected2?.dom?.removeClass(SELECTED)
                    selected2?.dom?.addClass(MaterialTable.ROW_BG_STYLE)
                    dom.addClass(SELECTED)
                    dom.removeClass(MaterialTable.ROW_BG_STYLE)
                    selected2 = this
                    selectOption?.invoke(group)
                    lastSelected = this
                    it.preventDefault()
                }
                dom.addClass(SELECTABLE)
            }
            update()
        }

        override fun createTitle(): MaterialTable.Column = MaterialTable.LinkColumn(text = "", href = "")
    }

    private inner class GoodRow(var group: DTOGood) : ItemRow() {
        private val edit = if (!allowRename) {
            null
        } else {
            actions.component.iconBtn(MaterialIcons.EDIT)
        }
        private val delete = if (deleteGood == null) {
            null
        } else {
            actions.component.iconBtn(MaterialIcons.DELETE)
        }

        private fun update() {
            val title = title as MaterialTable.TextColumn
            title.text = group.name.takeIf { group.id != "" } ?: "Товар отсутствует. Код: ${group.code}"
            code.text = group.code
        }

        init {
            afterConstruct()
            status.component.text = (if (group.id == "") MaterialIcons.ERROR else MaterialIcons.INSERT_DRIVE_FILE).code
            edit?.onClick {
                val data = GoodEditDialog.show(
                    title = "Изменение товара",
                    name = group.name,
                    code = group.code,
                    vendorCode = group.vendorCode,
                ).await()
                    ?: return@onClick
                group = goodsService.editGood(
                    id = group.id.jdto,
                    name = data.name.jdto,
                    code = data.code.jdto,
                    vendorCode = data.vendorCode?.jdto,
                ).await()
                    ?: TODO()

                update()
                Effects.blockRow(dom)
            }

            delete?.onClick {
                if (!YesNoDialog.show("Удалить товар ${group.name}?", 310).await()) {
                    return@onClick
                }
                if (ProcessBlocker.block("Удаление товара") {
                        deleteGood!!(group).await()
                    }.await()
                ) {
                    Effects.removeItem(dom).await()
                    dom.remove()
                } else {
                    MessageDialog.showError("Товар не найдена")
                }
            }

            if (selectOption != null) {
                dom.on("click") {
                    selected2?.dom?.removeClass(SELECTED)
                    selected2?.dom?.addClass(MaterialTable.ROW_BG_STYLE)
                    dom.addClass(SELECTED)
                    dom.removeClass(MaterialTable.ROW_BG_STYLE)
                    selected2 = this
                    selectOption?.invoke(group)
                    lastSelected = this
                    it.preventDefault()
                }
                dom.addClass(SELECTABLE)
            }
            update()
        }

        override fun createTitle(): MaterialTable.Column = MaterialTable.TextColumn()
    }

    private inner class ClassifierRow(var group: DTOClassifier) : ItemRow() {
//        private val edit = if (!allowRename) {
//            null
//        } else actions.component.iconBtn(MaterialIcons.EDIT)
//        private val delete = if (deleteGood == null) {
//            null
//        } else actions.component.iconBtn(MaterialIcons.DELETE)

        private fun update() {
            val title = title as MaterialTable.TextColumn
            title.text = group.name.takeIf { group.id != "" } ?: "Товар отсутствует. Код: ${group.code}"
            code.text = group.code
        }

        init {
            afterConstruct()
            status.component.text = (if (group.id == "") MaterialIcons.ERROR else MaterialIcons.INSERT_DRIVE_FILE).code
//            edit?.onClick {
//                val data = GoodEditDialog.show(title = "Изменение классификатора", name = group.name, code = group.code)
//                    .await()
//                    ?: return@onClick
//                group = goodsService.editGood(id = group.id.jdto, name = data.name.jdto, code = data.code.jdto).await()
//                    ?: TODO()
//
//                update()
//                Effects.blockRow(dom)
//            }
//
//            delete?.onClick {
//                if (!YesNoDialog.show("Удалить группу ${group.name}?", 310).await()) {
//                    return@onClick
//                }
//                if (ProcessBlocker.block("Удаление группы") {
//                        deleteGood!!(group).await()
//                    }.await()
//                ) {
//                    Effects.removeItem(dom).await()
//                    dom.remove()
//                } else {
//                    MessageDialog.showError("Группа не найдена")
//                }
//            }

            if (selectOption != null) {
                dom.on("click") {
                    selected2?.dom?.removeClass(SELECTED)
                    selected2?.dom?.addClass(MaterialTable.ROW_BG_STYLE)
                    dom.addClass(SELECTED)
                    dom.removeClass(MaterialTable.ROW_BG_STYLE)
                    selected2 = this
                    selectOption!!(group)
                    lastSelected = this
                    it.preventDefault()
                }
                dom.addClass(SELECTABLE)
            }
            update()
        }

        override fun createTitle(): MaterialTable.Column = MaterialTable.TextColumn()
    }
}
