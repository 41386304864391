package org.tlsys.ui

import org.w3c.dom.HTMLElement


interface Layout<T : HTMLElement> {
    val parent: T
    var visible: Boolean
    fun close()
    val closed: Boolean
}
val HTMLElement.layout: Layout<HTMLElement>
    get() {
        val self_element = this
        var l = js("self_element.LAYOUT")
        if (l == null || l == undefined)
            l = SimpleLayout(this)
        return l
    }

open class SimpleLayout<T : HTMLElement>(parent: T) : Layout<T> {
    private var _closed: Boolean = false
    override fun close() {
        if (closed)
            throw IllegalStateException("Layout already was closed")
        _closed = true
        val dom_element = parent
        js("dom_element.LAYOUT=null")
    }

    override val closed: Boolean
        get() = _closed

    override var visible: Boolean
        get() = parent.style.display != "none"
        set(value) {
            if (value)
                parent.style.display = ""
            else
                parent.style.display = "none"
        }


    private var _parent: T

    init {
        val dom_element = parent
        val g = js("dom_element.LAYOUT")
        if (g != null || g != undefined) {
            g.close()
        }

        this._parent = parent

        val self = this
        js("dom_element.LAYOUT=self")
    }

    override val parent: T
        get() {
            if (closed)
                throw IllegalStateException("Layout was closed")
            return _parent
        }

}