package org.tlsys

import libs.Date
import libs.time
import kotlin.js.Promise

class Cache<K, V>(val ttl: Int = 60 * 1000, val loader: suspend (K) -> V) {
    private inner class Value<V>(val value: Promise<V>, val time: Long)

    private val map = HashMap<K, Value<V>>()

    suspend fun get(key: K): V {
        var g = map[key]
        if (g != null) {
            if (Date().time - g.time > ttl)
                g = null
        }

        if (g == null) {
            g = Value(async2 {
                loader(key)
            }, Date().time)
            map[key] = g
        }
        return g.value.await()
    }
}