package org.tlsys.dto

import org.tlsys.json.*

class ReferenceDto(val member: MemberDTO, val createDate: Long?) : JDTO {
    companion object : JDTOFactory<ReferenceDto> {
        override val type: String
            get() = "ReferenceDto"

        override fun read(node: JsonNode): ReferenceDto =
            ReferenceDto(
                member = MemberDTO.read(node["member"]!!),
                createDate = node["createDate"]?.long(),
            )

        override fun write(obj: ReferenceDto) =
            JsonNode.obj {
                this.set("member", MemberDTO.write(obj.member))
                this.set("createDate", obj.createDate?.json)
            }

    }

    override val factory: JDTOFactory<JDTO>
        get() = asDefault
}