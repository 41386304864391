package org.tlsys.members

import kotlinx.dom.addClass
import org.tlsys.admin.core.PageNavigation
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.AccountsService
import org.tlsys.api.MembersService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.cms.Extensions
import org.tlsys.cms.use
import org.tlsys.dto.AccountDTO
import org.tlsys.gui.InfoPlane
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.then
import org.w3c.dom.HTMLDivElement
import pw.binom.date.DateTime
import pw.binom.web.layout.*
import kotlin.js.Promise

@Deprecated(message = "Not user this. We are use one to one. One member can't have several accounts")
class AccountPage(val accountId: Long) : AbstractPage() {
    override suspend fun getTitle(): String = "Счет №$accountId"

    lateinit var account: Promise<AccountDTO>
    private val accountsService by Services.byClass(AccountsService::class)
    private val membersService by Services.byClass(MembersService::class)

    private fun reloadAccount() {
        account = async2 { accountsService.getAccountById(accountId.jdto, true.jdto).await()!! }
    }

    init {
        reloadAccount()
    }

    // val account by accountLazy

    val member = async2 { membersService.getMemberById(this.account.await().memberId.jdto).await()!! }
    // val member by memberLazy

    val headLayout = FlexLayout.div {
        this.parent.classList.add("infoHead")
        contentLayout.add(this.parent) {
            direction = FlexLayout.Direction.Row
            shrink = 0
        }
    }

    val infoPlane = InfoPlane().apply {
        headLayout.add(this.dom)
    }
    val head_allowadd = infoPlane.add("Начисление") {
        val body = it
        accountsService.getAccountById(accountId.jdto, true.jdto).promise.then {
            body.innerHTML = if (it!!.allowSub) "Разрешено" else "Запрещено"
        }
    }

    val head_allowsub = infoPlane.add("Списание") {
        val body = it
        accountsService.getAccountById(accountId.jdto, true.jdto).promise.then {
            body.innerHTML = if (it!!.allowSub) "Разрешено" else "Запрещено"
        }
    }

    private val navigation by Services.byClass(PageNavigation::class)

    val head_MemberName = infoPlane.add("ФИО", {
        val body = it
        it.classList.add("link")
        async2 {
            val m = member.await()
            body.innerHTML = "${m.lastName} ${m.firstName}${if (m.middleName === null) "" else " ${m.middleName}"}"
        }
        it.onclick = {
            async2 {
                navigation.go("/${MembersPage.URI}/m${member.await().id}")
            }
        }
    })

    val head_allow = infoPlane.add("Доступно") {
        val body = it
        // it.classList.add("link")
        async2 {
            val m = account.await()
            body.innerHTML = "${m.info!!.allow}"
        }
    }

    val head_notAllow = infoPlane.add("Недоступно") {
        val body = it
        // it.classList.add("link")
        async2 {
            val m = account.await()
            body.innerHTML = "${m.info!!.notAllow}"
        }
    }

    val head_status = infoPlane.add("Статус") {
        val body = it
        // it.classList.add("link")
        async2 {
            val m = account.await()
            body.innerHTML = if (m.enabled) "Включен" else "Выключен"
        }
    }

    val actions = FlexLayout.div {
        this.parent.classList.add("actions")
        headLayout.add(this.parent) {
            direction = FlexLayout.Direction.Column
            shrink = 0
            add(
                Span(MemberInfoPage.CHANGE_BALANS).dom.apply {
                    addClass("link")
                    onclick = { _ ->
                        async2 {
                            ChangeBalansDialog.show { amount, start, end, description ->
                                accountsService.changeAmount(
                                    id = accountId.jdto,
                                    amount = amount.jdto,
                                    start = start.jdto,
                                    end = end?.let { it + DateTime.nowTime }?.jdto,
                                    terminalId = null,
                                    description = description.jdto,
                                )
                                // accountLazy.reset()
                                reloadAccount()
                                head_allow.draw()
                                head_notAllow.draw()
                                true
                            }
                        }
                    }
                },
            )
            add(
                Span(MemberInfoPage.CLEAR_AMOUNT).dom.apply {
                    classList.add("link")
                    style.color = "#d0021b"
                    onclick = {
                        async2 {
                            if (YesNoDialog.show(text = "Удаление всех бонусов с счета.\nВы уверены?", width = 300)
                                    .await()
                            ) {
                                accountsService.cleanAmount(accountId.jdto).await()
                            }
                        }
                        undefined
                    }
                },
            )
            add(
                Span("Удалить счет").dom.apply {
                    classList.add("link")
                    style.color = "#d0021b"
                },
            )
        }
    }

    val tabs = Tabs().apply {
        dom.style.paddingTop = 8.px
        contentLayout.add(dom) {
            shrink = 0
            grow = 0
        }
    }

    internal fun addExTab(name: String, fragment: Fragment<HTMLDivElement>) {
        tabs.add({ it.innerHTML = name }) {
            view.set2(fragment)
        }
    }

    val view = ComponentView().apply {
        dom.style.paddingTop = 8.px
        contentLayout.add(dom) {
            shrink = 1
            grow = 1
        }
    }

    val cardFragment = CardOfAccountFragment(accountId)

    override suspend fun onStart() {
        super.onStart()
        // AccountPage.activeAccountPage = this

        tabs.add({ it.innerHTML = "Карты" }) {
            view.set2(cardFragment)
        }
        /*
        for (t in AccountPage.tabs)
            addExTab(name = t.name, fragment = t.body(accountId))
*/
        tabs.active = 0

        head_MemberName.draw()
        head_status.draw()
        head_allowadd.draw()
        head_allowsub.draw()
    }

    override suspend fun onStop() {
        super.onStop()
        /*
        if (AccountPage.activeAccountPage === this)
            AccountPage.activeAccountPage = null
            */
    }

    init {
        Extensions.accountExtensionFragments.use(dom) { el ->
            val tab = tabs.add({ it.innerHTML = el.value.name }) {
                view.set2(el.value.fragment(accountId))
            }
            el.close {
            }
        }
    }
}
