package org.tlsys.file

import org.tlsys.json.*

class RFile(
        val id: JLong,
        val name: JString,
        val ext: JString,
        val mimeType: JString,
        val expiration: JLong?
) : JDTO {
    protected constructor() : this(0L.jdto, JString.EMPTY, JString.EMPTY, JString.EMPTY, null)

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : AutoJDTOFactory<RFile>(RFile::class)
}