package org.tlsys.externalscripts

import org.tlsys.admin.SettingItem
import org.tlsys.admin.ui.*
import org.tlsys.api.ExternalScriptsService
import org.tlsys.await
import org.tlsys.cms.click
import org.tlsys.dto.ExternalScriptDto
import org.tlsys.ui.*
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class ExternalScriptSettings : AbstractPage() {

    object ExternalScriptSettingItem : SettingItem {
        override val uri: String
            get() = "externalscripts"
        override val title: String
            get() = "Внешнии скрипты"

        override suspend fun getPage(): Page = ExternalScriptSettings()
    }

    override suspend fun getTitle(): String = ExternalScriptSettingItem.title

    private val tooBar = ActionPanel()
        .appendTo(contentLayout, grow = 0, shrink = 0)
        .apply {
            addSpace()
        }

    private val div = DivLayout(direction = FlexLayout.Direction.Column).appendTo(contentLayout, grow = 1, shrink = 1)
    private val table = MaterialTable().appendTo(div.layout, grow = 0, shrink = 0)

    private val scrollController = ScrollController(div.dom)
    private val addDropdown = tooBar.button("Добавить")

    init {
        addDropdown.onClick {
            val gateway = ExternalScriptDialog.show(
                new = true,
                dto = null,
            ) ?: return@onClick

            ProcessBlocker.block("Создание шлюза") {
                ExternalScriptsService.create(gateway)
            }.await()

            val row = ExternalScriptItem(gateway)
            row.appendTo(table)
            Effects.createAndBlankItem(row.dom)
        }
        val head = MaterialTable.Header().appendTo(table)
        MaterialTable.TextColumn("Название").appendTo(head)
        MaterialTable.TextColumn("Адрес").appendTo(head).widthPx(1)
        MaterialTable.TextColumn("Действия").appendTo(head).widthPx(1)
    }

    override suspend fun onInit() {
        super.onInit()
        ExternalScriptsService.getList().forEach {
            ExternalScriptItem(it).appendTo(table)
        }
        super.onInit()
    }

    inner class ExternalScriptItem(var script: ExternalScriptDto) : MaterialTable.Row() {
        val name = MaterialTable.TextColumn().appendTo(this)
        val url = MaterialTable.TextColumn().appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        init {
            actions.component.visibleOnHover(this)
            refresh()

            actions.component.iconBtn(MaterialIcons.CONTENT_COPY).click {
                val r = ExternalScriptDialog.show(
                    new = true,
                    dto = script,
                ) ?: return@click

                ProcessBlocker.block("Добавление внешнего скрипта") {
                    ExternalScriptsService.create(r)
                }.await()
                val row = ExternalScriptItem(r)
                row.appendTo(table)
                Effects.createAndBlankItem(row.dom)
            }
            actions.component.iconBtn(MaterialIcons.EDIT).click {
                val r = ExternalScriptDialog.show(
                    new = false,
                    dto = script,
                ) ?: return@click

                ProcessBlocker.block("Обновление внешнего скрипта") {
                    ExternalScriptsService.update(r)
                }.await()
                this.script = r
                refresh()
                Effects.blockRow(dom)
            }
            actions.component.iconBtn(MaterialIcons.DELETE).click {
                if (!YesNoDialog.show("Удалить внешний скрипт ${script.name}?", width = 500).await()) {
                    return@click
                }
                ExternalScriptsService.delete(script.id)
                Effects.removeItem(dom)
                removeSelf()
            }
        }

        fun refresh() {
            name.text = script.name
            url.text = script.url.toString()
        }
    }
}
