package org.tlsys.admin

import org.w3c.dom.*
import org.w3c.dom.css.CSSStyleDeclaration
import pw.binom.web.Component

/**
 * Добавляет класс элементу и возвращает его
 *
 * @param className имя класса, который требуется добавить
 * @return текущий элемент [this]
 */
//fun <E : HTMLElement, T : Component<E>> T.addClass(className: String): T {
//    dom.classList.add(className)
//    return this
//}

fun <E : HTMLElement, T : Component<E>> T.removeClass(className: String): T {
    dom.classList.remove(className)
    return this
}

/**
 * Добавляет класс элементу и возвращает его
 *
 * @param className имя класса, который требуется добавить
 * @return текущий элемент [this]
 */
fun <E : HTMLElement, T : Component<E>> T.addClass(className: String): T {
    dom.classList.add(className)
    return this
}

fun <E : HTMLElement, T : Component<E>> T.addClass(classes: Array<String>): T {
    classes.forEach {
        addClass(it)
    }
    return this
}

val Element.style: CSSStyleDeclaration
get()=this.asDynamic().style

val HTMLElement.childElements: List<HTMLElement>
    get() {
        val o = ArrayList<HTMLElement>()
        for (i in 0.until(childNodes.length)) {
            val g = childNodes.get(i)
            if (g is HTMLElement)
                o += g
        }
        return o
    }

fun <T : HTMLAnchorElement> T.href(url: String): T {
    this.href = url
    return this
}

fun <T : HTMLAnchorElement> T.innerText(text: String): T {
    this.innerText = text
    return this
}