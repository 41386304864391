package org.tlsys.cms.events

import org.tlsys.async2
import org.tlsys.core.Closeable

class EventElement {

    private val listeners = ArrayList<() -> Unit>()

    fun on(reset: Boolean = false, f: () -> Unit): Closeable {
        listeners.add(f)
        val f = Closeable {
            listeners.remove(f)
        }

        if (reset) {
            f()
        }

        return f
    }

    fun one(f: suspend () -> Unit): Closeable {
        var e: Closeable? = null
        e = on {
            async2 {
                f()
            }
            e!!.close()
        }
        return e
    }

    fun dispatch() {
        for (f in listeners)
            f()
    }
}

class EventElementValue<V> {

    private val listeners = ArrayList<(V) -> Unit>()

    fun on(f: (V) -> Unit): Closeable {
        listeners.add(f)
        return Closeable {
            listeners.remove(f)
        }
    }

    fun dispatch(value: V) {
        for (f in listeners)
            f(value)
    }
}
