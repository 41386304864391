package org.tlsys.admin.ui

import kotlinx.browser.document
import libs.*
import org.tlsys.DateOnly
import org.tlsys.admin.addClass
import org.tlsys.admin.form.MutableValidated
import org.tlsys.admin.form.SimpleValidator
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.form.Validator
import org.tlsys.admin.ui.DateSelector.Companion.LEFT_BTN
import org.tlsys.admin.ui.DateSelector.Companion.RIGHT_BTN
import org.tlsys.admin.ui.DateSelector.Companion.SELECT_BTN
import org.tlsys.async2
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.EmptyComponent
import pw.binom.web.layout.*
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import kotlin.math.floor

class DateSelector(
        year: Int,
        month: Int,
        day: Int,
        val minYear: Int? = null,
        val maxYear: Int? = null,
        val minMonth: Int? = null,
        val maxMonth: Int? = null) : AbstractComponent<HTMLDivElement>(), PopupWindow.Selector, MutableValidated<DateSelector.Value> {
    override val dom: HTMLDivElement = document.createDiv()
    private val componentHelper = ComponentStorageHelper()


    override suspend fun onStart() {
        componentHelper.start()
    }

    override suspend fun onStop() {
        componentHelper.stop()
    }

    companion object {
        val SELECT_BTN = CSS.style {
//            fontFamily = Styles.DEFAULT_TEXT_FONT
//            fontSize = Styles.DEFAULT_FONT_SIZE.px
            borderRadius = 5.px
            outline = "none"
            border = "none"
            backgroundColor = "transparent"

            ":hover" then {
                backgroundColor = "#ccc"
            }
            padding = "5px 0px 5px 0px"
            transitionProperty = "background-color"
            transitionDuration = "200ms"
            cursor = "pointer"
        }

        val LEFT_BTN = CSS.style {
            //backgroundImage = "url('${js("array_left_png")}')"
            backgroundRepeat = "no-repeat"
            backgroundPosition = "center"
            width = 30.px
            transitionProperty = "background-color"
            transitionDuration = "200ms"
            cursor = "pointer"
        }

        val RIGHT_BTN = CSS.style {
            //backgroundImage = "url('${js("array_right_png")}')"
            backgroundRepeat = "no-repeat"
            backgroundPosition = "center"
            width = 30.px
            transitionProperty = "background-color"
            transitionDuration = "200ms"
            cursor = "pointer"
        }

        suspend fun show(
            x: Float, y: Float, year: Int,
            month: Int,
            day: Int,
            minYear: Int? = null,
            maxYear: Int? = null,
            minMonth: Int? = null,
            maxMonth: Int? = null
        ) {
            PopupWindow.show(
                x = x.toInt() - 2,
                y = y.toInt() - 2,
                selector = DateSelector(
                    year = year,
                    month = month,
                    day = day,
                    minYear = minYear,
                    maxYear = maxYear,
                    minMonth = minMonth,
                    maxMonth = maxMonth,
                )
            )
        }
    }

    override fun onValidChange(listener: ValidListener) = validator.onValidChange(listener)
    private val validator = SimpleValidator<Value>()
    override fun addValidator(validator: Validator<Value>) = this.validator.addValidator(validator)
    class Value(val year: Int, val month: Int, val day: Int)

    override val valid: Boolean
        get() = validator.valid


    val view = ViewPlace(EmptyComponent()).also {
        dom.appendChild(it.dom)
        async2 {
            componentHelper.element(it)
        }
    }

    private var _year = year
    private var _month = month
    private var _day = day

    val year: Int
        get() = _year

    val month: Int
        get() = _month

    val day: Int
        get() = _day
    val lowLevel: F1
    var date: DateOnly
        get() =
            DateOnly.create(year = year, month = month + 1, day = day)
        set(value) {
            lowLevel.year = value.year
            lowLevel.month = value.month - 1
            lowLevel.day = value.day
        }

    init {
        lowLevel = F1(
            year = year, month = month, day = day,
            selectMonth = { full ->
                val mm = F2(year = full.year, month = full.month,
                    selected = { mm ->
                        full.month = mm.month!!
                        full.year = mm.year
                        view.set2(full)
                    }, yearSelect = { mm ->
                        val yy = F3(year = mm.year, selected = { yy ->
                            mm.year = yy.yearSelected
                            view.set2(mm)
                        })
                        view.set2(yy)

                    })
                view.set2(mm)
            }, selected = { full ->
                _year = full.year
                _month = full.month
                _day = full.day
                validator.updateValue(Value(year = full.year, month = full.month, day = full.day))
            })
        validator.updateValue(Value(year = year, month = month, day = day))
        view.set2(lowLevel)
    }
}

class F1(year: Int, month: Int, day: Int, val selectMonth: (F1) -> Unit, val selected: (F1) -> Unit) :
    AbstractFragment<HTMLDivElement>(document.createDiv()) {
    private var _month = month
    var month: Int
        get() = _month
        set(it) {
            _month = it
            dateBtn.text = "${monthName(this.month)}, ${this.year}"
            rebuildDays()
        }
    private var _year = year
    var year: Int
        get() = _year
        set(it) {
            _year = it
            dateBtn.text = "${monthName(this.month)}, ${this.year}"
            rebuildDays()
        }
    private var _day = day
    var day: Int
        get() = _day
        set(it) {
            _day = it
            days!!.day = it
        }
    val layout = FlexLayout(dom).apply {
        direction = FlexLayout.Direction.Column
    }

    val buttonsLayout = FlexLayout.div().also {
        it.direction = FlexLayout.Direction.Row
        layout.add(it.parent)
    }

    val leftMonth = Button("chevron_left").also {
        it.addClass(SELECT_BTN.name)
        it.addClass(LEFT_BTN.name)
        buttonsLayout.add(it.dom)
    }.addClass("material-icons")

    val dateBtn = Button().also {
        it.dom.classList.add(SELECT_BTN.name)
        buttonsLayout.add(it.dom) {
            grow = 1
        }
    }.addClass(Styles.SIMPLE_TEXT)

    val rightMonth = Button("chevron_right").also {
        it.dom.classList.add(SELECT_BTN.name)
        it.dom.classList.add(RIGHT_BTN.name)
        buttonsLayout.add(it.dom)
    }.addClass("material-icons")

    val view = ComponentView().also {
        dom.appendChild(it.dom)
    }

    private var days: DayOfMonthPicker? = null

    fun rebuildDays() {
        if (days != null) {
            layout.remove(days!!.dom)
        }

        val lastDay = Date().also {
            it.year = year
            it.month = month
        }.lastDate
        days = DayOfMonthPicker(year = year, month = month, day = if (day > lastDay) lastDay else day).also {
            layout.add(it.dom)
        }

        days!!.onChange {
            if (days!!.day != null) {
                _day = days!!.day!!
                selected(this)
            }
        }
    }

    init {
        /*
        fun refreshBtn() {
            dateBtn.text = "${monthName(this.month)}, ${this.year}"
        }
*/
        leftMonth.onClick {
            this._month--

            if (this._month < 0) {
                this._year--
                this._month = 11
            }
            //refreshBtn()
            this.year = _year
        }

        rightMonth.onClick {
            this._month++

            if (this._month >= 12) {
                this._year++
                this._month = 0
            }
            //refreshBtn()
            //rebuildDays()
            this.year = _year
        }

        dateBtn.onClick {
            selectMonth(this)
        }

        //refreshBtn()
        this.year = year
        rebuildDays()


    }
}

private class F2(year: Int, month: Int?, val yearSelect: (F2) -> Unit, val selected: (F2) -> Unit) : AbstractFragment<HTMLDivElement>(document.createDiv()) {
    private var _year = year
    val layout = FlexLayout(dom).apply {
        direction = FlexLayout.Direction.Column
    }

    val buttonsLayout = FlexLayout.div().also {
        it.direction = FlexLayout.Direction.Row
        layout.add(it.parent)
    }

    val leftMonth = Button("chevron_left").also {
        it.dom.classList.add(SELECT_BTN.name)
        it.dom.classList.add(LEFT_BTN.name)
        buttonsLayout.add(it.dom)
    }.addClass("material-icons")

    val dateBtn = Button().also {
        it.dom.classList.add(SELECT_BTN.name)
        buttonsLayout.add(it.dom) {
            grow = 1
        }
    }.addClass(Styles.SIMPLE_TEXT)

    val rightMonth = Button("chevron_right").also {
        it.dom.classList.add(SELECT_BTN.name)
        it.dom.classList.add(RIGHT_BTN.name)
        buttonsLayout.add(it.dom)
    }.addClass("material-icons")

    val monthControl = MonthPicker(itemOnRow = 3, selected = month).also {
        layout.add(it.dom)
    }

    var month: Int?
        get() = monthControl.selected
        set(it) {
            monthControl.selected = it
        }

    var year: Int
        get() = _year
        set(it) {
            _year = it
            dateBtn.text = this.year.toString()
        }

    init {

        leftMonth.onClick {
            this.year--
        }

        rightMonth.onClick {
            this.year++
        }
        this.year = year
        dateBtn.onClick {
            yearSelect(this)
        }

        monthControl.onChange {
            if (monthControl.selected != null)
                selected(this)
        }
    }
}

private class F3(year: Int, val selected: (F3) -> Unit) : AbstractFragment<HTMLDivElement>(document.createDiv()) {
    private var _year = year
    val layout = FlexLayout(dom).apply {
        this.direction = FlexLayout.Direction.Column
    }

    val buttonsLayout = FlexLayout.div().also {
        it.direction = FlexLayout.Direction.Row
        layout.add(it.parent)
    }

    val leftMonth = Button("chevron_left").also {
        it.dom.classList.add(SELECT_BTN.name)
        it.dom.classList.add(LEFT_BTN.name)
        buttonsLayout.add(it.dom)
    }.addClass("material-icons")

    val dateBtn = Button().also {
        it.dom.classList.add(SELECT_BTN.name)
        buttonsLayout.add(it.dom) {
            grow = 1
        }
    }.addClass(Styles.SIMPLE_TEXT)

    val rightMonth = Button("chevron_right").also {
        it.dom.classList.add(SELECT_BTN.name)
        it.dom.classList.add(RIGHT_BTN.name)
        buttonsLayout.add(it.dom)
    }.addClass("material-icons")

    private var yearControl: YearPicker? = null

    private fun rebuildYears() {
        if (yearControl != null)
            layout.remove(yearControl!!.dom)
        yearControl = YearPicker(itemOnRow = 3, selected = year, start = floor(year / 12.0).toInt() * 12, count = 12)
        layout.add(yearControl!!.dom)

        yearControl!!.onChange {
            selected(this)
        }
    }

    var year: Int
        get() = _year
        set(it) {
            _year = it
            val from = floor(year / 12.0) * 12
            dateBtn.text = "${from}-${from + 12 - 1}"
        }

    val yearSelected: Int
        get() = yearControl!!.selected!!

    init {

        leftMonth.onClick {
            this.year -= 12
            rebuildYears()
        }

        rightMonth.onClick {
            this.year += 12
            rebuildYears()
        }
        this.year = year
        rebuildYears()

        //selected
/*
        monthControl.onChange {
            if (monthControl.selected != null)
                selected(this)
        }
        */
    }
}

/*
class DateSelector(year: Int, month: Int, day: Int, val minYear: Int? = null, val maxYear: Int? = null, val minMonth: Int? = null, val maxMonth: Int? = null) : AbstractController<HTMLDivElement>(document.createDiv()), PopupWindow.Selector, MutableValidated<DateSelector.Value> {
    override fun onValidChange(listener: ValidListener): Closeable = validator.onValidChange(listener)

    class Value(val year: Int, val month: Int, val day: Int)

    private val validator = SimpleValidator<Value>()
    override fun addValidator(validator: Validator<Value>) = this.validator.addValidator(validator)

    override val valid: Boolean
        get() = validator.valid

    private var _year = run {
        var gg = year
        if (minYear != null && gg < minYear)
            gg = minYear
        if (maxYear != null && gg > maxYear)
            gg = maxYear
        gg
    }

    private var _month = run {
        var gg = month
        if (minYear != null && _year == minYear && minMonth != null)
            gg = minMonth
        if (maxYear != null && _year == maxYear && maxMonth != null)
            gg = maxMonth
        gg
    }

    var year: Int
        get() = _year
        set(it) {
            if (it == year)
                return
            _year = it
            buildYears()
            yearSelector.redraw()
        }

    val layout = FlexLayout(dom).apply {
        direction = FlexLayout.Direction.Column
    }
    val buttonSelectors = FlexLayout.div().apply {
        direction = FlexLayout.Direction.Row
        layout.add(parent)
        this.parent.style.marginBottom = 10.px
    }

    private val yearModel = StaticListModel<Int> {
        it.dom.innerText = it.data.toString()
    }

    var month: Int
        get() = _month
        set(it) {
            if (it == month)
                return
            _month = it
            monthSelector.redraw()
        }
    private var _day: Int = day
    var day: Int
        get() = _day
        set(it) {
            _day = it
            if (days != null) {
                days!!.day = it
            }
            validator.updateValue(Value(year = this@DateSelector.year, month = this@DateSelector.month, day = this@DateSelector.day))
        }

    private val monthModel = StaticListModel<Int> {
        it.dom.innerText = monthName(it.data)
    }

    private val monthSelector = Combobox(model = monthModel).apply {
        dom.style.minWidth = 80.px
        buttonSelectors.add(dom) {
            grow = 0
            shrink = 0
        }
        redraw()

        this.dom.addEventListener("change", {
            if (this.selected >= 0) {
                _month = monthModel[selected]
                buildMonth()
                buildDays()
                validator.updateValue(Value(year = this@DateSelector.year, month = this@DateSelector.month, day = this@DateSelector.day))
            }
        })
    }

    private val yearSelector = Combobox(model = yearModel).apply {
        dom.style.minWidth = 60.px
        buttonSelectors.add(dom) {
            grow = 0
            shrink = 0
        }
        redraw()

        this.dom.addEventListener("change", {
            if (this.selected >= 0) {
                _year = yearModel[selected]
                buildYears()
                buildMonth()
                buildDays()
                validator.updateValue(Value(year = this@DateSelector.year, month = this@DateSelector.month, day = this@DateSelector.day))
            }
        })
    }

    var days: DayOfMonthPicker? = null

    private fun buildDays() {
        if (days != null) {
            days!!.dom.parentElement!!.removeChild(days!!.dom)
        }
        val maxDay = Date().also {
            it.year = this@DateSelector.year
            it.month = this@DateSelector.month
        }.lastDate
        days = DayOfMonthPicker(year = this@DateSelector.year, month = this@DateSelector.month, day = if (this@DateSelector.day <= maxDay) this@DateSelector.day else null)
        days!!.onChange {
            if (days!!.day != null)
                this._day = days!!.day!!
        }
        layout.add(days!!.dom)
    }

    private fun buildMonth() {

        val min = run {
            if (minYear != null && year == minYear && minMonth != null)
                minMonth
            else
                0
        }

        val max = run {
            if (maxYear != null && year == maxYear && maxMonth != null)
                maxMonth
            else
                11
        }

        monthModel.clear()

        var ii = -1
        var iii = 0
        for (i in min..max) {
            monthModel.add(i)
            if (ii == -1 && i == _month)
                ii = iii
            iii++
        }
        monthSelector.selected = ii
    }

    private fun buildYears() {
        yearModel.clear()

        var min = _year - 5
        if (minYear != null && min < minYear)
            min = minYear

        var max = _year + 5
        if (maxYear != null && max > maxYear)
            max = maxYear

        var ii = -1
        var iii = 0
        for (i in min..max) {
            yearModel.add(i)
            if (ii == -1 && i == _year)
                ii = iii
            iii++
        }
        yearSelector.selected = ii
    }

    init {
        buildYears()
        buildMonth()
        buildDays()
        yearSelector.redraw()

        validator.updateValue(Value(year = this.year, month = this.month, day = this.day))
    }

}
*/