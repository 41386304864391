package org.tlsys.core

class Stack<T> {
    private var array: Array<T> = js("([])")

    fun <T> Array<T>.push(value: T) {
        this.asDynamic().push(value)
    }

    fun <T> Array<T>.unshift(value: T) {
        this.asDynamic().unshift(value)
    }

    fun <T> Array<T>.pop(): T = this.asDynamic().pop()
    fun <T> Array<T>.shift(): T = this.asDynamic().shift()

    fun push(value: T) {
        array.push(value)
    }

    fun pop(): T {
        if (array.size <= 0) {
            throw NoSuchElementException()
        }
        return array.pop()
    }

    fun remove(value: T): Boolean {
        val p = array.indexOf(value).takeIf { it >= 0 } ?: return false
        array.asDynamic().splice(p, 1)
        return true
    }

    fun popFirst(): T {
        if (array.size <= 0) {
            throw NoSuchElementException()
        }
        return array.shift()
    }

    val top: T?
        get() = if (isEmpty) null else array[length - 1]

    fun pushFirst(value: T) {
        array.unshift(value)
    }

    val length: Int
        get() = array.size

    val isEmpty: Boolean
        get() = length == 0

    val isNotEmpty: Boolean
        get() = !isEmpty
}
