package org.tlsys.dto.plugins

import org.tlsys.json.*

class DTOPlugin(val uid: String, val description: String, val enabled: Boolean, val valid: Boolean) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTOPlugin> {
        override val type: String
            get() = "DTOPlugin"

        override fun read(node: JsonNode) = DTOPlugin(
                uid = node["uid"]!!.string(),
                description = node["description"]!!.string(),
                enabled = node["enabled"]!!.boolean(),
                valid = node["valid"]!!.boolean()
        )

        override fun write(obj: DTOPlugin) =
                JsonNode.obj()
                        .set("uid", obj.uid.json)
                        .set("description", obj.description.json)
                        .set("enabled", obj.enabled.json)
                        .set("valid", obj.valid.json)
    }
}

class DTOPluginNeedDependency(val uid: String, val version: String?) : RuntimeException("PluginNeedDependency $uid:$version"), JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTOPluginNeedDependency> {
        override val type: String
            get() = "DTOPluginNeedDependency"

        override fun read(node: JsonNode) = DTOPluginNeedDependency(
                uid = node["uid"]!!.string(),
                version = node["version"]?.string()
        )

        override fun write(obj: DTOPluginNeedDependency) = JsonNode.obj()
                .set("uid", obj.uid.json)
                .set("version", obj.version?.json)
    }
}

class DTOPluginStartException(val uid: String, val from: Throwable) : RuntimeException("Can't start plugin \"$uid\""), JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTOPluginStartException> {
        override val type: String
            get() = "DTOPluginStartException"

        override fun read(node: JsonNode) = DTOPluginStartException(
                uid = node["uid"]!!.string(),
                from = node["from"]?.let { JsonFactory.read<JDTO>(it) } as Throwable
        )

        override fun write(obj: DTOPluginStartException) = JsonNode.obj()
                .set("uid", obj.uid.json)
                .set("from", JsonFactory.write(obj.from as JDTO))
    }
}