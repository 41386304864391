package org.tlsys.core.promotion

import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.TagPanel
import org.tlsys.admin.ui.withLabel
import org.tlsys.api.MemberTagService
import org.tlsys.json.jdto
import org.tlsys.promotion.dto.TagFilterDto
import org.tlsys.ui.*
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.web.layout.*

open class ConditionsTab : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    val includeTags =
        TagPanel(readOnly = false).withLabel("Включить для меток").appendTo(layout, grow = 0, shrink = 0)
    val excludeTags =
        TagPanel(readOnly = false).withLabel("Исключить для меток").appendTo(layout, grow = 0, shrink = 0)
    private val memberTagService by Services.byClass(MemberTagService::class)
    val tagFilter: TagFilterDto
        get() = TagFilterDto(
            includeTags = includeTags.content.list.map { it.tag.id },
            excludeTags = excludeTags.content.list.map { it.tag.id },
        )

    suspend fun setTags(filter: TagFilterDto) {
        val tags =
            memberTagService.getTagList((filter.includeTags + filter.excludeTags).map { it.jdto }.jdto()).await()
                .associateBy { it.id }
        includeTags.content.addTag(
            filter.includeTags.map { tags[it]!! },
        )
        excludeTags.content.addTag(
            filter.excludeTags.map { tags[it]!! },
        )
    }
}
