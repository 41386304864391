package org.tlsys.cms.ui


import org.tlsys.admin.ui.Button
import org.tlsys.css.CSS
import org.tlsys.px

class FloatButton(text: String = "", image: String? = null, width: Int? = null, height: Int? = null) : Button(text) {

    var width: Int?
        get() {
            if (dom.style.width.endsWith("px"))
                return dom.style.width.substring(0, dom.style.width.length - 2).toInt()
            else
                return null
        }
        set(it) {
            if (it == null)
                dom.style.width = ""
            else
                dom.style.width = it.px
        }

    var height: Int?
        get() {
            if (dom.style.height.endsWith("px"))
                return dom.style.height.substring(0, dom.style.height.length - 2).toInt()
            else
                return null
        }
        set(it) {
            if (it == null)
                dom.style.height = ""
            else
                dom.style.height = it.px
        }

    var image: String?
        get() {
            if (dom.style.backgroundImage.startsWith("url(")) {
                return dom.style.backgroundImage.substring(4, dom.style.backgroundImage.length - 1)
            } else {
                return null
            }
        }
        set(it) {
            dom.style.backgroundImage = "url('$it')"
        }

    init {
        dom.classList.add(floatButtonStyle.name)
        if (image != null)
            this.image = image
        if (width != null)
            this.width = width

        if (height != null)
            this.height = height
    }

    companion object {
        val floatButtonStyle = CSS.style {
            cursor = "pointer"
            backgroundColor = "transparent"
            backgroundRepeat = "no-repeat"
            backgroundPosition = "center"
            border = "none"
            outline = "none"
        }
    }
}
