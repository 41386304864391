package org.tlsys.core

import org.tlsys.json.*
import kotlin.js.JsName

interface PayDebtService {
    class CompanyPay(
            val amount: JLong,
            val date: JLong
    ) : JDTO {
        protected constructor() : this(0L.jdto, 0L.jdto)

        companion object : AutoJDTOFactory<CompanyPay>(CompanyPay::class)

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }

    class CompanyDebt(
            val amount: JLong,
            val date: JLong,
            val description: JString
    ) : JDTO {
        protected constructor() : this(0L.jdto, 0L.jdto, JString.EMPTY)

        companion object : AutoJDTOFactory<CompanyDebt>(CompanyDebt::class)

        override val factory: JDTOFactory<JDTO>
            get() = asDefault
    }

    @JsName("getPays")
    fun getPays(max: JInt, offset: JLong): AsyncResult<JList<CompanyPay>>

    @JsName("getDebt")
    fun getDebt(max: JInt, offset: JLong): AsyncResult<JList<CompanyDebt>>

    @JsName("getBalance")
    fun getBalance(): AsyncResult<JLong>
}