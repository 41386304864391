package org.tlsys.lk

import org.tlsys.admin.core.Remote
import org.tlsys.admin.core.Services
import org.tlsys.lk.admin.dto.CARDPR_SERVICE
import org.tlsys.lk.admin.dto.INVAKATOR
import org.tlsys.lk.admin.dto.RemoteCardPrAdminService

val RemoteCardPR by lazy {
    val remote by Services.byClass(Remote::class)
    remote.getService(RemoteCardPrAdminService::class, invokator = INVAKATOR, setvice = CARDPR_SERVICE)
}
