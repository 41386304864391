package org.tlsys.core.clientImport

import org.tlsys.admin.core.Services
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.form.Validated
import org.tlsys.admin.ui.*
import org.tlsys.api.FileService
import org.tlsys.api.upload
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.core.Closeable
import org.tlsys.file.RFile
import org.tlsys.px
import org.w3c.dom.DataTransfer
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.files.get
import org.w3c.xhr.FormData
import pw.binom.web.Component
import pw.binom.web.layout.*

object Master {
    class NextStepAbortException : RuntimeException()
    interface Stage<T : HTMLElement> : Component<T>, Validated {
        val title: String
        suspend fun next(): Stage<out HTMLElement> = throw NextStepAbortException()

        val isFinallyStage: Boolean
            get() = false

        suspend fun finish() {
        }

        val height: Int
    }
}

class MasterDialog(startStage: Master.Stage<out HTMLElement>) : TitleDialog() {
    val body = SaveCancelBody(okText = "Готово", cancelText = "Закрыть")
        .appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private var stage = startStage

    val previousButton = BaseButton("Назад").also {
        body.buttonsLayout.addBefore(it.dom, body.okBtn.dom) {
            grow = 0
            shrink = 0
        }
    }
//    val nextButton = ConfirmButton("Далее").appendTo(body.buttonsLayout, grow = 0, shrink = 0)

    val view = ComponentView().appendTo(layout)

    private val stack = ArrayList<Master.Stage<out HTMLElement>>()

    public override suspend fun show() {
        super.show()
    }

    private fun refrashState() {
        body.okBtn.text = if (stage.isFinallyStage) "Готово" else "Далее"
        previousButton.enabled = stack.isNotEmpty()
        body.okBtn.enabled = stage.valid
        body.cancelBtn.enabled = !stage.isFinallyStage
        dom.style.apply {
            height = stage.height.px
        }
        title = stage.title
    }

    init {
        stage.onValidChange {
            refrashState()
        }
        view.set2(startStage)
        previousButton.onClick {
            this.stage = stack.removeAt(stack.lastIndex)
            view.set2(stage)
            refrashState()
        }
        body.cancel {
            close()
        }
        body.ok {
            async2 {
                previousButton.enabled = false
                body.okBtn.enabled = false
                stack += stage
                if (stage.isFinallyStage) {
                    try {
                        stage.finish()
                        close()
                    } catch (e: Master.NextStepAbortException) {
                        refrashState()
                    }
                } else {
                    try {
                        stage = stage.next()
                        view.set2(stage)
                        refrashState()
                        stage.onValidChange {
                            refrashState()
                        }
                    } catch (e: Master.NextStepAbortException) {
                        refrashState()
                    }
                }
            }
        }
    }

    override suspend fun onInit() {
        super.onInit()
        refrashState()
    }
}

class OpenFileStage(
    override val title: String,
    fileFilter: (DataTransfer) -> String? = { null },
    description: String?,
    val nextStage: (List<RFile>) -> Master.Stage<out HTMLElement>,
) : FileUploadPlace(description, fileFilter), Master.Stage<HTMLDivElement> {
    override val valid: Boolean
        get() = files.size == 1

    private val fileService by Services.byClass(FileService::class)

    override suspend fun next(): Master.Stage<out HTMLElement> {
        val files = ProcessBlocker.block("Загрузка файла") {
            val d = FormData()
            files.forEach {
                d.append(it.name, it, it.name)
            }
            fileService.upload(d).await()
        }.await()
        return nextStage(files)
    }

    override val isFinallyStage: Boolean
        get() = false

    private var valided = false

    private val listeners = ArrayList<ValidListener>()

    init {
        addFileSelectEventListener {
            valided != valid
            valided = valid
            listeners.forEach {
                it.invoke(valid)
            }
        }
    }

    override fun onValidChange(listener: ValidListener): Closeable {
        listeners += listener
        return Closeable {
            listeners -= listener
        }
    }

    override val height: Int
        get() = 300
}

class ImportDialog : TitleDialog("Импорт клиентов") {

    val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    val view = ComponentView().appendTo(layout)

    private val place = FileUploadPlace(null) {
        when {
            it.files.length != 1 -> "Загрузите один файл"
            !it.files[0]!!.name.lowercase().endsWith(".xlsx") -> "Загрузите файл формата Excel (xlsx)"
            else -> null
        }
    }

    private val fileService by Services.byClass(FileService::class)

    init {
        view.set2(place)
        dom.style.apply {
            width = 500.px
            height = 500.px
        }
        place.addFileSelectEventListener {
            console.info("done! ${place.files.size}")
            if (place.files.size == 1) {
                ProcessBlocker.block("Загрузка файла") {
                    val d = FormData()
                    val f = place.files[0]
                    d.append(f.name, f, f.name)
                    val file = fileService.upload(d).await()
                    view.set2(ExcelImportComponent(file[0]))
                }
                console.info("Файл выбран! ${place.files[0].name}")
            }
        }
    }

    companion object {
        suspend fun show() {
            val excelFilter: (DataTransfer) -> String? = {
                when {
                    it.files.length != 1 -> "Загрузите один файл"
                    !it.files[0]!!.name.lowercase().endsWith(".xlsx") -> {
                        val name = it.files[0]!!.name
                        val i = name.lastIndexOf('.')
                        val ext = if (i == -1) {
                            ""
                        } else {
                            "*.${name.substring(i + 1)}"
                        }
                        "Формат файла $ext не поддерживается.\nЗагрузите файл формата Excel (*.xlsx)"
                    }

                    else -> null
                }
            }
            MasterDialog(
                OpenFileStage(
                    title = "Выберите файл для импорта",
                    fileFilter = excelFilter,
                    description = "Загрузите файл формата Excel (*.xlsx)",
                    nextStage = { ExcelImportComponent(it[0]) },
                ),
            ).show()
            // ImportDialog().show()
        }
    }
}
