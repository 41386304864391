package org.tlsys.admin.ui

import org.tlsys.async2
import org.w3c.dom.HTMLElement
import pw.binom.web.Component

class ViewPlace(view: Component<out HTMLElement>) : Component<HTMLElement> {

    var view: Component<out HTMLElement> = view
        set(value) {
            if (value === field) {
                return
            }
            field.dom.parentElement?.also {
                if (_started) {
                    async2 {
                        field.onStop()
                        it.replaceChild(value.dom, field.dom)
                        value.onStart()
                    }
                } else {
                    it.replaceChild(value.dom, field.dom)
                }
            }
            field = value
        }

    override val dom: HTMLElement
        get() = view.dom
    private var _started = false

    override suspend fun onStart() {
        _started = true
        view.onStart()
    }

    override suspend fun onStop() {
        view.onStop()
        _started = false
    }

    fun set2(view: Component<out HTMLElement>) {
        this.view = view
    }
}
