package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.admin.addClass
import org.tlsys.admin.form.MutableValidated
import org.tlsys.admin.form.SimpleValidator
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.form.Validator
import org.tlsys.async2
import org.tlsys.core.Closeable
import org.tlsys.styles.ButtonStyle
import org.tlsys.styles.DateSelector
import org.tlsys.ui.*
import pw.binom.web.layout.*
import org.w3c.dom.events.MouseEvent
import pw.binom.date.DateTime
import pw.binom.date.format.toDatePattern

private val format = "dd.MM.yyyy HH:mm:ss".toDatePattern()

open class DatetimeEditor(date: pw.binom.date.DateTime) : MutableValidated<DateTime?>, DivComponentWithLayout(
    direction = FlexLayout.Direction.Row,
    alignItems = FlexLayout.AlignItems.Center
) {
    private val span = Span().addClass(Styles.SIMPLE_TEXT).appendTo(layout, grow = 0, shrink = 0)
    private val validator = SimpleValidator<DateTime?>()

    init {
        document.createDiv().appendTo(layout, grow = 1, shrink = 1)
    }

    val icon = Button("today")
        .appendTo(layout, grow = 0, shrink = 0)
        .addClass(ButtonStyle.MATERIAL_BUTTON)
        .addClass(DateSelector.ICON)
    var date: pw.binom.date.DateTime? = date
        set(value) {
            field = value
            validator.updateValue(value)
            refresh()
        }

    fun refresh() {
        span.text = date?.let { format.toString(it) } ?: "не выбрано"
    }

    init {
        addClass(DateSelector.MAIN)
        refresh()
        dom.on("click") {
            it as MouseEvent
            async2 {
                val r = span.dom.getBoundingClientRect()
                this.date = DatetimeSelector.show(
                    x = r.left.toFloat(),
                    y = r.top.toFloat(),
                    date = this.date ?: DateTime.now
                ) ?: return@async2
            }
        }
    }

    override val valid: Boolean
        get() = validator.valid

    override fun onValidChange(listener: ValidListener): Closeable = validator.onValidChange(listener)
    override fun addValidator(validator: Validator<DateTime?>) {
        this.validator.addValidator(validator)
        this.validator.updateValue(date)
    }
}