package org.tlsys.jobs.dialogs

import org.tlsys.ParamUIService
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.jobs.dto.JobDTO
import org.tlsys.jobs.dto.TaskDTO
import org.tlsys.json.JMap
import org.tlsys.json.JString
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*
import kotlin.js.Promise

class JobSettings(script: TaskDTO, job: JobDTO?) :
    TitleDialog(if (job == null) "Новая задача" else "Редактирование задачи") {
    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val validator = MultiValidator()
    private var canceled = false

    private val paramUIService by Services.byClass(ParamUIService::class)

    private val jobTitle = EditText(placeHolder = "Название")
        .text(job?.title?.value ?: script.taskName.value)
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)

    private val params = paramUIService.build(
        settings = script.params.value,
        values = job?.params?.map { it.key.value to it.value.value }?.toMap(),
    )

    init {

        dom.style.width = 600.px
        validator.addValidated(jobTitle)
        params.appendTo(layout, grow = 1, shrink = 1)
        /*
        val factories by Services.listByClass(ParamEditorFactory::class)
        paramMap = script.params.asSequence().map {Завершен успешно
            val e = factories.create(type = it.typeEnum.name, canBeEmpty = it.canBeEmpty.value)?.withLabel(it.title.value)?.appendTo(layout, grow = 0, shrink = 0)
            if (e == null) {
                console.error("Can't find factory for param ${it.typeEnum.name}")
                throw RuntimeException()
            }

            it.id.value to e
        }.toMap()

        async {
            paramMap.forEach {
                val params = job?.params?.entries?.associate { it.key.value to it.value.value }
                it.value.element.paramValue = params?.get(it.key) ?: ""
                validator.addValidated(it.value.element)
            }
        }
        */

        validator.onValidChange {
            body.okBtn.enabled = it
        }

        body.ok {
            canceled = false
            close()
        }

        body.cancel {
            canceled = true
            close()
        }
    }

    override suspend fun onStart() {
        super.onStart()
        jobTitle.focus()
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        fun show(script: TaskDTO, job: JobDTO?) = Promise<JobDTO?> { d, c ->
            val dd = JobSettings(script, job)
            layoutNavigator.show(dd) {
                if (dd.canceled) {
                    d(null)
                }

                d(
                    JobDTO(
                        id = job?.id ?: JString.EMPTY,
                        scriptId = script.id,
                        title = dd.jobTitle.text.jdto,
                        taskName = script.taskName,
                        params = JMap(dd.params.values().asSequence().associate { it.key.jdto to it.value.jdto }),
                        scriptName = script.name,
                    ),
                )
            }
        }
    }
}
