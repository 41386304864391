package org.tlsys.cms

import org.tlsys.core.start
import org.w3c.dom.Document
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLElement
import pw.binom.web.Component

// interface Controller<out T : Element> {
//    val dom: T
//
//    fun onClick(handler: (Event) -> Unit) = dom.on("click", f = handler)
// }
//
// fun <T : HTMLElement> T.con(): Controller<T> = this.asDynamic().CONTROL

// abstract class AbstractController<T : HTMLElement>(override val dom: T) : Controller<T> {
//    init {
//        val g = dom.asDynamic().CONTROL
//        if (g != null && g != undefined)
//            throw IllegalStateException("Dom element already have controller")
//        dom.asDynamic().CONTROL = this
//    }
// }

fun Document.link() = createElement("a").unsafeCast<HTMLAnchorElement>()

fun <V : HTMLElement, T : Component<V>> T.click(f: suspend (T) -> Unit): T {
    dom.on("click") {
        f.start(this)
    }
    return this
}
