package org.tlsys.admin.ui

import org.tlsys.admin.events.EventElement
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.tlsys.ui.on
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import kotlinx.browser.document
import pw.binom.web.AbstractComponent

open class Checkbox(label: String = "", checked: Boolean = false, enabled: Boolean = true) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    companion object Styles1 {
        val ENABLED = CSS.genName()
        val CHECKED = CSS.genName()

        val style = CSS.style {
            userSelect = "none"
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontSize = 14.px
            textAlign = "left"
            position = "relative"
            fontWeight = "500"
            color = "#9e9e9e"
            padding = "5px 0px 5px 45px"
            lineHeight = 25.px
            height = 25.px
            transition = "200ms all ease-in-out"
            backgroundColor = "transparent"

            ".${ENABLED}" then {
                cursor = "pointer"
                color = "#424a54"

                ".${CHECKED}:before" then {
                    borderColor = "transparent"
                    borderRight = "2px solid #26a69a"
                    borderBottom = "2px solid #26a69a"
                    top = "1px"
                    left = "15px"
                    width = "12px"
                    height = "22px"
                    transform = "rotate(40deg)"
                }
                ":not(.${CHECKED}):before" then {
                    border = "2px solid #5a5a5a"
                    width = 18.px
                    height = 18.px
                    borderRadius = 1.px
                    top = 7.px
                    left = 10.px
                    transform = "rotate(0deg)"
                }
            }


            ":not(.${ENABLED})" then {
                cursor = "default"
                color = "#A9A9B8"

                ".${CHECKED}:before" then {
                    borderColor = "transparent"
                    borderRight = "2px solid rgba(0,0,0,0.26)"
                    borderBottom = "2px solid rgba(0,0,0,0.26)"
                    top = "1px"
                    left = "15px"
                    width = "12px"
                    height = "22px"
                    transform = "rotate(40deg)"
                }
                ":not(.${CHECKED}):before" then {
                    border = "2px solid rgba(0,0,0,0.26)"
                    width = 18.px
                    height = 18.px
                    borderRadius = 1.px
                    top = 7.px
                    left = 10.px
                    transform = "rotate(0deg)"
                }

            }
            ":before" then {
                content = "''";
                width = 20.px
                height = 20.px
                float = "left"
                display = "block"
                marginRight = 7.px
                marginTop = (0).px
                boxSizing = "border-box"
                position = "absolute"
                transition = "200ms all ease-in-out"



                ":not(.${ENABLED})" then {
                    //@include grayscale();
                }
            }
        }
    }

    private var _enabled: Boolean = !enabled
    protected var listenClickEvents = true

    init {
        dom.classList.add(style.name)
        dom.on("click") {
            if (listenClickEvents && this.enabled) {
                this.checked = !this.checked
            }
        }
    }


    var enabled: Boolean
        get() = _enabled
        set(it) {
            if (it == _enabled)
                return
            _enabled = it

            if (it)
                dom.classList.add(ENABLED)
            else
                dom.classList.remove(ENABLED)
        }


    private var _checked = false
    private fun updateStatus() {
        if (_checked)
            dom.classList.add(CHECKED)
        else
            dom.classList.remove(CHECKED)
    }

    fun setCheckedForce(checked: Boolean) {
        _checked = checked
        updateStatus()
    }

    val EVENT_CHANGED = EventElement()

    var checked: Boolean
        get() = _checked
        set(it) {
            if (it != checked) {
                setCheckedForce(it)
            }

            dom.dispatchEvent(Event("change"))
            EVENT_CHANGED.dispatch()
        }

    var label: String
        get() = dom.innerHTML
        set(it) {
            dom.innerHTML = it
        }

    init {
        this.label = label
        this.checked = checked
        this.enabled = enabled
    }

    fun checked(value: Boolean): Checkbox {
        this.checked = value
        return this
    }
}