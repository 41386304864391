package org.tlsys.admin

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.DateDuration
import org.tlsys.Percent

@Serializable
data class BonusPercentConfig(
    @SerialName("bonus_percent")
    val bonusPercent: Percent,
    val start: DateDuration,
    val end: DateDuration?,
)
