package org.tlsys.utils

import org.tlsys.core.start
import kotlin.js.Promise
import kotlin.reflect.KProperty

class promise<T>(val f: suspend () -> T) {

    private var value: Promise<T>? = null

    operator fun getValue(thisRef: Any?, property: KProperty<*>): Promise<T> {
        if (value == null) {
            value = f.start()
        }

        return value!!
    }
}
