package org.tlsys.admin.ui

import org.tlsys.admin.core.Services
import org.tlsys.api.ShopsService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.dto.DTOTerminal
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.web.layout.*

class Terminal(val tag: DTOTerminal, readOnly: Boolean) : PanelItem<DTOTerminal>(tag, readOnly) {

    init {
        setText(tag.name)
    }
}

open class TerminalPanel(val readOnly: Boolean) : DivComponentWithLayout(direction = FlexLayout.Direction.Row, alignItems = FlexLayout.AlignItems.Center) {
    val list = ArrayList<Terminal>()
    private val shopsService by Services.byClass(ShopsService::class)
    protected suspend open fun addTag(tags: List<DTOTerminal>) {
        tags.forEach {
            val t = Terminal(it, readOnly).appendTo(layout, grow = 0, shrink = 0)
            t.eventClose.on {
                async2 {
                    removeTag(t)
                }
            }
            Effects.createInline(t.dom, 300)
            list += t
        }
    }

    init {
        layout.apply {
            direction = FlexLayout.Direction.Row
            wrap = FlexLayout.Wrap.Enable
        }

        if (!readOnly) {
            val addBtn = AddTagBtn().appendTo(layout, grow = 0, shrink = 0)
            addBtn.dom.style.order = "1"

            addBtn.eventClick.on {
                async2 {
                    val ids = TerminalSelectorDialog.show(hide = this.list.map { it.tag.id })
                            ?: return@async2
                    addTag(ids)
                }
            }
        }
    }

    protected open suspend fun removeTag(tag: Terminal) {
        list -= tag
        async2 {
            Effects.removeInline(tag.dom, 300).await()
            tag.dom.remove()
        }
    }

    fun addTag(tag: DTOTerminal) {
        val t = Terminal(tag, readOnly).appendTo(layout, grow = 0, shrink = 0)
        t.eventClose.on {
            async2 {
                removeTag(t)
            }
        }
        list += t
    }
}