package org.tlsys.core.report

import kotlinx.browser.window
import libs.*
import org.tlsys.admin.core.Services
import org.tlsys.admin.core.join
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.api.ProcessService
import org.tlsys.await
import org.tlsys.core.api.ReportService
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

object DocumentReport : ReportPage.ReportConfigDialog {
    override val title = "По Документам"
    override suspend fun make() {
        DocumentConfigDialog.show()
    }
}

class DocumentConfigDialog : TitleDialog("Отчет по документам") {
    private val body = SaveCancelBody("Создать", "Закрыть")
        .appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val date = DivLayout(direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)
    private val fromDate = DateEditText(placeHolder = "Начало отчета").appendTo(date.layout, grow = 1)
    private val endDate = DateEditText(placeHolder = "Конец отчета").appendTo(date.layout, grow = 1)

    private val price = ElementGroup("Сумма чека", FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)
    private val minPrice = EditText(placeHolder = "Минимальная сумма").appendTo(price.layout, grow = 1)
    private val maxPrice = EditText(placeHolder = "Максимальная сумма").appendTo(price.layout, grow = 1)

    private val cardPrAndSexFilter =
        DivLayout(direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)

    private val sexGroup = RadioBox.Group()
    private val sexMGroup = ElementGroup("Пол").appendTo(cardPrAndSexFilter.layout, grow = 1, shrink = 1)
    private val sexMale = RadioBox("Мужской").appendTo(sexMGroup.layout).group(sexGroup)
    private val sexFamale = RadioBox("Женский").appendTo(sexMGroup.layout).group(sexGroup)
    private val sexNotFiltered = RadioBox("Не фильтровать").appendTo(sexMGroup.layout).group(sexGroup).checked()

    private val cardPrGroup = RadioBox.Group()
    private val cardPrMGroup = ElementGroup("CardPr").appendTo(cardPrAndSexFilter.layout, grow = 1, shrink = 1)
    private val cardPrInstalled = RadioBox("Установлено").appendTo(cardPrMGroup.layout).group(cardPrGroup)
    private val cardPrNotInstalled = RadioBox("Не установлено").appendTo(cardPrMGroup.layout).group(cardPrGroup)
    private val cardPrNotFiltered =
        RadioBox("Не фильтровать").appendTo(cardPrMGroup.layout).group(cardPrGroup).checked()

    private val ageGroup = ElementGroup("Возраст").appendTo(cardPrAndSexFilter.layout, grow = 1, shrink = 1)

    private val minAge = EditText(placeHolder = "Минимальный")
        .appendTo(ageGroup.layout, grow = 1)
        .textValidator(IntegerValidator.FORMAT or TextValidators.EMPTY)
    private val maxAge = EditText(placeHolder = "Максимальный")
        .appendTo(ageGroup.layout, grow = 1)
        .textValidator(IntegerValidator.FORMAT or TextValidators.EMPTY)

    private val includeTags = TagPanel(readOnly = false)
        .withLabel("Фильтр по метокам")
        .appendTo(layout)
    private val terminalFilter = TerminalPanel(readOnly = false)
        .withLabel("Фильтр по терминалам")
        .appendTo(layout)

    private val reportService by Services.byClass(ReportService::class)
    private val processService by Services.byClass(ProcessService::class)

    init {
        maxPrice.textValidator((DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2)) or TextValidators.BLANK)
        minPrice.textValidator(
            Validator {
                if (TextValidators.BLANK.valid(it).isValid) {
                    return@Validator valid()
                }
                (DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2)).valid(it)?.let { return@Validator it }
                if (!maxPrice.valid) {
                    return@Validator invalid("Максимальная дата не указана не верно")
                }

                if (!maxPrice.text.isBlank()) {
                    if (maxPrice.text.toDouble() < it.toDouble()) {
                        return@Validator invalid("Минимальная сумма чека должна быть меньше максимальной")
                    }
                }

                valid()
            },
        )
        maxPrice.eventChange.on {
            minPrice.forceRefreshValid()
        }
        endDate.date(Date())
        fromDate.date(Date().also { it.month-- })
        fromDate.dateValidator(
            Validator {
                if (endDate.date == null) {
                    return@Validator invalid("Не указана дата окончания")
                }
                if (it.time > endDate.date!!.time) {
                    return@Validator invalid("Дата начала больше даты конца")
                }
                valid()
            },
        )
        endDate.eventChange.on {
            fromDate.forceRefreshValid()
        }
        val validator = MultiValidator(fromDate, endDate, maxPrice, minPrice)
        validator.onValidChange {
            body.okBtn.enabled = it
        }
        body.ok {
            ProcessBlocker.block("") {
                val process = reportService.genReportByDocuments(
                    dateStart = fromDate.date!!.startOfDay.timeUTC.jdto,
                    dateEnd = endDate.date!!.endOfDay.timeUTC.jdto,
                    terminalFilter = terminalFilter.element.list.map { it.tag.id.jdto }.jdto(),
                    memberFilter = null,
                    filterByTags = includeTags.element.list.map { it.tag.id.jdto }.jdto(),
                    maxPrice = maxPrice.text.toDoubleOrNull()?.let { (it * 100).toLong() }?.jdto,
                    minPrice = minPrice.text.toDoubleOrNull()?.let { (it * 100).toLong() }?.jdto,
                    cardPr = when {
                        cardPrInstalled.checked -> true
                        cardPrNotInstalled.checked -> false
                        else -> null
                    }?.jdto,
                    sex = when {
                        sexMale.checked -> true
                        sexFamale.checked -> false
                        else -> null
                    }?.jdto,
                    minAge = minAge.text.takeIf { it.isNotBlank() }?.toInt()?.jdto,
                    maxAge = maxAge.text.takeIf { it.isNotBlank() }?.toInt()?.jdto,
                ).await()

                val process1 = processService.getProcess(process).await()!!
                val file = process1.join(1_000).await()!!

                console.info("URL:")
                console.info(file)
                window.open(file, "_blank")
                MessageDialog.showInfo("Отчет сгенерирован!")
            }
        }

        body.cancel {
            close()
        }
    }

    companion object {
        suspend fun show() {
            val d = DocumentConfigDialog()
            d.show()
        }
    }
}
