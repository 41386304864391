package org.tlsys.navigation

internal sealed class PathAction {
    class UpLevel : PathAction()
    class GoTo(val name: String) : PathAction()
}

internal fun fixRelative(url: String): String {
    var s = ""
    val items = url.split('/').toMutableList()
    val it = items.listIterator()

    var newItems = ArrayList<String>()

    while (it.hasNext()) {
        val g = it.next()
        if (g == ".") {
            if (!it.hasNext()) {
                newItems.add("")
            }
            continue
        }

        if (g == "") {
            if (!it.hasPrevious()) {
                throw RuntimeException("Can't get back path!")
            }
            if (newItems.isNotEmpty()) {
                newItems.removeAt(newItems.lastIndex)
            }
            if (!it.hasNext()) {
                newItems.add("")
            }
            continue
        }
        if (g.isEmpty() && !it.hasNext()) {
            continue
        }
        newItems.add(g)
    }
    if (newItems.isNotEmpty() && newItems[0].isNotEmpty()) {
        newItems.add(0, "")
    }

    return newItems.joinToString("/")
}

internal fun navigateRelative(oldPath: String, newPath: String): List<PathAction> {
    val out = ArrayList<PathAction>()

    navigateRelative(oldPath, newPath, { out += PathAction.UpLevel() }, { out += PathAction.GoTo(it) })
    return out
}

private fun buildRelative(oldPath: String, newPath: String): String {
    var s = oldPath

    navigateRelative(oldPath, newPath, { s += "/" }, { s += "/$it" })
    return s
}

private fun navigateRelative(oldPath: String, newPath: String, up: () -> Unit, goto: (String) -> Unit) {
    val old = fixRelative(oldPath)
    val new = newPath

    val oldItems = old.split('/') // .toMutableList()
    val newItems = new.split('/') // .toMutableList()

    var s = ""
    var min = minOf(oldItems.size, newItems.size) - 1
    FIRST@ for (i in 0..min) {
        if (oldItems.size - 1 == i) {
            var from = i
            if (oldItems[i] == newItems[i]) {
                from++
            } else {
                up()
                s += ".1./"
            }
            for (j in from until newItems.size) {
                s += "${newItems[j]}/"
                goto(newItems[j])
            }
            break@FIRST
        }

        if (newItems.size - 1 == i) { // если новый путь кончился
            var from = i
            if (oldItems[i] == newItems[i]) {
                from++
            } else {
                // up()
                // s += ".2./"
            }
            for (ITERATOR in from..oldItems.size - 1) {
                up()
                s += ".4./"
            }
            if (from == i) {
                s += "${newItems[newItems.size - 1]}/"
                goto(newItems[newItems.size - 1])
            }
            break@FIRST
        }

        if (oldItems[i] == newItems[i]) {
            continue@FIRST
        } else {
            for (j in i..oldItems.size - 1) {
                up()
                s += ".5./"
            }
            for (j in i..newItems.size - 1) {
                s += "${newItems[j]}/"
                goto(newItems[j])
            }
            break@FIRST
        }
    }

    // console.info("RELATIVE CHANGE = $s")
}

private fun test_PathUtils() {
    fun asEquals(ex: String, ac: String) {
        if (ex != ac) {
            throw RuntimeException("exceptid <$ex>, but actual <$ac>")
        }
    }

    fun asEquals(ex: Int, ac: Int) {
        if (ex != ac) {
            throw RuntimeException("exceptid <$ex>, but actual <$ac>")
        }
    }

    fun asTrue(value: Boolean) {
        if (!value) {
            throw RuntimeException("exceptid <false>, but actual <true>")
        }
    }

    fun asGoTo(c: PathAction, name: String) {
        asTrue(c is PathAction.GoTo)
        c as PathAction.GoTo
        asEquals(name, c.name)
    }

    fun asUp(c: PathAction) {
        asTrue(c is PathAction.UpLevel)
    }

    fun fromRoot1() {
        val o = navigateRelative("/", "/mm")
        asEquals(o.size, 1)
        asGoTo(o[0], "mm")
    }

    fun fromRoot2() {
        val o = navigateRelative("", "/mm/gg")
        asEquals(o.size, 2)
        asGoTo(o[0], "mm")
        asGoTo(o[1], "gg")
    }

    fun toRoot() {
        val o = navigateRelative("/a1/a2/a3", "")
        asEquals(o.size, 3)
        asUp(o[0])
        asUp(o[1])
        asUp(o[2])
    }

    fun toOtherViaRoot1() {
        val o = navigateRelative("/a1", "/a2")
        asEquals(o.size, 2)
        asUp(o[0])
        asGoTo(o[1], "a2")
    }

    fun toOtherViaRoot2() {
        val o = navigateRelative("/a1", "/a2/a3")
        asEquals(o.size, 3)
        asUp(o[0])
        asGoTo(o[1], "a2")
        asGoTo(o[2], "a3")
    }

    fun toOtherViaRoot3() {
        val o = navigateRelative("/a1/a2", "/a3/a4")
        asEquals(o.size, 4)
        asUp(o[0])
        asUp(o[1])
        asGoTo(o[2], "a3")
        asGoTo(o[3], "a4")
    }

    fun test_fix_relative() {
        asEquals(fixRelative("/ROOT/./a1"), "/ROOT/a1")
        asEquals(fixRelative("/ROOT/"), "/ROOT")
        asEquals(fixRelative("ROOT/"), "/ROOT")
        asEquals(fixRelative("ROOT"), "/ROOT")
        asEquals(fixRelative("/"), "")
    }

    fun test_to_lower_and_other() {
        val o = navigateRelative("/a1/a2", "/a3")
        asEquals(o.size, 3)
        asUp(o[0])
        asUp(o[1])
        asGoTo(o[2], "a3")
    }

    fun test_to_lower_parent() {
        val o = navigateRelative("/a1/a2", "/a1")
        asEquals(o.size, 1)
        asUp(o[0])
    }

    fromRoot1()
    fromRoot2()
    toRoot()
    toOtherViaRoot1()
    toOtherViaRoot2()
    toOtherViaRoot3()
    test_fix_relative()

    test_to_lower_and_other()
    test_to_lower_parent()
    console.info("PathUtils Test is DONE without errors")
}
