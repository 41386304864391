package org.tlsys.lk.pages

import org.tlsys.admin.cross.RemoteOsmiService
import org.tlsys.admin.cross.getAllEvents
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.core.AsyncIterator
import org.tlsys.lk.OsmicardEventDto
import org.tlsys.lk.dialogs.EmmitOsmicardEventDialog
import org.tlsys.lk.emmitEvent
import org.tlsys.ui.*
import pw.binom.date.iso8601
import pw.binom.web.Listener
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class OsmicardEventComponent : DivComponentWithLayout() {
    private val top = DivLayout().appendTo(layout, grow = 0, shrink = 0)
    private val serialSearch = EditText(placeHolder = "Поиск").appendTo(top.layout)
    private val emmitBtn = AddButton().appendTo(top.layout, grow = 0, shrink = 0)
        .onClick {
            async2 {
                val event = EmmitOsmicardEventDialog.show() ?: return@async2
                RemoteOsmiService.emmitEvent(event)
                EventRow(event).appendFirstTo(table)
            }
        }
    private val list = DivLayout().appendTo(layout)
    private val scroll = ScrollController(list.dom)
    private val table = MaterialTable().appendTo(list.layout)
    private lateinit var iterator: AsyncIterator<OsmicardEventDto>

    init {
        layout.direction = FlexLayout.Direction.Column
        serialSearch.eventEnter.on {
            async2 {
                updateIterator()
            }
        }
    }

    override suspend fun onStart() {
        super.onStart()
        updateIterator()
    }

    private suspend fun updateIterator() {
        iterator = RemoteOsmiService.getAllEvents(
            pageSize = 25,
            serial = serialSearch.text.takeIf { it.isNotBlank() },
        )
        table.clearBody()
        preload()
    }

    private var endListener: Listener? = null
    private suspend fun preload() {
        console.info("preload osmicard events")
        endListener?.stopListen()

        while (!scroll.visibleScrollY && iterator.hasNext()) {
            val element = iterator.next()
            EventRow(element).appendTo(table)
        }
        endListener = scroll.addScrollListener {
            async2 {
                if (iterator.hasNext()) {
                    val element = iterator.next()
                    EventRow(element).appendTo(table)
                }
            }
        }
    }

    init {
        MaterialTable.Header().also {
            MaterialTable.TextColumn("Карта").appendTo(it)
            MaterialTable.TextColumn("Тип события").appendTo(it)
            MaterialTable.TextColumn("Дата").appendTo(it)
        }.appendTo(table)
    }

    private class EventRow(event: OsmicardEventDto) : MaterialTable.Row() {
        init {
            console.info("Event: ", event)
            console.dir(event)
        }

        private val serial = MaterialTable.TextColumn(event.serial).appendTo(this)
        private val event = MaterialTable.TextColumn(event.event.code).appendTo(this)
        private val date = MaterialTable.TextColumn(event.createdAt.iso8601()).appendTo(this)
    }
}
