package org.tlsys.admin

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.DateDuration
import org.tlsys.MoneyValue

@Serializable
data class TagsByPaymentConfig(
    @SerialName("include_tags")
    val includeTags: List<Long>,
    @SerialName("exclude_tags")
    val excludeTags: List<Long>,
    val records: List<Record>,
    @SerialName("pay_period")
    val payPeriod: DateDuration?,
    @SerialName("all_period")
    val allPeriod: Boolean,
) {
    @Serializable
    data class Record(
        val amount: MoneyValue,
        @SerialName("add_tags")
        val enableTags: List<Long>,

        @SerialName("remove_tags")
        val disableTags: List<Long>,
    )
}
