package org.tlsys.security

import kotlinx.browser.localStorage
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.GroupService
import org.tlsys.cms.IntStorage
import org.tlsys.dto.auth.GroupDTO
import org.tlsys.json.jdto
import org.tlsys.then
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.Component
import pw.binom.web.layout.*

/**
 * Created by Ермаков Игорь Александрович (email: igor.yermakov94@yandex.ru) on 17.08.2017.
 */
class EditGroupPage(val groupId: Long) : AbstractPage() {
    override suspend fun getTitle(): String = if (group == null) {
        "Группа №$groupId"
    } else {
        "Группа ${group!!.name}"
    }

    private val groupService by Services.byClass(GroupService::class)
    var group: GroupDTO? = null
    var groupFrag: GroupPermissionsFragment? = null
    val actionPanel = ActionPanel().appendTo(contentLayout, grow = 0, shrink = 0)
    private val tabs = Tabs().appendTo(actionPanel, FlexLayout.FlexItem.AlignSelf.Normal)
    val view = ComponentView().appendTo(contentLayout, grow = 1, shrink = 1)

    var ACTIVE_TAB by IntStorage(storage = localStorage, name = "security_group_active_tab")

    fun refresh() {
        groupService.getGroupById(groupId.jdto).promise.then {
            group = it
            eventTitleChange.dispatch()
            if (firstRun) {
                if (group != null) {
                    groupFrag = GroupPermissionsFragment(group!!)
                    addTab(fragment = groupFrag!!, name = GroupPermissionsFragment.NAME, index = 0)
                    addTab(fragment = GroupPropertiesFragment(group!!), name = GroupPropertiesFragment.NAME, index = 1)
                    tabs.active = ACTIVE_TAB ?: 0
                    firstRun = false
                }
            }
        }
    }

    private fun addTab(fragment: Fragment<HTMLDivElement>, name: String, index: Int) {
        tabs.add({ it.innerText = name }) {
            ACTIVE_TAB = index
            view.set2(fragment)
        }
    }

    private fun addTab(fragment: Component<HTMLDivElement>, name: String, index: Int) {
        tabs.add({ it.innerText = name }) {
            ACTIVE_TAB = index
            view.set2(fragment)
        }
    }

    var firstRun = true

    init {
    }

    override suspend fun onStart() {
        super.onStart()
        refresh()
    }
}
