package org.tlsys.admin.ui

import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

typealias ListRender<T> = (ListModelImp.RenderPoint<T>) -> Unit

interface ListModel {

    val size: Int
    fun item(index: Int): Any
    fun isEnabled(index: Int): Boolean
    fun render(index: Int, value: Any, dom: HTMLDivElement, selected: Boolean, enabled: Boolean)
}

fun <T> listModel(list: List<T>, render: (T) -> String = { it?.toString() ?: "null" }) =
        StaticListModel<T> { it.dom.innerText = render(it.data) }.also {
            it.addAll(list)
        }

open class StaticListModel<T>(private val render: (Render<T>) -> Unit) : ListModel, List<T> {
    override fun contains(element: T): Boolean = data.contains(element)
    override fun containsAll(elements: Collection<T>): Boolean = data.containsAll(elements)
    override fun indexOf(element: T): Int = data.indexOf(element)
    override fun isEmpty(): Boolean = data.isEmpty()
    override fun iterator(): Iterator<T> = data.iterator()
    override fun lastIndexOf(element: T): Int = data.lastIndexOf(element)
    override fun listIterator(): ListIterator<T> = data.listIterator()
    override fun listIterator(index: Int): ListIterator<T> = data.listIterator(index)
    override fun subList(fromIndex: Int, toIndex: Int): List<T> = data.subList(fromIndex, toIndex)
    override fun item(index: Int): Any = get(index) as Any
    class Render<out T>(val data: T, val index: Int, val dom: HTMLElement, val selected: Boolean)

    private val data = ArrayList<T>()

    override val size: Int
        get() = data.size

    override fun get(index: Int): T = data[index]!!
    override fun isEnabled(index: Int): Boolean = true
    fun add(item: T) = data.add(item)
    fun addAll(items: Collection<T>) = data.addAll(items)
    fun clear() = data.clear()
    fun removeAt(index: Int) = data.removeAt(index)
    fun remove(value: T) = data.remove(value)

    override fun render(index: Int, value: Any, dom: HTMLDivElement, selected: Boolean, enabled: Boolean) {
        render(Render(data = value as T, dom = dom, index = index, selected = selected))
    }

}

class ListModelImp<T>(val sizeProvider: () -> Int,
                      val dataProvider: (Int) -> T,
                      val enabledProvider: (Int) -> Boolean = { true },
                      val renderrer: ListRender<T>) : ListModel {
    override fun isEnabled(index: Int) = enabledProvider(index)

    override val size: Int
        get() = sizeProvider()

    override fun item(index: Int) = dataProvider(index) as Any

    override fun render(index: Int, value: Any, dom: HTMLDivElement, selected: Boolean, enabled: Boolean) {
        renderrer(RenderPoint(index = index, data = value as T, dom = dom, selected = selected, enabled = enabled))
    }

    class RenderPoint<T>(val index: Int, val data: T, val dom: HTMLDivElement, val selected: Boolean, val enabled: Boolean)
}