package org.tlsys.admin

import kotlinx.serialization.Serializable

@Serializable
enum class GatewayProvider(val title: String, val type: Type) {
    SMSC(
        title = "SMSC",
        type = Type.PHONE_SMS,
    ),

    SMS_RU(
        title = "SMS.RU",
        type = Type.PHONE_SMS,
    ),

    INTERNAL_SMTP(
        title = "SMTP Клиент",
        type = Type.EMAIL,
    ),

    INTERNAL_TELEGRAM(
        title = "Telegram",
        type = Type.TELEGRAM,
    ),

    MTC_CONNECT(
        title = "МТС Коннект",
        type = Type.PHONE_SMS,
    ),
    ;

    enum class Type {
        PHONE_SMS,
        EMAIL,
        TELEGRAM,
    }
}
