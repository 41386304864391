package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.dom.removeClass
import org.tlsys.addClass
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.events.EventElementValue
import org.tlsys.admin.form.*
import org.tlsys.core.Closeable
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.DivComponentWithLayout
import org.tlsys.ui.on
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.events.KeyboardEvent
import pw.binom.web.layout.appendTo

private val ERROR = CSS.genName()
private val STYLE = CSS.style {
    ">textarea" then {
        backgroundColor = "transparent"
        border = "none"
        outline = "none"
        fontFamily = Styles.DEFAULT_TEXT_FONT
        fontSize = Styles.DEFAULT_FONT_SIZE.px
        borderBottom = "1px solid #BCBCBC"
        marginTop = 10.px
        boxShadow = "none"
        transition = "border-bottom-width 300ms ease, box-shadow 300ms ease"
        ":focus" then {
            boxShadow = "0 0 0 1px #26a69a"
//            borderBottomColor = "#26a69a"
        }
    }

    ".$ERROR" then {
        ">textarea" then {
            borderBottomColor = "#F44336"
            ":focus" then {
                boxShadow = "0 1px 0 0 #F44336"
            }
        }
    }

//    height = 42.px
    position = "relative"
    padding = "5px 10.5px 5px 10.5px"
}.name

class TextareaInput(readOnly: Boolean = false, text: String = "") : DivComponentWithLayout(), Validated {
    val editor = document.createElement("textarea").unsafeCast<HTMLTextAreaElement>()
    var text
        get() = editor.value
        set(value) {
            editor.value = value
        }

    var enabled: Boolean
        get() = !editor.disabled
        set(value) {
            editor.disabled = !value
        }

    var textValidator: Validator<String>? = null

    open val isValid: Boolean
        get() = textValidator?.valid(text).isNullOrValid

    private var previceValid = false
    val eventValidChange = EventElementValue<Boolean>()
    val eventChange = EventElement()
    val eventEnter = EventElement()
    protected open fun refreshValid() {
        if (previceValid != isValid) {
            eventValidChange.dispatch(isValid)
        }
        previceValid = isValid
        if (isValid) {
            dom.removeClass(ERROR)
        } else {
            dom.addClass(ERROR)
        }
    }

    fun forceRefreshValid() {
        refreshValid()
    }

    private var _isFocus = false
    val isFocus
        get() = _isFocus

    protected open fun onBlur() {
        if (!editor.readOnly) {
            refreshValid()
        }
    }

    protected open fun onFocus() {
    }

    init {
        editor.appendTo(layout)
        dom.addClass(STYLE)
        editor.readOnly = readOnly
        this.text = text
        editor.on("focus") {
            _isFocus = true
            onFocus()
        }

        editor.on("blur") {
            _isFocus = false
            onBlur()
        }

        editor.on("change") {
            refreshValid()
            eventChange.dispatch()
        }

        editor.on("keyup") {
            val event = it as? KeyboardEvent ?: return@on
            val keyCode = event.keyCode
            refreshValid()
            if (keyCode == 27 || keyCode == 13) {
                eventEnter.dispatch()
            }
        }
    }

    fun focus() {
        editor.focus()
    }

    fun select() {
        editor.select()
    }

    override val valid: Boolean
        get() = isValid

    override fun onValidChange(listener: ValidListener): Closeable =
        eventValidChange.on { listener(it) }
}

fun <T : TextareaInput> T.textValidator(validator: Validator<String>): T {
    this.textValidator = if (textValidator != null) {
        textValidator!! and validator
    } else {
        validator
    }

    return this
}

fun <T : TextareaInput> T.text(value: String): T {
    this.text = value
    return this
}
