package org.tlsys.ui

import kotlinx.browser.document
import org.w3c.dom.*
import pw.binom.web.AbstractComponent
import pw.binom.web.Component

fun <T : HTMLElement> T.con(): Component<T> = Component.getComponent(this).unsafeCast<Component<T>>()
val HTMLElement.isControl
    get() = this.asDynamic().COMPONENT != null

open class DivController : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
}

fun Document.link() = createElement("a").unsafeCast<HTMLAnchorElement>()

fun Document.createDiv() = createElement("div").unsafeCast<HTMLDivElement>()
fun Document.createSpan() = createElement("span").unsafeCast<HTMLSpanElement>()
fun Document.createSpan(text: String) = createSpan().also {
    it.innerText = text
}

fun Document.createLink() = createElement("a").unsafeCast<HTMLAnchorElement>()
fun Document.createLink(text: String, href: String) = createLink().also {
    it.innerText = text
    it.href = href
}

fun <T : HTMLAnchorElement> T.href(text: String): T {
    href = text
    return this
}

fun <T : HTMLAnchorElement> T.innerText(text: String): T {
    this.innerText = text
    return this
}
