package org.tlsys.admin.ui

import org.w3c.dom.HTMLTableCellElement

/**
 * Класс модели данных для таблицы
 *
 * @author Subochev Anton (caffeine.mgn@gmail.com)
 */
@Deprecated(level = DeprecationLevel.WARNING, message = "Deplicated")
class StaticTableDataProvider<T>(f: (StaticTableDataProvider<T>.() -> Unit)? = null) : TableDataProvider {
    inner class RenderData(val body: HTMLTableCellElement, val data: T, val rowNum: Int)
    private inner class ColumnRecord(val title: String, val data: (RenderData) -> Any?, val titleRender: ((HTMLTableCellElement) -> Unit)?)

    private val columns = ArrayList<ColumnRecord>()

    override val columnSize: Int
        get() = columns.size
    private val _items = ArrayList<T>()
    val items: List<T>
        get() = _items

    override val rowSize: Int
        get() = _items.size

    override fun get(column: Int): String = columns[column].title

    override fun get(column: Int, row: Int): Any? = null

    fun column(title: String, titleRender: ((HTMLTableCellElement) -> Unit)? = null, data: (RenderData) -> Unit) {
        columns += ColumnRecord(title = title, data = data, titleRender = titleRender)
    }

    fun remove(v: T) {
        _items.remove(v)
    }

    fun add(index: Int, item: T) {
        _items.add(0, item)
    }

    fun add(item: T) {
        _items.add(item)
    }

    fun addAll(items: Collection<T>) {
        for (t in items)
            add(t)
    }

    fun replace(old: T, newitem: T) {
        _items[_items.indexOf(old)] = newitem
    }

    fun replace(index: Int, newitem: T) {
        _items[index] = newitem
    }

    fun clear() {
        _items.clear()
    }

    val render: (TablePoint<*>) -> Unit = {
        if (it.row == -1) {
            val r = columns[it.column].titleRender
            if (r !== null)
                r(it.body)
            else
                it.body.innerHTML = columns[it.column].title
        } else
            columns[it.column].data(RenderData(body = it.body, data = _items[it.row], rowNum = it.row))
    }

    val size: Int
        get() = _items.size

    operator fun set(row: Int, value: T) {
        _items[row] = value
    }

    fun removeAt(index: Int) = _items.removeAt(index)

    init {
        if (f != null)
            this.f()
    }
}