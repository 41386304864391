package org.tlsys.security

import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.ExPermissionManagerService
import org.tlsys.api.UsersService
import org.tlsys.api.getAllPermissionIterator
import org.tlsys.async2
import org.tlsys.cms.SIMPLE_TEXT
import org.tlsys.core.map
import org.tlsys.dto.auth.ExPermissionDTO
import org.tlsys.dto.auth.GroupDTO
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.DivController
import pw.binom.web.layout.*

/**
 * Created by Ермаков Игорь Александрович (email: igor.yermakov94@yandex.ru) on 20.08.2017.
 */
class GroupPropertiesFragment(val group: GroupDTO) : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    companion object {
        val NAME = "Дополнительные"
    }

//    private val plugins by lazy { Plugins.getPluginList() }

    private val usersService by Services.byClass(UsersService::class)
    private val selected = usersService.getExPermisstions(group.id.jdto)

    inner class PermissionItem(val permission: ExPermissionDTO) : DivController() {
        init {
            addClass(Styles.LIST_ITEM_STYLE)
            dom.style.padding = 10.px
        }

        val layout = FlexLayout(dom, direction = FlexLayout.Direction.Row, alignItems = FlexLayout.AlignItems.Center)

//        val titleDiv = document.createDiv().also {
//            layout.add(it) {
//                grow = 1
//                shrink = 1
//            }
//            it.style.position = "relative"
//        }

        val title = Span(permission.title).addClass(SIMPLE_TEXT).appendTo(layout, grow = 1, shrink = 1).also {
            it.dom.style.textAlign = "left"
        }

        val checkbox = BigCheckboxNoText().appendTo(layout, grow = 0, shrink = 0)

        init {
            async2 {
                checkbox.enabled = false
                checkbox.checked = selected.await().any { it.uid == permission.uid }
                checkbox.enabled = true

                checkbox.EVENT_CHANGED.on {
                    if (checkbox.checked) {
                        usersService.addExPermission(groupId = group.id.jdto, permissionId = permission.id.jdto)
                    } else {
                        usersService.removeExPermission(groupId = group.id.jdto, permissionId = permission.id.jdto)
                    }
                }
            }
        }
    }

    private val exPermissionManagerService by Services.byClass(ExPermissionManagerService::class)
    private val list = LoadableListView(
        iterator = exPermissionManagerService
            .getAllPermissionIterator(minForLoad = 100, maxPart = 100)
            .map { PermissionItem(permission = it) },
        emptyText = "Нет дополнительныех прав",
        partCount = 30,
    ).appendTo(layout, grow = 1, shrink = 1)

    override suspend fun onStart() {
        super.onStart()
        list.onStart()
    }

    override suspend fun onStop() {
        list.onStop()
        super.onStop()
    }
}
