package org.tlsys.gateway

import kotlinx.browser.localStorage
import org.tlsys.GatewaySendResult
import org.tlsys.STORAGE_CONSTS
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.EditText
import org.tlsys.admin.ui.SaveCancelBody
import org.tlsys.admin.ui.TitleDialog
import org.tlsys.admin.ui.textValidator
import org.tlsys.await
import org.tlsys.gui.MessageDialog
import org.tlsys.serialization.Gateway
import org.tlsys.ui.*
import org.tlsys.ui.DivLayout
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.get
import org.w3c.dom.set
import pw.binom.web.layout.*

abstract class GatewayTestDialog(var gateway: Gateway) : TitleDialog("Тест шлюза") {
    private val body = SaveCancelBody(okText = "Тестировать", cancelText = "Закрыть").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    protected val multiValidator = MultiValidator()
    private val fullNameLayout = DivLayout()
        .appendTo(layout, grow = 0, shrink = 0)

    protected val surname = EditText(placeHolder = "Фамилия")
        .appendTo(fullNameLayout.layout)
        .textValidator(TextValidators.maxLength(70))
    protected val firstName = EditText(placeHolder = "Имя")
        .appendTo(fullNameLayout.layout)
        .textValidator(TextValidators.maxLength(70))
    protected val patronymic = EditText(placeHolder = "Отчество")
        .appendTo(fullNameLayout.layout)
        .textValidator(TextValidators.maxLength(70))

    protected abstract suspend fun test()

    protected suspend fun showResult(resp: GatewaySendResult) {
        val sb = StringBuilder()
        val txt = when (resp.status) {
            GatewaySendResult.Status.OK -> "Сообщение успешно отправлено"
            GatewaySendResult.Status.INVALID_CONFIG -> "Настройке не верны"
            GatewaySendResult.Status.BALANCE -> "Не достаточно балнса для отправки"
            GatewaySendResult.Status.NOT_FOUND -> "Шлюз отправки не найден"
            GatewaySendResult.Status.FAIL -> "Не удалось отправить сообщение"
            GatewaySendResult.Status.INVALID_AUTH_DATA -> "Не верные логин или пароль"
            GatewaySendResult.Status.INVALID_ADDRESS -> "Не верный адрес получателя"
            GatewaySendResult.Status.GONE -> "Шлюз отправки не имплементирован"
        }
        sb.append(txt)
        if (resp.description != null) {
            sb.append(": ").append(resp.description)
        }
        val messageType = if (resp.status.isError) MessageDialog.MessageType.ERROR else MessageDialog.MessageType.INFO
        MessageDialog.show(title = "Результат тестирования", message = sb.toString(), type = messageType).await()
    }

    init {
        multiValidator.onValidChange {
            body.okBtn.enabled = it
        }
        body.ok {
            test()
        }
        body.cancel {
            close()
        }

        surname.text = localStorage.get(STORAGE_CONSTS.LAST_TESTED_SURNAME) ?: ""
        firstName.text = localStorage.get(STORAGE_CONSTS.LAST_TESTED_FIRSTNAME) ?: ""
        patronymic.text = localStorage.get(STORAGE_CONSTS.LAST_TESTED_PATRONYMIC) ?: ""

        surname.eventChange.on {
            if (surname.isValid) {
                localStorage.set(key = STORAGE_CONSTS.LAST_TESTED_SURNAME, surname.text)
            }
        }

        firstName.eventChange.on {
            if (firstName.isValid) {
                localStorage.set(key = STORAGE_CONSTS.LAST_TESTED_FIRSTNAME, firstName.text)
            }
        }

        patronymic.eventChange.on {
            if (patronymic.isValid) {
                localStorage.set(key = STORAGE_CONSTS.LAST_TESTED_PATRONYMIC, patronymic.text)
            }
        }
    }
}
