package org.tlsys.wc

import org.tlsys.ParamUIService
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.SaveCancelBody
import org.tlsys.admin.ui.TitleDialog
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.node.MemberActionDTO
import org.tlsys.node.api.ScriptAdminService
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class MemberActionDialog(val memberId: Long, val memberActionDTO: MemberActionDTO) :
    TitleDialog(memberActionDTO.actionName.value) {
    private val body = SaveCancelBody().appendTo(super.layout)

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    val paramUIService by Services.byClass(ParamUIService::class)
    val root = paramUIService.build(memberActionDTO.params.value, null).appendTo(layout)
    val scriptAdminService by Services.byClass(ScriptAdminService::class)

    init {
        root.onValidChange {
            body.okBtn.enabled = it
        }

        body.ok {
            async2 {
                val result = scriptAdminService.executeMemberAction(
                    memberId = memberId.jdto,
                    scriptId = memberActionDTO.scriptId,
                    params = root.values().map { it.key.jdto to it.value.jdto }.toMap().jdto(),
                    actionName = memberActionDTO.actionName,
                ).await()
                if (result != null) {
                    showResult(memberActionDTO.actionName.value, result)
                }
                close()
            }
        }

        body.cancel {
            close()
        }
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        suspend fun show(memberId: Long, memberActionDTO: MemberActionDTO) {
            layoutNavigator.show(MemberActionDialog(memberId, memberActionDTO)).await()
        }

        suspend fun showResult(title: String, result: MemberActionDTO.ResultDTO) {
            MessageDialog.show(
                title = title,
                message = result.text.value,
                type = when (result) {
                    is MemberActionDTO.ResultDTO.InfoDTO -> MessageDialog.MessageType.INFO
                    is MemberActionDTO.ResultDTO.WarningDTO -> MessageDialog.MessageType.ATTENTION
                    is MemberActionDTO.ResultDTO.ErrorDTO -> MessageDialog.MessageType.ERROR
                },
            ).await()
        }
    }
}
