package org.tlsys.core.clientImport

import org.tlsys.admin.MembersAction
import org.tlsys.async2

object ImportMembersAction : MembersAction {
    override val text: String
        get() = "Импорт"

    override fun action() {
        async2 {
            ImportDialog.show()
        }
    }
}
