package org.tlsys.core.promotion.discountByPayWithLevels

import org.tlsys.*
import org.tlsys.admin.DiscountByPayedWithLevelsConfig
import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.OrderPromotionType
import org.tlsys.admin.PromotionStatus
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.api.ShopsService
import org.tlsys.cms.click
import org.tlsys.core.promotion.OrderPromotionEditor
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.uuid.nextUuid
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.random.Random

class DiscountByPayedWithLevelsDialog(record: OrderPromotionRecord?) :
    TitleDialog(OrderPromotionType.DISCOUNT_BY_PAYED_AND_DOC_PAY_WITH_LEVELS.title) {
    private val shopsService by Services.byClass(ShopsService::class)
    private val memberTagService by Services.byClass(MemberTagService::class)
    private val params = record?.params?.getObject<DiscountByPayedWithLevelsConfig>()

    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val titleGroup = DivLayout().appendTo(layout, grow = 0, shrink = 0)

    val name = EditText(placeHolder = "Название")
        .text(record?.title ?: type.title)
        .appendTo(titleGroup.layout, grow = 1, shrink = 1)
        .andTextValidator(TextValidators.NOT_BLANK)

    val status = Combobox2(
        items = PromotionStatus.values().map { it.title },
        select = record?.status?.let { PromotionStatus.values().indexOf(it) } ?: 0,
    )
        .appendTo(titleGroup.layout, grow = 1, shrink = 1)
    val dates = DivLayout().appendTo(layout, grow = 0, shrink = 0)

    private val dateStart =
        DatetimeEditor(record?.startDate ?: pw.binom.date.DateTime.now)
            .withLabel("Дата начала")
            .appendTo(dates.layout, grow = 1, shrink = 1)
    private val dateEnd =
        DatetimeEditor(
            record?.endDate ?: pw.binom.date.DateTime(pw.binom.date.DateTime.nowTime + 1000 * 60 * 60 * 24 * 7),
        )
            .withLabel("Дата окончания")
            .appendTo(dates.layout, grow = 1, shrink = 1)

    val tabs = Tabs().appendTo(layout, grow = 0, shrink = 0)
    private val viewport = ComponentView().appendTo(layout = layout)
    private val viewScroll = ScrollController(layout.dom)
    private val conditions = Conditions()
    private val levels = Levels()

    override fun onEscapeClick() {
        result = null
        close()
    }

    override suspend fun onInit() {
        super.onInit()
        if (params != null) {
            params.levels.forEach { level ->
                levels.list.add(LevelItem(level, levels.list))
            }
            val tags =
                memberTagService.getTagList((params.includeTags + params.excludeTags).map { it.jdto }.jdto()).await()
                    .associateBy { it.id }
            conditions.includeTags.content.addTag(
                params.includeTags.map { tags[it]!! },
            )
            conditions.excludeTags.content.addTag(
                params.excludeTags.map { tags[it]!! },
            )
            conditions.includeShops.content.addTag(
                params.shops.mapNotNull { shopsService.getShop(it.jdto).await() },
            )
        }
    }

    private val validator = MultiValidator(name)

    init {
        validator.onValidChange {
            body.okBtn.enabled = it
        }
        dom.style.width = 500.px
        dom.style.height = 700.px
        tabs.add("Уровни") {
            viewport.set2(levels)
        }
        tabs.add("Условия") {
            viewport.set2(conditions)
        }
        tabs.active = 0
        body.ok {
            val config = DiscountByPayedWithLevelsConfig(
                levels = levels.list.items.map { it }.map { it.record }.toList(),
                includeTags = conditions.includeTags.content.list.map { it.tag.id },
                excludeTags = conditions.excludeTags.content.list.map { it.tag.id },
                shops = conditions.includeShops.element.list.map { it.id },
            )
            result = OrderPromotionRecord(
                type = OrderPromotionType.BONUS_BY_PAYED_AND_DOC_PAY_WITH_LEVELS,
                id = record?.id ?: Random.nextUuid(),
                params = JsonObject.create(config),
                title = name.text,
                ownerUserId = 0,
                status = PromotionStatus.values()[status.select],
                createDate = pw.binom.date.DateTime.now,
                startDate = dateStart.content.date!!,
                endDate = dateEnd.content.date,
            )

            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    private var result: OrderPromotionRecord? = null

    companion object : OrderPromotionEditor {
        override val type: OrderPromotionType
            get() = OrderPromotionType.DISCOUNT_BY_PAYED_AND_DOC_PAY_WITH_LEVELS

        override suspend fun show(record: OrderPromotionRecord?): OrderPromotionRecord? {
            val d = DiscountByPayedWithLevelsDialog(record)
            d.show()
            return d.result
        }
    }

    inner class Conditions : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        val includeTags =
            TagPanel(readOnly = false).withLabel("Включить для меток").appendTo(this.layout, grow = 0, shrink = 0)
        val excludeTags =
            TagPanel(readOnly = false).withLabel("Исключить для меток").appendTo(this.layout, grow = 0, shrink = 0)
        val includeShops =
            ShopsPanel(readOnly = false).withLabel("Включить для торговых объектов")
                .appendTo(this.layout, grow = 0, shrink = 0)
    }

    inner class Levels : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        val list = LevelEditList().appendTo(layout)
    }

    inner class LevelEditList : EditableList<LevelItem>() {
        override suspend fun createItemForAdd(): Item? {
            val record = LevelDialog.show(null) ?: return null
            return LevelItem(record, this)
        }

        override fun afterAdd(item: Item) {
            super.afterAdd(item)
            sort()
        }
    }

    inner class LevelItem(
        var record: DiscountByPayedWithLevelsConfig.Level,
        list: LevelEditList,
    ) : EditableList.Item(), Comparable<LevelItem> {
        override val valid: Boolean
            get() = true

        fun refresh() {
            div.innerText = "от ${record.minSum} скидка ${record.selfPercent}"
        }

        override suspend fun isCanDeleteItem(): Boolean =
            YesNoDialog.show("Удалить уровень \"${div.innerText}\"", width = 500).await()

        init {
            div.style.cursor = "default"
            div.style.userSelect = "none"
            div.addClass(Styles.SIMPLE_TEXT)
            actionPanel.iconBtn(MaterialIcons.EDIT).click {
                val r = LevelDialog.show(record) ?: return@click
                record = r
                Effects.blockRow(dom)
                refresh()
                list.sort()
            }
            actionPanel.iconBtn(MaterialIcons.CONTENT_COPY).click {
                val r = LevelDialog.show(record) ?: return@click
                val newItem = LevelItem(r, list)
                list.add(newItem)
                Effects.blockRow(newItem.dom)
                list.sort()
            }
            refresh()
        }

        override fun compareTo(other: LevelItem): Int =
            record.minSum.asLong.compareTo(other.record.minSum.asLong)
    }
}
