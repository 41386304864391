package org.tlsys.api

import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import org.tlsys.ApiUrls
import org.tlsys.Template
import pw.binom.url.UrlEncoder
import pw.binom.uuid.UUID
import pw.binom.uuid.serialization.UUIDStringSerializer

object TemplateService {
    suspend fun getList() =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.TEMPLATE_LIST_GET,
            bodyClass = Unit.serializer(),
            body = null,
            response = ListSerializer(Template.serializer()),
            method = "GET",
        )

    suspend fun create(record: Template) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.TEMPLATE_CREATE_POST,
            bodyClass = Template.serializer(),
            body = record,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun update(record: Template) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.TEMPLATE_UPDATE_POST,
            bodyClass = Template.serializer(),
            body = record,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun delete(id: UUID) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.TEMPLATE_DELETE_POST,
            bodyClass = UUIDStringSerializer,
            body = id,
            response = Unit.serializer(),
            method = "POST",
        )

    suspend fun getById(id: UUID) =
        Core2ApiAccess.call(
            serviceUrl = ApiUrls.TEMPLATE_BY_ID_GET + UrlEncoder.encode(id.toString()),
            bodyClass = Unit.serializer(),
            body = Unit,
            response = Unit.serializer(),
            method = "GET",
        )
}
