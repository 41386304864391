package org.tlsys.core.interrogation

import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.interrogation.InterrogationWebService
import org.tlsys.json.jdto
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class InterrogationListPage : AbstractPage() {
    companion object {
        val URI = "interrogations"
        val TITLE = "Опросы"
    }

    //    private val toolBar = ActionPanel().appendTo(contentLayout, grow = 0, shrink = 0)
    private val interrogationWebService by Services.byClass(InterrogationWebService::class)

    //    private val list = DivLayout(direction = FlexLayout.Direction.Column).appendTo(contentLayout)
    private val scroll = ScrollController(dom)

//    init {
//        toolBar.addSpace()
//    }

    private val table = MaterialTable().appendTo(contentLayout, grow = 0, shrink = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Название").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    init {
        AddButton()
            .appendTo(contentLayout, grow = 0, shrink = 0)
            .onClick {
                val int = InterrogationDialog.show(null) ?: return@onClick
                val ii = interrogationWebService.create(int.first).await()
                interrogationWebService.setInterrogationTerminals(ii.id, int.second.map { it.jdto }.jdto())
                val item = Item(ii).appendTo(table)
                Effects.createAndBlankItem(item.dom)
            }
    }

    override suspend fun onInit() {
        super.onInit()
        interrogationWebService.getList().await().forEach {
            Item(it).appendTo(table)
        }
    }

    override suspend fun getTitle(): String = TITLE

    private inner class Item(var dto: InterrogationWebService.Interrogation) : MaterialTable.Row() {
        private val text = MaterialTable.TextColumn("")
            .appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        private fun refresh() {
            text.text = dto.question.value
        }

        init {
            actions.component.visibleOnHover(this)
            refresh()
            actions.component.iconBtn(MaterialIcons.SETTINGS).onClick {
                async2 {
                    val t = InterrogationDialog.show(dto) ?: return@async2
                    ProcessBlocker.block("Обновление опросника") {
                        dto = interrogationWebService.update(t.first).await()
                            ?: TODO("Не возможно обновить опросник: опросник ${dto.id.value} не найден")
                        interrogationWebService.setInterrogationTerminals(t.first.id, t.second.map { it.jdto }.jdto())
                        refresh()
                        Effects.blockRow(dom)
                    }
                }
                Unit
            }
            actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                async2 {
                    if (!YesNoDialog.show("Удалить опрос? Так же удалятся результаты опроса.").await()) {
                        return@async2
                    }
                    ProcessBlocker.block("Удаление опросника") {
                        interrogationWebService.delete(dto.id).await()
                    }.await()
                    Effects.removeItem(dom).await()
                    removeSelf()
                }
            }
        }
    }
}
