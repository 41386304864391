package org.tlsys.core.promotion.externalScriptPromotion

import org.tlsys.ExternalScriptManifest
import org.tlsys.ExternalUiMethods
import org.tlsys.JsonObject
import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.form.Validated
import org.tlsys.admin.form.Validator
import org.tlsys.admin.form.addToValidator
import org.tlsys.admin.ui.Combobox2
import org.tlsys.admin.ui.ProcessBlocker
import org.tlsys.admin.ui.validator
import org.tlsys.admin.ui.withLabel
import org.tlsys.api.ExternalScriptsService
import org.tlsys.core.promotion.BasePromotionDialog
import org.tlsys.dto.ExternalPromotionConfig
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import pw.binom.url.URI
import pw.binom.uuid.UUID
import pw.binom.web.layout.*

abstract class ExternalScriptPromotion(record: OrderPromotionRecord?, title: String) :
    BasePromotionDialog(record, title) {

    private val params = record?.params?.getObject<ExternalPromotionConfig>()

    private val script = Combobox2()
        .addToValidator(validator)
        .validator(Validator { if (it >= 0) valid() else invalid() })
        .withLabel("Внешний скрипт")
        .appendTo(config.layout, grow = 0, shrink = 0)

    private lateinit var scripts: List<ExternalScriptManifest>
    private val iframe = UiContainerExternalUI()
        .appendTo(config.layout)

    private val scriptValid = Validated AA@{
        if (script.content.select == -1) {
            return@AA false
        }
        val item = items[script.content.select]
        item.script.valid
    }

    private val frameValidator = Validated {
        if (this::items.isInitialized) {
            val promotionConfig = items.getOrNull(script.content.select)?.promo
            if (promotionConfig != null && promotionConfig.ui == null) {
                return@Validated true
            }
        }
        iframe.validator.valid
    }

    init {
        iframe.validator.onValidChange { frameValidator.refresh() }
        validator.addValidated(frameValidator)
        validator.addValidated(scriptValid)
        iframe.config.data = params?.promotionConfig
    }

    private suspend fun scriptSelected(item: Item?) {
        val ui = item?.promo?.ui
        if (ui != null) {
            if (ui.width != null) {
                dom.style.width = ui.width!!
                dom.style.height = ui.height!!
            }
            iframe.loadPage(item.rootUri.toURL().goto(ui.uri).toURI())
        } else {
            dom.style.width = 500.px
            dom.style.height = 700.px
            iframe.stop()
        }
        frameValidator.refresh()
    }

    class Item(val rootUri: URI, val script: ExternalScriptManifest, val promotion: UUID) {
        override fun toString(): String =
            script.script.name + ": " + (
                script.manifest?.promotions?.find { it.uuid == promotion }?.title
                    ?: "Не удалось загрузить"
                )

        val promo = script.manifest!!.promotions.find { it.uuid == promotion }!!
    }

    private lateinit var items: List<Item>
    override suspend fun onInit() {
        super.onInit()
        scripts = ExternalScriptsService.getManifestList()
        items = scripts.flatMap { s ->
            s.manifest?.promotions?.map {
                Item(script = s, promotion = it.uuid, rootUri = s.rootUri)
            } ?: emptyList()
        }
        script.content.items = items.map { it.toString() }
        script.content.eventChange.on { index ->
            scriptValid.refresh()
            ProcessBlocker.block("Загрузка скрипта") {
                scriptSelected(items.getOrNull(index))
            }
        }
        val externalScriptId = params?.externalScriptId
        val promotionType = params?.promotionType
        if (externalScriptId != null && promotionType != null) {
            script.content.select = items.indexOfFirst {
                it.script.script.id == externalScriptId && it.promotion == promotionType
            }
        }
    }

    override suspend fun buildConfig(): JsonObject {
        val config = iframe.call(ExternalUiMethods.OK_CLICK, null) as? String?
        val item = items[script.content.select]
        val params = ExternalPromotionConfig(
            externalScriptId = item.script.script.id,
            promotionType = item.promotion,
            promotionConfig = config ?: "",
        )
        return JsonObject.create(params)
    }
}
