package org.tlsys.core.promotion

import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.Promotion2Service
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.cms.click
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.ui.DivComponentWithLayout
import org.w3c.dom.DOMRect
import pw.binom.date.DateTime
import pw.binom.date.format.toDatePattern
import pw.binom.uuid.nextUuid
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.random.Random

val promotionFormat = "yyyy.MM.dd HH:mm".toDatePattern()

class OnOrderPromotion : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {

    private val promotionService by Services.byClass(AdminPromotionService::class)
    private val scripts by lazy { promotionService.getScripts().promise }
    private val promo2List by Services.listByClass(OrderPromotionEditor::class)

    private val list by lazy { promotionService.getPromotions().promise }

    private val table = MaterialTable().appendTo(layout, grow = 0, shrink = 1)
    private val scroll = ScrollController(dom)

    init {
        MaterialTable.Header().also {
            MaterialTable.TextColumn("Статус").appendTo(it).widthPx(100)
            MaterialTable.TextColumn("Название").appendTo(it)
            MaterialTable.TextColumn("Дата начала акции").appendTo(it).widthPx(170)
            MaterialTable.TextColumn("Дата окончания акции").appendTo(it).widthPx(170)
            MaterialTable.TextColumn("Действия").appendTo(it).widthPx(1)
        }.appendTo(table)
    }

    override suspend fun onInit() {
        super.onInit()
        Promotion2Service.getOrderPromotions(max = 50, offset = 0).map {
            PromoItem2(it).appendTo(table)
        }

        list.await().map {
            PromotionItem(it).appendTo(table)
        }
    }

    suspend fun addClick(rect: DOMRect) {
        val scripts = scripts.await()
        console.info("Scripts:", scripts)
        console.info("Scripts:", scripts.value)
        val index = Selector2.select(
            items = scripts.value.map {
                console.info("processing", it)
                it.title
            } + promo2List.map { it.type.title },
            x = rect.left,
            y = rect.top,
        ) ?: return
        if (index < scripts.size) {
            val script = scripts[index]
            val values = PromotionSettings.show(
                script = script,
                dto = null,
            ) ?: return

            val item = ProcessBlocker2.block("Создание акции") {
                promotionService.createPromotion(
                    id = script.id.jdto,
                    name = script.title.jdto,
                    params = values.second.jdto,
                    title = values.first.jdto,
                ).await()
            }.await()

            val pi = PromotionItem(item)
            pi.appendTo(table)
            Effects.createItem(pi.dom).await()
        } else {
            val promo2Index = index - scripts.size
            val rr = promo2List[promo2Index].show(null) ?: return
            Promotion2Service.createOrderPromotion(rr)
            val pi = PromoItem2(rr)
            pi.appendTo(table)
            Effects.createItem(pi.dom).await()
        }
    }

    private inner class PromoItem2(var item: OrderPromotionRecord) :
        MaterialTable.Row() {
        private val status = MaterialTable.TextColumn("").addClass(Styles.SIMPLE_TEXT).appendTo(this)
        private val title = MaterialTable.TextColumn("").addClass(Styles.SIMPLE_TEXT).appendTo(this)
        private val dateStart = MaterialTable.TextColumn("").addClass(Styles.SIMPLE_TEXT).appendTo(this)
        private val dateDuration = MaterialTable.TextColumn("").addClass(Styles.SIMPLE_TEXT).appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace()).appendTo(this)
        private fun update() {
            title.text = item.title
            status.text = item.status.title
            dateStart.text = promotionFormat.toString(item.startDate)
            dateDuration.text = item.endDate?.let { promotionFormat.toString(it) } ?: "Бессрочно"
        }

        init {
            actions.component.visibleOnHover(this)
            actions.component.iconBtn(MaterialIcons.EDIT).click {
                val editor = promo2List.find { it.type == item.type } ?: return@click
                val newItem = editor.show(item) ?: return@click
                Promotion2Service.updateOrderPromotion(newItem)
                item = newItem
                update()
                Effects.blockRow(dom)
            }
            actions.component.iconBtn(MaterialIcons.CONTENT_COPY).click {
                val editor = promo2List.find { it.type == item.type } ?: return@click
                val newItem =
                    editor.show(item.copy(id = Random.nextUuid(), createDate = DateTime.now, ownerUserId = 0))
                        ?: return@click
                Promotion2Service.createOrderPromotion(newItem)
                val pi = PromoItem2(newItem)
                pi.appendTo(table)
                Effects.createItem(pi.dom).await()
            }
            update()
        }
    }

    private inner class PromotionItem(var item: AdminPromotionService.PromotionDTO) :
        MaterialTable.Row() {
        val enable = MaterialTable.ComponentColumn(
            Checkbox(
                checked = item.enabled,
                label = "",
            ),
        ).appendTo(this)
        val t1 = MaterialTable.TextColumn("").appendTo(this)
        val t2 = MaterialTable.TextColumn("").appendTo(this)
        val t3 = MaterialTable.TextColumn("").appendTo(this)
        val actions = MaterialTable.ComponentColumn(ActionPlace()).appendTo(this)

        private fun refresh() {
            t1.text = item.title.takeIf { it.isNotEmpty() } ?: item.name
        }

        init {
            enable.component.EVENT_CHANGED.on {
                ProcessBlocker.block("Изменение статус акции") {
                    promotionService.setEnabled(item.id.jdto, enable.component.checked.jdto).await()
                }
            }
            actions.component.iconBtn(MaterialIcons.EDIT).click {
                async2 {
                    val script = scripts.await().find { it.id == item.scriptId && it.title == item.name }
                    if (script != null) {
                        console.info("Script found. Try to edit")
                        val values = PromotionSettings.show(script, item) ?: return@async2
                        ProcessBlocker2.block("Изменение акции") {
                            item = promotionService.updatePromotion(item.id.jdto, values.second.jdto, values.first.jdto)
                                .await()
                            refresh()
                            Effects.blockRow(dom)
                        }
                    } else {
                        console.info("Script ${item.scriptId} not found. all ids: ${scripts.await().map { it.id }} ")
                        MessageDialog.showError(
                            message = "Не удаётся найти параметры акции ${item.scriptId}",
                        )
                    }
                }
            }
            actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                if (!YesNoDialog.show("Удалить акцию?").await()) {
                    return@onClick
                }

                ProcessBlocker.block("Удаление акции") {
                    promotionService.deletePromotion(item.id.jdto).await()
                }.await()
                Effects.removeItem(dom).await()
                dom.remove()
            }
            actions.component.visibleOnHover(this)
            refresh()
        }
    }
}
