package org.tlsys

import kotlinx.serialization.Serializable

@Serializable
enum class GatewayEventType(val mask: Int, val title: String) {
    BALANCE_UPDATE(mask = 0b1, title = "Обновления баланса"),

    NOTIFY(mask = 0b10, title = "Уведомления"),
    SECURITY_CODE(mask = 0b100, title = "Код безопасности"),
    ;

    inline infix fun and(other: GatewayEventType) = mask and other.mask

    inline infix fun or(other: GatewayEventType) = mask or other.mask

    inline infix fun xor(other: GatewayEventType) = mask xor other.mask

    inline operator fun not() = mask.inv()

    companion object {
        val all =
            run {
                var result = 0
                entries.forEach {
                    result = result or it.mask
                }
                result
            }

        fun compress(set: Set<GatewayEventType>): Int {
            var result = 0
            set.forEach {
                result = result or it.mask
            }
            return result
        }

        fun uncompress(compressed: Int): Set<GatewayEventType> {
            val result = HashSet<GatewayEventType>()
            values().forEach {
                if (compressed and it.mask != 0) {
                    result += it
                }
            }
            return result
        }
    }
}
