package org.tlsys.admin.ui

import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import kotlinx.browser.document
import kotlinx.dom.addClass
import org.tlsys.css.CSS
import pw.binom.web.AbstractComponent
import pw.binom.web.Component
import pw.binom.web.layout.*

private val ITEM = CSS.genName()
private val ITEM_TITLE = CSS.genName()
private val STYLE = CSS.style {
    ".$ITEM" {
        height = 56.px
        ":nth-child(2n)" then {
            background = "rgba(0, 0, 0, 0.02)"
        }
        ">:last-child" then {
            marginRight = 16.px
        }
        ".$ITEM_TITLE"{
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontSize = 14.px
            color = "#3aafda"
            lineHeight = 20.px
            paddingLeft = 16.px
        }
    }
}.name

class SettingsPlane : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    private val layout = FlexLayout(dom, direction = FlexLayout.Direction.Column)

    init {
        dom.classList.add(STYLE)
    }

    class SettingItem<T : HTMLElement, V : Component<T>>(val render: (HTMLDivElement) -> Unit, val component: V) : AbstractComponent<HTMLDivElement>() {
        override val dom: HTMLDivElement = document.createDiv()
        init {
            dom.classList.add(ITEM)
        }

        private val layout = FlexLayout(dom, direction = FlexLayout.Direction.Row, alignItems = FlexLayout.AlignItems.Center).apply {

            add(document.createDiv().apply {
                render(this)
            }) {
                align = FlexLayout.FlexItem.AlignSelf.Center
                grow = 1
                shrink = 1
            }
            add(component.dom) {
                align = FlexLayout.FlexItem.AlignSelf.Center
            }
        }
    }

    fun <T : HTMLElement, V : Component<T>> add(render: (HTMLDivElement) -> Unit, component: V): SettingItem<T, V> {
        val s = SettingItem(render, component)
        layout.add(s.dom)
        return s
    }

    fun add(render: (HTMLDivElement) -> Unit): HTMLDivElement {
        val div = document.createDiv()
        div.classList.add(ITEM)
        layout.add(div) {
            grow = 0
            shrink = 0
        }
        render(div)
        return div
    }

    fun add(label: String) = add {
        it.innerText = label
        it.addClass(Styles.SIMPLE_TEXT)
        it.style.apply {
            padding = "20px 0px 10px 15px"
            height = "auto"
            fontSize = 18.px
            color = "#3aafda"
            fontWeight = "bold"
            backgroundColor = "rgba(0, 0, 0, 0.08)"
        }
    }

    fun <T : HTMLElement, V : Component<T>> add(name: String, component: V): SettingItem<T, V> {
        val s = SettingItem({
            it.innerText = name
            it.classList.add(ITEM_TITLE)

        }, component)
        s.appendTo(layout, grow = 0, shrink = 0)
        return s
    }
}