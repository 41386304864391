package org.tlsys.members

import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.clear
import libs.Date
import libs.asDateString
import libs.time
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.AbstractFragment
import org.tlsys.admin.ui.Span
import org.tlsys.admin.ui.StaticTableDataProvider
import org.tlsys.admin.ui.Table
import org.tlsys.api.MemberBalansProvider
import org.tlsys.async2
import org.tlsys.cms.LINK
import org.tlsys.core.AsyncIterator
import org.tlsys.dto.MemberDTO
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.tlsys.ui.createLink
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.ScrollType
import pw.binom.web.layout.*
import kotlin.math.round

class MemberListFragment(val memberIterator: AsyncIterator<MemberDTO>) :
    AbstractFragment<HTMLDivElement>(document.createDiv()) {

    companion object {
        val SEX_RENDER: (StaticTableDataProvider<MemberDTO>.RenderData) -> Unit = {
            val bg = if (it.data.active && it.data.sex) {
                js("man_enable_png")
            } else if (it.data.active && !it.data.sex) {
                js("woman_enable_png")
            } else if (!it.data.active && it.data.sex) {
                js("man_disable_png")
            } else {
                js("woman_disable_png")
            }
            it.body.style.background = "url($bg)"
            it.body.style.apply {
                backgroundColor = "transparent"
                backgroundRepeat = "no-repeat"
                backgroundPosition = "16px center"
                border = "0"
                width = 25.px
                minHeight = 21.px
            }
        }

        val FULL_NAME_RENDER: (StaticTableDataProvider<MemberDTO>.RenderData) -> Unit = {
            it.body.innerHTML = "${it.data.lastName} ${it.data.firstName} ${it.data.middleName ?: ""}"
        }
    }

    val layout = FlexLayout(dom, direction = FlexLayout.Direction.Column)

    /*    var allSelected = false
        val selected = kotlin.collections.HashSet<Long>()*/
    var member: MemberDTO? = null

    /*
    private fun selectStatusChanged() {
        deleteBtn.enabled = selected.isNotEmpty() || allSelected
    }
*/

    fun addMember(member: MemberDTO) {
        tt.add(0, member)
        table.fireInsert(0)
        table.blinkRow(0)
    }

    override suspend fun onStart() {
        super.onStart()
        if (member != null) {
            refresh()
        }

        if (firstRun) {
            firstRun = false
            firstRun()
            // tableScrollPlane.update()
            table.refresh()
        } else {
            scroll.y = lastScroll
        }
    }

    private val memberBalansProvider by Services.byClass(MemberBalansProvider::class)

    val tt = StaticTableDataProvider<MemberDTO>().apply {
        column("") {
            SEX_RENDER(it)
        }

        column("ФИО") {
            it.body.clear()
            val link = document.createLink()
            val layout = FlexLayout(link)
            layout.alignItems = FlexLayout.AlignItems.Center
            link.style.apply {
                height = "100%"
            }
            link.classList.add(LINK)
            link.href = "${window.location.hash}/m${it.data.id}"

            Span(it.data.fullName.takeIf { it.isNotBlank() } ?: "не указано").appendTo(layout)

            it.body.appendChild(layout.parent)

//            FULL_NAME_RENDER(it)
//            val id = it.data.id
//            val index = it.rowNum
//            val d = it.data
//            it.body.onclick = {
//                console.info("Set index = $index")
//                member = d
//                MainPageControl.show("./m$id")
//            }
//            it.body.classList.add(LINK)
        }
        column("Дата рождения") {
            it.body.innerHTML = it.data.birthday?.run { Date.time(this).asDateString } ?: "Не указано"
        }
        column("Активные бонусы") {
            it.body.innerHTML = "${round(it.data.allowBalans * 100) / 100.0}"
        }
        column("Неактивные бонусы") {
            it.body.innerHTML = "${round(it.data.notAllowBalans * 100) / 100.0}"
        }

        column("") {
        }
    }
    val table = Table(tt, tt.render)

    val scroll = ScrollController(document.createDiv()).also {
        layout.add(it.dom) {
            shrink = 1
            grow = 1
        }
        it.scrollY = ScrollType.AUTO
        it.scrollX = ScrollType.NONE

        it.dom.appendChild(table.dom)
    }

    private fun firstRun() = async2 {
        while (true) {
//            if (!this.started)
//                break
            val scrollHeight = scroll.dom.scrollHeight
            val clientHeight = scroll.dom.clientHeight
            console.info("scrollHeight: [$scrollHeight], clientHeight: [$clientHeight]")
            if (scroll.visibleScrollY) {
                break
            }

            if (!memberIterator.hasNext()) {
                break
            }
            tt.add(memberIterator.next())
            table.fireInsert(tt.size - 1)
        }
    }

    private val scrollAutoLoadListener = scroll.addScrollListener {
        if (scroll.endScrollY) {
            async2 {
                if (memberIterator.hasNext()) {
                    tt.add(memberIterator.next())
                    table.fireInsert(tt.size - 1)
                }
            }
        }
    }

    /*
    val tableScrollPlane = AutoLoadScrollY.build(
            firstSize = 10,
            partSize = 10,
            render = memberRender,
            loaderAsync = {
                if (max == 0)
                    listOf()
                else
                    Members.getMembers(offset = offset, max = max).wait().toList()
            }).apply {
        layout.add(dom) {
            shrink = 1
        }
        scrollY = ScrollType.AUTO
        scrollX = ScrollType.NONE
    }
    */
    init {

        // tableScrollPlane.dom.appendChild(table.dom)
    }

    private var firstRun = true

    private var lastScroll = 0

    override suspend fun onStop() {
        super.onStop()
        lastScroll = scroll.y
//        console.info("ScrollY=${scroll.y}")
    }

    fun refresh() {
        // tableScrollPlane.reload { table.refresh() }
    }
}
