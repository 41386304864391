package org.tlsys.admin.cross

import pw.binom.rpc.CrossService


interface CartCrossService : CrossService {
    override val name: String
        get() = "cart_service"

    val updateCount: CrossService.CrossMethod<Unit>
}