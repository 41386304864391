package org.tlsys.admin.ui

import org.tlsys.async2
import org.tlsys.await
import org.tlsys.core.Closeable
import org.tlsys.core.start
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import kotlin.js.Promise
import kotlin.reflect.KProperty

abstract class AbstractFragment<T : HTMLDivElement>(override val dom: T) : Fragment<T>, AbstractComponent<T>() {

    private val loadItems = ArrayList<inLoad<*>>()

    private var loaded = false

    override fun load() = async2<Unit> {
        if (loaded) {
            return@async2
        }
        loaded = true
        val dd = ArrayList<Promise<*>>()
        loadItems.forEach {
            dd += it.onFragmentLoaded()
        }
        Promise.all(dd.toTypedArray()).await()
    }

    inner class inLoad<out T>(val f: suspend () -> T) {
        init {
            loadItems += this
        }

        internal fun onFragmentLoaded(): Promise<T> {
            promise = f.start()
            return promise!!
        }

        private var promise: Promise<T>? = null
        operator fun getValue(thisRef: Any?, property: KProperty<*>): Promise<T> {
            if (promise == null) {
                TODO("Fragment was not loaded!")
            }

            return promise!!
        }
    }

    private var _started = false
    private var _inited = false

    override suspend fun onInit() {
    }

    override suspend fun onStart() {
        if (!_inited) {
            onInit()
        }
        _inited = true
        _started = true

        ArrayList(onStartListeners).forEach {
            it.invoke()
        }
    }

    override suspend fun onStop() {
        _started = false

        ArrayList(onStopListeners).forEach {
            it.invoke()
        }
    }

    private val onStartListeners = ArrayList<() -> Unit>()
    private val onStopListeners = ArrayList<() -> Unit>()

    override fun addListenerOnSrart(l: () -> Unit): Closeable {
        onStartListeners += l

        return Closeable {
            onStartListeners -= l
        }
    }

    override fun addListenerOnStop(l: () -> Unit): Closeable {
        onStopListeners += l

        return Closeable {
            onStopListeners -= l
        }
    }
}
