package org.tlsys.core.clientImport

import kotlinx.browser.document
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.ui.Span
import org.tlsys.admin.ui.Styles
import org.tlsys.admin.ui.text
import org.tlsys.core.Closeable
import org.tlsys.core.api.ImportService
import org.tlsys.ui.*
import org.tlsys.ui.createLink
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class ResultViewStage(val result: ImportService.ImportResult) :
    DivComponentWithLayout(direction = FlexLayout.Direction.Column), Master.Stage<HTMLDivElement> {
    override val title: String
        get() = "Результат"
    override val height: Int
        get() = 200
    override val valid: Boolean
        get() = true

    override fun onValidChange(listener: ValidListener): Closeable = Closeable { }
    override val isFinallyStage: Boolean
        get() = true

    override suspend fun finish() {
    }

    init {
        val notImportedClientsCount = result.notImportedClientsCount.value
        val warningCountCount = result.warningCount.value
        Span()
            .addClass(Styles.SIMPLE_TEXT)
            .appendTo(layout)
            .text("Ошибки импорта: $notImportedClientsCount")
            .dom.style.textAlign = "center"
        Span()
            .addClass(Styles.SIMPLE_TEXT)
            .appendTo(layout)
            .text("Предупреждения импорта: $warningCountCount")
            .dom.style.textAlign = "center"

        val link = document.createLink()
        link.addClass(Styles.LINK)
        link.appendTo(layout)
        link.href = result.reportFile.value
        link.target = "_blank"
        link.text = "Отчет импорта клиентов"
        link.style.textAlign = "center"
    }
}
