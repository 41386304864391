package org.tlsys

import org.w3c.dom.css.CSSStyleDeclaration

var CSSStyleDeclaration.userSelect7: String
    get() = asDynamic()["user-select"]
    set(value) {
        asDynamic()["user-select"] = value
        asDynamic()["-moz-user-select"] = value
        asDynamic()["-ms-user-select"] = value
        asDynamic()["-webkit-user-select"] = value
    }

inline val Float.px: String
    get() = "${this.unsafeCast<String>()}px"

inline val Int.px: String
    get() = "${this.unsafeCast<String>()}px"

inline val Long.px: String
    get() = "${this.unsafeCast<String>()}px"

inline val Double.px: String
    get() = "${this.unsafeCast<String>()}px"

inline var CSSStyleDeclaration.widthPX: Float
    get() = width.removeSuffix("px").toFloat()
    set(it) {
        width = it.px
    }

inline var CSSStyleDeclaration.heightPX: Float
    get() = height.removeSuffix("px").toFloat()
    set(it) {
        height = it.px
    }

inline var CSSStyleDeclaration.topPX: Float
    get() = top.removeSuffix("px").toFloat()
    set(it) {
        top = it.px
    }

inline var CSSStyleDeclaration.leftPX: Float
    get() = left.removeSuffix("px").toFloat()
    set(it) {
        left = it.px
    }

inline var CSSStyleDeclaration.paddingLeftPX: Float
    get() = paddingLeft.removeSuffix("px").toFloat()
    set(it) {
        paddingLeft = it.px
    }
inline var CSSStyleDeclaration.paddingRightPX: Float
    get() = paddingRight.removeSuffix("px").toFloat()
    set(it) {
        paddingRight = it.px
    }

inline var CSSStyleDeclaration.paddingTopPX: Float
    get() = paddingTop.removeSuffix("px").toFloat()
    set(it) {
        paddingTop = it.px
    }

inline var CSSStyleDeclaration.paddingBottomPX: Float
    get() = paddingBottom.removeSuffix("px").toFloat()
    set(it) {
        paddingBottom = it.px
    }

inline var CSSStyleDeclaration.overflow: String
    get() = this.asDynamic().overflow
    set(it) {
        this.asDynamic().overflow = it
    }

inline val Int.percent: String
    get() = "${this.unsafeCast<String>()}%"

inline val Long.percent: String
    get() = "${this.unsafeCast<String>()}%"

inline val Float.percent: String
    get() = "${this.unsafeCast<String>()}%"

inline val Double.percent: String
    get() = "${this.unsafeCast<String>()}%"