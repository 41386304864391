package org.tlsys

import kotlinx.serialization.modules.SerializersModule
import org.tlsys.serialization.*
import pw.binom.uuid.UUID
import pw.binom.date.DateTime
import pw.binom.url.Path
import pw.binom.url.URI
import pw.binom.url.URL
import pw.binom.uuid.serialization.UUIDLongSerializer
import pw.binom.uuid.serialization.UUIDStringSerializer
import kotlin.time.Duration

val BaseModule = SerializersModule {
//    contextual(DateTime::class, DateStringIso8601Serializer)
    contextual(Duration::class, DurationMillisecondsSerializer)
    contextual(Email::class, EmailSerializer)
    contextual(MoneyValue::class, MoneyValueSerializer)
    contextual(Path::class, PathSerializer)
    contextual(Percent::class, PercentSerializer)
    contextual(Phone::class, PhoneSerializer)
    contextual(Quantity::class, QuantitySerializerLong)
    contextual(URI::class, URISerializer)
    contextual(URL::class, URLSerializer)
}

val BaseTextModule = SerializersModule {
    include(BaseModule)
    this.contextual(UUID::class, UUIDStringSerializer)
    this.contextual(DateTime::class, DateStringIso8601Serializer)
}

val BaseBinaryModule = SerializersModule {
    include(BaseModule)
    this.contextual(UUID::class, UUIDLongSerializer)
    this.contextual(DateTime::class, DateLongSerializer)
}
