package org.tlsys

import kotlinx.browser.window
import kotlin.coroutines.*
import kotlin.js.Promise
import kotlin.time.Duration
import kotlin.time.ExperimentalTime

private fun <T, V> (suspend V.() -> T).start(value: V) = Promise<T> { resolve, reject ->
    this.startCoroutine(
        value,
        object : Continuation<T> {
            override fun resumeWith(result: Result<T>) {
                if (result.isSuccess) {
                    result.onSuccess(resolve)
                }
                if (result.isFailure) {
                    reject(result.exceptionOrNull()!!)
                }
            }

            override val context = EmptyCoroutineContext
        },
    )
}

fun <T> (suspend () -> T).start() = Promise<T> { resolve, reject ->
    this.startCoroutine(object : Continuation<T> {
        override fun resumeWith(result: Result<T>) {
            if (result.isSuccess) {
                result.onSuccess(resolve)
            }
            if (result.isFailure) {
                reject(result.exceptionOrNull()!!)
            }
        }

        override val context = EmptyCoroutineContext
    })
}

fun <T> async2(c: suspend () -> T): Promise<T> = c.start()

suspend fun <T> Promise<T>.await() = suspendCoroutine<T> { c ->
    then({
        c.resume(it)
    }, {
        c.resumeWithException(it)
    })
}

@ExperimentalTime
suspend fun <T> timeout(timeout: Duration, func: suspend () -> T) =
    suspendCoroutine<T> { con ->
        var finished = false
        val timerId = window.setTimeout({
            if (!finished) {
                finished = true
                con.resumeWithException(TimeoutException())
            }
        }, timeout.inWholeMilliseconds.toInt())

        func.startCoroutine(object : Continuation<T> {
            override fun resumeWith(result: Result<T>) {
                if (!finished) {
                    window.clearTimeout(timerId)
                    finished = true
                    con.resumeWith(result)
                }
            }

            override val context = con.context
        })
    }

suspend fun <T> Promise<T>.await(timeout: Int) = suspendCoroutine<T> { c ->
    require(timeout > 0)
    var done = false
    val timeoutTimer = window.setTimeout({
        if (!done) {
            done = true
            c.resumeWithException(TimeoutException())
        }
    }, timeout)
    then({
        if (!done) {
            window.clearTimeout(timeoutTimer)
            done = true
            c.resume(it)
        }
    }, {
        if (!done) {
            window.clearTimeout(timeoutTimer)
            done = true
            c.resumeWithException(it)
        }
    })
}

fun Collection<Promise<*>>.join() = Promise.all(this.toTypedArray())

class TimeoutException : RuntimeException()
