package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.ui.createLink
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLSpanElement
import pw.binom.web.AbstractComponent

open class Span(text: String = "") : AbstractComponent<HTMLSpanElement>() {
    final override val dom: HTMLSpanElement = document.createElement("span").unsafeCast<HTMLSpanElement>()
    open var text: String
        get() = dom.innerText
        set(value) {
            dom.innerText = value
        }

    init {
        this.text = text
        afterConstruct()
    }
}

fun <T : Span> T.text(text: String): T {
    this.text = text
    return this
}

open class Link(text: String = "", href: String = "") : AbstractComponent<HTMLAnchorElement>() {
    override val dom: HTMLAnchorElement = document.createLink()
    open var text: String
        get() = dom.innerText
        set(value) {
            dom.innerText = value
        }

    open var href: String
        get() = dom.href
        set(value) {
            dom.href = value
        }

    init {
        this.text = text
        this.href = href
    }
}
