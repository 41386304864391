package org.tlsys.dto.auth

import org.tlsys.json.*

enum class PermissionDTO(val title: String) {
    CREATE_USER("Создание пользователей"),
    DELETE_USER("Удаление пользователей"),

    ADD_USER_TO_GROUP("Добавление пользователей в группы"),
    REMOVE_USER_FROM_GROUP("Удаление пользователей из группы"),

    CREATE_GROUP("Создание групп"),
    DELETE_GROUP("Удаление групп"),
    CHANGE_GROUP_PERMISSION("Изменение прав доступа групп"),
    CHANGE_GROUP_NAME("Переименование групп"),

    CREATE_SHOP("Создание торговых объектов"),
    EDIT_SHOP_INFO("Редактирование торговых объектов"),

    @Deprecated(message = "Don't use it")
    CHANGE_COMPANY_NAME("Редактирование наименования компании"),

    /**
     * Удаление маганизна. Если в магазине есть терминалы, то необходимо разрешение REMOVE_TERMINAL
     */
    REMOVE_SHOP("Удаление торговых объектов"),

    CREATE_TERMINAL("Создание терминалов"),
    EDIT_TERMINAL_INFO("Редактирование терминалов"),
    REMOVE_TERMINAL("Удаление терминалов"),

    /**
     * Работа с плагинами
     */
    CHANGE_PLUGIN_ENABLE_STATE("Изменение статуса активации компонентов"),
    EDIT_SETTINGS("Изменение настроек системы")
}

class ExPermissionDTO(val id: Long, val uid: String, val title: String, val owner: String) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<ExPermissionDTO> {
        override val type: String
            get() = "ExPermissionDTO"

        override fun read(node: JsonNode) =
                ExPermissionDTO(
                        id = node["id"]!!.long(),
                        uid = node["uid"]!!.string(),
                        title = node["title"]!!.string(),
                        owner = node["owner"]!!.string()
                )

        override fun write(obj: ExPermissionDTO) =
                JsonNode.obj()
                        .set("id", obj.id.json)
                        .set("uid", obj.uid.json)
                        .set("title", obj.title.json)
                        .set("owner", obj.owner.json)
    }
}

class ExPermissionDuplicateExceptionDTO(val id: JLong, val uid: JString) : RuntimeException(), JDTO {
    constructor():this(
            id=0L.jdto,
            uid = "".jdto
    )
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : AutoJDTOFactory<ExPermissionDuplicateExceptionDTO>(ExPermissionDuplicateExceptionDTO::class)
}

data class ExPermissionNotFoundExceptionDTO(val id: JLong) : RuntimeException(), JDTO {
    constructor():this(0L.jdto)
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : AutoJDTOFactory<ExPermissionNotFoundExceptionDTO>(ExPermissionNotFoundExceptionDTO::class)
}
