package org.tlsys.members

import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.Validator
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.cms.click
import org.tlsys.dto.contacts.ContactDTO
import org.tlsys.px
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class ContactEditDialog private constructor(contact: ContactDTO?) :
    TitleDialog(if (contact != null) "Редактирование контакта" else "Новый контакт") {
    val body = SaveCancelBody().appendTo(super.layout)

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    val typeSelector = Combobox2(listOf("Телефон", "Электронная почта", "Адрес", "Telegram"))
        .withLabel("Тип")
        .appendTo(layout, grow = 0, shrink = 0)

    val value = EditText(placeHolder = "Контакт")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)

    var cancelled = false

    override suspend fun onStart() {
        super.onStart()
        value.focus()
    }

    init {
        dom.style.width = 300.px
        if (contact != null) {
            typeSelector.element.select = when (contact.type) {
                ContactDTO.Type.PHONE -> 0
                ContactDTO.Type.EMAIL -> 1
                ContactDTO.Type.ADDRESS -> 2
                ContactDTO.Type.TELEGRAM -> 3
            }
            value.text = contact.value
        } else {
            typeSelector.element.select = 0
        }

        value.eventValidChange.on {
            body.okBtn.enabled = it
        }

        body.okBtn.click {
            cancelled = false
            close()
        }

        body.cancelBtn.click {
            cancelled = true
            close()
        }

        value.textValidator(
            Validator {
                console.info(
                    "typeSelector.element.select: [${typeSelector.element.select}]   ${
                        TextValidators.IS_PHONE.valid(
                            it,
                        )
                    }",
                )
                when (typeSelector.element.select) {
                    0 -> TextValidators.IS_PHONE.valid(it)
                    1 -> TextValidators.IS_MAIL.valid(it)
                    2 -> valid()
                    3 -> TextValidators.IS_TELEGRAM.valid(it)
                    else -> TODO()
                }
            },
        )

        typeSelector.element.enabled = contact == null
        // typeSelector.element.dom.style.height = 25.px
        typeSelector.element.eventChange.on {
            value.forceRefreshValid()
        }
    }

    companion object {
        fun show(contact: ContactDTO?) = async2 {
            val d = ContactEditDialog(contact)
            d.show()
            if (d.cancelled) {
                return@async2 null
            }
            return@async2 ContactDTO(
                id = "",
                type = when (d.typeSelector.element.select) {
                    0 -> ContactDTO.Type.PHONE
                    1 -> ContactDTO.Type.EMAIL
                    2 -> ContactDTO.Type.ADDRESS
                    3 -> ContactDTO.Type.TELEGRAM
                    else -> TODO()
                },
                value = d.value.text,
            )
        }
    }
}
