package org.tlsys.dto

import org.tlsys.json.*

class TransactionDTO(
    val member: Long,
    val date: Long,
    val start: Long,
    val end: Long?,
    val document: String?,
    val documentNumber: String?,
    val terminalId: Long?,
    val shopId: Long?,
    val terminalName: String?,
    val delta: Long,
    val description: String?,
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<TransactionDTO> {
        override val type: String
            get() = "Transaction"

        override fun read(node: JsonNode) = TransactionDTO(
            member = node["member"]!!.long(),
            date = node["date"]!!.long(),
            document = node["document"]?.string(),
            end = node["end"]?.long(),
            start = node["start"]!!.long(),
            delta = node["delta"]!!.long(),
            documentNumber = node["documentNumber"]?.string(),
            terminalId = node["terminalId"]?.long(),
            shopId = node["shopId"]?.long(),
            terminalName = node["terminalName"]?.string(),
            description = node["description"]?.string(),
        )

        override fun write(obj: TransactionDTO) = JsonNode.obj(
            "member" to obj.member.json,
            "date" to obj.date.json,
            "document" to obj.document?.json,
            "end" to obj.end?.json,
            "start" to obj.start.json,
            "delta" to obj.delta.json,
            "documentNumber" to obj.documentNumber?.json,
            "terminalId" to obj.terminalId?.json,
            "shopId" to obj.shopId?.json,
            "terminalName" to obj.terminalName?.json,
            "description" to obj.description?.json,
        )

    }
}