package org.tlsys.goods

enum class Charsets(val code: String, val title: String) {
    WINDOWS1251("WINDOWS-1251", "Windows-1251"),
    UTF8(code = "UTF-8", title = "UTF-8"),
    ;

    companion object {
        fun findByCode(code: String) =
            values().find { it.code == code } ?: throw IllegalArgumentException("Unknown charset code")
    }
}
