package org.tlsys.admin.ui

import org.tlsys.addClass
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.DivComponentWithLayout
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

/*
private val STYLE = CSS.style {
    boxShadow = "0 2px 2px 0 rgba(0,0,0,.05), 0 3px 1px -2px rgba(0,0,0,.08), 0 1px 5px 0 rgba(0,0,0,.08)"
//    position = "relative"
    borderRadius = 2.px
    backgroundColor = "#fff"
    padding = 10.px
    margin = 10.px
    boxSizing = "border-box"
}.name
*/
abstract class Card : DivComponentWithLayout() {
    init {
        dom.addClass(org.tlsys.styles.Card.MAIN)
    }
}

open class CardLayout : Card() {
    public override val layout: FlexLayout<HTMLDivElement>
        get() = super.layout

    init {
        dom.addClass(org.tlsys.styles.Card.MAIN)
        super.layout.alignItems = FlexLayout.AlignItems.Stretch
        super.layout.wrap = FlexLayout.Wrap.Enable
        super.layout.alignContent = FlexLayout.AlignContent.Start
    }
}

open class CardLayoutWithLayout(title: String = "") : CardLayout() {
    protected val titleLayout = FlexComponent()
        .appendTo(
            super.layout,
            grow = 0,
            shrink = 0,
            align = FlexLayout.FlexItem.AlignSelf.Normal,
        )
    protected val title = Span(title).appendTo(
        titleLayout.layout,
        grow = 1,
        shrink = 0,
    ).addClass(Styles.SIMPLE_TEXT)

    protected val body = FlexComponent()
        .also {
            super.layout.direction = FlexLayout.Direction.Column
            super.layout.alignContent = FlexLayout.AlignContent.Stretch
            it.layout.direction = FlexLayout.Direction.Column
        }
        .appendTo(super.layout, grow = 1, shrink = 1)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.layout

    init {
        titleLayout.layout.alignItems = FlexLayout.AlignItems.Center
        titleLayout.dom.style.minHeight = 35.px
    }
}
