package org.tlsys.admin

import kotlinx.serialization.Serializable

@Serializable
enum class GatewayStatus(val title: String) {
    ENABLED(
        title = "Активен",
    ),
    DISABLED(
        title = "Не активен",
    ),
    DEBUG(
        title = "Отладка",
    ),
}
