package org.tlsys

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.ui.AbstractDialog
import org.tlsys.animation.DefaultAnimationDialogTheme
import org.tlsys.cms.ui.LayoutLevelManagerImpl
import org.tlsys.core.Stack
import org.tlsys.navigation.Layout2
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import kotlin.js.Promise

class ViewLayout(val layoutLevelManager: LayoutLevelManagerImpl) : LayoutNavigator {

    private class Record(
        val dialog: Layout2<out HTMLElement>,
        val div: HTMLDivElement,
        val level: LayoutLevelManagerImpl.Level,
        val func: Layout2<out HTMLElement>.() -> Unit,
    )

    private val dialogStack = Stack<Record>()

    val last: Layout2<out HTMLElement>?
        get() = dialogStack.top?.dialog

    override suspend fun close(dialog: Layout2<out HTMLElement>): Boolean {
        val closeDialog = dialogStack.top ?: return false
        if (closeDialog.dialog !== dialog) {
            return false
        }
        closeDialog.dialog.onFocusLost()
        closeDialog.dialog.onStop()
        val hideAnimation = if (closeDialog.dialog is AbstractDialog) {
            DefaultAnimationDialogTheme.dialogHide(AnimationSpeed.HIDE_DIALOG, closeDialog.dialog)
        } else {
            Promise.resolve(Unit)
        }
        closeDialog.level.close()
        closeDialog.func(closeDialog.dialog)
        dialogStack.remove(closeDialog)
        hideAnimation.then {
            closeDialog.func(closeDialog.dialog)
        }
        dialogStack.top?.also {
            it.dialog.onFocusGot()
        }
        return true
    }

    override val currentDialog: Layout2<out HTMLElement>?
        get() = last

    override fun <T : Layout2<out HTMLElement>> show(dialog: T, f: T.() -> Unit) {
        val level = layoutLevelManager.giveLayout()
        dialogStack.top?.let {
            it.dialog.onFocusLost()
        }

        level.dom.appendChild(dialog.dom)

        dialogStack.push(Record(dialog = dialog, div = level.dom, level = level, func = f.asDynamic()))
        async2 {
            dialog.onStart()
        }
        dialog.onFocusGot()
        if (dialog is AbstractDialog) {
            DefaultAnimationDialogTheme.dialogShow(AnimationSpeed.SHOW_DIALOG, dialog)
        }
    }

    override fun <T : Layout2<out HTMLElement>> show(dialog: T) = Promise<T> { d, c ->
        show(dialog) {
            d(dialog)
        }
    }

    suspend fun closeAll() {
        while (true) {
            val l = last ?: break
            close(l)
        }
    }
}
