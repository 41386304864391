package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.childElements
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.form.Validated
import org.tlsys.admin.form.Validator
import org.tlsys.admin.form.isNullOrValid
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.core.Closeable
import org.tlsys.insertAfter
import org.tlsys.ui.*
import pw.binom.web.layout.*
import org.w3c.dom.events.KeyboardEvent
import pw.binom.web.ScrollController

private val TEXT_ITEM_CONSTRUCTOR: suspend () -> EditableList.Item? = {
    EditableList.TextItem("")
}

open class EditableList<T : EditableList.Item> :
    DivComponentWithLayout(direction = FlexLayout.Direction.Column),
    Validated {

    protected val list = DivLayout(direction = FlexLayout.Direction.Column).appendTo(layout, grow = 0, shrink = 1)
    private val scroll = ScrollController(list.dom)

    fun sort() {
        val l = this.items.map { it as Comparable<T> }.toMutableList()
        l.sortWith(Comparator { a, b -> a.compareTo(b as T) })

        l.forEach {
            it as Item
            it.dom.remove()
        }

        l.forEach {
            it as Item
            list.dom.appendChild(it.dom)
        }
    }

    fun add(value: Item) {
        value.appendTo(list.layout, grow = 0, shrink = 0)
        value._list = this
        afterAdd(value)
    }

    fun add(value: String) {
        add(TextItem(value))
    }

    protected open suspend fun createItemForAdd(): Item? {
        return itemConstructor()
    }

    private var itemConstructor: (suspend () -> Item?) = TEXT_ITEM_CONSTRUCTOR

    private var textValidator: Validator<String>? = null

    protected open fun isValid(text: String): Boolean {
        val tv = textValidator
        if (tv != null) {
            return textValidator?.valid(text).isNullOrValid
        }
        return true
    }

    val values: List<String>
        get() = list.dom.childElements.filter { it.isControl }.mapNotNull { it.con() as? TextItem }.map { it.text }

    val items
        get() = list.dom.childElements.asSequence().filter { it.isControl }
            .mapNotNull { it.con() as? Item } as Sequence<T>

    fun textValidator(validator: Validator<String>?): EditableList<T> {
        this.textValidator = validator
        return this
    }

    private val addButton = AddButton().also {
        it.onClick {
            async2 {
                val item = createItemForAdd() ?: return@async2
                item.appendTo(list.layout, grow = 0, shrink = 0)
                scroll.moveToEndV()
                Effects.blockRow(item.dom)
                if (item is TextItem)
                    item.focus()
                afterAdd(item)
            }
        }
        Unit
    }.appendTo(layout, grow = 0, shrink = 0)

    protected open fun afterAdd(item: Item) {
        // Do nothing
    }

    abstract class Item :
        DivComponentWithLayout(alignItems = FlexLayout.AlignItems.Center) {
        protected val div = document.createDiv().appendTo(layout)
        protected val actionPanel = ActionPlace().appendTo(layout, grow = 0, shrink = 0)
        protected open suspend fun isCanDeleteItem(): Boolean = true
        open val valid: Boolean
            get() = true

        internal var _list: EditableList<out Item>? = null
        protected val list: EditableList<out Item>
            get() = _list!!

        init {
            addClass(Styles.LIST_ITEM_STYLE)

            actionPanel.visibleOnHover(this)
            actionPanel.iconBtn(MaterialIcons.DELETE).onClick {
                if (!isCanDeleteItem()) {
                    return@onClick
                }
                Effects.removeItem(dom).await()
                dom.remove()
            }
        }
    }

    open class TextItem(text: String) : Item(), Comparable<TextItem> {

        val text
            get() = div.textContent ?: ""

        fun focus() {
            div.focus()
        }

        override val valid
            get() = list!!.isValid(text)

        protected fun refresh() {
            list!!.updateValid(valid)
            this.dom.style.border = when {
                editing && text.isNotBlank() && !valid -> "solid 1px #f00"
                editing -> "solid 1px rgb(0, 172, 193)"
                else -> ""
            }
        }

        private var editing = false

        init {
            div.textContent = text
            div.contentEditable = "true"

            div.style.apply {
                outline = "none"
                border = "none"
                boxShadow = "none"
            }
            div.addClass(Styles.SIMPLE_TEXT)
            div.on("keydown") {
                it as KeyboardEvent
                if (it.keyCode == 13) {
                    it.preventDefault()
                    if (dom.textContent?.isBlank() == true) {
                        div.blur()
                    } else {
                        val item = TextItem("").appendTo(list!!.layout, grow = 0, shrink = 0)
                        item._list = list
                        list!!.dom.insertAfter(item.dom, dom)
                        item.focus()
                    }
                    return@on
                }
                if (it.keyCode == 27) {
                    div.blur()
                    it.preventDefault()
                    return@on
                }
                refresh()
            }
            div.on("keyup") {
                refresh()
            }
            div.on("blur") {
                if (div.textContent?.isBlank() == true) {
                    async2 {
                        Effects.removeItem(dom).await()
                        dom.remove()
                        list!!.updateValid()
                    }
                    return@on
                }
                editing = false
                refresh()
            }
            div.on("focus") {
                editing = true
                refresh()
            }
        }

        override fun compareTo(other: TextItem): Int =
            text.compareTo(other.text)
    }

    override val valid: Boolean
        get() = !list.dom.childElements
            .filter { it.isControl }
            .mapNotNull { it.con() as? Item }
            .filter { !it.valid }
            .any()

    private var oldValid = true

    fun updateValid() {
        updateValid(valid)
    }

    private fun updateValid(valid: Boolean) {
        if (oldValid != valid) {
            oldValid = valid
            validListeners.forEach {
                it(valid)
            }
        }
    }

    private val validListeners = ArrayList<ValidListener>()

    override fun onValidChange(listener: ValidListener): Closeable {
        validListeners += listener
        return Closeable {
            validListeners -= listener
        }
    }
}
