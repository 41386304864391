package org.tlsys.admin.ui

import org.tlsys.admin.MemberListProvider
import org.tlsys.admin.core.Services
import org.tlsys.dto.SearchSource
import org.tlsys.px
import org.w3c.dom.HTMLDivElement
import kotlin.js.Promise

class SelectorSearchSource(val current: SearchSource?) : ToolWindow() {

    init {
        dom.style.width = 200.px
        dom.style.height = 150.px
    }

    val list2 by Services.listByClass(MemberListProvider::class)


    val list = object : ListModel {
        override val size: Int
            get() = list2.size//Extensions.membersSearch.elements.size

        override fun item(index: Int): Any = list2[index]//Extensions.membersSearch.elements[index].value

        override fun isEnabled(index: Int): Boolean = true

        override fun render(index: Int, value: Any, dom: HTMLDivElement, selected: Boolean, enabled: Boolean) {
            value as MemberListProvider
            dom.innerHTML = value.title
            dom.onclick = {
                _selected = value
                hide()
            }
        }
    }


    val listView = GUIList().apply {
        model = list
        this@SelectorSearchSource.dom.appendChild(dom)
        this.redraw()
    }

    private var _selected: MemberListProvider? = null
    val selected: MemberListProvider?
        get() = _selected

    companion object {

        private val listProvider by Services.listByClass(MemberListProvider::class)

        fun select(x: Int, y: Int) = Promise<MemberListProvider?> { d, c ->
//            val list = Extensions.membersSearch.elements.toTypedArray()
            val builder = PopupMenu.build {
                for (g in listProvider) {
                    it.text(g.title) {
                        d(g)
                    }
                }
            }.then {
                it.showLeft(x = x, y = y).then {
                    if (!it)
                        d(null)
                }
            }
//            PopupMenu.select(x = x, y = y) {
//                for (g in list) {
//                    it.text(g.value.title)
//                }
//            }.then {
//                if (it == null)
//                    d(null)
//                else
//                    d(list[it.index].value)
//            }
        }
    }
}