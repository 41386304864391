package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.dom.removeClass
import libs.Date
import libs.date
import libs.month
import libs.year
import org.tlsys.addClass
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.events.EventElementValue
import org.tlsys.admin.form.*
import org.tlsys.core.Closeable
import org.tlsys.styles.EditTextStyle
import org.tlsys.ui.DivComponentWithLayout
import org.tlsys.ui.on
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import pw.binom.web.layout.appendTo

/*
private val TOP_PLACEHOLDER = CSS.genName()
private val PLACE_HOLDER = CSS.genName()
private val ERROR = CSS.genName()

private val STYLE = CSS.style {
    ">input" then {
        backgroundColor = "transparent"
        border = "none"
        outline = "none"
        fontFamily = Styles.DEFAULT_TEXT_FONT
        fontSize = Styles.DEFAULT_FONT_SIZE.px
        borderBottom = "1px solid #BCBCBC"
        marginTop = 10.px
        boxShadow = "none"
        transition = "border-bottom-width 300ms ease, border-bottom-color 300ms ease, box-shadow 300ms ease"
        ":focus" then {
            boxShadow = "0 1px 0 0 #26a69a"
            borderBottomColor = "#26a69a"
        }
    }
    ">.$PLACE_HOLDER" then {
        position = "absolute"
        fontFamily = Styles.DEFAULT_TEXT_FONT
        fontSize = Styles.DEFAULT_FONT_SIZE.px
        fontWeight = "500"
        transition = "font-size 300ms ease, top 300ms ease"
        color = "#9e9e9e"
        top = 20.px
    }

    ".$ERROR" then {
        ">input" then {
            borderBottomColor = "#F44336"
            ":focus" then {
                boxShadow = "0 1px 0 0 #F44336"
            }
        }
    }

    ".$TOP_PLACEHOLDER" then {
        ">.$PLACE_HOLDER" then {
            fontSize = 11.2.px//Styles.SMALL_FONT_SIZE.px
            top = 0.px
        }
    }
    height = 42.px
    position = "relative"
    padding = "5px 10.5px 5px 10.5px"
}.name
*/
open class EditText(placeHolder: String? = null, password: Boolean = false, readOnly: Boolean = false) :
    DivComponentWithLayout(), Validated {
    override val valid: Boolean
        get() = isValid

    var enabled: Boolean
        get() = !editor.disabled
        set(value) {
            editor.disabled = !value
        }

    fun focus() {
        editor.focus()
    }

    fun select() {
        editor.select()
    }

    override fun onValidChange(listener: ValidListener): Closeable =
        eventValidChange.on { listener(it) }

    val editor = document.createElement("input").unsafeCast<HTMLInputElement>()

    val eventValidChange = EventElementValue<Boolean>()
    val eventChange = EventElement()
    val eventEnter = EventElement()
    protected val holder =
        Span(placeHolder ?: "").appendTo(layout).also {
            it.dom.addClass(EditTextStyle.PLACE_HOLDER)
        }

    var placeHolder: String
        get() = holder.text
        set(value) {
            holder.text = value
        }

    var textValidator: Validator<String>? = null

    open val isValid: Boolean
        get() = textValidator?.valid(text).isNullOrValid

    private var previceValid = false
    protected open fun refreshValid() {
        if (previceValid != isValid) {
            eventValidChange.dispatch(isValid)
        }
        previceValid = isValid
        if (isValid) {
            dom.removeClass(EditTextStyle.ERROR)
        } else {
            dom.addClass(EditTextStyle.ERROR)
        }
    }

    fun forceRefreshValid() {
        refreshValid()
    }

    var text: String
        get() = editor.value
        set(value) {
            editor.value = value
            forceRefreshValid()
            if (value.isNotEmpty()) {
                dom.addClass(EditTextStyle.TOP_PLACEHOLDER)
            } else {
                dom.removeClass(EditTextStyle.TOP_PLACEHOLDER)
            }
        }

    private var _isFocus = false
    val isFocus
        get() = _isFocus

    protected open fun onBlur() {
        if (!editor.readOnly) {
            if (text.isEmpty()) {
                dom.removeClass(EditTextStyle.TOP_PLACEHOLDER)
            }
            refreshValid()
        }
    }

    protected open fun onFocus() {
        if (!editor.readOnly) {
            dom.addClass(EditTextStyle.TOP_PLACEHOLDER)
        }
    }

    init {
        editor.readOnly = readOnly
        editor.type = if (password) "password" else "text"
        editor.appendTo(layout)
        dom.addClass(EditTextStyle.MAIN)

        editor.on("focus") {
            _isFocus = true
            onFocus()
        }

        editor.on("blur") {
            _isFocus = false
            onBlur()
        }

        editor.on("change") {
            console.info("Refresh: is valid: $isValid")
            refreshValid()
            eventChange.dispatch()
        }

        holder?.dom?.on("mousedown") {
            editor.focus()
            it.preventDefault()
        }
        editor.on("keyup") {
            val event = it as? KeyboardEvent ?: return@on
            val keyCode = event.keyCode
            refreshValid()
            if (keyCode == 27 || keyCode == 13) {
                eventEnter.dispatch()
            }
        }

        if (password) {
            editor.setAttribute("autocomplete", "new-password")
        }
    }

    override suspend fun onStart() {
        super.onStart()
        previceValid = isValid
        refreshValid()
        if (text.isNotBlank()) {
            dom.addClass(EditTextStyle.TOP_PLACEHOLDER)
        }
    }
}

fun <T : EditText> T.textValidator(validator: Validator<String>): T {
    this.textValidator = if (textValidator != null) {
        textValidator!! and validator
    } else {
        validator
    }

    return this
}

fun <T : EditText> T.andTextValidator(validador: Validator<String>): T {
    textValidator =
        if (textValidator != null) textValidator!! and validador else validador
    forceRefreshValid()
    return this
}

fun <T : EditText> T.orTextValidator(validador: Validator<String>): T {
    textValidator =
        if (textValidator != null) textValidator!! or validador else validador
    forceRefreshValid()
    return this
}

fun <T : EditText> T.text(text: String): T {
    this.text = text
    return this
}

open class DateEditText(
    placeHolder: String? = null,
    val allowEmpty: Boolean = false,
    readOnly: Boolean = false,
    date: Date? = null,
) : EditText(placeHolder = placeHolder, readOnly = readOnly, password = false) {
    var dateValidator: Validator<Date>? = null
    private val keyDown: (Event) -> Unit = FUNC@{ event ->
        event as KeyboardEvent
        if (event.keyCode != 8 && event.key.length > 1) {
            return@FUNC
        }
        if (event.key.length == 1 && event.key[0] !in ('0'..'9')) {
            event.preventDefault()
            return@FUNC
        }
        val pos = currentPos
        if (event.keyCode == 8) {
            if (previesPos >= 0) {
                val bb = previesPos
                var s = ""
                if (bb > 0) {
                    s = text.substring(0, bb)
                }
                s += EMPTY[bb]
                if (bb < text.length - 1) {
                    s += text.substring(bb + 1)
                }

//                val tt = text.substring(0, bb) +
//                        EMPTY[bb] +
//                        text.substring(bb + 1)
                val tt = s
                text = tt
            }
            refreshSelected()
            event.preventDefault()
            return@FUNC
        }

        if (pos == -1) {
            event.preventDefault()
        }
    }

    private val keyUp: (Event) -> Unit = { event ->
        if (refreshSelected() == -1) {
            event.preventDefault()
        }
    }

    private val keyPress: (Event) -> Unit = { event ->
        event as KeyboardEvent
        if (refreshSelected() == -1) {
            event.preventDefault()
        }
    }

    // private val mask = Span("__.__.__").appendTo(layout).addClass(MASK)
    private var EMPTY = "dd.mm.yyyy"

    var date: Date?
        get() {
            if (!isValid || text.isBlank() || text == EMPTY) {
                return null
            }
            return DateValidator.parseDate(text)
        }
        set(value) {
            if (value == null) {
                if (isFocus) {
                    text = EMPTY
                    refreshSelected()
                } else {
                    text = ""
                }
            } else {
                text = ""
                text += value.date.toString().padStart(2, '0')
                text += "."
                text += value.month.let { it + 1 }.toString().padStart(2, '0')
                text += "."
                text += value.year.let { it }.toString()
            }
            refreshValid()
        }

    override fun refreshValid() {
        _isValid = run {
            if (allowEmpty && (text.isBlank() || text == EMPTY)) {
                return@run true
            }
            if (DateValidator.DATE_FORMAT.valid(text).isNotValid) {
                return@run false
            }
            val date = DateValidator.parseDate(text) ?: return@run false
            if (!dateValidator?.valid(date).isNullOrValid) {
                return@run false
            }
            return@run true
        }
        super.refreshValid()
    }

    private var _isValid = allowEmpty
    override val isValid: Boolean
        get() = _isValid

    private val currentPos: Int
        get() {
            (0 until text.length).forEach {
                if (EMPTY[it] != '.' && EMPTY[it] != '/' && text[it] == EMPTY[it]) {
                    return it
                }
            }
            return -1
        }

    private val previesPos: Int
        get() {
            var pos = currentPos.let { if (it == -1) text.length - 1 else it - 1 }
            (pos downTo 0).forEach {
                if (EMPTY[it] != '.' && EMPTY[it] != '/' && text[it] != EMPTY[it]) {
                    return it
                }
            }
            return -1
        }

    init {
        this.date = date
        // dom.addClass(DATE_STYLE)
    }

    private fun refreshSelected(): Int {
        val pos = currentPos
        if (pos >= 0) {
            editor.setSelectionRange(pos, pos + 1)
        }
        return pos
    }

    private val vv = js("{capture: true}")
    override fun onFocus() {
        super.onFocus()
        if (text.isBlank()) {
            text = EMPTY
        }
        console.info("onFocus")
        this.editor.addEventListener("keypress", keyPress)
        this.editor.addEventListener("keyup", keyUp)
        this.editor.addEventListener("keydown", keyDown)
        refreshSelected()
    }

    override fun onBlur() {
        if (text == EMPTY) {
            text = ""
        }
        console.info("onBlur")
        this.editor.removeEventListener("keypress", keyPress)
        this.editor.removeEventListener("keyup", keyUp)
        this.editor.removeEventListener("keydown", keyDown)
        super.onBlur()
    }
}

fun <T : DateEditText> T.date(date: Date?): T {
    this.date = date
    return this
}

fun <T : DateEditText> T.dateValidator(validator: Validator<Date>): T {
    dateValidator = validator
    return this
}

fun <T : EditText> T.updateOnChange(validator: Combobox2): T {
    validator.eventChange.on {
        forceRefreshValid()
    }
    return this
}
