package org.tlsys.admin

import kotlinx.serialization.Serializable
import org.tlsys.DtoDescriptionRU
import pw.binom.db.serialization.Enumerate

@Serializable
enum class PromotionStatus(val code: Int, val canWork: Boolean, val title: String) {
    @DtoDescriptionRU("Не активна")
    @Enumerate.Alias("0")
    DISABLED(code = 0, canWork = false, title = "Не активна"),

    @Enumerate.Alias("1")
    @DtoDescriptionRU("Активна")
    ACTIVE(code = 1, canWork = true, title = "Активна"),

    @Enumerate.Alias("2")
    @DtoDescriptionRU("Черновик")
    DRAFT(code = 2, canWork = false, title = "Черновик"),

    @Enumerate.Alias("3")
    @DtoDescriptionRU("Отладка")
    DEBUG(code = 3, canWork = true, title = "Отладка"),
    ;

    companion object {
        val byCodes = values().associateBy { it.code }
        fun findByCode(code: Int) =
            byCodes[code] ?: throw IllegalArgumentException("Can't find promotion status by code $code")
    }
}
