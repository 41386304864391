package org.tlsys.admin

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.MoneyValue
import org.tlsys.Percent
import org.tlsys.promotion.dto.TagFilterDtoWithShop
import org.tlsys.promotion.dto.WithFilterByTagAndShops

@Serializable
data class DiscountByTagsConfig(
    @SerialName("remove_tags_after_apply")
    val removeTagsAfterApply: Boolean,
    override val filter: TagFilterDtoWithShop,
    val records: List<Record>,
) : WithFilterByTagAndShops {
    @Serializable
    data class Record(
        val tags: List<Long>,
        val discountPercent: Percent?,
        val discountAmount: MoneyValue?,
        val partnerBonus: PercentWithDate,
        val usePartnerBonus: Boolean,
    ) {
        init {
            require((discountPercent == null && discountAmount != null) || (discountPercent != null && discountAmount == null)) { "Only one of field should be full" }
        }
    }
}
