package org.tlsys.file

import org.tlsys.admin.core.Remote
import org.tlsys.admin.core.Services
import org.tlsys.api.FileService
import org.tlsys.dto.reg_admin_extensionLib

fun fileMain(remote: Remote) {
    reg_admin_extensionLib.reg()
    Services.reg(remote.getService(FileService::class, invokator = FILE_SERVICE_INVOCATOR, setvice = FILE_SERVICE))
}
