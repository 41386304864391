package org.tlsys.core.api

import org.tlsys.dto.DtoSet
import org.tlsys.json.*
import kotlin.js.JsName

interface ApiService {
    @JsName("getApi")
    fun getApi(): AsyncResult<JList<ApiDto>>

    @JsName("isApiEnabled")
    fun isApiEnabled(): AsyncResult<JBoolean>

    @JsName("setApiEnabled")
    fun setApiEnabled(enabled: JBoolean): AsyncResult<JUnit>

    @JsName("getApiKey")
    fun getApiKey(): AsyncResult<JString>

    class ApiDto(val description: JString, val methods: JList<MethodDTO>) : JDTO {
        protected constructor() : this(JString.EMPTY, JList.empty())

        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : AutoJDTOFactory<ApiDto>(ApiDto::class)
    }

    class MethodDTO(val method: JString,
                    val path: JString,
                    val description: JString,
                    val arguments: JList<ArgumentDTO>,
                    val headers: JList<ArgumentDTO>,
                    val response: JList<ResponseDTO>) : JDTO {
        protected constructor() : this(JString.EMPTY, JString.EMPTY, JString.EMPTY, JList.empty(), JList.empty(), JList.empty())

        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : AutoJDTOFactory<MethodDTO>(MethodDTO::class)
    }

    class ResponseDTO(val code: JInt, val description: JString, val example: JString?) : JDTO {
        protected constructor() : this(0.jdto, JString.EMPTY, null)

        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : AutoJDTOFactory<ResponseDTO>(ResponseDTO::class)
    }

    class ArgumentDTO(val name: JString, val description: JString, defaultValue: JString?) : JDTO {
        protected constructor() : this(JString.EMPTY, JString.EMPTY, null)

        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : AutoJDTOFactory<ArgumentDTO>(ArgumentDTO::class)
    }

    object set : DtoSet() {
        override fun regDto() {
            ApiDto.reg()
            MethodDTO.reg()
            ArgumentDTO.reg()
            ResponseDTO.reg()
        }

    }
}