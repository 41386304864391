package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.libs.sleep
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.core.start
import org.tlsys.css.animate
import org.tlsys.gui.MessageDialog
import org.tlsys.navigation.LayoutLevelManager
import org.tlsys.ui.DivComponent
import pw.binom.web.layout.*
import org.tlsys.ui.*
import kotlin.js.Promise

/**
 * Время, через которое появится окно блокировки процесса
 */
private const val TIME_TO_SHOW = 500

class Spiner : DivComponent() {
    private val d1 = document.createDiv()

    init {
        addClass("preloader-wrapper")
        addClass("big")
        addClass("active")


        dom.appendChild(d1)
        d1.addClass("spinner-layer")
        d1.addClass("spinner-red-only")

        val d2 = document.createDiv().also {
            it.addClass("circle-clipper")
            it.addClass("left")
            val d3 = document.createDiv()
            d3.addClass("circle")
            d3.style.apply {
                boxSizing = "border-box"
            }
            it.appendChild(d3)
            d1.appendChild(it)
        }

        document.createDiv().also {
            it.addClass("gap-patch")
            document.createDiv().apply {
                addClass("circle")
                it.appendChild(this)
                this.style.apply {
                    boxSizing = "border-box"
                }
            }
            d1.appendChild(it)
        }

        document.createDiv().also {
            it.addClass("circle-clipper")
            it.addClass("right")
            document.createDiv().apply {
                addClass("circle")
                it.appendChild(this)
                this.style.apply {
                    boxSizing = "border-box"
                }
            }
            d1.appendChild(it)
        }
    }
}

class ProcessBlocker private constructor() : DivComponent() {
    val spiner = Spiner().appendTo(dom)
    private val layoutNavigator by Services.byClass(LayoutLevelManager::class)
    val bg = FullBackground()

    init {
        dom.style.apply {
            position = "absolute"
            transform = "translate(-50%, -50%)"
            left = "50%"
            top = "50%"
        }
        bg.dom.style.backgroundColor = "rgba(255,255,255,0.8)"
    }

    private fun show() {
        document.body!!.appendChild(bg.dom)
        this.appendTo(bg.dom)
        bg.dom.animate {
            duration = 200
            0{
                opacity = 0.0
            }

            100{
                opacity = 1.0
            }
        }.start()
    }

    private suspend fun hide() {
        bg.dom.animate {
            duration = 200
            0{
                opacity = 1.0
            }

            100{
                opacity = 0.0
            }
            onEnd {
                bg.dom.remove()
            }
        }.start().await()
    }

    companion object {
        private val processes = ArrayList<Process<*>>()

        class Process<T>(func: suspend () -> T) {
            val promise = async2 {
                try {
                    processes += this
                    func()
                } finally {
                    processes -= this
                }
            }
        }

        fun <T> block2(text: String, func: suspend () -> T): Promise<T> = async2 {
            val bg = FullBackground()
            document.body!!.appendChild(bg.dom)
            ProcessBlocker().appendTo(bg.dom)
            try {
                func()
            } finally {
                bg.dom.remove()
            }
        }

        private var runned = false
        private var timeout = 0
        private fun start(withoutTimeout: Boolean) {
            if (runned)
                return
            runned = true
            timeout = if (withoutTimeout) TIME_TO_SHOW + 1 else 0
            async2 {
                while (processes.isNotEmpty()) {
                    if (timeout > TIME_TO_SHOW)
                        break
                    Promise.sleep(100)
                    timeout += 100
                }
                if (timeout >= TIME_TO_SHOW) {
                    val pb = ProcessBlocker()
                    pb.show()
                    while (processes.isNotEmpty()) {
                        Promise.sleep(100)
                    }
                    pb.hide()
                }
                runned = false
            }
        }

        fun <T> block(text: String, withoutTimeout: Boolean = false, func: suspend () -> T): Promise<T> {
            val p = Process(func)
            start(withoutTimeout)
            return p.promise
        }
    }
}

/**
 * Окно блокировки админки на время загрузки данных
 */
@Deprecated("Use ProcessBlocker")
class ProcessBlocker2 private constructor() : AbstractDialog() {
    private val layout = FlexLayout(dom)
    private val text = Span("").appendTo(layout, grow = 1, shrink = 1).addClass(Styles.SIMPLE_TEXT)

    companion object {

        private val layoutNavigator by Services.byClass(LayoutNavigator::class)

        private fun <T> block(text: String, promise: Promise<T>): Pair<Promise<T>, ProcessBlocker2> {
            var done = false
            var showed = false
            val b = ProcessBlocker2()
            val timer = window.setTimeout({
                if (!done) {
                    b.text.text = text
                    showed = true
                    layoutNavigator.show(b)
                }
            }, TIME_TO_SHOW)

            promise.then {
                window.clearInterval(timer)
                done = true
                if (showed)
                    b.close()
            }
            promise.catch {
                window.clearInterval(timer)
                if (showed) {
                    b.text.text = "Произошла ошибка. Обновите страницу"
                } else {
                    MessageDialog.showError("Произошла ошибка. Обновите страницу", canClose = false, width = 400)
                }
            }

            return promise to b
        }

        /**
         * Вызывает блокировку админки на время выполнения [f]. Во время выполнения отображает текст [text]
         */
        fun <T> block(text: String, f: suspend () -> T): Promise<T> = block(text, f.start()).first

        fun <T> blockEx(text: String, f: suspend ((String) -> Unit) -> T): Promise<T> {
            var b: ProcessBlocker2? = null
            val func: (String) -> Unit = {
                b?.text?.text = it
            }
            val v = block(text, f.start(func))
            b = v.second
            return v.first
        }

    }
}