package org.tlsys.lk

import org.tlsys.admin.SettingItem
import org.tlsys.admin.core.Remote
import org.tlsys.admin.core.Services
import org.tlsys.lk.admin.dto.lk_dto_set
import org.tlsys.lk.pages.CodePrSettingPage
import org.tlsys.lk.pages.OsmiSerttingsPage
import org.tlsys.ui.Page

fun lkMain(remote: Remote) {
    lk_dto_set.reg()
    Services.reg(object : SettingItem {
        override val uri: String
            get() = CodePrSettingPage.URI
        override val title: String
            get() = CodePrSettingPage.TITLE

        override suspend fun getPage(): Page = CodePrSettingPage()
    })

    Services.reg(object : SettingItem {
        override val uri: String
            get() = OsmiSerttingsPage.URI
        override val title: String
            get() = OsmiSerttingsPage.TITLE

        override suspend fun getPage(): Page = OsmiSerttingsPage()
    })

//    Extensions.memberActionProperty.extend(CardPrAction)
}
/*
object CardPrInstallFlag : MemberInfoRecord {
    override suspend fun isClicable(memberId: Long): Boolean = true

    override suspend fun click(memberId: Long) {
        val old = RemoteCardPR.isCardPrInstalled(memberId.jdto).await().value
        if (old) {
            if (YesNoDialog.show("Выключить флаг установки CardPr?").await())
                RemoteCardPR.setCardPrInstalled(memberId.jdto, false.jdto).await()
        } else {
            if (YesNoDialog.show("Включить флаг установки CardPr?").await())
                RemoteCardPR.setCardPrInstalled(memberId.jdto, true.jdto).await()
        }
    }

    override val title: String
        get() = "Приложение CardPr установлено"

    override fun getValue(memberId: Long): Promise<String> = async {
        if (RemoteCardPR.isCardPrInstalled(memberId.jdto).await().value) {
            "Да"
        } else {
            "Нет"
        }
    }
}
*/
// object CardPrAction : Extensions.MemberAction {
//    override val title: String
//        get() = "CardPR"
//
//    override fun action(member: Long) = async {
//        MainViewLayout.show(MemberCardPrDialog(member))
//    }
//
// }
