package org.tlsys.members

import kotlinx.browser.document
import kotlinx.browser.localStorage
import libs.Date
import libs.fromUTC
import libs.timeUTC
import org.tlsys.*
import org.tlsys.admin.core.PageNavigation
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.api.AccountsService
import org.tlsys.api.DEFAULT_SESSION
import org.tlsys.api.MembersService
import org.tlsys.cms.Extensions
import org.tlsys.cms.click
import org.tlsys.cms.int
import org.tlsys.cms.use
import org.tlsys.core.clientImport.fix
import org.tlsys.dto.AccountDTO
import org.tlsys.dto.MemberDTO
import org.tlsys.dto.auth.CorePermission
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.w3c.dom.HTMLTableElement
import org.w3c.dom.HTMLTableRowElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.*
import kotlin.js.Promise

private class ValueList :
    AbstractComponent<HTMLTableElement>() {
    override val dom: HTMLTableElement = document.createElement("table").unsafeCast<HTMLTableElement>()

    init {
        afterConstruct()
    }

    class Record(label: String, val value: suspend () -> String, override val dom: HTMLTableRowElement) :
        AbstractComponent<HTMLTableRowElement>() {
        private val l = dom.insertCell()
        private val v = dom.insertCell()

        init {
            l.style.apply {
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.DEFAULT_FONT_SIZE.px
                padding = 5.px
            }
            v.style.apply {
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.DEFAULT_FONT_SIZE.px
                fontWeight = "bold"
                padding = 5.px
            }
            l.innerText = label
        }

        fun refresh() {
            async2 {
                v.innerText = value()
            }
        }
    }

    private val records = ArrayList<Record>()
    fun add(label: String, value: String) = add(label) { value }
    fun add(label: String, value: suspend () -> String) {
        val row = dom.insertRow()
        records += Record(label, value, row)
    }

    fun refresh() {
        records.forEach {
            it.refresh()
        }
    }

    override suspend fun onStart() {
        super.onStart()
        refresh()
    }
}

class MemberInfoPage(private var member: MemberDTO) : AbstractPage() {

    companion object {
        val CHANGE_BALANS = "Начислить бонусы"
        val HOLD_BALANS = "Заморозить бонусы"
        val CLEAR_AMOUNT = "Удалить бонусы"
    }

    override suspend fun getTitle(): String =
        "${member.lastName} ${member.firstName}${if (member.middleName == null) "" else " " + member.middleName}"

    private val actionPanel = ActionPanel().appendTo(contentLayout, grow = 0, shrink = 0)
    private val allowDeleteMember by permission(CorePermission.DELETE_MEMBERS)
    private val alloeEditStatuses by permission(CorePermission.EDIT_MEMBER_STATUSES)
    private val allowTestPromotion by permission(CorePermission.EDIT_MEMBER_TEST_PROMOTION)
    private val alloeChangeBalance by permission(CorePermission.CHANGE_MEMBER_BALANS)
    private val allowShowTags by permission(CorePermission.SHOW_TAGS)
    private val allowEdit = DEFAULT_SESSION!!.isAllow(CorePermission.EDIT_MEMBERS_INFO)
    private val allowEditTags = DEFAULT_SESSION!!.isAllow(CorePermission.EDIT_MEMBERS_TAGS)

    private val cardsLayout =
        DivLayout(direction = FlexLayout.Direction.Row).appendTo(contentLayout, grow = 1, shrink = 1)

    private val membeInfoCard = CardLayout()
        .appendTo(cardsLayout.layout, grow = 0, shrink = 1, basis = 335, align = FlexLayout.FlexItem.AlignSelf.Start)
        .also {
            it.layout.direction = FlexLayout.Direction.Column
            it.layout.wrap = FlexLayout.Wrap.Disable
            it.layout.alignItems = FlexLayout.AlignItems.Stretch
        }
    private val lastName = EditText(placeHolder = "Фамилия", readOnly = !allowEdit)
        .text(member.lastName)
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(membeInfoCard.layout, grow = 0, shrink = 0)
    private val firstName = EditText(placeHolder = "Имя", readOnly = !allowEdit)
        .text(member.firstName)
        .appendTo(membeInfoCard.layout, grow = 0, shrink = 0)
    private val middleName = EditText(placeHolder = "Отчество", readOnly = !allowEdit)
        .text(member.middleName ?: "")
        .appendTo(membeInfoCard.layout, grow = 0, shrink = 0)
    private val bd = DateEditText(
        placeHolder = "День рождения",
        readOnly = !allowEdit,
        allowEmpty = true,
        date = member.birthday?.let { Date.fromUTC(it) },
    )
        .appendTo(membeInfoCard.layout, grow = 0, shrink = 0)
    private val sexGroup = RadioBox.Group(readOnly = !allowEdit)
    private val sexMGroup = ElementGroup("Пол").appendTo(membeInfoCard.layout, grow = 0, shrink = 0)
    private val sexMale = RadioBox("Мужской").appendTo(sexMGroup.layout).group(sexGroup)
    private val sexFamale = RadioBox("Женский").appendTo(sexMGroup.layout).group(sexGroup)
    private val info = CardLayout().appendTo(cardsLayout.layout).also {
        it.layout.direction = FlexLayout.Direction.Column
        it.layout.wrap = FlexLayout.Wrap.Disable
        it.layout.alignItems = FlexLayout.AlignItems.Stretch
    }
    private val actionsList by Services.listByClass(MemberAction::class)

    private val accounts2 by lazy {
        membersService.getAccountsOfMember(member.id.jdto, true.jdto, 0.jdto, Int.MAX_VALUE.jdto).promise
    }

    private val navigation by Services.byClass(PageNavigation::class)

    init {
        contentLayout.alignItems = FlexLayout.AlignItems.Stretch
//        contentLayout.wrap = FlexLayout.Wrap.Enable
        contentLayout.alignContent = FlexLayout.AlignContent.Stretch

        if (allowDeleteMember) {
            BaseButton("Удалить клиента").click {
                if (YesNoDialog.show(
                        text = "Клиент будет удален безвозвратно. Вместе с тем все его транзакции и прочая связанная информация.\nВы уверены?",
                        width = 300,
                    ).await()
                ) {
                    console.info("Удаление клиента ${this@MemberInfoPage.member.id}...")
                    try {
                        val p = membersService.deleteMember(this@MemberInfoPage.member.id.jdto)
                        ProcessBlocker.block("Удаление клиента") {
                            p.await()
                        }.await()
                        MessageDialog.showInfo("Клиент #${this@MemberInfoPage.member.id} успешно удален")
                        console.info("Клиент ${this@MemberInfoPage.member.id} удален")
                    } catch (e: dynamic) {
                        console.error("ERROR", e)
                    }
                }
            }.appendTo(actionPanel)
        }
    }

    init {
        actionPanel.addSpace()
    }

    private val actionButton2 =
        Dropdown("Действия").appendTo(actionPanel) // .appendTo(membeInfoCard.layout, grow = 0, shrink = 0)
    private val properties = ElementGroup("Статусы").appendTo(membeInfoCard.layout, grow = 0, shrink = 0)
    private val status = Checkbox(label = "Разрешить операции", enabled = alloeEditStatuses).checked(member.active)
        .appendTo(properties.layout, grow = 0, shrink = 0)
        .also {
            it.EVENT_CHANGED.on {
                membersService.setEnabled(member.id.jdto, it.checked.jdto)
            }
        }
    private val allowDrop =
        Checkbox("Разрешить списание", enabled = alloeEditStatuses).appendTo(properties.layout, grow = 0, shrink = 0)
    private val testPromotion =
        Checkbox("Отладка лояльности", enabled = allowTestPromotion).appendTo(properties.layout, grow = 0, shrink = 0)

    private val properties2 = ElementGroup("Параметры").appendTo(membeInfoCard.layout, grow = 0, shrink = 0)
    private val properties3 = ValueList().appendTo(properties2.layout, grow = 0, shrink = 0)

    init {
        if (allowShowTags) {
            TagPanel3(memberId = member.id, readOnly = !allowEditTags)
                .withLabel("Метки")
                .appendTo(membeInfoCard.layout, grow = 0, shrink = 1)
        }
    }

    private suspend fun saveMemberInfo() {
        val lastName = if (lastName.isValid) lastName.text else member.lastName
        val firstName = if (firstName.isValid) firstName.text else member.firstName
        val middleName = if (middleName.isValid) middleName.text.takeIf { it.isNotBlank() } else member.middleName
        val sex = sexGroup.selected === sexMale
        console.info("Save BD -> ${bd.date}")
        val birthday = if (bd.isValid) bd.date?.timeUTC else member.birthday
        if (lastName != member.lastName ||
            firstName != member.firstName ||
            middleName != member.middleName ||
            sex != member.sex ||
            birthday != member.birthday
        ) {
            member = membersService.editMember(
                id = member.id.jdto,
                sex = sex.jdto,
                middleName = middleName?.jdto,
                lastName = lastName.jdto,
                firstName = firstName.jdto,
                birthDay = birthday?.jdto,
            ).await()
        }
    }

    init {
        sexGroup.selected = if (member.sex) {
            sexMale
        } else {
            sexFamale
        }

        firstName.eventChange.on {
            async2 {
                saveMemberInfo()
            }
        }

        lastName.eventChange.on {
            async2 {
                saveMemberInfo()
            }
        }

        middleName.eventChange.on {
            async2 {
                saveMemberInfo()
            }
        }

        sexGroup.eventChange.on {
            async2 {
                saveMemberInfo()
            }
        }

        bd.eventChange.on {
            async2 {
                saveMemberInfo()
            }
        }
    }

    lateinit var accounts: Promise<List<AccountDTO>>
    private val membersService by Services.byClass(MembersService::class)
    private val accountsService by Services.byClass(AccountsService::class)
    private fun refreshAccounts() {
        accounts = membersService.getAccountsOfMember(
            id = member.id.jdto,
            max = 100.jdto,
            offset = 0.jdto,
            withInfo = true.jdto,
        ).promise
    }

    init {
        refreshAccounts()
    }

    private val memberInfoRecords by Services.listByClass(MemberInfoRecord::class)
    private val memberBooleanProperty by Services.listByClass(MemberBooleanProperty::class)

    internal val tabs = Tabs().apply {
        dom.style.padding = "5px 0px 5px 0px"
    }.appendTo(info.layout, shrink = 0, grow = 0)
    val fragmentView = ComponentView().appendTo(info.layout, grow = 1, shrink = 1)

    private var firstStart = true

    private fun refresh() = async2 {
        val it = member
        refreshAccounts()
        eventTitleChange.dispatch()
    }

    override suspend fun onInit() {
        val actions = ArrayList<Pair<String, () -> Unit>>()
        if (alloeChangeBalance) {
            actions += CHANGE_BALANS to {
                async2 {
                    ChangeBalansDialog.show { amount, start, end, description ->
                        ProcessBlocker.block("Изменение баланса") {
                            accountsService.changeAmount(
                                id = member.id.jdto,
                                amount = amount.jdto,
                                start = start.jdto,
                                end = end?.let { it + start }?.jdto,
                                terminalId = null,
                                description = description.jdto,
                            ).await()
                        }.await()
                        properties3.refresh()
                        true
                    }
                }
                Unit
            }

            actions += HOLD_BALANS to {
                async2 {
                    HoldBalansDialog.show { amount, description ->
                        accountsService.hold(
                            memberId = member.id.jdto,
                            amount = amount.fix(2).jdto,
                            description = description?.jdto,
                        )
                        properties3.refresh()
                        true
                    }
                }
                Unit
            }
        }
        actionsList.forEach { action ->
            actions += action.title to {
                async2 {
                    action.action(member.id)
                }
                Unit
            }
        }
        actionButton2.items = actions.map { it.first }
        if (actions.isEmpty()) {
            actionButton2.dom.remove()
        }
        properties3.add("Доступно бонусов", member.allowBalans.toString())
        properties3.add("Не доступно бонусов", member.notAllowBalans.toString())
        memberInfoRecords.forEach { element ->
            properties3.add(element.title, element.getValue(member.id).await())
        }
        super.onInit()
        actionButton2.eventClick.on {
            actions[it].second()
        }
        allowDrop.checked = member.allowSub
        allowDrop.EVENT_CHANGED.on {
            membersService.setAllowSubtract(member.id.jdto, allowDrop.checked.jdto)
        }

        testPromotion.checked = member.testPromotion
        testPromotion.EVENT_CHANGED.on {
            membersService.setTestPromotion(member.id.jdto, testPromotion.checked.jdto)
        }
        val osmiCardEnabled = DEFAULT_SESSION!!.company.osmiCardEnabled
        val cardPrEnabled = DEFAULT_SESSION!!.company.cardPrEnabled
        if (osmiCardEnabled && (member.osmiCardCreated != null || member.osmiCardInstalled != null)) {
            val icon = when {
                member.osmiCardInstalled != null -> "cloud"
                member.osmiCardCreated != null -> "swap_horiz"
                else -> TODO()
            }
            Status(icon, "Синхронизация OSMI Card").appendTo(properties.layout, grow = 0, shrink = 0)
        }
        if (cardPrEnabled && member.cardPrInstallDate != null) {
            Status("cloud", "Синхронизация CardPR").appendTo(properties.layout, grow = 0, shrink = 0)
        }
        val parentId = membersService.getParentsRef(member.id.jdto, (-1).jdto, 0L.jdto).await().firstOrNull()
        if (parentId != null) {
            val parent = membersService.getMemberById(parentId).await()!!
            val parentIcon = when {
                osmiCardEnabled && parent.osmiCardInstalled != null -> "cloud"
                cardPrEnabled && parent.cardPrInstallDate != null -> "cloud"
                osmiCardEnabled && parent.osmiCardCreated != null -> "swap_horiz"
                else -> "   "
            }
            StatusLink(text = parent.fullName, href = "#/members/m${parentId.value}", materialIcon = parentIcon)
                .withLabel("Партнер")
                .appendTo(properties.layout, grow = 0, shrink = 0)
        }
        memberBooleanProperty.map {
            val ch = Checkbox(it.title).appendTo(properties.layout, grow = 0, shrink = 0)
            async2 {
                ch.enabled = false
                ch.checked = it.getValue(member.id)

                ch.EVENT_CHANGED.on {
                    ProcessBlocker.block("Изменение свойства") {
                        it.setValue(member.id, ch.checked)
                    }
                }
                ch.enabled = alloeEditStatuses
            }
        }

        cardsFragment = CardOfAccountFragment(member.id)

        val cartFragment = CartFragment(member.id)

        tabs.add("Карты") {
            fragmentView.set2(cardsFragment)
        }
        if (DEFAULT_SESSION!!.company.cartEnabled) {
            tabs.add("Корзина") {
                fragmentView.set2(cartFragment)
            }
        }

        var act = activeTab ?: 0
        if (act >= tabs.size) {
            act = tabs.size - 1
        }
        if (act >= 0) {
            tabs.active = act
        }
    }

    override suspend fun onStart() {
        super.onStart()

        refresh()

        firstStart = false
    }

    override suspend fun onStop() {
        super.onStop()
    }

    override suspend fun next(pageName: String): Page? {
        if (pageName.startsWith("a")) {
            val accountId = pageName.substring(1).toLongOrNull() ?: return super.next(pageName)
            return AccountPage(accountId)
        }
        return super.next(pageName)
    }

    private var activeTab by localStorage.int("MEMBERS:ACTIVE_TAB")
    private val contactHistoryFragment = ContactHistoryFragment(member.id)
    private val transactionsFragment = TransactionsFragment(member.id)
    private val referalsFragment = ReferalsFragment(member.id)
    private val journalView = JournalView(buyerId = member.id, userId = null)
    lateinit var cardsFragment: CardOfAccountFragment

    init {
        tabs.add("Транзакции") {
            fragmentView.set2(transactionsFragment)
        }
        tabs.add("Связи") {
            fragmentView.set2(referalsFragment)
        }
        tabs.event_ACTIVE_CHANGED.on {
            activeTab = it
        }

        tabs.add("Контакты") {
            fragmentView.set2(contactHistoryFragment)
        }
        tabs.add("Журнал") {
            fragmentView.set2(journalView)
        }

        accounts.then { acList ->
            if (acList.size == 1) {
                Extensions.accountExtensionFragments.use(dom) { el ->
                    val tab = tabs.add({ it.innerHTML = el.value.name }) {
                        fragmentView.set2(el.value.fragment(acList[0].id))
                    }
                }
            } else {
                MessageDialog.showError("У клиента несколько считов. Обратитесь к поддержку")
            }
        }
    }
}

class Status(materialIcon: String, text: String) : DivComponentWithLayout() {
    private val icon = MaterialIcon(materialIcon).appendTo(layout, grow = 0, shrink = 0)
    private val span = Span(text).addClass(Styles.SIMPLE_TEXT).appendTo(layout)

    init {
        icon.dom.style.paddingLeft = 8.px
        span.dom.style.paddingLeft = 13.px
        dom.style.lineHeight = 25.px
        dom.style.paddingLeft = "5px 0px"
    }
}

class StatusLink(materialIcon: String, text: String, href: String) : DivComponentWithLayout() {
    private val icon = MaterialIcon(materialIcon).appendTo(layout, grow = 0, shrink = 0)
    private val span = Link(text = text, href = href)
        .addClass(Styles.SIMPLE_TEXT)
        .addClass(Styles.LINK)
        .appendTo(layout)

    init {
        icon.dom.style.paddingLeft = 8.px
        span.dom.style.paddingLeft = 13.px
        dom.style.lineHeight = 25.px
        dom.style.paddingLeft = "5px 0px"
    }
}
