package org.tlsys.user

import kotlinx.browser.document
import org.tlsys.*
import org.tlsys.admin.ui.Span
import org.tlsys.admin.ui.Styles
import org.tlsys.admin.ui.backDivColorAlpha
import org.tlsys.cms.on
import org.tlsys.cms.onClick
import org.tlsys.css.CSS
import org.tlsys.css.animate
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLSpanElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.*
import kotlin.js.Promise

class UserIcon : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    init {
        dom.style.apply {
            //            background = "#e6e9ed"
            marginBottom = 16.px
            padding = 5.px
            overflow = "hidden"
            cursor = "pointer"
            background = "url('profile_bg.png')"
            backgroundSize = "cover"
        }
    }

    val layout = FlexLayout(dom).also {
        it.justifyContent = FlexLayout.JustifyContent.End
        it.direction = FlexLayout.Direction.Row
    }
    val nameDiv = FlexLayout.div().also {
        it.direction = FlexLayout.Direction.Column
        it.parent.style.paddingRight = 18.px
        it.parent.style.paddingTop = 7.px
    }.appendTo(layout, grow = 1, shrink = 0)

    val helloText = document.createElement("span").unsafeCast<HTMLSpanElement>().also {
        nameDiv.add(it) {
            grow = 1
            shrink = 0
        }
        it.style.apply {
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontSize = 14.px
            color = "#434a54"
            textAlign = "left"
            userSelect = "none"
            paddingLeft = 10.px
        }
        it.innerText = "Добро пожаловать,"
    }

    val nameText = Span().also {
        it.dom.style.apply {
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontWeight = "bold"
            fontSize = 16.px
            paddingLeft = 10.px
            color = "#3aafda"
        }
    }.appendTo(nameDiv, grow = 0, shrink = 0)

    /*
    val image = document.createElement("div").unsafeCast<HTMLDivElement>().also {
        it.style.apply {
            width = 48.px
            height = 48.px
            backgroundSize = "contain"
            backgroundColor = "white"
            borderRadius = 7.px
        }
    }.appendTo(layout, grow = 0, shrink = 0)
*/
    var userInfo: UserInfo? = null
        set(value) {
            field = value
            nameText.text = value?.name ?: ""
            /*
            val avatarPath = value?.avatar ?: ""
            val imagePath = if (avatarPath.isBlank())
                js("user_default_icon")
            else
                avatarPath
            image.style.backgroundImage = "url($imagePath)"
            */
        }

    init {
        dom.onClick {
            UserMenu.show(
                icon = this,
                items = listOf({ d: HTMLDivElement ->
                    d.innerText = "ВЫЙТИ"
                    d.style.apply {
                        fontFamily = Styles.DEFAULT_TEXT_FONT
                        fontSize = 16.px
                        color = "#d0021b"
                        fontWeight = "bold"
                        padding = 16.px
                    }
                    Unit
                }),
                sel = {
                    async2 {
                        TotalMessageConnector.logout()
                        document.location!!.reload()
                    }
                },
            )
        }
    }

    class UserInfo(val name: String, val avatar: String)
}

class UserMenu private constructor(
    val icon: UserIcon,
    items: List<(HTMLDivElement) -> Unit>,
    val selected: (Int?) -> Unit,
) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    val backDiv = document.createDiv().apply {
        classList.add(backStyle.name)
    }

    fun show() =
        backDiv.animate {
            duration = 100
            0 {
                opacity = 0.0
            }
            100 {
                opacity = 1.0
            }
        }.start()

    fun hide() = backDiv.animate {
        duration = 100
        0 {
            opacity = 1.0
        }
        100 {
            opacity = 0.0
        }
        onEnd {
            backDiv.style.display = "none"
        }
    }.start()

    private val layout = FlexLayout(dom, FlexLayout.Direction.Column)

    init {
        backDiv.appendChild(dom)
        backDiv.on("click") {
            if (it.target === backDiv) {
                selected(null)
            }
        }

        items.forEachIndexed { index, it ->
            val d = document.createDiv()
            d.appendTo(layout, grow = 0, shrink = 0)
            d.style.cursor = "pointer"
            it(d)
            d.addEventListener("click", {
                selected(index)
            })
        }
    }

    companion object {

        private val backStyle = CSS.style {
            position = "absolute"
            backgroundColor = "rgba(0,0,0,$backDivColorAlpha)"
            top = 0.px
            left = 0.px
            bottom = 0.px
            right = 0.px
        }

        private val style = CSS.style {
            //            left = "0px"
//            right = "0px"
//            top = "0px"
//            bottom = "0px"
            position = "absolute"
            backgroundColor = "#FFF"
            boxShadow = "0px 3px 5px 0px rgba(0,0,0,0.27)"
            borderRadius = "0px 0px 4px 4px"
        }

        fun show(icon: UserIcon, items: List<(HTMLDivElement) -> Unit>, sel: (Int) -> Unit) = Promise<Int?> { d, c ->
            var menu: UserMenu? = null
            var selected: ((Int?) -> Unit) = { index ->
                menu!!.hide().then {
                    menu!!.backDiv.remove()
                    if (index != null) {
                        sel(index)
                    }
                }
            }

            menu = UserMenu(icon, items, selected)
            menu.dom.classList.add(style.name)

            val iconPos = icon.dom.getBoundingClientRect()
            menu.dom.style.apply {
                left = iconPos.left.px
                width = iconPos.width.px
                top = iconPos.bottom.px
//                height = 300.px
            }

            document.body!!.appendChild(menu.backDiv)

            menu.show()
        }
    }
}
