package org.tlsys.admin.ui

import org.tlsys.admin.addClass
import org.tlsys.admin.events.EventElement
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.userSelect7
import org.w3c.dom.HTMLSpanElement
import org.w3c.dom.events.Event
import kotlinx.browser.document
import pw.binom.web.AbstractComponent

private object Styles1 {
    val NO_TEXT = "noText"
    val CHECKED = "checked"
    val ENBALED = "enabled"
    val css = CSS.style {
        position = "relative"
        height = 30.px
        display = "inline-block"
        border = "1px solid #ccd1d9"
        borderRadius = 16.px
        cursor = "pointer"
        ".${NO_TEXT}" then {
            transition = "300ms background-color linear"

            ".${ENBALED}" then {
                ".${CHECKED}" then {
                    backgroundColor = "#3bafda"
                }
                ":not(.${CHECKED})" then {
                    backgroundColor = "#fff"
                }
            }

            ":not(.${ENBALED})" then {
                ".${CHECKED}" then {
                    backgroundColor = "#D8D8D8"
                }
                ":not(.${CHECKED})" then {
                    backgroundColor = "#F5F5F5"
                }
            }
        }

        ":not(.${NO_TEXT})" then {
            ".${ENBALED}" then {
                backgroundColor = "#3bafda"
            }
            ":not(.${ENBALED})" then {
                backgroundColor = "#D8D8D8"
            }
        }
        ":not(.${ENBALED})" then {
            cursor = "default"
        }
    }
}

open class BigCheckboxNoText : BigCheckbox(" ", " ") {
    init {
        dom.style.width = "60px"
        dom.classList.add(Styles1.NO_TEXT)
    }

    override fun updateStyle() {
        super.updateStyle()
        if (checked) {
            dom.classList.add(Styles1.CHECKED)
        } else {
            dom.classList.remove(Styles1.CHECKED)
        }
    }

    override var checked: Boolean
        get() = super.checked
        set(value) {
            super.checked = value
            updateStyle()
        }
}

open class BigCheckbox(on: String, off: String) : AbstractComponent<HTMLSpanElement>() {
    override val dom: HTMLSpanElement = document.createElement("span").unsafeCast<HTMLSpanElement>()

    val EVENT_CHANGED = EventElement()
    val EVENT_ENABLED = EventElement()

    private val pos = (document.createElement("span") as HTMLSpanElement).apply {
        style.apply {
            position = "absolute"
            height = "30px"
            width = "50%"
            backgroundColor = "#ffffff"
            border = "1px solid #ccd1d9"
            top = "-2px"
            boxShadow = "0px 1px 3px 0px rgba(0,0,0,0.50)"
            borderRadius = "16px"
        }
        dom.classList.add(Styles1.ENBALED)
        this@BigCheckbox.dom.appendChild(this)

    }
    private var _enabled = true

    var enabled: Boolean
        get() = dom.classList.contains(Styles1.ENBALED)
        set(it) {
            if (it)
                dom.classList.add(Styles1.ENBALED)
            else
                dom.classList.remove(Styles1.ENBALED)
            EVENT_ENABLED.dispatch()
        }

    private val left = Span(on)
    private val right = Span(off)

    private var _checked: Boolean = true

    protected open fun updateStyle() {
        if (checked) {
            pos.style.apply {
                left = "50%"
            }
        } else {
            pos.style.apply {
                left = (-1).px
            }
        }
    }

    fun setCheckedForce(it: Boolean) {
        if (this._checked == it)
            return
        this._checked = it

        updateStyle()
    }

    open var checked: Boolean
        get() = _checked
        set(it) {
            if (this._checked == it)
                return
            //this._checked = it
            setCheckedForce(it)
            dom.dispatchEvent(Event("change"))
            EVENT_CHANGED.dispatch()
        }

    init {
        dom.classList.add(Styles1.css.name)
        dom.style.apply {
            /*
            position = "relative"
            height = "30px"
            display = "inline-block"
            backgroundColor = "#3bafda"
            border = "1px solid #ccd1d9"
            borderRadius = "16px"
            cursor = "pointer"
            */
        }


        dom.appendChild(left.dom)
        dom.appendChild(right.dom)



        left.addClass(Styles.SIMPLE_TEXT)
        right.addClass(Styles.SIMPLE_TEXT)
        left.dom.style.apply {
            userSelect7 = "none"
            left = 0.px
        }

        right.dom.style.apply {
            userSelect7 = "none"
            right = 0.px
        }

        _checked = false
        updateStyle()

        pos.style.transition = "100ms left linear"

        fun resetText(t: Span) {
            t.dom.style.apply {
                position = "absolute"
                color = "white"
                top = "7px"
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = "12px"
                color = "#424a54"
                textAlign = "center"
                width = "50%"
            }
        }
        resetText(left)
        resetText(right)

        dom.onclick = {
            if (enabled)
                checked = !checked
        }
    }
}