package org.tlsys.admin.ui

import org.tlsys.addClass
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.form.SimpleValidator
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.form.Validated
import org.tlsys.admin.form.Validator
import org.tlsys.core.Closeable
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.DivComponentWithLayout
import org.tlsys.ui.appendTo
import org.tlsys.ui.on
import pw.binom.web.layout.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLLabelElement
import kotlinx.browser.document
import kotlinx.dom.removeClass

class RadioBox(title: String) : DivComponentWithLayout() {

    class Group(readOnly: Boolean = false) : Validated {
        private val validator = SimpleValidator<Group>()

        val groupId = ++RADIO_ID_IT
        val eventChange = EventElement()

        fun validator(validator: Validator<Group>): Group {
            this.validator.addValidator(validator)
            return this
        }


        private val list = ArrayList<RadioBox>()
        var readOnly: Boolean = false
            set(value) {
                field = value
                list.forEach {
                    it.body.disabled = value
                }
            }

        fun add(radioBox: RadioBox): Group {
            radioBox.body.name = "group$groupId"
            radioBox.body.disabled = readOnly
            list += radioBox
            return this
        }

        var selected: RadioBox?
            get() = list.find { it.checked }
            set(value) {
                if (value == null) {
                    list.forEach {
                        it.checked = false
                        it.refresh()
                    }
                } else {
                    check(value in list)
                    list.forEach {
                        it.checked = it === value
                        it.refresh()
                    }
                }
                validator.updateValue(this)
            }

        init {
            this.readOnly = readOnly
            eventChange.on {
                list.forEach {
                    it.refresh()
                }
                validator.updateValue(this)
            }
        }


        override val valid: Boolean
            get() = validator.valid

        override fun onValidChange(listener: ValidListener): Closeable =
                validator.onValidChange(listener)
    }

    private companion object {
        var RADIO_ID_IT = 0
        val CHECKED = CSS.genName()
        val STYLE = CSS.style {
            padding = "10px 0px"
            "label"{
                cursor = "pointer"
                position = "relative"
                paddingLeft = 25.px
                color = "#9e9e9e"
                fontSize = Styles.DEFAULT_FONT_SIZE.px
                fontFamily = Styles.DEFAULT_TEXT_FONT
                userSelect = "none"
            }
            "label:before" {
                borderRadius = 2.px
                border = "2px solid #5a5a5a"
                content = "''"
                position = "absolute"
                left = 0.px
                width = 16.px
                height = 16.px
                borderRadius = "50%"
                transition = "border 280ms ease-in-out"
                boxSizing = "border-box"
            }

            "label:after"{
                transform = "scale(0)"
                border = "2px solid #5a5a5a"
                backgroundColor = "#5a5a5a"
                borderRadius = "50%"
                content = "''"
                position = "absolute"
                width = 16.px
                height = 16.px
                left = 0.px
                transition = "border 280ms ease-in-out, transform 280ms ease-in-out, background-color 280ms ease-in-out"
                boxSizing = "border-box"
            }

            ".$CHECKED" then {
                "label:before" {
                    border = "2px solid transparent"
                }

                "label:after" {
                    border = "2px solid #26a69a"
                    backgroundColor = "#26a69a"
                    transform = "scale(1)"
                }
            }
        }.name
    }

    private val body = document.createElement("input").unsafeCast<HTMLInputElement>()
            .appendTo(layout, grow = 0, shrink = 0)
    private val label = document.createElement("label").unsafeCast<HTMLLabelElement>()
            .appendTo(layout)

    @JsName("setCheckedValue")
    fun checked(value:Boolean=true): RadioBox {
        checked = value
        return this
    }

    private var group: Group? = null
    /*
        var group: Group? = null
            set(value) {
                field = value
                body.name = if (value == null)
                    ""
                else
                    "group${value.groupId}"
            }
    */
    fun group(group: Group): RadioBox {
        group.add(this)
        this.group = group
        group.eventChange.dispatch()
        return this
    }

    var checked: Boolean
        get() = body.checked
        set(value) {
            body.checked = value
            group?.eventChange?.dispatch()
            refresh()
        }

    private fun refresh() {
        if (body.checked)
            dom.addClass(CHECKED)
        else
            dom.removeClass(CHECKED)
    }

    init {
        body.style.apply {
            position = "absolute"
            left = (-9999).px
            opacity = "0"
        }
        dom.addClass(STYLE)
        body.type = "radio"
        body.id = "radio${RADIO_ID_IT++}"
        body.value = "radio${RADIO_ID_IT++}"
        body.on("change") {
            group?.eventChange?.dispatch()
            refresh()
        }
        label.htmlFor = body.id
        label.innerText = title
    }
}