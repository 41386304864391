package org.tlsys.goods

import org.tlsys.json.*

class DTODictionary(
    val id: String,
    val name: String,
    val autoImport: Boolean,
    val importUrl: String,
    val charset: String,
    val codeNormalization: Boolean,
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTODictionary> {
        override val type: String
            get() = "good_dictionary"

        override fun read(node: JsonNode) =
            DTODictionary(
                name = node["name"]!!.string(),
                id = node["id"]!!.string(),
                autoImport = node["autoImport"]!!.boolean(),
                importUrl = node["importUrl"]!!.string(),
                charset = node["charset"]!!.string(),
                codeNormalization = node["codeNormalization"]!!.boolean(),
            )

        override fun write(obj: DTODictionary): JsonNode =
            JsonNode.obj()
                .set("name", obj.name.json)
                .set("id", obj.id.json)
                .set("autoImport", obj.autoImport.json)
                .set("importUrl", obj.importUrl.json)
                .set("charset", obj.charset.json)
                .set("codeNormalization", obj.codeNormalization.json)
    }
}
