package org.tlsys.jobs

import org.tlsys.admin.SettingItem
import org.tlsys.admin.core.Remote
import org.tlsys.admin.core.Services
import org.tlsys.jobs.api.INVOKATOR
import org.tlsys.jobs.api.JobAdminService
import org.tlsys.jobs.dto.jobs_dto
import org.tlsys.jobs.pages.JobsPage
import org.tlsys.ui.Page

fun jobMain(remote: Remote) {
    Services.reg(remote.getService(JobAdminService::class, INVOKATOR, JobAdminService.SERVICE))
    jobs_dto.reg()

    Services.reg(object : SettingItem {
        override val uri: String
            get() = JobsPage.URI
        override val title: String
            get() = JobsPage.TITLE

        override suspend fun getPage(): Page = JobsPage()
    })
}
