package org.tlsys.core

import kotlin.coroutines.*
import kotlin.js.Promise

interface AsyncIterator<T> {
    suspend fun hasNext(): Boolean
    suspend fun next(): T
}

interface AsyncListIterator<T> : AsyncIterator<T> {
    fun back()
}

fun <T, R> AsyncIterator<T>.map(func: suspend (T) -> R) = object : AsyncIterator<R> {
    override suspend fun hasNext(): Boolean = this@map.hasNext()
    override suspend fun next(): R = func(this@map.next())
}

fun <T> List<T>.asyncIterator() = object : AsyncListIterator<T> {
    val it = listIterator()
    override suspend fun hasNext(): Boolean = it.hasNext()

    override suspend fun next(): T = it.next()

    override fun back() {
        it.previous()
    }
}

fun <T> Iterator<T>.asAsync() = object : AsyncIterator<T> {
    override suspend fun hasNext(): Boolean = hasNext()
    override suspend fun next(): T = next()
}

fun <T> asyncIteratorOf(vararg items: T) = items.toList().asyncIterator()
operator fun <T> AsyncIterator<T>.plus(other: AsyncIterator<T>) = FlatAsyncIterator(asyncIteratorOf(this, other))

fun <T> AsyncIterator<AsyncIterator<T>>.flat() = FlatAsyncIterator(this)

class FlatAsyncIterator<T>(val iterators: AsyncIterator<AsyncIterator<T>>) : AsyncIterator<T> {
    var current: AsyncIterator<T>? = null
    private suspend fun check() {
        while (current?.hasNext() != true) {
            if (current?.hasNext() == false) {
                current = null
            }
            if (current == null) {
                if (iterators.hasNext()) {
                    current = iterators.next()
                } else {
                    return
                }
            }
        }
    }

    override suspend fun hasNext(): Boolean {
        check()
        return current?.hasNext() ?: false
    }

    override suspend fun next(): T {
        check()
        val current = current ?: throw NoSuchElementException()
        return current.next()
    }
}

operator fun <T> AsyncListIterator<T>.plus(other: AsyncListIterator<T>) = object : AsyncListIterator<T> {

    init {
        if (other === this@plus) {
            throw IllegalArgumentException()
        }
    }

    var current: AsyncListIterator<T> = this@plus

    override suspend fun hasNext(): Boolean {
        if (current.hasNext()) {
            return true
        }
        if (current === other) {
            return false
        }
        current = other
        return this.hasNext()
    }

    override suspend fun next(): T {
        return try {
            current.next()
        } catch (e: NoSuchElementException) {
            if (current === other) {
                throw e
            }
            current = other
            next()
        }
    }

    override fun back() {
        try {
            current.back()
        } catch (e: NoSuchElementException) {
            if (current === this@plus) {
                throw e
            }
            current = this@plus
            back()
        }
    }
}

fun <T : Any> AsyncIterator<T?>.filterNotNull() = this.filter { it != null } as AsyncIterator<T>

fun <T> AsyncIterator<T>.filter(filter: suspend (T) -> Boolean) = object : AsyncIterator<T> {

    private var nextExist = false
    private var end = false
    private var next: T? = null
    private suspend fun refresh() {
        if (end) {
            return
        }
        if (nextExist) {
            return
        }
        while (this@filter.hasNext()) {
            next = this@filter.next()
            if (filter(next as T)) {
                nextExist = true
                return
            }
        }
        end = true
        next = null
    }

    override suspend fun hasNext(): Boolean {
        refresh()
        return nextExist
    }

    override suspend fun next(): T {
        refresh()
        if (nextExist) {
            nextExist = false
            return next as T
        } else {
            throw NoSuchElementException()
        }
    }
}

fun <T> asyncIteratorOf(func: () -> Promise<List<T>>) = object : AsyncListIterator<T> {

    private val list by lazy(func)
    private var cursor = 0

    override suspend fun hasNext(): Boolean = cursor < list.internalAwait().size

    override suspend fun next(): T {
        if (!hasNext()) {
            throw NoSuchElementException()
        }
        return list.internalAwait()[cursor++]
    }

    override fun back() {
        if (cursor == 0) {
            throw NoSuchElementException()
        }
        cursor--
    }
}

fun <T, R> AsyncListIterator<T>.map(func: suspend (T) -> R) = object : AsyncListIterator<R> {
    override fun back() {
        this@map.back()
    }

    override suspend fun hasNext(): Boolean = this@map.hasNext()
    override suspend fun next(): R = func(this@map.next())
}

class LoadIterator<T>(val min: Int, val loader: suspend (offset: Long) -> Array<T>) : AsyncListIterator<T> {
    override fun back() {
        offset--
    }

    private val list: Array<T> = js("([])")
    var offset = 0L
    var endded = false
    private var max = min

    private suspend fun loadNext() {
        if (endded || list.size > min) {
            return
        }

        val data = loader(offset)
        if (data.size < max) {
            endded = true
        } else {
            max = data.size
        }

        offset += data.size

        data.forEach {
            list.asDynamic().push(it)
        }
    }

    override suspend fun next(): T {
        loadNext()

        if (list.isEmpty()) {
            throw NoSuchElementException()
        }
        return list.asDynamic().shift()
    }

    override suspend fun hasNext(): Boolean {
        loadNext()
        return list.isNotEmpty()
    }
}

// private fun <T, V> (suspend V.() -> T).start(value: V) = Promise<T> { resolve, reject ->
//    this.startCoroutine(value, object : Continuation<T> {
//        override fun resumeWith(result: Result<T>) {
//            if (result.isSuccess)
//                result.onSuccess(resolve)
//            if (result.isFailure)
//                reject(result.exceptionOrNull()!!)
//        }
//
//        override val context = EmptyCoroutineContext
//    })
// }

// private fun <T> (suspend () -> T).start() = Promise<T> { resolve, reject ->
//    this.startCoroutine(object : Continuation<T> {
//        override fun resumeWith(result: Result<T>) {
//            if (result.isSuccess)
//                result.onSuccess(resolve)
//            if (result.isFailure)
//                reject(result.exceptionOrNull()!!)
//        }
//
//        override val context = EmptyCoroutineContext
//    })
// }
//
// private fun <T> async(c: suspend () -> T): Promise<T> = c.start()

private suspend fun <T> Promise<T>.internalAwait() = suspendCoroutine<T> { c ->
    then({ c.resume(it) }, { c.resumeWithException(it) })
}

// private fun Collection<Promise<*>>.join() = Promise.all(this.toTypedArray())
