package org.tlsys

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.ProcessBlocker
import org.tlsys.api.*
import org.tlsys.cms.Extensions
import org.tlsys.cms.ui.LayoutLevelManagerImpl
import org.tlsys.core.CORE_INVOKATOR
import org.tlsys.core.RemoteImpl
import org.tlsys.core.SEARCH_SERVICE
import org.tlsys.core.coreMain
import org.tlsys.core.promotion.addBonus.AddBonusPromotionDialog
import org.tlsys.core.promotion.birthdayPromotion.BirthdayPromotionDialog
import org.tlsys.core.promotion.bonusByPayWithLevels.BonusByPayedWithLevelsDialog
import org.tlsys.core.promotion.clearBonusOnUnusedPromotion.ClearBonusOnUnusedPromotionDialog
import org.tlsys.core.promotion.discountByPayWithLevels.DiscountByPayedWithLevelsDialog
import org.tlsys.core.promotion.discountByTagsPromotion.DiscountByTagsPromotionDialog
import org.tlsys.core.promotion.externalScriptPromotion.DiscountExternalPromotion
import org.tlsys.core.promotion.holidaysPromotion.HolidaysBonusPromotion
import org.tlsys.core.promotion.setMaxDiscount.SetMaxDiscountOrderPromotion
import org.tlsys.core.promotion.tagSetterPromotion.TagSetterPromotionDialog
import org.tlsys.core.promotion.tagsByPaymentPromotion.TagByPaymentPromotionDialog
import org.tlsys.core.search.SearchService
import org.tlsys.dto.admin_internal_dto
import org.tlsys.dto.auth.CorePermission
import org.tlsys.externalscripts.ExternalScriptSettings
import org.tlsys.file.fileMain
import org.tlsys.frontol.frontolMain
import org.tlsys.gateway.GatewayPage
import org.tlsys.goods.goodsMain
import org.tlsys.help.pipelines.StartWorkPipeline
import org.tlsys.jobs.jobMain
import org.tlsys.lk.lkMain
import org.tlsys.login.LoginWindow
import org.tlsys.login.RegWindow
import org.tlsys.members.MembersPage
import org.tlsys.members.list.ByCardMemberListProvider
import org.tlsys.members.list.ByNameMemberListProvider
import org.tlsys.members.list.ByPhoneMemberListProvider
import org.tlsys.security.PermissionPage
import org.tlsys.settings.CartSettings
import org.tlsys.settings.Settings
import org.tlsys.shops.ShopsPage
import org.tlsys.sms.SMSAccountListPage
import org.tlsys.tags.TagsPage
import org.tlsys.templates.TemplatesPage
import org.tlsys.ui.appendTo
import org.tlsys.ui.createDiv
import org.tlsys.user.UserIcon
import org.tlsys.wc.wsMain
import org.w3c.dom.events.KeyboardEvent
import pw.binom.web.layout.*

const val PAGE_CHANGE_ANIMATION_CHANGE = 400
var DIALOG_AUTO_FOCUS_ON_INPUT = true
private val layoutLevelManager = LayoutLevelManagerImpl(document.body!!)

private val bgImg = document.createDiv().apply {
    style.apply {
        backgroundImage = "url('login_bg.jpg')"
        top = 0.px
        bottom = 0.px
        left = 0.px
        right = 0.px
        position = "absolute"
    }
    document.body!!.appendChild(this)
}

private val MainViewLayout = ViewLayout(layoutLevelManager)

suspend fun showLogin() {
    LoginWindow.show { login, password ->
        try {
            SessionService.login(
                login = login,
                password = password,
            )
            showMainInterface()
            null
        } catch (e: BadLoginOrPassword) {
            "Не верный логин или пароль"
        }
    }
}

suspend fun showReg() {
    RegWindow.show { phone, password, companyName ->
        "Телефон уже зарегестрирован"
    }
}

suspend fun showMainInterface() {
    val adminPage = AdminPage().appendTo(layoutLevelManager.createLayout(js("({})")).dom).also {
        it.dom.style.height = "100%"
    }

    val pv = PageView().apply {
        dom.style.position = "relative"
    }.appendTo(adminPage.body.layout)

    val mainPageControl = HashPageControlImp(adminPage, DefaultPage(), pv, null)
    Services.reg(mainPageControl)

    document.addEventListener("keydown", {
        val event = it as? KeyboardEvent ?: return@addEventListener
        val dialog = MainViewLayout.last

        if (dialog != null) {
            dialog.onKeyDown(event)
        } else {
            mainPageControl.currentPage.onKeyDown(event)
        }
    })

    document.addEventListener("keyup", {
        val event = it as? KeyboardEvent ?: return@addEventListener
        val dialog = MainViewLayout.last

        if (dialog != null) {
            dialog.onKeyUp(event)
        } else {
            mainPageControl.currentPage.onKeyUp(event)
        }
    })

    ProcessBlocker.block("Загрузка страницы", true) {
        val hp = window.location.pathname.lastIndexOf('/')
        if (hp < 0) {
            throw RuntimeException("Can't get root application URL")
        }

        adminPage.navigation.userMenu.userInfo = UserIcon.UserInfo(name = DEFAULT_SESSION!!.user.name, avatar = "")
        TagsPage.regPluginPage()

        adminPage.navigation.add(uri = "search", text = "Клиенты", after = arrayOf(), imageUrl = "contacts")
        val showShops by permission(CorePermission.SHOW_SHOPS)
        if (showShops) {
            adminPage.navigation.add(
                uri = ShopsPage.URI,
                text = ShopsPage.TITLE,
                after = arrayOf(MembersPage.URI),
                imageUrl = "store",
            )
        }

        if (DEFAULT_SESSION!!.isAllow(OPermission.EDIT_SETTINGS)) {
            adminPage.navigation.add(
                uri = Settings.URI,
                text = Settings.TITLE,
                after = arrayOf(ShopsPage.URI, "promotions", "reports"),
                imageUrl = "supervisor_account",
            )
        }
        PermissionPage.regPluginPage()
        Extensions.readyListeners.dispatch()
        val readyDoneListener by Services.listByClass(ReadyDoneListener::class)
        readyDoneListener.forEach {
            try {
                it.onReady()
            } catch (e: dynamic) {
                console.error(e)
            }
        }

        val h = window.location.hash
        mainPageControl.start()
        if (h.isEmpty()) {
            mainPageControl.go("/search")
        }

        mainPageControl.addPageChangeListener { oldUrl, newUrl ->
            MainViewLayout.closeAll()
        }

        adminPage.navigation.buildMenu()
        bgImg.remove()
        window.setTimeout({
            if (HelpDialog.showOnNext()) {
                MainViewLayout.show(HelpDialog())
            }
        }, 1)
    }.await()
}

@JsExport
fun main(args: Array<String>) {
//    Jivo.init()
//    Motomo.init()
    admin_internal_dto.reg()
    val remote = RemoteImpl()
    Services.reg(remote)
    Services.reg(MainViewLayout)

    Services.reg(SMSAccountListPage.SMSSettingItem)
    Services.reg(CartSettings.CartSettingItem)
    Services.reg(GatewayPage.GatewaySettingItem)
    Services.reg(TemplatesPage.TemplatesSettingItem)
    Services.reg(ExternalScriptSettings.ExternalScriptSettingItem)
    Services.reg(BonusByPayedWithLevelsDialog)
    Services.reg(DiscountByPayedWithLevelsDialog)
    Services.reg(BirthdayPromotionDialog)
    Services.reg(ClearBonusOnUnusedPromotionDialog)
    Services.reg(DiscountByTagsPromotionDialog)
    Services.reg(SetMaxDiscountOrderPromotion)
    Services.reg(TagByPaymentPromotionDialog)
    Services.reg(HolidaysBonusPromotion)
    Services.reg(DiscountExternalPromotion)
    Services.reg(TagSetterPromotionDialog)
    Services.reg(AddBonusPromotionDialog)
    Services.reg(remote.getService(SearchService::class, invokator = CORE_INVOKATOR, setvice = SEARCH_SERVICE))

    Services.reg(remote.getService(ShopsService::class, invokator = ADMIN_INVOKATOR, setvice = SHOP_SERVICE))
    Services.reg(remote.getService(UsersService::class, invokator = ADMIN_INVOKATOR, setvice = USER_SERVICE))
    Services.reg(remote.getService(CardsService::class, invokator = ADMIN_INVOKATOR, setvice = CARD_SERVICE))
    Services.reg(
        remote.getService(
            RemoteCompanyService::class,
            invokator = ADMIN_INVOKATOR,
            setvice = REMOTE_COMPANY_SERVICE,
        ),
    )
    Services.reg(remote.getService(MembersService::class, invokator = ADMIN_INVOKATOR, setvice = MEMBERS_SERVICE))
    Services.reg(remote.getService(GroupService::class, invokator = ADMIN_INVOKATOR, setvice = GROUPS_SERVICE))
    Services.reg(remote.getService(RemoteCartService::class, invokator = ADMIN_INVOKATOR, setvice = CART_SERVICE))
    Services.reg(remote.getService(AccountsService::class, invokator = ADMIN_INVOKATOR, setvice = ACCOUNTS_SERVICE))
    Services.reg(
        remote.getService(
            ExPermissionManagerService::class,
            invokator = ADMIN_INVOKATOR,
            setvice = EXPERMISSIONMANAGERSERVICE_SERVICE,
        ),
    )
    Services.reg(remote.getService(MemberTagService::class, invokator = ADMIN_INVOKATOR, setvice = MEMBERTAGS_SERVICE))
    Services.reg(remote.getService(ContractsService::class, invokator = ADMIN_INVOKATOR, setvice = CONTRACTS_SERVICE))
    Services.reg(
        remote.getService(
            TransactionsService::class,
            invokator = ADMIN_INVOKATOR,
            setvice = TRANSACTIONS_SERVICE,
        ),
    )
    Services.reg(remote.getService(ProcessService::class, invokator = ADMIN_INVOKATOR, setvice = PROCESS_SERVICE))
    Services.reg(
        remote.getService(
            SMSManagerService::class,
            invokator = ADMIN_INVOKATOR,
            setvice = SMSManagerService.NAME,
        ),
    )
//    Services.reg(remote.getService(OsmicardTemplateService::class, invokator = ADMIN_INVOKATOR, setvice = OsmicardTemplateService.NAME))

    Services.reg(ByNameMemberListProvider)
    Services.reg(ByCardMemberListProvider)
    Services.reg(ByPhoneMemberListProvider)
    Services.reg(MemberBalansProvider())
    async2 {
        val loggined = ProcessBlocker.block("Восстановление сессии", withoutTimeout = true) {
            try {
                val cc = SessionService.resume()
                Core2ApiAccess.resume(cc.key)
//                Promise.sleep(5000)
//            Jivo.setSession(TotalMessageConnector.session)//TODO установить сессию
                showMainInterface()
                true
            } catch (e: SessionBreak) {
                false
            }
        }.await()
        if (!loggined) {
            showLogin()
            Jivo.setSession(null)
        }
    }

    document.body!!.style.display = ""

    coreMain(remote)
    goodsMain(remote)
    frontolMain(remote)
    jobMain(remote)
    wsMain(remote)
    lkMain(remote)
    fileMain(remote)

    val v: () -> Unit = {
        StartWorkPipeline().start()
    }

    window.asDynamic()["startHelp"] = v
}
