package org.tlsys.admin

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonNames
import org.tlsys.DateDuration
import org.tlsys.MoneyValue

@Serializable
class BonusAmountConfig(
    @JsonNames("bonusAmount")
    @SerialName("bonus_amount")
    val bonusAmount: MoneyValue,
    val start: DateDuration,
    val end: DateDuration?,
)
