package org.tlsys.gui

import kotlinx.browser.document
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLTableCellElement
import org.w3c.dom.HTMLTableElement
import org.w3c.dom.HTMLTableRowElement
import pw.binom.web.AbstractComponent

class InfoPlane : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    init {
        dom.classList.add("infoPlane")
    }

    private val table = (document.createElement("table") as HTMLTableElement).apply {
        dom.appendChild(this)
    }

    fun add(name: String, render: (HTMLTableCellElement) -> Unit): Column {
        val row = table.insertRow()
        val nameColumn = row.insertCell()
        nameColumn.innerHTML = name
        val column = row.insertCell() as HTMLTableCellElement
        val out = Column(column, render)
        out.draw()
        return out
    }

    class Column(val cell: HTMLTableCellElement, val render: (HTMLTableCellElement) -> Unit) {
        fun draw() {
            render(cell)
        }
    }
}
