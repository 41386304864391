package org.tlsys.core.api

import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.await
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.*
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class ApiPage : AbstractPage() {
    companion object {
        val TITLE = "API"
        val URI = "api"
    }

    override suspend fun getTitle(): String = TITLE
    private val api by Services.byClass(ApiService::class)
    private val apis by lazy { api.getApi().promise }
    private val scroll = ScrollController(dom)
    private val apiKey = EditText("API ключ авторизации", readOnly = true)
        .appendTo(contentLayout, grow = 0, shrink = 0)

    override suspend fun onInit() {
        super.onInit()

        val apis = apis.await()
        apiKey.text = api.getApiKey().await().value
        apis.forEach {
            ApiItem(it).appendTo(contentLayout, grow = 0, shrink = 0)
        }
    }

    private class ApiItem(val api: ApiService.ApiDto) :
        DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
        val description = Span(api.description.value).appendTo(layout, grow = 0, shrink = 0)

        init {
            description.dom.style.apply {
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.fontSizeH(3).px
                color = "rgba(0,0,0,.6)"
                paddingLeft = 10.px
            }
            api.methods.forEach {
                MethodItem(it).appendTo(layout, grow = 0, shrink = 0)
            }
        }
    }

    private class MethodItem(val method: ApiService.MethodDTO) : Card() {
        init {
            layout.direction = FlexLayout.Direction.Column
        }

        private companion object {
            val CODE_BLOCK = CSS.style {
                backgroundColor = "#F7F7F9"
                border = "1px solid #E1E1E8"
                padding = 10.px
                margin = 5.px
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.DEFAULT_FONT_SIZE.px
                display = "inline-block"
            }.name
        }

        val description = Span(method.description.value).appendTo(layout, grow = 0, shrink = 0)

        val rq = DivLayout().appendTo(layout, grow = 0, shrink = 0)
        val mm = Span(method.method.value).appendTo(rq.layout, grow = 0, shrink = 0).addClass(CODE_BLOCK)
        val path = Span(method.path.value).appendTo(rq.layout, grow = 0, shrink = 0).addClass(CODE_BLOCK)

        class HeadName(val title: String) : MaterialTable.Column() {
            init {
                dom.innerText = title
            }
        }

        class TextCol(text: String) : MaterialTable.Column() {
            init {
                dom.innerText = text
            }
        }

        class EmptyCol : MaterialTable.Column()

        class Argument(val arg: ApiService.ArgumentDTO) : MaterialTable.Row() {
            init {
                TextCol(arg.name.value).appendTo(this)
                TextCol(arg.description.value).appendTo(this)
            }
        }

        class ReturnCol(val arg: ApiService.ResponseDTO) : MaterialTable.Row() {
            init {
                TextCol(arg.code.value.toString()).appendTo(this)
                TextCol(arg.description.value).appendTo(this)
                val example = EmptyCol().appendTo(this)
                val description = arg.example?.value ?: ""
                if (description.isNotBlank()) {
                    val s = Span().addClass(CODE_BLOCK)
                    s.dom.innerHTML = description.replace("\n", "<br/>")
                    example.dom.appendChild(s.dom)
                }
            }
        }

        init {
            if (this.method.headers.isNotEmpty()) {
                Span("Заголовки запроса").appendTo(layout, grow = 0, shrink = 0).dom.style.apply {
                    fontFamily = Styles.DEFAULT_TEXT_FONT
                    fontSize = 18.px
                    paddingTop = 40.px
                }
                val headers = MaterialTable().appendTo(layout, align = FlexLayout.FlexItem.AlignSelf.Start)
                MaterialTable.Header().also {
                    HeadName("Название").appendTo(it)
                    HeadName("Описание").appendTo(it)
                    it.appendTo(headers)
                }
                this.method.headers.forEach {
                    Argument(it).appendTo(headers)
                }
            }

            if (this.method.arguments.isNotEmpty()) {
                Span("Параметры запроса").appendTo(layout, grow = 0, shrink = 0).dom.style.apply {
                    fontFamily = Styles.DEFAULT_TEXT_FONT
                    fontSize = 18.px
                    paddingTop = 40.px
                }
                val queryParams = MaterialTable().appendTo(layout, align = FlexLayout.FlexItem.AlignSelf.Start)
                MaterialTable.Header().also {
                    HeadName("Название").appendTo(it)
                    HeadName("Описание").appendTo(it)
                    it.appendTo(queryParams)
                }
                this.method.arguments.forEach {
                    Argument(it).appendTo(queryParams)
                }
            }

            if (this.method.response.isNotEmpty()) {
                Span("Ответ сервера").appendTo(layout, grow = 0, shrink = 0).dom.style.apply {
                    fontFamily = Styles.DEFAULT_TEXT_FONT
                    fontSize = 18.px
                    paddingTop = 40.px
                }
                val queryParams = MaterialTable().appendTo(layout, align = FlexLayout.FlexItem.AlignSelf.Start)
                MaterialTable.Header().also {
                    HeadName("HTTP код ответа").appendTo(it)
                    HeadName("Описание").appendTo(it)
                    HeadName("Пример").appendTo(it)
                    it.appendTo(queryParams)
                }
                this.method.response.sortedBy { it.code.value }.forEach {
                    ReturnCol(it).appendTo(queryParams)
                }
            }

            description.dom.style.apply {
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.fontSizeH(5).px
                color = "rgba(0,0,0,.6)"
            }
        }
    }
}
