package org.tlsys.admin.ui

import org.tlsys.async2
import org.tlsys.core.AsyncIterator
import org.tlsys.core.AsyncListIterator
import pw.binom.web.layout.*
import org.tlsys.ui.DivComponentWithLayout
import org.w3c.dom.HTMLElement
import pw.binom.web.Component
import pw.binom.web.ScrollController

class LoadableTableView<E : MaterialTable.Row>(
        val iterator: AsyncListIterator<E>,
        emptyText: String = "Нет элементов к отображению",
        val partCount: Int = 10) : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {

    private val noElementForView = NoElementForView(emptyText)
    private var inited = false
    private val table = MaterialTable().appendTo(layout)

    private val scroll = ScrollController(dom)

    private fun checkEmpty() {
        if (table.rows.isEmpty()) {
            if (noElementForView.dom.parentElement == null) {
                dom.appendChild(noElementForView.dom)
            }
        } else {
            if (noElementForView.dom.parentElement != null) {
                dom.removeChild(noElementForView.dom)
            }
        }
    }

    init {

    }

    override suspend fun onStart() {
        super.onStart()
        async2 {
            while (!scroll.visibleScrollY && iterator.hasNext()) {
                iterator.next().appendTo(table)
            }
            checkEmpty()
            if (!inited) {
                inited = true
                scroll.addScrollListener {
                    if (!scroll.endScrollY) {
                        return@addScrollListener
                    }
                    async2 {
                        for (i in 0 until partCount)
                            if (iterator.hasNext()) {
                                iterator.next().appendTo(table)
                                checkEmpty()
                            } else
                                break
                    }
                }
            }
        }
    }
}

class LoadableListView<E : Component<HTMLElement>>(
        val iterator: AsyncIterator<E>,
        emptyText: String = "Нет элементов к отображению",
        header: Component<out HTMLElement>? = null,
        val partCount: Int = 10) : ListView<E>(emptyText, header) {

    private var inited = false

    override suspend fun onStart() {
        super.onStart()
        async2 {
            while (!scroll.visibleScrollY && iterator.hasNext()) {
                addLast(iterator.next())
            }
            if (!inited) {
                inited = true
                scroll.addScrollListener {
                    if (!scroll.endScrollY) {
                        return@addScrollListener
                    }
                    async2 {
                        for (i in 0 until partCount)
                            if (iterator.hasNext()) {
                                addLast(iterator.next())
                            } else
                                break
                    }
                }
            }
        }
    }
}