package org.tlsys.jobs.dialogs

import kotlinx.browser.document
import kotlinx.browser.window
import libs.asDateString
import libs.asDateUtc
import libs.asTimeString
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.FileService
import org.tlsys.api.ProcessService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.jobs.dto.ExecutionDTO
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.tlsys.ui.createLink
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.layout.*

class ExecutionDialog(val execution: ExecutionDTO) : TitleDialog("Задача") {
    private val body = SaveCancelBody(okText = "Закрыть", cancelVisible = false).appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val start = execution.start.value.asDateUtc
    private val end = execution.end?.value?.asDateUtc

    val startLabel = Span("${start.asDateString} ${start.asTimeString}").addClass(Styles.SIMPLE_TEXT)
        .withLabel("Время начала выполнения")
        .appendTo(layout, grow = 0, shrink = 0)
    val endLabel = Span(
        if (end == null) {
            "Выполняется"
        } else {
            "${end.asDateString} ${end.asTimeString}"
        },
    ).addClass(Styles.SIMPLE_TEXT)
        .withLabel("Время окончания выполнения")
        .appendTo(layout, grow = 0, shrink = 0)

    private val statusLabel = Span("").addClass(Styles.SIMPLE_TEXT)
        .withLabel("Время начала выполнения")
        .appendTo(layout, grow = 0, shrink = 0)

    private val files = ListView<FileItem>().withLabel("Файлы").appendTo(layout)
    private val processService by Services.byClass(ProcessService::class)

    private suspend fun refreshProgress() {
        val process = processService.getProcess(execution.processId).await()
        if (end != null) {
            endLabel.element.text = "${end.asDateString} ${end.asTimeString}"
            statusLabel.element.text = if (process?.success == true) "Завершен успешно" else "Завершен провалом"
        } else {
            endLabel.element.text = "Нет"

            if (process == null) {
                statusLabel.element.text = "Процесс не найден."
            } else {
                if (process.max != null) {
                    statusLabel.element.text = "Выполнено ${process.progress}/${process.max}"
                } else {
                    statusLabel.element.text = "Обработано ${process.progress}"
                }
            }
        }
    }

    private var updateTimer = 0
    override suspend fun onStart() {
        super.onStart()
        refreshProgress()
        updateTimer = window.setInterval({
            async2 {
                refreshProgress()
            }
        }, 1000)
    }

    override suspend fun onStop() {
        window.clearInterval(updateTimer)
        super.onStop()
    }

    init {
        dom.style.apply {
            height = 600.px
        }
        body.ok {
            close()
        }

        onInit {
            execution.files.forEach {
                files.element.addLast(FileItem(it.value.toLong()))
            }
        }
    }

    private class FileItem(val fileId: Long) : AbstractComponent<HTMLDivElement>() {
        override val dom: HTMLDivElement = document.createDiv()
        private val layout = FlexLayout(this, alignItems = FlexLayout.AlignItems.Center)
        private val link = document.createLink().addClass(Styles.LINK).addClass(Styles.SIMPLE_TEXT).appendTo(layout)

        private val fileService by Services.byClass(FileService::class)

        private val file by lazy {
            fileService.getFileById(fileId.jdto).promise
        }

        private val url by lazy {
            fileService.getFileUrl(fileId.jdto).promise
        }

        init {
            dom.addClass(Styles.LIST_ITEM_STYLE)
        }

        override suspend fun onStart() {
            super.onStart()
            link.text = "Файл #$fileId"
            link.text = "${file.await()!!.name.value}.${file.await()!!.ext.value}"
            link.href = url.await()!!.value
        }
    }

    companion object {
        suspend fun show(execution: ExecutionDTO) {
            val dd = ExecutionDialog(execution)
            dd.show()
        }
    }
}
