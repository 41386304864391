package org.tlsys.externalscripts

import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.dto.ExternalScriptDto
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.url.toURI
import pw.binom.uuid.nextUuid
import pw.binom.web.layout.*
import kotlin.random.Random

class ExternalScriptDialog(val new: Boolean, val dto: ExternalScriptDto?) :
    TitleDialog(if (new) "Новый внешний скрипт" else "Внешний скрипт") {
    private val body = SaveCancelBody(okText = if (new) "Создать" else "Сохранить").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private var result: ExternalScriptDto? = null

    private val name = EditText(placeHolder = "Название")
        .text(dto?.name ?: "")
        .textValidator(TextValidators.NOT_BLANK and TextValidators.maxLength(126))
        .appendTo(layout, grow = 0, shrink = 0)

    private val url = EditText(placeHolder = "Адрес")
        .text(dto?.url?.toString() ?: "")
        .textValidator(TextValidators.URL and TextValidators.maxLength(1024))
        .appendTo(layout, grow = 0, shrink = 0)

    private val auth = EditText(placeHolder = "Токен аутентификации")
        .text(dto?.auth ?: "")
        .textValidator(TextValidators.maxLength(126) or TextValidators.EMPTY)
        .appendTo(layout, grow = 0, shrink = 0)

    private val debug = Checkbox(label = "Отладка", checked = dto?.debug ?: false)
        .appendTo(layout, grow = 0, shrink = 0)

    private fun build() = ExternalScriptDto(
        id = if (new || dto == null) Random.nextUuid() else dto.id,
        name = name.text,
        url = url.text.toURI(),
        auth = auth.text.takeIf { it.isNotBlank() },
        debug = debug.checked,
    )

    override fun onEscapeClick() {
        super.onEscapeClick()
        result = null
        close()
    }

    init {
        dom.style.apply {
            width = 600.px
            height = 300.px
        }
        MultiValidator(name, url, auth)
            .connectWith(body.okBtn)

        body.ok {
            result = build()
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    companion object {
        suspend fun show(dto: ExternalScriptDto?, new: Boolean): ExternalScriptDto? {
            val d = ExternalScriptDialog(dto = dto, new = new)
            d.show()
            return d.result
        }
    }
}
