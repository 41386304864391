package org.tlsys.login

import kotlinx.browser.document
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.px
import org.tlsys.ui.DivLayout
import org.tlsys.ui.addClass
import pw.binom.web.layout.*

class SuccessWindow private constructor(title: String, message: String) : TitleDialog(title) {
    init {
        dom.style.width = 300.px
        Span(message)
            .appendTo(layout, grow = 0, shrink = 0)
            .addClass(Styles.SIMPLE_TEXT)
            .addClass(Styles.TEXT_CENTER)

        Span("Теперь вы можете перейти в личный кабинет и начать пользоваться TradeLine")
            .appendTo(layout, grow = 0, shrink = 0)
            .addClass(Styles.SIMPLE_TEXT)
            .addClass(Styles.TEXT_CENTER)

        DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
    }

    private val btn = ConfirmButton("Перейти")
        .appendTo(layout, grow = 0, shrink = 0)
        .onClick {
            document.location!!.reload()
        }

    companion object {
        fun show(title: String, message: String) {
            async2 {
                SuccessWindow(
                    title = title,
                    message = message,
                ).show()
            }
        }
    }
}
