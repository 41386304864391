package org.tlsys.gateway

import org.tlsys.GatewayEventType
import org.tlsys.Template
import org.tlsys.TemplateType
import org.tlsys.admin.GatewayProvider
import org.tlsys.admin.GatewayStatus
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.api.TemplateService
import org.tlsys.cms.click
import org.tlsys.serialization.Gateway
import org.tlsys.ui.*
import org.tlsys.ui.DivLayout
import org.tlsys.ui.before
import org.w3c.dom.HTMLDivElement
import pw.binom.date.DateTime
import pw.binom.uuid.nextUuid
import pw.binom.web.layout.*
import kotlin.random.Random

abstract class GatewayDialog(new: Boolean, val gateway: Gateway?) : TitleDialog("Шлюз") {
    val body = SaveCancelBody(okText = if (new) "Создать" else "Сохранить").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private var result: Gateway? = null

    override fun onEscapeClick() {
        result = null
        close()
    }

    init {
        title = "Шлюз ${getGatewayProvider().title}"
    }

    private val tt = DivLayout(direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)
    private val name =
        EditText("Название").text(gateway?.title ?: getGatewayProvider().title)
            .textValidator(TextValidators.NOT_BLANK)
            .appendTo(tt.layout, grow = 1, shrink = 1)
    private val status =
        Combobox2(
            items = GatewayStatus.values().map { it.title },
            select = gateway?.status?.ordinal ?: 0,
        )
            .appendTo(tt.layout, grow = 1, shrink = 1)
    private val template = Combobox2().withLabel("Шаблон").appendTo(layout, grow = 0, shrink = 0)
    protected val eventsPanel = DivLayout().withLabel("События").appendTo(layout, grow = 0, shrink = 0)
    private val events = GatewayEventType.values().map {
        Checkbox(
            label = it.title,
            checked = gateway?.eventTypes?.let { e -> e and it.mask != 0 } ?: true,
        ).appendTo(
            eventsPanel.content.layout,
            grow = 0,
            shrink = 0,
        )
    }

    protected abstract fun getConfig(): String
    protected abstract fun getGatewayProvider(): GatewayProvider
    protected val multiValidator = MultiValidator(name)

    private val testButton =
        BaseButton("Тест").appendTo(body.buttonsLayout, grow = 0, shrink = 0).before(body.cancelBtn)
    private lateinit var templates: List<Template>
    protected abstract suspend fun testClick(gateway: Gateway)

    override suspend fun onInit() {
        super.onInit()
        val templateType = when (getGatewayProvider()) {
            GatewayProvider.SMSC -> TemplateType.SMS
            GatewayProvider.SMS_RU -> TemplateType.SMS
            GatewayProvider.MTC_CONNECT -> TemplateType.SMS
            GatewayProvider.INTERNAL_TELEGRAM -> TemplateType.TELEGRAM
            GatewayProvider.INTERNAL_SMTP -> TemplateType.EMAIL
        }
        templates = TemplateService.getList().filter { it.type == templateType }

        template.content.items = listOf("Нет") + templates.map { it.name }
        val selectedTemplate = gateway?.templateId
        template.content.select = if (selectedTemplate != null) {
            val index = templates.indexOfFirst { it.id == selectedTemplate }
            if (index == -1) {
                0
            } else {
                index + 1
            }
        } else {
            0
        }
    }

    private fun makeGateway() = Gateway(
        id = gateway?.id ?: Random.nextUuid(),
        title = name.text,
        status = GatewayStatus.values()[status.select],
        provider = getGatewayProvider(),
        createDate = gateway?.createDate ?: DateTime.now,
        changeDate = DateTime.now,
        eventTypes = events.foldIndexed(0) { index, acc, checkbox ->
            if (checkbox.checked) {
                GatewayEventType.values()[index].mask or acc
            } else {
                acc
            }
        },
        config = getConfig(),
        templateId = if (template.content.select == 0) null else templates[template.content.select - 1].id,
    )

    init {
        testButton.click {
            testClick(makeGateway())
        }
        multiValidator.onValidChange {
            body.okBtn.enabled = it
        }
        multiValidator.onValidChange {
            testButton.enabled = it
        }
        body.ok {
            result = makeGateway()
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    protected suspend fun showAndGetResult(): Gateway? {
        show()
        return result
    }
}
