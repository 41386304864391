package org.tlsys.core.promotion

import org.tlsys.ParamUIService
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.or
import org.tlsys.admin.ui.EditText
import org.tlsys.admin.ui.SaveCancelBody
import org.tlsys.admin.ui.TitleDialog
import org.tlsys.admin.ui.textValidator
import org.tlsys.build
import org.tlsys.px
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.FlexLayout
import pw.binom.web.layout.appendTo

class PromotionSettings(
    val script: AdminPromotionService.ScriptDTO,
    val dto: AdminPromotionService.PromotionDTO?,
) : TitleDialog(script.title) {
    private val buttons = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = buttons.componentsLayout

    init {
        dom.style.width = 800.px
    }

    private val promotionTitle1 = EditText(placeHolder = "Описание").appendTo(layout, grow = 0, shrink = 0)
        .textValidator(TextValidators.NOT_BLANK or TextValidators.EMPTY)

    val promotionTitle
        get() = promotionTitle1.text

    private val validator = MultiValidator()
    private val paramsService by Services.byClass(ParamUIService::class)
    private val params1 = paramsService.build(settings = script.settings, values = dto?.params)
    private var canceled = false

    init {
        promotionTitle1.text = dto?.title ?: ""
        params1.onValidChange {
            buttons.okBtn.enabled = it
        }
        buttons.ok {
            canceled = false
            close()
        }
        buttons.cancel {
            canceled = true
            close()
        }

        params1.appendTo(layout)
        validator.addValidated(params1)
    }

    companion object {

        suspend fun show(
            script: AdminPromotionService.ScriptDTO,
            dto: AdminPromotionService.PromotionDTO?,
        ): Pair<String, String>? {
            val dd = PromotionSettings(script, dto)
            dd.show()
            return if (dd.canceled) {
                null
            } else {
                dd.promotionTitle to jsonData(dd.params1.values())
            }
        }
    }
}

private fun jsonData(data: Map<String, String>): String {
    val json = js("({})")
    data.map {
        json[it.key] = it.value
    }
    return JSON.stringify(json)
}
