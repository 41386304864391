package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.ui.createDiv
import org.tlsys.ui.on
import org.w3c.dom.*
import pw.binom.web.AbstractComponent

@Deprecated(level = DeprecationLevel.WARNING, message = "Deplicated")
class Table(val model: TableDataProvider, val render: (TablePoint<*>) -> Unit) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    private val table = document.createElement("table") as HTMLTableElement

    init {
        dom.appendChild(table)
    }

    val head: HTMLTableSectionElement
    val body: HTMLTableSectionElement

    init {
        head = document.createElement("thead") as HTMLTableSectionElement
        table.appendChild(head)

        body = document.createElement("tbody") as HTMLTableSectionElement
        table.appendChild(body)

        table.classList.add("data_table")
        table.style.apply {
            width = "100%"
            // height = "100%"
        }
    }

    private var _headerVisible: Boolean = false

    /**
     * Отображать или не отображать заголовок таблицы
     */
    var headerVisible: Boolean
        get() = _headerVisible
        set(it) {
            if (it) {
                head.style.display = ""
            } else {
                head.style.display = "none"
            }
            _headerVisible = it
        }

    init {
        headerVisible = true
    }

//    val selectedRows = HashSet<Int>()

    private fun drawRow(index: Int, row: HTMLTableRowElement, isNew: Boolean) {
        var x: Int = 0
        var i = 0
        while (x < model.columnSize) {
            val cel: HTMLTableCellElement
            if (isNew) {
                cel = row.insertCell() as HTMLTableCellElement
            } else {
                cel = row.cells[x] as HTMLTableCellElement
            }
            render(TablePoint(table = this, row = index, column = x, value = model[x, index], body = cel, /*selected = selected, */update = !isNew))
            x += cel.colSpan
            i++
        }
    }

    fun getNativeRow(index: Int) = body.rows[index] as HTMLTableRowElement

    fun fireChanged(rowIndex: Int) {
        val row = body.rows[rowIndex] as HTMLTableRowElement
        drawRow(rowIndex, row, false)
    }

    fun fireInsert(rowIndex: Int, size: Int) {
        if (size <= 0) {
            return
        }

        for (i in rowIndex..rowIndex + size - 1) {
            val column = body.insertRow(i) as HTMLTableRowElement
            drawRow(i, column, true)
        }
        for (g in rowIndex + size..model.rowSize - 1)
            drawRow(g, body.rows[g] as HTMLTableRowElement, false)
    }

    fun fireInsert(rowIndex: Int) {
        val column = body.insertRow(rowIndex) as HTMLTableRowElement
        // val column = document.createElement("tr") as HTMLTableRowElement
        // body.insertChild(column, rowIndex)
        drawRow(rowIndex, column, true)
        for (g in rowIndex + 1..model.rowSize - 1)
            drawRow(g, body.rows[g] as HTMLTableRowElement, false)
    }

    fun fireDeleted(rowIndex: Int) {
        body.deleteRow(rowIndex)
        for (g in rowIndex until model.rowSize)
            drawRow(rowIndex, body.rows[g] as HTMLTableRowElement, false)
    }

    fun repaint() {
        for (y in 0..model.rowSize - 1) {
            val column = body.rows[y] as HTMLTableRowElement
            drawRow(y, column, false)
        }
    }

    fun refresh() {
        while (head.rows.length > 0)
            head.deleteRow(0)

        for (i in 0..body.rows.length - 1)
            body.deleteRow(0)

        var x = 0
        var i = 0
        val headRow = head.insertRow() as HTMLTableRowElement
        while (x < model.columnSize) {
            val cel = headRow.insertCell(i) as HTMLTableCellElement
            render(TablePoint(this, -1, x, model[x], cel, false))
            x += cel.colSpan
            i++
        }

        for (y in 0..model.rowSize - 1) {
            val column = body.insertRow(y) as HTMLTableRowElement
            // column.addEventListener("mousedown", onRowClick)
            drawRow(y, column, true)
        }
    }
}

@Deprecated(level = DeprecationLevel.WARNING, message = "Deplicated")
class TablePoint<V>(val table: Table, val row: Int, val column: Int, val value: V, val body: HTMLTableCellElement, /*val selected: Boolean, */val update: Boolean)

@Deprecated(level = DeprecationLevel.WARNING, message = "Deplicated")
interface TableDataProvider {
    val rowSize: Int
    val columnSize: Int
    operator fun get(column: Int): String
    operator fun get(column: Int, row: Int): Any?
}

@Deprecated(level = DeprecationLevel.WARNING, message = "Deplicated")
fun Table(rowSizeProvider: () -> Int, builder: TableBuilder.() -> Unit): Table {
    val g = TableBuilder(rowSizeProvider)
    g.builder()
    return g.build()
}

@Deprecated(level = DeprecationLevel.WARNING, message = "Deplicated")
class TableBuilder(val rowSizeProvider: () -> Int) : TableDataProvider {
    val columns = ArrayList<ColumnRecord>()
    override val rowSize: Int
        get() = rowSizeProvider()

    override val columnSize: Int
        get() = columns.size

    override fun get(index: Int): String = columns[index].name

    override fun get(column: Int, row: Int): Any? = columns[column].dataProvider(row)

    class ColumnRecord(val name: String, val dataProvider: (Int) -> Any?) {
        var render: ((TablePoint<*>) -> Unit)? = null
    }

    class Trigger() {
        val list = ArrayList<(TablePoint<*>) -> Unit>()
        val includes = ArrayList<(TablePoint<*>) -> Boolean>()
        val excludes = ArrayList<(TablePoint<*>) -> Boolean>()

        fun include(f: (TablePoint<*>) -> Boolean) {
            includes += f
        }

        fun exclude(f: (TablePoint<*>) -> Boolean) {
            excludes += f
        }

        operator fun plusAssign(f: (TablePoint<*>) -> Boolean) {
            includes += f
        }

        operator fun minusAssign(f: (TablePoint<*>) -> Boolean) {
            excludes += f
        }

        fun draw(f: (TablePoint<*>) -> Unit) {
            list += f
        }

        fun test(point: TablePoint<*>): Boolean {
            var result: Boolean = false
            for (f in includes)
                if (f(point)) {
                    result = true
                    break
                }

            for (f in excludes) {
                if (f(point)) {
                    result = false
                    break
                }
            }
            return result
        }

        fun use(point: TablePoint<*>) {
            for (p in list)
                p(point)
        }
    }

    val triggers = ArrayList<Trigger>()
    fun trigger(t: Trigger.() -> Unit): TableBuilder {
        val trigger = Trigger()
        trigger.t()
        triggers += trigger
        return this
    }

    fun <V> column(name: String, dataProvider: (Int) -> V, render: ((TablePoint<V>) -> Unit)? = null): TableBuilder {
        val cl = ColumnRecord(name, dataProvider)
        val columnId = columns.size
        if (render != null) {
            trigger {
                exclude { it.row == -1 }
                include { it.row >= 0 }

                exclude { it.column != columnId }
                include { it.column == columnId }
                draw {
                    render(it as TablePoint<V>)
                }
            }
        }
        // cl.render = render as ((TablePoint<*>) -> Unit)?
        columns += cl
        return this
    }

    class ActionBuilder(val point: TablePoint<Unit>) {
        fun text(text: String, clickListener: Button.(Int) -> Unit) {
            val b = Button(text)
            point.body.appendChild(b.dom)
            b.dom.classList.add("floatButton")
            b.dom.on("click") {
                b.clickListener(point.row)
            }
        }

        fun image(image: String, clickListener: Button.(Int) -> Unit) {
            val b = Button()
            point.body.appendChild(b.dom)
            b.dom.classList.add("floatButton")
            val img = document.createElement("img") as HTMLImageElement
            img.src = image
            b.dom.appendChild(img)
            b.dom.on("click") {
                b.clickListener(point.row)
            }
        }
    }

    fun actions(name: String = "", builder: ActionBuilder.() -> Unit): TableBuilder {
        return column(name, {}) {
            for (i in 0..it.body.childNodes.length - 1) {
                it.body.removeChild(it.body.childNodes[0]!!)
            }
            ActionBuilder(it).builder()
            it.body.classList.add("actions")
        }
    }

    fun build(): Table = Table(this) {
        if (columns[it.column].render !== null) {
            columns[it.column].render!!(it)
        }

        for (t in triggers)
            if (t.test(it)) {
                t.use(it)
            }
    }
}
