package org.tlsys.dto

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.DtoDescriptionRU
import org.tlsys.serialization.DurationMillisecondsSerializer
import org.tlsys.serialization.URISerializer
import pw.binom.uuid.UUID
import pw.binom.url.URI
import pw.binom.openapi.annotation.Description
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

@DtoDescriptionRU("Манифест провайдера внешних акций")
@Description("Манифест провайдера внешних акций")
@Serializable
@SerialName("ExternalManifest")
class ExternalManifest(
    @DtoDescriptionRU("Общее название акций")
    @Description("Общее название акций")
    val title: String,
    @DtoDescriptionRU("Список предоставляемых акций")
    @Description("Список предоставляемых акций")
    val promotions: List<Promotion> = emptyList(),
    val provider: Provider? = null,
) {
    @DtoDescriptionRU("Информация о поставщике ")
    @Description("Информация о поставщике ")
    @Serializable
    @SerialName("ExternalManifest.Provider")
    class Provider(
        @DtoDescriptionRU("Название провайдера услуг")
        @Description("Название провайдера услуг")
        val name: String,
        @DtoDescriptionRU("Информация о поддержке провайдера")
        @Description("Информация о поддержке провайдера")
        val support: String,
    )

    @DtoDescriptionRU("Объект описания интерфейса пользователя")
    @Description("Объект описания интерфейса пользователя")
    @Serializable
    @SerialName("ExternalManifest.UI")
    class UI(
        @DtoDescriptionRU("Адрес IFrame для отображения в административном интерфейсе")
        @Description("Адрес IFrame для отображения в административном интерфейсе")
        @Serializable(URISerializer::class)
        val uri: URI,
        @DtoDescriptionRU("Исходная ширина окна. Нужно использовать CSS стиль. Например \"300px\"")
        @Description("Исходная ширина окна. Нужно использовать CSS стиль. Например \"300px\"")
        val width: String? = null,
        @DtoDescriptionRU("Исходная высота окна. Нужно использовать CSS стиль. Например \"500px\"")
        @Description("Исходная высота окна. Нужно использовать CSS стиль. Например \"500px\"")
        val height: String? = null,
    )

    @Serializable
    @SerialName("ExternalManifest.Promotion")
    class Promotion(
        @DtoDescriptionRU("Внутренний идентификатор акции. Для каждой акции должен быть константен")
        @Description("Внутренний идентификатор акции. Для каждой акции должен быть константен")
        @Contextual
        @SerialName("uuid")
        val uuid: UUID,

        val ui: UI? = null,

        @DtoDescriptionRU("Адрес сервиса обработчика акции")
        @Description("Адрес сервиса обработчика акции")
        @Serializable(URISerializer::class)
        @SerialName("execute_url")
        val executeUrl: URI? = null,

        @DtoDescriptionRU("Время ответа. По умолчанию 10 сек.")
        @Description("Время ответа. По умолчанию 10 сек.")
        @Serializable(DurationMillisecondsSerializer::class)
        val timeout: Duration? = 10.seconds,

        @DtoDescriptionRU("Название акции")
        @Description("Название акции")
        val title: String,
    )
}
