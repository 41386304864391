package org.tlsys.core.promotion.externalScriptPromotion

import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.OrderPromotionType
import org.tlsys.core.promotion.OrderPromotionEditor
import org.tlsys.core.promotion.discountByTagsPromotion.DiscountByTagsPromotionDialog

class DiscountExternalPromotion(record: OrderPromotionRecord?) :
    ExternalScriptPromotion(record = record, title = DiscountByTagsPromotionDialog.type.title) {
    override val type: OrderPromotionType
        get() = OrderPromotionType.DISCOUNT_EXTERNAL

    companion object : OrderPromotionEditor {
        override val type: OrderPromotionType
            get() = OrderPromotionType.DISCOUNT_EXTERNAL

        override suspend fun show(record: OrderPromotionRecord?): OrderPromotionRecord? {
            val d = DiscountExternalPromotion(record)
            d.show()
            return d.result
        }
    }
}
