package org.tlsys.core.promotion.bonusByPayWithLevels

import org.tlsys.*
import org.tlsys.admin.BonusByPayedWithLevelsConfig
import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.OrderPromotionType
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.Validator
import org.tlsys.admin.form.addToValidator
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.api.ShopsService
import org.tlsys.cms.click
import org.tlsys.core.promotion.BasePromotionDialog
import org.tlsys.core.promotion.OrderPromotionEditor
import org.tlsys.goods.DTODictionary
import org.tlsys.goods.api.GoodDictionaryService
import org.tlsys.json.jdto
import org.tlsys.ui.*
import pw.binom.uuid.toUUID
import pw.binom.web.layout.*

class BonusByPayedWithLevelsDialog(record: OrderPromotionRecord?) :
    BasePromotionDialog(record, OrderPromotionType.BONUS_BY_PAYED_AND_DOC_PAY_WITH_LEVELS.title) {
    private val shopsService by Services.byClass(ShopsService::class)
    private val memberTagService by Services.byClass(MemberTagService::class)
    private val goodDictionaryService by Services.byClass(GoodDictionaryService::class)
    private val params = record?.params?.getObject<BonusByPayedWithLevelsConfig>()
    private lateinit var dictionariesList: List<DTODictionary>

    override val type: OrderPromotionType
        get() = OrderPromotionType.BONUS_BY_PAYED_AND_DOC_PAY_WITH_LEVELS

    private val applyOnBonusPayGone = Checkbox(label = "Только если отсутствует оплата бонусами")
        .checked(params?.applyOnBonusPayGone ?: false)
        .appendFirstTo(conditions.layout, grow = 0, shrink = 0)

    private val excludeDictionaries = Combobox2()
        .addToValidator(validator)
        .validator(Validator { if (it >= 0) valid() else invalid() })
        .withLabel("Исключить для товаров")
        .appendFirstTo(conditions.layout, grow = 0, shrink = 0)

    private val includeDictionaries = Combobox2()
        .addToValidator(validator)
        .validator(Validator { if (it >= 0) valid() else invalid() })
        .withLabel("Включить для товаров")
        .appendFirstTo(conditions.layout, grow = 0, shrink = 0)

    private val levels = LevelEditList().appendTo(config.layout)

    override suspend fun buildConfig(): JsonObject =
        JsonObject.create(
            BonusByPayedWithLevelsConfig(
                levels = levels.items.map { it }.map { it.record }.toList(),
                includeTags = conditions.includeTags.content.list.map { it.tag.id },
                excludeTags = conditions.excludeTags.content.list.map { it.tag.id },
                shops = conditions.includeShops.element.list.map { it.id },
                applyOnBonusPayGone = applyOnBonusPayGone.checked,
                includeGoodsDictionaryId = includeDictionaries.content.select.let { if (it > 0) dictionariesList[it - 1].id.toUUID() else null },
                excludeGoodsDictionaryId = excludeDictionaries.content.select.let { if (it > 0) dictionariesList[it - 1].id.toUUID() else null },
            ),
        )

    override suspend fun onInit() {
        super.onInit()
        val dictionaries = goodDictionaryService.getDictionaries((-1).jdto, 0L.jdto).await()
        this.includeDictionaries.content.items = listOf("Все товары") + dictionaries.map { it.name }
        this.excludeDictionaries.content.items = listOf("Нет") + dictionaries.map { it.name }
        dictionariesList = dictionaries
        if (params != null) {
            val selected1 = dictionariesList.indexOfFirst { it.id.toUUID() == params.includeGoodsDictionaryId }
            this.includeDictionaries.content.select = if (selected1 >= 0) selected1 + 1 else 0
            val selected2 = dictionariesList.indexOfFirst { it.id.toUUID() == params.excludeGoodsDictionaryId }
            this.excludeDictionaries.content.select = if (selected2 >= 0) selected2 + 1 else 0

            params.levels.forEach { level ->
                levels.add(LevelItem(level))
            }
            val tags =
                memberTagService.getTagList((params.includeTags + params.excludeTags).map { it.jdto }.jdto()).await()
                    .associateBy { it.id }
            conditions.includeTags.content.addTag(
                params.includeTags.map { tags[it]!! },
            )
            conditions.excludeTags.content.addTag(
                params.excludeTags.map { tags[it]!! },
            )
            conditions.includeShops.content.addTag(
                params.shops.mapNotNull { shopsService.getShop(it.jdto).await() },
            )
        }
    }

    init {
        dom.style.width = 500.px
        dom.style.height = 700.px
    }

    companion object : OrderPromotionEditor {
        override val type: OrderPromotionType
            get() = OrderPromotionType.BONUS_BY_PAYED_AND_DOC_PAY_WITH_LEVELS

        override suspend fun show(record: OrderPromotionRecord?): OrderPromotionRecord? {
            val d = BonusByPayedWithLevelsDialog(record)
            d.show()
            return d.result
        }
    }

    class LevelEditList : EditableList<LevelItem>() {
        override suspend fun createItemForAdd(): Item? {
            val record = LevelDialog.show(null) ?: return null
            return LevelItem(record)
        }

        override fun afterAdd(item: Item) {
            super.afterAdd(item)
            sort()
        }
    }

    class LevelItem(
        var record: BonusByPayedWithLevelsConfig.Level,
    ) : EditableList.Item(), Comparable<LevelItem> {
        override val valid: Boolean
            get() = true

        fun refresh() {
            div.innerText = "от ${record.minSum} начеслять ${record.self.bonusPercent}"
        }

        override suspend fun isCanDeleteItem(): Boolean =
            YesNoDialog.show("Удалить уровень \"${div.innerText}\"", width = 500).await()

        init {
            div.style.cursor = "default"
            div.style.userSelect = "none"
            div.addClass(Styles.SIMPLE_TEXT)
            actionPanel.iconBtn(MaterialIcons.EDIT).click {
                val r = LevelDialog.show(record) ?: return@click
                record = r
                Effects.blockRow(dom)
                refresh()
                list.sort()
            }
            actionPanel.iconBtn(MaterialIcons.CONTENT_COPY).click {
                val r = LevelDialog.show(record) ?: return@click
                val newItem = LevelItem(r)
                list.add(newItem)
                Effects.blockRow(newItem.dom)
                list.sort()
            }
            refresh()
        }

        override fun compareTo(other: LevelItem): Int =
            record.minSum.asLong.compareTo(other.record.minSum.asLong)
    }
}
