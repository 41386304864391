package org.tlsys.lex

class SimpleParse {

    companion object {

        class Stack<T> {
            private val f = ArrayList<T>()
            fun push(value: T) {
                f.add(f.size, value)
            }

            fun pop(): T {
                val o = peek()
                f.removeAt(f.size - 1)
                return o
            }

            val size: Int
                get() = f.size

            fun isEmpty() = f.isEmpty()

            fun peek(): T {
                if (f.isEmpty())
                    TODO()
                return f[f.size - 1]
            }
        }

        fun rlex(t: String, current: Stack<DTOExpression>, l: LexStream): DTOExpression? {
            if (t.isBlank())
                return null
            if (t == "!") {
                return DTOExpression.DTONot(rnext(current, l)!!)
            }
            if (t == "^") {
                return DTOExpression.DTOStartWith((rnext(current, l) as DTOExpression.DTOContain).text)
            }
            if (t == "$") {
                return DTOExpression.DTOEndWith((rnext(current, l) as DTOExpression.DTOContain).text)
            }
            if (t == "&") {
                val left = current.pop()
                return DTOExpression.DTOAnd(left, rnext(current, l)!!)
            }
            if (t == "|") {
                val left = current.pop()
                return DTOExpression.DTOOr(left, rnext(current, l)!!)
            }
            if (t == "(") {
                val c = Stack<DTOExpression>()

                while (true) {
                    val s = l.next()
                    if (s === null)
                        continue
                    if (s.isBlank())
                        continue
                    if (s == ")")
                        break
                    val o = rlex(s, c, l)
                    if (o !== null)
                        c.push(o)
                    else
                        break
                }

                return DTOExpression.DTOUnion(c.pop())
            }
            return DTOExpression.DTOContain(t)
        }

        fun rnext(current: Stack<DTOExpression>, l: LexStream): DTOExpression? {
            while (true) {
                val s = l.next()
                if (s === null)
                    return null
                if (s.isBlank())
                    continue
                return rlex(s, current, l);
            }
        }

        fun parse(text: String): DTOExpression? {
            val l = LexStream(text, arrayOf("&", "|", "(", ")", "!", "^", "$"))
            val current = Stack<DTOExpression>()
            while (true) {
                val s = l.next()
                if (s === null)
                    break
                if (s.isBlank())
                    continue
                val ll = rlex(s, current, l);
                if (ll !== null) {
                    current.push(ll)
                }
            }
            if (current.isEmpty())
                return null
            if (current.size == 1)
                return current.pop()
            var cur = current.pop()
            while (!current.isEmpty()) {
                cur = DTOExpression.DTOOr(cur, current.pop())
            }
            return cur
        }
    }
}