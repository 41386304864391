package org.tlsys.shops

import org.tlsys.*
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.AbstractPage
import org.tlsys.admin.ui.ComponentView
import org.tlsys.api.ShopsService
import org.tlsys.gui.InfoPlane
import org.tlsys.json.jdto
import pw.binom.web.layout.*

/**
 * Created by Igor on 15.02.2017.
 */
class TerminalInfo(val terminalId: Long) : AbstractPage() {
    override suspend fun getTitle(): String = terminal.await()?.name ?: terminalId.toString()

    val distributionFragment = DistributionFragment(terminalId)

    private val shopsService by Services.byClass(ShopsService::class)
    val terminalLazy = LazyPromise {
        shopsService.getTerminalById(terminalId.jdto).promise
    }
    val terminal by terminalLazy

    val headLayout = FlexLayout.div {
        this.parent.addClass("infoHead")
        contentLayout.add(this.parent) {
            direction = FlexLayout.Direction.Row
            shrink = 0
        }
    }

    val infoPlane = InfoPlane().apply {
        headLayout.add(this.dom)
    }

//    val tabs = Tabs().apply {
//        dom.style.padding = "5px 0px"
//        this.add("Дистрибутивы") {
//            view.set2(distributionFragment)
//        }
//    }.appendTo(contentLayout, grow = 0, shrink = 0)

    val view = ComponentView().apply {
        this@TerminalInfo.contentLayout.add(dom)
        dom.style.paddingTop = "10px"
//        this.layout.direction = FlexLayout.Direction.Column
    }

    val head_code = infoPlane.add("Регистрационный номер") {
        val body = it
        terminal.then {
            body.innerText = "Подробнее"
        }
        body.onclick = {
            async2 {
                AboutDialog.show(terminal.await()!!.code)
            }
        }
        body.addClass("link")
    }

    var firstStart = true
    override suspend fun onStart() {
        super.onStart()
//        tabs.active = if (firstStart) 0 else tabs.active
        firstStart = false

        head_code.draw()
    }
}
