package org.tlsys.sms

import org.tlsys.admin.core.Services
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.api.SMSManagerService
import org.tlsys.api.text
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.px
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class SMSAccountEditDialog(account: SMSManagerService.AccountDTO?) :
    TitleDialog(if (account == null) "Новый шлюз" else "Редактирование шлюза") {
    val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val smsManagerService by Services.byClass(SMSManagerService::class)

    //    val provider = Combobox().withLabel("Поставщик услуг").appendTo(layout, grow = 0, shrink = 0)
    val provider = Combobox2(SMSManagerService.Provider.values().map { it.rus }).withLabel("Поставщик услуг")
        .appendTo(layout, grow = 0, shrink = 0)
    val name = EditText(placeHolder = "Название")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    val login = EditText(placeHolder = "Имя пользователя")
        .textValidator(TextValidators.NOT_BLANK and TextValidators.NO_SPACE)
        .appendTo(layout, grow = 0, shrink = 0)
    val password = EditText(placeHolder = "Пароль", password = true)
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)

    val list = ListView(items = SMSManagerService.Type.values().map { TypeItem(it) })
        .withLabel("Использование")
        .appendTo(layout, grow = 1, shrink = 1)

    private var canceled = false

    class TypeItem(val type: SMSManagerService.Type) : Checkbox() {
        init {
            label = type.text
        }
    }

    init {
        dom.style.width = 300.px
        dom.style.height = 460.px
        val model = listModel(SMSManagerService.Provider.values().toList()) {
            when (it) {
                SMSManagerService.Provider.SMS_RU -> "SMS.ru"
                SMSManagerService.Provider.SMSC_RU -> "SMSC.ru"
            }
        }
//        provider.content.model = model
        if (account != null) {
            password.text = OLD_PASSWORD
            name.text = account.name.value
            login.text = account.login?.value ?: ""
            provider.content.select = model.indexOf(account.providerEnum)
        } else {
            provider.content.select = 0
        }

        account?.typesEnum?.forEach { type ->
            list.element.items.find { it.type == type }?.checked = true
        }
        if (account == null) {
            list.element.items.forEach { it.checked = true }
        }

        MultiValidator(name, login, password).onValidChange {
            body.okBtn.enabled = it
        }
        body.ok {
            async2 {
                val result = ProcessBlocker.block("Проверка учетной записи") {
                    smsManagerService.checkAccount(
                        provider = (model.item(provider.content.select) as SMSManagerService.Provider).name.jdto,
                        password = (password.text.takeIf { it != OLD_PASSWORD } ?: "").jdto,
                        login = login.text.jdto,
                        account = account?.id,
                    ).await()?.value
                }.await()

                if (result != null) {
                    MessageDialog.showError(result)
                } else {
                    canceled = false
                    close()
                }
            }
        }
        body.cancel {
            canceled = true
            close()
        }
    }

    class Result(
        val name: String,
        val login: String,
        val password: String,
        val provider: SMSManagerService.Provider,
        val type: Set<SMSManagerService.Type>,
    )

    companion object {
        private const val OLD_PASSWORD = "********"
        suspend fun show(account: SMSManagerService.AccountDTO?): Result? {
            val dd = SMSAccountEditDialog(account)
            dd.show()
            if (dd.canceled) {
                return null
            }

            return Result(
                name = dd.name.text,
                login = dd.login.text,
                password = dd.password.text.takeIf { it != OLD_PASSWORD } ?: "",
                provider = SMSManagerService.Provider.values()[dd.provider.content.select],
                type = dd.list.element.items.filter { it.checked }.map { it.type }.toSet(),
            )
        }
    }
}

private val SMSManagerService.Provider.rus
    get() = when (this) {
        SMSManagerService.Provider.SMS_RU -> "SMS.ru"
        SMSManagerService.Provider.SMSC_RU -> "SMSC.ru"
    }
