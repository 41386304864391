package org.tlsys.admin.ui

import org.tlsys.addClass
import org.tlsys.px
import org.tlsys.ui.DivComponentWithLayout
import org.tlsys.ui.DivLayout
import pw.binom.web.layout.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.Component

open class ElementGroup(title: String, direction: FlexLayout.Direction = FlexLayout.Direction.Column) : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    private val titleLayout = DivLayout(direction = FlexLayout.Direction.Row)
        .appendTo(super.layout, grow = 0, shrink = 0)
        .asParent()
    private val title = Span(title).appendTo(titleLayout.layout, grow = 1, shrink = 1)

    init {
        this.title.dom.style.apply {
            fontSize = 11.2.px
            fontFamily = Styles.DEFAULT_TEXT_FONT
            color = "#9e9e9e"
            fontWeight = "500"
            padding = "10px 0px"
//            position = "absolute"
//            top = 0.px
//            color = "rgba(0,0,0,.54)"

//            fontFamily = Styles.DEFAULT_TEXT_FONT
//            fontSize = Styles.DEFAULT_FONT_SIZE.px
//            marginTop = 15.px
        }
    }

    fun iconBtn(icon: String): Button {
        val b = BaseButton()
        b.dom.addClass("material-icons")
        b.text = icon
        b.dom.style.padding = 2.px
        b.appendTo(titleLayout.layout, grow = 0, shrink = 0)
        return b
    }

    val _layout = DivLayout(direction = direction).appendTo(super.layout).asParent()
    public override val layout: FlexLayout<HTMLDivElement>
        get() = _layout.layout

    override suspend fun onStart() {
        super.onStart()
    }
}