package org.tlsys.serialization

import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.protobuf.ProtoBuf
import org.tlsys.*
import pw.binom.xml.serialization.Xml

val internalTextModule =
    SerializersModule {
        include(BaseTextModule)
    }

private val internalBinaryModule =
    SerializersModule {
        include(BaseBinaryModule)
    }

val internalJson =
    Json {
        this.serializersModule = internalTextModule
        this.classDiscriminator = "@"
        ignoreUnknownKeys = true
    }

val prettyInternalJson =
    Json {
        this.serializersModule = internalTextModule
        prettyPrint = true
        encodeDefaults = true
    }

val internalXml = Xml(internalTextModule)
val internalProtobuf = ProtoBuf { serializersModule = internalBinaryModule }
