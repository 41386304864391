package org.tlsys

import kotlinx.serialization.Serializable
import org.tlsys.serialization.MoneyValueSerializer
import pw.binom.openapi.annotation.Description
import kotlin.jvm.JvmInline
import kotlin.math.roundToLong

@Serializable(MoneyValueSerializer::class)
@DtoDescriptionRU("Сумма в копейках. Например 150руб = 15000")
@Description("Сумма в копейках. Например 150руб = 15000")
@JvmInline
value class MoneyValue(private val value: Long) {
    companion object {
        val ZERO = MoneyValue(0L)
    }

    operator fun unaryMinus() = MoneyValue(-value)

    operator fun compareTo(value: Long): Int = this.value.compareTo(value)

    operator fun compareTo(sum: MoneyValue): Int = value.compareTo(sum.value)

    operator fun plus(second: MoneyValue) = MoneyValue(value + second.value)

    val asLong: Long
        get() = value

    val asFloat: Float
        get() = value * 0.01f

    val asDouble: Double
        get() = value * 0.01

    val isPositive
        get() = value > 0

    val isNegative
        get() = value < 0

    val isZero
        get() = value == 0L

    override fun toString(): String {
        if (value == 0L) {
            return "0"
        }
        val l = value / 100L
        val f = asDouble
        return if (l.toDouble() == f) l.toString() else f.toString().replace('.', ',')
    }

    operator fun minus(other: MoneyValue): MoneyValue = (asLong - other.asLong).toMoneyValue

//    override fun equals(other: Any?): Boolean {
//        if (this === other) return true
//        if (other == null || this::class != other::class) return false
//
//        other as MoneyValue
//
//        if (value != other.value) return false
//
//        return true
//    }
//
//    override fun hashCode(): Int {
//        return value.hashCode()
//    }

    val abs
        get() = if (value >= 0) this else MoneyValue(-value)

    val negative
        get() = -abs

    operator fun times(invert: Percent) = MoneyValue((value * invert.asDoubleDivide).roundToLong())
}

inline fun minOf(
    a: MoneyValue,
    b: MoneyValue,
) = minOf(a.asLong, b.asLong).toMoneyValue

inline fun maxOf(
    a: MoneyValue,
    b: MoneyValue,
) = maxOf(a.asLong, b.asLong).toMoneyValue

val Float.toMoneyValue: MoneyValue
    get() = MoneyValue((this * 100f).roundToLong())

val Double.toMoneyValue: MoneyValue
    get() = MoneyValue((this * 100.0).roundToLong())

val Int.toMoneyValue: MoneyValue
    get() = toLong().toMoneyValue

val Long.toMoneyValue: MoneyValue
    get() = MoneyValue(this)

@OptIn(kotlin.experimental.ExperimentalTypeInference::class)
@OverloadResolutionByLambdaReturnType
@kotlin.jvm.JvmName("sumOfMoneyValue")
inline fun <T> Iterable<T>.sumOfMoney(selector: (T) -> MoneyValue): MoneyValue {
    var sum = 0L
    for (element in this) {
        sum += selector(element).asLong
    }
    return sum.toMoneyValue
}

inline fun Iterable<MoneyValue>.sumOfMoney() = sumOfMoney { it }
