package org.tlsys.api

import org.tlsys.AutoLoadAsyncListIterator
import org.tlsys.json.jdto

fun MemberTagService.getTagsIterator(search: String?, minForLoad: Int, maxPart: Int) =
        AutoLoadAsyncListIterator(
                minFull = minForLoad,
                max = maxPart,
                loader = { max, offset ->
                    getTags(
                            search = search?.jdto,
                            offset = offset.toInt().jdto,
                            max = max.jdto).promise
                })