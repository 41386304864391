package org.tlsys.jobs.dto

import org.tlsys.json.*

class JobDTO(val id: JString,
             val scriptId: JString,
             val title: JString,
             val taskName: JString,
             val scriptName: JString,
             val params: JMap<JString, JString>) : JDTO {
    constructor() : this(JString.EMPTY, JString.EMPTY, JString.EMPTY, JString.EMPTY, JString.EMPTY, JMap())

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : AutoJDTOFactory<JobDTO>(JobDTO::class, "JobDTO")
}