package org.tlsys.documents.dialogs

import kotlinx.browser.document
import libs.asDateString
import libs.asDateUtc
import libs.asTimeString
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.core.documents.JDocument
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.js.Promise

class Param(
    key: String,
    value: String,
    valueColor: String? = null,
) : DivComponentWithLayout(direction = FlexLayout.Direction.Row, alignItems = FlexLayout.AlignItems.Center) {
    val key = Span(key).addClass(Styles.SIMPLE_TEXT)
        .appendTo(layout, grow = 0, shrink = 0, basis = -50)
    val value = Span(value).addClass(Styles.SIMPLE_TEXT)
        .appendTo(layout, grow = 0, shrink = 0, basis = -50)

    init {
        dom.addClass(Styles.LIST_ITEM_STYLE)
        dom.style.minHeight = 30.px
        dom.style.cursor = "default"
        this.key.dom.style.apply {
            fontWeight = "bold"
            color = "rgba(0,0,0,.6)"
        }
        if (valueColor != null) {
            this.value.dom.style.apply {
                color = valueColor
            }
        }
    }
}

class DocumentView private constructor(val document: JDocument) : TitleDialog("Документ №${document.number}") {
    private val body = SaveCancelBody(okText = "Закрыть", cancelVisible = false)
        .appendTo(super.layout)

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val info = ElementGroup("Информация").appendTo(layout, grow = 0, shrink = 0)

    init {
        Param("Дата", document.date.asDateUtc.let { "${it.asDateString} ${it.asTimeString}" })
            .appendTo(info.layout, grow = 0)
        Param("Тип", if (document.cashback) "Возврат" else "Продажа")
            .appendTo(info.layout, grow = 0)
        if (document.bonusCode != null) {
            Param("Аутентификация", document.bonusCode)
                .appendTo(info.layout, grow = 0)
        }
        Param("Списано бонусов", "${document.bonusPay / 100f}")
            .appendTo(info.layout, grow = 0)
    }

    private val positions = ElementGroup("Позиции").appendTo(layout, grow = 0)

    private val positionsTable = MaterialTable().also {
        val head = MaterialTable.Header().appendTo(it)
        MaterialTable.TextColumn("Название").appendTo(head)
        MaterialTable.TextColumn("Количество").appendTo(head)
        MaterialTable.TextColumn("Цена").appendTo(head)
        MaterialTable.TextColumn("Бонусы").appendTo(head)
        MaterialTable.TextColumn("Активация").appendTo(head)
        MaterialTable.TextColumn("Сгорание").appendTo(head)
    }.appendTo(positions.layout)
    private val posScroll = ScrollController(positions._layout.dom)

    init {

        document.positions.forEach {
            val r = MaterialTable.Row().appendTo(positionsTable)
            val rowSpan = maxOf(1, it.bonus.size)
            MaterialTable.TextColumn(it.name ?: it.code, rowSpan).appendTo(r)
            MaterialTable.TextColumn((it.count / 1000f).toString(), rowSpan).appendTo(r)
            MaterialTable.TextColumn((it.price / 100f).toString(), rowSpan).appendTo(r)
            if (it.bonus.isEmpty()) {
                MaterialTable.TextColumn("").appendTo(r)
                MaterialTable.TextColumn("").appendTo(r)
                MaterialTable.TextColumn("").appendTo(r)
            } else {
                var row = r
                var first = true
                it.bonus.forEach {
                    if (!first) {
                        row = MaterialTable.Row().appendTo(positionsTable)
                    }
                    first = false
                    MaterialTable.TextColumn((it.amount / 100f).toString()).appendTo(row)
                    val start =
                        if (it.amount > 0) it.start.asDateUtc.let { "${it.asDateString} ${it.asTimeString}" } else ""
                    val end = if (it.amount > 0) {
                        it.end?.asDateUtc?.let { "${it.asDateString} ${it.asTimeString}" } ?: ""
                    } else {
                        ""
                    }
                    MaterialTable.TextColumn(start).appendTo(row)
                    MaterialTable.TextColumn(end).appendTo(row)
                }
            }
        }

        val bonus = ElementGroup("Бонусы на чек").appendTo(layout, grow = 0, shrink = 0, basis = 140)

        val bonusTable = MaterialTable().also {
            val head = MaterialTable.Header().appendTo(it)
            MaterialTable.TextColumn("Бонусы").appendTo(head)
            MaterialTable.TextColumn("Активация").appendTo(head)
            MaterialTable.TextColumn("Сгорание").appendTo(head)
        }.appendTo(bonus.layout)
        val bonusScroll = ScrollController(bonus._layout.dom)

        document.bonus.forEach {
            val r = MaterialTable.Row().appendTo(bonusTable)
            MaterialTable.TextColumn((it.amount / 100f).toString()).appendTo(r)
            val start = if (it.amount > 0) it.start.asDateUtc.let { "${it.asDateString} ${it.asTimeString}" } else ""
            val end = if (it.amount > 0) {
                it.end?.asDateUtc?.let { "${it.asDateString} ${it.asTimeString}" }
                    ?: ""
            } else {
                ""
            }
            MaterialTable.TextColumn(start).appendTo(r)
            MaterialTable.TextColumn(end).appendTo(r)
        }
    }

    override suspend fun onInit() {
        super.onInit()
    }

    init {
        body.ok {
            close()
        }
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        fun show(document: JDocument) = Promise<Unit> { d, c ->
            val dd = DocumentView(document)
            layoutNavigator.show(dd) {
                d(Unit)
            }
        }
    }
}
