package org.tlsys.admin.libs

import kotlinx.browser.window
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.js.Promise

suspend fun Promise.Companion.sleep(time: Int) = suspendCoroutine<Unit> {
    window.setTimeout({
        it.resume(Unit)
    }, time)
}