package org.tlsys.gateway

import org.tlsys.admin.ui.ProcessBlocker
import org.tlsys.api.GatewayService
import org.tlsys.await
import org.tlsys.serialization.Gateway
import org.tlsys.serialization.GatewayTest
import org.tlsys.serialization.SMSTestData
import org.tlsys.serialization.internalJson
import org.tlsys.toPhone

class SMSGatewayTestDialog(gateway: Gateway) : AbstractSmsGatewayTest(gateway) {
    override suspend fun test() {
        val testData = SMSTestData(
            phone = phone.text.toPhone(),
            text = message.content.text,
        )
        val testDataText = internalJson.encodeToString(SMSTestData.serializer(), testData)
        val request = GatewayTest(
            gateway = gateway,
            testData = testDataText,
            firstName = firstName.text,
            middleName = patronymic.text,
            lastName = surname.text,
        )
        val response = ProcessBlocker.block("Отправка письма") {
            GatewayService.test(request)
        }.await()
        showResult(response)
    }

    companion object {
        suspend fun show(gateway: Gateway) =
            SMSGatewayTestDialog(gateway).show()
    }
}
