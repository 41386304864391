package org.tlsys.core.promotion.tagSetterPromotion

import org.tlsys.JsonObject
import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.OrderPromotionType
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.TagPanel
import org.tlsys.admin.ui.withLabel
import org.tlsys.api.MemberTagService
import org.tlsys.api.ShopsService
import org.tlsys.core.promotion.BasePromotionDialog
import org.tlsys.core.promotion.OrderPromotionEditor
import org.tlsys.json.jdto
import org.tlsys.promotion.dto.TagSetterConfig
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import pw.binom.web.layout.*

class TagSetterPromotionDialog(record: OrderPromotionRecord?) :
    BasePromotionDialog(record, type.title) {
    private val params = record?.params?.getObject<TagSetterConfig>()
    override val type: OrderPromotionType
        get() = OrderPromotionType.TAG_SETTER

    val addTags =
        TagPanel(readOnly = false).withLabel("Добавить метки")
            .appendTo(config.layout, grow = 0, shrink = 0)
    val removeTags =
        TagPanel(readOnly = false).withLabel("Удалить метки")
            .appendTo(config.layout, grow = 0, shrink = 0)
    private val memberTagService by Services.byClass(MemberTagService::class)
    private val shopsService by Services.byClass(ShopsService::class)
    override suspend fun onInit() {
        super.onInit()
        if (params != null) {
            val tags =
                memberTagService.getTagList(
                    (params.filter.includeTags + params.filter.excludeTags + params.addTags + params.removeTags).map { it.jdto }
                        .jdto(),
                ).await()
                    .associateBy { it.id }
            conditions.includeTags.content.addTag(
                params.filter.includeTags.map { tags[it]!! },
            )
            conditions.excludeTags.content.addTag(
                params.filter.excludeTags.map { tags[it]!! },
            )
            conditions.includeShops.content.addTag(
                params.filter.shops.mapNotNull { shopsService.getShop(it.jdto).await() },
            )

            addTags.content.addTag(params.addTags.map { tags[it]!! })
            removeTags.content.addTag(params.removeTags.map { tags[it]!! })
        }
    }

    override suspend fun buildConfig(): JsonObject =
        JsonObject.create(
            TagSetterConfig(
                addTags = addTags.element.list.map { it.tag.id },
                removeTags = removeTags.element.list.map { it.tag.id },
                filter = conditions.tagFilterWithShops,
            ),
        )

    companion object : OrderPromotionEditor {
        override val type: OrderPromotionType
            get() = OrderPromotionType.TAG_SETTER

        override suspend fun show(record: OrderPromotionRecord?): OrderPromotionRecord? {
            val d = TagSetterPromotionDialog(record)
            d.show()
            return d.result
        }
    }
}
