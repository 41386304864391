package org.tlsys.api

import org.tlsys.OSession

var MUTABLE_DEFAULT_SESSION: OSession? = null

val DEFAULT_SESSION: OSession?
    get() = MUTABLE_DEFAULT_SESSION

// object Auth {
//    private val service = KernelService("Auth")
//
//    fun login(login: String, password: String) =
//            service.call2<SessionDTO>("login", login.jdto, password.jdto)
//
//    fun resume(key: String) =
//            service.call2<LoginResponce>("resume", key.jdto)
// }
