package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.px
import org.tlsys.ui.createDiv
import pw.binom.web.layout.*
import org.tlsys.userSelect7
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent

class NoElementForView(val text: String = "Нет элементов к отображению") : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    private val layout =
        FlexLayout(this, alignItems = FlexLayout.AlignItems.Center, direction = FlexLayout.Direction.Row)
    private val element = Span(text).appendTo(layout)

    init {
        element.dom.style.apply {
            textAlign = "center"
            opacity = "0.3"
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontStyle = "italic"
            fontSize = 12.px
            color = "#434a54"
        }

        dom.style.apply {
            // position="absolute"
            width = "100%"
            minHeight = 50.px
            dom.style.userSelect7 = "none"
        }
    }

    fun remove() {
        if (isUse) {
            dom.remove()
        }
    }
}
