package org.tlsys.admin.ui

import kotlinx.browser.window
import kotlinx.dom.addClass
import org.tlsys.*
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.Validated
import org.tlsys.css.CSS
import org.tlsys.css.animate
import org.tlsys.navigation.Layout2
import pw.binom.web.layout.*
import org.tlsys.ui.before
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.KeyboardEvent
import pw.binom.web.Component
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

/**
 * Всплывающее окно, необходимое для заполнения каких-то комплексных данных
 */
class PopupWindow<T : PopupWindow.Selector>(val selector: T, val x: Float, val y: Float, buttons: Array<out Button>) :
    Layout2<HTMLDivElement> {

    open val layout = FlexLayout.div().apply {
        parent.addClass(WINDOW.name)
    }
    override val dom: HTMLDivElement
        get() = layout.dom

    /**
     * Содержимое всплывающего окна
     */
    interface Selector : Component<HTMLDivElement>, Validated

    private var resizeTimer = 0

    val body = SaveCancelBody().appendTo(layout, grow = 1, shrink = 1).apply {
        buttonsLayout.parent.classList.add(BUTTENS_STYLE)
        componentsLayout.add(selector.dom) {
            shrink = 1
            grow = 1
        }
    }

    init {
        layout.parent.style.left = x.px
        layout.parent.style.top = y.px

        buttons.forEach {
            it.appendTo(body.buttonsLayout, grow = 0, shrink = 0)
            it.before(body.cancelBtn)
        }

        body.componentsLayout.add(selector.dom)

        selector.onValidChange {
            body.okBtn.enabled = it
        }
    }

    private fun close() {


        async2 {
            layoutNavigator.close(this)
        }
    }

    private val resizeFunc: () -> Unit = {
        val style = window.getComputedStyle(layout.parent)

        if (y + style.heightPX + style.paddingTopPX + style.paddingBottomPX >= window.innerHeight) {
            layout.parent.style.topPX =
                window.innerHeight - (style.heightPX + style.paddingTopPX + style.paddingBottomPX)
        } else {
            layout.parent.style.topPX = y.toFloat()
        }

        if (x + style.widthPX + style.paddingLeftPX + style.paddingRightPX >= window.innerWidth) {
            layout.parent.style.leftPX =
                window.innerWidth - (style.widthPX + style.paddingLeftPX + style.paddingRightPX)
        } else {
            layout.parent.style.leftPX = x.toFloat()
        }
    }

    override suspend fun onStart() {
        layout.parent.animate {
            duration = 100
            0{
                opacity = 0.0
            }
            100 {
                opacity = 1.0
            }
        }.start()

        console.info("Selector started!")

        resizeTimer = window.setInterval(resizeFunc, 100)
        selector.onStart()
    }

    override fun onKeyUp(event: KeyboardEvent) {
        super.onKeyUp(event)
        if (event.keyCode == 27) {
            close()
        }
    }

    override suspend fun onStop() {
        selector.onStop()
        window.clearInterval(resizeTimer)
        layout.parent.animate {
            duration = 100
            0{
                opacity = 1.0
            }

            100 {
                opacity = 0.0
            }

            onEnd {
                dom.remove()
            }
        }.start()
        console.info("Selector stoped!")
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        val WINDOW = CSS.style {
            background = "#fafafa"
            boxShadow = "0px 1px 1px 0px rgba(0,0,0,0.20)"
            borderRadius = 2.px
            position = "absolute"
            padding = 6.px
            transition = "top 100ms, left 100ms, heght 100ms, width 100ms"
        }

        val BUTTENS_STYLE = CSS.genName()

        init {
            CSS {
                ".$BUTTENS_STYLE>button:not(:first-child)" {
                    marginLeft = 24.px
                }
            }
        }

        /**
         * Отображает диалог для заполнения окмплексной информации
         *
         * @param x позиция окна по оси X
         * @param y позиция окна по оси Y
         * @param selector тело комлексного диалога
         */
        suspend fun <T : Selector> show(x: Int, y: Int, selector: T, vararg buttons: Button): T? {
            val w = PopupWindow(selector = selector, x = x.toFloat(), y = y.toFloat(), buttons = buttons)
            return suspendCoroutine { con ->
                w.body.ok {
                    con.resume(selector)
                    w.close()
                }
                w.body.cancel {
                    con.resume(null)
                    w.close()
                }
                layoutNavigator.show(w)
            }
        }
    }
}