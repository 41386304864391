package org.tlsys.json

import kotlin.reflect.KClass

actual fun objectKeys(obj: JDTO): Array<String> =
        js("Object.keys(obj)").unsafeCast<Array<String>>()

actual fun getFieldValue(obj: JDTO, field: String): Any? =
        obj.asDynamic()[field].unsafeCast<Any?>()

actual fun setFieldValue(obj: JDTO, field: String, value: Any?) {
    obj.asDynamic()[field] = value
}

internal actual fun <T : JDTO> newInstance(clazz: KClass<T>): T {
    val js = clazz.js
    return js("Object.create(js.prototype)").unsafeCast<T>()
}

fun <T : Any> KClass<T>.newInstance(): T {
    return js("({})").unsafeCast<T>()
}

internal actual fun checkConstructor(any: KClass<*>) {
}