package org.tlsys.wc

import org.tlsys.json.*

class MemberReportDTO(
        val id: String,
        val date: Long,
        val file: String,
        val version: String) : JDTO {

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<MemberReportDTO> {
        override val type: String
            get() = "MemberReportDTO"

        override fun read(node: JsonNode) =
                MemberReportDTO(
                        id = node["id"]!!.string(),
                        date = node["date"]!!.long(),
                        file = node["file"]!!.string(),
                        version = node["version"]!!.string()
                )

        override fun write(obj: MemberReportDTO) =
                JsonNode.obj(
                        "id" to obj.id.json,
                        "date" to obj.date.json,
                        "file" to obj.file.json,
                        "version" to obj.version.json
                )
    }
}