package org.tlsys.goods.dialogs

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.or
import org.tlsys.admin.ui.*
import org.tlsys.px
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.FlexLayout
import pw.binom.web.layout.appendTo
import kotlin.js.Promise

class GoodEditDialog private constructor(
    title: String,
    name: String,
    code: String,
    vendorCode: String?,
) : TitleDialog(title) {
    private val body = SaveCancelBody().appendTo(super.layout, grow = 1, shrink = 1)

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private var canceled = false

    val name = EditText(placeHolder = "Название")
        .textValidator(TextValidators.NOT_BLANK)
        .text(name)
        .appendTo(layout, grow = 0, shrink = 0)
    val code = EditText(placeHolder = "Код")
        .text(code)
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    val vendorCode = EditText(placeHolder = "Артикул")
        .text(vendorCode ?: "")
        .textValidator(TextValidators.NOT_BLANK or TextValidators.BLANK)
        .appendTo(layout, grow = 0, shrink = 0)

    private val validator = MultiValidator(this.name, this.code)

    init {
        dom.style.apply {
            width = 300.px
            height = 230.px
        }
        validator.onValidChange {
            body.okBtn.enabled = it
        }

        body.okBtn.onClick {
            canceled = false
            close()
        }

        body.cancelBtn.onClick {
            canceled = true
            close()
        }
    }

    override suspend fun onStart() {
        super.onStart()
        this.name.dom.focus()
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        canceled = true
        close()
    }

    data class Result(
        val code: String,
        val name: String,
        val vendorCode: String?,
    )

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        fun show(title: String, code: String = "", name: String = "", vendorCode: String? = null) =
            Promise<Result?> { d, c ->
                val dd = GoodEditDialog(
                    title = title,
                    code = code,
                    name = name,
                    vendorCode = vendorCode,
                )
                layoutNavigator.show(dd) {
                    if (dd.canceled) {
                        d(null)
                    } else {
                        d(
                            Result(
                                code = dd.code.text,
                                name = dd.name.text,
                                vendorCode = dd.vendorCode.text.takeIf { it.isNotBlank() },
                            ),
                        )
                    }
                }
            }
    }
}
