package org.tlsys.help.pipelines

import org.tlsys.*
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.isUse
import org.tlsys.admin.ui.waitStop
import org.tlsys.core.wait
import org.tlsys.help.HelpPipeline
import org.tlsys.help.HelpScreen
import org.tlsys.navigation.PageControl
import org.tlsys.shops.*

class StartWorkPipeline : HelpPipeline {
    val screen = HelpScreen()

    private val navigation by Services.byClass(PageControl::class)
    private val adminPage by Services.byClass(AdminPage::class)

    /**
     * Перейти в Торговые объекты
     */
    private suspend fun step1() {
        val shopNavigationElement = adminPage.navigation.recrods.find { it.pageName == ShopsPage.URI } ?: TODO()
        screen.holdFocus(
            shopNavigationElement.btn.dom,
            holeRadius = 70f,
            xOffset = -30f,
        )
        wait {
            val page = navigation.currentPage
            page is ShopsPage && page.isUse
        }
        step2()
    }

    /**
     * Нажать на кнопку создания магазина
     */
    private suspend fun step2() {
        val shopPage = navigation.currentPage as ShopsPage
        DIALOG_AUTO_FOCUS_ON_INPUT = false

        screen.holdFocus(
            shopPage.addBtn.dom,
            holeRadius = 50f,
        )
        screen.waitTargetClick()
        screen.clearFocuse()
        wait {
            val msg = layoutNavigator.currentDialog
            msg is AddShopDialog && msg.isUse
        }
        step3()
    }

    private val layoutNavigator by Services.byClass(LayoutNavigator::class)

    /**
     * Ввести название маганиза
     */
    private suspend fun step3() {
        val dialog = layoutNavigator.currentDialog as AddShopDialog
        dialog.body.cancelBtn.enabled = false
        val ss = HelpScreen()
        ss.start()
        ss.holdFocus(
            dialog.name.editor,
            holeRadius = 100f,
            x = HelpScreen.Align.BEGIN,
            xOffset = 90f,
        )
        ss.waitTargetClick()
        ss.close()
        DIALOG_AUTO_FOCUS_ON_INPUT = true

        dialog.waitStop()
        step4()
    }

    /**
     * Зайти в магазин
     */
    private suspend fun step4() {
        val shopPage = navigation.currentPage as ShopsPage
        wait { shopPage.getFirstShop() != null }
        val shop = shopPage.getFirstShop()!!
        screen.holdFocus(
            shop.title.element,
            holeRadius = 70f,
            x = HelpScreen.Align.BEGIN,
            xOffset = 20f,
        )
        wait {
            val page = navigation.currentPage
            page is ShopInfo && page.isUse
        }
        step5()
    }

    /**
     * Нажать на создание терминала
     */
    private suspend fun step5() {
        val page = navigation.currentPage as ShopInfo
        screen.holdFocus(
            page.terminalsFragment.addBtn.dom,
        )
        DIALOG_AUTO_FOCUS_ON_INPUT = false
        wait {
            val msg = layoutNavigator.currentDialog
            msg is AddTerminalDialog && msg.isUse
        }
        step6()
    }

    /**
     * Ввести название терминала
     */
    private suspend fun step6() {
        val page = navigation.currentPage as ShopInfo
        val dialog = layoutNavigator.currentDialog as AddTerminalDialog
        dialog.body.cancelBtn.enabled = false
        screen.clearFocuse()
        val ss = HelpScreen()
        ss.start()
        ss.holdFocus(
            dialog.name.editor,
            x = HelpScreen.Align.BEGIN,
            holeRadius = 100f,
            xOffset = 90f,
        )
        DIALOG_AUTO_FOCUS_ON_INPUT = true
        ss.waitTargetClick()
        ss.close()
        dialog.waitStop()
        wait { page.terminalsFragment.getFirstTerminal() != null }
        step7()
    }

    /**
     * Перейти в терминал
     */
    private suspend fun step7() {
        val page = navigation.currentPage as ShopInfo
        val terminal = page.terminalsFragment.getFirstTerminal()!!
        screen.holdFocus(
            terminal.title.dom,
            holeRadius = 70f,
            x = HelpScreen.Align.BEGIN,
            xOffset = 20f,
        )
        wait {
            val page = navigation.currentPage
            page is TerminalInfo && page.isUse
        }
        // TODO Доделать шаг просмотра терминала
        screen.close()
    }

    override fun start() {
        screen.start()
        async2 {
            step1()
        }
    }

    override fun stop() {
        screen.close()
    }
}
