package org.tlsys.goods

import org.tlsys.json.*

class DTOHistory(val date: Long, val user: Long?, val text: String) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTOHistory> {
        override val type: String
            get() = "good_history"

        override fun read(node: JsonNode) =
                DTOHistory(
                        date = node["date"]!!.long(),
                        user = node["user"]?.long(),
                        text = node["text"]!!.string()
                )

        override fun write(obj: DTOHistory): JsonNode =
                JsonNode.obj()
                        .set("date", obj.date.json)
                        .set("user", obj.user?.json)
                        .set("text", obj.text.json)


    }
}