package org.tlsys.gateway

import org.tlsys.InternalSmtpConfig
import org.tlsys.admin.GatewayProvider
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.Checkbox
import org.tlsys.admin.ui.EditText
import org.tlsys.admin.ui.text
import org.tlsys.admin.ui.textValidator
import org.tlsys.px
import org.tlsys.serialization.Gateway
import org.tlsys.serialization.internalJson
import org.tlsys.toEmail
import org.tlsys.ui.*
import org.tlsys.ui.DivLayout
import pw.binom.web.layout.*

class InternalSMTPDialog(new: Boolean, gateway: Gateway?) : GatewayDialog(new = new, gateway = gateway) {
    private val configs = gateway?.config?.let { internalJson.decodeFromString(InternalSmtpConfig.serializer(), it) }

    private val tt3 = DivLayout(direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)
    private val login = EditText(placeHolder = "Логин")
        .text(configs?.login ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(tt3.layout)
    private val password = EditText(placeHolder = "Пароль", password = true)
        .text(configs?.password ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(tt3.layout)
    private val tt2 = DivLayout(direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)
    private val sourceEmail = EditText(placeHolder = "Email отправителя")
        .text(configs?.sourceEmail?.raw ?: "")
        .textValidator(TextValidators.EMAIL)
        .appendTo(tt2.layout, grow = 1, shrink = 1)

    private val sourceEmailAlias = EditText(placeHolder = "Псевдоним отправителя")
        .text(configs?.sourceEmailAlias ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(tt2.layout, grow = 1, shrink = 1)

    private val tt = DivLayout(direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)
    private val serverHost = EditText(placeHolder = "Адрес сервера")
        .text(configs?.serverHost ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(tt.layout, grow = 1, shrink = 1)

    private val serverPort = EditText(placeHolder = "Порт сервера")
        .text(configs?.serverPort?.toString() ?: "587")
        .textValidator(DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2))
        .appendTo(tt.layout, grow = 0, shrink = 0, basis = 110)
    private val useTls = Checkbox(label = "Использовать TLS", checked = configs?.useTls ?: true)
        .appendTo(tt.layout, grow = 0, shrink = 0)

    override fun getConfig(): String {
        val config = InternalSmtpConfig(
            login = login.text,
            password = password.text,
            sourceEmail = sourceEmail.text.toEmail(),
            sourceEmailAlias = sourceEmailAlias.text,
            serverHost = serverHost.text,
            serverPort = serverPort.text.toInt(),
            useTls = useTls.checked,
        )
        return internalJson.encodeToString(InternalSmtpConfig.serializer(), config)
    }

    override fun getGatewayProvider(): GatewayProvider = GatewayProvider.INTERNAL_SMTP
    override suspend fun testClick(gateway: Gateway) {
        EmailGatewayTestDialog.show(gateway)
    }

    init {
        dom.style.width = 600.px
        multiValidator.addValidated(login)
        multiValidator.addValidated(password)
        multiValidator.addValidated(sourceEmail)
        multiValidator.addValidated(sourceEmailAlias)
        multiValidator.addValidated(serverHost)
        multiValidator.addValidated(serverPort)
    }

    companion object {
        suspend fun show(new: Boolean, gateway: Gateway?): Gateway? =
            InternalSMTPDialog(new = new, gateway = gateway).showAndGetResult()
    }
}
