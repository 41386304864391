package org.tlsys.admin.ui

import kotlinx.browser.document
import org.w3c.dom.HTMLIFrameElement
import pw.binom.web.AbstractComponent

class IFrameComponent :
    AbstractComponent<HTMLIFrameElement>() {
    override val dom: HTMLIFrameElement = document.createElement("iframe").unsafeCast<HTMLIFrameElement>()

    fun rewriteBody(html: String) {
        val document = dom.contentDocument!!
        dom.src = "data:text/html;charset=utf-8"
        document.open()
        document.write(html)
        document.close()
    }
}
