package org.tlsys.admin.events

import org.tlsys.core.Closeable

class EventElementValue<V> {

    private val listeners = ArrayList<(V) -> Unit>()

    fun on(f: (V) -> Unit): Closeable {
        listeners.add(f)
        return Closeable {
            listeners.remove(f)
        }
    }

    fun dispatch(value: V) {
        for (f in listeners)
            f(value)
    }
}
