package org.tlsys.goods

import org.tlsys.OSession
import org.tlsys.ReadyDoneListener
import org.tlsys.admin.GlobalMenuItem
import org.tlsys.admin.SettingItem
import org.tlsys.admin.core.Remote
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.AbstractPage
import org.tlsys.api.ExPermissionManagerService
import org.tlsys.api.UploadProgress
import org.tlsys.goods.api.GoodDictionaryService
import org.tlsys.goods.api.GoodsHistoryService
import org.tlsys.goods.api.GoodsService
import org.tlsys.goods.api.ImportService
import org.tlsys.goods.pages.DictionaryListPage
import org.tlsys.goods.pages.GoodsPage
import org.tlsys.goods.pages.GoodsSettings
import org.tlsys.ui.Page
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest
import kotlin.js.Promise

fun goodsMain(remote: Remote) {
    goods_dto_set.reg()

    Services.reg(
        ReadyDoneListener {
            Services.reg(
                remote.getService(
                    GoodDictionaryService::class,
                    invokator = GOODS_SERVICE_INVOCATOR,
                    setvice = GOOD_DICTIONARY_SERVICE,
                ),
            )
            Services.reg(
                remote.getService(
                    GoodsHistoryService::class,
                    invokator = GOODS_SERVICE_INVOCATOR,
                    setvice = GOODS_HISTORY_SERVICE,
                ),
            )
            Services.reg(
                remote.getService(
                    GoodsService::class,
                    invokator = GOODS_SERVICE_INVOCATOR,
                    setvice = GOODS_SERVICE,
                ),
            )
            Services.reg(
                remote.getService(
                    ImportService::class,
                    invokator = GOODS_SERVICE_INVOCATOR,
                    setvice = GOOD_IMPORT_SERVICE,
                ),
            )

            val session by Services.byClass(OSession::class)
            val exPermissionManagerService by Services.byClass(ExPermissionManagerService::class)

            if (session.isAllow(SHOW_GOODS_DICTIONARY)) {
                Services.reg(
                    object : GlobalMenuItem {
                        override val name: String
                            get() = GoodsPage.TITLE
                        override val uri: String
                            get() = GoodsPage.URI
                        override val after: Array<String> = arrayOf()
                        override val page: (parent: AbstractPage) -> AbstractPage = { GoodsPage(null) }
                        override val img: String
                            get() = "view_list"
                    },
                )
            }

            if (session.isAllow(SHOW_GOODS_LIST)) {
                Services.reg(
                    object : GlobalMenuItem {
                        override val name: String
                            get() = DictionaryListPage.TITLE
                        override val uri: String
                            get() = DictionaryListPage.URI
                        override val after: Array<String> = arrayOf()
                        override val page: (parent: AbstractPage) -> AbstractPage = { DictionaryListPage() }
                        override val img: String
                            get() = "subtitles"
                    },
                )
            }

            Services.reg(
                object : SettingItem {
                    override val uri: String
                        get() = GoodsSettings.URI
                    override val title: String
                        get() = GoodsSettings.TITLE

                    override suspend fun getPage(): Page = GoodsSettings()
                },
            )
        },
    )
}

fun upload(url: String, method: String, data: FormData, process: ((UploadProgress) -> Unit)? = null) =
    Promise<Int> { d, c ->
        val xhr = XMLHttpRequest()

        xhr.onload = {
            d(xhr.status.toInt())
        }
        if (process != null) {
            xhr.upload.onprogress = {
                process(
                    UploadProgress(
                        loaded = it.loaded.toInt(),
                        total = if (it.lengthComputable) it.total.toInt() else null,
                    ),
                )
            }
        }
        xhr.upload.ontimeout = {
            c(Exception("Timeout"))
        }
        xhr.upload.onabort = {
            c(Exception("Abort"))
        }

        xhr.upload.onerror = {
            c(Exception("Error"))
        }

        xhr.ontimeout = {
            c(Exception("Timeout"))
        }
        xhr.onabort = {
            c(Exception("Abort"))
        }

        xhr.onerror = {
            c(Exception("Error"))
        }
        xhr.open(method = method, url = url, async = true)
        xhr.send(data)
    }
