package org.tlsys.gateway

import org.tlsys.SMSConfig
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.EditText
import org.tlsys.admin.ui.text
import org.tlsys.admin.ui.textValidator
import org.tlsys.px
import org.tlsys.serialization.Gateway
import org.tlsys.serialization.internalJson
import pw.binom.web.layout.*

abstract class AbstractSMSDialog(new: Boolean, gateway: Gateway?) : GatewayDialog(new = new, gateway = gateway) {
    protected val configs = gateway?.config?.let { internalJson.decodeFromString(SMSConfig.serializer(), it) }
    private val login = EditText(placeHolder = "Логин")
        .text(configs?.login ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    private val password = EditText(placeHolder = "Пароль", password = true)
        .text(configs?.password ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)

    private val maxLength = EditText(placeHolder = "Максимальная длина сообщения", password = false)
        .text(configs?.maxLength?.toString() ?: "120")
        .textValidator(DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(0))
        .appendTo(layout, grow = 0, shrink = 0)

    protected open fun configOverload(config: SMSConfig) = config

    override fun getConfig(): String {
        val config = SMSConfig(
            login = login.text,
            password = password.text,
            maxLength = maxLength.text.toInt(),
        )
        return internalJson.encodeToString(SMSConfig.serializer(), configOverload(config))
    }

    init {
        dom.style.width = 365.px
        eventsPanel.content.layout.direction = FlexLayout.Direction.Column
        multiValidator.addValidated(login)
        multiValidator.addValidated(password)
        multiValidator.addValidated(maxLength)
    }
}
