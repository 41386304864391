package org.tlsys

import org.w3c.dom.Node

fun Node.insertAfter(node: Node, after: Node) {
    val next = after.nextSibling
    if (next == null)
        appendChild(node)
    else
        insertBefore(node, next)
}