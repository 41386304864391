package org.tlsys.core.customers.dialog

import libs.Date
import libs.now
import libs.year
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.await
import org.tlsys.ui.*
import org.tlsys.ui.GridComponentLayout
import org.tlsys.ui.appendTo
import org.tlsys.ui.column
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class CreateCustomerDialog : TitleDialog("Новый клиент") {
    val body = SaveCancelBody(okText = "Создать").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    val grid = GridComponentLayout().appendTo(layout)
        .column(1)
        .column(1)
        .column(1)

    val lastName = EditText(placeHolder = "Фамилия")
        .appendTo(grid.layout)

    val firstName = EditText(placeHolder = "Имя")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(grid.layout)

    val middleName = EditText(placeHolder = "Отчество")
        .appendTo(grid.layout)

    private val sexGroup = RadioBox.Group().validator(
        Validator {
            if (it.selected == null) {
                invalid("Пол не выбран")
            } else {
                valid()
            }
        },
    )
    private val sexMGroup = ElementGroup("Пол").appendTo(grid.layout)
    val sexMale = RadioBox("Мужской").appendTo(sexMGroup.layout).group(sexGroup)
    val sexFamale = RadioBox("Женский").appendTo(sexMGroup.layout).group(sexGroup).checked()

    private val allowDropGrop = ElementGroup("Настройки").appendTo(grid.layout)
    val allowDrop = Checkbox("Разрешить списание").appendTo(allowDropGrop.layout)

    val birthDayInput = DateEditText(date = null, allowEmpty = true, placeHolder = "Дата рождения")
        .dateValidator(DateValidator.BEFORE_TODAY and DateValidator.before(Date.now().apply { year -= 120 }))
        .appendTo(grid.layout)

    private val cardsGroup = ElementGroup("Карты").appendTo(grid.layout)
    val cardsList = EditableList<EditableList.TextItem>()
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(cardsGroup.layout)

    private val phonesGroup = ElementGroup("Телефоны").appendTo(grid.layout)

    //    private val addPhoneBtn = phonesGroup.iconBtn("add")
    val phonesList = EditableList<EditableList.TextItem>()
        .textValidator(TextValidators.IS_PHONE).appendTo(phonesGroup.layout)

    private val emailGroup = ElementGroup("Электронная почта").appendTo(grid.layout)

    //    private val addEmailBtn = emailGroup.iconBtn("add")
    val emailList = EditableList<EditableList.TextItem>()
        .textValidator(TextValidators.IS_MAIL).appendTo(emailGroup.layout)

    private val tagGroup = ElementGroup("Метки").appendTo(layout)
    val tagSelector = TagPanel(false).appendTo(tagGroup.layout)

    /*    init {
            addPhoneBtn.onClick {
                phonesList.createItemForAdd()
            }
            addEmailBtn.onClick {
                emailList.createItemForAdd()
            }
        }*/

    private var canceled = false

    init {
        MultiValidator(lastName, firstName, middleName, birthDayInput, phonesList, emailList)
            .onValidChange {
                body.okBtn.enabled = it
            }

        body.ok {
            close()
        }

        body.cancel {
            canceled = true
            close()
        }
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        suspend fun show(): CreateCustomerDialog? {
            val dd = CreateCustomerDialog()
            layoutNavigator.show(dd).await()
            return if (dd.canceled) {
                null
            } else {
                dd
            }
        }
    }
}
