package org.tlsys.templates

import org.tlsys.admin.ui.*
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class EmailPreviewDialog(subject: String, val html: String) : TitleDialog("Предосмотр") {
    private val body = SaveCancelBody(okText = "Закрыть", cancelVisible = false).appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private val subjectDiv = DivLayout().appendTo(layout, grow = 0, shrink = 0)
    private val t = Span("Тема:").appendTo(subjectDiv.layout, grow = 0, shrink = 0).addClass(Styles.SIMPLE_TEXT)
    private val g = Span(subject).appendTo(subjectDiv.layout).addClass(Styles.SIMPLE_TEXT)
    private val frame = IFrameComponent().withLabel("Сообщение").appendTo(layout)
    private val scroll = ScrollController(frame.dom)

    override fun onEscapeClick() {
        super.onEscapeClick()
        close()
    }

    init {
        this.body.ok {
            close()
        }
        t.dom.style.apply {
            paddingRight = 10.px
            fontWeight = "500"
        }
        frame.content.dom.style.apply {
            border = "1px solid rgb(187 187 187)"
        }
        dom.style.apply {
            width = "80%"
            height = "80%"
        }
    }

    override suspend fun onInit() {
        super.onInit()
        frame.content.rewriteBody(html)
    }

    companion object {
        suspend fun show(subject: String, body: String) {
            EmailPreviewDialog(subject = subject, html = body).show()
        }
    }
}
