package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.admin.childElements
import org.tlsys.admin.core.PageNavigation
import org.tlsys.admin.core.Services
import org.tlsys.admin.events.EventElement
import org.tlsys.ui.Page
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.Component
import pw.binom.web.layout.FlexLayout

abstract class AbstractPage : AbstractComponent<HTMLDivElement>(), Page {
    final override val dom: HTMLDivElement = document.createDiv()
    override val eventTitleChange = EventElement()
    private var _params: Map<String, String?> = HashMap()

    init {
        afterConstruct()
    }

    protected val params: Map<String, String?>
        get() = _params

    override fun setTitle(title: String) {
        eventTitleChange.dispatch()
    }

    protected val pageLayout = FlexLayout(dom).apply {
        direction = FlexLayout.Direction.Column
    }

    //    open fun next(pageName: String): AbstractPage? = null
    protected val content: HTMLDivElement = document.createDiv().apply {
        pageLayout.add(this) {
            grow = 1
            shrink = 1
        }
    }

    protected val contentLayout = FlexLayout(content).apply {
        direction = FlexLayout.Direction.Column
    }.asParent()

    /**
     * Вызывается при обновлении параметров страницы
     */
    override suspend fun updateParams(params: Map<String, String?>) {
        this._params = params
    }

    protected val pageNavigation by Services.byClass(PageNavigation::class)

    protected suspend fun setParams(params: Map<String, String?>) {
        pageNavigation.go(pageNavigation.getUrl(this)!!, params)
    }

    override suspend fun onStart() {
        super.onStart()
        console.info("AbstractPage(${this::class.js.name})->onStart")
        console.info("Child elements:")
        contentLayout.dom.childElements.forEach {
            console.info("contentLayout->", Component.getComponent(it), it)
        }
    }
}
