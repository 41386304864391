package org.tlsys.osmicard

import org.tlsys.admin.ui.*
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class ValueEditorDialog(name: String, value: String) : TitleDialog(name) {
    val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private var canceled = false
    val editor = TextareaInput(text = value).appendTo(layout, grow = 1, shrink = 1)

    override fun onEscapeClick() {
        canceled = true
        close()
    }

    init {
        dom.style.height = 300.px
        body.cancel {
            canceled = true
            close()
        }
        body.ok {
            canceled = false
            close()
        }
    }

    companion object {
        suspend fun show(
            name: String,
            value: String,
        ): String? {
            val d = ValueEditorDialog(name = name, value = value)
            d.show()
            return if (d.canceled) {
                null
            } else {
                d.editor.text
            }
        }
    }
}
