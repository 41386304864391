package org.tlsys.node.properties

import org.tlsys.ParamEditor
import org.tlsys.ParamEditorFactory
import org.tlsys.cms.ui.TimeCountSelector
import org.tlsys.wc.ParamDTO
import org.w3c.dom.HTMLDivElement

object DurationPropertyFactory : ParamEditorFactory {
    override fun create(type: String, canBeEmpty: Boolean) =
        if (type == ParamDTO.Type.DURATION.name) {
            DurationProperty(canBeEmpty)
        } else {
            null
        }
}

class DurationProperty(canBeEmpty: Boolean) : ParamEditor<HTMLDivElement>, TimeCountSelector(canBeEmpty = canBeEmpty) {
    override var paramValue: String
        get() = time?.toString() ?: ""
        set(value) {
            time = value.toLongOrNull() ?: 0
            autoTimeFormat()
        }
}
