package org.tlsys.styles

object Card {
    val MAIN = "card"
    val TITLE = "title"
}

object Text {
    const val DEFAULT_TEXT = "default_text"
    const val DEFAULT_LINK = "default_link"
}

object EditTextStyle {
    val MAIN = "edit_text"
    val PLACE_HOLDER = "place_holder"
    val ERROR = "error"
    val TOP_PLACEHOLDER = "top_placeholder"
}

object ButtonStyle {
    val MATERIAL_BUTTON = arrayOf("flat_button", "material-icons")
}

object CardFlowStyles {
    val CARD400 = "card400"
}

object DateSelector {
    val MAIN = "date_selector"
    val ICON = "icon"
}

object DialogStyle {
    const val MAIN = "dialog"
    const val TITLE = "title_dialog"
    const val ANIMATION_SHOW = "dialog_animation_show"
    const val ANIMATION_HIDE = "dialog_animation_hide"
}

object ExternalScript {
    const val IFRAME_STYLE = "external_script_iframe"
    const val LOADING_SCREEN = "external_script_loading"
    const val LOADED_SCREEN = "external_script_loaded"
    const val EMPTY_SCREEN = "external_script_empty"
    const val ERROR_SCREEN = "external_script_error"
    const val SCREEN_BG = "external_script_screen"
    const val SCREEN_MESSAGE = "external_script_message"
    const val CONTAINER = "external_script_container"
}
