package org.tlsys.goods.pages

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.admin.ui.Styles.LIST_ITEM_STYLE
import org.tlsys.admin.ui.Styles.SIMPLE_TEXT
import org.tlsys.await
import org.tlsys.css.CSS
import org.tlsys.goods.DTODictionary
import org.tlsys.goods.api.GoodDictionaryService
import org.tlsys.goods.dialogs.EditDictionary
import org.tlsys.goods.services.dictionaryIterator
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

private val BUTTON_STYLE = CSS.genName()
private val NAME_STYLE = CSS.genName()
private val ROW_STYLE = CSS.style {
    height = 56.px
    ">.$NAME_STYLE" then {
        textAlign = "left"
        paddingLeft = 16.px
    }
}.name

class DictionaryListPage : AbstractPage() {
    override suspend fun getTitle(): String = TITLE

    companion object {
        const val URI = "goods_dictionaries"
        const val TITLE = "Списки товаров"
    }

    private val tooBar = ActionPanel()
        .appendTo(contentLayout, grow = 0, shrink = 0).apply {
            addSpace()
        }
    private val addBtn = tooBar.button("Добавить список").onClick {
        val name = EditDictionary.show(null).await() ?: return@onClick
        val dic = ProcessBlocker.block("Создание списка") {
            goodDictionaryService.create(name).await()
        }.await()

        val component = Item(dic).appendTo(listLayout, grow = 0, shrink = 0)
        Effects.createItem(component.dom)
    }

    private val list = document.createDiv().appendTo(contentLayout, grow = 1, shrink = 1)
    private val scroll = ScrollController(list)
    private val listLayout = FlexLayout(list, direction = FlexLayout.Direction.Column)

    init {
        onInit {
            while (it.hasNext() && !scroll.visibleScrollY) {
                Item(it.next()).appendTo(listLayout, grow = 0, shrink = 0)
            }
        }
    }

    override suspend fun onStart() {
        super.onStart()
        lastClick?.dom?.let { Effects.blockRow(it) }
    }

    override suspend fun next(path: String): Page {
        return DictionaryPage(path)
    }

    private val goodDictionaryService by Services.byClass(GoodDictionaryService::class)

    val it = goodDictionaryService.dictionaryIterator(minForLoad = 20, maxPart = 30)
    private var lastClick: Item? = null

    inner class Item(var dic: DTODictionary) : AbstractComponent<HTMLDivElement>() {
        override val dom: HTMLDivElement = document.createDiv()
        private val layout = FlexLayout(this, alignItems = FlexLayout.AlignItems.Center)

        val name = document.createLink()
            .innerText(dic.name)
            .addClass(SIMPLE_TEXT).appendTo(layout)
            .addClass(NAME_STYLE)
            .addClass("link")
            .href("${window.location.hash}/${dic.id}").also {
                it.on("click") {
                    lastClick = this
                }
            }
        private val actionBtns = ActionPlace().appendTo(layout, grow = 0, shrink = 0)
        val editBtn = actionBtns.iconBtn(MaterialIcons.EDIT)
        val deleteBtn = actionBtns.iconBtn(MaterialIcons.DELETE)

//        val editBtn = FloatButton(image = "settings.svg", width = ICON_SIZE, height = ICON_SIZE).appendTo(layout, grow = 0, shrink = 0).addClass(BUTTON_STYLE)
//        val deleteBtn = FloatButton(image = "delete.svg", width = ICON_SIZE, height = ICON_SIZE).appendTo(layout, grow = 0, shrink = 0).addClass(BUTTON_STYLE)

        init {
            addClass(LIST_ITEM_STYLE)
            addClass(ROW_STYLE)
            actionBtns.visibleOnHover(this)
            editBtn.onClick {
                val newName = EditDictionary.show(dic).await() ?: return@onClick
                ProcessBlocker.block("Изменение списка") {
                    dic = goodDictionaryService.edit(newName).await()
                        ?: throw IllegalStateException("Список товаров ${dic.id} не найден")
                    name.innerText(dic.name)
                    Effects.blockRow(this.dom)
                }.await()
            }

            deleteBtn.onClick {
                if (YesNoDialog.show("Удалить список \"${dic.name}\"?", 300).await()) {
                    ProcessBlocker.block("Удаление списка ${dic.name}") {
                        goodDictionaryService.delete(dic.id.jdto).await()
                    }.await()

                    Effects.removeItem(this.dom).await()
                    dom.remove()
                }
            }
        }
    }
}
