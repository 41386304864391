package org.tlsys.admin.serialization

import kotlinx.serialization.*
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.descriptors.element
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Polymorphic
@Serializable(ResponseSerializer::class)
data class Response(@SerialName("data")@Contextual @Polymorphic val result: Any?, val success: Boolean) : R

private val pp = PolymorphicSerializer(Any::class).nullable

private object ResponseSerializer : KSerializer<Response> {
    override val descriptor: SerialDescriptor =
            buildClassSerialDescriptor("response") {
                element<String>("data")
                element<String>("success")
            }

    override fun deserialize(decoder: Decoder): Response =
            decoder.beginStructure(descriptor).let {
                var result: Any? = null
                var success: Boolean? = null
                var count = 0
                while (true) {
                    when (it.decodeElementIndex(descriptor)) {
                        0 -> result = it.decodeSerializableElement(descriptor, 0, pp)
                        1 -> success = it.decodeBooleanElement(descriptor, 1)
                        else -> throw IllegalStateException()
                    }
                    count++
                    if (count == 2)
                        break
                }
                it.endStructure(descriptor)
                Response(result, success!!)
            }

    override fun serialize(encoder: Encoder, value: Response) {
        encoder.beginStructure(descriptor).also {
            it.encodeSerializableElement(descriptor, 0, pp, value.result)
            it.encodeBooleanElement(descriptor, 1, value.success)
            it.endStructure(descriptor)
        }
    }
}