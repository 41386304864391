package org.tlsys.core.documents

import org.tlsys.json.*

class JDocument(
        val number: String,
        val uid: String,
        val date: Long,
        val createDate: Long,
        val positions: List<JPosition>,
        val bonus: List<JBonus>,
        val terminalId: Long,
        val bonusPay: Long,
        val referUid: String?,
        val cashback: Boolean,
        val bonusCode: String?,
        val member: Long
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JDocument> {
        override val type: String
            get() = "jdocument"

        override fun read(node: JsonNode) = JDocument(
                number = node["number"]!!.string(),
                uid = node["uid"]!!.string(),
                date = node["date"]!!.long(),
                createDate = node["createDate"]!!.long(),
                positions = JsonFactory.readArray(node["positions"]!!),
                bonus = JsonFactory.readArray(node["bonus"]!!),
                terminalId = node["terminalId"]!!.long(),
                bonusPay = node["bonusPay"]!!.long(),
                referUid = node["referUid"]?.string(),
                cashback = node["cashback"]!!.boolean(),
                bonusCode = node["bonusCode"]?.string(),
                member = node["member"]!!.long()
        )

        override fun write(obj: JDocument) = JsonNode.obj(
                "number" to obj.number.json,
                "uid" to obj.uid.json,
                "date" to obj.date.json,
                "createDate" to obj.createDate.json,
                "positions" to JsonFactory.writeArray(obj.positions),
                "bonus" to JsonFactory.writeArray(obj.bonus),
                "terminalId" to obj.terminalId.json,
                "bonusPay" to obj.bonusPay.json,
                "referUid" to obj.referUid?.json,
                "cashback" to obj.cashback.json,
                "bonusCode" to obj.bonusCode?.json,
                "member" to obj.member.json
        )

    }
}

class JPosition(
        val code: String,
        val count: Long,
        val price: Long,
        val name: String?,
        val bonus: List<JBonus>
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JPosition> {
        override val type: String
            get() = "jposition"

        override fun read(node: JsonNode) = JPosition(
                code = node["code"]!!.string(),
                count = node["count"]!!.long(),
                price = node["price"]!!.long(),
                name = node["name"]?.string(),
                bonus = JsonFactory.readArray(node["bonus"]!!)
        )

        override fun write(obj: JPosition) = JsonNode.obj(
                "code" to obj.code.json,
                "count" to obj.count.json,
                "price" to obj.price.json,
                "name" to obj.name?.json,
                "bonus" to JsonFactory.writeArray(obj.bonus)
        )

    }
}

class JBonus(
        val member: Long,
        val amount: Long,
        val start: Long,
        val end: Long?
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JBonus> {
        override val type: String
            get() = "jbonus"

        override fun read(node: JsonNode) = JBonus(
                member = node["member"]!!.long(),
                amount = node["amount"]!!.long(),
                start = node["start"]!!.long(),
                end = node["end"]?.long()
        )

        override fun write(obj: JBonus) = JsonNode.obj(
                "member" to obj.member.json,
                "amount" to obj.amount.json,
                "start" to obj.start.json,
                "end" to obj.end?.json
        )

    }
}