package org.tlsys.lk.dialogs

import org.tlsys.admin.ui.*
import org.tlsys.lk.OsmicardEvent
import org.tlsys.lk.OsmicardEventDto
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.date.DateTime
import pw.binom.web.layout.*

class EmmitOsmicardEventDialog : TitleDialog("Эмулировать событие") {
    val body = SaveCancelBody(okText = "Закрыть")
        .appendTo(super.layout, grow = 1, shrink = 1).also {
            it.okBtn.text = "Создать"
        }

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val type =
        Combobox2(
            items = OsmicardEvent.values().map { "${it.code} - ${it.description}" },
            select = OsmicardEvent.cardcreate.ordinal,
        ).appendTo(layout)
    private val code = EditText(placeHolder = "Карта клиента").appendTo(layout)
    private var dto: OsmicardEventDto? = null

    init {
        body.ok {
            dto = OsmicardEventDto(
                simulated = true,
                serial = code.text,
                event = OsmicardEvent.values()[type.select],
                createdAt = DateTime.now,
            )
            close()
        }
        body.cancel {
            dto = null
            close()
        }
    }

    companion object {
        suspend fun show(): OsmicardEventDto? {
            val d = EmmitOsmicardEventDialog()
            d.show()
            return d.dto
        }
    }
}
