package org.tlsys.members.list

import org.tlsys.admin.MemberListProvider
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.ValidationResult
import org.tlsys.api.MembersService
import org.tlsys.api.searchMembersIterator
import org.tlsys.core.AsyncIterator
import org.tlsys.dto.MemberDTO
import org.tlsys.dto.SearchSource
import org.tlsys.lex.SimpleParse

object ByCardMemberListProvider : MemberListProvider {
    override val source: SearchSource
        get() = SearchSource.CARD

    override fun valid(it: String): ValidationResult =
        try {
            if (SimpleParse.parse(it) != null) ValidationResult.valid() else ValidationResult.invalid()
        } catch (e: dynamic) {
            ValidationResult.invalid()
        }

    private val membersService by Services.byClass(MembersService::class)
    override val title: String
        get() = "Карта"

    override val serachBy: String
        get() = "по Карте"

    override suspend fun get(search: String, filter: MemberListProvider.Filter): AsyncIterator<MemberDTO> =
        membersService.searchMembersIterator(
            exp = search.takeIf { it.isNotBlank() },
            source = source,
            withTags = filter.withTags,
            withoutTags = filter.withoutTags,
            sex = filter.sex,
            maxPart = 50,
            minForLoad = 30,
        )
}
