package org.tlsys

fun encrypt(text: String, key: Int = 10): String =
    text.asSequence().map {
        (it.toInt() xor 10).toString(16)
    }.joinToString("g")

fun decrypt(text: String, key: Int = 10): String {
    val sb = StringBuilder()
    text.splitToSequence('g')
        .filter { it.isNotBlank() }
        .map {
            (it.toInt(16) xor 10).toChar()
        }.forEach {
            sb.append(it)
        }
    return sb.toString()
}
