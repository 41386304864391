package org.tlsys.admin

import kotlinx.serialization.Serializable

@Serializable
enum class TimeIntervalEnum(h: Int) {
    T0(0),
    T1(1),
    T2(2),
    T3(3),
    T4(4),
    T5(5),
    T6(6),
    T9(7),
    MANUAL(0),
    ;

    val delay: Long = 1000L * 60 * 60 * h
}
