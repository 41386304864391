package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.browser.localStorage
import org.tlsys.*
import org.tlsys.admin.MemberListProvider
import org.tlsys.admin.SELECTED_SEARCH_ATTRIBUTE
import org.tlsys.admin.addClass
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.ValidationResult
import org.tlsys.admin.form.Validator
import org.tlsys.core.AsyncIterator
import org.tlsys.css.CSS
import org.tlsys.dto.MemberDTO
import org.tlsys.ui.DivController
import org.tlsys.ui.createDiv
import org.tlsys.ui.on
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.get
import org.w3c.dom.set
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.js.Promise

class MemberSelectorDialog private constructor() : TitleDialog("Поиск клиента") {
    private val body = SaveCancelBody(okText = "Выбрать")
        .appendTo(super.layout, grow = 1, shrink = 1)
    override val layout = body.componentsLayout

    private val SEARCH_VALIDATOR = Validator<String> {
        if (it.isBlank()) {
            valid()
        } else {
            currentSearchSource.valid(it)
        }
    }
    private val searchDiv =
        FlexLayout.div { direction = FlexLayout.Direction.Row; alignItems = FlexLayout.AlignItems.Center }
            .appendTo(layout, grow = 0, shrink = 0)
    private val searchInput = SearchInput()
        .appendTo(searchDiv, grow = 1, shrink = 0)
        .apply {
            addTextValidator(SEARCH_VALIDATOR)
        }

    private var searchSourceSelector = Span("").apply {
        // dom.classList.add(MembersPage.SEARCH_SELECTOR.name)
    }.appendTo(searchDiv, grow = 0, shrink = 0).addClass(Styles.SIMPLE_TEXT)

    private fun redrawSearchSource() {
        searchSourceSelector.text = currentSearchSource.serachBy
    }

    private val fragmentViewSimple = ComponentView()
        .appendTo(layout, grow = 1, shrink = 1)

    private val list by Services.listByClass(MemberListProvider::class)

    var currentSearchSource: MemberListProvider = run {
        localStorage.get(SELECTED_SEARCH_ATTRIBUTE)?.let { uid ->
            list.find { it.source.name == uid }
        } ?: list[0]
    }

    private var selected: Long? = null

    private val onSelected: (Long, Boolean) -> Unit = { member, close ->
        selected = member
        if (close) {
            close()
        }
    }

    init {
        dom.style.apply {
            width = 600.px
            height = 600.px
        }
        body.okBtn.onClick {
            close()
        }

        body.cancelBtn.onClick {
            selected = null
            close()
        }

        searchInput.onEnter {
            if (!searchInput.valid || searchInput.text.isBlank()) {
                return@onEnter
            }
            async2 {
                fragmentViewSimple.set2(
                    MemberShortList(
                        currentSearchSource.get(
                            search = searchInput.text,
                            filter = MemberListProvider.Filter(),
                        ),
                        onSelected,
                    ),
                )
            }
        }

        redrawSearchSource()

        searchSourceSelector.dom.onclick = {
            val rec = searchSourceSelector.dom.getBoundingClientRect()
            async2 {
                val newSearchSet = SelectorSearchSource.select(
                    x = rec.left.toInt(),
                    y = rec.top.toInt(),
                ).await()

                if (newSearchSet != null) {
                    localStorage.set(SELECTED_SEARCH_ATTRIBUTE, newSearchSet.source.name)
                    currentSearchSource = newSearchSet
                    redrawSearchSource()
                }
            }
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        selected = null
        close()
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        fun show() = Promise<Long?> { d, c ->
            val dd = MemberSelectorDialog()

            layoutNavigator.show(dd) {
                d(dd.selected)
            }
        }
    }
}

private class MemberShortList(result: AsyncIterator<MemberDTO>, val onSelected: (Long, Boolean) -> Unit) :
    AbstractFragment<HTMLDivElement>(document.createDiv()) {
    val iterator = result
    val layout = FlexLayout(this, direction = FlexLayout.Direction.Column)

    private var inited = false
    val scroll = ScrollController(dom)
    private var selected: Item? = null

    override suspend fun onStart() {
        if (!inited) {
            inited = true

            async2 {
                while (!scroll.visibleScrollY && iterator.hasNext()) {
                    Item(iterator.next()).appendTo(layout, grow = 0, shrink = 0)
                }

                scroll.addScrollListener {
                    async2 {
                        if (iterator.hasNext()) {
                            Item(iterator.next()).appendTo(layout, grow = 0, shrink = 0)
                        }
                    }
                }
            }
        }
    }

    private inner class Item(val member: MemberDTO) : DivController() {
        private val layout = FlexLayout(this)

        //        val sexIcon = Span().appendTo(layout, grow = 0, shrink = 0).also {
//            val bg = when {
//                member.active && member.sex -> js("man_enable_png")
//                member.active && !member.sex -> js("woman_enable_png")
//                !member.active && member.sex -> js("man_disable_png")
//                else -> js("woman_disable_png")
//            }
//            it.dom.style.background = "url($bg)"
//            it.dom.style.apply {
//                backgroundColor = "transparent"
//                backgroundRepeat = "no-repeat"
//                backgroundPosition = "16px center"
//                border = "0"
//                width = 25.px
//                minHeight = 21.px
//            }
//        }
        val span = Span(
            "${member.lastName} ${member.firstName} ${
                member.middleName
                    ?: ""
            }",
        ).appendTo(layout, grow = 1, shrink = 1)
            .also {
                it.dom.style.apply {
                    fontFamily = Styles.DEFAULT_TEXT_FONT
                    fontSize = Styles.DEFAULT_FONT_SIZE.px
                    paddingLeft = 10.px
                    userSelect7 = "none"
                }
            }

        init {
            dom.style.apply {
                padding = "10px 0px"
                overflow = "hidden"
                cursor = "pointer"
            }
            dom.classList.add(ITEM_STYLE)
            dom.on("click") {
                selected?.dom?.classList?.remove(SELECTED)
                selected = this
                dom.classList.add(SELECTED)
                onSelected(member.id, false)
            }
            dom.on("dblclick") {
                onSelected(member.id, true)
            }
        }
    }
}

private val SELECTED = CSS.genName()
private val ITEM_STYLE = CSS.style {
    ":not(.$SELECTED)" then {
        ":nth-child(2n)" then {
            backgroundColor = "rgba(0, 0, 0, 0.03)"

            ":hover" then {
                backgroundColor = "rgba(0, 0, 0, 0.08)"
            }
        }
        ":hover" then {
            backgroundColor = "rgba(0, 0, 0, 0.08)"
        }
    }
    ".$SELECTED" then {
        backgroundColor = "rgba(0, 0, 0, 0.2)"
    }
    transition = "100ms background-color linear"
}.name
