package org.tlsys

import kotlinx.browser.document
import org.w3c.dom.MutationObserver
import org.w3c.dom.Node
import kotlin.js.Promise

/**
 * Промис, который выполнится, когда [this] добавится в документ
 */
fun <T : Node> T.waitDocumentAdd() = Promise<Unit> { d, c ->
    var ob: MutationObserver? = null
    ob = MutationObserver { _, _ ->
        if (this.isOnDocument) {
            ob!!.disconnect()
            d(Unit)
        }
    }

    ob.observe(document.body!!, js("({childList: true, subtree:true})"))
}

/**
 * Промис, который выполнится, когда [this] удалится из документа
 */
fun <T : Node> T.waitDocumentRemove() = Promise<Unit> { d, c ->
    var ob: MutationObserver? = null
    ob = MutationObserver { _, _ ->

        if (!this.isOnDocument) {
            ob!!.disconnect()
            d(Unit)
        }
    }

    ob.observe(document.body!!, js("({childList: true, subtree:true})"))
}

val Node.isOnDocument: Boolean
    get() {
        var g: Node? = this
        do {
            g = g!!.parentNode
            if (g == null) {
                return false
            }
            if (g === document) {
                return true
            }
        } while (true)
    }
