package org.tlsys.frontol

import androidx.compose.runtime.*
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.tlsys.Percent
import org.tlsys.admin.components.*
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.*
import org.tlsys.frontol.api.ConfigService
import org.tlsys.frontol.dto.ProcessingConfig
import org.tlsys.json.jdto
import org.tlsys.toPercent

private val configService by lazy { Services.byClass(ConfigService::class).get() }

@Composable
fun bool(
    text: String,
    config: MutableState<ProcessingConfig?>,
    value: (ProcessingConfig) -> Boolean,
    func: ProcessingConfig.(Boolean) -> ProcessingConfig,
) {
    P {
        Checkbox(
            defaultValue = config.value?.let(value) ?: false,
            attrs = {
                onChange {
                    GlobalScope.launch {
                        try {
                            val newConf = func(config.value!!, it.value)
                            configService.setConfig(newConf).await()
                            config.value = newConf
                        } finally {
                            it.target.checked = value(config.value!!)
                        }
                    }
                }
            },
        ) { Text(text) }
    }
}

@Composable
fun str(
    text: String,
    config: MutableState<ProcessingConfig?>,
    validator: Validator<String>? = null,
    value: (ProcessingConfig) -> String,
    func: ProcessingConfig.(String) -> ProcessingConfig,
) {
    P {
        InputText(
            placeHolder = text,
            value = config.value?.let { value(it) } ?: "",
            validator = validator,
            withErrorMessage = false,
            inputAttrs = { validator ->
                onChange {
                    if (validator.valid(it.value).isValid) {
                        GlobalScope.launch {
                            val newConf = func(config.value!!, it.value)
                            configService.setConfig(newConf).await()
                            config.value = newConf
                        }
                    }
                }
            },
        )
    }
}

private const val CARD_WIDTH = 400

fun ConfigPage2() =
    ComposablePage {
        var loading by remember { mutableStateOf(false) }
        val config = remember { mutableStateOf<ProcessingConfig?>(null) }
        LaunchedEffect(null) {
            try {
                loading = true
                config.value = configService.getConfig().await()
            } finally {
                loading = false
            }
        }
        CardArray {
            Card(label = "Касса") {
                if (loading) {
                    Loading()
                } else {
                    DivFlex {
                        str(
                            text = "Префиксы карт (через запятую)",
                            config = config,
                            value = {
                                it.cardPrefix
                                    .map { it.value }
                                    .joinToString(", ")
                            },
                            func = {
                                copy(
                                    cardPrefix =
                                        it.split(',')
                                            .map { it.trim().jdto }
                                            .filter { it.value.isNotBlank() }
                                            .jdto(),
                                )
                            },
                        )
                        bool(
                            text = "Использовать артикулы вместо кодов",
                            config = config,
                            value = { it.useGoodsArticle.value },
                            func = { copy(useGoodsArticle = it.jdto) },
                        )
                        bool(
                            text = "Отображать на кассе карты клиента",
                            config = config,
                            value = { it.showCards.value },
                            func = { copy(showCards = it.jdto) },
                        )
                        bool(
                            text = "Отображать на кассе телефоны клиента",
                            config = config,
                            value = { it.showPhone.value },
                            func = { copy(showPhone = it.jdto) },
                        )
                        bool(
                            text = "Выводить в слип средство идентификации клиента",
                            config = config,
                            value = { it.printInSlipLoginInfo.value },
                            func = { copy(printInSlipLoginInfo = it.jdto) },
                        )
                        bool(
                            text = "Отображать на кассе ФИО клиента",
                            config = config,
                            value = { it.showFIO.value },
                            func = { copy(showFIO = it.jdto) },
                        )
                        bool(
                            text = "Всегда запрашивать код из SMS",
                            config = config,
                            value = { it.verifyOnPhoneAlways.value },
                            func = { copy(verifyOnPhoneAlways = it.jdto) },
                        )
                    }
                }
            }
            Card(label = "Идентификация") {
                if (loading) {
                    Loading()
                } else {
                    DivFlex {
                        bool(
                            text = "Идентификация по номеру телефона",
                            config = config,
                            value = { it.enterByPhone.value },
                            func = { copy(enterByPhone = it.jdto) },
                        )
                        bool(
                            text = "Разрешить неизвестные карты",
                            config = config,
                            value = { it.allowUnkownCard.value },
                            func = { copy(allowUnkownCard = it.jdto) },
                        )
                        str(
                            text = "Длина SMS кода",
                            config = config,
                            validator = IntegerValidator.FORMAT and DoubleValidator.more2(1.0),
                            value = { it.smsCodeLength.value.toString() },
                            func = { copy(smsCodeLength = it.toInt().jdto) },
                        )
                    }
                }
            }
            Card(label = "Автосоздание") {
                if (loading) {
                    Loading()
                } else {
                    DivFlex {
                        bool(
                            text = "Автосоздание бонусной карты",
                            config = config,
                            value = { it.autoCreateCard.value },
                            func = { copy(autoCreateCard = it.jdto) },
                        )
                        bool(
                            text = "Автосоздание клиента по номеру телефона",
                            config = config,
                            value = { it.autoCreatePhone.value },
                            func = { copy(autoCreatePhone = it.jdto) },
                        )
                        bool(
                            text = "SMS подтверждение при создании клиента по телефону",
                            config = config,
                            value = { it.verifyOnPhoneCreate.value },
                            func = { copy(verifyOnPhoneCreate = it.jdto) },
                        )
                    }
                }
            }
            Card(label = "Списание бонусов") {
                if (loading) {
                    Loading()
                } else {
                    DivFlex {
                        str(
                            text = "Максимальный процент оплаты чека бонусами",
                            config = config,
                            validator =
                                DoubleValidator.FORMAT
                                    and DoubleValidator.moreOrEquals2(0.0)
                                    and DoubleValidator.forMaxAfterPoint(2),
                            value = { Percent(it.maxBonusPay.value).asDouble.toString() },
                            func = { copy(maxBonusPay = it.toDouble().toPercent.asLong.jdto) },
                        )
                        bool(
                            text = "Списание бонусов по номеру телефона",
                            config = config,
                            value = { it.allowDropByPhone.value },
                            func = { copy(allowDropByPhone = it.jdto) },
                        )
                        bool(
                            text = "Проверочный код для оплаты через телефон",
                            config = config,
                            value = { it.verifyOnPhonePay.value },
                            func = { copy(verifyOnPhonePay = it.jdto) },
                        )
                        str(
                            text = "Текст SMS с кодом подтверждения",
                            config = config,
                            validator = TextValidators.NOT_BLANK,
                            value = { it.smsCodeTemplate.value },
                            func = { copy(smsCodeTemplate = it.jdto) },
                        )
                    }
                }
            }
            Card(label = "Автоактивация") {
                if (loading) {
                    Loading()
                } else {
                    DivFlex {
                        bool(
                            text = "Автоактивация телефона",
                            config = config,
                            value = { it.autoActivatePhone.value },
                            func = { copy(autoActivatePhone = it.jdto) },
                        )
                        bool(
                            text = "Автоактивация бонусной карты",
                            config = config,
                            value = { it.autoActivateCard.value },
                            func = { copy(autoActivateCard = it.jdto) },
                        )
                    }
                }
            }
        }
    }
