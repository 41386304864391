package org.tlsys.lk.pages

import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.json.jdto
import org.tlsys.lk.RemoteCardPR
import pw.binom.web.layout.*

class CodePrSettingPage : AbstractPage() {
    companion object {
        val URI = "cardpr"
        val TITLE = "CardPR"
    }

    override suspend fun getTitle(): String = "CardPR"

    val settingPanel = SettingsPlane().appendTo(contentLayout, grow = 1, shrink = 1)

    val status = settingPanel.add("Статус", BigCheckboxNoText())
    val syncOnlyWithApp = settingPanel.add("Синхронизировать только установивших приложение", BigCheckboxNoText())
    val appKey = settingPanel.add("App Key", EditText())
    val siteUrl = settingPanel.add("Ссылка", EditText())
    val autoActive = settingPanel.add("Авто-активация новых клиентов", BigCheckboxNoText())

    init {
        async2 {
            status.component.checked = RemoteCardPR.isEnable().await().value
            status.component.EVENT_CHANGED.on {
                RemoteCardPR.setEnabled(status.component.checked.jdto)
            }
        }

        async2 {
            syncOnlyWithApp.component.checked = RemoteCardPR.isSyncOnlyWithApp().await().value
            syncOnlyWithApp.component.EVENT_CHANGED.on {
                RemoteCardPR.setSyncOnlyWithApp(syncOnlyWithApp.component.checked.jdto)
            }
        }

        async2 {
            appKey.component.text = RemoteCardPR.getAppKey().await().value
            appKey.component.eventChange.on {
                RemoteCardPR.setAppKey(appKey.component.text.jdto)
            }
        }

        async2 {
            siteUrl.component.text = RemoteCardPR.getSite().await().value
            siteUrl.component.eventChange.on {
                RemoteCardPR.setSite(siteUrl.component.text.jdto)
            }
        }

        async2 {
            autoActive.component.checked = RemoteCardPR.isAutoActive().await().value
            autoActive.component.EVENT_CHANGED.on {
                RemoteCardPR.setAutoActive(autoActive.component.checked.jdto)
            }
        }
    }
}
