package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.admin.addClass
import org.tlsys.async2
import org.tlsys.css.CSS
import org.tlsys.heightPX
import org.tlsys.isOnDocument
import org.tlsys.px
import org.tlsys.ui.con
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pw.binom.web.AbstractComponent
import pw.binom.web.Component
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

val Component<*>.isUse: Boolean
    get() = dom.isOnDocument

private val headerStyle = CSS.style {
    minHeight = 30.px
    "span" {
        background = "#ccd1d9"
        ":last-child" then {
            borderRadius = "0px 4px 0px 0px"
        }
        ":first-child" then {
            borderRadius = "4px 0px 0px 0px"
        }
        padding = "5px 0px"
    }
}.name

class TableHeader : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    private val layout = FlexLayout(this, alignItems = FlexLayout.AlignItems.Center)

    fun add(text: String, grow: Int = 1, shrink: Int = 1, basis: Int? = null): TableHeader {
        Span(text).appendTo(layout, grow = grow, shrink = shrink, basis = basis)
            .addClass(Styles.SIMPLE_TEXT)
            .addClass(Styles.TEXT_CENTER)
        return this
    }

    init {
        dom.style.apply {
            position = "absolute"
            width = "100%"
        }
        addClass(headerStyle)
    }

    override suspend fun onStart() {
        super.onStart()
        layout.onStart()
    }

    override suspend fun onStop() {
        layout.onStop()
        super.onStop()
    }
}

open class ListView<T : Component<HTMLElement>>(
    emptyText: String = "Нет элементов к отображению",
    val header: Component<out HTMLElement>? = null,
    items: List<T>? = null
) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    private val layout = FlexLayout(dom, direction = FlexLayout.Direction.Column)
    private val scrollDiv = document.createDiv().appendTo(layout)
    private val listLayout = FlexLayout(scrollDiv, direction = FlexLayout.Direction.Column)
    val scroll = ScrollController(scrollDiv)
    private val childs = ArrayList<T>()
    private val noElementForView = NoElementForView(emptyText)

    init {
        afterConstruct()
        dom.style.position = "relative"
    }

    override suspend fun onStart() {
        super.onStart()
        listLayout.onStart()
        checkEmpty()
    }

    override suspend fun onStop() {
        super.onStop()
        listLayout.onStop()
    }

    private fun checkEmpty() {
        if (items.isEmpty()) {
            if (!noElementForView.isUse) {
                scrollDiv.style.display = "none"
                noElementForView.appendTo(layout, grow = 0, shrink = 0)
            }
            if (header?.isUse == true) {
                layout.remove(header.dom)
                dom.style.paddingTop = ""
            }
        } else {
            if (noElementForView.isUse) {
                scrollDiv.style.display = ""
                layout.remove(noElementForView.dom)
            }
            if (header != null && !header.isUse) {
                header.appendTo(layout, grow = 0, shrink = 0)
                val height = window.getComputedStyle(header.dom).heightPX
                dom.style.paddingTop = height.px
                header.dom.style.top = 0.px
            }
        }
    }

    open fun remove(item: T) {
        if (childs.remove(item)) {
            listLayout.remove(item.dom)
            checkEmpty()
        }
    }

    open suspend fun addLast(item: T) {
        console.info("ListView->addLast. isStarted=$isStarted")
        item.appendToAndStart(listLayout, grow = 0, shrink = 0)
        childs.add(item)
        checkEmpty()
    }

    open suspend fun addFirst(item: T): T {
        if (scrollDiv.childElementCount > 0) {
            listLayout.addBefore(item.dom, scrollDiv.firstElementChild.asDynamic()) { grow = 0; shrink = 0 }
        } else {
            addLast(item)
        }
        childs.add(0, item)
        checkEmpty()
        return item
    }

    fun clear() {
        if (isStarted) {
            async2 {
                while (listLayout.dom.hasChildNodes()) {
                    val el = (listLayout.dom.firstElementChild as HTMLElement)
                    val con = el.con()
                    if (con is Component) {
                        con.onStop()
                    }
                    el.remove()
                }
            }
        } else {
            while (listLayout.dom.hasChildNodes()) {
                listLayout.dom.firstElementChild?.remove()
            }
        }
    }

    open suspend fun addBefore(item: T, after: T) {
        val i = childs.indexOf(after)
        childs.add(i + 1, item)
        listLayout.addBeforeAndStart(item.dom, scrollDiv.firstElementChild.asDynamic()) { grow = 0; shrink = 0 }
        checkEmpty()
    }

    val items: List<T>
        get() = childs

    init {
        onInit {
            items?.forEach {
                addLast(it)
            }
        }
    }
}
