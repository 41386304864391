package org.tlsys

interface ReadyDoneListener {
    suspend fun onReady()
}

fun ReadyDoneListener(func: suspend ()->Unit)=object :ReadyDoneListener{
    override suspend fun onReady() {
        func()
    }

}