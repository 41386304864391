package org.tlsys.admin.ui

import org.tlsys.async2
import org.tlsys.await
import org.tlsys.css.animate
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import kotlinx.browser.document
import org.w3c.dom.HTMLElement
import pw.binom.web.AbstractComponent
import pw.binom.web.Component
import pw.binom.web.layout.*

class ComponentView : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    private var _view: Component<HTMLElement>? = null

    val view: Component<*>?
        get() = _view

    init {
        afterConstruct()
    }

    override suspend fun onStart() {
        console.info("ComponentView->onStart")
        super.onStart()
        _view?.onStart()
    }

    override suspend fun onStop() {
        _view?.onStop()
        super.onStop()
    }

    fun set2(view: Component<HTMLElement>) = async2 {
        set(view)
    }

    suspend fun set(view: Component<HTMLElement>) {
        view.dom.style.apply {
            position = "absolute"
            width = "100%"
            height = "100%"
            left = 0.px
            right = 0.px
            top = 0.px
            bottom = 0.px
        }
        if (isStarted) {
            val old = _view
            old?.also {
                val animPromis = old.dom.animate {
                    duration = 200
                    function = "ease-out"
                    init {
                        position = "absolute"
                        width = this@ComponentView.dom.offsetWidth.px
                        height = this@ComponentView.dom.offsetHeight.px
                    }
                    0 {
                        opacity = 1.0
                    }

                    100 {
                        opacity = 0.0
                    }

                    onEnd {
                        old.dom.remove()
                    }
                }.start()
                old.onStop()
            }

            this._view = view
            view.dom.appendTo(dom)
            val animPromis = view.dom.animate {
                duration = 200
                function = "ease-out"

                start {
                    position = "absolute"
                    width = this@ComponentView.dom.offsetWidth.px
                    height = this@ComponentView.dom.offsetHeight.px
                    position = "absolute"
                    width = "100%"
                    height = "100%"
                    left = 0.px
                    right = 0.px
                    top = 0.px
                    bottom = 0.px
                }
                0 {
                    opacity = 0.0
                }

                100 {
                    opacity = 1.0
                }

                end {
                    position = ""
                }
            }.start()

            view.onStart()
            animPromis.await()
        } else {
            this._view?.dom?.remove()
            this._view = view
            view.appendTo(dom)
        }
        view.dom.style.position=""
    }

    init {
        dom.style.position = "relative"
    }
}