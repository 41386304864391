package org.tlsys.admin

import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.DtoDescriptionRU
import org.tlsys.JsonObject
import pw.binom.date.DateTime
import pw.binom.uuid.UUID

@Serializable
data class OrderPromotionRecord(
    @DtoDescriptionRU("Тип акции")
    val type: OrderPromotionType,
    @Contextual
    @DtoDescriptionRU("Идентификатор акции")
    val id: UUID,
    @DtoDescriptionRU("Параметры акции")
    val params: JsonObject,
    @DtoDescriptionRU("Заголовок акции")
    val title: String,
    @DtoDescriptionRU("Идентификатор пользователя владельца акции")
    @SerialName("owner_user_id")
    val ownerUserId: Long,

    @DtoDescriptionRU("Статус акции")
    val status: PromotionStatus,
    @DtoDescriptionRU("Дата создания акции")
    @Contextual
    @SerialName("create_date")
    val createDate: DateTime,
    @DtoDescriptionRU("Дата старта акции")
    @Contextual
    @SerialName("start_date")
    val startDate: DateTime,
    @DtoDescriptionRU("Дата окончания акции")
    @Contextual
    @SerialName("end_date")
    val endDate: DateTime?,
)
