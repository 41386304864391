package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.removeClass
import org.tlsys.addClass
import org.tlsys.admin.events.EventElementValue
import org.tlsys.css.CSS
import org.tlsys.css.animate
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.tlsys.ui.on
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent

private val STYLE_TAB = "tab"
private val ACTIVE = "active"
private val INDICATOR = "indicator"
private val STYLE2 = CSS.style {
    display = "flex"
    flexDirection = "row"
    position = "relative"
    ".$INDICATOR" {
        backgroundColor = "#26a69a"
        height = 2.px
        boxSizing = "border-box"
        position = "absolute"
        bottom = 0.px
        // transition = "all 300ms ease-in-out"
    }
    ".$STYLE_TAB" {
        padding = "8.5px 33px"
        fontFamily = Styles.DEFAULT_TEXT_FONT
        fontWeight = "bold"
        lineHeight = 24.px
        fontSize = 14.px
        color = "#b0bec5"
        border = "1px solid transparent"
        cursor = "pointer"
        letterSpacing = "0.8px"
        textTransform = "uppercase"

        transition = "100ms color linear"

        userSelect = "none"

        ":hover" then {
            color = "#26a69a"
        }

        ".$ACTIVE" then {
            cursor = "default"
            color = "#26a69a"
        }
    }
}.name

open class Tabs(bottomPudding: Boolean = false) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    val event_ACTIVE_CHANGED = EventElementValue<Int>()
    private val indicator = document.createDiv().addClass(INDICATOR)

    inner class Tab(val index: Int, val render: (HTMLDivElement) -> Unit, val listener: (() -> Unit)?) :
        AbstractComponent<HTMLDivElement>() {
        override val dom: HTMLDivElement = document.createDiv()

        init {
            dom.addClass(STYLE_TAB)
            dom.on("click") {
                this@Tabs.active = index
            }
        }
    }

    init {
        afterConstruct()
        if (bottomPudding) {
            dom.style.marginBottom = 5.px
        }
        dom.addClass(STYLE2)
        dom.appendChild(indicator)
    }

    private val tabs = ArrayList<Tab>()

    fun add(text: String, listener: (() -> Unit)? = null): Tab = add({ it.innerText = text }, listener)

    fun add(render: (HTMLDivElement) -> Unit, listener: (() -> Unit)? = null): Tab {
        val div = Tab(index = tabs.size, render = render, listener = listener)
        dom.appendChild(div.dom)
        div.render(div.dom)
        refreshActive(active)
        tabs += div
        return div
    }

    private var _active: Int = -1

    operator fun get(index: Int): Tab {
        return tabs[index]
//        if (index < 0 || index >= size) throw IndexOutOfBoundsException()
//        return (dom.childNodes[index]!! as HTMLElement).con() as Tab
    }

    val size: Int
        get() = dom.childNodes.length

    private fun refreshIndicator() {
        val leftStart = dom.getBoundingClientRect().left
        if (_active >= 0 && tabs.isNotEmpty()) {
            val tab = tabs[_active]
            val rect = tab.dom.getBoundingClientRect()
            val left = rect.left - leftStart

            setIndication(left, rect.width)
        }
    }

    private fun refreshActive(index: Int) {
        val oldActiove = _active
        tabs.forEachIndexed { i, tab ->
            if (index == i) {
                tab.dom.addClass(ACTIVE)
                _active = index
                tab.listener?.invoke()
            } else {
                tab.dom.removeClass(ACTIVE)
            }
        }
        refreshIndicator()
    }

    private fun setIndication(left: Double, width: Double) {
        val old = indicator.style.left.removeSuffix("px").toDoubleOrNull() ?: 0.0
        val toLeft = old > left
        val right = dom.getBoundingClientRect().width - (left + width)

        val rr = indicator.style.right
        val ll = indicator.style.left

        indicator.animate {
            duration = 300
            function = "ease-in-out"

            if (toLeft) {
                0 {
                    this.left = ll
                    this.right = rr
                }
                10 {
                    this.right = rr
                }
                50 {
                    this.left = left.px
                }
                100 {
                    this.left = left.px
                    this.right = right.px
                }
            } else {
                0 {
                    this.left = ll
                    this.right = rr
                }
                10 {
                    this.left = ll
                }
                50 {
                    this.right = right.px
                }
                100 {
                    this.left = left.px
                    this.right = right.px
                }
            }
        }.start()

        indicator.style.apply {
            this.left = left.px
            this.right = right.px
        }
    }

    var active: Int
        get() = _active
        set(index) {
            if (index == _active) {
                return
            }
            refreshActive(index)
            event_ACTIVE_CHANGED.dispatch(index)
        }

    override suspend fun onStart() {
        super.onStart()
//        init.then {
//            refreshActive(_active)
//        }
        console.info("Tab update")
        refreshActive(_active)
        window.setTimeout(
            {
                refreshIndicator()
            },
            100,
        )
    }
}
