package org.tlsys.api

import org.tlsys.AutoLoadAsyncListIterator
import org.tlsys.LocalCache
import org.tlsys.dto.auth.UserDTO
import org.tlsys.json.jdto


fun UsersService.getUsersIterator(minForLoad: Int, maxPart: Int) =
    AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
        findUsers(offset = offset.toInt().jdto, max = max.jdto).promise
    })

private var usersLocalCache: LocalCache<Long, UserDTO>? = null

suspend fun UsersService.getUserById2(userId: Long): UserDTO? {
    if (usersLocalCache == null) {
        usersLocalCache = LocalCache(1000L * 60 * 30) {
            getUserById(userId.jdto).promise
        }
    }
    return usersLocalCache!!.get(userId)
}