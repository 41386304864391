package org.tlsys.templates

import org.tlsys.Template
import org.tlsys.TemplateType
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.connectWith
import org.tlsys.admin.ui.*
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.uuid.nextUuid
import pw.binom.web.layout.*
import kotlin.random.Random

class TelegramTemplateDialog(val dto: Template?, val new: Boolean) : TitleDialog("Шаблон Telegram сообщения") {
    private val body = SaveCancelBody(okText = if (new) "Создать" else "Сохранить").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private val name = EditText(placeHolder = "Название шаблона")
        .text(dto?.name ?: "Шаблон Telegram")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    private val text = TextareaInput()
        .text(dto?.body ?: "")
        .withLabel("HTML текст шаблона")
        .appendTo(layout)

    private fun build() = Template(
        id = if (new || dto == null) Random.nextUuid() else dto.id,
        name = name.text,
        type = TemplateType.TELEGRAM,
        subject = null,
        body = text.content.text,
    )

    private var result: Template? = null

    override fun onEscapeClick() {
        super.onEscapeClick()
        result = null
        close()
    }

//    private val preview = BaseButton("Предосмотр")
//        .appendTo(body.buttonsLayout, grow = 0, shrink = 1)
//        .before(body.cancelBtn)

    init {
        dom.style.apply {
            width = "70%"
            height = 600.px
        }
        MultiValidator(name, text.content)
//            .connectWith(preview)
            .connectWith(body.okBtn)

//        preview.onClick {
//            val firstName = "Сергей"
//            val secondName = "Павлович"
//            val lastName = "Королёв"
//
//            val html = GatewayUtils.replaceText(
//                body = text.content.text,
//                message = "Тело сообщения",
//                firstName = firstName,
//                middleName = secondName,
//                lastName = lastName,
//            )
//
//            EmailPreviewDialog.show(
//                subject = "",
//                body = html,
//            )
//        }
        body.ok {
            result = build()
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    companion object {
        suspend fun show(dto: Template?, new: Boolean): Template? {
            val d = TelegramTemplateDialog(dto = dto, new = new)
            d.show()
            return d.result
        }
    }
}
