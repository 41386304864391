package org.tlsys.goods.dialogs

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.goods.Charsets
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import org.w3c.files.File
import org.w3c.files.get
import pw.binom.web.layout.*
import kotlin.js.Promise

class GoodsImportDialog private constructor(val allowExt: Array<String>) : TitleDialog("Импорт") {

    data class ImportConfig(
        val files: List<File>,
        val charset: String,
        val codeNormalization: Boolean,
    )

    private val body = SaveCancelBody().appendTo(super.layout, grow = 1, shrink = 1)
    private var cancelled = false

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val charsetSelector = Combobox2(
        items = Charsets.values().map { it.title },
        select = 0,
    )
        .withLabel("Кодировка")
        .appendTo(layout, grow = 0, shrink = 0)
    private val codeNormalization = Checkbox("Нормализация данных")
        .appendTo(layout, grow = 0, shrink = 0)

    // TODO Добавить в описание поддерживаемые типы файлов
    private val uploadPlace = FileUploadPlace(null) {
        for (i in 0 until it.files.length) {
            val file = it.files.get(i) ?: continue
            val name = file.name

            if (!allowExt.any { name.lowercase().endsWith(".${it.lowercase()}") }) {
                return@FileUploadPlace "Импорт из файла ${file.name} не поддерживается"
            }
        }
        null
    }.appendTo(layout, grow = 1, shrink = 1)

    init {
        dom.style.apply {
            width = 600.px
            height = 600.px
        }
        body.okBtn.onClick {
            cancelled = false
            close()
        }

        body.cancelBtn.onClick {
            cancelled = true
            close()
        }

        uploadPlace.addFileSelectEventListener {
            body.okBtn.enabled = uploadPlace.files.isNotEmpty()
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelled = true
        close()
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        fun show(vararg allowExt: String) = Promise<ImportConfig?> { d, c ->

            val dd = GoodsImportDialog(allowExt.unsafeCast<Array<String>>())

            layoutNavigator.show(dd) {
                console.info("WIndow closed")
                if (dd.cancelled) {
                    d(null)
                } else {
                    d(
                        ImportConfig(
                            files = dd.uploadPlace.files,
                            charset = Charsets.values()[dd.charsetSelector.content.select].code,
                            codeNormalization = dd.codeNormalization.checked,
                        ),
                    )
                }
            }
        }
    }
}
