package org.tlsys

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SMSConfig(
    @SerialName("v")
    val login: String,
    @SerialName("b")
    val password: String,

    @SerialName("max_length")
    val maxLength: Int,
    val mtc: MTC? = null,
) {
    @Serializable
    data class MTC(val clazz: Int? = null, val smsAddress: String)
}
