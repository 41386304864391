package org.tlsys.security

import kotlinx.browser.localStorage
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.GroupService
import org.tlsys.api.UsersService
import org.tlsys.await
import org.tlsys.cms.IntStorage
import org.tlsys.cms.onClick
import org.tlsys.json.jdto
import org.tlsys.settings.Settings
import org.tlsys.ui.*
import org.tlsys.ui.Page
import org.w3c.dom.HTMLDivElement
import pw.binom.web.Component
import pw.binom.web.layout.*

/**
 * Created by Ермаков Игорь Александрович (email: igor.yermakov94@yandex.ru) on 11.08.2017.
 */
class PermissionPage : AbstractPage() {
    override suspend fun getTitle(): String = TITLE

    companion object {
        val TITLE = "Права доступа"
        val URI = "permissions"
        var currentTab = 0

        fun regPluginPage() {
            Settings.reg(uri = URI, name = TITLE, creator = {
                PermissionPage()
            })
        }
    }

    val actionPanel = ActionPanel().appendTo(contentLayout, grow = 0, shrink = 0)

    val tabs = Tabs().apply {
        dom.onClick {
            currentTab = active
        }
    }.appendTo(actionPanel, FlexLayout.FlexItem.AlignSelf.Normal)
    val view = ComponentView().appendTo(contentLayout, grow = 1, shrink = 1)

    var firstRun = true

    private val usersService by Services.byClass(UsersService::class)
    private val groupService by Services.byClass(GroupService::class)

    var ACTIVE_TAB by IntStorage(storage = localStorage, name = "security_active_tab")

    private val userFragment = UserFragment()
    private val groupFragment = GroupFragment()

    init {
        actionPanel.addSpace()
        val createClient = actionPanel.iconBtn("add")

        addTab(fragment = userFragment, name = UserFragment.NAME, index = 0)
        addTab(fragment = groupFragment, name = GroupFragment.NAME, index = 1)

        createClient.onClick {
            if (view.view == userFragment) {
                val d = AddUserDialog.show(null) ?: return@onClick
                val user = usersService.createUser2(
                    login = d.login.text.jdto,
                    password = d.pass.text.jdto,
                    name = d.name.text.jdto,
                    phone = d.phone.text.jdto,
                    groups = d.groups.await().selectedItems.map { it.jdto }.jdto(),
                ).await()
//                    d.groups.await().selectedItems.map {
//                        groupService.addUserToGroup(user.id.jdto, it.jdto).promise
//                    }.join().await()
                userFragment.addUser(user)
            }

            if (view.view == groupFragment) {
                val d = AddGroupDialog.show(null) ?: return@onClick
                val group = groupService.createGroup(d.name.text.jdto).await()
                groupFragment.add(group)
            }
        }
    }

    override suspend fun onStart() {
        super.onStart()
        if (firstRun) {
            tabs.active = ACTIVE_TAB ?: 0
            firstRun = false
        } else {
            tabs.active = currentTab
        }
    }

    override suspend fun next(path: String): Page? {
        if (path.startsWith("g")) {
            val groupId = path.substring(1).toLongOrNull() ?: return super.next(path)
            return EditGroupPage(groupId)
        }
        return super.next(path)
    }

    private fun addTab(fragment: Component<HTMLDivElement>, name: String, index: Int) {
        tabs.add(name) {
            ACTIVE_TAB = index
            view.set2(fragment)
        }
    }
}
