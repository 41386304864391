package org.tlsys.goods.pages

import kotlinx.browser.window
import org.tlsys.admin.core.Services
import org.tlsys.admin.core.join
import org.tlsys.admin.ui.*
import org.tlsys.api.ProcessService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.emptyAsyncIterator
import org.tlsys.goods.DTOGoodGroup
import org.tlsys.goods.api.GoodsService
import org.tlsys.goods.dialogs.GoodEditDialog
import org.tlsys.goods.dialogs.GoodsImportDialog
import org.tlsys.goods.services.classifiersIterator
import org.tlsys.goods.services.goodsIterator
import org.tlsys.goods.services.groupIterator
import org.tlsys.goods.upload
import org.tlsys.goods.views.GroupView
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.ui.Page
import org.w3c.xhr.FormData
import pw.binom.web.layout.appendTo

class GoodsPage(val groupId: String?) : AbstractPage() {
    override suspend fun getTitle(): String = group?.await()?.name ?: TITLE

    companion object {
        val URI = "goods"
        val TITLE = "Товары"
    }

    private var loaded: DTOGoodGroup? = null

    private val tooBar = ActionPanel()
        .appendTo(contentLayout, grow = 0, shrink = 0).apply {
            addSpace()
        }

    private val addBtn = Dropdown("Добавить", listOf("Товар", "Группа", "Импорт")).appendTo(tooBar)

    init {
        addBtn.eventClick.on {
            when (it) {
                0 -> async2 {
                    val result = GoodEditDialog.show(title = "Новый товар").await() ?: return@async2
                    val good = ProcessBlocker.block("Создание товара") {
                        goodsService.createGood(
                            code = result.code.jdto,
                            parent = groupId?.jdto,
                            name = result.name.jdto,
                            vendorCode = result.vendorCode?.jdto,
                        ).await()
                    }.await()
                    view.createGood(good)
                }

                1 -> async2 {
                    val result = GoodEditDialog.show(title = "Новая группа").await() ?: return@async2
                    val good = ProcessBlocker.block("Создание группы товаров") {
                        goodsService.createGoodGroup(
                            code = result.code.jdto,
                            parent = groupId?.jdto,
                            name = result.name.jdto,
                        ).await()
                    }.await()
                    view.createGroup(good)
                }

                2 -> async2 {
                    val files = GoodsImportDialog.show("txt", "xml", "spr").await()
                    console.info("files:", files)
                    files ?: return@async2

                    ProcessBlocker.block("Загрузка") {
                        val uploadUrl = goodsService.getUploadUrl().await().value

                        processService.getProcess(goodsService.prepareImport(groupId?.jdto).await()).await()!!
                            .join(1000).await()

                        val formData = FormData()
                        val fileIds = files.files.map {
                            val fileId = goodsService.startImport(it.name.jdto).await().value
                            formData.append(name = fileId.toString(), value = it, filename = it.name)
                            fileId to it
                        }
                        upload(
                            url = uploadUrl,
                            method = "POST",
                            data = formData,
                            process = {
                            },
                        ).await()
                        fileIds.forEachIndexed { index, it ->
                            val process = goodsService.doImportFile(
                                parentGroup = groupId?.jdto,
                                fileId = it.first.jdto,
                                charset = files.charset.jdto,
                                codeNormalization = files.codeNormalization.jdto,
                            ).await()
                            processService.getProcess(process).await()!!.join(1000).await()
                        }
                    }.await()
                    MessageDialog.showInfo("Импорт завершён")
                    resetView()
                }
            }
        }
    }

    private val goodsService by Services.byClass(GoodsService::class)
    private val processService by Services.byClass(ProcessService::class)

    private val group by lazy { if (groupId == null) null else goodsService.getGroup(groupId.jdto).promise }

    private fun showGroupNotFound() {
        console.info("Группа не найдена!")
    }

    override suspend fun onInit() {
        super.onInit()
        resetView()
    }

    override suspend fun next(pageName: String): Page =
        GoodsPage(pageName)

    private suspend fun resetView() {
        view.resetSource(
            groupIt = goodsService.groupIterator(parent = groupId, maxPart = 30, minForLoad = 20),
            goodIt = goodsService.goodsIterator(parent = groupId, maxPart = 30, minForLoad = 20),
            classifierIt = if (groupId == null) {
                goodsService.classifiersIterator(
                    maxPart = 30,
                    minForLoad = 20,
                )
            } else {
                emptyAsyncIterator()
            },
        )
    }

    val view = GroupView(
        groupIt = emptyAsyncIterator(),
        goodIt = emptyAsyncIterator(),
        classifierIt = emptyAsyncIterator(),
        rootUrl = window.location.hash,
        deleteGroup = { async2 { goodsService.removeGroup(it.id.jdto).await().value } },
        deleteGood = { async2 { goodsService.removeGood(it.id.jdto).await().value } },
        selectOption = null,
        allowRename = true,
        emptyText = "Группы и товары отсутствуют",
    ).appendTo(contentLayout, grow = 1, shrink = 1)
}

suspend fun Array<Byte>.partedAsync(partSize: Int, f: suspend (ByteArray) -> Unit) {
    var it = 0
    val fullPart = size / partSize

    if (fullPart > 0) {
        val buffer = ByteArray(partSize)
        for (j in 0 until fullPart) {
            for (i in 0 until partSize)
                buffer[i] = this[it++]
            f(buffer)
        }
    }

    val out = ByteArray(size - it)
    for (i in 0 until out.size) {
        out[i] = this[it++]
    }
    f(out)
}
