package org.tlsys.core.promotion.bonusByPayWithLevels

import org.tlsys.DateDuration
import org.tlsys.Percent
import org.tlsys.admin.BonusByPayedWithLevelsConfig
import org.tlsys.admin.BonusPercentConfig
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.core.promotion.BonusConfigEditor
import org.tlsys.px
import org.tlsys.toMoneyValue
import org.tlsys.ui.*
import org.tlsys.ui.DivLayout
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class LevelDialog(record: BonusByPayedWithLevelsConfig.Level?) : TitleDialog("Уровень") {
    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private var result: BonusByPayedWithLevelsConfig.Level? = null

    init {
        dom.style.width = 550.px
    }

    private val min = EditText(placeHolder = "Оборот")
        .textValidator(
            DoubleValidator.FORMAT and DoubleValidator.moreOrEquals2(
                0.0,
            ),
        )
        .text(record?.minSum?.asDouble?.toString() ?: "0")
        .appendTo(layout, grow = 1, shrink = 1)
    private val selfPanel = DivLayout().withLabel("Бонусы клиента")
        .appendTo(layout, grow = 0, shrink = 0)
    private val selfBonus = BonusConfigEditor.PercentEditor(canBeEmpty = false)
        .appendTo(selfPanel.element.layout, grow = 1, shrink = 1)
//    private val selfPercent = EditText(placeHolder = "Процент от чека")
//        .textValidator(
//            DoubleValidator.FORMAT and DoubleValidator.minOrEquals2(100.0) and DoubleValidator.moreOrEquals2(
//                0.0
//            )
//        )
//        .text(record?.self?.bonusPercent?.asDouble?.toString() ?: "10")
//        .appendTo(selfPanel.element.layout, grow = 1, shrink = 1)
//    private val selfStart = TimeCountSelector(placeHolder = "Активация", canBeEmpty = false)
//        .time(record?.self?.start ?: DateDuration.ZERO)
//        .appendTo(selfPanel.element.layout, grow = 1, shrink = 1)
//    private val selfEnd = TimeCountSelector(placeHolder = "Сгорание", canBeEmpty = true)
//        .time(record?.self?.end)
//        .appendTo(selfPanel.element.layout, grow = 1, shrink = 1)

    private val partnerEnabled = Checkbox("Начеслять бонусы партнеру")
        .appendTo(layout, grow = 1, shrink = 1)
    private val partnerPanel = DivLayout().withLabel("Бонусы партнера")
        .appendTo(layout, grow = 1, shrink = 1)

    private val partnerBonus = BonusConfigEditor.PercentEditor(canBeEmpty = false)
        .appendTo(partnerPanel.element.layout, grow = 1, shrink = 1)

//    private val partnerPercent = EditText(placeHolder = "Процент от чека")
//        .textValidator(
//            DoubleValidator.FORMAT and DoubleValidator.minOrEquals2(100.0) and DoubleValidator.moreOrEquals2(
//                0.0
//            )
//        )
//        .text(record?.partner?.bonusPercent?.asDouble?.toString() ?: "0")
//        .appendTo(partnerPanel.element.layout, grow = 1, shrink = 1)
//
//    private val partnerStart = TimeCountSelector(placeHolder = "Активация", canBeEmpty = false)
//        .time(record?.partner?.start ?: DateDuration.ZERO)
//        .appendTo(partnerPanel.element.layout, grow = 1, shrink = 1)
//
//    private val partnerEnd = TimeCountSelector(placeHolder = "Сгорание", canBeEmpty = true)
//        .time(record?.partner?.end)
//        .appendTo(partnerPanel.element.layout, grow = 1, shrink = 1)

    val validator = MultiValidator(min, selfBonus, partnerBonus)

    override fun onEscapeClick() {
        result = null
        close()
    }

    init {
        partnerBonus.value = record?.partner?.bonusPercent
        partnerBonus.start = record?.partner?.start ?: DateDuration.ZERO
        partnerBonus.end = record?.partner?.end
        selfBonus.value = record?.self?.bonusPercent
        selfBonus.start = record?.self?.start ?: DateDuration.ZERO
        selfBonus.end = record?.self?.end
        partnerEnabled.EVENT_CHANGED.on {
            partnerBonus.enabled = partnerEnabled.checked
            partnerBonus.canBeEmpty = !partnerEnabled.checked
        }
        partnerEnabled.checked = record?.usePartnerBonus ?: false
        partnerBonus.canBeEmpty = !partnerEnabled.checked
        validator.onValidChange { body.okBtn.enabled = it }
        body.ok {
            result = BonusByPayedWithLevelsConfig.Level(
                minSum = min.text.toFloat().toMoneyValue,
                self = BonusPercentConfig(
                    bonusPercent = selfBonus.value!!,
                    start = selfBonus.start,
                    end = selfBonus.end,
                ),
                partner = BonusPercentConfig(
                    bonusPercent = partnerBonus.value ?: Percent.ZERO,
                    start = partnerBonus.start,
                    end = partnerBonus.end,
                ),
                usePartnerBonus = partnerEnabled.checked,
            )
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    companion object {
        suspend fun show(record: BonusByPayedWithLevelsConfig.Level?): BonusByPayedWithLevelsConfig.Level? {
            val d = LevelDialog(record)
            d.show()
            return d.result
        }
    }
}
