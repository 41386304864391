package org.tlsys.admin.ui

import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.ui.*
import org.tlsys.api.ShopsService
import org.tlsys.api.getShopsIterator
import org.tlsys.api.shopTerminalIterator
import org.tlsys.core.filter
import org.tlsys.core.filterNotNull
import org.tlsys.core.map
import org.tlsys.dto.DTOShop
import pw.binom.web.layout.*
import pw.binom.web.layout.*
import org.w3c.dom.HTMLDivElement

class ShopsSelectorDialog(hide: List<Long>) : TitleDialog("Магазины") {
    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    init {
        dom.style.maxHeight = "80%"
        dom.style.maxWidth = "80%"
    }

    private val shopsService by Services.byClass(ShopsService::class)
    private var count = 0

    private inner class ShopItem(val dto: DTOShop) : DivComponentWithLayout(alignItems = FlexLayout.AlignItems.Center) {
        val checked = Checkbox((dto.title.takeIf { it.isNotBlank() } ?: dto.address)).appendTo(layout)

        init {
            checked.EVENT_CHANGED.on {
                if (checked.checked) {
                    count++
                } else {
                    count--
                }
                body.okBtn.enabled = count > 0
            }
            addClass(Styles.LIST_ITEM_STYLE)
        }
    }

    private val iterator = shopsService.getShopsIterator(minForLoad = 20, maxPart = 30)
        .filter { it.id !in hide }
        .map {
            ShopItem(it)
        }

    private val list = LoadableListView(iterator).appendTo(layout)

    private var canceled = false

    init {
        body.cancel {
            canceled = true
            close()
        }

        body.ok {
            canceled = false
            close()
        }

        body.okBtn.enabled = false
    }

    companion object {
        suspend fun show(hide: List<Long>): List<DTOShop>? {
            val d = ShopsSelectorDialog(hide)
            d.show()
            if (d.canceled)
                return null
            return d.list.items.filter { it.checked.checked }.map { it.dto }
        }
    }
}