package org.tlsys.templates

import org.tlsys.Template
import org.tlsys.TemplateType
import org.tlsys.admin.SettingItem
import org.tlsys.admin.ui.*
import org.tlsys.api.TemplateService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.cms.click
import org.tlsys.ui.DivLayout
import org.tlsys.ui.Page
import pw.binom.uuid.nextUuid
import pw.binom.web.ScrollController
import pw.binom.web.layout.*
import kotlin.random.Random

private suspend fun showTemplateDialog(new: Boolean, template: Template?, type: TemplateType): Template? =
    when (type) {
        TemplateType.EMAIL -> EmailTemplateDialog.show(dto = template, new = new)
        TemplateType.SMS -> SMSTemplateDialog.show(dto = template, new = new)
        TemplateType.TELEGRAM -> TelegramTemplateDialog.show(dto = template, new = new)
        else -> TODO()
    }
//    when (gatewayProvider) {
//        GatewayProvider.SMS_RU -> SMSRuGatewayDialog.show(new = new, gateway = gateway)
//        GatewayProvider.SMSC -> SMSCGatewayDialog.show(new = new, gateway = gateway)
//        GatewayProvider.INTERNAL_SMTP -> InternalSMTPDialog.show(new = new, gateway = gateway)
//        GatewayProvider.INTERNAL_TELEGRAM -> TelegramGatewayDialog.show(new = new, gateway = gateway)
//    }

class TemplatesPage : AbstractPage() {

    object TemplatesSettingItem : SettingItem {
        override val uri: String
            get() = URI
        override val title: String
            get() = TITLE

        override suspend fun getPage(): Page = TemplatesPage()
    }

    companion object {
        const val TITLE = "Шаблоны сообщений"
        const val URI = "templates"
    }

    override suspend fun getTitle(): String = TITLE

    private val tooBar = ActionPanel()
        .appendTo(contentLayout, grow = 0, shrink = 0).apply {
            addSpace()
        }
    private val div = DivLayout(direction = FlexLayout.Direction.Column).appendTo(contentLayout, grow = 1, shrink = 1)
    private val table = MaterialTable().appendTo(div.layout, grow = 0, shrink = 0)

    private val scrollController = ScrollController(div.dom)
    private val addDropdown = Dropdown("Добавить", TemplateType.values().map { it.title }).appendTo(tooBar)

    init {
        val head = MaterialTable.Header().appendTo(table)
        MaterialTable.TextColumn("Название").appendTo(head)
        MaterialTable.TextColumn("Тип").appendTo(head).widthPx(130)
        MaterialTable.TextColumn("Действия").appendTo(head).widthPx(1)

        addDropdown.eventClick.on { selected ->
            async2 {
                val gateway = showTemplateDialog(
                    new = true,
                    type = TemplateType.values()[selected],
                    template = null,
                ) ?: return@async2

                ProcessBlocker.block("Создание шлюза") {
                    TemplateService.create(gateway)
                }.await()

                val row = TemplateItem(gateway)
                row.appendTo(table)
                Effects.createAndBlankItem(row.dom)
            }
        }
    }

    override suspend fun onInit() {
        super.onInit()
        TemplateService.getList().forEach {
            TemplateItem(it).appendTo(table)
        }
        super.onInit()
    }

    inner class TemplateItem(var template: Template) : MaterialTable.Row() {
        val name = MaterialTable.TextColumn().appendTo(this)
        val type = MaterialTable.TextColumn().appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        init {
            actions.component.visibleOnHover(this)
            refresh()

            actions.component.iconBtn(MaterialIcons.CONTENT_COPY).click {
                val r = showTemplateDialog(
                    new = true,
                    template = template.copy(id = Random.nextUuid()),
                    type = template.type,
                ) ?: return@click

                ProcessBlocker.block("Обновление шлюза") {
                    TemplateService.create(r)
                }.await()
                val row = TemplateItem(r)
                row.appendTo(table)
                Effects.createAndBlankItem(row.dom)
            }

            actions.component.iconBtn(MaterialIcons.EDIT).click {
                val r = showTemplateDialog(
                    new = false,
                    template = template,
                    type = template.type,
                ) ?: return@click

                ProcessBlocker.block("Обновление шлюза") {
                    TemplateService.update(r)
                }.await()
                this.template = r
                refresh()
                Effects.blockRow(dom)
            }

            actions.component.iconBtn(MaterialIcons.DELETE).click {
                if (!YesNoDialog.show("Удалить шаблон ${template.name}?", width = 500).await()) {
                    return@click
                }
                TemplateService.delete(template.id)
                Effects.removeItem(dom)
                removeSelf()
            }
        }

        fun refresh() {
            name.text = template.name
            type.text = template.type.title
        }
    }
}
