package org.tlsys.gateway

import kotlinx.browser.localStorage
import org.tlsys.STORAGE_CONSTS
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.px
import org.tlsys.serialization.Gateway
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import org.w3c.dom.get
import org.w3c.dom.set
import pw.binom.web.layout.*

abstract class AbstractSmsGatewayTest(gateway: Gateway) : GatewayTestDialog(gateway) {
    protected val phone =
        EditText(placeHolder = "Телефон")
            .text(localStorage.get(STORAGE_CONSTS.LAST_TESTED_PHONE) ?: "")
            .textValidator(TextValidators.IS_PHONE)
            .appendTo(layout, grow = 0, shrink = 0)
    protected val message = TextareaInput()
        .text(localStorage.get(STORAGE_CONSTS.LAST_TESTED_TEXT_SMS) ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .withLabel("Тест сообщения")
        .appendTo(layout, grow = 1, shrink = 1)

    init {
        dom.style.width = 300.px
        phone.eventChange.on {
            if (phone.isValid) {
                localStorage.set(key = STORAGE_CONSTS.LAST_TESTED_PHONE, phone.text)
            }
        }

        message.content.eventChange.on {
            if (message.content.isValid) {
                localStorage.set(key = STORAGE_CONSTS.LAST_TESTED_TEXT_SMS, message.content.text)
            }
        }
    }
}
