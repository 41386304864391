package org.tlsys

import kotlinx.serialization.InternalSerializationApi
import kotlinx.serialization.Polymorphic
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.serializer
import kotlin.js.JsName

val dtoModule = SerializersModule {
//    polymorphic(O::class, O.serializer()) {
//        subclass(OSession::class, OSession.serializer())
//    }
    polymorphic(OSession::class, OSession.serializer())
    polymorphic(OVerifyResponse::class, OVerifyResponse.serializer())
    polymorphic(OVerify::class, OVerify.serializer())
}

@Polymorphic
@Serializable
abstract class O {
    companion object {
    }
}

@OptIn(InternalSerializationApi::class)
inline fun <reified T : O> T.toJson(): String = jsonO.encodeToString(T::class.serializer(), this)

@JsName("json_global_module")
val jsonO = run {
    try {
        Json {
            this.classDiscriminator = "@"
            this.serializersModule = dtoModule
        }
    } catch (e: Throwable) {
        e.printStackTrace()
        throw e
    }
}
