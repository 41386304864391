package org.tlsys

import org.tlsys.admin.GlobalMenuItem
import org.tlsys.admin.GlobalPage
import org.tlsys.admin.core.PageNavigation
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.AbstractPage
import org.tlsys.admin.ui.Span
import org.tlsys.api.DEFAULT_SESSION
import org.tlsys.cms.Extensions
import org.tlsys.cms.onClick
import org.tlsys.members.MembersPage
import org.tlsys.settings.Settings
import org.tlsys.ui.Page

class DefaultPage : AbstractPage() {
    override suspend fun getTitle(): String = "Главная страница"

    companion object {

        internal val pages = HashMap<String, (AbstractPage) -> AbstractPage>()

        fun reg(uid: String, creator: (AbstractPage) -> AbstractPage) {
            pages[uid] = creator
        }

        fun unreg(uid: String) {
            pages.remove(uid)
        }
    }

    private val navigation by Services.byClass(PageNavigation::class)

    val text = Span("").apply {
        content.appendChild(dom)
        dom.onClick {
            navigation.go("/search")
        }
    }

    /*
    init {
        contentLayout.add(ToolsPlane().dom)
    }
    */

    private val globalMenuItems by Services.listByClass(GlobalMenuItem::class)
    private val globalPages by Services.listByClass(GlobalPage::class)

    override suspend fun next(path: String): Page? {
        if (path == "members") {
            return MembersPage()
        }

        if (DEFAULT_SESSION!!.isAllow(OPermission.EDIT_SETTINGS) && path == Settings.URI) {
            return Settings.SettingsPage()
        }

        if (path == org.tlsys.cards.CardsPage.URI) {
            return org.tlsys.cards.CardsPage()
        }
        if (path == org.tlsys.shops.ShopsPage.URI) {
            return org.tlsys.shops.ShopsPage()
        }

        val custom = pages[path]
        if (custom !== null) {
            return custom(this)
        }

        val ex = Extensions.globalMenu.elements.find { it.value.uri == path }
        if (ex != null) {
            return ex.value.page(this)
        }

        val ex2 = globalMenuItems.find { it.uri == path }
        if (ex2 != null) {
            return ex2.page(this)
        }

        val ex4 = globalPages.find { it.uri == path }
        if (ex4 != null) {
            return ex4.page
        }

        return super.next(path)
    }
}
