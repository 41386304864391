package org.tlsys.members

import kotlinx.browser.document
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.CardsService
import org.tlsys.api.DEFAULT_SESSION
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.cards.CardCodeDialog
import org.tlsys.cms.LIST_ITEM_STYLE
import org.tlsys.cms.SIMPLE_TEXT
import org.tlsys.dto.CardDTO
import org.tlsys.dto.CardExistDTO
import org.tlsys.dto.auth.CorePermission
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.permission
import org.tlsys.px
import org.tlsys.ui.*
import org.tlsys.ui.DivLayout
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class CardOfAccountFragment(val memberId: Long) : AbstractFragment<HTMLDivElement>(document.createDiv()) {
    val layout = FlexLayout(dom, direction = FlexLayout.Direction.Column)
    private val cardsService by Services.byClass(CardsService::class)
    val allowEdit by permission(CorePermission.EDIT_MEMBERS_CARDS)

    private val table = MaterialTable().appendTo(layout, grow = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Статус").appendTo(header).widthPx(1)
//            MaterialTable.TextColumn("Статус").appendTo(header).widthPx(1)
            MaterialTable.TextColumn("Карта").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    init {
        if (allowEdit) {
            val addBtn = AddButton().appendTo(layout, grow = 0, shrink = 0)
            addBtn.onClick {
                CardCodeDialog.show(code = null, enabled = true) { code, enabled ->
                    try {
                        val card = cardsService.createCard(member = memberId.jdto, code = code.jdto).await()
                        if (!enabled) {
                            cardsService.setEnabled(cardId = card.id.jdto, enable = false.jdto)
                        }
                        val cardItem = Item(card)
                        cardItem.appendTo(table)
                        Effects.createItem(cardItem.dom)
                        Effects.blockRow(cardItem.dom)
                        true
                    } catch (e: CardExistDTO) {
                        MessageDialog.show(
                            title = "Ошибка",
                            message = "Карта \"$code\" уже зарегистрирована",
                            width = 300,
                            height = 130,
                        )
                        false
                    } catch (e: dynamic) {
                        console.info("ERROR:")
                        console.dir(e)
                        throw e
                    }
                }
            }
        }
    }

    override suspend fun onInit() {
        super.onInit()
        console.info("Card Fragment INIT!")
        cardsService.getCardsOfMember(memberId.jdto).await().forEach {
            Item(it).appendTo(table)
        }
    }

    private inner class Item(var card: CardDTO) : MaterialTable.Row() {
        inner class StatusColumn : MaterialTable.Column() {
            val layout = DivLayout().appendTo(dom)
            val activeExternalSync = MaterialIcon("cloud").appendTo(layout.layout)
            val disabled = MaterialIcon("block").appendTo(layout.layout)
            val slaveExternalSync = MaterialIcon("swap_horiz").appendTo(layout.layout)

            init {
                layout.addClass(Styles.ICONS_H)
                layout.addClass(Styles.LEFT_PADDING)
                refresh()
            }

            fun refresh() {
                val osmiCardEnabled = DEFAULT_SESSION!!.company.osmiCardEnabled
                val cardPrEnabled = DEFAULT_SESSION!!.company.cardPrEnabled
                activeExternalSync.visible =
                    (osmiCardEnabled && card.osmiCardActivate != null) || (cardPrEnabled && card.cardprDate != null)
                slaveExternalSync.visible =
                    !activeExternalSync.visible && osmiCardEnabled && card.osmiCardReg != null && card.osmiCardActivate == null
                disabled.visible = !card.enabled
            }
        }

        val status = StatusColumn().appendTo(this)
//        val activeColumn = MaterialTable.ComponentColumn(Checkbox())
//            .appendTo(this)

        private val code = MaterialTable.ComponentColumn(Span().addClass(SIMPLE_TEXT))
            .appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        init {
            actions.component.visibleOnHover(this)
            if (allowEdit) {
                actions.component.iconBtn(MaterialIcons.SETTINGS).onClick {
                    async2 {
                        CardCodeDialog.show(code = card.code, enabled = card.enabled) { code, enabled ->
                            try {
                                if (code != card.code) {
                                    card = cardsService.editCode(cardId = card.id.jdto, code = code.jdto).await()
                                }
                                if (enabled != card.enabled) {
                                    card = cardsService.setEnabled(cardId = card.id.jdto, enable = enabled.jdto).await()
                                }
                                refresh()
                                Effects.blockRow(this@Item.dom)
                                true
                            } catch (e: CardExistDTO) {
                                MessageDialog.show(
                                    title = "Ошибка",
                                    message = "Карта \"$code\" уже зарегистрирована",
                                    width = 300,
                                    height = 130,
                                )
                                false
                            }
                        }
                    }
                }

                actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                    async2 {
                        if (!YesNoDialog.show("Удалить карту ${card.code}?", 400).await()) {
                            return@async2
                        }
                        ProcessBlocker.block("Удаление карты") {
                            cardsService.deleteCard(memberId = memberId.jdto, cardId = card.id.jdto)
                            Effects.removeItem(this.dom).await()
                            removeSelf()
                        }
                    }
                }
            }
        }

        private fun refresh() {
            status.refresh()
//            activeColumn.component.setCheckedForce(card.enabled)
            code.component.text = card.code
        }

        init {
            addClass(LIST_ITEM_STYLE)
            dom.style.padding = 10.px
            refresh()
        }
    }
}
