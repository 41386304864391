package org.tlsys.core.promotion.discountByPayWithLevels

import org.tlsys.*
import org.tlsys.admin.DiscountByPayedWithLevelsConfig
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.core.promotion.BonusConfigEditor
import org.tlsys.ui.*
import org.tlsys.ui.DivLayout
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class LevelDialog(record: DiscountByPayedWithLevelsConfig.Level?) : TitleDialog("Уровень") {
    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private var result: DiscountByPayedWithLevelsConfig.Level? = null

    init {
        dom.style.width = 550.px
    }

    private val min = EditText(placeHolder = "Оборот")
        .textValidator(
            DoubleValidator.FORMAT and DoubleValidator.moreOrEquals2(
                0.0,
            ),
        )
        .text(record?.minSum?.asDouble?.toString() ?: "0")
        .appendTo(layout, grow = 1, shrink = 1)
    private val selfPanel = DivLayout().withLabel("Скидка клиента")
        .appendTo(layout, grow = 0, shrink = 0)
    private val selfBonus = EditText().textValidator(
        TextValidators.NOT_BLANK
            and DoubleValidator.FORMAT
            and DoubleValidator.minOrEquals2(100.0) and DoubleValidator.moreOrEquals2(0.0),
    )
        .text(record?.selfPercent?.toString() ?: "0")
        .appendTo(selfPanel.element.layout, grow = 1, shrink = 1)

    private val partnerEnabled = Checkbox("Начислять бонусы партнеру")
        .appendTo(layout, grow = 1, shrink = 1)
    private val partnerPanel = DivLayout().withLabel("Бонусы партнера")
        .appendTo(layout, grow = 1, shrink = 1)

    private val partnerBonus = BonusConfigEditor.PercentEditor(canBeEmpty = false)
        .appendTo(partnerPanel.element.layout, grow = 1, shrink = 1)

    val validator = MultiValidator(min, selfBonus, partnerBonus)

    override fun onEscapeClick() {
        result = null
        close()
    }

    init {
        partnerBonus.value = record?.partner?.percent
        partnerBonus.start = record?.partner?.start ?: DateDuration.ZERO
        partnerBonus.end = record?.partner?.end
        partnerEnabled.EVENT_CHANGED.on {
            partnerBonus.enabled = partnerEnabled.checked
            partnerBonus.canBeEmpty = !partnerEnabled.checked
        }
        partnerEnabled.checked = record?.usePartnerBonus ?: false
        partnerBonus.canBeEmpty = !partnerEnabled.checked
        validator.onValidChange { body.okBtn.enabled = it }
        body.ok {
            result = DiscountByPayedWithLevelsConfig.Level(
                minSum = min.text.toFloat().toMoneyValue,
                selfPercent = selfBonus.text.toFloat().asPercent,
                partner = DiscountByPayedWithLevelsConfig.BonusConfig(
                    percent = partnerBonus.value ?: Percent.ZERO,
                    start = partnerBonus.start,
                    end = partnerBonus.end,
                ),
                usePartnerBonus = partnerEnabled.checked,
            )
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    companion object {
        suspend fun show(record: DiscountByPayedWithLevelsConfig.Level?): DiscountByPayedWithLevelsConfig.Level? {
            val d = LevelDialog(record)
            d.show()
            return d.result
        }
    }
}
