package org.tlsys.gateway

import kotlinx.browser.localStorage
import org.tlsys.STORAGE_CONSTS
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.api.GatewayService
import org.tlsys.await
import org.tlsys.px
import org.tlsys.serialization.EmailTestData
import org.tlsys.serialization.Gateway
import org.tlsys.serialization.GatewayTest
import org.tlsys.serialization.internalJson
import org.tlsys.toEmail
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import org.w3c.dom.get
import org.w3c.dom.set
import pw.binom.web.layout.*

class EmailGatewayTestDialog(gateway: Gateway) : GatewayTestDialog(gateway) {
    val destination = EditText(placeHolder = "Получатель")
        .text(localStorage.get(STORAGE_CONSTS.LAST_TESTED_EMAIL) ?: "")
        .textValidator(TextValidators.EMAIL)
        .appendTo(layout, grow = 0, shrink = 0)

    protected val message = TextareaInput()
        .text(localStorage.get(STORAGE_CONSTS.LAST_TESTED_TEXT_EMAIL) ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .withLabel("Cообщения")
        .appendTo(layout, grow = 1, shrink = 1)

    override suspend fun test() {
        val testData = EmailTestData(
            text = message.content.text,
            destination = destination.text.toEmail(),
        )
        val testDataText = internalJson.encodeToString(EmailTestData.serializer(), testData)
        val request = GatewayTest(
            gateway = gateway,
            testData = testDataText,
            firstName = firstName.text,
            middleName = patronymic.text,
            lastName = surname.text,
        )
        val response = ProcessBlocker.block("Отправка письма") {
            GatewayService.test(request)
        }.await()
        showResult(response)
    }

    init {
        dom.style.height = 600.px
        destination.eventChange.on {
            if (destination.isValid) {
                localStorage.set(key = STORAGE_CONSTS.LAST_TESTED_EMAIL, destination.text)
            }
        }

        message.content.eventChange.on {
            if (message.content.isValid) {
                localStorage.set(key = STORAGE_CONSTS.LAST_TESTED_TEXT_EMAIL, message.content.text)
            }
        }
    }

    companion object {
        suspend fun show(gateway: Gateway) =
            EmailGatewayTestDialog(gateway).show()
    }
}
