package org.tlsys.admin.ui

import org.tlsys.*
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.events.EventElementValue
import org.tlsys.ui.*
import pw.binom.web.layout.*
import org.tlsys.css.CSS
import org.tlsys.css.animate
import org.tlsys.ui.createSpan
import org.tlsys.ui.on
import org.w3c.dom.HTMLSpanElement
import kotlinx.browser.document
import pw.binom.web.AbstractComponent
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

private class Selector(items: List<String>, selected: Int, width: Double?) : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    val eventSelect = EventElementValue<Int>()

    companion object {
        val ACTIVE_ITEM = CSS.genName()
        val ITEM_STYLE = CSS.style {
            lineHeight = 24.px
            cursor = "pointer"
            color = "rgba(0,0,0,0.87)"
            boxSizing = "border-box"
            color = "#4C4C4C"
            padding = "8px 20px"
            fontSize = 13.px
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontWeight = "500"
            display = "block"

            ".$ACTIVE_ITEM" then {
                backgroundColor = "#e1e1e1"
            }
            ":not(.$ACTIVE_ITEM):hover" then {
                backgroundColor = "#eee"
            }
        }.name

        val STYLE = CSS.style {
            backgroundColor = "#fff"
            position = "fixed"
            boxShadow = "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)"
            overflow = "hidden"
        }.name
    }

    private inner class Item(text: String, val index: Int) : AbstractComponent<HTMLSpanElement>() {
        override val dom: HTMLSpanElement = document.createSpan()

        init {
            dom.innerText = text
            dom.addClass(ITEM_STYLE)
            dom.on("click") {
                eventSelect.dispatch(index)
            }
        }
    }

    fun setPos(x: Double, y: Double) {
        dom.style.apply {
            left = x.px
            top = y.px
        }
    }

    val width
        get() = dom.getBoundingClientRect().width

    init {
        val elements = items.mapIndexed { index, s ->
            Item(s, index)
        }
        elements.forEach {
            it.appendTo(layout, grow = 0, shrink = 0)
        }
        dom.addClass(STYLE)
        dom.style.apply {
            if (width != null)
                this.width = width.px
        }
        if (selected >= 0 && selected <= elements.size - 1)
            elements[selected].dom.addClass(ACTIVE_ITEM)
    }
}


class Selector2 private constructor() {
    private var bg: FullBackground? = null

    private val selected = EventElementValue<Int>()

    companion object {
        suspend fun select(items: List<String>, x: Double, y: Double, width: Double? = null, selected: Int = -1): Int? {
            val selector = Selector2()
            selector.open(items, selected, x, y, width)
            return suspendCoroutine<Int?> {
                selector.selected.on { index ->
                    selector.close()
                    it.resume(index.takeIf { it >= 0 })
                }
            }
        }
    }

    private suspend fun open(items: List<String>, selected: Int, x: Double, y: Double, width: Double?) {
        if (bg != null)
            return
        bg = FullBackground()
        document.body!!.appendChild(bg!!.dom)
        val s = Selector(items = items, selected = selected, width = width)
        bg!!.dom.appendChild(s.dom)

        s.setPos(0.0, 0.0)
        val maxWidth = s.width
        val bgWidth = bg!!.dom.getBoundingClientRect().width
        if (maxWidth + x > bgWidth-5) {
            console.info("Over Width")
            s.setPos(bgWidth - maxWidth-5, y)
        } else {
            console.info("Normal Width")
            s.setPos(x, y)
        }
        console.info("maxWidth=$maxWidth  bgWidth=$bgWidth   maxWidth + x = ${maxWidth + x}")

        bg!!.eventClick.on {
            this.selected.dispatch(-1)
        }
        s.eventSelect.on {
            this.selected.dispatch(it)
        }


        val selectHight = s.dom.getBoundingClientRect().height
        s.dom.animate {
            duration = 200
            function = "ease-in-out"
            0{
                height = 0.px
                opacity = 0.0
            }
            100 {
                height = selectHight.px
                opacity = 1.0
            }
        }.start().await()
    }

    private fun close() {
        val bg = bg ?: return
        this.bg = null
        bg.dom.animate {
            duration = 200
            function = "ease-in-out"
            0 {
                opacity = 1.0
            }

            100 {
                opacity = 0.0
            }

            onEnd {
                bg.dom.remove()
            }
        }.start()
    }
}

class FullBackground : DivComponent() {
    val eventClick = EventElement()

    init {
        dom.on("click") {
            if (it.target === dom)
                eventClick.dispatch()
        }
        dom.style.apply {
            left = "0px"
            right = "0px"
            top = "0px"
            bottom = "0px"
            position = "fixed"
            background = "transparent"
        }
    }
}