package org.tlsys.admin.ui

import org.tlsys.admin.core.Services
import org.tlsys.api.ShopsService
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.dto.DTOShop
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.web.layout.*

private class Shop(val tag: DTOShop, readOnly: Boolean) : PanelItem<DTOShop>(tag, readOnly) {

    init {
        setText(tag.title)
    }
}

open class ShopsPanel(val readOnly: Boolean) :
    DivComponentWithLayout(direction = FlexLayout.Direction.Row, alignItems = FlexLayout.AlignItems.Center) {
    private val _list = ArrayList<Shop>()
    val list
        get() = _list.map { it.tag }
    private val shopsService by Services.byClass(ShopsService::class)
    suspend open fun addTag(tags: List<DTOShop>) {
        tags.forEach {
            val t = Shop(it, readOnly).appendTo(layout, grow = 0, shrink = 0)
            t.eventClose.on {
                async2 {
                    removeTag(t)
                }
            }
            Effects.createInline(t.dom, 300)
            _list += t
        }
    }

    init {
        layout.apply {
            direction = FlexLayout.Direction.Row
            wrap = FlexLayout.Wrap.Enable
        }

        if (!readOnly) {
            val addBtn = AddTagBtn().appendTo(layout, grow = 0, shrink = 0)
            addBtn.dom.style.order = "1"

            addBtn.eventClick.on {
                async2 {
                    val ids = ShopsSelectorDialog.show(hide = this._list.map { it.tag.id })
                        ?: return@async2
                    addTag(ids)
                }
            }
        }
    }

    private suspend fun removeTag(tag: Shop) {
        _list -= tag
        async2 {
            Effects.removeInline(tag.dom, 300).await()
            tag.dom.remove()
        }
    }

    fun addTag(tag: DTOShop) {
        val t = Shop(tag, readOnly).appendTo(layout, grow = 0, shrink = 0)
        t.eventClose.on {
            async2 {
                removeTag(t)
            }
        }
        _list += t
    }
}