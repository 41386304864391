package org.tlsys.admin.serialization

import kotlinx.serialization.Serializable

abstract class RemoteException : RuntimeException(), R {
    @Serializable
    data class ServerException(val msg: String) : RemoteException() {
        override val message: String?
            get() = msg
    }
}