package org.tlsys.core.promotion.discountByTagsPromotion

import org.tlsys.*
import org.tlsys.admin.DiscountByTagsConfig
import org.tlsys.admin.PercentWithDate
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.core.promotion.BonusConfigEditor
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.tlsys.ui.DivLayout
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class RecordDialog(val record: DiscountByTagsConfig.Record?) : TitleDialog("Запись") {
    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private var result: DiscountByTagsConfig.Record? = null
    private val memberTagService by Services.byClass(MemberTagService::class)

    init {
        dom.style.width = 550.px
    }

    private val actionLayout = DivLayout(direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)

    private val selfBonus = EditText(placeHolder = "Скидка клиента")
        .text(
            record?.discountPercent?.asFloat?.toString()
                ?: record?.discountAmount?.toString()
                ?: "0",
        )
        .appendTo(actionLayout.layout, grow = 1, shrink = 1)

    private val discountTypeGroup = RadioBox.Group()
    private val discountTypeLayout =
        DivLayout(direction = FlexLayout.Direction.Column).appendTo(actionLayout.layout, grow = 0, shrink = 0)
    private val percentType = RadioBox("Проценты").group(discountTypeGroup)
        .appendTo(discountTypeLayout.layout, grow = 0, shrink = 0)
        .checked(record?.discountPercent != null)
    private val amountType = RadioBox("Сумма").group(discountTypeGroup)
        .appendTo(discountTypeLayout.layout, grow = 0, shrink = 0)
        .checked(record?.discountPercent == null)

    val percentValidator =
        DoubleValidator.minOrEquals2(100.0) and DoubleValidator.moreOrEquals2(0.0) and DoubleValidator.forMaxAfterPoint(
            2,
        )
    val amountValidator = DoubleValidator.moreOrEquals2(0.0) and DoubleValidator.forMaxAfterPoint(2)

    private val actionValidator = Validator<String> {
        val t = (TextValidators.NOT_BLANK and DoubleValidator.FORMAT).valid(it)
        if (t.isNotValid) {
            return@Validator t
        }

        if (percentType.checked) {
            percentValidator.valid(it)
        } else {
            amountValidator.valid(it)
        }
    }

    init {
        selfBonus.textValidator(actionValidator)
        discountTypeGroup.eventChange.on(true) {
            selfBonus.forceRefreshValid()
        }
    }

    private val partnerEnabled = Checkbox("Начислять бонусы партнеру")
        .appendTo(layout, grow = 1, shrink = 1)
    private val partnerPanel = DivLayout().withLabel("Бонусы партнера")
        .appendTo(layout, grow = 1, shrink = 1)

    private val partnerBonus = BonusConfigEditor.PercentEditor(canBeEmpty = false)
        .values(
            value = record?.partnerBonus?.percent,
            start = record?.partnerBonus?.start ?: DateDuration.ZERO,
            end = record?.partnerBonus?.end,
        )
        .appendTo(partnerPanel.element.layout, grow = 1, shrink = 1)

    private val validator = MultiValidator(selfBonus, partnerBonus)

    override suspend fun onInit() {
        super.onInit()
        if (record != null) {
            memberTagService.getTagList(record.tags.map { it.jdto }.jdto()).await()
                .forEach {
                    tags.content.addTag(it)
                }
        }
    }

    private val tags = TagPanel(readOnly = false)
        .withLabel("Метки")
        .appendTo(layout, grow = 0, shrink = 0)

    override fun onEscapeClick() {
        result = null
        close()
    }

    init {
        partnerEnabled.EVENT_CHANGED.on {
            partnerBonus.enabled = partnerEnabled.checked
            partnerBonus.canBeEmpty = !partnerEnabled.checked
        }
        partnerEnabled.checked = record?.usePartnerBonus ?: false
        partnerBonus.canBeEmpty = !partnerEnabled.checked
        validator.onValidChange { body.okBtn.enabled = it }
        body.ok {
            result = DiscountByTagsConfig.Record(
                discountPercent = if (percentType.checked) selfBonus.text.toFloat().asPercent else null,
                discountAmount = if (amountType.checked) selfBonus.text.toFloat().toMoneyValue else null,
                partnerBonus = PercentWithDate(
                    percent = partnerBonus.value ?: Percent.ZERO,
                    start = partnerBonus.start,
                    end = partnerBonus.end,
                ),
                usePartnerBonus = partnerEnabled.checked,
                tags = tags.content.list.map { it.tag.id },
            )
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    companion object {
        suspend fun show(record: DiscountByTagsConfig.Record?): DiscountByTagsConfig.Record? {
            val d = RecordDialog(record)
            d.show()
            return d.result
        }
    }
}
