package org.tlsys.goods

import org.tlsys.json.*

class DTOGood(
    val id: String,
    val code: String,
    val name: String,
    val vendorCode: String?,
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<DTOGood> {
        override val type: String
            get() = "good"

        override fun read(node: JsonNode) =
            DTOGood(
                id = node["id"]!!.string(),
                name = node["name"]!!.string(),
                code = node["code"]!!.string(),
                vendorCode = node["vendorCode"]?.string(),
            )

        override fun write(obj: DTOGood): JsonNode =
            JsonNode.obj()
                .set("id", obj.id.json)
                .set("name", obj.name.json)
                .set("code", obj.code.json)
                .set("vendorCode", obj.vendorCode?.json)
    }
}
