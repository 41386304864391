package org.tlsys.frontol

import org.tlsys.admin.core.Services
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.frontol.api.ConfigService
import org.tlsys.frontol.dto.ProcessingConfig
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.on
import pw.binom.web.ScrollController
import pw.binom.web.layout.appendTo

class ConfigPage : AbstractPage() {
    companion object {
        const val URI = "config"
        const val TITLE = "Бонусная система"
    }

    override suspend fun getTitle(): String = TITLE

    private val settings = SettingsPlane().appendTo(contentLayout)
    private val scroll = ScrollController(settings.dom)
    private var prefixList = emptyList<String>()

    private fun refreshPrefixList() {
        prifixEdit.component.text =
            if (prefixList.isEmpty()) "нет префиксов" else prefixList.joinToString(separator = ", ")
    }

    val wcTitle = settings.add("Касса")
    val prifixEdit =
        settings.add(
            "Префиксы карт",
            Span("").apply {
                dom.classList.add(Styles.LINK)
                dom.on("click") {
                    async2 {
                        prefixList = PrifixEditDialog.show(prefixList) ?: return@async2
                        refreshPrefixList()
                        saveConfig()
                    }
                }
            },
        )
    val useGoodsArticle = settings.add("Использовать артикулы вместо кодов", BigCheckboxNoText())
    val showCard = settings.add("Отображать на кассе карты клиента", BigCheckboxNoText())
    val showPhone = settings.add("Отображать на кассе телефоны клиента", BigCheckboxNoText())
    val printInSlipLoginInfo = settings.add("Выводить в слип средство идентификации клиента", BigCheckboxNoText())
    val showFio = settings.add("Отображать на кассе ФИО клиента", BigCheckboxNoText())
    private val authTitle = settings.add("Идентификация")
    val enterByPhone = settings.add("Идентификация по номеру телефона", BigCheckboxNoText())
    val allowUnkownCard = settings.add("Разрешить неизвестные карты", BigCheckboxNoText())
    val smsCodeLength =
        settings.add(
            "Длина SMS кода",
            EditText().textValidator(
                DoubleValidator.FORMAT
                    and DoubleValidator.forMaxAfterPoint(0)
                    and DoubleValidator.minOrEquals2(10.0)
                    and DoubleValidator.moreOrEquals2(1.0),
            ),
        )

    val autoCreateTitle = settings.add("Автосоздание")
    val autoCreateCard = settings.add("Автосоздание бонусной карты", BigCheckboxNoText())
    val autoCreatePhone = settings.add("Автосоздание клиента по номеру телефона", BigCheckboxNoText())
    val verifyOnPhoneCreate = settings.add("SMS подтверждение при создании клиента по телефону", BigCheckboxNoText())

    val dropTitle = settings.add("Списание бонусов")
    val maxBonusPay =
        settings.add(
            "Максимальный процент оплаты чека бонусами",
            EditText().textValidator(
                DoubleValidator.FORMAT
                    and DoubleValidator.forMaxAfterPoint(2)
                    and DoubleValidator.moreOrEquals2(0.0)
                    and DoubleValidator.minOrEquals2(100.0),
            ),
        )
    val allowDropByPhone = settings.add("Списание бонусов по номеру телефона", BigCheckboxNoText())

    // val phoneDropSMS = settings.add("SMS подтверждение при списании бонусов с номера телефона", BigCheckboxNoText())
    val verifyOnPhonePay = settings.add("Проверочный код для оплаты через телефон", BigCheckboxNoText())
    val verifyOnPhoneAlways = settings.add("Всегда запрашивать код из SMS", BigCheckboxNoText())
    val smsCodeTemplate = settings.add("Текст SMS с кодом подтверждения", TemplateEditor())

    val autoActiveTitle = settings.add("Автоактивация")
    val autoActivatePhone = settings.add("Автоактивация телефона", BigCheckboxNoText())
    val autoActivateCard = settings.add("Автоактивация бонусной карты", BigCheckboxNoText())

//    val cashbackTitle = settings.add("Возврат чека")
//    val allowCashbackWithOutRefer = settings.add("Разрешить возврат бонусов при возврате чека без документа - основания", BigCheckboxNoText())
//    val bonus_cashback = settings.add("Процент возврата бонусов при возврате без чека основания", InputNumberPicker(canBeEmpty = false))

    private val configService by Services.byClass(ConfigService::class)
    private var config = ProcessingConfig()

    override suspend fun onInit() {
        super.onInit()

        config = configService.getConfig().await()
        smsCodeTemplate.component.knownTemplates = arrayOf("%КОД%", "%ФИО%", "%ИМЯ%", "%ФАМИЛИЯ%", "%ОТЧЕСТВО%")
        smsCodeTemplate.component.dom.style.width = 136.px
        smsCodeTemplate.component.addValidator(TextValidators.NOT_BLANK)

        useGoodsArticle.component.checked = config.useGoodsArticle.value
        showPhone.component.checked = config.showPhone.value
        showCard.component.checked = config.showCards.value
        showFio.component.checked = config.showFIO.value
        enterByPhone.component.checked = config.enterByPhone.value
        verifyOnPhonePay.component.checked = config.verifyOnPhonePay.value
        verifyOnPhoneAlways.component.checked = config.verifyOnPhoneAlways.value
        allowUnkownCard.component.checked = config.allowUnkownCard.value
        autoCreateCard.component.checked = config.autoCreateCard.value
        autoCreatePhone.component.checked = config.autoCreatePhone.value
        verifyOnPhoneCreate.component.checked = config.verifyOnPhoneCreate.value
        allowDropByPhone.component.checked = config.allowDropByPhone.value
        smsCodeTemplate.component.content = config.smsCodeTemplate.value
        autoActivatePhone.component.checked = config.autoActivatePhone.value
        autoActivateCard.component.checked = config.autoActivateCard.value
        smsCodeLength.component.text = config.smsCodeLength.value.toString()
        printInSlipLoginInfo.component.checked = config.printInSlipLoginInfo.value
        prefixList = config.cardPrefix.map { it.value }
        refreshPrefixList()
        maxBonusPay.component.text = (config.maxBonusPay.value / 100.0).toString()

        showFio.component.EVENT_CHANGED.on {
            saveConfig()
        }
        showPhone.component.EVENT_CHANGED.on {
            saveConfig()
        }
        useGoodsArticle.component.EVENT_CHANGED.on {
            saveConfig()
        }
        showCard.component.EVENT_CHANGED.on {
            saveConfig()
        }
        enterByPhone.component.EVENT_CHANGED.on {
            saveConfig()
        }
        verifyOnPhonePay.component.EVENT_CHANGED.on {
            saveConfig()
        }
        verifyOnPhoneAlways.component.EVENT_CHANGED.on {
            saveConfig()
        }
        allowUnkownCard.component.EVENT_CHANGED.on {
            saveConfig()
        }
        autoCreateCard.component.EVENT_CHANGED.on {
            saveConfig()
        }
        autoCreatePhone.component.EVENT_CHANGED.on {
            saveConfig()
        }
        verifyOnPhoneCreate.component.EVENT_CHANGED.on {
            saveConfig()
        }
        allowDropByPhone.component.EVENT_CHANGED.on {
            saveConfig()
        }
        smsCodeTemplate.component.EVENT_CHANGED.on {
            saveConfig()
        }
        autoActivatePhone.component.EVENT_CHANGED.on {
            saveConfig()
        }
        autoActivateCard.component.EVENT_CHANGED.on {
            saveConfig()
        }
        smsCodeLength.component.eventChange.on {
            saveConfig()
        }
        maxBonusPay.component.eventChange.on {
            saveConfig()
        }

        printInSlipLoginInfo.component.EVENT_CHANGED.on {
            saveConfig()
        }
    }

    private fun saveConfig() {
        if (maxBonusPay.component.valid) {
            config.maxBonusPay =
                (maxBonusPay.component.text.toDoubleOrNull()?.let { it * 100 }?.let { it.toLong() } ?: 100_00L).jdto
        }
        if (smsCodeLength.component.valid) {
            config.smsCodeLength = (smsCodeLength.component.text.toInt()).jdto
        }
        config.autoCreatePhone = autoCreatePhone.component.checked.jdto
        config.allowUnkownCard = allowUnkownCard.component.checked.jdto
        config.verifyOnPhonePay = verifyOnPhonePay.component.checked.jdto
        config.verifyOnPhoneAlways = verifyOnPhoneAlways.component.checked.jdto
        config.smsCodeTemplate = smsCodeTemplate.component.content.jdto
        config.autoActivatePhone = autoActivatePhone.component.checked.jdto
        config.allowDropByPhone = allowDropByPhone.component.checked.jdto
        config.enterByPhone = enterByPhone.component.checked.jdto
        config.verifyOnPhoneCreate = verifyOnPhoneCreate.component.checked.jdto
        config.autoActivateCard = autoActivateCard.component.checked.jdto
        config.cardPrefix = prefixList.map { it.jdto }.jdto()
        config.autoCreateCard = autoCreateCard.component.checked.jdto
        config.showCards = showCard.component.checked.jdto
        config.showFIO = showFio.component.checked.jdto
        config.showPhone = showPhone.component.checked.jdto
        config.printInSlipLoginInfo = printInSlipLoginInfo.component.checked.jdto
        config.useGoodsArticle = useGoodsArticle.component.checked.jdto
        configService.setConfig(config)
    }
}
