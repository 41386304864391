package org.tlsys.shops

import kotlinx.browser.document
import org.tlsys.*
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.DEFAULT_SESSION
import org.tlsys.api.ShopsService
import org.tlsys.api.getTerminalsIterator
import org.tlsys.cms.on
import org.tlsys.core.map
import org.tlsys.dto.DTOTerminal
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class TerminalsListFragmentForShop(
    val shopId: Long,
    val terminalClick: ((DTOTerminal) -> Unit)? = null,
    val allowGotoTerm: Boolean = false,
    val allowEdit: Boolean = false,
    val allowDelete: Boolean = false,
    val allowAdd: Boolean = false,
) : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    private val layout = FlexLayout(dom, direction = FlexLayout.Direction.Column)
    private val allowCreateTerminal by permission(OPermission.CREATE_TERMINAL)
    private val shopsService by Services.byClass(ShopsService::class)
    private val scroll = ScrollController(dom)
    private val table = MaterialTable().appendTo(layout, grow = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Название").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    val addBtn = AddButton()

    init {
        afterConstruct()
        if (allowAdd && allowCreateTerminal) {
            addBtn.appendTo(layout, grow = 0, shrink = 0)
            addBtn.onClick {
                var result = AddTerminalDialog.show(null) ?: return@onClick
                result = ProcessBlocker.block("Создание терминала") {
                    shopsService.createTerminal(title = result.name.jdto, parent = shopId.jdto).await()
                }.await()
                insertTerminal(result)
            }
        }
    }

    fun getFirstTerminal() =
        table.rows.getOrNull(0)?.let { it as Item }

    inner class Item(var terminal: DTOTerminal) : MaterialTable.Row() {
        //        val title = MaterialTable.LinkColumn(
//                text = terminal.name,
//                href = ""
//        ).appendTo(this)
        val title = MaterialTable.TextColumn(
            text = terminal.name,
        ).appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)
//        private val name = document.createLink()
//                .innerText(terminal.name)
//                .appendTo(layout, grow = 1, shrink = 1)
//                .addClass(Styles.SIMPLE_TEXT).addClass(LINK)

//        private val actionPanel = ActionPlace().appendTo(layout, grow = 0, shrink = 0)

        init {
            actions.component.visibleOnHover(this)
            if (allowEdit && DEFAULT_SESSION!!.isAllow(OPermission.EDIT_TERMINAL_INFO)) {
                actions.component.iconBtn(MaterialIcons.SETTINGS).onClick {
                    async2 {
                        val v = AddTerminalDialog.show(terminal) ?: return@async2

                        terminal = ProcessBlocker.block("Переименование терминала") {
                            shopsService.setTerminalName(terminal.id.jdto, v.name.jdto).await()
                        }.await()
                        title.text = terminal.name
                        Effects.blockRow(dom)
                    }
                }
            }
        }

        init {
            if (allowDelete && DEFAULT_SESSION!!.isAllow(OPermission.REMOVE_TERMINAL)) {
                actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                    async2 {
                        if (!YesNoDialog.show("Вы действительно хотите удалить терминал?", width = 250).await()) {
                            return@async2
                        }
                        ProcessBlocker.block("Удаление терминала") {
                            shopsService.setTerminalDeletedFlag(terminal.id.jdto, true.jdto).await()
                        }.await()
                        Effects.removeItem(dom).await()
                        removeSelf()
                    }
                }
            }
        }

        init {
            terminalClick?.let { terminalClick ->
                dom.on("click") {
                    terminalClick(terminal)
                }
            }

//            dom.style.cursor = "pointer"
            dom.style.height = 56.px
            dom.style.paddingLeft = 16.px
//            if (allowGotoTerm) {
//                title.href = "#/${ShopsPage.URI}/s${shopId}/t${terminal.id}"
//            }
        }
    }

    private val terminalIt = shopsService
        .getTerminalsIterator(shopId = shopId, maxPart = 30, minForLoad = 20)
        .map { Item(it) }

//    private val list = LoadableListView(terminalIt, "Терминалы отсутствуют.")
//            .appendTo(layout, grow = 1, shrink = 1)

    fun insertTerminal(terminal: DTOTerminal) {
        async2 {
            val item = Item(terminal).appendTo(table)
            Effects.createItem(item.dom).await()
            Effects.blockRow(item.dom)
        }
    }

    override suspend fun onStart() {
        super.onStart()
        layout.onStart()
    }

    override suspend fun onInit() {
        super.onInit()
        shopsService.getTerminals(
            shopId = shopId.jdto,
            offset = 0.jdto,
            max = (-1).jdto,
        ).await().forEach {
            Item(it).appendTo(table)
        }
    }

    override suspend fun onStop() {
        super.onStop()
        layout.onStop()
    }
}
