package org.tlsys.admin.form

value class ValidationResult(val raw: String?) {

    val isValid
        get() = raw == null
    val isNotValid
        get() = !isValid

    override fun toString(): String = if (isValid) "SUCCESS" else "Invalid: $raw"

    val message
        get() = raw

    companion object {
        fun invalid(message: String) = ValidationResult(message)
        fun invalid() = invalid("")
        fun valid() = ValidationResult(null)
    }
}

val ValidationResult?.isNullOrValid
    get() = this == null || this.isValid
