package org.tlsys.core.search

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.DEFAULT_SESSION
import org.tlsys.await
import org.tlsys.heightPX
import org.tlsys.tags.TagSelector
import org.tlsys.ui.*
import org.tlsys.widthPX
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class FilterDialog private constructor(
    tags: List<Long>,
    gender: Boolean?,
    cardPr: Boolean?,
) : TitleDialog(title = "Фильтры") {

    private val body = SaveCancelBody(okText = "ПРИМЕНИТЬ").appendTo(super.layout, grow = 1, shrink = 1)

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private var canceled = false
    private val withTags = TagSelector(tags)
        .withLabel("Метки")
        .appendTo(layout, grow = 1, shrink = 1, basis = 50)

    private val cardPrGroup = RadioBox.Group()
    private val cardPrMGroup = ElementGroup("CardPr").appendTo(layout, grow = 0, shrink = 0)
    private val cardPrInstalled = RadioBox("Установлен").appendTo(cardPrMGroup.layout).group(cardPrGroup)
    private val cardPrNotInstalled = RadioBox("Не установлен").appendTo(cardPrMGroup.layout).group(cardPrGroup)
    private val cardPrNotFiltered = RadioBox("Не фильтровать")
        .appendTo(cardPrMGroup.layout)
        .group(cardPrGroup)
        .checked()

    private val sexGroup = RadioBox.Group()
    private val sexMGroup = ElementGroup("Пол").appendTo(layout, grow = 0, shrink = 0)
    private val sexMale = RadioBox("Мужской").appendTo(sexMGroup.layout).group(sexGroup)
    private val sexFamale = RadioBox("Женский").appendTo(sexMGroup.layout).group(sexGroup)
    private val sexNotFiltered = RadioBox("Не фильтровать").appendTo(sexMGroup.layout).group(sexGroup)
        .checked()

    init {
        console.info("cardPr: $cardPr, gender: $gender")
        dom.style.apply {
            widthPX = 600f
            heightPX = 600f
        }
        body.okBtn.onClick {
            canceled = false
            close()
        }

        body.cancelBtn.onClick {
            canceled = true
            close()
        }

        when (gender) {
            true -> sexMale.checked()
            false -> sexFamale.checked()
            null -> {}
        }

        if (DEFAULT_SESSION!!.company.cardPrEnabled) {
            when (cardPr) {
                true -> cardPrInstalled.checked()
                false -> cardPrNotInstalled.checked()
                null -> {}
            }
        } else {
            cardPrMGroup.dom.remove()
        }
    }

    data class Filter(val selectedTags: List<Long>, val gender: Boolean?, val cardPr: Boolean?)

    companion object {
        private val viewLayout by Services.byClass(LayoutNavigator::class)
        suspend fun show(filter: Filter): Filter? {
            val dialog = viewLayout.show(
                FilterDialog(
                    tags = filter.selectedTags,
                    gender = filter.gender,
                    cardPr = filter.cardPr,
                ),
            ).await()
            if (dialog.canceled) {
                return null
            }
            return Filter(
                selectedTags = dialog.withTags.element.selectedItems,
                gender = when {
                    dialog.sexMale.checked -> true
                    dialog.sexFamale.checked -> false
                    else -> null
                },
                cardPr = when {
                    dialog.cardPrInstalled.checked -> true
                    dialog.cardPrNotInstalled.checked -> false
                    else -> null
                },
            )
        }
    }
}
