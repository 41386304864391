package org.tlsys.gateway

import kotlinx.browser.localStorage
import org.tlsys.STORAGE_CONSTS
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.ui.*
import org.tlsys.api.GatewayService
import org.tlsys.await
import org.tlsys.serialization.*
import org.tlsys.ui.*
import org.tlsys.ui.appendTo
import org.w3c.dom.get
import org.w3c.dom.set
import pw.binom.web.layout.*

class TelegramGatewayTestDialog(gateway: Gateway) : GatewayTestDialog(gateway) {

    val destination = EditText(placeHolder = "Получатель")
        .text(localStorage.get(STORAGE_CONSTS.LAST_TESTED_TELEGRAM) ?: "")
        .appendTo(layout, grow = 0, shrink = 0)

    protected val message = TextareaInput()
        .text(localStorage.get(STORAGE_CONSTS.LAST_TESTED_TEXT_EMAIL) ?: "")
        .textValidator(TextValidators.NOT_BLANK)
        .withLabel("Cообщения")
        .appendTo(layout, grow = 1, shrink = 1)

    override suspend fun test() {
        val testData = TelegramTestData(
            chatId = destination.text,
            text = message.content.text,
        )
        val testDataText = internalJson.encodeToString(TelegramTestData.serializer(), testData)
        val request = GatewayTest(
            gateway = gateway,
            testData = testDataText,
            firstName = firstName.text,
            middleName = patronymic.text,
            lastName = surname.text,
        )
        val response = ProcessBlocker.block("Отправка сообщения") {
            GatewayService.test(request)
        }.await()
        showResult(response)
    }

    init {
        destination.eventChange.on {
            if (destination.isValid) {
                localStorage.set(key = STORAGE_CONSTS.LAST_TESTED_TELEGRAM, destination.text)
            }
        }

        message.content.eventChange.on {
            if (message.content.isValid) {
                localStorage.set(key = STORAGE_CONSTS.LAST_TESTED_TEXT_EMAIL, message.content.text)
            }
        }
    }

    companion object {
        suspend fun show(gateway: Gateway) =
            TelegramGatewayTestDialog(gateway).show()
    }
}
