package org.tlsys.security

import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.GroupService
import org.tlsys.api.UsersService
import org.tlsys.await
import org.tlsys.dto.auth.UserDTO
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class UserFragment : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    companion object {
        const val NAME = "Пользователи"
    }

    private val groupService by Services.byClass(GroupService::class)
    private val usersService by Services.byClass(UsersService::class)

    fun addUser(user: UserDTO) {
        val item = Item(user).appendFirstTo(table)
        Effects.createAndBlankItem(item.dom)
    }

    private val scroll = ScrollController(dom)
    private val table = MaterialTable()
        .appendTo(layout, grow = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Имя").appendTo(header)
            MaterialTable.TextColumn("Группы").appendTo(header).widthPx(1)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    override suspend fun onInit() {
        super.onInit()
        usersService.findUsers(0.jdto, (-1).jdto).await().forEach {
            Item(it).appendTo(table)
        }
    }

    private inner class Item(var user: UserDTO) : MaterialTable.Row() {
        val name = MaterialTable.TextColumn(user.name).appendTo(this)
        val group = MaterialTable.TextColumn("").appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        override suspend fun onInit() {
            super.onInit()
            refresh()
        }

        private suspend fun refresh() {
            name.text = user.name
            group.text = groupService.getGroupsOfUser(user.id.jdto).await().map { it.name }.joinToString(", ")
        }

        init {
            actions.component.visibleOnHover(this)
            actions.component.iconBtn(MaterialIcons.EDIT).onClick {
                val d = AddUserDialog.show(user) ?: return@onClick

                user = usersService.editUser(
                    id = user.id.jdto,
                    groups = d.groups.await().selectedItems.map { it.jdto }.jdto(),
                    password = d.pass.text.takeIf { it.isNotBlank() }?.jdto,
                    name = d.name.text.jdto,
                    phone = d.phone.text.jdto,
                ).await()
                refresh()
                Effects.blockRow(dom)
                refresh()
            }
            actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                if (!YesNoDialog.show("Удалить пользователя ${user.name}?").await()) {
                    return@onClick
                }
                usersService.removeUser(user.id.jdto).await()
                Effects.removeItem(dom).await()
                removeSelf()
            }
        }
    }
}
