package org.tlsys.templates

import org.tlsys.Template
import org.tlsys.TemplateType
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.connectWith
import org.tlsys.admin.ui.*
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.uuid.nextUuid
import pw.binom.web.layout.*
import kotlin.random.Random

class SMSTemplateDialog(val dto: Template?, val new: Boolean) : TitleDialog("Шаблон SMS сообщения") {
    private val body = SaveCancelBody(okText = if (new) "Создать" else "Сохранить").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private val name = EditText(placeHolder = "Название шаблона")
        .text(dto?.name ?: "Шаблон SMS")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(layout, grow = 0, shrink = 0)
    private val text = TextareaInput()
        .text(dto?.body ?: "")
        .withLabel("Текст шаблона")
        .appendTo(layout)

    private fun build() = Template(
        id = if (new || dto == null) Random.nextUuid() else dto.id,
        name = name.text,
        type = TemplateType.SMS,
        subject = null,
        body = text.content.text,
    )

    private var result: Template? = null

    override fun onEscapeClick() {
        super.onEscapeClick()
        result = null
        close()
    }

    init {
        dom.style.apply {
            width = 600.px
            height = 300.px
        }
        MultiValidator(name, text.content)
            .connectWith(body.okBtn)

        body.ok {
            result = build()
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    companion object {
        suspend fun show(dto: Template?, new: Boolean): Template? {
            val d = SMSTemplateDialog(dto = dto, new = new)
            d.show()
            return d.result
        }
    }
}
