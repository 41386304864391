package org.tlsys.security

import org.tlsys.GroupSelectorFragment
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.api.GroupService
import org.tlsys.api.UsersService
import org.tlsys.async2
import org.tlsys.dto.auth.UserDTO
import org.tlsys.gui.MessageDialog
import org.tlsys.json.jdto
import org.tlsys.px
import pw.binom.web.layout.*

/**
 * Created by Ермаков Игорь Александрович (email: igor.yermakov94@yandex.ru) on 18.08.2017.
 */
class AddUserDialog(val user: UserDTO?) :
    TitleDialog(if (user === null) "Создание пользователя" else "Редактирование пользователя") {
    init {
        dom.style.width = 300.px
        dom.style.height = 450.px
    }

    private var _canceled: Boolean = true
    private val usersService by Services.byClass(UsersService::class)
    val canceled: Boolean
        get() = _canceled

    private val body = SaveCancelBody().appendTo(layout, grow = 1, shrink = 1)

    val name = EditText(placeHolder = "ФИО")
        .textValidator(TextValidators.NOT_BLANK)
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)

    val login = EditText(placeHolder = "Логин")
        .textValidator(TextValidators.NOT_BLANK and TextValidators.NO_SPACE)
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)

    val phone = EditText(placeHolder = "Телефон")
        .textValidator(TextValidators.BLANK or TextValidators.IS_PHONE)
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)

    init {
        login.enabled = user == null
    }

    val pass = EditText(password = true, placeHolder = "Пароль")
        .textValidator(
            TextValidators.NOT_BLANK or
                Validator {
                    if (user != null && it.isEmpty()) valid() else invalid("Пароль не может быть пустым")
                },
        )
        .appendTo(body.componentsLayout, grow = 0, shrink = 0)

    // val groupList = ArrayList<GroupDTO>()
    private val groupService by Services.byClass(GroupService::class)
    val groupView = ComponentView().appendTo(body.componentsLayout, grow = 1, shrink = 1)
    val groups = async2 {
        console.info("Getting selected...")
        val selected = if (user != null) {
            groupService.getGroupsOfUser(user.id.jdto).await().map { it.id }
        } else {
            listOf<Long>()
        }
        console.info("Build group list")
        val g = GroupSelectorFragment(selected)
        groupView.set(g)
        g
    }

    private val cmv = MultiValidator().also {
        it.addValidated(pass)
        it.addValidated(name)
        it.addValidated(login)
        it.addValidated(phone)
        it.onValidChange {

            body.okBtn.enabled = it
        }
    }

    init {
        if (user !== null) {
            name.text = user.name
            login.text = user.login
            phone.text = user.phone ?: ""
//            name.content.enabled = false
//            login.content.enabled = false
//            pass.content.enabled = false
//            pass2.content.enabled = false

            pass.placeHolder = "Новый пароль"
            pass.text = ""
        }

        body.ok {
            if (user == null) {
                val err = usersService.checkUserExist(
                    login = login.text.jdto,
                    phone = phone.text.jdto,
                ).await()?.value
                if (err != null) {
                    MessageDialog.showError(message = err)
                    return@ok
                }
            }
            _canceled = false
            close()
        }

        body.cancel {
            cancelClick()
        }
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        cancelClick()
    }

    private fun cancelClick() {
        _canceled = true
        close()
    }

    companion object {
        suspend fun show(user: UserDTO?): AddUserDialog? {
            val d = AddUserDialog(user)
            d.show()
            return if (d.canceled) {
                null
            } else {
                d
            }
        }
    }
}
