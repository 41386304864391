package org.tlsys.settings

import kotlinx.browser.document
import org.tlsys.addClass
import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.AbstractPage
import org.tlsys.admin.ui.ListView
import org.tlsys.admin.ui.Styles
import org.tlsys.paddingLeftPX
import org.tlsys.px
import org.tlsys.ui.Page
import org.tlsys.ui.createDiv
import org.tlsys.ui.createLink
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.ScrollController
import pw.binom.web.layout.FlexLayout
import pw.binom.web.layout.appendTo

private class SettingItem(val name: String, val creator: (AbstractPage) -> AbstractPage)

object Settings {
    val URI = "settings"
    val TITLE = "Администрирование"
    private val items = HashMap<String, SettingItem>()
    private val items2 by Services.listByClass(org.tlsys.admin.SettingItem::class)

    fun reg(uri: String, name: String, creator: (AbstractPage) -> AbstractPage) {
        items.put(uri, SettingItem(name, creator))
    }

    fun unreg(uri: String) {
        items.remove(uri)
    }

    class SettingsPage : AbstractPage() {
        override suspend fun getTitle(): String = TITLE
        private val listView = ListView<ListItem>().appendTo(contentLayout)
        private val scrollPlane = ScrollController(listView.dom)

        init {
            onInit {
                console.info("OnInit->SettingsPage")
                items.forEach {
                    listView.addLast(ListItem(title = it.value.name, uri = it.key))
                }
                items2.forEach {
                    listView.addLast(ListItem(title = it.title, uri = it.uri))
                }
            }
        }

        override suspend fun next(path: String): Page? {
            val g = items[path]
            if (g != null) {
                return g.creator(this)
            }

            val b = items2.find { it.uri == path }
            if (b != null) {
                return b.getPage()
            }

            return super.next(path)
        }

        private inner class ListItem(val title: String, val uri: String) :
            AbstractComponent<HTMLDivElement>() {
            override val dom: HTMLDivElement = document.createDiv()
            private val layout = FlexLayout(this, alignItems = FlexLayout.AlignItems.Center)
            val link = document.createLink().appendTo(layout).addClass(Styles.LINK).addClass(Styles.SIMPLE_TEXT)

            init {
                onInit {
                    console.info("ListItem->onInit")
                }
                afterConstruct()
                addClass(Styles.LIST_ITEM_STYLE)
                link.text = title
                link.href = "#${pageNavigation.getUrl(this@SettingsPage)}/$uri"
                link.style.textAlign = "left"
                dom.style.apply {
                    paddingLeftPX = 16f
                    lineHeight = 50.px
                }
            }
        }
    }
}
