package org.tlsys.core.promotion

import org.tlsys.*
import org.tlsys.admin.BonusAmountConfig
import org.tlsys.admin.form.*
import org.tlsys.admin.removeClass
import org.tlsys.admin.ui.*
import org.tlsys.cms.ui.TimeCountSelector
import org.tlsys.cms.ui.time
import org.tlsys.core.Closeable
import org.tlsys.css.CSS
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.FlexLayout
import pw.binom.web.layout.appendTo

class BonusPeriodDialog(start: Long, end: Long?) : TitleDialog("Активация и сгорание") {
    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private val start = TimeCountSelector(placeHolder = "Время перед активацией", canBeEmpty = false)
        .time(start)
        .appendTo(layout, grow = 0, shrink = 0)

    private val withEndTime = Checkbox("Использовать сгорание").checked(end != null)
        .appendTo(layout, grow = 0, shrink = 0)

    private val end = TimeCountSelector(placeHolder = "Время перед сгоранием", canBeEmpty = !withEndTime.checked)
        .time(end)
        .appendTo(layout, grow = 0, shrink = 0)
    private var canceled = true

    init {
        val endValidator = Validated(this.end) {
            if (withEndTime.checked) this.end.valid else true
        }
        withEndTime.EVENT_CHANGED.on {
            endValidator.refresh()
            this.end.canBeEmpty = !withEndTime.checked
            this.end.enabled = withEndTime.checked
        }
        endValidator.refresh()
        dom.style.width = 300.px
        body.cancel {
            canceled = true
            close()
        }
        body.ok {
            canceled = false
            close()
        }

        val validator = MultiValidator(this.start, endValidator)
        validator.onValidChange {
            body.okBtn.enabled = it
        }
    }

    override fun onEscapeClick() {
        canceled = true
        close()
    }

    override suspend fun onInit() {
        super.onInit()
        start.autoTimeFormat()
        if (end.time != null) {
            end.autoTimeFormat()
        }
        end.forceRefreshValid()
    }

    companion object {
        suspend fun show(start: DateDuration, end: DateDuration?): Pair<DateDuration, DateDuration?>? {
            val d = BonusPeriodDialog(start = start.asLong, end = end?.asLong)
            d.show()
            if (d.canceled) {
                return null
            }
            val end = if (d.withEndTime.checked) d.end.time?.toDateDuration else null
            return d.start.time!!.toDateDuration to end
        }
    }
}

abstract class BonusConfigEditor(canBeEmpty: Boolean) :
    DivComponentWithLayout(
        direction = FlexLayout.Direction.Row,
        alignItems = FlexLayout.AlignItems.Center,
    ),
    Validated {
    companion object {
        val BUTTON = CSS.genName()
        val TEXT = CSS.genName()
        val ENABLED = CSS.genName()

        //        val UNIT = CSS.genName()
        val POSTFIX = CSS.genName()
        val STYLE = CSS.style {
            ".$POSTFIX" {
                opacity = 0.5
                userSelect = "none"
                transition = "opacity 100ms ease-in-out"
                cursor = "default"
            }
            ".$ENABLED" then {
                ".$POSTFIX" {
                    cursor = "pointer"
                    hover {
                        opacity = 1.0
                    }
                }
            }
            ".$BUTTON" {
                opacity = 0.5
                border = "none"
                backgroundColor = "transparent"
                outline = "none"
                paddingTop = 18.px
                paddingLeft = 7.px
//                ":hover" then {
//                    opacity = 1.0
//                }
            }
            ".$TEXT" {
//                opacity = 0.5
                paddingTop = 20.px
            }
//            ".$UNIT"{
// //                opacity = 0.5
//                paddingTop = 16.px
//                paddingRight = 8.px
//            }
//            hover {
//                ".$BUTTON"{
//                    opacity = 1.0
//                }
//                ".$TEXT" {
//                    opacity = 1.0
//                }
//                ".$UNIT"{
//                    opacity = 1.0
//                }
//            }
        }.name

        val UNIT = CSS.style {
            userSelect = "none"
            cursor = "default"
            paddingTop = 16.px
            paddingRight = 8.px
        }.name
    }

    override val valid: Boolean
        get() = selfPercent.valid

    override fun onValidChange(listener: ValidListener): Closeable =
        selfPercent.onValidChange(listener)

    protected val selfPercent = EditText()
        .textValidator(DoubleValidator.FORMAT)
        .appendTo(layout, grow = 1, shrink = 1)
    protected val postfix = DivLayout().appendTo(layout, grow = 0, shrink = 0).addClass(POSTFIX)

    protected val span =
        Span().appendTo(postfix.layout, grow = 0, shrink = 0).addClass(Styles.SIMPLE_TEXT).addClass(TEXT)

    private val icon =
        Span("schedule")
            .appendTo(postfix.layout, grow = 0, shrink = 0)
            .addClass("material-icons")
            .addClass(BUTTON)

    var enabled
        get() = selfPercent.enabled
        set(value) {
            selfPercent.enabled = value
            if (value) {
                addClass(ENABLED)
            } else {
                removeClass(ENABLED)
            }
            selfPercent.forceRefreshValid()
        }
    var canBeEmpty = canBeEmpty
        set(value) {
            field = value
            selfPercent.forceRefreshValid()
        }

    init {
        this.enabled = enabled
        addClass(STYLE)

        selfPercent.orTextValidator(
            Validator {
                if (canBeEmpty && it.isBlank()) {
                    valid()
                } else {
                    invalid()
                }
            },
        )

        selfPercent.andTextValidator(
            Validator {
                if (!canBeEmpty && it.isBlank()) {
                    invalid()
                } else {
                    valid()
                }
//            if (!canBeEmpty) {
//                TextValidators.NOT_BLANK.valid(it)
//            } else {
//                null
//            }
            },
        )
//        if (!canBeEmpty) {
//            selfPercent.andTextValidator(TextValidators.NOT_BLANK)
//        }
        postfix.dom.on("click") {
            if (!enabled) {
                return@on
            }
            async2 {
                val (start, end) = BonusPeriodDialog.show(start, end)
                    ?: return@async2
                this.start = start
                this.end = end
            }
        }
        this.canBeEmpty = canBeEmpty
    }

    private fun refresh() {
        val end = end
        val c = start.calcOptimalType(TimeType.values()) ?: TimeType.MS
        val b = c.convert(start.asLong)
        val startText = if (start.asLong == 0L) {
            "сразу"
        } else {
            "$b ${c.getSuffixRus(b)}"
        }
        val endText = if (end == null) {
            "ထ"
        } else {
            val e = end.calcOptimalType(TimeType.values()) ?: TimeType.MS
            val v = e.convert(end.asLong)
            "$v ${e.getSuffixRus(v)}"
        }
        span.text = "$startText / $endText"
    }

    var start: DateDuration = DateDuration.ZERO
        set(value) {
            field = value
            refresh()
        }

    var end: DateDuration? = null
        set(value) {
            field = value
            refresh()
        }

    override suspend fun onInit() {
        super.onInit()
        refresh()
    }

    class PercentEditor(placeHolder: String = "Процент от чека", canBeEmpty: Boolean) :
        BonusConfigEditor(canBeEmpty = canBeEmpty) {
        init {
            selfPercent.placeHolder = placeHolder
            selfPercent.andTextValidator(
                (
                    DoubleValidator.minOrEquals2(100.0) and DoubleValidator.moreOrEquals2(
                        0.0,
                    ) and DoubleValidator.forMaxAfterPoint(2)
                    ) or TextValidators.BLANK,
            )
        }

        var value: Percent?
            get() = selfPercent.text.toDoubleOrNull()?.let { it.toPercent }
            set(value) {
                selfPercent.text = value?.asDouble?.toString() ?: ""
            }

        fun values(value: Percent?, start: DateDuration, end: DateDuration?): PercentEditor {
            this.value = value
            this.start = start
            this.end = end
            return this
        }
    }

    class AmountEditor(
        value: MoneyValue? = null,
        start: DateDuration = DateDuration.ZERO,
        end: DateDuration? = null,
        placeHolder: String = "Сумма бонусов",
        canBeEmpty: Boolean = false,
    ) : BonusConfigEditor(canBeEmpty = canBeEmpty) {
        private val unitSpan = Span("₽")
            .appendTo(layout, grow = 0, shrink = 0)
            .addClass(Styles.SIMPLE_TEXT)
            .addClass(UNIT)
            .after(selfPercent)
        var value: MoneyValue?
            get() = selfPercent.text.toDoubleOrNull()?.let { it.toMoneyValue }
            set(value) {
                selfPercent.text = value?.asDouble?.toString() ?: ""
            }

        var value2: BonusAmountConfig?
            get() {
                val value = value ?: return null
                return BonusAmountConfig(bonusAmount = value, start = start, end = end)
            }
            set(value) {
                this.value = value?.bonusAmount
                this.start = value?.start ?: DateDuration.ZERO
                this.end = value?.end
            }

        fun value(value: BonusAmountConfig?): AmountEditor {
            this.value2 = value
            return this
        }

        init {
            selfPercent.placeHolder = placeHolder
            selfPercent.andTextValidator(
                (DoubleValidator.moreOrEquals2(0.0) and DoubleValidator.forMaxAfterPoint(2)) or TextValidators.BLANK,
            )
            this.value = value
            this.start = start
            this.end = end
        }
    }
}
