package org.tlsys.members

import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.Tag
import org.tlsys.admin.ui.TagPanel
import org.tlsys.api.MembersService
import org.tlsys.dto.MemberTagDTO
import org.tlsys.json.jdto

class TagPanel3(val memberId: Long, readOnly: Boolean) : TagPanel(readOnly) {

    private val membersService by Services.byClass(MembersService::class)

    private var inited = false

    override suspend fun addTag(tags: List<MemberTagDTO>) {
        super.addTag(tags)
        if (inited) {
            membersService.setMemberTags(memberId.jdto, list.map { it.tag.id.jdto }.jdto()).await()
        }
    }

    override suspend fun removeTag(tag: Tag) {
        if (inited) {
            membersService.setMemberTags(memberId.jdto, (list - listOf(tag)).map { it.tag.id.jdto }.jdto()).await()
        }
        super.removeTag(tag)
    }

    override suspend fun onStart() {
        super.onStart()
        if (!inited) {
            addTag(membersService.getMemberTags(memberId.jdto).await())
        }
        inited = true
    }
}
