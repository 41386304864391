package org.tlsys.cms

import org.tlsys.core.Closeable
import org.w3c.dom.*
import org.w3c.dom.css.CSSStyleDeclaration
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener
import org.w3c.dom.parsing.DOMParser
import kotlin.js.Console
import kotlin.properties.ReadWriteProperty
import kotlin.reflect.KProperty

fun HTMLElement.onClick(f: (Event) -> Unit) = on("click", f = f)
inline val <T : Element> T.style: CSSStyleDeclaration
    get() = this.asDynamic().style

fun Element.on(event: String, useCapture: Boolean = false, f: (Event) -> Unit): Closeable {
    val o = object : EventListener {
        override fun handleEvent(event: Event) {
            f(event)
        }
    }

    addEventListener(type = event, callback = o, options = useCapture)
    return Closeable {
        removeEventListener(event, o)
    }
}

inline fun <T : Closeable, R> T.use(block: (T) -> R): R {
    val r = try {
        block(this)
    } finally {
        close()
    }

    return r
}

fun Storage.string(name: String) = StringStorage(this, name)
fun Storage.int(name: String) = IntStorage(this, name)
fun Storage.long(name: String) = LongStorage(this, name)
fun Storage.bool(name: String) = BoolStorage(this, name)

class StringStorage(val storage: Storage, val name: String) {
    operator fun getValue(thisRef: Any?, property: KProperty<*>): String? = storage.getItem(name)

    operator fun setValue(thisRef: Any?, property: KProperty<*>, value: String?) {
        if (value == null) {
            storage.removeItem(name)
        } else {
            storage.set(name, value)
        }
    }
}

class IntStorage(val storage: Storage, val name: String) {
    operator fun getValue(thisRef: Any?, property: KProperty<*>): Int? = storage.getItem(name)?.toIntOrNull()

    operator fun setValue(thisRef: Any?, property: KProperty<*>, value: Int?) {
        if (value == null) {
            storage.removeItem(name)
        } else {
            storage.set(name, value.toString())
        }
    }
}

class BoolStorage(val storage: Storage, val name: String) : ReadWriteProperty<Any?, Boolean?> {
    override operator fun getValue(thisRef: Any?, property: KProperty<*>): Boolean? =
        storage.getItem(name)?.toBooleanStrictOrNull()

    override operator fun setValue(thisRef: Any?, property: KProperty<*>, value: Boolean?) {
        if (value == null) {
            storage.removeItem(name)
        } else {
            storage.set(name, value.toString())
        }
    }
}

class LongStorage(val storage: Storage, val name: String) {
    operator fun getValue(thisRef: Any?, property: KProperty<*>): Long? = storage.getItem(name)?.toLongOrNull()

    operator fun setValue(thisRef: Any?, property: KProperty<*>, value: Long?) {
        if (value == null) {
            storage.removeItem(name)
        } else {
            storage.set(name, value.toString())
        }
    }
}

inline fun Console.debugger() {
    js("debugger")
}

fun parseXML(xml: String): Document {
    val parser = DOMParser()
    return parser.parseFromString(xml, "text/xml")
}
