package org.tlsys.lk.pages

import org.tlsys.admin.cross.RemoteOsmiService
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.and
import org.tlsys.admin.form.text
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.cms.ui.TimeCountSelector
import org.tlsys.core.clientImport.fix
import org.tlsys.core.clientImport.unfix
import org.tlsys.gui.MessageDialog
import org.tlsys.lk.*
import org.tlsys.osmicard.TemplateEditDialog
import org.tlsys.px
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class CardLayoutWithLayoutAndCheckbox(title: String) : CardLayoutWithLayout(title) {
    val checkbox = BigCheckboxNoText().appendTo(titleLayout.layout, grow = 0, shrink = 0)

    init {
        checkbox.dom.style.transform = "translate(8px, 0px) scale(0.7)"
    }
}

class TemplateCard : CardLayoutWithLayout("Шаблоны") {
    private val refreshButton = RefreshButton().appendTo(titleLayout.layout, grow = 0, shrink = 0)
    private val table = MaterialTable().appendTo(layout, grow = 1, shrink = 1)

    init {
        layout.dom.style.minWidth = 300.px
        val h = MaterialTable.Header()
        h.appendTo(table)
        MaterialTable.TextColumn("Название").appendTo(h)
        MaterialTable.TextColumn("Действия").appendTo(h).widthPx(1)
        refreshButton.onClick {
            async2 {
                try {
                    val templates = RemoteOsmiService.refreshTemplates()
                    table.clearBody()
                    RemoteOsmiService.getTemplates().forEach {
                        TemplateRow(it).appendTo(table)
                    }
                } catch (e: dynamic) {
                    MessageDialog.showError("Не удается обновить шаблоны OsmiCards")
                }
            }
        }
    }

    override suspend fun onInit() {
        RemoteOsmiService.getTemplates().forEach {
            TemplateRow(it).appendTo(table)
        }
        super.onInit()
    }

    private class TemplateRow(var data: OsmicardTemplateDto) : MaterialTable.Row() {
        private val title = MaterialTable.TextColumn(data.name)
            .appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        init {
            actions.component.visibleOnHover(this)
            actions.component.iconBtn(MaterialIcons.SETTINGS).onClick {
                async2 {
                    data = TemplateEditDialog.show(data) ?: return@async2
                    RemoteOsmiService.editTemplate(data)
                    Effects.blockRow(dom)
                }
            }
        }
    }
}

class OsmiSerttingsPage : AbstractPage() {
    companion object {
        val URI = "osmicards"
        val TITLE = "Osmi Cards"
    }

    override suspend fun getTitle(): String = TITLE
    private val scroll = ScrollController(dom)

    private val membeInfoCard =
        CardLayoutWithLayoutAndCheckbox("Партнерская система").appendTo(
            contentLayout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Start,
        ).also {
            it.layout.direction = FlexLayout.Direction.Column
        }

    private val statusCard = CardLayoutWithLayoutAndCheckbox("Статус").appendTo(
        contentLayout,
        grow = 1,
        shrink = 1,
        align = FlexLayout.FlexItem.AlignSelf.Start,
    ).also {
        it.layout.direction = FlexLayout.Direction.Column
    }

    private val templateCard = TemplateCard().appendTo(
        contentLayout,
        grow = 1,
        shrink = 1,
        align = FlexLayout.FlexItem.AlignSelf.Start,
    )

    private val greetingCard =
        CardLayoutWithLayoutAndCheckbox("Приветственные бонусы").appendTo(
            contentLayout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Start,
        ).also {
            it.layout.direction = FlexLayout.Direction.Column
        }

    val eventsCard = CardLayoutWithLayout("События")
        .also {
            it.layout.alignItems = FlexLayout.AlignItems.Stretch
            it.layout.alignContent = FlexLayout.AlignContent.Stretch
            OsmicardEventComponent().appendTo(it.layout)
        }
        .appendTo(contentLayout)

    val referentBonusAmount = EditText(placeHolder = "Сумма")
        .textValidator(
            DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2) and DoubleValidator.moreOrEquals(0.0).text,
        ).appendTo(
            membeInfoCard.layout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Normal,
        )

    val referentBonusStart = TimeCountSelector(canBeEmpty = false, placeHolder = "Активация").appendTo(
        membeInfoCard.layout,
        grow = 0,
        shrink = 0,
        align = FlexLayout.FlexItem.AlignSelf.Normal,
    )
    val referentBonusEnd = TimeCountSelector(canBeEmpty = true, placeHolder = "Сгорание").appendTo(
        membeInfoCard.layout,
        grow = 0,
        shrink = 0,
        align = FlexLayout.FlexItem.AlignSelf.Normal,
    )

    val greetingAmount = EditText(placeHolder = "Сумма")
        .textValidator(
            DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2) and DoubleValidator.moreOrEquals(
                0.0,
            ).text,
        ).appendTo(
            greetingCard.layout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Normal,
        )
    val greetingStart = TimeCountSelector(canBeEmpty = false, placeHolder = "Активация")
        .appendTo(
            greetingCard.layout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Normal,
        )
    val greetingEnd = TimeCountSelector(canBeEmpty = true, placeHolder = "Сгорание")
        .appendTo(
            greetingCard.layout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Normal,
        )

    val allowDropForCreatedCard = Checkbox(label = "Авто-активация новых клиентов")
        .appendTo(
            statusCard.layout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Normal,
        )
    val apiId = EditText(placeHolder = "Api Идентификатор").textValidator(TextValidators.NOT_BLANK)
        .appendTo(
            statusCard.layout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Normal,
        )
    val apiKey = EditText(placeHolder = "Api Ключ").textValidator(TextValidators.NOT_BLANK)
        .appendTo(
            statusCard.layout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Normal,
        )
    val prefix = EditText(placeHolder = "Префикс").textValidator(TextValidators.NOT_BLANK)
        .appendTo(
            statusCard.layout,
            grow = 1,
            shrink = 1,
            align = FlexLayout.FlexItem.AlignSelf.Normal,
        )

    init {
        contentLayout.direction = FlexLayout.Direction.Row
        contentLayout.wrap = FlexLayout.Wrap.Enable
        contentLayout.alignContent = FlexLayout.AlignContent.Start
        async2 {
            val config = RemoteOsmiService.getConfig()
            statusCard.checkbox.checked = RemoteOsmiService.getEnabled()
            apiId.text = config.apiId
            apiKey.text = config.apiKey
            prefix.text = config.prefix
            allowDropForCreatedCard.checked = config.allowDropCreatedCard
            greetingAmount.text = config.greetingBonusAmount.unfix(2).toString()
            greetingStart.time = config.greetingBonusStart
            greetingEnd.time = config.greetingBonusEnd

            referentBonusAmount.text = config.referentBonusAmount.unfix(2).toString()
            referentBonusStart.time = config.referentBonusStart
            referentBonusEnd.time = config.referentBonusEnd
            referentBonusStart.autoTimeFormat()
            referentBonusEnd.autoTimeFormat()
            greetingCard.checkbox.checked = config.enableGreeting

            membeInfoCard.checkbox.checked = config.enableReferalSystem

            greetingStart.autoTimeFormat()
            greetingEnd.autoTimeFormat()

            greetingAmount.eventChange.on {
                updateSettings()
            }

            greetingStart.EVENT_CHANGED.on {
                updateSettings()
            }

            greetingEnd.EVENT_CHANGED.on {
                updateSettings()
            }

            greetingCard.checkbox.EVENT_CHANGED.on {
                updateSettings()
            }

            referentBonusAmount.eventChange.on {
                updateSettings()
            }

            referentBonusStart.EVENT_CHANGED.on {
                updateSettings()
            }

            referentBonusEnd.EVENT_CHANGED.on {
                updateSettings()
            }

            statusCard.checkbox.EVENT_CHANGED.on {
                async2 {
                    RemoteOsmiService.setEnabled(statusCard.checkbox.checked)
                }
            }

            apiId.eventChange.on {
                updateSettings()
            }
            apiKey.eventChange.on {
                updateSettings()
            }
            prefix.eventChange.on {
                updateSettings()
            }
        }
    }

    private fun updateSettings() {
        if (!greetingAmount.valid) {
            console.info("greetingAmount is not valid")
            return
        }
        if (!greetingStart.valid) {
            console.info("greetingStart is not valid")
            return
        }
        if (!greetingEnd.valid) {
            console.info("greetingEnd is not valid")
            return
        }
        if (!apiId.valid) {
            console.info("apiId is not valid")
            return
        }
        if (!apiKey.valid) {
            console.info("apiKey is not valid")
            return
        }
        if (!prefix.valid) {
            console.info("prefix is not valid")
            return
        }

        async2 {
            val config = RemoteOsmiService.getConfig()
            RemoteOsmiService.updateConfig(
                OsmiConfigDTO(
                    apiId = apiId.text,
                    apiKey = apiKey.text,
                    prefix = prefix.text,
                    greetingBonusAmount = greetingAmount.text.toFloat().fix(2),
                    greetingBonusStart = greetingStart.time!!,
                    greetingBonusEnd = greetingEnd.time,
                    referentBonusAmount = referentBonusAmount.text.toFloat().fix(2),
                    referentBonusStart = referentBonusStart.time!!,
                    referentBonusEnd = referentBonusEnd.time,
                    allowDropCreatedCard = allowDropForCreatedCard.checked,
                    referalLabel = config.referalLabel,
                    nameLabel = config.nameLabel,
                    bonusLabel = config.bonusLabel,
                    referalText = config.referalText,
                    enableReferalSystem = membeInfoCard.checkbox.checked,
                    enableGreeting = greetingCard.checkbox.checked,
                ),
            )
        }
    }
}

class OsmiSerttingsPage1 : AbstractPage() {

    companion object {
        val URI = "osmicards"
        val TITLE = "Osmi Cards"
    }

    override suspend fun getTitle(): String = OsmiSerttingsPage.TITLE

    val settingPanel = SettingsPlane().appendTo(contentLayout, grow = 1, shrink = 1)

    init {
        ScrollController(settingPanel.dom)
    }

    val status = settingPanel.add("Статус", BigCheckboxNoText())
    val allowDropForCreatedCard = settingPanel.add("Авто-активация новых клиентов", BigCheckboxNoText())
    val apiId = settingPanel.add("Api Идентификатор", EditText().textValidator(TextValidators.NOT_BLANK))
    val apiKey = settingPanel.add("Api Ключ", EditText().textValidator(TextValidators.NOT_BLANK))
    val prefix = settingPanel.add("Префикс", EditText().textValidator(TextValidators.NOT_BLANK))
    val enableReferalSystem = settingPanel.add("Включить реферальную систему", BigCheckboxNoText())

    init {
        settingPanel.add("Приветственные бонусы")
    }

    val greetingAmount = settingPanel.add(
        "Сумма",
        EditText()
            .textValidator(
                DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2) and DoubleValidator.moreOrEquals(
                    0.0,
                ).text,
            ),
    )
    val greetingStart = settingPanel.add("Активация", TimeCountSelector(canBeEmpty = false))
    val greetingEnd = settingPanel.add("Сгорание", TimeCountSelector(canBeEmpty = true))

    init {
        settingPanel.add("Бонусы за друга")
    }

    val referentBonusAmount = settingPanel.add(
        "Сумма",
        EditText()
            .textValidator(
                DoubleValidator.FORMAT and DoubleValidator.forMaxAfterPoint(2) and DoubleValidator.moreOrEquals(
                    0.0,
                ).text,
            ),
    )
    val referentBonusStart = settingPanel.add("Активация", TimeCountSelector(canBeEmpty = false))
    val referentBonusEnd = settingPanel.add("Сгорание", TimeCountSelector(canBeEmpty = true))

    init {
        settingPanel.add("Отображение")
    }

    val nameLabel = settingPanel.add("Заголовок поля для отображения ФИО", EditText())
    val bonusLabel = settingPanel.add("Заголовок поля для отображения бонусов", EditText())
    val referalLabel = settingPanel.add("Заголовок поля распространения реферальной системы", EditText())
    val referalText = settingPanel.add("Текст реферального сообщения", EditText())

    private fun updateSettings() {
        if (!greetingAmount.component.valid) {
            console.info("greetingAmount is not valid")
            return
        }
        if (!greetingStart.component.valid) {
            console.info("greetingStart is not valid")
            return
        }
        if (!greetingEnd.component.valid) {
            console.info("greetingEnd is not valid")
            return
        }
        if (!apiId.component.valid) {
            console.info("apiId is not valid")
            return
        }
        if (!apiKey.component.valid) {
            console.info("apiKey is not valid")
            return
        }
        if (!prefix.component.valid) {
            console.info("prefix is not valid")
            return
        }

        async2 {
            RemoteOsmiService.updateConfig(
                OsmiConfigDTO(
                    apiId = apiId.component.text,
                    apiKey = apiKey.component.text,
                    prefix = prefix.component.text,
                    greetingBonusAmount = greetingAmount.component.text.toFloat().fix(2),
                    greetingBonusStart = greetingStart.component.time!!,
                    greetingBonusEnd = greetingEnd.component.time,
                    referentBonusAmount = referentBonusAmount.component.text.toFloat().fix(2),
                    referentBonusStart = referentBonusStart.component.time!!,
                    referentBonusEnd = referentBonusEnd.component.time,
                    allowDropCreatedCard = allowDropForCreatedCard.component.checked,
                    referalLabel = referalLabel.component.text,
                    nameLabel = nameLabel.component.text,
                    bonusLabel = bonusLabel.component.text,
                    referalText = referalText.component.text,
                    enableReferalSystem = enableReferalSystem.component.checked,
                    enableGreeting = true,
                ),
            )
        }
    }

    override suspend fun onInit() {
        super.onInit()

        async2 {
            val config = RemoteOsmiService.getConfig()
            status.component.checked = RemoteOsmiService.getEnabled()
            apiId.component.text = config.apiId
            apiKey.component.text = config.apiKey
            prefix.component.text = config.prefix
            allowDropForCreatedCard.component.checked = config.allowDropCreatedCard
            greetingAmount.component.text = config.greetingBonusAmount.unfix(2).toString()
            greetingStart.component.time = config.greetingBonusStart
            greetingEnd.component.time = config.greetingBonusEnd

            referentBonusAmount.component.text = config.referentBonusAmount.unfix(2).toString()
            referentBonusStart.component.time = config.referentBonusStart
            referentBonusEnd.component.time = config.referentBonusEnd

            referalLabel.component.text = config.referalLabel
            nameLabel.component.text = config.nameLabel
            bonusLabel.component.text = config.bonusLabel
            referalText.component.text = config.referalText

            fun gg() {
                referalLabel.component.enabled = enableReferalSystem.component.checked
                referalText.component.enabled = enableReferalSystem.component.checked
            }

            enableReferalSystem.component.EVENT_CHANGED.on {
                gg()
                updateSettings()
            }

            enableReferalSystem.component.checked = config.enableReferalSystem
            gg()

            greetingStart.component.autoTimeFormat()
            greetingEnd.component.autoTimeFormat()

            referalLabel.component.eventChange.on {
                updateSettings()
            }
            nameLabel.component.eventChange.on {
                updateSettings()
            }
            bonusLabel.component.eventChange.on {
                updateSettings()
            }
            referalText.component.eventChange.on {
                updateSettings()
            }

            greetingAmount.component.eventChange.on {
                updateSettings()
            }

            greetingStart.component.EVENT_CHANGED.on {
                updateSettings()
            }

            greetingEnd.component.EVENT_CHANGED.on {
                updateSettings()
            }

            referentBonusAmount.component.eventChange.on {
                updateSettings()
            }

            referentBonusStart.component.EVENT_CHANGED.on {
                updateSettings()
            }

            referentBonusEnd.component.EVENT_CHANGED.on {
                updateSettings()
            }

            status.component.EVENT_CHANGED.on {
                async2 {
                    RemoteOsmiService.setEnabled(status.component.checked)
                }
            }

            apiId.component.eventChange.on {
                updateSettings()
            }
            apiKey.component.eventChange.on {
                updateSettings()
            }
            prefix.component.eventChange.on {
                updateSettings()
            }
        }
    }
}
