package org.tlsys.core.clientImport

import org.tlsys.admin.addClass
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.await
import org.tlsys.cms.click
import org.tlsys.core.Closeable
import org.tlsys.core.api.ImportService
import org.tlsys.file.RFile
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.tlsys.utils.StorageVar
import org.tlsys.utils.bind
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pw.binom.web.layout.*

class ExcelImportComponent(val file: RFile) :
    DivComponentWithLayout(direction = FlexLayout.Direction.Column),
    Master.Stage<HTMLDivElement> {
    private val listeners = ArrayList<ValidListener>()

    //    private val span = Span("Импорт \"${file.name.value}.${file.ext.value}\"").appendTo(layout, grow = 0, shrink = 0).addClass(Styles.SIMPLE_TEXT)
    private val allowDrop = Checkbox("Разрешить списание импортируемым клиентам", true)
        .appendTo(layout, grow = 0, shrink = 0)
    private val buildPlanUpdate = Checkbox("Построить план выполнения", false)
        .appendTo(layout, grow = 0, shrink = 0)

    private val logicGroup = RadioBox.Group()
    private val importModeGroup = ElementGroup("Режим импорта").appendTo(layout, grow = 0, shrink = 0)
    private val importCreateOrUpdate =
        RadioBox("Создать или обновить").checked().group(logicGroup).appendTo(importModeGroup.layout)
    private val importOnlyCreate = RadioBox("Только создать").group(logicGroup).appendTo(importModeGroup.layout)
    private val importOnlyUpdate = RadioBox("Только обновить").group(logicGroup).appendTo(importModeGroup.layout)

    private val rowGroup =
        ElementGroup("Строки", direction = FlexLayout.Direction.Row).appendTo(layout, grow = 0, shrink = 0)
    private val rowEndValidator = Validator<String> {
        val start = rowStart.text.toIntOrNull() ?: return@Validator invalid("Укажите значение стартовой строки")
        if (it.toInt() < start) {
            invalid("Значение конечной строки должно быть больше начальной")
        } else {
            valid()
        }
    }
    private val rowStart = EditText("Начало")
        .appendTo(rowGroup.layout)
        .textValidator(IntegerValidator.FORMAT and TextValidators.NOT_BLANK)
        .bind(StorageVar("import_row_start"))
    private val rowEnd = EditText("Конец")
        .appendTo(rowGroup.layout)
        .textValidator(IntegerValidator.FORMAT and TextValidators.NOT_BLANK and rowEndValidator)
        .bind(StorageVar("import_row_end"))

    private val divList = DivLayout(direction = FlexLayout.Direction.Column).appendTo(layout)
    private val list = ListView<Item>().appendTo(divList.layout, grow = 0, shrink = 1)
    private val addBtn = AddItemBtn().appendTo(divList.layout, grow = 0, shrink = 0)
    private var savedList by StorageVar("import_columns")

    private fun saveColumnList() {
        savedList = list.items.map { "${it.type.name};${it.columnName}" }.joinToString("|")
    }

    private inner class Item : DivComponentWithLayout(), Validated {
        private val typeSelector =
            Combobox2(ImportService.ExcelColumnType.values().map { it.rus }, 0).appendTo(layout, grow = 0, shrink = 0)
        private val column = EditText(placeHolder = "Столбец")
            .appendTo(layout, grow = 1, shrink = 1)
            .textValidator(TextValidators.NOT_BLANK and TextValidators.EXCEL_COLUMN)
        var columnName
            get() = column.text
            set(value) {
                column.text = value
            }
        private val actions = ActionPlace().appendTo(layout, grow = 0, shrink = 0)
        var type: ImportService.ExcelColumnType
            get() = ImportService.ExcelColumnType.values()[typeSelector.select]
            set(value) {
                typeSelector.select = value.ordinal
            }

        init {
            typeSelector.eventChange.on {
                saveColumnList()
            }
            column.eventChange.on {
                saveColumnList()
            }
            dom.style.apply {
                padding = "10px 0px"
                boxSizing = "border-box"
            }
            column.onValidChange {
                refreshState()
            }
            addClass(Styles.LIST_ITEM_STYLE)
            actions.visibleOnHover(this)
            actions.iconBtn(MaterialIcons.DELETE).onClick {
                Effects.removeItem(dom).await()
                list.remove(this)
                refreshState()
            }
        }

        override val valid: Boolean
            get() = column.valid

        override fun onValidChange(listener: ValidListener): Closeable = column.onValidChange(listener)
    }

    private val validator = Validator<Unit> {
        if (!rowStart.valid || !rowEnd.valid || list.items.isEmpty() || list.items.any { !it.valid }) {
            invalid()
        } else {
            valid()
        }
    }

    private val importService by Services.byClass(ImportService::class)

    override suspend fun next(): Master.Stage<out HTMLElement> {
        val columns = list.items.map {
            ImportService.ExcelColumn(
                typeStr = it.type.name.jdto,
                column = it.columnName.jdto,
            )
        }.jdto()

        return ProcessBlocker.block<Master.Stage<out HTMLElement>>("Проверка") {
            val errors = importService.checkImportExcel(
                file = file,
                rowStart = rowStart.text.toInt().jdto,
                rowEnd = rowEnd.text.toInt().jdto,
                columns = columns,
            ).await()

            if (errors.isNotEmpty()) {
                ImportErrorStage(errors.map { it.value })
            } else {
                val preview = importService.getPreviewImportExcel(
                    file = file,
                    rowStart = rowStart.text.toInt().jdto,
                    rowEnd = minOf(rowStart.text.toInt() + 100, rowEnd.text.toInt()).jdto,
                    columns = columns,
                ).await()
                val importMode = when {
                    importCreateOrUpdate.checked -> ImportService.ProcessingMode.CREATE_OR_UPDATE
                    importOnlyCreate.checked -> ImportService.ProcessingMode.ONLY_CREATE
                    importOnlyUpdate.checked -> ImportService.ProcessingMode.ONLY_UPDATE
                    else -> throw RuntimeException("Не известный режим импорта")
                }
                ImportPreviewStage(
                    columns = columns,
                    rowStart = rowStart.text.toInt(),
                    rowEnd = rowEnd.text.toInt(),
                    allowDrop = allowDrop.checked,
                    importMode = importMode,
                    preview = preview,
                    file = file,
                    makePlan = buildPlanUpdate.checked,
                )
            }
        }.await()
    }

    override suspend fun onStart() {
        super.onStart()
        refreshState()
    }

    init {
        onInit {
            savedList?.takeIf { it.isNotEmpty() }?.split('|')?.forEach {
                val items = it.split(';', limit = 2)
                val type = ImportService.ExcelColumnType.valueOfOrNull(items[0]) ?: return@forEach
                val columnName = items[1]
                val i = Item()
                i.type = type
                i.columnName = columnName
                list.addLast(i)
            }
        }
        rowStart.eventChange.on {
            rowEnd.forceRefreshValid()
        }
        addBtn.click {
            list.addLast(Item())
            refreshState()
        }
        rowStart.onValidChange {
            refreshState()
        }

        rowEnd.onValidChange {
            refreshState()
        }
    }

    override val title: String
        get() = "Настройка импорта"

    override var valid: Boolean = false

    fun refreshState() {
        console.info("refreshState")
        val v = validator.valid(Unit).isValid
        if (v != valid) {
            valid = v
            listeners.forEach {
                it(v)
            }
        }
    }

    override fun onValidChange(listener: ValidListener): Closeable {
        listeners += listener
        return Closeable {
            listeners -= listener
        }
    }

    override val height: Int
        get() = 600
}

private class AddItemBtn : Span("add") {
    init {
        addClass("material-icons")
        dom.style.apply {
            textAlign = "center"
            cursor = "default"
        }
    }
}
