package org.tlsys.api

import kotlinx.browser.window
import org.tlsys.admin.core.Services
import org.tlsys.async2
import org.tlsys.dto.MemberBalans
import org.tlsys.json.jdto
import kotlin.coroutines.Continuation
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

class MemberBalansProvider {
    private val membersService by Services.byClass(MembersService::class)

    class Request(val member: Long) {
        val list = ArrayList<Continuation<MemberBalans>>()
    }

    private val members = HashMap<Long, ArrayList<Continuation<MemberBalans>>>()

    private var timer = 0

    private inner class DataGetter(members: Map<Long, List<Continuation<MemberBalans>>>) {

        init {
            async2 {
                val memList = HashMap(members)
                val balans = membersService.getBalans(members.keys.map { it.jdto }.jdto()).await()
                balans.forEach {
                    memList[it.member.value]?.forEach { r ->
                        r.resume(it)
                    }
                }
            }
        }
    }

    private fun startTimer() {
        if (timer == 0) {
            timer = window.setTimeout({
                DataGetter(members)
                members.clear()
                timer = 0
            }, 1000)
        }
    }

    suspend fun getBalans(member: Long): MemberBalans {
        val r = members.getOrPut(member) { ArrayList<Continuation<MemberBalans>>() }
        return suspendCoroutine<MemberBalans> {
            r += it
            startTimer()
        }
    }
}
