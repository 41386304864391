package org.tlsys.core.clientImport

import org.tlsys.admin.addClass
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.ui.Span
import org.tlsys.admin.ui.Styles
import org.tlsys.core.Closeable
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class ImportErrorStage(val errors: List<String>) :
    DivComponentWithLayout(direction = FlexLayout.Direction.Row),
    Master.Stage<HTMLDivElement> {
    override val title: String
        get() = when (errors.size) {
            1 -> "Ошибка импорта"
            else -> "Ошибки импорта"
        }
    override val height: Int
        get() = 500
    override val valid: Boolean
        get() = false

    private val closableStab = Closeable { }

    override fun onValidChange(listener: ValidListener): Closeable = closableStab

    private val list = DivLayout(direction = FlexLayout.Direction.Column).appendTo(layout)
    private val scroll = ScrollController(list.dom)

    init {
        errors.forEach {
            val div = DivLayout(direction = FlexLayout.Direction.Row, alignItems = FlexLayout.AlignItems.Center)
                .addClass(Styles.LIST_ITEM_STYLE)
                .appendTo(list.layout, grow = 0, shrink = 0)
            Span(it).addClass(Styles.SIMPLE_TEXT).appendTo(div.layout)
        }
    }
}
