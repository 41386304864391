package org.tlsys.ui

import org.tlsys.InputTarget
import org.tlsys.admin.events.EventElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.KeyboardEvent
import pw.binom.web.Component

interface Page : Component<HTMLDivElement>, InputTarget {
    suspend fun updateParams(params: Map<String, String?>)
    suspend fun getTitle(): String
    fun setTitle(title: String)
    val eventTitleChange: EventElement
    suspend fun next(path: String): Page? = null
    fun onKeyDown(event: KeyboardEvent): Boolean = false
    fun onKeyUp(event: KeyboardEvent): Boolean = false
}