package org.tlsys.wc

import org.tlsys.json.*

class CalcDocumentResultDTO(
        val screenMessage: String?,
        val discont: List<DiscontRecord>,
        val maxPay: List<MaxPayRecrod>,
        val newPositions: List<NewPositionRecord>,
        val state: List<BlockStateDTO>?,
        val success: Boolean
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<CalcDocumentResultDTO> {
        override val type: String
            get() = "CalcDocumentResultDTO"

        override fun read(node: JsonNode) =
                CalcDocumentResultDTO(
                        screenMessage = node["screenMessage"]?.string(),
                        maxPay = JsonFactory.readArray<MaxPayRecrod>(node["maxPay"]!!),
                        discont = JsonFactory.readArray<DiscontRecord>(node["discont"]!!),
                        newPositions = JsonFactory.readArray<NewPositionRecord>(node["newPositions"]!!),
                        state = node["state"]?.let { JsonFactory.readArray<BlockStateDTO>(it) },
                        success = node["success"]!!.boolean()
                )

        override fun write(obj: CalcDocumentResultDTO) =
                JsonNode.obj()
                        .set("screenMessage", obj.screenMessage?.json)
                        .set("maxPay", JsonFactory.writeArray(obj.maxPay))
                        .set("discont", JsonFactory.writeArray(obj.discont))
                        .set("newPositions", JsonFactory.writeArray(obj.newPositions))
                        .set("state", obj.state?.let { JsonFactory.writeArray(it) })
                        .set("success", obj.success.json)
    }

    class DiscontRecord(
            val positionId: String,
            val amount: Long
    ) : JDTO {
        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : JDTOFactory<DiscontRecord> {
            override val type: String
                get() = "DiscontRecord"

            override fun read(node: JsonNode) =
                    DiscontRecord(
                            positionId = node["positionId"]!!.string(),
                            amount = node["amount"]!!.long()
                    )

            override fun write(obj: DiscontRecord) =
                    JsonNode.obj()
                            .set("positionId", obj.positionId.json)
                            .set("amount", obj.amount.json)

        }
    }

    class MaxPayRecrod(
            val positionId: String,
            val amount: Long
    ) : JDTO {
        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : JDTOFactory<MaxPayRecrod> {
            override val type: String
                get() = "MaxPayRecrod"

            override fun read(node: JsonNode) =
                    MaxPayRecrod(
                            positionId = node["positionId"]!!.string(),
                            amount = node["amount"]!!.long()
                    )

            override fun write(obj: MaxPayRecrod) =
                    JsonNode.obj()
                            .set("positionId", obj.positionId.json)
                            .set("amount", obj.amount.json)

        }
    }

    class NewPositionRecord(
            val code: String,
            val count: Long
    ) : JDTO {
        override val factory: JDTOFactory<JDTO>
            get() = asDefault

        companion object : JDTOFactory<NewPositionRecord> {
            override val type: String
                get() = "NewPositionRecord"

            override fun read(node: JsonNode) =
                    NewPositionRecord(
                            code = node["code"]!!.string(),
                            count = node["count"]!!.long()
                    )

            override fun write(obj: NewPositionRecord) =
                    JsonNode.obj()
                            .set("code", obj.code.json)
                            .set("count", obj.count.json)

        }
    }
}