package org.tlsys.core.promotion.discountByTagsPromotion

import org.tlsys.JsonObject
import org.tlsys.addClass
import org.tlsys.admin.DiscountByTagsConfig
import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.OrderPromotionType
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.api.ShopsService
import org.tlsys.await
import org.tlsys.cms.click
import org.tlsys.core.promotion.BasePromotionDialog
import org.tlsys.core.promotion.OrderPromotionEditor
import org.tlsys.json.jdto
import org.tlsys.promotion.dto.TagFilterDtoWithShop
import org.tlsys.ui.*
import org.tlsys.userSelect
import pw.binom.web.layout.*

class DiscountByTagsPromotionDialog(record: OrderPromotionRecord?) :
    BasePromotionDialog(record, type.title) {
    private val shopsService by Services.byClass(ShopsService::class)
    private val memberTagService by Services.byClass(MemberTagService::class)
    private val params = record?.params?.getObject<DiscountByTagsConfig>()

    override suspend fun onInit() {
        super.onInit()
        if (params != null) {
            params.records.forEach { level ->
                list.add(RecordItem(level))
            }
            val tags =
                memberTagService.getTagList(
                    (params.filter.includeTags + params.filter.excludeTags).map { it.jdto }
                        .jdto(),
                ).await()
                    .associateBy { it.id }
            conditions.includeTags.content.addTag(
                params.filter.includeTags.map { tags[it]!! },
            )
            conditions.excludeTags.content.addTag(
                params.filter.excludeTags.map { tags[it]!! },
            )
            conditions.includeShops.content.addTag(
                params.filter.shops.mapNotNull { shopsService.getShop(it.jdto).await() },
            )
        }
    }

    private val removeTagsAfterApply = Checkbox("Удалять метки после применения")
        .appendTo(config.layout, grow = 0, shrink = 0)
        .checked(params?.removeTagsAfterApply ?: false)

    private val list = LevelEditList().appendTo(config.layout)

    companion object : OrderPromotionEditor {
        override val type: OrderPromotionType
            get() = OrderPromotionType.DISCOUNT_BY_TAGS

        override suspend fun show(record: OrderPromotionRecord?): OrderPromotionRecord? {
            val d = DiscountByTagsPromotionDialog(record)
            d.show()
            return d.result
        }
    }

    override val type: OrderPromotionType
        get() = OrderPromotionType.DISCOUNT_BY_TAGS

    override suspend fun buildConfig(): JsonObject =
        JsonObject.create(
            DiscountByTagsConfig(
                filter = TagFilterDtoWithShop(
                    includeTags = conditions.includeTags.content.list.map { it.tag.id },
                    excludeTags = conditions.excludeTags.content.list.map { it.tag.id },
                    shops = conditions.includeShops.content.list.map { it.id },
                ),
                records = list.items.map { it; it.record }.toList(),
                removeTagsAfterApply = removeTagsAfterApply.checked,
            ),
        )

    inner class LevelEditList : EditableList<RecordItem>() {
        override suspend fun createItemForAdd(): Item? {
            val record = RecordDialog.show(null) ?: return null
            return RecordItem(record)
        }

        override fun afterAdd(item: Item) {
            super.afterAdd(item)
            sort()
        }
    }

    inner class RecordItem(
        var record: DiscountByTagsConfig.Record,
    ) : EditableList.Item(), Comparable<RecordItem> {
        override val valid: Boolean
            get() = true

        fun refresh() {
            val value = when {
                record.discountAmount != null -> "${record.discountAmount}₽"
                record.discountPercent != null -> "${record.discountPercent!!.asFloat}%"
                else -> throw RuntimeException()
            }
            div.innerText = "Скидка $value"
        }

        override suspend fun isCanDeleteItem(): Boolean =
            YesNoDialog.show("Удалить уровень \"${div.innerText}\"", width = 500).await()

        init {
            div.style.cursor = "default"
            div.style.userSelect = "none"
            div.addClass(Styles.SIMPLE_TEXT)
            actionPanel.iconBtn(MaterialIcons.EDIT).click {
                val r = RecordDialog.show(record) ?: return@click
                record = r
                Effects.blockRow(dom)
                refresh()
                list.sort()
            }
            actionPanel.iconBtn(MaterialIcons.CONTENT_COPY).click {
                val r = RecordDialog.show(record) ?: return@click
                val newItem = RecordItem(r)
                list.add(newItem)
                Effects.blockRow(newItem.dom)
                list.sort()
            }
            refresh()
        }

        override fun compareTo(other: RecordItem): Int {
            val f = record.discountPercent?.asLong ?: record.discountAmount?.asLong ?: 0L
            val s = other.record.discountPercent?.asLong ?: other.record.discountAmount?.asLong ?: 0L
            return f.compareTo(s)
        }
    }
}
