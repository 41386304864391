package org.tlsys.admin

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.DateDuration
import org.tlsys.MoneyValue
import org.tlsys.Percent

@Serializable
data class DiscountByPayedWithLevelsConfig(
    val levels: List<Level>,
    @SerialName("include_tags")
    val includeTags: List<Long>,
    @SerialName("exclude_tags")
    val excludeTags: List<Long>,
    val shops: List<Long>,
) {
    @Serializable
    class Level(
        @SerialName("min_sum")
        val minSum: MoneyValue,
        @SerialName("self_percent")
        val selfPercent: Percent,
        @SerialName("use_partner_bonus")
        val usePartnerBonus: Boolean,
        val partner: BonusConfig,
    )

    @Serializable
    class BonusConfig(
        @SerialName("percent")
        val percent: Percent,
        val start: DateDuration,
        val end: DateDuration?,
    )
}
