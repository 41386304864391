package org.tlsys.login

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.*
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.ui.*
import org.w3c.dom.Window
import pw.binom.web.layout.*
import kotlin.js.Promise

class RegWindow private constructor(val regFunc: suspend (phone: String, password: String, companyName: String) -> String?) :
    TitleDialog("Регистрация") {

    init {
        dom.style.apply {
            width = 300.px
            height = 470.px
        }
    }

    private val reg = object : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {

        val name = EditText(placeHolder = "ФИО пользователя")
            .appendTo(layout, grow = 0, shrink = 0)
            .textValidator(TextValidators.NOT_BLANK)

        val phone = EditText(placeHolder = "Телефон")
            .appendTo(layout, grow = 0, shrink = 0)
            .textValidator(TextValidators.IS_PHONE and TextValidators.NOT_BLANK)

        init {
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        val companyName = EditText(placeHolder = "Название организации")
            .appendTo(layout, grow = 0, shrink = 0)
            .textValidator(TextValidators.NOT_BLANK)

        init {
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        val recapcha = RecapchaButton()
            .appendTo(layout, grow = 0, shrink = 0)

        override suspend fun onStart() {
            super.onStart()
            codeValidator.refresh()
        }
    }

    private inner class ValidationStep(phone: String, var reqiestId: String) :
        DivComponentWithLayout(direction = FlexLayout.Direction.Column) {

        init {
            Span("На телефон ${WebUtils.phoneFormat(phone)} отправлено сообщение с новым паролем от вашей учётной записи в личном кабинете TradeLine.")
                .appendTo(layout, grow = 0, shrink = 0)
                .addClass(Styles.SIMPLE_TEXT)
                .addClass(Styles.TEXT_CENTER)
            Span("Введите его")
                .addClass(Styles.SIMPLE_TEXT)
                .addClass(Styles.TEXT_CENTER)
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        val code = EditText(placeHolder = "Пароль")
            .appendTo(layout, grow = 0, shrink = 0)
            .textValidator(TextValidators.NOT_BLANK)

        init {
            DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        }

        private var c = 0
        private var timer = 0
        private val resend = BaseButton("Послать SMS снова").appendTo(layout, grow = 0, shrink = 0)

        init {
            code.eventValidChange.on {
                codeValidator.refresh()
            }

            resend.onClick {
                async2<Unit> {
                    try {
                        resend.enabled = false
                        val r = SessionService.registrationResend(
                            requestId = reqiestId,
                            recapchaToken = reg.recapcha.token!!,
                        )
                        if (r.requestId == null) {
                            resend.enabled = true
                            errorSpan.text = r.error
                                ?: "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                            errorSpan.displayShow()
                        } else {
                            resend.enabled = false
                            c = 60
                            resend.text = "Послать SMS снова ($c)"
                            reqiestId = r.requestId!!
                            timer = window.setInterval({
                                c--
                                if (c > 0) {
                                    resend.text = "Послать SMS снова ($c)"
                                } else {
                                    resend.text = "Послать SMS снова"
                                    resend.enabled = true
                                    window.clearInterval(timer)
                                }
                            }, 1000)
                        }
                    } catch (e: dynamic) {
                        errorSpan.text = "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                        errorSpan.displayShow()
                    }
                }
            }
        }

        override suspend fun onStart() {
            super.onStart()
            codeValidator.refresh()
        }
    }

    private val view = ComponentView().appendTo(layout)

    private val errorSpan = Span()
        .addClass(Styles.ERROR)
        .addClass(Styles.TEXT_CENTER)
        .also {
            it.dom.style.paddingBottom = 20.px
        }
        .appendTo(layout, grow = 0, shrink = 0)
        .displayHide()

    init {
        Span().appendTo(layout, grow = 0, shrink = 0).also {
            it.addClass(Styles.TEXT_CENTER)
            document.createSpan("Регистрируясь в сервисе ТрейдЛайн, вы принимаете ")
                .addClass(Styles.SIMPLE_TEXT)
                .appendTo(it.dom)
            document.createLink(
                text = "условия договора-оферты.",
                href = "https://tlsys.ru/wp-content/uploads/2019/09/Oferta.pdf",
            )
                .addClass(Styles.LINK)
                .appendTo(it.dom)
                .also {
                    it.target = "_blank"
                }
        }
        DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
    }

    private val btn = ConfirmButton("Зарегистрироваться").appendTo(layout, grow = 0, shrink = 0)

    init {
        DivLayout().appendTo(layout, grow = 0, shrink = 1, basis = 20)
        Span().appendTo(layout, grow = 0, shrink = 0).also {
            it.addClass(Styles.TEXT_CENTER)
            document.createSpan("Уже зарегистрированы? ")
                .addClass(Styles.SIMPLE_TEXT)
                .appendTo(it.dom)
            document.createSpan("Войдите")
                .addClass(Styles.LINK)
                .appendTo(it.dom)
                .on("click") {
                    async2 {
                        close()
                        showLogin()
                    }
                }
        }
    }

    private val capchaValidator = Validated {
        if (view.view === reg) {
            reg.recapcha.checked
        } else {
            true
        }
    }.attach(reg.recapcha.EVENT_CHANGED)

    private val codeValidator = Validated {
        val f = view.view
        if (f is ValidationStep) {
            f.code.valid
        } else {
            true
        }
    }

    private val validator = MultiValidator(reg.name, reg.phone, reg.companyName, capchaValidator, codeValidator)

    init {
        validator.onValidChange {
            btn.enabled = it
        }
        view.set2(reg)
        btn.onClick {
            val v = view.view
            when {
                v === reg -> {
                    ProcessBlocker.block<Unit>("Валидация") {
                        errorSpan.displayHide()
                        try {
                            val res = SessionService.registration(
                                phone = WebUtils.getPhone(reg.phone.text),
                                userName = reg.name.text,
                                companyName = reg.companyName.text,
                                recapchaToken = reg.recapcha.token!!,
                            )
                            val requestId = res.requestId
                            if (requestId == null) {
                                errorSpan.text = res.error
                                    ?: "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                                errorSpan.displayShow()
                            } else {
                                view.set2(
                                    ValidationStep(
                                        phone = WebUtils.getPhone(reg.phone.text),
                                        reqiestId = requestId,
                                    ),
                                )
                            }
                        } catch (e: dynamic) {
                            errorSpan.text = "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                            errorSpan.displayShow()
                            console.error("Reg Error:", e)
                        }
                    }
                }

                v is ValidationStep -> {
                    ProcessBlocker.block<Unit>("Валидация") {
                        try {
                            val ss = SessionService.registrationVerify(
                                code = v.code.text,
                                requestId = v.reqiestId,
                                recapchaToken = reg.recapcha.token!!,
                            )
                            if (ss.session == null) {
                                errorSpan.text = ss.error
                                    ?: "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                                errorSpan.displayShow()
                            } else {
                                close()
                                console.info("Компания Успешно зарегестрированна! Обновите страницу чтобы войти")
                                SuccessWindow.show(
                                    title = "Регистрация",
                                    message = "Вы успешно зарегистрировались!",
                                )
                            }
                        } catch (e: dynamic) {
                            console.error("Error:", e)
                            errorSpan.text = "Произошла неизвестная ошибка. Пожалуйста, повторите операцию позже."
                            errorSpan.displayShow()
                        }
                    }
                }
            }
        }
    }

    companion object {
        suspend fun show(regFunc: suspend (phone: String, password: String, companyName: String) -> String?) {
            RegWindow(regFunc).show()
        }
    }
}

/**
 * Публичный ключ 6LcPuNUZAAAAAKo4WDskUnTnYu_rbApqqSabTWY8
 * Секретный ключ 6LcPuNUZAAAAAK_CMFlgN1sCWqueJZT05Th9WUI7
 */
class RecapchaButton : Checkbox("Я не робот") {
    var token: String? = null
        private set

    init {
        listenClickEvents = false
        dom.on("click") { e ->
            e.preventDefault()
            if (token != null) {
                return@on
            }
            window.grecaptcha.ready {
                async2 {
                    try {
                        val token = window.grecaptcha.execute(
                            "6LcPuNUZAAAAAKo4WDskUnTnYu_rbApqqSabTWY8",
                            js("({action: 'submit'})"),
                        ).await()
                        this.token = token
                        checked = true
                    } catch (e: dynamic) {
                        console.error("ReCaptcha Error", e)
                    }
                }
            }
        }
    }
}

external class Grecaptcha {
    fun ready(func: () -> Unit)
    fun execute(api: String, params: dynamic): Promise<String>
}

val Window.grecaptcha: Grecaptcha
    get() = window.asDynamic()["grecaptcha"]
