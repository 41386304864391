package org.tlsys.core.promotion.addBonus

import org.tlsys.JsonObject
import org.tlsys.admin.AddBonusPromotionConfig
import org.tlsys.admin.OrderPromotionRecord
import org.tlsys.admin.OrderPromotionType
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.Validated
import org.tlsys.admin.ui.Checkbox
import org.tlsys.api.MemberTagService
import org.tlsys.api.ShopsService
import org.tlsys.core.promotion.BasePromotionDialog
import org.tlsys.core.promotion.BonusConfigEditor
import org.tlsys.core.promotion.OrderPromotionEditor
import org.tlsys.json.jdto
import pw.binom.web.layout.appendFirstTo
import pw.binom.web.layout.appendTo

class AddBonusPromotionDialog(record: OrderPromotionRecord?) : BasePromotionDialog(record, type.title) {
    override val type: OrderPromotionType
        get() = Companion.type

    private val params = record?.params?.getObject<AddBonusPromotionConfig>()
    private val memberTagService by Services.byClass(MemberTagService::class)
    private val shopsService by Services.byClass(ShopsService::class)

    private val applyOnBonusPayGone = Checkbox(label = "Только если отсутствует оплата бонусами")
        .checked(params?.applyOnBonusPayGone ?: false)
        .appendFirstTo(conditions.layout, grow = 0, shrink = 0)

    private val bonus =
        BonusConfigEditor.AmountEditor(canBeEmpty = false)
            .value(params?.bonus)
            .appendTo(config.layout, grow = 0, shrink = 0)

    init {
        validator.addValidated(Validated(bonus) { bonus.value2 != null })
    }

    override suspend fun buildConfig(): JsonObject = JsonObject.create(
        AddBonusPromotionConfig(
            includeTags = conditions.includeTags.content.list.map { it.tag.id },
            excludeTags = conditions.excludeTags.content.list.map { it.tag.id },
            bonus = bonus.value2!!,
            shops = conditions.includeShops.content.list.map { it.id },
            applyOnBonusPayGone = applyOnBonusPayGone.checked,
        ),
    )

    override suspend fun onInit() {
        if (params != null) {
            val tags =
                memberTagService.getTagList((params.includeTags + params.excludeTags).map { it.jdto }.jdto())
                    .await()
                    .associateBy { it.id }
            conditions.includeTags.content.addTag(
                params.includeTags.map { tags[it]!! },
            )
            conditions.excludeTags.content.addTag(
                params.excludeTags.map { tags[it]!! },
            )
            conditions.includeShops.content.addTag(
                params.shops.mapNotNull { shopsService.getShop(it.jdto).await() },
            )
        }
    }

    companion object : OrderPromotionEditor {
        override val type: OrderPromotionType
            get() = OrderPromotionType.ADD_BONUS

        override suspend fun show(record: OrderPromotionRecord?): OrderPromotionRecord? {
            val d = AddBonusPromotionDialog(record)
            d.show()
            return d.result
        }
    }
}
