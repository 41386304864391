package org.tlsys.core.promotion.externalScriptPromotion

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.*
import org.tlsys.core.Closeable
import org.tlsys.styles.ExternalScript
import org.tlsys.ui.once
import org.w3c.dom.HTMLIFrameElement
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event
import pw.binom.url.URI
import pw.binom.web.AbstractComponent
import kotlin.coroutines.Continuation
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.coroutines.suspendCoroutine
import kotlin.time.Duration
import kotlin.time.ExperimentalTime

class ExternalScriptException(args: Any?) : RuntimeException()

@OptIn(ExperimentalTime::class)
abstract class ExternalUIImpl :
    AbstractComponent<HTMLIFrameElement>(),
    ExternalUI<HTMLIFrameElement> {
    override val dom: HTMLIFrameElement = document.createElement("iframe").unsafeCast<HTMLIFrameElement>()
    private var lastUri: URI? = null
    override val uri: URI?
        get() = lastUri // dom.src.takeIf { it.isNotEmpty() }?.toURIOrNull

    protected abstract val baseTimeout: Duration
    private var loading = false
    private var loaded = false

    init {
        dom.sandbox.add("allow-scripts")
        dom.sandbox.add("allow-popups")
        dom.sandbox.add("allow-forms")
        dom.sandbox.add("allow-modals")
        dom.addClass(ExternalScript.IFRAME_STYLE)
    }

    private fun messageListener(event: Event) {
        event as MessageEvent
        if (event.source !== dom.contentWindow) {
//            console.info("TL: Found message from invalid window", event.data, event.target)
            return
        }
        console.info("TL: Message received", event.data)
        val msg = event.data.unsafeCast<ExchangeMessage>()
        val rq = msg.rq
        when (msg.type) {
            MessageTypes.RESPONSE_OK.code -> {
                if (rq == null) {
                    console.warn("TL: Got invalid response message. Type ${msg.type} and rq is lost")
                    return
                }
                val waiter = waiters.remove(rq)
                if (waiter == null) {
                    console.warn("TL: Got message, but can't find suspend method. rq=$rq")
                    return
                }
                console.info("resume $rq using", msg)
                waiter.resume(msg.args)
            }

            MessageTypes.RESPONSE_FAIL.code -> {
                if (rq == null) {
                    console.warn("TL: Got invalid response message. Type ${msg.type} and rq is lost")
                    return
                }
                val waiter = waiters.remove(rq)
                if (waiter == null) {
                    console.warn("TL: Got message, but can't find suspend method. rq=$rq")
                    return
                }
                waiter.resumeWithException(ExternalScriptException(msg.args))
            }

            MessageTypes.EVENT.code -> {
                val externalMethod = ExternalUiMethods.find(msg.method)
                if (externalMethod == null) {
                    console.warn("TL: Can't find methpd ${msg.method}")
                    return
                }
                async2 {
                    event(method = externalMethod, args = msg.args)
                }
            }

            MessageTypes.REQUEST.code -> {
                if (rq == null) {
                    console.warn("TL: Got invalid request message. Type ${msg.type} and rq is lost")
                    return
                }
                val externalMethod = ExternalUiMethods.find(msg.method)
                if (externalMethod == null) {
                    console.warn("TL: Can't find methpd ${msg.method}")
                    return
                }
                async2 {
                    val msgResp: ExchangeMessage = js("({})").unsafeCast<ExchangeMessage>()
                    msgResp.rq = rq
                    try {
                        msgResp.args = calling(method = externalMethod, args = msg.args)
                        msgResp.type = MessageTypes.RESPONSE_OK.code
                    } catch (e: dynamic) {
                        msgResp.type = MessageTypes.RESPONSE_FAIL.code
                        msgResp.args = e
                    }
                    console.info("TL: Send response")
                    dom.contentWindow!!.postMessage(msgResp, "*")
                }
            }
        }
    }

    protected abstract suspend fun calling(method: ExternalUiMethods, args: Any?): Any?
    protected abstract suspend fun event(method: ExternalUiMethods, args: Any?)

    private val postMessageListener: (Event) -> Unit = {
        messageListener(it)
    }

    override suspend fun onStart() {
        super.onStart()
        window.addEventListener("message", postMessageListener)
        console.info("dom.isOnDocument=${dom.isOnDocument}")
        console.info("Starting the frame. lastUri=$lastUri, loaded=$loaded")
        if (lastUri != null && !loaded) {
            console.info("page set but frame not loaded")
            loadPage(lastUri!!)
        }

        if (loaded) {
            timeout(baseTimeout) {
                call(ExternalUiMethods.START, null)
            }
        }
    }

    override suspend fun onStop() {
//        if (loaded) {
//            call(ExternalUiMethods.STOP, null)
//        }
        console.info("TL: Stop the frame!")
        stopInternal()
        console.info("TL: listener removed!")
        window.removeEventListener("message", postMessageListener)
        super.onStop()
    }

    protected open suspend fun afterInit(): Boolean = true

    private val waiters = HashMap<Int, Continuation<Any?>>()
    private var rqIt = 0

    override suspend fun dispatch(method: ExternalUiMethods, args: Any?) {
        if (loaded) {
            return
        }
        val msg: ExchangeMessage = js("({})").unsafeCast<ExchangeMessage>()
        msg.type = MessageTypes.EVENT.code
        msg.method = method.code
        msg.args = args
        console.info("TL: Dispatch ${msg.method}", args)
        dom.contentWindow!!.postMessage(msg, "*")
    }

    override suspend fun call(method: ExternalUiMethods, args: Any?): Any? {
        if (!loaded) {
            return null
        }
        return suspendCoroutine {
            val id = rqIt++
            val msg: ExchangeMessage = js("({})").unsafeCast<ExchangeMessage>()
            msg.rq = id
            msg.type = MessageTypes.REQUEST.code
            msg.method = method.code
            msg.args = args
            waiters[id] = it
            console.info("TL: Calling ${msg.method}. rq=$id", args)
            dom.contentWindow!!.postMessage(msg, "*")
        }
    }

    override suspend fun loadPage(url: URI): Boolean {
        if (loading) {
            throw IllegalStateException("IFrame already trying to load page")
        }
        loading = true
        try {
            timeout(baseTimeout) {
                suspendCoroutine<Unit> { con ->
                    var ok: Closeable? = null
                    var fail: Closeable? = null
                    ok = dom.once("load") {
                        fail?.close()
                        con.resume(Unit)
                    }
                    fail = dom.once("error") {
                        ok.close()
                        con.resumeWithException(RuntimeException("Can't load iframe from $url"))
                    }
                    console.info("Setting uri: ${url.toString()}")
                    dom.src = url.toString()
                }
            }
        } catch (e: dynamic) {
            clearIFrame()
            return false
        }
        loaded = true
        loading = false
        if (!afterInit()) {
            clearIFrame()
            return false
        }
        try {
            timeout(baseTimeout) {
                if (isStarted) {
                    call(ExternalUiMethods.START, null)
                }
            }
        } catch (e: dynamic) {
            clearIFrame()
            return false
        }
        lastUri = url
        return true
    }

    protected open fun clearIFrame() {
        dom.src = ""
        loading = false
        loaded = false
    }

    private suspend fun stopInternal() {
        if (isStarted && loaded) {
            try {
                timeout(baseTimeout) {
                    call(ExternalUiMethods.STOP, null)
                }
            } catch (e: dynamic) {
                // Do nothing
            }
        }
        clearIFrame()
    }

    override suspend fun stop() {
        lastUri = null
        stopInternal()
    }
}
