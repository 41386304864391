package org.tlsys.api

import org.tlsys.AutoLoadAsyncListIterator
import org.tlsys.async2
import org.tlsys.core.*
import org.tlsys.dto.DTOShop
import org.tlsys.json.jdto

fun ShopsService.getShopsIterator(minForLoad: Int, maxPart: Int) = AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
    async2 { getShops(offset = offset.toInt().jdto, max = max.jdto).await().toList() }
})

fun ShopsService.getTerminalsIterator(shopId: Long, minForLoad: Int, maxPart: Int) = AutoLoadAsyncListIterator(minFull = minForLoad, max = maxPart, loader = { max, offset ->
    async2 { getTerminals(shopId = shopId.jdto, offset = offset.toInt().jdto, max = max.jdto).await() }
})

fun ShopsService.shopTerminalIterator(minForLoad: Int, maxPart: Int) =
        this.getShopsIterator(minForLoad = minForLoad, maxPart = maxPart)
                .map<DTOShop, AsyncIterator<Any>> {
                    asyncIteratorOf<Any>(it) + (this.getTerminalsIterator(minForLoad = minForLoad, maxPart = maxPart, shopId = it.id) as AsyncIterator<Any>)
                }
                .flat()