package org.tlsys.gui

import kotlinx.browser.document
import org.tlsys.admin.core.PageNavigation
import org.tlsys.admin.core.Services
import org.tlsys.async2
import org.tlsys.core.Closeable
import org.tlsys.css.animate
import org.tlsys.navigation.urlWithParams
import org.tlsys.ui.*
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import pw.binom.web.AbstractComponent

class BreadCrumbs : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    class BreadItem(val page: Page) : AbstractComponent<HTMLAnchorElement>(), Closeable {
        override val dom: HTMLAnchorElement = document.createLink()

        override fun close() {
        }

        init {
            async2 {
                dom.innerText = page.getTitle()
            }
        }

        var href: String
            get() = dom.href
            set(value) {
                dom.href = value
            }
    }

    init {
        dom.classList.add("breadCrumbsBackground")
    }

    private val navigation by Services.byClass(PageNavigation::class)

    private fun setNewPath(list: List<Go>) {
        CYCL@ for (g in list) {
            when (g) {
                is GoUp -> {
                    val p = (dom.lastChild ?: continue@CYCL) as HTMLElement
                    val item = p.con() as BreadItem
                    item.close()
                    dom.removeChild(p)
                }

                is GoIn -> {
                    val p = BreadItem(g.page).dom
                    dom.appendChild(p)
                    val url = navigation.getUrl(g.page)!!
                    p.href = "#${urlWithParams(url, g.params)}"

                    p.animate {
                        function = "ease-in"
                        duration = 100
                        init {
                            position = "relative"
                        }
                        start {
                            opacity = 0.0
                            left = "-10px"
                        }
                        end {
                            opacity = 1.0
                            left = "0px"
                        }
                        onEnd {
                            p.style.removeProperty("position")
                        }
                    }.start()
                }
            }
        }
    }

    fun newPath(): PathBuilder = PathBuilder(this)

    private interface Go
    private class GoUp : Go
    private class GoIn(val page: Page, val params: Map<String, String?>) : Go

    class PathBuilder(val parent: BreadCrumbs) {
        private val list = ArrayList<Go>()
        fun up() {
            list += GoUp()
        }

        fun goIn(page: Page, params: Map<String, String?>) {
            list += GoIn(page, params)
        }

        fun build() {
            parent.setNewPath(list)
        }
    }
}
