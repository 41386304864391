package org.tlsys.core.promotion

import org.tlsys.admin.ui.ShopsPanel
import org.tlsys.admin.ui.withLabel
import org.tlsys.promotion.dto.TagFilterDtoWithShop
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class ConditionsTabWithShops : ConditionsTab() {
    public override val layout: FlexLayout<HTMLDivElement>
        get() = super.layout
    val includeShops =
        ShopsPanel(readOnly = false)
            .withLabel("Включить для торговых объектов")
            .appendTo(layout, grow = 0, shrink = 0)

    val tagFilterWithShops: TagFilterDtoWithShop
        get() = TagFilterDtoWithShop(
            includeTags = includeTags.content.list.map { it.tag.id },
            excludeTags = excludeTags.content.list.map { it.tag.id },
            shops = includeShops.content.list.map { it.id },
        )
}
