package org.tlsys.admin.cross

import org.tlsys.WGoodElement
import org.tlsys.admin.serialization.RList
import pw.binom.rpc.CrossService

interface CrossGoodsService : CrossService {
    override val name: String
        get() = "good_service"

    val getByCodes: CrossService.CrossMethod<RList<WGoodElement>>
}