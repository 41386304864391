package org.tlsys.lex

interface DTOExpression {

    class DTOContain(val text: String) : DTOExpression {
        override fun asString(): String = "\"$text\""
    }

    class DTOStartWith(val text: String) : DTOExpression {
        override fun asString(): String = "^\"$text\""
    }

    class DTOEndWith(val text: String) : DTOExpression {
        override fun asString(): String = "\$\"$text\""
    }

    class DTOUnion(val value: DTOExpression) : DTOExpression {
        override fun asString(): String = "(${value.asString()})"
    }

    class DTOAnd(val left: DTOExpression, val right: DTOExpression) : DTOExpression {
        override fun asString(): String = "${left.asString()}&${right.asString()}"
    }

    class DTOOr(val left: DTOExpression, val right: DTOExpression) : DTOExpression {
        override fun asString(): String = "${left.asString()}|${right.asString()}"
    }

    class DTONot(val value: DTOExpression) : DTOExpression {
        override fun asString(): String = "!${value.asString()}"

    }

    fun asString(): String
}