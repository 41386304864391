package org.tlsys.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import org.tlsys.DateDuration

object DateDurationSerializer : KSerializer<DateDuration> {
    override fun deserialize(decoder: Decoder): DateDuration =
        DateDuration(decoder.decodeLong())

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("DateDuration", PrimitiveKind.LONG)

    override fun serialize(encoder: Encoder, value: DateDuration) {
        encoder.encodeLong(value.asLong)
    }
}
