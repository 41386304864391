package org.tlsys.frontol

import org.tlsys.admin.ui.EditableList
import org.tlsys.admin.ui.SaveCancelBody
import org.tlsys.admin.ui.TitleDialog
import org.tlsys.async2
import org.tlsys.px
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class PrifixEditDialog(prefixList: List<String>) : TitleDialog(title = "Префиксы карт") {

    init {
        dom.style.width = 300.px
        dom.style.height = 400.px
    }

    val body = SaveCancelBody(cancelText = "Закрыть").appendTo(super.layout)

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    val list2 = EditableList<EditableList.TextItem>().appendTo(layout, grow = 1, shrink = 1)
    override fun onEscapeClick() {
        super.onEscapeClick()
        close()
    }

    var caneled = false

    init {
        body.ok {
            caneled = false
            close()
        }

        body.cancel {
            caneled = true
            close()
        }
        async2 {
            prefixList.forEach { list2.add(it) }
        }
    }

    companion object {
        suspend fun show(prefixList: List<String>): List<String>? {
            val dd = PrifixEditDialog(prefixList)
            dd.show()
            return if (dd.caneled) {
                null
            } else {
                dd.list2.values
            }
        }
    }
}
