package org.tlsys.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*
import org.tlsys.styles.Card
import org.w3c.dom.HTMLDivElement

@Composable
fun Card(attrs: AttrBuilderContext<HTMLDivElement>? = null, func: @Composable ElementScope<HTMLDivElement>.() -> Unit) {
    Div(attrs = {
        classes(Card.MAIN)
        attrs?.invoke(this)
    }) {
        func()
    }
}

@Composable
fun Card(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    label: String,
    func: @Composable ElementScope<HTMLDivElement>.() -> Unit,
) {
    Card(attrs = attrs) {
        Label(attrs = { classes(Card.TITLE) }) { Text(label) }
        func()
    }
}
