package org.tlsys.jobs.dto

import org.tlsys.json.*

class ExecutionDTO(val id: JString,
                   val jobId: JString,
                   val start: JLong,
                   val end: JLong?,
                   val processId: JString,
                   val files: JList<JString>
) : JDTO {
    protected constructor() : this(JString.EMPTY, JString.EMPTY, 0L.jdto, null, JString.EMPTY, JList.empty())

    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : AutoJDTOFactory<ExecutionDTO>(clazz = ExecutionDTO::class, type = "executionDTO")
}