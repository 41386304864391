package org.tlsys.members

import libs.asDateString
import libs.asDateUtc
import libs.asTimeString
import org.tlsys.admin.ui.*
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.core.clientImport.unfix
import org.tlsys.documents.RemoteDocumentServiceImpl
import org.tlsys.documents.dialogs.DocumentView
import org.tlsys.documents.dialogs.Param
import org.tlsys.dto.TransactionDTO
import org.tlsys.json.jdto
import org.tlsys.ui.addClass
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class TransactionDescriptionDialog(val transaction: TransactionDTO) : TitleDialog("Описание транзакции") {
    private val body = SaveCancelBody(okText = "Закрыть", cancelVisible = false).appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val info = ElementGroup("Информация").appendTo(layout, grow = 0, shrink = 0)
    private val description = Span(transaction.description ?: "")
        .addClass(Styles.SIMPLE_TEXT)
        .withLabel("Описание")
        .appendTo(layout)

    init {
        Param("Дата", transaction.date.asDateUtc.let { "${it.asDateString} ${it.asTimeString}" })
            .appendTo(info.layout, grow = 0)
        transaction.documentNumber
        val delta = Param(
            key = "Изменение",
            value = transaction.delta.unfix(2).toString(),
            valueColor = if (transaction.delta >= 0) {
                "#258627"
            } else {
                "#d1021b"
            },
        )
            .appendTo(info.layout, grow = 0)

        if (transaction.documentNumber != null) {
            val document by lazy {
                async2 {
                    val id = transaction.document ?: return@async2 null
                    RemoteDocumentServiceImpl.getDocument(id.jdto).await()
                }
            }

            val doc = Param("Документ", "№${transaction.documentNumber}")
                .appendTo(info.layout, grow = 0)
            doc.dom.onclick = {
                async2 {
                    DocumentView.show(document.await()!!).await()
                }
            }
            doc.dom.style.color = Styles.LINK_COLOR
            doc.dom.style.cursor = "pointer"
        }
        body.ok {
            close()
        }
    }

    companion object {
        fun show(transaction: TransactionDTO) = async2 {
            val d = TransactionDescriptionDialog(transaction)
            d.show()
        }
    }
}
