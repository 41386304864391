package org.tlsys.security

import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.GroupService
import org.tlsys.await
import org.tlsys.dto.auth.GroupDTO
import org.tlsys.json.jdto
import org.tlsys.ui.*
import org.tlsys.ui.DivComponentWithLayout
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

/**
 * Created by Ермаков Игорь Александрович (email: igor.yermakov94@yandex.ru) on 16.08.2017.
 */
class GroupFragment : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    companion object {
        val NAME = "Группы"
    }

    private val groupService by Services.byClass(GroupService::class)

    fun add(group: GroupDTO) {
        val item = Item(group).appendFirstTo(table)
        Effects.createAndBlankItem(item.dom)
    }

    private val scroll = ScrollController(dom)
    private val table = MaterialTable()
        .appendTo(layout, grow = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Название").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    override suspend fun onInit() {
        super.onInit()
        groupService.getGroups(0.jdto, (-1).jdto).await().forEach {
            Item(it).appendTo(table)
        }
    }

    private inner class Item(var group: GroupDTO) : MaterialTable.Row() {
        val name = MaterialTable.LinkColumn(group.name, "#/settings/permissions/g${group.id}").appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace())
            .appendTo(this)

        init {
            actions.component.visibleOnHover(this)
            actions.component.iconBtn(MaterialIcons.EDIT).onClick {
                val d = AddGroupDialog.show(group) ?: return@onClick
                group = groupService.setGroupName(
                    id = group.id.jdto,
                    name = d.name.text.jdto,
                ).await()
                name.text = group.name
                Effects.blockRow(dom)
            }
            actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                groupService.removeGroup(group.id.jdto)
                Effects.removeItem(dom).await()
                removeSelf()
            }
        }
    }
}
