package org.tlsys.core.promotion.tagsByPaymentPromotion

import org.tlsys.admin.TagsByPaymentConfig
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.DoubleValidator
import org.tlsys.admin.form.MultiValidator
import org.tlsys.admin.form.TextValidators
import org.tlsys.admin.form.and
import org.tlsys.admin.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.toMoneyValue
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*

class RecordDialog(val record: TagsByPaymentConfig.Record?) : TitleDialog("Запись") {
    private val body = SaveCancelBody().appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private var result: TagsByPaymentConfig.Record? = null
    private val memberTagService by Services.byClass(MemberTagService::class)

    init {
        dom.style.width = 550.px
    }

    private val amount = EditText(placeHolder = "Сумма накоплений").textValidator(
        TextValidators.NOT_BLANK
            and DoubleValidator.FORMAT
            and DoubleValidator.forMaxAfterPoint(2) and DoubleValidator.moreOrEquals2(0.0),
    ).text(record?.amount?.toString() ?: "0")
        .appendTo(layout, grow = 1, shrink = 1)

    private val validator = MultiValidator(amount)

    override suspend fun onInit() {
        super.onInit()
        if (record != null) {
            val allTags = record.enableTags + record.disableTags
            val allTagsEntity = memberTagService.getTagList(allTags.map { it.jdto }.jdto()).await()
                .associateBy { it.id }

            record.enableTags.mapNotNull { allTagsEntity[it] }
                .forEach {
                    enableTags.content.addTag(it)
                }

            record.disableTags.mapNotNull { allTagsEntity[it] }
                .forEach {
                    disableTags.content.addTag(it)
                }
        }
    }

    private val enableTags = TagPanel(readOnly = false)
        .withLabel("Добавить метки")
        .appendTo(layout, grow = 0, shrink = 0)

    private val disableTags = TagPanel(readOnly = false)
        .withLabel("Удалить метки")
        .appendTo(layout, grow = 0, shrink = 0)

    override fun onEscapeClick() {
        result = null
        close()
    }

    init {
        validator.onValidChange { body.okBtn.enabled = it }
        body.ok {
            result = TagsByPaymentConfig.Record(
                enableTags = enableTags.content.list.map { it.tag.id },
                disableTags = disableTags.content.list.map { it.tag.id },
                amount = amount.text.toFloat().toMoneyValue,
            )
            close()
        }
        body.cancel {
            result = null
            close()
        }
    }

    companion object {
        suspend fun show(record: TagsByPaymentConfig.Record?): TagsByPaymentConfig.Record? {
            val d = RecordDialog(record)
            d.show()
            return d.result
        }
    }
}
