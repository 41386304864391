package org.tlsys.goods.dialogs

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.*
import org.tlsys.admin.ui.*
import org.tlsys.goods.Charsets
import org.tlsys.goods.DTODictionary
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.*
import kotlin.js.Promise

class EditDictionary private constructor(val dictionary: DTODictionary?) :
    TitleDialog(if (dictionary == null) "Новый список" else "Редактирование списка") {
    private val body = SaveCancelBody(okText = if (dictionary == null) "Создать" else "Изменить")
        .appendTo(super.layout, grow = 1, shrink = 1)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    private val nameInp =
        EditText(placeHolder = "Имя списка")
            .text(dictionary?.name ?: "")
            .textValidator(TextValidators.NOT_BLANK and TextValidators.maxLength(255))
            .appendTo(layout, grow = 0, shrink = 0)

    private val autoImport = Checkbox("Авто-импорт")
        .appendTo(layout, grow = 0, shrink = 0)

    private val imporUrl = EditText(placeHolder = "Источник").text(dictionary?.importUrl ?: "")
        .textValidator(ProtocolValidators.ALL)
        .appendTo(layout, grow = 0, shrink = 0)
    private val codeNormalization = Checkbox("Нормализация кодов")
        .appendTo(layout, grow = 0, shrink = 0)
    private val charsetSelector = Combobox2(
        items = Charsets.values().map { it.title },
        select = dictionary?.charset?.let { Charsets.findByCode(it) }?.ordinal ?: 0,
    )
        .withLabel("Кодировка")
        .appendTo(layout, grow = 0, shrink = 0)

    val dynValid = Validated {
        !autoImport.checked || imporUrl.valid
    }
    private val validator = MultiValidator(nameInp, dynValid)

    private var canelled = false

    init {

        autoImport.EVENT_CHANGED.on {
            imporUrl.enabled = autoImport.checked
            dynValid.refresh()
        }

        imporUrl.eventChange.on {
            dynValid.refresh()
        }

        autoImport.checked = dictionary?.autoImport ?: false
        imporUrl.text = dictionary?.importUrl ?: ""
        codeNormalization.checked = dictionary?.codeNormalization ?: false

        dom.style.apply {
            width = 300.px
            height = 330.px
        }
        body.okBtn.onClick {
            canelled = false
            close()
        }

        body.cancelBtn.onClick {
            canelled = true
            close()
        }

        validator.onValidChange {
            body.okBtn.enabled = it
        }

        nameInp.eventEnter.on {
            if (validator.valid) {
                canelled = false
                close()
            }
        }

        imporUrl.eventEnter.on {
            if (validator.valid) {
                canelled = false
                close()
            }
        }
    }

    override suspend fun onStart() {
        super.onStart()
        nameInp.dom.focus()
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        canelled = true
        close()
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        fun show(dictionary: DTODictionary?) = Promise<DTODictionary?> { d, c ->
            val dd = EditDictionary(dictionary)
            layoutNavigator.show(dd) {
                if (dd.canelled) {
                    d(null)
                } else {
                    d(
                        DTODictionary(
                            name = dd.nameInp.text,
                            id = dictionary?.id ?: "",
                            importUrl = dd.imporUrl.text,
                            autoImport = dd.autoImport.checked,
                            charset = Charsets.values()[dd.charsetSelector.content.select].code,
                            codeNormalization = dd.codeNormalization.checked,
                        ),
                    )
                }
            }
        }
    }
}
