package org.tlsys.admin.ui

import org.tlsys.admin.events.EventElement
import org.tlsys.admin.form.*
import org.tlsys.core.Closeable
import org.tlsys.ui.on
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import kotlinx.browser.document
import kotlinx.dom.addClass
import pw.binom.web.AbstractComponent

/**
 * Абстрактный класс поля ввода. Работает на основе дом узла "input" с типом "text" или "password"
 *
 * @param text начальное значение компонента
 * @param password флаг, означающий, что значение этого поля должно отображаться как пароль
 * @param placeHolder текст, отображаемый в поле, если текущее значение не установленно
 */
abstract class InputText<T>(text: String = "", placeHolder: String = "", password: Boolean = false) : AbstractComponent<HTMLInputElement>(), MutableValidated<T>, ValidatedController<HTMLInputElement> {
    override val dom: HTMLInputElement = document.createElement("input").unsafeCast<HTMLInputElement>()

    protected val textValidator = SimpleValidator<String>()
    protected val valueValidator = SimpleValidator<T>()
    protected val totalValidator = MultiValidator(textValidator, valueValidator)
    protected abstract fun convertText(text: String): T?
    protected abstract fun convertValue(value: T): String

    init {
        dom.addClass("text")
        dom.addClass("browser-default")
        dom.setAttribute("autocomplete", "off")
        dom.setAttribute("autocapitalize", "off")
    }

    override fun onValidChange(listener: (Boolean) -> Unit): Closeable = totalValidator.onValidChange(listener)
    fun onEnter(l: () -> Unit) = dom.on("keyup") { it: Event ->
        val keyCode = it.asDynamic().keyCode as Int
        if (keyCode == 27 || keyCode == 13)
            l()
    }

    override fun addValidator(validator: Validator<T>) = run {
        val d = valueValidator.addValidator(validator)
        onTextChanged()
        d
    }

    fun addTextValidator(validator: Validator<String>) = run {
        val d = textValidator.addValidator(validator)
        onTextChanged()
        d
    }

    private val validListener: ValidListener = {
        if (it) {
            dom.classList.add("valid")
            dom.classList.remove("invalid")
        } else {
            dom.classList.remove("valid")
            dom.classList.add("invalid")
        }

        dom.dispatchEvent(Event("valid"))
    }

    init {
        totalValidator.onValidChange(validListener)
    }


    private val eventListener: (Event) -> dynamic = {
        onTextChanged()
        undefined
    }

    init {
        dom.classList.add("validateble")
        dom.addEventListener("keyup", eventListener)
        dom.addEventListener("keydown", eventListener)
        dom.addEventListener("change", eventListener)
    }

    var value: T?
        get() = if (valid) convertText(text) else null
        set(it) {
            if (it == null) {
                text = ""
                onTextChanged()
            } else {
                text = convertValue(it)
                onTextChanged()
            }
        }

    var text: String
        get() = dom.value
        set(it) {
            dom.value = it
            onTextChanged()
        }

    val event_CHANGE = EventElement()

    fun forceRefreshValid() {
        val g = convertText(text)
        if (g == null) {
            valueValidator.forceSetValid(true)
        } else {
            val b2 = valueValidator.updateValue(g)
        }
        val b1 = textValidator.updateValue(text)
    }

    protected fun onTextChanged() {
        forceRefreshValid()
        event_CHANGE.dispatch()
    }

    override val valid: Boolean
        get() = totalValidator.valid

    var placeHolder: String
        get() = dom.placeholder
        set(it) {
            dom.placeholder = it
        }

    var password: Boolean
        get() = dom.type == "password"
        set(it) {
            if (it)
                dom.type = "password"
            else
                dom.type = "text"
        }

    var enabled: Boolean
        get() = !dom.disabled
        set(value) {
            dom.disabled = !value
        }

    init {
        this.dom.value = text
        this.placeHolder = placeHolder
        this.password = password
    }
}