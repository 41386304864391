package org.tlsys.members

import kotlinx.browser.window
import libs.time
import org.tlsys.*
import org.tlsys.admin.MemberListProvider
import org.tlsys.admin.MembersAction
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.Validator
import org.tlsys.admin.ui.*
import org.tlsys.api.*
import org.tlsys.cms.Extension
import org.tlsys.core.Closeable
import org.tlsys.css.CSS
import org.tlsys.css.animate
import org.tlsys.css.padding
import org.tlsys.dto.MemberDTO
import org.tlsys.dto.SearchSource
import org.tlsys.dto.auth.CorePermission
import org.tlsys.dto.contacts.ContactDTO
import org.tlsys.dto.contacts.ContactExistException
import org.tlsys.gui.MessageDialog
import org.tlsys.json.JList
import org.tlsys.json.empty
import org.tlsys.json.jdto
import org.tlsys.members.search.CardSearchSource
import org.tlsys.members.search.NameSearchSource
import org.tlsys.ui.Page
import kotlin.collections.set

class MembersPage : AbstractPage() {
    override suspend fun getTitle(): String = TITLE
    private val listProvider by Services.listByClass(MemberListProvider::class)

    companion object {
        val TITLE = "Клиенты"
        val URI = "members"

        val SEARCH_SELECTOR = CSS.style {
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontSize = 14.px
            color = "#3bafda"
            cursor = "pointer"
            padding {
                left = 10.px
                top = 7.px
            }
        }

        private val costumColumns = HashSet<CustomColumn>()

        class CustomColumn(val name: String, val render: (TablePoint<MemberDTO>) -> Unit) : Closeable {
            override fun close() {
                costumColumns.remove(this)
            }
        }

        fun addColumn(name: String, render: (TablePoint<MemberDTO>) -> Unit): CustomColumn {
            val c = CustomColumn(name, render)
            costumColumns += c
            return c
        }

        init {
            NameSearchSource
            CardSearchSource
        }

        interface Action {
            val title: String
            fun action(): Unit
        }

        val toolBar = Extension<Action>()
    }

    override suspend fun onStart() {
        super.onStart()
        fragmentView.onStart()
    }

    override suspend fun onStop() {
        super.onStop()
        fragmentView.onStop()
    }

    val membersActions by Services.listByClass(MembersAction::class)

    val toolsPlane = ToolsPlane(usingSearch = false).apply {
        contentLayout.add(dom) {
            shrink = 0
        }
    }
    private val membersService by Services.byClass(MembersService::class)
    private val accountsService by Services.byClass(AccountsService::class)
    private val contractsService by Services.byClass(ContractsService::class)
    val addClientBtn =
        if (DEFAULT_SESSION!!.isAllow(CorePermission.CREATE_MEMBERS)) {
            toolsPlane.button("Создать") {
                async2 {
                    AddMemberDialog.show()?.let { dialog ->
                        ProcessBlocker2.block("Создание клиента") {
                            val phone = dialog.phone.takeIf { it.isValid }?.text?.takeIf { it.isNotBlank() }
                            if (phone != null && contractsService.isExist(
                                    type = ContactDTO.Type.PHONE.name.jdto,
                                    value = phone.jdto,
                                ).await().value
                            ) {
                                MessageDialog.showError("Клиент с телефоном \"$phone\" уже существует")
                                return@block
                            }
                            try {
                                val memb = membersService.createMember(
                                    firstName = dialog.firstName.text.jdto,
                                    middleName = dialog.middleName.text.takeIf { it.isNotBlank() }?.jdto,
                                    lastName = dialog.lastName.text.jdto,
                                    birthDay = dialog.birthDayInput.date?.time?.jdto,
                                    sex = dialog.isMale.jdto,
                                    phones = if (phone == null) JList.empty() else listOf(phone.jdto).jdto(),
                                    emails = JList.empty(),
                                    allowDropBonus = false.jdto,
                                    tags = JList.empty(),
                                    cards = JList.empty(),
                                ).await()
                                members.addMember(memb)
                            } catch (e: ContactExistException) {
                                MessageDialog.showError("Клиент с контактом ${e.contact} уже существует")
                            } catch (e: Throwable) {
                                MessageDialog.showError("Ошибка создания клиента ${e.message}")
                                console.info("ERROR!")
                                console.dir(it)
                            }
                        }
                    }
                }
            }
        } else {
            null
        }
    private val PARAM_SEARCH_BY = "f"
    private val PARAM_EXP = "q"
    private val PARAM_WITH_TAG = "wt"
    private val PARAM_WITHOUT_TAG = "wo"

    override suspend fun updateParams(params: Map<String, String?>) {
        super.updateParams(params)

        members = MemberListFragment(
            membersService.searchMembersIterator(
                exp = params[PARAM_EXP]?.takeIf { it.isNotBlank() },
                maxPart = 50,
                minForLoad = 30,
                sex = null,
                source = params[PARAM_SEARCH_BY]?.let { SearchSource.valueOf(it) } ?: SearchSource.NAME,
                withTags = filter.withTags,
                withoutTags = filter.withoutTags,
            ),
        )

        currentSearchSource = (
            params[PARAM_SEARCH_BY]
                ?: SearchSource.NAME.name
            ).let { s -> listProvider.find { it.source.name == s } ?: listProvider[0] }
        fragmentView.set(members)
        updateSearchSource()
        searchBox.text = params[PARAM_EXP] ?: ""
    }

    init {
        toolsPlane.button("Фильтр") {
            async2 {
                val filters = FilterDialog.show(filter) ?: return@async2
                val params = HashMap(params)
                params.setList(PARAM_WITH_TAG, filters.withTags.map { it.toString() })
                params.setList(PARAM_WITHOUT_TAG, filters.withoutTags.map { it.toString() })
                setParams(params)
            }
        }
        /*
        toolBar.useOnPage {
            val btn = toolsPlane.button(text = it.value.title) { btn ->
                it.value.action()
            }

            it.close {
                btn.dom.remove()
            }
        }
        */
    }

    private val SEARCH_VALIDATOR = Validator<String> {
        if (it.isBlank()) {
            valid()
        } else {
            currentSearchSource.valid(it)
        }
    }

    val searchBox = SearchInput(placeHolder = "Поиск").apply {
        addValidator(SEARCH_VALIDATOR)
        dom.style.width = "233px"
        toolsPlane.layout.add(dom)
        toolsPlane.dom.insertBefore(dom, toolsPlane.buttonsLayout.parent)
    }

    init {
        searchBox.onEnter {
            if (!searchBox.valid) {
                return@onEnter
            }

            val params = HashMap(params)
            params[PARAM_SEARCH_BY] = currentSearchSource.source.name
            params[PARAM_EXP] = searchBox.text
            async2 {
                setParams(params.removeBlank())
            }
            /*
            if (searchBox.text.isNotBlank()) {
                if (addClientBtn != null && addClientBtn.dom.style.display != "none")
                    addClientBtn.dom.animate {
                        duration = 300
                        0 {
                            opacity = 1.0
                        }

                        100{
                            opacity = 0.0
                        }
                        onEnd {
                            addClientBtn.dom.style.display = "none"
                        }
                    }.start()
                async {
                    fragmentView.set(SearchFragment(currentSearchSource!!.serarch(searchBox.text, filters)))
                }
            } else {
                if (addClientBtn != null && addClientBtn.dom.style.display == "none")
                    addClientBtn.dom.animate {
                        duration = 300
                        init {
                            addClientBtn.dom.style.display = ""
                        }
                        0 {
                            opacity = 0.1
                        }

                        100{
                            opacity = 0.0
                        }
                    }.start()
            }
            */
        }
    }

    override suspend fun onInit() {
        super.onInit()
        membersActions.forEach {
            toolsPlane.button(it.text) { _ ->
                async2 {
                    it.action()
                }
            }
        }
    }

    var searchSourceSelector = Span("").apply {
        dom.classList.add(SEARCH_SELECTOR.name)
        toolsPlane.layout.add(dom)
        toolsPlane.dom.insertBefore(dom, toolsPlane.buttonsLayout.parent)
    }

    var currentSearchSource = run {
        val type = params[PARAM_SEARCH_BY]?.let { SearchSource.valueOf(it) }
            ?: SearchSource.NAME

        listProvider.find { it.source == type }!!
    }

    val filter: MemberListProvider.Filter
        get() = MemberListProvider.Filter(
            sex = null,
            withTags = params.getList(PARAM_WITH_TAG).map { it.toLong() },
            withoutTags = params.getList(PARAM_WITHOUT_TAG).map { it.toLong() },
        )

    init {

        searchSourceSelector.dom.onclick = {
            val rec = searchSourceSelector.dom.getBoundingClientRect()
            async2 {
                currentSearchSource = SelectorSearchSource.select(
                    x = rec.left.toInt(),
                    y = rec.top.toInt(),
                ).await() ?: return@async2

                updateSearchSource()
            }
        }
    }

    private fun updateSearchSource() {
        searchSourceSelector.text = currentSearchSource.serachBy
    }

    private lateinit var members: MemberListFragment
    val fragmentView = ComponentView()

    init {
        contentLayout.add(fragmentView.dom) {
            shrink = 1
            grow = 1
        }
    }

    override suspend fun next(pageName: String): Page? {
        if (!pageName.startsWith("m")) {
            return super.next(pageName)
        }
        val g = pageName.substring(1).toLongOrNull() ?: return super.next(pageName)

        val member = membersService.getMemberById(g.jdto).await() ?: TODO("Клиент не найден")
        return MemberInfoPage(member)
    }
}

fun Table.blinkRow(index: Int) {
    val row = this.getNativeRow(index)
    val rowColor = window.getComputedStyle(row).background
    row.animate {
        duration = 500
        start {
            background = "#FFDE00"
        }
        end {
            background = rowColor
        }
    }.start()
}
