package org.tlsys.wc

import org.tlsys.json.*

/**
 * Информация о позиции в чеке
 *
 * @param count количество в граммах
 * @param code код товара
 * @param amount цена в копейках
 */
class DocumentPositionDTO(val count: Long, val code: String, val amount: Long) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = Companion.asDefault

    companion object : JDTOFactory<DocumentPositionDTO> {
        override val type: String
            get() = "document_product"

        override fun read(node: JsonNode): DocumentPositionDTO =
                DocumentPositionDTO(
                        count = node["count"]!!.string().toLong(),
                        code = node["code"]!!.string(),
                        amount = node["amount"]!!.string().toLong()
                )

        override fun write(obj: DocumentPositionDTO): JsonNode =
                JsonNode.obj()
                        .set("count", obj.count.toString().json)
                        .set("code", obj.code.json)
                        .set("amount", obj.amount.toString().json)

    }
}