package org.tlsys.admin.ui

import kotlinx.browser.document
import org.tlsys.admin.addClass
import org.tlsys.admin.childElements
import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.core.Closeable
import org.tlsys.navigation.Dialog
import org.tlsys.styles.DialogStyle
import org.tlsys.ui.createDiv
import org.tlsys.userSelect7
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.KeyboardEvent
import pw.binom.web.AbstractComponent
import pw.binom.web.Component
import pw.binom.web.layout.*
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

abstract class AbstractDialog : AbstractComponent<HTMLDivElement>(), Dialog<HTMLDivElement> {
    final override val dom: HTMLDivElement = document.createDiv()

    init {
        afterConstruct()
        addClass(DialogStyle.MAIN)
    }

    /**
     * Вызывается, если пользователь нажал на кнопку Escape
     */
    protected open fun onEscapeClick() {
    }

    private var _focused = false

    override fun onFocusGot() {
        _focused = true
    }

    override fun onFocusLost() {
        _focused = false
    }

    val focused: Boolean
        get() = _focused

    override fun onKeyUp(event: KeyboardEvent) {
        if (event.keyCode == 27) {
            onEscapeClick()
        }
    }

    private val layoutNavigator by Services.byClass(LayoutNavigator::class)

    protected open suspend fun show() {
        layoutNavigator.show(this).await()
    }

    fun close() {
        if (closed) {
            throw RuntimeException("AbstractDialog was already closed")
        }
        for (c in closeListeners)
            c(this)
        closeListeners.clear()
        async2 {
            _closed = layoutNavigator.close(this)
            if (!_closed) {
                console.info("Can't close the dialog")
            }
        }
    }

    private var _closed: Boolean = false
    val closed: Boolean
        get() = _closed

    private val closeListeners = HashSet<(AbstractDialog) -> Unit>()

    fun onClose(listener: (AbstractDialog) -> Unit) {
        closeListeners += listener
    }

//    private var _firstStart = true

    override suspend fun onStart() {
//        if (_firstStart) {
//            onInit(init)
//            _firstStart = false
//        }
        super.onStart()
        ArrayList(onStartListeners).forEach {
            it.invoke()
        }
    }

    override suspend fun onStop() {
        ArrayList(onStopListeners).forEach {
            it.invoke()
        }
        super.onStop()
    }

    private val onStartListeners = ArrayList<suspend () -> Unit>()
    private val onStopListeners = ArrayList<suspend () -> Unit>()

    fun addListenerOnSrart(l: suspend () -> Unit): Closeable {
        onStartListeners += l

        return Closeable {
            onStartListeners -= l
        }
    }

    fun addListenerOnStop(l: suspend () -> Unit): Closeable {
        onStopListeners += l

        return Closeable {
            onStopListeners -= l
        }
    }
}

abstract class TitleDialog(title: String = "") : AbstractDialog() {
    private val mainLayout = FlexLayout(dom, direction = FlexLayout.Direction.Column).asParent()

    private val titleSpan = Span(title).apply {
        addClass(DialogStyle.TITLE)
        dom.style.apply {
            color = "rgba(0,0,0,.6)"
            paddingBottom = "15px"
            userSelect7 = "none"
        }
        appendTo(mainLayout, grow = 0, shrink = 0)
    }

    protected var title: String
        get() = titleSpan.text
        set(value) {
            titleSpan.text = value
        }
    protected open val layout = FlexLayout.Companion.div {
        direction = FlexLayout.Direction.Column
        appendTo(mainLayout, grow = 1, shrink = 1)
    }

    override suspend fun onStart() {
        super.onStart()
        console.info("TitleDialog OnStart")
        mainLayout.dom.childElements.forEach {
            console.info("mainLayout->", Component.getComponent(it), it)
        }
//        layout.onStart()
    }

    override suspend fun onStop() {
//        layout.onStop()
        console.info("TitleDialog OnStop")
        super.onStop()
    }

    open val content: HTMLDivElement
        get() = layout.parent
}

suspend fun AbstractDialog.waitStop() {
    suspendCoroutine<Unit> { con ->
        addListenerOnStop { con.resume(Unit) }
    }
}
