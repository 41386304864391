package org.tlsys

import pw.binom.date.Calendar
import pw.binom.date.DateTime
import pw.binom.date.format.toDatePattern
import pw.binom.date.of
import kotlin.jvm.JvmInline

private val format = "yyyy-MM-dd".toDatePattern()

@JvmInline
value class DateOnly(val body: String) {
    val year
        get() = format.parseOrNull(body)!!.calendar().year

    val month
        get() = format.parseOrNull(body)!!.calendar().month

    val day
        get() = format.parseOrNull(body)!!.calendar().dayOfMonth

    fun toDate(timezone: Int = DateTime.systemZoneOffset) = format.parseOrNull(body, defaultTimezoneOffset = timezone)!!

    companion object {
        fun create(year: Int, month: Int, day: Int) =
            DateOnly(format.toString(DateTime.of(year = year, month = month, dayOfMonth = day)))
    }
}

fun Calendar.toDateOnly() = DateOnly(format.toString(this))
fun DateTime.toDateOnly() = DateOnly(format.toString(this))
