package org.tlsys.admin.serialization

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.descriptors.element
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object LongSerializer : KSerializer<Long> {
    override val descriptor: SerialDescriptor =
            buildClassSerialDescriptor("l") {
                element<String>("v")
            }

    override fun serialize(encoder: Encoder, value: Long) {
        encoder.beginStructure(this.descriptor).also {
            it.encodeLongElement(this.descriptor, 0, value)
            it.endStructure(this.descriptor)
        }
    }

    override fun deserialize(decoder: Decoder): Long {
        return decoder.beginStructure(descriptor).let {
            val index = it.decodeElementIndex(BooleanSerializer.descriptor)
            if (index != 0) {
                throw SerializationException("Invalid index $index")
            }
            val s = it.decodeLongElement(descriptor, 0)
            it.endStructure(descriptor)
            s
        }
    }
}