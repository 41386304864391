package org.tlsys

import androidx.compose.runtime.NoLiveLiterals
import kotlinx.browser.document
import kotlinx.browser.window
import org.khronos.webgl.Int8Array
import org.tlsys.core.Closeable
import org.tlsys.css.CssClass
import org.tlsys.css.CssDeclaration
import org.w3c.dom.*
import org.w3c.dom.css.CSSStyleDeclaration
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventTarget
import kotlin.js.Promise
import kotlin.reflect.KProperty

fun CssClass.cross_blur(radius: Int) {
    this.asDynamic().WebkitFilter = "blur(${radius}px)"/* Chrome, Safari */
    this.asDynamic().MsFilter = "blur(${radius}px)" /* IE12? */
    this.asDynamic().MozFilter = "blur(${radius}px)" // FireFox
    if ("firefox" in window.navigator.userAgent.lowercase()) {
        filter =
            "blur(${radius}px)" // "url('data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"blur\"><feGaussianBlur stdDeviation=\"{$radius}\" /></filter></svg>#blur')"
    } else {
        filter = "blur(${radius}px)" // "progid:DXImageTransform.Microsoft.Blur(Strength=$radius)"; /* IE9 */
    }
}

// fun Storage.setDTO(id: String, obj: DTO?) {
//    if (obj == null)
//        removeItem(id)
//    setItem(id, JSWriter().apply {
//        obj(obj)
//    }.toBase64())
// }
//
// fun Storage.getDTO(id: String): DTO? {
//    val g = get(id) ?: return null
//    return JSReader.fromBase64(g).obj()
// }

fun Node.eachParents(l: (Node) -> Boolean) {
    var b: Node? = this
    while (b != null) {
        if (!l(b)) {
            return
        }
        b = b.parentElement
    }
}

val HTMLElement.absoluteOffsetLeft: Double
    get() {
        var left: Double = 0.0
        this.eachParents {
            console.dir(it)
            if (it === document.body) {
                return@eachParents false
            }
            if (it is HTMLElement) {
                left += it.offsetLeft
                true
            } else {
                false
            }
        }
        return left
    }

val HTMLElement.absoluteOffsetTop: Double
    get() {
        var top: Double = 0.0
        this.eachParents {
            if (it === document.body) {
                return@eachParents false
            }
            if (it is HTMLElement) {
                top += it.offsetTop
                true
            } else {
                false
            }
        }
        return top
    }

val HTMLElement.absoluteOffset: Pair<Double, Double>
    get() {
        var b: HTMLElement? = this
        var top = 0.0
        var left = 0.0
        this.eachParents {
            if (it === document.body) {
                return@eachParents false
            }
            if (it is HTMLElement) {
                top += it.offsetTop
                left += it.offsetLeft
                true
            } else {
                false
            }
        }
        return Pair(left, top)
    }

fun Node.byTag(tagName: String): Array<Node> {
    val list = ArrayList<Node>(childNodes.length)
    for (g in 0..childNodes.length - 1) {
        val item = childNodes[g]

        if (item is Element && item.tagName == tagName) {
            list += item
        }
    }

    return list.toTypedArray()
}

val <T : Node> Array<T>.one: Node?
    get() {
        if (size != 1) {
            return null
        }
        return get(0)
    }

fun <T, S> Promise<T>.then(f: (T) -> S) = then(f, null)

fun Int8Array.toTypedArray() = unsafeCast<Array<Byte>>()

fun Node.insertChild(node: Node, index: Int) {
    if (this.childNodes.length <= index) {
        appendChild(node)
    } else {
        val child = this.childNodes.get(index)!!
        insertAfter(node, child)
    }
}

fun sleep(ms: Int) = Promise<Unit> { d, c ->
    window.setTimeout({ d(Unit) }, ms)
}

fun NodeList.toList(): List<Node> {
    val list = ArrayList<Node>()
    for (i in 0.until(length)) {
        list += this.get(i)!!
    }
    return list
}

class LazyPromise<T>(val promise: () -> Promise<T>) {
    private val waiters = ArrayList<(T) -> Unit>()
    private var loading = false
    private var loaded = false
    private var outValue: Any? = null

    val value: Promise<T>
        get() = Promise<T> { d, c ->
            if (loaded) {
                d(outValue as T)
            } else {
                if (loading) {
                    waiters.add(d)
                } else {
                    loading = true
                    promise().then({
                        loaded = true
                        outValue = it
                        d(it)
                        for (w in waiters)
                            w(it)
                        waiters.clear()
                    })
                }
            }
        }

    operator fun getValue(thisRef: Any?, property: KProperty<*>) = value

    fun forceSetValue(value: T) {
        this.outValue = value
        loading = true
        loaded = true
        for (w in waiters)
            w(value)
        waiters.clear()
    }

    fun reset() {
        loading = false
        loaded = false
        outValue = null
    }
}

@NoLiveLiterals
inline var CSSStyleDeclaration.userSelect: String
    get() = asDynamic()["user-select"]
    set(value) {
        asDynamic()["user-select"] = value
        asDynamic()["-moz-user-select"] = value
        asDynamic()["-ms-user-select"] = value
        asDynamic()["-webkit-user-select"] = value
    }
@NoLiveLiterals
inline var CssDeclaration.userSelect: String
    get() = asDynamic().userSelect
    set(value) {
        asDynamic().userSelect = value
        asDynamic()["-moz-user-select"] = value
        asDynamic()["-ms-user-select"] = value
        asDynamic()["-webkit-user-select"] = value
    }

/**
 * @see <a href="http://help.dottoro.com/ljmcxjla.php">Описание</a>
 */
fun <T : EventTarget> T.onAddToDocument(f: (Event) -> Unit): Closeable {
    addEventListener("DOMNodeInsertedIntoDocument", f, false)
    return Closeable {
        removeEventListener("DOMNodeInsertedIntoDocument", f, false)
    }
}

/**
 * * @see <a href="http://help.dottoro.com/ljmcxjla.php">Описание</a>
 */
fun <T : EventTarget> T.onRemoveFromDocument(f: (Event) -> Unit): Closeable {
    addEventListener("DOMNodeRemovedFromDocument", f, false)
    return Closeable {
        removeEventListener("DOMNodeRemovedFromDocument", f, false)
    }
}

fun <T> Map<T, String?>.removeBlank() =
    asSequence().filter { !it.value.isNullOrBlank() }.map { it.key to it.value }.toMap()

fun <T> Map<T, String?>.getList(key: T) = get(key)?.split(',') ?: emptyList()
fun <T> MutableMap<T, String?>.setList(key: T, list: List<String>) {
    if (list.isEmpty()) {
        this.remove(key)
    } else {
        set(key, list.joinToString(","))
    }
}
