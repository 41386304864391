package org.tlsys

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.tlsys.admin.serialization.R

@SerialName("good-element")
@Serializable
class WGoodElement(val type: Type, val code: String, val id: String, val name: String) : R {
    enum class Type {
        Good, Group, Classifier
    }
}
