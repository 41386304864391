package org.tlsys.admin.cross

import org.tlsys.admin.serialization.*
import pw.binom.rpc.AbstractRemoteService

private object RemoteCartService1 : CartCrossService, AbstractRemoteService(JsInvoker) {
    override val updateCount by remote<Unit>()
}

object RemoteCartService2 {
    suspend fun updateCount(id: String, member: Long, count: Long) =
        RemoteCartService1.updateCount(mapOf("id" to id, "member" to member, "count" to count))
}
