package org.tlsys.core

import kotlinx.browser.window
import kotlin.coroutines.*
import kotlin.js.Date
import kotlin.js.Promise

fun <T, V> (suspend V.() -> T).start(value: V) = Promise<T> { resolve, reject ->
    this.startCoroutine(
        value,
        object : Continuation<T> {
            override fun resumeWith(result: Result<T>) {
                if (result.isSuccess) {
                    result.onSuccess(resolve)
                }
                if (result.isFailure) {
                    reject(result.exceptionOrNull()!!)
                }
            }

            override val context = EmptyCoroutineContext
        }
    )
}

fun <T> (suspend () -> T).start() = Promise<T> { resolve, reject ->
    this.startCoroutine(object : Continuation<T> {
        override fun resumeWith(result: Result<T>) {
            if (result.isSuccess) {
                result.onSuccess(resolve)
            }
            if (result.isFailure) {
                reject(result.exceptionOrNull()!!)
            }
        }

        override val context = EmptyCoroutineContext
    })
}

// fun <T> async(c: suspend () -> T): Promise<T> = c.start()

// suspend fun <T> Promise<T>.await() = suspendCoroutine<T> { c ->
//    then({ c.resume(it) }, { c.resumeWithException(it) })
// }

fun Collection<Promise<*>>.join() = Promise.all(this.toTypedArray())

fun Promise.Companion.delay(time: Int) = delay(time, Unit)

fun <T : Any?> Promise.Companion.delay(time: Int, value: T) = Promise<T> { d, c ->
    window.setTimeout({
        d(value)
    }, time)
}

class WaitPromise<T>() {
    fun resolve(value: T) {
        d(value)
    }

    fun reject(exception: Throwable) {
        c(exception)
    }

    private lateinit var d: (T) -> Unit
    private lateinit var c: (Throwable) -> Unit
    val promise = Promise<T> { d, c ->
        this.d = d
        this.c = c
    }
}

/**
 * Связный promise
 */
external class BackPromise<T>

/**
 * Возвращает связный promise, который можно присоединить к другому промису (см [Promise.connect]).
 */
fun <T> Promise.Companion.back(): BackPromise<T> {
    var d: ((T) -> Unit)? = null
    var c: ((Throwable) -> Unit)? = null

    val p = Promise<T> { dd, cc ->
        d = dd
        c = cc
    }.unsafeCast<BackPromise<T>>()

    p.asDynamic().resolveFunc = d
    p.asDynamic().rejectFunc = c
    return p
}

/**
 * Конвертирует связанный promise в обычный
 */
inline val <T>BackPromise<T>.asPromise: Promise<T>
    get() = this.unsafeCast<Promise<T>>()

/**
 * Соединяет текущий promise с [back]. т.е. [back] promise выполнится тогда когда выполнится текущий
 *
 * @param back promise, который выполнится тогда когда выполнится текущий
 */
fun <T> Promise<T>.connect(back: BackPromise<T>) {
    then({
        back.asDynamic().resolveFunc(it)
    }, {
        back.asDynamic().rejectFunc(it)
    })
}

fun <T> BackPromise<T>.resolve(value: T) = this.asDynamic().resolveFunc(value)
fun <T> BackPromise<T>.exception(value: Throwable) = this.asDynamic().rejectFunc(value)

suspend fun wait(timeout: Float = 0f, interval: Int = 50, func: () -> Boolean) {
    require(interval >= 0)
    suspendCoroutine<Unit> { con ->
        var timer = 0
        val startTime = Date.now()
        timer = window.setInterval({
            if (func()) {
                window.clearInterval(timer)
                con.resume(Unit)
            }
            if (timeout != 0f && Date.now() > startTime + timeout * 1000f) {
                window.clearInterval(timer)
                con.resumeWithException(RuntimeException("Timeout Exception"))
            }
        }, interval)
    }
}
