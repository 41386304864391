package org.tlsys.admin.form

import libs.*
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.isInteger
import org.tlsys.admin.ui.Button
import org.tlsys.core.Closeable
import org.tlsys.toEmailOrNull
import org.tlsys.toPhoneOrNull
import org.w3c.dom.HTMLElement
import pw.binom.url.toURLOrNull
import pw.binom.web.Component

/**
 * Слушатель события изменения статуса валидации
 */
typealias ValidListener = (Boolean) -> Unit

/**
 * Объект валидации
 */
interface Validated {

    /**
     * Статус валидацтт
     */
    val valid: Boolean

    /**
     * Добовляет слушателя на изменения статуса валидности
     *
     * @param слушатель
     * @return объект, позволяющий прекратить слушать события валидации
     */
    fun onValidChange(listener: ValidListener): Closeable
}

/**
 * Объект валидации с возможностью изменять лист валидаторов
 */
interface MutableValidated<T> : Validated {
    /**
     * Добавляет новый валидатор
     *
     * @param добовляемый валидатор
     */
    fun addValidator(validator: Validator<T>)
}

/**
 * Валидируемый контроллер
 */
interface ValidatedController<T : HTMLElement> : Validated, Component<T>

/**
 * Объект-мултивалидатор. Предназначин для объединения нескольких валидируемых полей в одно
 */
class MultiValidator(vararg validators: Validated) : Validated {
    private val validators = ArrayList<Validated>()

    fun addValidated(validated: Validated) {
        validators.add(validated)
        validated.onValidChange(validListener)

        if (valid && !validated.valid) {
            forceSetValid(false)
        } else {
            forceSetValid(valid)
        }
    }

    private val validListeners = ArrayList<ValidListener>()

    private var _valid = false

    private val validListener: ValidListener = {
        val ff = this.validators.find { !it.valid }
        val newValid = ff === null

        if (_valid != newValid) {
            _valid = newValid
            for (l in validListeners)
                l(_valid)
        }
    }

    init {
        for (v in validators) {
            addValidated(v)
//            v.onValidChange(validListener)
        }
    }

    fun forceSetValid(valid: Boolean) {
        if (_valid != valid) {
            _valid = valid
            for (l in validListeners)
                l(_valid)
        }
    }

    override val valid: Boolean
        get() = _valid

    override fun onValidChange(listener: ValidListener): Closeable {
        validListeners.add(listener)

        listener(valid)

        listener(valid)
        return object : Closeable {
            override fun close() {
                validListeners.remove(listener)
            }
        }
    }

    fun reset() {
        val newValid = !validators.any { !it.valid }
        _valid = newValid
        for (l in validListeners)
            l(_valid)
    }

    init {
        reset()
    }
}

fun Validated(vararg fields: Validated, f: () -> Boolean) = object : InlineValidated(*fields) {
    override fun isValid(): Boolean = f()
}

abstract class InlineValidated(vararg fields: Validated) : Validated, Closeable {
    private val listeners = ArrayList<ValidListener>()

    override fun close() {
        listenersForClose.forEach {
            it.close()
        }
    }

    private val listenersForClose = fields.map {
        it.onValidChange { refresh() }
    }

    override val valid: Boolean
        get() = oldValidState

    override fun onValidChange(listener: ValidListener): Closeable {
        listeners += listener
        listener(oldValidState)

        return Closeable {
            listeners -= listener
        }
    }

    protected abstract fun isValid(): Boolean

    private var oldValidState = false

    fun refresh() {
        val newValidState = isValid()
        if (oldValidState != newValidState) {
            oldValidState = newValidState
            listeners.toList().forEach {
                it.invoke(newValidState)
            }
        }
    }
}

fun <T : InlineValidated> T.attach(event: EventElement): T {
    event.on {
        refresh()
    }
    return this
}

/**
 * Простой валидатор. Предназначит для встраивания внутрь других объектов, которые наследуют [MutableValidated]
 */
open class SimpleValidator<T> : MutableValidated<T> {
    override fun addValidator(validator: Validator<T>) {
        _validators.add(validator)

        for (l in validListeners) {
            l(oldValid)
        }
    }

    private val _validators = ArrayList<Validator<T>>()
    val validators: List<Validator<T>>
        get() = _validators

    private val validListeners = ArrayList<ValidListener>()

    override fun onValidChange(listener: (Boolean) -> Unit): Closeable {
        validListeners.add(listener)
        listener(valid)
        return object : Closeable {
            override fun close() {
                validListeners.remove(listener)
            }
        }
    }

    private var oldValid = false

    override val valid: Boolean
        get() = oldValid

    fun forceSetValid(valid: Boolean) {
        if (valid != this.oldValid) {
            this.oldValid = valid

            for (l in validListeners) {
                l(oldValid)
            }
        }
    }

    fun forceRefresh() {
    }

    /**
     * Обновляет статус валидации
     *
     * @param text новый текст, который будет проверин всеми валидаторами
     * @return статус валидности
     */
    fun updateValue(text: T): Boolean {
        var done = false
        for (v in _validators) {
            if (v.valid(text).isNotValid) {
                if (oldValid) {
                    oldValid = false
                    for (l in validListeners) {
                        l(oldValid)
                    }
                }
                done = true
                break
            }
        }
        if (!done && !oldValid) {
            oldValid = true
            for (l in validListeners) {
                l(oldValid)
            }
        }
        return oldValid
    }
}

/**
 * Базовый валидатор
 */
fun interface Validator<in T> {
    companion object {
        fun <T> valid() = Validator<T> { valid() }
        fun <T> invalid() = Validator<T> { invalid() }
        fun <T> invalid(message: String) = Validator<T> { invalid(message) }
    }

    fun valid(value: T): ValidationResult
}

fun <T> Validator(f: ValidationResult.Companion.(T) -> ValidationResult) = object : Validator<T> {
    override fun valid(value: T): ValidationResult = f(ValidationResult.Companion, value)
}

/**
 * Текстовые валидаторы
 */
object TextValidators {

    val PASSWORD = Validator<String> { value ->
        val upper = value.any { it in 'A'..'Z' }
        val lower = value.any { it in 'a'..'z' }
        val num = value.any { it in '0'..'9' }

        when {
            !upper -> invalid("Пароль должен содержать большие латинские символы")
            !lower -> invalid("Пароль должен содержать маленькие латинские символы")
            !num -> invalid("Пароль должен цифровые символы")
            else -> valid()
        }
    }

    fun maxLength(max: Int) = Validator<String> { value ->
        if (value.length > max) {
            invalid("Длина текста не должна привышать $max")
        } else {
            valid()
        }
    }

    fun minLength(min: Int) = Validator<String> { value ->
        if (value.length < min) {
            invalid("Длина текста должна быть больше $min")
        } else {
            valid()
        }
    }

    /**
     * Значение не пустое. Если присутствуют только пробелмы, то поле считается не пустым
     */
    val NOT_EMPTY = Validator<String> { value ->
        if (value.isEmpty()) {
            invalid("Поле не должно быть пустым")
        } else {
            valid()
        }
    }

    /**
     * Проверка содержит ли значение пробел(ы)
     */
    val NOT_CONCAT_SPACE = Validator<String> { value ->
        if (" " in value) {
            invalid("Поле не должно содержать пробелы")
        } else {
            valid()
        }
    }

    /**
     * Значение не пустое. Если присутствуют только пробелмы, то поле считается пустым
     */
    val NOT_BLANK = Validator<String> { value ->
        if (value.isBlank()) {
            invalid("Поле не должно быть пустым")
        } else {
            valid()
        }
    }

    /**
     * Значение не пустое. Если присутствуют только пробелмы, то поле считается пустым
     */
    val EMAIL = Validator<String> { value ->
        if (value.toEmailOrNull() == null) {
            invalid("Не верный формат Email")
        } else {
            valid()
        }
    }

    /**
     * Значение не пустое. Если присутствуют только пробелмы, то поле считается пустым
     */
    val URL = Validator<String> { value ->
        if (value.toURLOrNull == null) {
            invalid("Не верный формат URL")
        } else {
            valid()
        }
    }

    /**
     * Название колонки в Excel
     */
    val EXCEL_COLUMN = Validator<String> { value ->
        if (value.isBlank()) {
            return@Validator invalid("Поле не должно быть пустым")
        }
        val default = value.all { it in 'A'..'Z' }
        val r1c1 = value.all { it in '0'..'9' }
        if (default || r1c1) {
            valid()
        } else {
            invalid("Не верный формат колонок")
        }
    }

    /**
     * Проверяет отсутствие пробелов
     */
    val NO_SPACE = Validator<String> { value ->
        if (" " in value) {
            invalid("Поле не должно содержать пробелы")
        } else {
            valid()
        }
    }

    /**
     * Значение пустое. Если если поле пустое или заполненно пробелами, то поле считается верным. Иначе не верным
     */
    val BLANK = Validator<String> { value ->
        if (value.isNotBlank()) {
            invalid("Поле должно быть пустым")
        } else {
            valid()
        }
    }

    /**
     * Значение пустое. Если поле пустое или заполненно пробелами, то поле считается верным. Иначе не верным
     */
    val EMPTY = Validator<String> { value ->
        if (value.isNotEmpty()) {
            invalid("Поле должно быть пустым")
        } else {
            valid()
        }
    }

    /**
     * Проверка телефона
     */
    val IS_PHONE = Validator<String> { value ->
//            if (value.length != 12)
//                return "Нужно 12 символов"
//            if (!value.matches("\\+7[0-9]+"))
//                return "Номер должен начинаться на +7 и состоять только из цифр"

        if (value.toPhoneOrNull == null) {
            invalid("Не верный формат телефона")
        } else {
            valid()
        }
    }

//    fun getPhone(value: String): String {
//        if (!value.matches("(\\+7[\\s,-,\\(]*\\d|8[\\s,-,\\(]*9|\\(?9[\\s,-,\\(]*)([\\s,\\-,\\),\\(]*\\d){9}")) {
//            throw IllegalArgumentException("Не верный формат телефона")
//        }
//        val txt = "[\\s\\-()]".toRegex().replace(value, "")
//        return if (txt.startsWith("8")) {
//            "+7${txt.removePrefix("8")}"
//        } else {
//            txt
//        }
//    }

//    fun formatPhone(value: String): String {
//        val p = getPhone(value)
//
//        val l1 = p.substring(p.length - 2)
//        val l2 = p.substring(p.length - 4, p.length - 2)
//        val l3 = p.substring(p.length - 7, p.length - 4)
//        val l4 = p.substring(p.length - 10, p.length - 7)
//        val l5 = p.substring(0, p.length - 10)
//
//        console.info("$l5-$l4-$l3-$l2-$l1")
//        return p
//    }

    /**
     * Проверка электронной почты
     */
    val IS_MAIL = Validator<String> { value ->
        if (!"^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$".toRegex()
                .matches(value)
        ) {
            invalid("Неправильный формат ввода e-mail адресса")
        } else {
            valid()
        }
    }

    /**
     * Проверка электронной почты
     */
    val IS_TELEGRAM = Validator<String> { value ->
        if (value.startsWith("@") && ' ' !in value && value.length > 3) {
            valid()
        } else {
            invalid("Неправильный формат ввода e-mail адресса")
        }
    }
}

/**
 * Валидаторы протоколов
 */
object ProtocolValidators {
    val HTTP = Validator<String> {
        if (it.startsWith("http://") && (it.length >= 11)) {
            valid()
        } else {
            invalid("Не вернная ссылка")
        }
    }

    val HTTPS = Validator<String> {
        if (it.startsWith("https://") && (it.length >= 12)) {
            valid()
        } else {
            invalid("Не вернная ссылка")
        }
    }

    val FTP = Validator<String> {
        if (it.startsWith("ftp://") && (it.length >= 10)) {
            valid()
        } else {
            invalid("Не вернная ссылка")
        }
    }

    val ALL = ProtocolValidators.FTP.or(ProtocolValidators.HTTP.or(ProtocolValidators.HTTPS))
}

infix fun <T> Validator<T>.or(v: Validator<T>) = Validator<T> {
    val v1 = this@or.valid(it)
    val v2 = v.valid(it)

    if (v2.isValid || v1.isValid) {
        return@Validator valid()
    }

    if (!v2.isValid) {
        v1
    } else {
        v2
    }
}

infix fun <T> Validator<T>.and(v: Validator<T>) = Validator<T> {
    val v1 = this@and.valid(it)
    if (!v1.isValid) {
        return@Validator v1
    }
    val v2 = v.valid(it)
    return@Validator v2
}

/**
 * Валидаторы даты
 */
object DateValidator {

    /**
     * Простой валидатор формата
     */
    val DATE_FORMAT = Validator<String> { value ->
        if (parseDate(value) === null) {
            invalid("Не верный формат даты")
        } else {
            valid()
        }
    }

    /**
     * Валидирует, что бы значение даты было до текущей (день, месяц, год)
     */
    val BEFORE_TODAY = Validator<Date> { value ->
        val now = Date.now()
        when {
            value.year < now.year -> valid()
            value.month < now.month -> valid()
            value.date < now.date -> valid()
            else -> invalid("Дата должна быть меньше текущей")
        }
    }

    /**
     * Валидирует, что бы значение даты было после текущей (день, месяц, год)
     */
    val AFTER_TODAY = Validator<Date> { value ->
        val now = Date.now()
        when {
            value.year > now.year -> valid()
            value.month > now.month -> valid()
            value.date > now.date -> valid()
            else -> invalid("Дата должна быть больше текущей")
        }
    }

    /**
     * Валидирует, что бы дата была сегоднешней (день, месяц, год)
     */
    val TODAY = Validator<Date> { value ->
        val now = Date.now()
        val txt = invalid("Дата должна быть сегодня")
        when {
            value.year != now.year -> txt
            value.month != now.month -> txt
            value.date != now.date -> txt
            else -> valid()
        }
    }

    /**
     * Валидирует что бы значение даты было до [date]
     *
     * @param date значение должно быть до указанной даты
     */
    fun before(date: Date) = Validator<Date> { value ->
        if (value.time < date.time) {
            invalid("Дата должна быть больше чем ${date.asDateString}")
        } else {
            valid()
        }
    }

    /**
     * Парсит строку, пытается выудить из нее дату
     *
     * @param text текст парсинга
     * @return если [text] удачно распарсился в дату, то вернет ее. Если не удачно, то вернет null
     */
    fun parseDate(text: String): Date? {
        val h = text.split(".")
        if (h.size < 3) return null
        if (!h[0].isInteger() || !h[1].isInteger() || !h[2].isInteger()) {
            return null
        }
        val day = h[0].toIntOrNull() ?: return null
        val month = h[1].toIntOrNull() ?: return null
        val year = h[2].toIntOrNull() ?: return null

        if (year < 0) return null
        if (day <= 0) return null
        if (month <= 0 || month > 12) return null

        return Date.date(day = day, month = month - 1, year = year, hour = 12)
    }
}

/**
 * Валидаторы цифр без плавоющей точки
 */
object IntegerValidator {

    /**
     * Валидатор формата. Просто проверяет, что бы в строке было число, причем было целым
     */
    val FORMAT = Validator<String> { value ->
        when {
            value.toDoubleOrNull() == null -> invalid("Не верный формат числа")
            "." in value || "," in value -> invalid("Число не является целым")
            else -> valid()
        }
    }
}

/**
 * Валидаторы цифр с плавоющей точкой
 */
object DoubleValidator {

    /**
     * Возвращает кол. символов после запятой
     *
     * @param text входной текст для проверки
     * @return кол. знаков после запятой
     */
    fun getAfterPoint(text: String): Int {
        var p = text.indexOf('.')
        if (p < 0) {
            p = text.indexOf(',')
        }

        if (p < 0) {
            return 0
        }
        return text.substring(p + 1).length
    }

    /**
     * Валидатор. Число должно быть больше чем [more]
     *
     * @param more минимальное число
     * @return итоговый валидатор
     */
    fun more(more: Double) = Validator<Double> { value ->
        if (value <= more) {
            invalid("Число должно быть больше $value")
        } else {
            valid()
        }
    }

    /**
     * Число должно быть больше или равно чем [more]
     *
     * @param more число для сравнения
     * @return итоговый валидатор
     */
    fun moreOrEquals(more: Double) = Validator<Double> { value ->
        if (value < more) {
            invalid("Число должно быть больше или равно $value")
        } else {
            valid()
        }
    }

    /**
     * Число должно быть больше или равно чем [more]
     *
     * @param more число для сравнения
     * @return итоговый валидатор
     */
    fun moreOrEquals2(more: Double) = Validator<String> { value ->
        val value2 = value.toDoubleOrNull() ?: return@Validator invalid("Введено не число")
        if (!(value2 >= more)) {
            invalid("Число должно быть больше или равно $value")
        } else {
            valid()
        }
    }

    /**
     * Число должно быть больше чем [more]
     *
     * @param more число для сравнения
     * @return итоговый валидатор
     */
    fun more2(more: Double) = Validator<String> { value ->
        val value2 = value.toDoubleOrNull() ?: return@Validator invalid("Введено не число")
        if (!(value2 > more)) {
            invalid("Число должно быть больше или равно $value")
        } else {
            valid()
        }
    }

    /**
     * Число должно быть меньше или равно чем [value]
     *
     * @param more число для сравнения
     * @return итоговый валидатор
     */
    fun minOrEquals(min: Double) = Validator<Double> { value ->
        if (value > min) {
            invalid("Число должно быть меньше или равно $min")
        } else {
            valid()
        }
    }

    fun minOrEquals2(min: Double) = Validator<String> { value ->
        val value2 = value.toDoubleOrNull() ?: return@Validator invalid("Введено не число")
        if (!(value2 <= min)) {
            invalid("Число должно быть меньше или равно $value")
        } else {
            valid()
        }
    }

    /**
     * Число должно быть меньше или равно чем [value]
     *
     * @param more число для сравнения
     * @return итоговый валидатор
     */
    fun min(min: Double) = Validator<Double> { value ->
        if (value >= min) {
            invalid("Число должно быть меньше чем $value")
        } else {
            valid()
        }
    }

    /**
     * Проверяет что строка является числом. То есть попросту проверяет формат строки,
     * что бы тот был числом
     */
    val FORMAT = Validator<String> { value ->
        if (value.toDoubleOrNull() == null) {
            invalid("Не верный формат числа")
        } else {
            valid()
        }
    }

    /**
     * Проверяет что бы заданное число не было равно нулю.
     */
    val NON_ZERO = Validator<Double> { if (it == 0.0) invalid("Число должно быть отличным от нуля") else valid() }

    /**
     * Проверяет, что бы в числе в виде текста было не больше определенного кол. цифор после запятой.
     * Максимальным кол. цифр после запятой является [max]
     *
     * @param max максимальное кол. цифор после запятой
     * @return итоговый текстовый валидатор
     */
    fun forMaxAfterPoint(max: Int): Validator<String> {
        if (max == 0) {
            return IntegerValidator.FORMAT
        }
        require(max > 0) { "Not allow max=$max" }

        return FORMAT and Validator { value ->
            if (getAfterPoint(value) > max) {
                invalid("Слишком много знаков после запятой")
            } else {
                valid()
            }
        }
    }
}

val Validator<Double>.text: Validator<String>
    get() =
        object : Validator<String> {
            override fun valid(value: String): ValidationResult {
                val d = value.toDoubleOrNull() ?: return ValidationResult.invalid("Не верный формат числа")
                return this@text.valid(d)
            }
        }

fun <T : Validated> T.connectWith(button: Button): T {
    onValidChange {
        button.enabled = it
    }
    return this
}

fun <T : Validated> T.addToValidator(validator: MultiValidator): T {
    validator.addValidated(this)
    return this
}
