package org.tlsys.gateway

import org.tlsys.admin.GatewayProvider
import org.tlsys.serialization.Gateway

class SMSRuGatewayDialog(new: Boolean, gateway: Gateway?) : AbstractSMSDialog(new = new, gateway = gateway) {
    override fun getGatewayProvider(): GatewayProvider = GatewayProvider.SMS_RU

    override suspend fun testClick(gateway: Gateway) {
        SMSGatewayTestDialog.show(gateway)
    }

    companion object {
        suspend fun show(new: Boolean, gateway: Gateway?): Gateway? =
            SMSRuGatewayDialog(new = new, gateway = gateway).showAndGetResult()
    }
}
