package org.tlsys.core

import org.tlsys.core.api.ApiService
import org.tlsys.core.api.ImportService
import org.tlsys.core.documents.JBonus
import org.tlsys.core.documents.JDocument
import org.tlsys.core.documents.JPosition
import org.tlsys.core.promotion.AdminPromotionService
import org.tlsys.core.search.SearchElement
import org.tlsys.dto.DtoSet
import org.tlsys.dto.reg_admin_extensionLib
import org.tlsys.interrogation.InterrogationWebService
import org.tlsys.json.reg

const val CORE_INVOKATOR = "Core Admin Extension"
const val DOCUMENTS_SERVICE = "DOCUMENTS"
const val PROMOTION_SERVICE = "PROMOTION"
const val PAYDEBTSERVICE_SERVICE = "PAYDEBTSERVICE_SERVICE"
const val API_SERVICE = "API"
const val REPORT_SERVICE = "REPORT_SERVICE"
const val SEARCH_SERVICE = "SEARCH_SERVICE"
const val INTERROGATION_SERVICE = "INTERROGATION_SERVICE"
const val IMPORT_SERVICE = "IMPORT_SERVICE"

object core_dto_set : DtoSet() {
    override fun regDto() {
        JDocument.reg()
        JPosition.reg()
        JBonus.reg()
        ApiService.set.reg()
        AdminPromotionService.DTO.reg()
        PayDebtService.CompanyDebt.reg()
        PayDebtService.CompanyPay.reg()
        SearchElement.MemberSearchElement.reg()
        InterrogationWebService.Interrogation.reg()
        ImportService.ExcelColumn.reg()
        ImportService.MemberInfo.reg()
        ImportService.ImportResult.reg()
    }

    override val subSet: List<DtoSet> = listOf(reg_admin_extensionLib)

}