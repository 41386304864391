package org.tlsys.admin

import kotlinx.serialization.Serializable
import org.tlsys.DateDuration
import org.tlsys.Percent

@Serializable
class PercentWithDate(
    val percent: Percent,
    val start: DateDuration,
    val end: DateDuration?,
)
