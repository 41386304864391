package org.tlsys.core

import org.tlsys.admin.core.Remote
import kotlin.reflect.KClass

@JsExport
class RemoteImpl : Remote {
    override fun <T : Any> getService(type: KClass<T>, invokator: String, setvice: String): T =
        buildRemoteService2(type, invokator, setvice)
}
