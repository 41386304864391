package org.tlsys.admin

import org.tlsys.admin.form.ValidationResult
import org.tlsys.core.AsyncIterator
import org.tlsys.dto.MemberDTO

val SELECTED_SEARCH_ATTRIBUTE = "SELECTED_SEARCH_ATTRIBUTE"

interface MemberListProvider {

    class Filter(val sex: Boolean?, val withTags: List<Long>, val withoutTags: List<Long>) {
        constructor() : this(sex = null, withoutTags = emptyList(), withTags = emptyList())
    }

    val title: String
    val serachBy: String
    val source: org.tlsys.dto.SearchSource
    suspend fun get(search: String, filter: Filter): AsyncIterator<MemberDTO>
    fun valid(it: String): ValidationResult
}