package org.tlsys.frontol.dto

import org.tlsys.Dto
import org.tlsys.json.*

@Dto
data class ProcessingConfig(
    var autoCreateCard: JBoolean = true.jdto,
    var autoActivateCard: JBoolean = true.jdto,
    var allowDropByPhone: JBoolean = true.jdto,
    var autoActivatePhone: JBoolean = true.jdto,
    var autoCreatePhone: JBoolean = true.jdto,
    var smsCodeLength: JInt = 4.jdto,
    var verifyOnPhonePay: JBoolean = false.jdto,
    var smsCodeTemplate: JString = "%CODE%".jdto,
    var enterByPhone: JBoolean = true.jdto,
    var verifyOnPhoneCreate: JBoolean = true.jdto,
    var verifyOnPhoneAlways: JBoolean = false.jdto,
    var maxBonusPay: JLong = 100_00L.jdto,
    var cardPrefix: JList<JString> = emptyList<JString>().jdto(),
    var allowUnkownCard: JBoolean = false.jdto,
    var showFIO: JBoolean = true.jdto,
    var showPhone: JBoolean = true.jdto,
    var showCards: JBoolean = true.jdto,
    var printInSlipLoginInfo: JBoolean = true.jdto,
    var useGoodsArticle: JBoolean = true.jdto,
) : JDTO {
    companion object : JDTOFactory<ProcessingConfig> {
        override val type: String
            get() = "ProcessingConfig"

        override fun read(node: JsonNode): ProcessingConfig =
            ProcessingConfig(
                autoCreateCard = node["autoCreateCard"]!!.boolean().jdto,
                autoActivateCard = node["autoActivateCard"]!!.boolean().jdto,
                allowDropByPhone = node["allowDropByPhone"]!!.boolean().jdto,
                autoActivatePhone = node["autoActivatePhone"]!!.boolean().jdto,
                autoCreatePhone = node["autoCreatePhone"]!!.boolean().jdto,
                smsCodeLength = node["smsCodeLength"]!!.int().jdto,
                verifyOnPhonePay = node["verifyOnPhonePay"]!!.boolean().jdto,
                smsCodeTemplate = node["smsCodeTemplate"]!!.string().jdto,
                enterByPhone = node["enterByPhone"]!!.boolean().jdto,
                verifyOnPhoneCreate = node["verifyOnPhoneCreate"]!!.boolean().jdto,
                maxBonusPay = node["maxBonusPay"]!!.long().jdto,
                cardPrefix = node["cardPrefix"]!!.map { it!!.string().jdto }.jdto(),
                allowUnkownCard = node["allowUnkownCard"]!!.boolean().jdto,
                showFIO = node["showFIO"]!!.boolean().jdto,
                verifyOnPhoneAlways = node["verifyOnPhoneAlways"]?.boolean()?.jdto ?: false.jdto,
                showPhone = node["showPhone"]!!.boolean().jdto,
                showCards = node["showCards"]!!.boolean().jdto,
                printInSlipLoginInfo = node["printInSlipLoginInfo"]!!.boolean().jdto,
                useGoodsArticle = node["useGoodsArticle"]!!.boolean().jdto,
            )

        override fun write(obj: ProcessingConfig): JsonNode =
            JsonNode.obj()
                .set("autoCreateCard", obj.autoCreateCard.value)
                .set("autoActivateCard", obj.autoActivateCard.value)
                .set("allowDropByPhone", obj.allowDropByPhone.value)
                .set("autoActivatePhone", obj.autoActivatePhone.value)
                .set("autoCreatePhone", obj.autoCreatePhone.value)
                .set("smsCodeLength", obj.smsCodeLength.value)
                .set("verifyOnPhonePay", obj.verifyOnPhonePay.value)
                .set("smsCodeTemplate", obj.smsCodeTemplate.value)
                .set("enterByPhone", obj.enterByPhone.value)
                .set("verifyOnPhoneCreate", obj.verifyOnPhoneCreate.value)
                .set("verifyOnPhoneAlways", obj.verifyOnPhoneAlways.value)
                .set("maxBonusPay", obj.maxBonusPay.value)
                .set("cardPrefix", obj.cardPrefix.value.map { JsonNode.string(it.value) }.json())
                .set("allowUnkownCard", obj.allowUnkownCard.value)
                .set("showFIO", obj.showFIO.value)
                .set("showPhone", obj.showPhone.value)
                .set("showCards", obj.showCards.value)
                .set("printInSlipLoginInfo", obj.printInSlipLoginInfo.value)
                .set("useGoodsArticle", obj.useGoodsArticle.value)
    }

    override val factory: JDTOFactory<JDTO>
        get() = asDefault
}
