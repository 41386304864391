package org.tlsys

import kotlinx.browser.window

object Env {
    val core2Url: String
        get() = window.asDynamic()["core2_url"]?.unsafeCast<String?>()?.removePrefix("/") ?: ""
    val coreUrl: String
        get() = window.asDynamic()["core_url"]?.unsafeCast<String?>()?.removePrefix("/") ?: ""
    val adminBEUri: String
        get() = window.asDynamic()["admin_be_uri"]?.unsafeCast<String?>()?.removePrefix("/") ?: ""
    val adminOldBEUri: String
        get() = window.asDynamic()["admin_old_be_uri"]?.unsafeCast<String?>()?.removePrefix("/") ?: ""
}
