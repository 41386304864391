package org.tlsys.admin.ui

import org.tlsys.*
import org.tlsys.admin.core.Services
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.style
import org.tlsys.ui.*
import org.tlsys.api.MemberTagService
import org.tlsys.dto.MemberTagDTO
import org.tlsys.json.jdto
import org.tlsys.ui.on
import org.w3c.dom.HTMLElement
import kotlinx.browser.document
import kotlinx.dom.addClass
import pw.binom.web.layout.*

class AddTagBtn : DivComponentWithLayout() {
    private val icon = document.createElement("i").unsafeCast<HTMLElement>().also {
        it.appendTo(layout, grow = 0, shrink = 0)
        it.innerHTML = "add"
        it.addClass("material-icons")
    }
    val eventClick = EventElement()

    init {
        dom.style.apply {
            backgroundColor = "#e4e4e4"
            padding = "3px 7px"
            borderRadius = 16.px
            padding = 6.px
            margin = 5.px
        }
        icon.style.apply {
            color = "rgba(0,0,0,0.6)"
            fontSize = 17.px
            cursor = "pointer"
            userSelect7 = "none"
        }
        icon.on("click") {
            eventClick.dispatch()
        }
        layout.alignItems = FlexLayout.AlignItems.Center
    }
}

abstract class PanelItem<T>(val item: T, readOnly: Boolean) : DivComponentWithLayout() {
    private val text = Span().appendTo(layout)
    val eventClose = EventElement()
    protected fun setText(text: String) {
        this.text.text = text
    }

    init {
        dom.style.apply {
            backgroundColor = "#e4e4e4"
            padding = "3px 7px"
            borderRadius = 16.px
            padding = "6px 10px"
            margin = 5.px
            overflow = "hidden"
        }
        this.text.dom.style.apply {
            color = "rgba(0,0,0,0.6)"
            fontFamily = Styles.DEFAULT_TEXT_FONT
            fontSize = Styles.DEFAULT_FONT_SIZE.px
            userSelect7 = "none"
            whiteSpace = "nowrap"
        }

        if (!readOnly) {
            val icon = document.createElement("i").also {
                layout.add(it) {
                    grow = 0
                    shrink = 0
                }
                it.innerHTML = "close"
                it.addClass("material-icons")
            }

            icon.style.apply {
                color = "rgba(0,0,0,0.6)"
                fontSize = 17.px
                cursor = "pointer"
                marginLeft = 5.px
                userSelect7 = "none"
            }
            icon.on("click") {
                eventClose.dispatch()
            }
        }
        layout.alignItems = FlexLayout.AlignItems.Center
    }
}

class Tag(val tag: MemberTagDTO, readOnly: Boolean) : PanelItem<MemberTagDTO>(tag, readOnly) {

    init {
        setText(tag.value)
    }
}

open class TagPanel(val readOnly: Boolean) :
    DivComponentWithLayout(direction = FlexLayout.Direction.Row, alignItems = FlexLayout.AlignItems.Center) {
    val list = ArrayList<Tag>()
    private val memberTagService by Services.byClass(MemberTagService::class)
    open suspend fun addTag(tags: List<MemberTagDTO>) {
        tags.forEach {
            val t = Tag(it, readOnly).appendTo(layout, grow = 0, shrink = 0)
            t.eventClose.on {
                async2 {
                    removeTag(t)
                }
            }
            Effects.createInline(t.dom, 300)
            list += t
        }
    }

    fun clear() {
        list.forEach {
            it.dom.remove()
        }
        list.clear()
    }

    init {
        layout.apply {
            direction = FlexLayout.Direction.Row
            wrap = FlexLayout.Wrap.Enable
            alignItems = FlexLayout.AlignItems.Center
        }

        if (!readOnly) {
            val addBtn = AddTagBtn().appendTo(layout, grow = 0, shrink = 0)
            addBtn.dom.style.order = "1"

            addBtn.eventClick.on {
                async2 {
                    val ids = TagEditDialog.show(selected = emptyList(), hide = this.list.map { it.tag.id })
                    if (ids == null) {
                        console.info("canceled!")
                        return@async2
                    }
                    console.info("Tags selected! ${ids}")
                    addTag(memberTagService.getTagList(ids.map { it.jdto }.jdto()).await())
                }
            }
        }
    }

    protected open suspend fun removeTag(tag: Tag) {
        list -= tag
        async2 {
            Effects.removeInline(tag.dom, 300).await()
            tag.dom.remove()
        }
    }

    fun addTag(tag: MemberTagDTO) {
        val t = Tag(tag, readOnly).appendTo(layout, grow = 0, shrink = 0)
        t.eventClose.on {
            async2 {
                removeTag(t)
            }
        }
        list += t
    }
}