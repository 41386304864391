package org.tlsys.json

class JRequest(
        val service: String,
        val method: String,
        val args: JsonNode
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<JRequest> {
        override val type: String
            get() = "JRequest"

        override fun read(node: JsonNode) =
                JRequest(
                        service = node["service"]!!.string(),
                        method = node["method"]!!.string(),
                        args = node["arguments"]!!
                )

        override fun write(obj: JRequest) =
                JsonNode.obj()
                        .set("service", obj.service.json)
                        .set("method", obj.method.json)
                        .set("arguments", obj.args)

    }



    fun arguments() = JsonFactory.readArray<JDTO>(args)
}