package org.tlsys.settings

import org.tlsys.OCompany
import org.tlsys.OSession
import org.tlsys.admin.SettingItem
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.AbstractPage
import org.tlsys.admin.ui.BigCheckboxNoText
import org.tlsys.admin.ui.SettingsPlane
import org.tlsys.api.DEFAULT_SESSION
import org.tlsys.api.MUTABLE_DEFAULT_SESSION
import org.tlsys.api.RemoteCompanyService
import org.tlsys.async2
import org.tlsys.json.jdto
import org.tlsys.ui.Page
import pw.binom.web.ScrollController
import pw.binom.web.layout.*

class CartSettings : AbstractPage() {

    object CartSettingItem : SettingItem {
        override val uri: String
            get() = "cart"
        override val title: String
            get() = "Корзина"

        override suspend fun getPage(): Page = CartSettings()
    }

    override suspend fun getTitle(): String = "Корзина"

    private val settings = SettingsPlane().appendTo(contentLayout)
    private val scroll = ScrollController(settings.dom)

    val showFio = settings.add("Включить механизм корзины", BigCheckboxNoText())

    private val remoteCompanyService by Services.byClass(RemoteCompanyService::class)

    override suspend fun onInit() {
        super.onInit()
        showFio.component.checked = DEFAULT_SESSION!!.company.cartEnabled

        showFio.component.EVENT_CHANGED.on {
            async2 {
                remoteCompanyService.setCartEnabled(showFio.component.checked.jdto)
                val session = DEFAULT_SESSION!!
                MUTABLE_DEFAULT_SESSION = OSession(
                    user = session.user,
                    company = OCompany(
                        id = session.company.id,
                        name = session.company.name,
                        cartEnabled = showFio.component.checked,
                        osmiCardEnabled = session.company.osmiCardEnabled,
                        cardPrEnabled = session.company.cardPrEnabled,
                    ),
                    group = session.group,
                    key = session.key,
                    exPermissions = session.exPermissions,
                    permissions = session.permissions,
                )
            }
        }
    }
}
