package org.tlsys.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div
import org.tlsys.styles.CardFlowStyles

@Composable
fun CardArray(func: @Composable () -> Unit) {
    Div(attrs = { classes(CardFlowStyles.CARD400) }) {
        func()
    }
//    DivFlex(direction = FlexDirection.Row, wrap = FlexWrap.Wrap) {
//        func()
//    }
}
