package org.tlsys.goods

import org.tlsys.dto.DtoSet
import org.tlsys.dto.reg_admin_extensionLib
import org.tlsys.json.JsonFactory
import org.tlsys.json.reg

val OWNER = "GoodsService"
val SHOW_GOODS_LIST = "SHOW_GOODS_LIST"
val SHOW_GOODS_DICTIONARY = "SHOW_GOODS_DICTIONARY"

object goods_dto_set:DtoSet(){
    override fun regDto() {
        DTOGood.reg()
        DTOGoodGroup.reg()
        DTOClassifier.reg()
        DTOHistory.reg()
        DTODictionary.reg()
        DTOImportSettings.reg()
    }

    override val subSet: List<DtoSet> = listOf(reg_admin_extensionLib)
}