package org.tlsys.core.promotion.externalScriptPromotion

import org.tlsys.ExternalUiMethods
import org.tlsys.admin.form.connectWith
import org.tlsys.admin.ui.SaveCancelBody
import org.tlsys.admin.ui.TitleDialog
import org.tlsys.ui.*
import org.w3c.dom.HTMLDivElement
import pw.binom.url.URI
import pw.binom.web.layout.*

class ExternalOkDialog(title: String, width: String, height: String, val url: URI, val data: Any?) :
    TitleDialog(title = title) {
    private val body = SaveCancelBody(cancelVisible = false, okText = "OK").appendTo(super.layout)
    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout
    private val frame = UiContainerExternalUI().appendTo(layout)

    private var result: Any? = null

    init {
        frame.config.data = data
        dom.style.width = width
        dom.style.height = height
        frame.validator.connectWith(body.okBtn)
        body.ok {
            result = frame.call(ExternalUiMethods.OK_CLICK, null)
            close()
        }
    }

    override suspend fun onInit() {
        super.onInit()
        frame.loadPage(url)
    }

    companion object {
        suspend fun show(title: String, width: String, height: String, url: URI, data: Any?): Any? {
            val d = ExternalOkDialog(
                title = title,
                width = width,
                height = height,
                url = url,
                data = data,
            )
            d.show()
            return d.result
        }
    }
}
