package org.tlsys.goods.dialogs

import org.tlsys.admin.core.LayoutNavigator
import org.tlsys.admin.core.Services
import org.tlsys.admin.form.Validator
import org.tlsys.admin.ui.*
import org.tlsys.emptyAsyncIterator
import org.tlsys.goods.api.GoodsService
import org.tlsys.goods.services.searchClassifierIterator
import org.tlsys.goods.services.searchGoodsIterator
import org.tlsys.goods.services.searchGroupsIterator
import org.tlsys.goods.views.GroupView
import org.tlsys.lex.SimpleParse
import org.tlsys.px
import org.w3c.dom.HTMLDivElement
import pw.binom.web.layout.FlexLayout
import pw.binom.web.layout.appendTo
import kotlin.js.Promise

class GoodsSelectDialog private constructor(
    goods: Boolean,
    groups: Boolean,
    classifiers: Boolean,
) : TitleDialog("Выбор товара") {
    private val body = SaveCancelBody(okText = "Выбрать").appendTo(super.layout, grow = 1, shrink = 1)

    override val layout: FlexLayout<HTMLDivElement>
        get() = body.componentsLayout

    val toolsPlane = ToolsPlane(usingSearch = true).appendTo(layout, grow = 0, shrink = 0).also {
        it.search!!.addTextValidator(
            Validator {
                try {
                    if (it.isBlank() || SimpleParse.parse(it) != null) {
                        // парсим лексемы
                        valid() // если какие-то лексемы найдены, то все ок, начит все верно
                    } else {
                        invalid("Другая ошибка") // результат null. Не порядок
                    }
                } catch (e: dynamic) {
                    invalid("Ошибка") // случиласт какая-то ошибка. Не порядок
                }
            },
        )
    }

    var view = ComponentView().appendTo(layout, grow = 1, shrink = 1)
    var fragment = GroupView(
        groupIt = emptyAsyncIterator(),
        goodIt = emptyAsyncIterator(),
        classifierIt = emptyAsyncIterator(),
        deleteGroup = null,
        deleteGood = null,
        rootUrl = null,
        selectOption = null,
        allowRename = false,
        emptyText = "Введите поисковый запрос",
    )

    private var selected: Any? = null
    private val goodsService by Services.byClass(GoodsService::class)

    init {
        dom.style.apply {
            width = 800.px
            height = 500.px
        }
        body.okBtn.enabled = false

        toolsPlane.search!!.onEnter {
            if (!toolsPlane.search!!.valid) {
                return@onEnter
            }
            body.okBtn.enabled = false

            fragment = GroupView(
                groupIt = if (groups) {
                    goodsService.searchGroupsIterator(
                        search = toolsPlane.search!!.text,
                        minForLoad = 60,
                        maxPart = 160,
                    )
                } else {
                    emptyAsyncIterator()
                },
                goodIt = if (goods) {
                    goodsService.searchGoodsIterator(
                        search = toolsPlane.search!!.text,
                        minForLoad = 60,
                        maxPart = 160,
                    )
                } else {
                    emptyAsyncIterator()
                },
                classifierIt = if (classifiers) {
                    goodsService.searchClassifierIterator(
                        search = toolsPlane.search!!.text,
                        minForLoad = 60,
                        maxPart = 160,
                    )
                } else {
                    emptyAsyncIterator()
                },
                deleteGroup = null,
                deleteGood = null,
                rootUrl = null,
                allowRename = false,
                emptyText = "Ничего не найдено",
                selectOption = {
                    selected = it
                    body.okBtn.enabled = true
                },
            )

            view.set2(fragment)
        }

        view.set2(fragment)

        body.okBtn.onClick {
            close()
        }

        body.cancelBtn.onClick {
            selected = null
            close()
        }
    }

    override suspend fun onStart() {
        super.onStart()
        toolsPlane.search!!.dom.focus()
    }

    override fun onEscapeClick() {
        super.onEscapeClick()
        selected = null
        close()
    }

    companion object {
        private val layoutNavigator by Services.byClass(LayoutNavigator::class)
        fun show(goods: Boolean, groups: Boolean, classifiers: Boolean) = Promise<Any?> { d, c ->
            val dd = GoodsSelectDialog(
                goods = goods,
                groups = groups,
                classifiers = classifiers,
            )
            layoutNavigator.show(dd) {
                d(dd.selected)
            }
        }
    }
}
