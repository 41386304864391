package org.tlsys.core

import androidx.compose.runtime.NoLiveLiterals
import org.tlsys.*
import org.tlsys.admin.core.Proxy
import org.tlsys.admin.core.createProxyHandler
import org.tlsys.admin.core.newInstance
import org.tlsys.admin.serialization.parseResponse
import org.tlsys.admin.serialization.toJson
import org.tlsys.json.*
import org.w3c.xhr.XMLHttpRequest
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.coroutines.suspendCoroutine
import kotlin.js.Promise
import kotlin.reflect.KClass

suspend fun sendAndResive3(path: String, params: Map<String, Any?>): Any? {
    return suspendCoroutine { con ->
        val url = "call2"
        val rr = XMLHttpRequest()
        rr.withCredentials = true

        rr.onreadystatechange = {
            if (rr.readyState == XMLHttpRequest.DONE) {
                if (rr.status == 200.toShort()) {
                    val response = parseResponse(rr.responseText)
                    if (!response.success) {
                        con.resumeWithException(response.result as Throwable)
                    } else {
                        con.resume(response.result)
                    }
                } else {
                    con.resumeWithException(RuntimeException("Unknown CODE=${rr.status}"))
                }
            }
        }

        rr.open(method = "POST", url = "${Env.adminOldBEUri}${AuthNetworkConsts.CALL2}$path", async = true)
        rr.send(params.toJson())
    }
}

private fun sendAndResive(invakator: String, request: JRequest) = Promise<JResponce> { d, c ->
    val rr = XMLHttpRequest()
    rr.withCredentials = true

    rr.onreadystatechange = {
        if (rr.readyState == XMLHttpRequest.DONE) {
            if (rr.status == 200.toShort()) {
                d(JsonFactory.read<JResponce>(JsonNode.parse(rr.responseText)))
            } else {
                c(RuntimeException("Unknown CODE=${rr.status}"))
            }
        }
    }
    rr.open(method = "POST", url = "${Env.adminOldBEUri}${AuthNetworkConsts.CALL}$invakator", async = true)
//    rr.setRequestHeader("Content-Type", "application/json; charset=utf-8")
    rr.send(JsonFactory.write(request).json())
}
@NoLiveLiterals
fun <T : Any> buildRemoteService2(clazz: KClass<T>, invocator: String, service: String): T {
    val instance = clazz.newInstance()
    val handler = createProxyHandler(get = { target, property, receiver ->
        if ("$" in property) {
            return@createProxyHandler instance.asDynamic()[property]
        }
        if (property == "CLASS") {
            return@createProxyHandler clazz
        }
        val b = createProxyHandler(
            apply = PROXY_INVOCATION@{ target, thisArg, argumentsList ->
                val request = JRequest(
                    service = service,
                    method = property,
                    args = JsonFactory.writeArray(argumentsList.unsafeCast<Array<out JDTO?>>().toList()),
                )
                return@PROXY_INVOCATION AsyncResult(
                    async2 {
                        val response = sendAndResive(invocator, request).await()
                        if (response.error) {
                            if (response.obj == null) {
                                throw RuntimeException("Server Error. invocation: $invocator, service: $service, method: $property")
                            }
                            val oo = response.obj
                            throw JsonFactory.read<JDTO>(oo!!).unsafeCast<Throwable>()
                        }
                        val responseObj = response.obj?.let { JsonFactory.read<JDTO>(it) }
                        return@async2 responseObj
                    },
                )
            },
        )
        b.asDynamic()["CLASS"] = clazz
        Proxy(js("(function(){})"), b)
    })
    handler.asDynamic()["CLASS"] = clazz
    val proxy = Proxy(clazz.newInstance(), handler).unsafeCast<T>()
    proxy.asDynamic()["CLASS"] = clazz
    return proxy
}

fun <T : Any> buildRemoteService(clazz: KClass<T>, invocator: String, service: String): T {
    val invokator = ExtensionConnect.service(name = service, serviceName = invocator)

    val handler = createProxyHandler(get = { target, property, receiver ->
        val b = createProxyHandler(apply = { target, thisArg, argumentsList ->
            console.info("Call $property")
            val promise = invokator.call2<JDTO?>(property, *(argumentsList.unsafeCast<Array<out JDTO?>>()))
                .unsafeCast<Promise<JDTO?>>()
            return@createProxyHandler AsyncResult(promise)
        })

        Proxy(js("(function(){})"), b)
    })

    return Proxy(clazz.newInstance(), handler).unsafeCast<T>()
}

fun <T : Any> dynamicProxy(clazz: KClass<T>, handler: (methodName: String, arguments: Array<out Any?>) -> Any?): T {
    val propertyHandler = createProxyHandler(get = { target, property, receiver ->
        val functionHandler = createProxyHandler(apply = { target, thisArg, argumentsList ->
        })

        Proxy(js("(function(){})"), functionHandler)
    })
    return Proxy(clazz.newInstance(), propertyHandler).unsafeCast<T>()
}
