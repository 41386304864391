package org.tlsys

object GatewayUtils {
    fun replaceText(body: String, message: String, firstName: String, middleName: String?, lastName: String): String {
        val fullName = "$lastName $firstName${middleName?.let { " $it" } ?: ""}"
        return body
            .replace("%MESSAGE%", message)
            .replace("%FULL_NAME%", fullName)
            .replace("%FIO%", fullName)
            .replace("%FIRST_NAME%", firstName)
            .replace("%MIDDLE_NAME%", middleName ?: "")
            .replace("%LAST_NAME%", lastName)
    }
}
