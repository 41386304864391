package org.tlsys.search

import kotlinx.browser.document
import kotlinx.dom.removeClass
import org.tlsys.addClass
import org.tlsys.admin.events.EventElement
import org.tlsys.admin.ui.Styles
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLLabelElement
import pw.binom.web.layout.*
import kotlin.random.Random

class SearchField : DivComponentWithLayout(direction = FlexLayout.Direction.Column) {
    companion object {
        private val STYLE = CSS.style {
            //            marginLeft = 265.px
//            marginRight = 220.px
//            marginTop = 6.px
            margin = "6px 220px 0px 200px"
            position = "relative"

            "input" {
                outline = "none"
                boxShadow = "none"
                backgroundColor = "rgba(255, 255, 255, 0.18)"
                border = "none"
                color = "rgba(255,255,255,.75)"
                height = 48.px
                fontSize = 15.px
                paddingLeft = 50.px
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontWeight = "500"
                "::placeholder" then {
                    color = "rgba(255,255,255,.75)"
                    transition = "all 300ms"
                }
                transition = "all 300ms"

                ":focus" then {
                    color = "#FFF"
                    "::placeholder" then {
                        color = "#FFF"
                    }
                }
            }
            "label" {
                position = "absolute"
                left = 15.px
                top = 14.px
                color = "rgba(255,255,255,.75)"
                fontSize = 20.px
                transition = "all 300ms"
                ".focus" then {
                    color = "#FFF"
                }
            }
        }.name
    }

    val input = document.createElement("input").unsafeCast<HTMLInputElement>()
    val icon = document.createElement("label").unsafeCast<HTMLLabelElement>()
    val changeEvent = EventElement()

    var value: String
        get() = input.value
        set(value) {
            input.value = value
        }

    init {
        input.id = "el${Random.nextLong()}"
        dom.addClass(STYLE)
        input.addClass("browser-default")
        input.type = "text"
        input.placeholder = "Поиск"
        icon.innerHTML = "search"
        icon.addClass("material-icons")
        icon.htmlFor = input.id
        dom.appendChild(icon)
        input.appendTo(layout, grow = 0, shrink = 1)
        input.addEventListener("focus", {
            icon.addClass("focus")
        })

        input.addEventListener("blur", {
            icon.removeClass("focus")
        })

        input.addEventListener("change", {
            changeEvent.dispatch()
        })
    }
}
