package org.tlsys.members.search

import org.tlsys.admin.MemberListProvider
import org.tlsys.admin.core.Services
import org.tlsys.api.CardsService
import org.tlsys.async2
import org.tlsys.cms.Extensions
import org.tlsys.cms.search.SearchSource
import org.tlsys.dto.MemberDTO
import org.tlsys.json.jdto
import org.tlsys.lex.SimpleParse
import kotlin.js.Promise

/**
 * Поиск по номеру карты
 */
object CardSearchSource : SearchSource {

    override val title: String = "Код карты"

    override val searchBy: String = "по коду карты"

    override val uid: String = "MemberCard"

    /**
     * Проверяет
     */
    override fun valid(text: String): String? {
        try {
            if (SimpleParse.parse(text) != null) {
                // парсим лексемы
                return null // если какие-то лексемы найдены, то все ок, начит все верно
            }
            return "Другая ошибка" // результат null. Не порядок
        } catch (e: dynamic) {
            return "Ошибка" // случиласт какая-то ошибка. Не порядок
        }
    }

    override fun serarch(text: String, filter: MemberListProvider.Filter): SearchSource.SearchResult =
        Result(text) // Просто возвращаем результат поиска. Сам поиск будет в классе [Result]

    init {
        Extensions.membersSearch.extend(this) // регестрируем поисковый источник
    }

    class Result(val exp: String) : SearchSource.SearchResult {
        val cardsService by Services.byClass(CardsService::class)
        override fun getResult(max: Int, offset: Int): Promise<List<MemberDTO>> =
            async2 {
                cardsService.searchMemberByCard(exp = exp.jdto, max = max.jdto, offset = offset.jdto).await().value
            }

        override fun close() {
            // пусто. Закрывать нечего
        }
    }
}
