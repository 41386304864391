package org.tlsys

external class ExchangeMessage {
    var type: String?
    var method: String
    var args: Any?
    var rq: Int?
}

enum class ExternalUiMethods(val code: String) {
    CONFIG("config"),
    VALID_UPDATE("valid_update"),
    START("start"),
    GET_STATE("get_state"),
    SET_STATE("set_state"),
    STOP("stop"),
    YES_NO_DIALOG("yes_no_dialog"),
    ON_CANCEL_DIALOG("ok_cancel_dialog"),
    OK_DIALOG("ok_dialog"),
    YES_CLICK("yes_click"),
    NO_CLICK("no_click"),
    OK_CLICK("ok_click"),
    CANCEL_CLICK("cancel_click"),
    ;

    companion object {
        private val byCodes = values().associateBy { it.code }
        fun find(code: String) = byCodes[code]
    }
}

enum class MessageTypes(val code: String) {
    REQUEST("request"),
    RESPONSE_OK("response_ok"),
    RESPONSE_FAIL("response_fail"),
    EVENT("event");
}
