package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.browser.window
import org.tlsys.async2
import org.tlsys.css.CSS
import org.tlsys.css.animate
import org.tlsys.px
import org.tlsys.ui.*
import pw.binom.web.layout.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.MouseEvent
import pw.binom.web.AbstractComponent
import pw.binom.web.ScrollController
import kotlin.js.Promise

private val itemStyle = CSS.genName()
private val hrStyle = CSS.genName()
val backDivColorAlpha = 0.2f
private val style = CSS.style {
    background = "#424a54"
    boxShadow = "0px 3px 5px 0px rgba(0,0,0,0.27)"
    borderRadius = 4.px
    position = "absolute"

    ".$hrStyle" {
        background = "#666d78"
        height = 1.px
    }
    ".$itemStyle" {
        paddingLeft = 20.px
        paddingRight = 20.px
        paddingTop = 16.px
        paddingBottom = 16.px
        cursor = "pointer"
        ":hover span" then {
            color = "#ffffff"
        }
        "span" {
            color = "#a9b2bd"
            transitionProperty = "color"
            transitionDuration = "100ms"
        }
    }
}

private val backStyle = CSS.style {
    position = "absolute"
    backgroundColor = "rgba(0,0,0,$backDivColorAlpha)"
    top = 0.px
    left = 0.px
    bottom = 0.px
    right = 0.px
}

class PopupMenu private constructor(val onSelect: (Item) -> Unit) :
    AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    init {
        dom.classList.add(style.name)
    }

    private val scroll = ScrollController(document.createDiv()).also {
        dom.appendChild(it.dom)
    }

    private val scrollLayout = FlexLayout(scroll.dom).apply {
        direction = FlexLayout.Direction.Column
    }

    private var elementCounter = 0

    fun text(text: String, onClick: (() -> Unit)? = null): TextItem {
        val tt = TextItem(text = text, index = elementCounter++)
        tt.dom.appendTo(scrollLayout, grow = 0, shrink = 0)

        tt.dom.addEventListener("click", {
            if (onClick != null) {
                onClick()
            }
            onSelect(tt)
        })
        return tt
    }

    fun hr(): HrItem {
        val tt = HrItem()
        tt.dom.appendTo(scrollLayout, grow = 0, shrink = 0)
        return tt
    }

    interface Item {
        val index: Int
    }

    class HrItem : AbstractComponent<HTMLDivElement>(), Item {
        override val dom: HTMLDivElement = document.createDiv()
        init {
            dom.classList.add(hrStyle)
        }

        override val index: Int
            get() = -1
    }

    class TextItem(text: String, override val index: Int) :
        AbstractComponent<HTMLDivElement>(),
        Item {
        override val dom: HTMLDivElement = document.createDiv()
        init {
            dom.classList.add(itemStyle)
        }

        private val span = Span(text).also {
            dom.appendChild(it.dom)
            it.dom.classList.add(Styles.SIMPLE_TEXT)
        }

        var text: String
            get() = span.text
            set(it) {
                span.text = it
            }
    }

    companion object {
/*        fun show(x: Int, y: Int, builder: (PopupMenu) -> Unit): Promise<Boolean> = async {
            select(x = x, y = y, builder = builder).wait() != null
        }*/

        interface CreatedMenu {
            fun showRight(x: Int, y: Int): Promise<Boolean>
            fun showLeft(x: Int, y: Int): Promise<Boolean>
            fun text(text: String, onClick: (() -> Unit)? = null): TextItem
            fun hr(): HrItem
        }

        fun build(builder: (suspend (PopupMenu) -> Unit)? = null): Promise<CreatedMenu> = async2 {
            val backDiv = document.createDiv()
            backDiv.classList.add(backStyle.name)
            var menu: PopupMenu
            var ex: ((Event) -> Unit)? = null
            fun close() {
                backDiv.animate {
                    duration = 100
                    0 {
                        opacity = 1.0
                    }
                    100 {
                        opacity = 0.0
                    }

                    onEnd {
                        backDiv.parentElement!!.removeChild(backDiv)
                    }
                }.start()
                document.removeEventListener("keydown", ex)
            }

            var dd: ((Boolean) -> Unit)? = null
            ex = {
                if (it.asDynamic().keyCode == 27) {
                    dd!!(false)
                    close()
                }
            }
            document.addEventListener("keydown", ex)

            menu = PopupMenu {
                dd!!(true)
                close()
            }
            if (builder != null) {
                builder(menu)
            }

            return@async2 object : CreatedMenu {
                override fun showLeft(x: Int, y: Int) = Promise<Boolean> { d, c ->
                    dd = d
                    document.body!!.appendChild(backDiv)

                    menu.dom.style.also {
                        it.left = x.px
                        it.top = y.px
                    }

                    show(y = y, d = d)
                }

                override fun text(text: String, onClick: (() -> Unit)?): TextItem = menu.text(text, onClick)

                override fun hr(): HrItem = menu.hr()

                private fun show(y: Int, d: (Boolean) -> Unit) {
                    menu.scroll.dom.style.maxHeight = (window.innerHeight - y.toDouble()).px
                    backDiv.addEventListener("click", { e ->
                        e as MouseEvent
                        if (e.target === backDiv) {
                            d(false)
                            close()
                        }
                    })

                    backDiv.appendChild(menu.dom)

                    backDiv.animate {
                        duration = 100
                        0 {
                            opacity = 0.0
                        }
                        100 {
                            opacity = 1.0
                        }
                    }.start()
                }

                override fun showRight(x: Int, y: Int) = Promise<Boolean> { d, c ->
                    dd = d
                    document.body!!.appendChild(backDiv)

                    menu.dom.style.also {
                        it.right = (window.innerWidth - x.toDouble()).px
                        it.top = y.px
//                        it.maxHeight = (window.innerHeight - y.toDouble()).px
                    }

                    show(y = y, d = d)
                }
            }
        }
    }

    fun select(x: Int, y: Int, builder: (PopupMenu) -> Unit) = Promise<Item?> { d, c ->
    }
}
