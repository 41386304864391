package org.tlsys.jobs.dto

import org.tlsys.dto.DtoSet
import org.tlsys.dto.reg_admin_extensionLib
import org.tlsys.json.reg

object jobs_dto : DtoSet() {
    override val subSet: List<DtoSet> = listOf(reg_admin_extensionLib)
    override fun regDto() {
        JobDTO.reg()
        TaskDTO.reg()
        ExecutionDTO.reg()
    }

}