package org.tlsys.sms

import kotlinx.dom.addClass
import kotlinx.dom.clear
import org.tlsys.admin.SettingItem
import org.tlsys.admin.core.Services
import org.tlsys.admin.ui.*
import org.tlsys.api.SMSManagerService
import org.tlsys.api.text
import org.tlsys.async2
import org.tlsys.await
import org.tlsys.json.jdto
import org.tlsys.px
import org.tlsys.ui.DivLayout
import org.tlsys.ui.Page
import pw.binom.web.layout.*

class SMSAccountListPage : AbstractPage() {

    companion object {
        val URI = "sms"
        val TITLE = "Шлюзы SMS"
    }

    object SMSSettingItem : SettingItem {
        override val uri: String
            get() = URI
        override val title: String
            get() = TITLE

        override suspend fun getPage(): Page = SMSAccountListPage()
    }

    override suspend fun getTitle() = TITLE

//    private val panel = ToolsPlane(usingSearch = false).appendTo(contentLayout, grow = 0, shrink = 0)

    private val smsManagerService by Services.byClass(SMSManagerService::class)

    private val table = MaterialTable().appendTo(contentLayout, grow = 0, shrink = 0)
        .also { table ->
            val header = MaterialTable.Header().appendTo(table)
            MaterialTable.TextColumn("Название").appendTo(header)
            MaterialTable.TextColumn("Метки").appendTo(header)
            MaterialTable.TextColumn("Баланс").appendTo(header)
            MaterialTable.TextColumn("Действия").appendTo(header).widthPx(1)
        }

    override suspend fun onInit() {
        super.onInit()
        smsManagerService.getAccounts().await().forEach {
            Item(it).appendTo(table)
        }
    }

    init {
        AddButton()
            .appendTo(layout = contentLayout, grow = 0, shrink = 0)
            .onClick {
                val result = SMSAccountEditDialog.show(null) ?: return@onClick
                var acc = smsManagerService.createAccount(
                    provider = result.provider.name.jdto,
                    password = result.password.jdto,
                    login = result.login.jdto,
                    name = result.name.jdto,
                ).await()
                acc = smsManagerService.updateTypes(account = acc.id, types = result.type.map { it.name.jdto }.jdto())
                    .await()!!
                val item = Item(acc)
                item.appendTo(table)
                Effects.createItem(item.dom).await()
                Effects.blockRow(item.dom).await()
            }

//        panel.image("add.svg", Styles.ICON_SIZE) {
//            async {
//                val result = SMSAccountEditDialog.show(null) ?: return@async
//                var acc = smsManagerService.createAccount(
//                        provider = result.provider.name.jdto,
//                        password = result.password.jdto,
//                        login = result.login.jdto,
//                        name = result.name.jdto
//                ).await()
//                acc = smsManagerService.updateTypes(account = acc.id, types = result.type.map { it.name.jdto }.jdto()).await()!!
//                val item = Item(acc)
//                list.addFirst(item)
//                Effects.createItem(item.dom).await()
//                Effects.blockRow(item.dom).await()
//            }
//        }
    }

    class TypeItem(text: String) : Span(text) {
        init {
            dom.addClass(Styles.SIMPLE_TEXT)
            dom.style.apply {
                backgroundColor = "#7e8b9c"
                padding = "3px 7px"
                color = "white"
                borderRadius = 10.px
            }
        }
    }

    private inner class Item(var accountDTO: SMSManagerService.AccountDTO) : MaterialTable.Row() {
        //        private val name = Span().appendTo(layout, grow = 1, shrink = 1)
//        val tags = DivLayout().appendTo(layout, grow = 1, shrink = 1)
//        val balance = Span().appendTo(layout, grow = 0, shrink = 0)
        private val name = MaterialTable.TextColumn(accountDTO.name.value).appendTo(this)
        private val tags = MaterialTable.ComponentColumn(DivLayout()).appendTo(this)
        private val balance = MaterialTable.TextColumn("").appendTo(this)
        private val actions = MaterialTable.ComponentColumn(ActionPlace()).appendTo(this)

//        val settings = FloatButton(image = "settings.svg", height = Styles.ICON_SIZE, width = Styles.ICON_SIZE).appendTo(layout, grow = 0, shrink = 0)
//        val delete = FloatButton(image = "delete.svg", height = Styles.ICON_SIZE, width = Styles.ICON_SIZE).appendTo(layout, grow = 0, shrink = 0)

        private fun refresh() {
            async2 {
                balance.text = "Загрузка баланса"
                balance.text = smsManagerService.getBalance(accountDTO.id).await()?.value?.let { "${it / 100f} руб." }
                    ?: "Не удается получить баланс"
            }
            name.text = accountDTO.name.value
            tags.component.dom.clear()
            accountDTO.typesEnum.forEach {
                TypeItem(it.text).appendTo(tags.component.layout, grow = 0, shrink = 0)
            }
        }

        init {
            actions.component.visibleOnHover(this)
            name.dom.addClass(Styles.SIMPLE_TEXT)
            balance.dom.addClass(Styles.SIMPLE_TEXT)
            dom.addClass(Styles.LIST_ITEM_STYLE)
            balance.dom.style.paddingRight = 30.px
            refresh()
            actions.component.iconBtn(MaterialIcons.SETTINGS).onClick {
                async2 {
                    val result = SMSAccountEditDialog.show(accountDTO) ?: return@async2
                    accountDTO = smsManagerService.updateAccount(
                        account = accountDTO.id,
                        name = result.name.jdto,
                        login = result.login.jdto,
                        password = result.password.jdto,
                        provider = result.provider.name.jdto,
                    ).await() ?: TODO()
                    accountDTO = smsManagerService.updateTypes(
                        account = accountDTO.id,
                        types = result.type.map { it.name.jdto }.jdto(),
                    ).await()!!
                    refresh()
                    Effects.blockRow(dom)
                }
            }
            actions.component.iconBtn(MaterialIcons.DELETE).onClick {
                async2 {
                    if (!YesNoDialog.show("Удалить SMS шлюз \"${accountDTO.name.value}\"?").await()) {
                        return@async2
                    }
                    smsManagerService.deleteAccount(accountDTO.id).await()
                    Effects.removeItem(dom).await()
                    removeSelf()
                }
            }
        }
    }
}
