package org.tlsys.ui

import kotlinx.browser.document
import org.w3c.dom.*
import pw.binom.web.AbstractComponent
import pw.binom.web.Component
import pw.binom.web.layout.FlexLayout

class FlexComponent : AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()
    val layout = FlexLayout(dom)
}

fun <T : HTMLElement, V : Component<T>> V.appendTo(dom: HTMLElement): V {
    dom.appendChild(this.dom)
    return this
}
//
// fun <T : HTMLElement, V : Component<T>> V.appendFirstTo(
//    layout: FlexLayout<*>,
//    grow: Int = 1,
//    shrink: Int = 1,
//    basis: Int? = null,
//    align: FlexLayout.FlexItem.AlignSelf = FlexLayout.FlexItem.AlignSelf.Auto
// ): V {
//    layout.addFirst(this.dom) {
//        this.basis = basis
//        this.grow = grow
//        this.shrink = shrink
//        this.align = align
//    }
//
//    return this
// }

// fun <T : HTMLElement, V : Component<T>> V.appendTo(
//    layout: FlexLayout<*>,
//    grow: Int = 1,
//    shrink: Int = 1,
//    basis: Int? = null,
//    align: FlexLayout.FlexItem.AlignSelf = FlexLayout.FlexItem.AlignSelf.Auto
// ): V {
//    layout.add(this.dom) {
//        this.basis = basis
//        this.grow = grow
//        this.shrink = shrink
//        this.align = align
//    }
//
//    return this
// }

// fun <T : HTMLElement> T.appendTo(layout: FlexLayout<*>, grow: Int = 1, shrink: Int = 1, basis: Int? = null): T {
//    layout.add(this) {
//        this.basis = basis
//        this.grow = grow
//        this.shrink = shrink
//    }
//
//    return this
// }

// class FlexLayout<T : HTMLElement>(
//    parent: T,
//    direction: Direction = Direction.Row,
//    alignItems: AlignItems = AlignItems.Stretch
// ) : SimpleLayout<T>(parent), Component<T> {
//
//    constructor(
//        controller: Component<T>,
//        direction: Direction = Direction.Row,
//        alignItems: AlignItems = AlignItems.Stretch
//    ) : this(controller.dom, direction, alignItems)
//
//    private var started = false
//
//    override suspend fun onStart() {
//        Component.callOnStart(dom)
//        started = true
//    }
//
//    override suspend fun onStop() {
//        started = false
//        (0 until dom.childNodes.length)
//            .mapNotNull { dom.childNodes[it] }
//            .mapNotNull { it.asDynamic().CONTROL.unsafeCast<Component<*>?>() }
//            .filterIsInstance<Component<*>>()
//            .forEach { it.onStop() }
//    }
//
//    override val dom: T
//        get() = parent
//
//    companion object {
//        fun layout(
//            direction: Direction = Direction.Row,
//            listener: (FlexLayout<HTMLDivElement>.() -> Unit)? = null
//        ): FlexComponent {
//            val com = FlexComponent()
//            com.layout.direction = direction
//            if (listener !== null)
//                com.layout.listener()
//
//            return com
//        }
//
//        fun div(listener: (FlexLayout<HTMLDivElement>.() -> Unit)? = null): FlexLayout<HTMLDivElement> {
//            val element = document.createDiv()
//            val g = FlexLayout(element)
//            if (listener != null)
//                g.listener()
//            return g
//        }
//    }
//
//    fun clear() {
//        while (parent.hasChildNodes()) {
//            val el = parent.firstChild!!
//            parent.removeChild(parent.firstChild!!)
//            js("delete el.FLEX_ITEM")
//        }
//    }
//
//    private var _visible: Boolean = true
//    override var visible: Boolean
//        get() = _visible
//        set(it) {
//            _visible = it
//            refreshFlexAttr()
//        }
//
//    private fun refreshFlexAttr() {
//        if (_visible) {
//            if (_inline)
//                parent.style.display = "inline-flex"
//            else
//                parent.style.display = "flex"
//        } else {
//            parent.style.display = "none"
//        }
//    }
//
//    private var _inline: Boolean = false
//    var inline: Boolean
//        get() = _inline
//        set(it) {
//            _inline = it
//            refreshFlexAttr()
//        }
//
//    private var _direction: Direction = Direction.Row
//    private var _justifyContent: JustifyContent = JustifyContent.Start
//
//    var justifyContent: JustifyContent
//        get() = _justifyContent
//        set(it) {
//            _justifyContent = it
//            parent.style.justifyContent = it.css
//        }
//
//    private var _alignItems: AlignItems = AlignItems.Stretch
//    private var _wrap: Wrap = Wrap.Disable
//    var alignItems: AlignItems
//        get() = _alignItems
//        set(it) {
//            _alignItems = it
//            parent.style.alignItems = it.css
//        }
//
//    var wrap: Wrap
//        get() = _wrap
//        set(it) {
//            _wrap = it
//            parent.style.flexWrap = it.css
//        }
//
//    private var _alignContent: AlignContent = AlignContent.Stretch
//    var alignContent: AlignContent
//        get() = _alignContent
//        set(it) {
//            _alignContent = it
//            parent.style.alignContent = it.css
//        }
//
//    var direction: Direction
//        get() = _direction
//        set(it) {
//            _direction = it
//            parent.style.flexDirection = it.css
//        }
//
//    init {
//        inline = inline
//        this.direction = direction
//        this.alignItems = alignItems
//        alignContent = alignContent
//        justifyContent = justifyContent
//        refreshFlexAttr()
//        parent.childNodes.length
//        for (f in 0..parent.childNodes.length - 1) {
//            val element = parent.childNodes.get(f)
//            if (element is HTMLElement) {
//                val item = FlexItem(element)
//                js("element.FLEX_ITEM=item")
//            }
//        }
//    }
//
//    fun item(element: HTMLElement): FlexItem {
//        if (element.parentNode !== parent)
//            throw RuntimeException("Element not in layout")
//        return js("element.FLEX_ITEM")
//    }
//
//    private fun prepareElement(element: Element, control: (FlexItem.() -> Unit)?) {
//        val item = FlexItem(element)
//        element.asDynamic().FLEX_ITEM = item
//        if (control != null)
//            item.control()
//    }
//
//    fun <T : HTMLElement> addFirst(element: T, control: (FlexItem.() -> Unit)? = null): T =
//        if (parent.childElementCount == 0)
//            add(element = element, control = control)
//        else
//            addBefore(element = element, before = parent.children.get(0) as HTMLElement, control = control)
//
//    private fun callStart(element: Element) {
//        val com = element.asDynamic().CONTROL ?: return
//        if (com !is Component<*>) return
//        async {
//            com.onStart(Promise.Companion.resolve(Unit))
//        }
//    }
//
//    private fun callStop(element: Element) {
//        val com = element.asDynamic().CONTROL ?: return
//        if (com !is Component<*>) return
//        async {
//            com.onStop(Promise.Companion.resolve(Unit))
//        }
//    }
//
//    fun <T : Element> add(element: T, control: (FlexItem.() -> Unit)? = null): T {
//        prepareElement(element, control)
//        parent.appendChild(element)
//        if (started) {
//            callStart(element)
//        }
//        return element
//    }
//
//    fun <T : HTMLElement> addBefore(element: T, before: HTMLElement, control: (FlexItem.() -> Unit)? = null): T {
//        prepareElement(element, control)
//        parent.insertBefore(node = element, child = before)
//        if (started) {
//            callStart(element)
//        }
//        return element
//    }
//
//    fun <T : HTMLElement> addAfter(element: T, after: HTMLElement, control: (FlexItem.() -> Unit)? = null): T {
//        prepareElement(element, control)
//        parent.insertBefore(node = element, child = after.nextSibling)
//        if (started) {
//            callStart(element)
//        }
//        return element
//    }
//
//    fun <T : HTMLElement> remove(element: T): T {
//        val el = element
//        if (started)
//            callStop(element)
//        item(element).diatach()
//        parent.removeChild(element)
//        js("delete el.FLEX_ITEM")
//        return element
//    }
//
//    enum class Direction(val css: String) {
//        Row("row"),
//        RowReverse("row-reverse"),
//        Column("column"),
//        ColumnReverse("column-reverse")
//    }
//
//    enum class JustifyContent(val css: String) {
//        Start("flex-start"),
//        End("flex-end"),
//        Center("center"),
//        SpaceBetween("space-between"),
//        SpaceAround("space-around")
//    }
//
//    enum class AlignItems(val css: String) {
//        Start("flex-start"),
//        End("flex-end"),
//        Center("center"),
//        Baseline("baseline"),
//        Stretch("stretch")
//    }
//
//    enum class Wrap(val css: String) {
//        Disable("nowrap"),
//        Enable("wrap"),
//        EnableReverse("wrap-reverse")
//    }
//
//    enum class AlignContent(val css: String) {
//        Start("flex-start"),
//        End("flex-end"),
//        Center("center"),
//        SpaceBetween("space-between"),
//        SpaceAround("space-around"),
//        Stretch("stretch")
//    }
//
//    class FlexItem {
//        private var dom: Element? = null
//
//        private var _flexShrink: Int = 1
//
//        var shrink: Int
//            get() = _flexShrink
//            set(it) {
//                _flexShrink = it
//                dom?.style?.flexShrink = it.toString()
//            }
//
//        private var _flexBasis: Int? = null
//        var basis: Int?
//            get() = _flexBasis
//            set(it) {
//                _flexBasis = it
//                this.dom?.style?.flexBasis = if (it == null)
//                    "auto"
//                else if (it <= 0) "${-it}%" else "${it}px"
//            }
//        private var _flexGrow: Int = 0
//        var grow: Int
//            get() = _flexGrow
//            set(it) {
//                dom?.style?.flexGrow = it.toString()
//                _flexGrow = it
//            }
//
//        private var _alignSelf: AlignSelf = AlignSelf.Auto
//
//        var align: AlignSelf
//            get() = _alignSelf
//            set(it) {
//                _alignSelf = it
//                if (dom != null)
//                    dom!!.style.alignSelf = it.css
//            }
//
//        constructor(dom: Element) {
//            this.dom = dom
//            align = align
//            shrink = shrink
//            grow = grow
//            basis = basis
//        }
//
//        fun diatach() {
//            dom!!.style.removeProperty("flex")
//            dom!!.style.removeProperty("align-self")
//        }
//
//        enum class AlignSelf(val css: String) {
//            Auto("auto"),
//            Start("flex-start"),
//            End("flex-end"),
//            Center("center"),
//            Baseline("baseline"),
//            Stretch("stretch"),
//            Normal("normal")
//        }
//    }
// }
//
// val Element.style: CSSStyleDeclaration
//    get() = this.asDynamic().style
