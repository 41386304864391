package org.tlsys.node

import org.tlsys.json.*

class MemberActionDTO(
        val scriptId: JString,
        val actionName: JString,
        val params: JString,
        val needPermissions: JList<JString>) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    protected constructor() : this(JString.EMPTY, JString.EMPTY, JString.EMPTY, JList.empty())

    companion object : AutoJDTOFactory<MemberActionDTO>(MemberActionDTO::class)

    sealed class ResultDTO : JDTO {
        abstract val text: JString

        class InfoDTO(override var text: JString) : ResultDTO() {
            protected constructor() : this(JString.EMPTY)

            override val factory: JDTOFactory<JDTO>
                get() = asDefault

            companion object : AutoJDTOFactory<InfoDTO>(InfoDTO::class)
        }

        class ErrorDTO(override var text: JString) : ResultDTO() {
            protected constructor() : this(JString.EMPTY)

            override val factory: JDTOFactory<JDTO>
                get() = asDefault

            companion object : AutoJDTOFactory<ErrorDTO>(ErrorDTO::class)
        }

        class WarningDTO(override var text: JString) : ResultDTO() {
            protected constructor() : this(JString.EMPTY)

            override val factory: JDTOFactory<JDTO>
                get() = asDefault

            companion object : AutoJDTOFactory<WarningDTO>(WarningDTO::class)
        }
    }
}