package org.tlsys.dto

import org.tlsys.json.*

class CardDTO(
    val enabled: Boolean,
    val id: Long,
    val code: String,
    val account: Long,
    val osmiCardReg: Long?,
    val osmiCardActivate: Long?,
    val cardprDate: Long?,
) : JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<CardDTO> {
        override val type: String
            get() = "CardDTO"

        override fun read(node: JsonNode) = CardDTO(
            enabled = node["enabled"]!!.boolean(),
            id = node["id"]!!.long(),
            code = node["code"]!!.string(),
            account = node["account"]!!.long(),
            osmiCardReg = node["osmiCardReg"]?.long(),
            osmiCardActivate = node["osmiCardActivate"]?.long(),
            cardprDate = node["cardprDate"]?.long(),
        )

        override fun write(obj: CardDTO) = JsonNode.obj()
            .set("enabled", obj.enabled.json)
            .set("id", obj.id.json)
            .set("code", obj.code.json)
            .set("account", obj.account.json)
            .set("osmiCardReg", obj.osmiCardReg?.json)
            .set("osmiCardActivate", obj.osmiCardActivate?.json)
            .set("cardprDate", obj.cardprDate?.json)
    }
}

class CardExistDTO(val code: String) : RuntimeException(), JDTO {
    override val factory: JDTOFactory<JDTO>
        get() = asDefault

    companion object : JDTOFactory<CardExistDTO> {
        override val type: String
            get() = "CardExistDTO"

        override fun read(node: JsonNode) = CardExistDTO(
            code = node["code"]!!.string()
        )

        override fun write(obj: CardExistDTO) = JsonNode.obj()
            .set("code", obj.code.json)

    }
}