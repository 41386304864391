package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.dom.addClass
import org.tlsys.admin.childElements
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.createDiv
import org.w3c.dom.HTMLDivElement
import pw.binom.web.AbstractComponent
import pw.binom.web.Component
import pw.binom.web.layout.*

private val STYLE = CSS.style {
    "button" {
        marginLeft = 10.px
        ":first-child" then {
            marginLeft = 0.px
        }
    }
}.name

class SaveCancelBody(okText: String = "ОК", cancelText: String = "ОТМЕНА", cancelVisible: Boolean = true) :
    AbstractComponent<HTMLDivElement>() {
    override val dom: HTMLDivElement = document.createDiv()

    init {
        afterConstruct()
    }

    private val layout = FlexLayout(dom).apply {
        direction = FlexLayout.Direction.Column
    }.asParent()
    val componentsLayout = FlexLayout.div {
        direction = FlexLayout.Direction.Column
    }.appendTo(layout, grow = 1, shrink = 1)

    var componentHeight: String
        get() = componentsLayout.parent.style.height
        set(it) {
            componentsLayout.parent.style.height = it
        }

    val buttonsLayout = FlexLayout.div {
        parent.style.paddingTop = 20.px
        direction = FlexLayout.Direction.Row
        justifyContent = FlexLayout.JustifyContent.End
        parent.addClass(STYLE)
    }.appendTo(layout, grow = 0, shrink = 0)

    val cancelBtn = BaseButton(cancelText)

    init {
        if (cancelVisible) {
            cancelBtn.appendTo(buttonsLayout, grow = 0, shrink = 0)
        }
    }

    fun ok(func: suspend () -> Unit) {
        okBtn.onClick(func)
    }

    fun cancel(func: suspend () -> Unit) {
        cancelBtn.onClick(func)
    }

    val okBtn = ConfirmButton(okText)
        .appendTo(buttonsLayout, grow = 0, shrink = 0)

    override suspend fun onStart() {
        super.onStart()
        console.info("SaveCancelBody->OnStart")
        layout.dom.childElements.forEach {
            console.info("SaveCancelBody->OnStart->", Component.getComponent(it), it)
        }
    }

    override suspend fun onStop() {
        console.info("SaveCancelBody->OnStop")
        super.onStop()
    }
}
