package org.tlsys.admin.ui

import kotlinx.browser.document
import kotlinx.dom.removeClass
import org.tlsys.addClass
import org.tlsys.admin.events.EventElementValue
import org.tlsys.admin.form.ValidListener
import org.tlsys.admin.form.Validated
import org.tlsys.admin.form.Validator
import org.tlsys.async2
import org.tlsys.core.Closeable
import org.tlsys.css.CSS
import org.tlsys.px
import org.tlsys.ui.DivComponent
import org.tlsys.ui.on
import org.w3c.dom.HTMLInputElement
import kotlin.math.max
import kotlin.math.min

open class Combobox2(items: List<String> = emptyList(), select: Int = -1) : DivComponent(), Validated {
    private var _valid = false

    override val valid: Boolean
        get() = _valid

    private val listeners = ArrayList<ValidListener>()

    override fun onValidChange(listener: ValidListener): Closeable {
        listeners += listener
        return Closeable {
            listeners -= listener
        }
    }

    var items: List<String> = emptyList()
        set(value) {
            field = value
            select = select
        }

    private companion object {
        val ERROR = CSS.genName()
        val STYLE = CSS.style {
            padding = "2px 5px 5px 5px"
            display = "flex"
            flexDirection = "column"
            ":after" then {
                content = "'▼'"
                position = "absolute"
                fontSize = 10.px
                right = 16.px
                top = 19.px
                color = "initial"
            }
            position = "relative"
            boxSizing = "border-box"

            ".$ERROR input" then {
                borderBottom = "1px solid #F44336"
            }

            "input" {
                color = "inherit"
                fontFamily = Styles.DEFAULT_TEXT_FONT
                fontSize = Styles.DEFAULT_FONT_SIZE.px
                backgroundColor = "transparent"
                border = "none"
                borderBottom = "1px solid #bcbcbc"
                cursor = "pointer"
                outline = "none"
                height = 42.px
                lineHeight = 42.px
                boxShadow = "none"
                userSelect = "none"
            }
            "input:disabled" {
                cursor = "default"
                color = "#545454"
            }
        }.name
    }

    private val input = document.createElement("input").unsafeCast<HTMLInputElement>()

    private fun refreshValid() {
        _valid = _validator?.valid(select) == null
        listeners.toTypedArray().forEach {
            it(_valid)
        }

        if (_valid)
            dom.removeClass(ERROR)
        else
            dom.addClass(ERROR)
    }

    var validator: Validator<Int>?
        get() = _validator
        set(value) {
            _validator = value
            refreshValid()
        }

    private var _validator: Validator<Int>? = null
    val eventChange = EventElementValue<Int>()
    var enabled: Boolean
        get() = !input.disabled
        set(value) {
            input.disabled = !value
        }
    var select: Int = -1
        set(value) {
            val index = max(-1, min(value, items.size - 1))
            val oldValue = field
            field = index
            input.value = if (index < 0)
                "Выберите значение"
            else {
                items[index]
            }
            refreshValid()
            if (oldValue != index)
                eventChange.dispatch(index)
        }

    init {
        dom.appendChild(input)
        input.readOnly = true
        dom.addClass(STYLE)

        input.on("click") {
            if (enabled && this.items.isNotEmpty())
                async2 {
                    val rect = dom.getBoundingClientRect()
                    val s = Selector2.select(
                            items = this.items,
                            x = rect.left,
                            y = rect.top + 10.0,
                            width = rect.width,
                            selected = this.select
                    )
                    if (s != null)
                        this.select = s
                }
            it.preventDefault()
        }
        this.items = items
        this.select = select
    }

    override suspend fun onStart() {
        super.onStart()
    }
}

fun <T : Combobox2> T.validator(validator: Validator<Int>): T {
    this.validator = validator
    return this
}